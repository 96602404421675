import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  .box {
    border-bottom: 1px solid #edeff2;
    padding: 16px 0;
    .general {
      font-weight: 700;
      margin-bottom: 10px;
    }
    .input-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      margin-bottom: 16px;
    }
  }
`;

export const Seperator = styled.div`
  width: 1px;
  background: #f1f2fb;
  margin: 40px 10px 0 10px;
`;

export const RequiredIcon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-right: 8px;
`;
