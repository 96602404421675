import gql from "graphql-tag";

export const GETLISTMENUORDERS = gql`
  query getListMenuOrders($input: GetListMenuOrdersInput!) {
    getListMenuOrders(input: $input) {
      count {
        all
        newPaid
        newUnpaid
        inProcess
        processed
      }
      list {
        id
        customer {
          id
          email
          firstName
          lastName
          picture {
            id
            fileUrl
          }
        }
        deliveryMethod
        deliveryAddress {
          id
          address {
            id
            floor
            building
            route {
              name
              kind
              number
            }
            postalCode
            country {
              id
              name
            }
            city {
              id
              name
            }
            location {
              latitude
              longitude
            }
          }
          primaryPhone {
            countryCode
            number
          }
          secondaryPhone {
            countryCode
            number
          }
        }
        reference
        discountedCost
        deliveredCost
        orderStatus
        confirmationPayment
        paymentStatus
        createdAt
        updatedAt
        cartDetails {
          count
          items {
            id
            product {
              id
              identifier
              names {
                id
                languageCode
                value
              }
              descriptions {
                id
                languageCode
                value
              }
              unit {
                kind
                default
              }
              picture {
                id
                fileUrl
              }
            }
            options {
              option {
                id
                names {
                  id
                  languageCode
                  value
                }
                inputKind
              }
              value {
                id
                names {
                  id
                  languageCode
                  value
                }
              }
            }
            quantity
            unitPrice {
              value
              currency {
                id
                code
              }
            }
            totalPrice {
              value
              currency {
                id
                code
              }
            }
            notes
          }
          originalTotalPrice {
            value
            currency {
              id
              code
            }
          }
          totalDiscountedCost {
            value
            currency {
              id
              code
            }
          }
          totalDeliveredCost {
            value
            currency {
              id
              code
            }
          }
          totalPrice {
            value
            currency {
              id
              code
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_MENU_ORDERS_STATUS = gql`
  mutation UpdateMenuOrderStatus($input: UpdateMenuOrderStatusInput!) {
    updateMenuOrderStatus(input: $input) {
      success
    }
  }
`;

export const GET_LIST_VENDOR_BY_MENU = gql`
  query GetListVendorByMenu($input: GetListVendorByMenuInput!) {
    getListVendorByMenu(input: $input) {
      vendors {
        id
        name
        logo {
          id
          fileUrl
        }
      }
    }
  }
`;

export const UPDATE_MENU_ORDER_PAYMENT_STATUS = gql`
  mutation updateMenuOrderPaymentStatus(
    $input: UpdateMenuOrderPaymentStatusInput!
  ) {
    updateMenuOrderPaymentStatus(input: $input) {
      success
    }
  }
`;
