import { PersonalInfoType } from "./RegisterMerchant";

type Error = {
  firstname?: string;
  lastname?: string;
  countryCode?: string;
  number?: string;
};

export const personalInfoValidation = (values: PersonalInfoType): Error => {
  const errors: Partial<Error> = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "firstname":
        if (!values.firstname) {
          errors.firstname = "form.fieldRequired";
        }
        break;
      case "lastname":
        if (!values.lastname) {
          errors.lastname = "form.fieldRequired";
        }
        break;
      case "phone":
        if (!values.phone.number) {
          errors.number = "form.fieldRequired";
        }
        if (!values.phone.countryCode) {
          errors.countryCode = "form.fieldRequired";
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
