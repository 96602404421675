import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Button, Typography, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery, useMutation } from "@apollo/client";

import { BreadCrumbsTitle, Modal } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

import { GET_LIST_ROLES, DELETE_ROLE } from "../../../api";
import {
  GetListRolesQuery,
  GetListRolesQueryVariables,
  RolePayload,
  DeleteRoleMutation,
  DeleteRoleMutationVariables,
} from "../../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../../api/local-state";

import { Wrapper } from "./RoleList--styles";

import { Owner } from "../../../img/digishop/menu-icons";

const RoleList: React.FC = () => {
  const history = useHistory();

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const [collections, setCollections] = useState<RolePayload[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [idRole, setIdRole] = useState<string>("");

  const columns: ColumnsProps = [
    {
      header: "Role Name",
      accessor: "kind",
    },
    {
      header: "Staff Members",
      accessor: "members",
    },
    {
      header: "Permissions",
      accessor: "permissions",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  const { data: listRole, refetch } = useQuery<
    GetListRolesQuery,
    GetListRolesQueryVariables
  >(GET_LIST_ROLES, {
    variables: {
      input: {
        token: localStorage.getItem("token") || "",
        menu: localMenu?.menuItem?.id || "",
        languageCode: "EN",
      },
    },
    skip: !localMenu?.menuItem.id,
  });

  useEffect(() => {
    const collectionsArray = listRole?.getListRoles?.list || [];
    setCollections(collectionsArray);
  }, [listRole]);

  const [DeleteRole, { loading: loadingDelete }] = useMutation<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >(DELETE_ROLE, {
    variables: {
      input: {
        id: idRole,
        token: localStorage.getItem("token") || "",
      },
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      refetch();
      setOpen(false);
    },
  });

  const renderTableRows = () => {
    return (
      [
        {
          id: "",
          kind: (
            <Typography variant="subtitle1">
              <Owner /> &nbsp; Owner
            </Typography>
          ),
          members: <Typography variant="subtitle1">1</Typography>,
          permissions: <Typography variant="subtitle1">All</Typography>,
        },
        ...collections?.map((collection) => ({
          id: collection.id,
          kind: <Typography variant="subtitle1">{collection.kind}</Typography>,
          members: (
            <Typography variant="subtitle1">
              {collection?.staff?.length || 0}
            </Typography>
          ),
          permissions: (
            <Typography variant="subtitle1">
              {collection?.permissions?.length || 0}
            </Typography>
          ),
          actions: (
            <div style={{ display: "flex" }}>
              <IconButton
                size="small"
                color="primary"
                style={{ color: " #1F90CF", margin: "0 4px" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                style={{ color: "#ff4949", margin: "0 4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                  setIdRole(collection.id || "");
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        })),
      ] || []
    );
  };

  const handleOnEdit = (data: RolePayload) => {
    history.push({
      pathname: "/role-form",
      state: data,
    });
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Staff Managment", "Roles"]}
        iconAlt="Roles"
      />
      <Box component={Paper} className="role__container">
        <div className="role__container-action">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/role-form")}
          >
            Create a role
          </Button>
        </div>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="List orders is empty"
          onClickRow={(row) => {
            if (row.id) {
              const selectedCollection = collections.filter(
                (collection) => collection.id === row.id
              )?.[0];
              handleOnEdit(selectedCollection);
            }
          }}
        />
      </Box>
      <Modal
        open={open}
        title="Delete role?"
        message="Are you sure you want to remove this role?"
        handleClose={() => setOpen(false)}
        handleContent={DeleteRole}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default RoleList;
