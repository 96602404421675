import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 480px;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  .details-inventroy {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: column;
      height: 40px;
      justify-content: space-between;
    }
  }
  .calculate-quantity {
    display: flex;
    justify-content: flex-end;
    & > div {
      display: flex;
      flex-direction: column;
      width: 80px;
      gap: 5px;
    }
    & > div:last-child {
      align-items: flex-end;
    }
  }
  .action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
