import React, { useEffect } from "react";
import {
  Dialog,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useQuery, useMutation } from "@apollo/client";

import { Input, Select } from "../../commons";

import {
  GET_LIST_ROLES,
  CREATE_MENU_STAFF,
  UPDATE_MENU_STAFF_ROLE,
} from "../../api";
import {
  GetListRolesQuery,
  GetListRolesQueryVariables,
  CreateMenuStaffMutation,
  CreateMenuStaffMutationVariables,
  CreateMenuStaffInput,
  StaffPayload,
  UpdateMenuStaffRoleMutation,
  UpdateMenuStaffRoleMutationVariables,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

import { ValidationForm, Error } from "./StaffMemberFormModalValidation";

import { Wrapper } from "./StaffMemberFormModal--styles";

type StaffMemberFormModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  refetch: () => void;
  staffData?: StaffPayload;
};

export type FormMemberStats = CreateMenuStaffInput & {
  confirmPassword: string;
};

const iniatialError: Error = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  password: "",
  confirmPassword: "",
};

const StaffMemberFormModal: React.FC<StaffMemberFormModalProps> = ({
  open,
  title,
  handleClose,
  refetch,
  staffData,
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const [formMember, setFormMember] = React.useState<FormMemberStats>({
    token: localStorage.getItem("token") || "",
    menu: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    role: "",
    languageCode: "EN",
  });

  const [formError, setFormError] = React.useState<Error>(iniatialError);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: listRole, refetch: refetchListRole } = useQuery<
    GetListRolesQuery,
    GetListRolesQueryVariables
  >(GET_LIST_ROLES, {
    variables: {
      input: {
        token: localStorage.getItem("token") || "",
        menu: localMenu?.menuItem?.id || "",
        languageCode: "EN",
      },
    },
    skip: !localMenu?.menuItem.id,
  });

  const [CreateMember, { loading }] = useMutation<
    CreateMenuStaffMutation,
    CreateMenuStaffMutationVariables
  >(CREATE_MENU_STAFF, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      handleClose();
      refetch();
      refetchListRole();
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully created",
      });
    },
  });

  const [UpdateStaff] = useMutation<
    UpdateMenuStaffRoleMutation,
    UpdateMenuStaffRoleMutationVariables
  >(UPDATE_MENU_STAFF_ROLE, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      handleClose();
      refetch();
      refetchListRole();
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully updated",
      });
    },
  });

  useEffect(() => {
    setFormMember({
      ...formMember,
      firstName: staffData?.informations?.firstName || "",
      lastName: staffData?.informations?.lastName || "",
      email: staffData?.email || "",
      role: staffData?.role?.id || "",
    });
  }, [staffData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormMember({ ...formMember, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormMember({ ...formMember, role: event.target.value as string });
    setFormError({ ...formError, role: "" });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (staffData?.id)
      UpdateStaff({
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            id: staffData?.id || "",
            role: formMember.role || "",
          },
        },
      });
    else {
      const validation = ValidationForm(formMember);
      if (Object.entries(validation).length === 0)
        CreateMember({
          variables: {
            input: {
              menu: localMenu?.menuItem.id || "",
              token: formMember.token,
              firstName: formMember.firstName,
              lastName: formMember.lastName,
              role: listRole?.getListRoles?.list?.filter(
                (role) => role.id === formMember.role
              )?.[0]?.kind,
              email: formMember.email,
              password: formMember.password,
              languageCode: formMember.languageCode,
            },
          },
        });
      else {
        setFormError({ ...validation });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Typography
          variant="h2"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          Set Staff Info
        </Typography>
        <Input
          label="First name"
          name="firstName"
          value={formMember.firstName}
          onChange={handleChange}
          msgError={formError.firstName}
          style={{ marginBottom: "10px" }}
          disabled={!!staffData?.id}
        />
        <Input
          label="Last name"
          name="lastName"
          value={formMember.lastName}
          onChange={handleChange}
          msgError={formError.lastName}
          style={{ marginBottom: "10px" }}
          disabled={!!staffData?.id}
        />
        <Select
          label="Assign A role"
          name="role"
          value={formMember.role}
          onChange={handleRole}
          msgError={formError.role}
        >
          {listRole?.getListRoles?.list?.map((role) => (
            <MenuItem value={role?.id || ""} key={role?.id || ""}>
              {role?.kind}
            </MenuItem>
          ))}
        </Select>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          Set Staff Account
        </Typography>
        <Input
          label="Email"
          type="email"
          name="email"
          value={formMember.email}
          onChange={handleChange}
          style={{ marginBottom: "10px" }}
          msgError={formError.email}
          disabled={!!staffData?.id}
        />
        {!staffData?.id && (
          <Input
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formMember.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            onChange={handleChange}
            msgError={formError.password}
            style={{ marginBottom: "10px" }}
          />
        )}
        {!staffData?.id && (
          <Input
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formMember.confirmPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            onChange={handleChange}
            msgError={formError.confirmPassword}
            style={{ marginBottom: "10px" }}
          />
        )}

        <div className="action">
          <Button onClick={handleClose}>Discard</Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default StaffMemberFormModal;
