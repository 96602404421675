export const handlePaymentOrder = (status: string): string => {
  switch (status) {
    case "WAITING":
      return "Awaiting Payment";
    case "PAID":
      return "Paid";
    case "CANCELED":
      return "Canceled";
      break;
    default:
      return "";
  }
};

export const handleStatusOrder = (status: string): string => {
  switch (status) {
    case "WAITING":
      return "Awaiting processing";
      break;
    case "ONGOING":
      return "Processing";
      break;
    case "READY":
      return "Ready for pickup";
      break;
    case "IN_DELIVERING":
      return "Shipped";
      break;
    case "CANCELED":
      return "Canceled";
      break;
    case "DELIVERED":
      return "Delivered";
      break;
    case "UNDELIVERED":
      return "Not delivered";
      break;
    default:
      return "";
  }
};
