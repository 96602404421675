import React from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
  VictoryBrushLine,
} from "victory";

const ChartLine: React.FC = () => {
  const data = [
    { y: 25, x: 0 },
    { y: 53, x: 1 },
    { y: 20, x: 2 },
    { y: 14, x: 3 },
    { y: 36, x: 4 },
    { y: 41, x: 5 },
    { y: 14, x: 6 },
    { y: 36, x: 7 },
    { y: 14, x: 8 },
    { y: 36, x: 9 },
  ];

  const data2 = [
    { y: 22, x: 0 },
    { y: 35, x: 1 },
    { y: 25, x: 2 },
    { y: 15, x: 3 },
    { y: 40, x: 4 },
    { y: 40, x: 5 },
    { y: 18, x: 6 },
    { y: 34, x: 7 },
    { y: 8, x: 8 },
    { y: 38, x: 9 },
  ];

  return (
    <div>
      <VictoryChart
        animate={{
          duration: 2000,
          onLoad: { duration: 1000 },
        }}
        // width={600}
        padding={35}
        domainPadding={{ x: [5, 0], y: [15, 15] }}
      >
        <VictoryLine
          interpolation="natural"
          style={{
            data: { stroke: "#F29F05" },
          }}
          data={data}
        />
        <VictoryScatter
          labelComponent={<VictoryTooltip />}
          data={data}
          labels={({ datum }) => ` Y: ${datum.y} , X : ${datum.x}`}
          size={3}
          style={{
            data: { fill: "#F29F05", strokeWidth: 1, stroke: "#F29F05" },
            labels: { fill: "#F29F05", strokeWidth: 1, stroke: "#F29F05" },
          }}
        />

        <VictoryLine
          interpolation="natural"
          style={{
            data: { stroke: "#92B0D6" },
          }}
          data={data2}
        />
        <VictoryScatter
          labelComponent={<VictoryTooltip />}
          data={data2}
          labels={({ datum }) => ` Y: ${datum.y} , X : ${datum.x}`}
          size={3}
          style={{
            data: { fill: "#92B0D6", strokeWidth: 1, stroke: "#92B0D6" },
            labels: { fill: "#92B0D6", strokeWidth: 1, stroke: "#92B0D6" },
          }}
        />
        <VictoryAxis
          gridComponent={<VictoryBrushLine width={1} allowDrag={false} />}
        />
      </VictoryChart>
    </div>
  );
};

export default ChartLine;
