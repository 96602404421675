import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import { Button, ClickAwayListener } from "@material-ui/core";

import { Wrapper, Note } from "./PromoCodeStyle";

import { Box, Modal, BreadCrumbsTitle } from "../../commons";
import { ListDataEmpty } from "../../components";

import {
  GetListPromoCodesQuery,
  GetListPromoCodesQueryVariables,
  DeletePromoCodeMutation,
  DeletePromoCodeMutationVariables,
} from "../../api/types";
import { GETLISTPROMOCODE, DELETEPROMOCODE } from "../../api";
import { SelectedMenuVar } from "../../api/local-state";
import { PromoCodeImg } from "../../img";

const PromoCode: React.FC = () => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";
  const menu = SelectedMenuVar();

  // Query get list promo
  const { data: promoList } = useQuery<
    GetListPromoCodesQuery,
    GetListPromoCodesQueryVariables
  >(GETLISTPROMOCODE, {
    variables: {
      input: {
        token,
        menu: menu?.id || "",
      },
    },
    fetchPolicy: "network-only",
  });

  const [deletePromoCode, { loading: loadingDelete }] = useMutation<
    DeletePromoCodeMutation,
    DeletePromoCodeMutationVariables
  >(DELETEPROMOCODE, {
    refetchQueries: () => [
      {
        query: GETLISTPROMOCODE,
        variables: {
          input: {
            token,
            menu: menu?.id || "",
          },
        },
      },
    ],
  });

  const [promoToDelete, setPromoToDelete] = useState<string | null | undefined>(
    ""
  );
  // const [OpenMenu, setOpenMenu] = useState<boolean>(false);
  const handleClickAway = () => {
    // setOpenMenu(false);
  };

  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const trigerDeletePromoCode = (id: string | null | undefined) => {
    deletePromoCode({
      variables: {
        input: {
          token,
          id: id || "",
        },
      },
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper>
        <Note>
          <BreadCrumbsTitle
            labelIntl={[
              intl.formatMessage({ id: "Store.ProductManagment" }),
              intl.formatMessage({ id: "Store.ProductManagment.Promo" }),
            ]}
            icon={PromoCodeImg}
            iconAlt="PromoCode"
          />
          {promoList?.getListPromoCodes?.list &&
            promoList.getListPromoCodes.list.length > 0 && (
              <div className="action">
                <Link to="/add-promo-code">
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-action"
                  >
                    {intl.formatMessage({
                      id: "Store.PromoCode.AddPromo",
                    })}
                  </Button>
                </Link>
              </div>
            )}
        </Note>
        <div className="container">
          {promoList?.getListPromoCodes?.list &&
            promoList.getListPromoCodes.list.length > 0 && (
              <>
                <Box p={20} width={1} className="container">
                  <h3>
                    {intl.formatMessage({
                      id: "Store.PromoCode.list",
                    })}
                  </h3>
                  <div className="collections">
                    <div className="collections__header">
                      <span>
                        {intl.formatMessage({
                          id: "Store.PromoCode.promoCode",
                        })}
                      </span>
                      <span>
                        {intl.formatMessage({
                          id: "Store.PromoCode.Type",
                        })}
                      </span>
                      <span>
                        {intl.formatMessage({
                          id: "Store.PromoCode.action",
                        })}
                      </span>
                    </div>
                    <div className="collections__list">
                      {promoList.getListPromoCodes.list.map((item) => (
                        <>
                          <div className="collections__item" key={item.id}>
                            <span>{item?.code}</span>
                            <span>{item?.kind}</span>
                            <span>
                              <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setPromoToDelete(item?.id);
                                }}
                              >
                                {intl.formatMessage({
                                  id: "Store.PromoCode.kill",
                                })}
                              </Button>
                            </span>
                          </div>
                          <Modal
                            open={deleteModal && promoToDelete === item?.id}
                            title={intl.formatMessage({
                              id: "Store.PromoCode.delete",
                            })}
                            message={`${intl.formatMessage({
                              id: "Store.PromoCode.delete.message",
                            })} ${item?.code} ?`}
                            handleClose={() => setDeleteModal(false)}
                            handleContent={() =>
                              trigerDeletePromoCode(item?.id)
                            }
                            action="delete"
                            loading={loadingDelete}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </Box>
              </>
            )}
          {promoList?.getListPromoCodes?.list &&
            promoList?.getListPromoCodes?.list.length === 0 && (
              <ListDataEmpty
                image={PromoCodeImg}
                description={intl.formatMessage({
                  id: "Store.PromoCode.listEmpty",
                })}
                btnName={intl.formatMessage({
                  id: "Store.PromoCode.AddPromo",
                })}
                url="/add-promo-code"
              />
            )}
        </div>
      </Wrapper>
    </ClickAwayListener>
  );
};

export default PromoCode;
