import {
  Facebook,
  YouTube,
  WhatsApp,
  Instagram,
  Reddit,
  Twitter,
  Snapchat,
  LinkedIn,
  Pinterest,
  Tumblr,
  Google,
  Periscope,
} from "../img/social";

export const getSocialIcon = (kind?: string | null): string => {
  switch (kind) {
    case "Facebook":
      return Facebook;
    case "Twitter":
      return Twitter;
    case "YouTube":
      return YouTube;
    case "WhatsApp":
      return WhatsApp;
    case "Instagram":
      return Instagram;
    case "Reddit":
      return Reddit;
    case "Tumblr":
      return Tumblr;
    case "Pinterest":
      return Pinterest;
    case "Snapchat":
      return Snapchat;
    case "LinkedIn":
      return LinkedIn;
    case "Google+":
      return Google;
    case "Periscope":
      return Periscope;
    default:
      return "";
  }
};
