import * as React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from "@apollo/client";

import { ButtonCustomize } from "../../commons";

import {
  GET_BEAMANDGO_LIST_CATEGORIES,
  GET_BEAMANDGO_LIST_PRODUCTS,
} from "../../api";

import {
  GetBeamandgoListCategoriesQuery,
  GetBeamandgoListCategoriesQueryVariables,
  GetBeamandgoListProductsQuery,
  GetBeamandgoListProductsQueryVariables,
  CreateApiDataBeamandgoInput,
} from "../../api/types";

type AssignProductTypeBeamAndGoModalProps = {
  open: boolean;
  onReturn: (
    id: string,
    name: string,
    typeId?: number,
    apiData?: CreateApiDataBeamandgoInput
  ) => void;
  onClose: () => void;
};

const AssignProductTypeBeamAndGoModal = ({
  open,
  onClose,
  onReturn,
}: AssignProductTypeBeamAndGoModalProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";

  const [category, setCategory] = React.useState("");
  const [idProduct, setIdProduct] = React.useState("");
  const [nameProduct, setNameProduct] = React.useState("");
  const [typeId, setTypeId] = React.useState<number>();
  const [stateApiData, setStateApiData] =
    React.useState<CreateApiDataBeamandgoInput>();

  const { data: dataCategories } = useQuery<
    GetBeamandgoListCategoriesQuery,
    GetBeamandgoListCategoriesQueryVariables
  >(GET_BEAMANDGO_LIST_CATEGORIES, {
    variables: {
      input: {
        token,
      },
    },
  });

  const listCategories = dataCategories?.getBeamandgoListCategories?.list || [];

  const { data: dataProducts, loading } = useQuery<
    GetBeamandgoListProductsQuery,
    GetBeamandgoListProductsQueryVariables
  >(GET_BEAMANDGO_LIST_PRODUCTS, {
    variables: {
      input: {
        token,
        category,
      },
    },
    skip: !category,
  });

  const listProducts = dataProducts?.getBeamandgoListProducts?.list || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Assign Product
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <Autocomplete
            id="Beam&GoCategory"
            options={listCategories}
            onChange={(event, value) => value && setCategory(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Beam&Go category"
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
            )}
            style={{ marginBottom: 16 }}
          />
          <Autocomplete
            id="Beam&GoProducts"
            options={listProducts}
            getOptionLabel={
              (option) =>
                `${option.name} -- ${option.unitPrice} ${option.unitPriceCurrency}`
              // option.name + " " + option.unitPrice || ""
            }
            onChange={(event, value) => {
              value?.id && setIdProduct(value?.id);
              value?.name && setNameProduct(value.name);
              value?.typeId && setTypeId(value.typeId);
              setStateApiData({
                productSid: value?.id,
                code: value?.identifier,
                name: value?.name,
                description: value?.description,
                productTypeId: value?.typeId,
                unitPrice: value?.unitPrice,
                unitPriceCurrencyCode: value?.unitPriceCurrency,
                imageUrl: value?.imageUrl,
                minQty: value?.minQuantity,
                maxQty: value?.maxQuantity,
                stockQty: value?.stockQuantity,
                isBranchSpecific: value?.isBranchSpecific,
                isShippingRequired: value?.isShippingRequired,
                isBirthDateRequired: value?.isBirthDateRequired,
                accountSid: value?.accountSid,
                shippingAndHandlingFee: value?.shippingAndHandlingFee,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Beam&Go products"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{ marginTop: -20 }}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            loading={loading}
            disabled={!category}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={2}
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={() => {
              onReturn(idProduct, nameProduct, typeId, stateApiData);
              onClose();
            }}
            disabled={!idProduct}
          >
            Assign
          </ButtonCustomize>
        </Box>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  min-width: 500px;
  flex-direction: column;

  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  .MuiFilledInput-underline:after {
    border-bottom: none;
  }
  .MuiInputBase-root {
    background-color: #ffffff;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus-within {
      border: 2px solid #000000;
      border-radius: 5px;
    }
  }
`;

export default AssignProductTypeBeamAndGoModal;
