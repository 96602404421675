import React from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { Chip, Avatar } from "@material-ui/core";

import { Note, Wrapper } from "./SettingsStyle";

import client from "../../api/apolloClient";
import { GET_LANGUAGE } from "../../api/local-state";

import { EN, FR } from "../../img";

const languages = [
  { name: "English", code: "en", flag: EN },
  { name: "French", code: "fr", flag: FR },
];

const Settings: React.FC = () => {
  const intl = useIntl();
  const { data } = useQuery(GET_LANGUAGE);
  const selectedLanguage = data?.language;
  const handleChangeLanguage = (languageCode: string) => {
    client.writeQuery({
      query: GET_LANGUAGE,
      data: { language: languageCode },
    });
  };

  return (
    <Wrapper>
      <div className="topbar">
        <Note>
          <FontAwesomeIcon
            icon={faCog}
            size="lg"
            style={{ marginRight: "15px" }}
          />
          {intl.formatMessage({ id: "Settings.title" })}
        </Note>
      </div>
      <span style={{ fontSize: "14px" }}>
        {intl.formatMessage({ id: "Settings.label" })}
      </span>
      <div className="box" style={{ marginTop: "15px" }}>
        <form>
          {languages !== undefined &&
            languages.map((language) => {
              return (
                <Chip
                  key={language.code}
                  avatar={<Avatar alt={language.name} src={language.flag} />}
                  label={language.name}
                  onClick={() => handleChangeLanguage(language.code)}
                  color={
                    selectedLanguage === language.code ? "primary" : "default"
                  }
                  style={{ marginRight: "15px", marginBottom: "10px" }}
                />
              );
            })}
        </form>
      </div>
    </Wrapper>
  );
};

export default Settings;
