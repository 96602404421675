import * as React from "react";
import ContentLoader from "react-content-loader";

const LoadingListHomePage = (): JSX.Element => {
  return (
    <ContentLoader viewBox="0 0 100% 550" height={550} width="100%">
      <rect x="0" y="20" rx="5" ry="5" width="130" height="130" />
      <rect x="0" y="190" rx="5" ry="5" width="100%" height="500" />
    </ContentLoader>
  );
};

export default LoadingListHomePage;
