import * as React from "react";
import { Box, Paper, IconButton, Typography } from "@material-ui/core";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { BreadCrumbsTitle, Modal, ButtonCustomize } from "../../commons";
import { VendorFormModal } from "../../components";
import Table, { ColumnsProps } from "../../commons/Table";

import { Wrapper } from "./ListVendor--styles";

import Role from "../../img/digishop/menu-icons/role.svg";

import { GET_MENU_STAFF, DELETE_MENU_STAFF } from "../../api";
import {
  GetMenuStaffQuery,
  GetMenuStaffQueryVariables,
  DeleteMenuStaffMutation,
  DeleteMenuStaffMutationVariables,
  MenuPayload,
  StaffPayload,
} from "../../api/types";

import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

const ListVendor = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [openModal, setOpenModal] = React.useState(false);
  const [idVendor, setIdvendor] = React.useState("");
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [vendorData, setVendorData] = React.useState<StaffPayload | undefined>(
    undefined
  );

  const { data, refetch } = useQuery<
    GetMenuStaffQuery,
    GetMenuStaffQueryVariables
  >(GET_MENU_STAFF, {
    variables: {
      input: {
        menu: localMenu?.id || "",
        token,
        kind: "VENDOR",
        sorting: "DATE_ASC",
      },
    },
    skip: !localMenu?.id,
  });

  const listVendor = data?.getMenuStaff?.list;

  const [DeleteVendor, { loading: loadingDelete }] = useMutation<
    DeleteMenuStaffMutation,
    DeleteMenuStaffMutationVariables
  >(DELETE_MENU_STAFF, {
    variables: {
      input: {
        token,
        id: idVendor,
      },
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      refetch();
      setOpenModalDelete(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully deleted",
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: "Vendor Name",
      accessor: "name",
    },
    {
      header: "Vendor Email",
      accessor: "email",
    },
    {
      header: "Role",
      accessor: "role",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listVendor?.map((item) => ({
        name: (
          <Typography variant="subtitle1">
            {item?.informations?.firstName} &nbsp;
            {item?.informations?.lastName}
          </Typography>
        ),
        email: <Typography variant="subtitle1">{item?.email}</Typography>,
        role: <Typography variant="subtitle1">{item?.role?.kind}</Typography>,
        actions: (
          <Box display="flex">
            <IconButton size="small" className="btn-edit">
              <EditIcon
                onClick={() => {
                  setOpenModal(true);
                  setVendorData(item);
                }}
              />
            </IconButton>
            <IconButton size="small" className="btn-delete">
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModalDelete(true);
                  setIdvendor(item.id || "");
                }}
              />
            </IconButton>
          </Box>
        ),
      })) || []
    );
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Staff Managment", "List Vendor"]}
        icon={Role}
        iconAlt="Vendor"
      />
      <Box component={Paper} className="vendor__container">
        <div className="vendor__container-action">
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(true);
              setVendorData(undefined);
            }}
          >
            Create vendor
          </ButtonCustomize>
        </div>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="Add your first vendor"
        />
      </Box>
      {openModal && (
        <VendorFormModal
          title="Vendor"
          open={openModal}
          vendorData={vendorData}
          refetch={refetch}
          handleClose={() => setOpenModal(false)}
        />
      )}
      <Modal
        open={openModalDelete}
        title="Delete Vendor?"
        message="Are you sure you want to remove this vendor?"
        handleClose={() => setOpenModalDelete(false)}
        handleContent={DeleteVendor}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default ListVendor;
