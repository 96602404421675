import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "../img";

const ErrorMessage = () => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Error>
      <img src={Alert} alt="Alert" />
      <h3>{intl.formatMessage({ id: "ErrorMessage.Message" })}</h3>
      <span
        role="button"
        tabIndex={0}
        onClick={() => history.go(0)}
        onKeyUp={() => history.go(0)}
      >
        {intl.formatMessage({ id: "ErrorMessage.refresh" })}
      </span>
    </Error>
  );
};

const Error = styled.div`
  width: 300px;
  height: 300px;
  padding: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 70px;
    width: 70px;
  }
  h3,
  img {
    opacity: 0.3;
  }
  span {
    color: #3fb4f6;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export default ErrorMessage;
