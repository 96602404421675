// Import libraries
import React, { FC } from "react";
import styled, { css } from "styled-components";
import BoxMUI, { BoxProps } from "@material-ui/core/Box";

type BoxNewProps = {
  paper?: boolean;
} & BoxProps;

const BoxNew: FC<BoxNewProps> = ({ children, paper, ...restProps }) => {
  return (
    <BoxStyled paper={paper} {...restProps}>
      {children}
    </BoxStyled>
  );
};

const BoxStyled = styled(BoxMUI)<BoxNewProps>`
  // got a paper background
  ${({ paper }) => {
    if (paper) {
      return css`
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
      `;
    }
    return undefined;
  }}
`;

export default BoxNew;
