import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  min-width: 500px;
  padding: 20px;
  table {
    border-radius: 7px;
    overflow: hidden;
    border: 0;
    border-collapse: collapse;
    width: 100%;
  }

  table td,
  table th {
    /* border: 1px solid #ddd; */
    padding: 8px;
  }

  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  table tr:hover {
    background-color: #ddd;
  }

  table thead {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f29f05;
    color: white;
  }
`;
