import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .container {
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
    align-items: center;
    .vector {
      position: relative;
      display: flex;
      min-width: 220px;
      height: 200px;
      justify-content: center;
      align-items: center;
      .img-vector {
        position: absolute;
        width: 150px;
        z-index: 9;
      }
      .img-apparence {
        width: 50px;
        position: absolute;
        z-index: 99;
      }
    }
    .title {
      text-align: center;
      font-size: 20px;
    }
    .form {
      display: flex;
      width: 650px;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      .MuiFilledInput-root {
        border-radius: 5px;
        border: 2px solid #f1f2fb;
        background-color: #ffffff;
        ::before {
          content: none;
        }
        ::after {
          content: none;
        }
        &:focus-within {
          background: #ffffff;
          border: 2px solid #000000;
          border-radius: 5px;
        }
        &:hover {
          background: #ffffff;
        }
        input {
          height: 23px;
        }
      }
      .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]
        .MuiAutocomplete-input {
        padding: 6px 4px;
      }
      .MuiChip-root {
        height: 29px;
      }
    }
  }
`;
