// Styled component import
import styled from "styled-components";

// Types
export const Note = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  justify-content: space-between;
  svg {
    width: 24px;
    margin-right: 15px;
  }
`;

export const Wrapper = styled.div`
  .link-container {
    position: relative;
    width: fit-content;
  }
  .input-container {
    margin-bottom: 20px;
    max-width: 400px;
    position: relative;
  }
  .textarea-container {
    max-width: 600px;
    position: relative;
  }
  .link {
    border: 2px solid #f3f4fa;
    height: 48px;
    display: flex;
    width: 450px;
    border-radius: 8px;
    overflow: hidden;
  }
  .link-prefix {
    background-color: ghostwhite;
    padding: 13px 6px 16px 20px;
  }
  .store-name {
    border: 0;
    width: 100%;
    padding: 0px 0px 3px 5px;
    color: #444444;
    font-size: 16px;
  }
  .checklist {
    position: absolute;
    right: -240px;
    background-color: ghostwhite;
    border: 1px solid #f1f2ff;
    padding: 5px 10px;
    border-radius: 8px;
    width: 200px;
    top: 00px;
    .tip {
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 10px solid #f1f2ff;
      border-bottom: 7px solid transparent;
      left: -9px;
      position: absolute;
      top: 7px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 13px;
        color: #868894;
        svg {
          margin-right: 8px;
        }
      }
      /* color: #0dca12; */
      li.success svg {
        color: #0dca12;
      }

      /* color: #f67070; */
      li.error {
        color: #f67070;
        svg {
          color: #f67070;
        }
      }

      li.initial svg {
        color: #afb1c6;
      }
    }
  }
`;

export const Separator = styled.div`
  height: 25px;
  width: 100%;
`;
