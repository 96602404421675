import * as React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useReactiveVar } from "@apollo/client";
import find from "lodash/find";

import { Select, ButtonCustomize } from "../../commons";

import { GETLISTMENUCATEGORIES, GET_LIST_MENU_PRODUCTS } from "../../api";
import {
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  GetListMenuProductsQuery,
  GetListMenuProductsQueryVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import {
  MenuCategoryExtended,
  regroupCategories,
} from "../../utils/category-utils";

type KindEnum = "CATEGORY" | "PRODUCT";

type AssignSlideHomePageProps = {
  open: boolean;
  onClose: () => void;
  onReturn: (kind: KindEnum, id: string) => void;
  typeKind: string | undefined | null;
  idObject: string | undefined | null;
};

const AssignSlideHomePage = ({
  open,
  onClose,
  onReturn,
  typeKind,
  idObject,
}: AssignSlideHomePageProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(localMenu?.languages, (o) => o.isMain === true)
    ?.language?.code;

  const [state, setState] = React.useState<{
    kind: KindEnum;
    id: string;
  }>({
    kind: "CATEGORY",
    id: "",
  });

  const { data: listProducts } = useQuery<
    GetListMenuProductsQuery,
    GetListMenuProductsQueryVariables
  >(GET_LIST_MENU_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: state.kind !== "PRODUCT",
  });

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.id || "" } },
    skip: state.kind !== "CATEGORY",
  });
  const categoriesListData = listCategory?.getListMenuCategories?.list;

  const renderCategorySelectOptions = (
    List: MenuCategoryExtended[]
  ): React.ReactNode => {
    return (
      List.map((category) => [
        <MenuItem
          value={category.id || ""}
          style={{ paddingLeft: `${(category.level || 0) * 10}px` }}
          disabled={!!category?.subcategories}
        >
          {category.names?.[0].value || ""}
        </MenuItem>,

        renderCategorySelectOptions(category.subcategories || []),
      ]) || null
    );
  };

  // refactor list categories
  const categories = React.useMemo(
    () => (categoriesListData ? regroupCategories(categoriesListData) : []),
    [categoriesListData]
  );

  React.useEffect(() => {
    if (idObject) {
      setState({
        id: idObject,
        kind: typeKind as KindEnum,
      });
    } else {
      setState({
        id: "",
        kind: "CATEGORY",
      });
    }
  }, [typeKind, idObject]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Assign Slide
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <Select
            label="Kind"
            value={state.kind}
            style={{ marginBottom: 16 }}
            onChange={(e) =>
              setState({
                kind: e.target.value as KindEnum,
                id: "",
              })
            }
          >
            <MenuItem value="CATEGORY">Category</MenuItem>
            <MenuItem value="PRODUCT">Product</MenuItem>
          </Select>
          {state.kind === "CATEGORY" && (
            <Select
              label="List category"
              value={state.id}
              onChange={(e) => {
                setState({
                  ...state,
                  id: e.target.value as string,
                });
              }}
            >
              {renderCategorySelectOptions(categories)}
            </Select>
          )}
          {state.kind === "PRODUCT" && (
            <Select
              label="List product"
              value={state.id}
              onChange={(e) => {
                setState({
                  ...state,
                  id: e.target.value as string,
                });
              }}
            >
              {listProducts?.getListMenuProducts?.list?.map((item) => (
                <MenuItem value={item.id!} key={item.id!}>
                  {
                    item.names?.find((o) => o.languageCode === languageIsMain)
                      ?.value
                  }
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={2}
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={async () => {
              await onReturn(state.kind, state.id);
              onClose();
            }}
            disabled={!state.id}
          >
            Assign
          </ButtonCustomize>
        </Box>
      </Container>
      ;
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  min-width: 500px;
  flex-direction: column;
`;

export default AssignSlideHomePage;
