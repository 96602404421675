import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type InputTagsProps = {
  options?: any[];
  // eslint-disable-next-line
  onReturn: (list: string[]) => void;
  inputValue: any[];
  disabled?: boolean;
};

const InputTagAutoComplete: React.FC<InputTagsProps> = ({
  options,
  disabled,
  onReturn,
}) => {
  const [list, setList] = useState<string[]>([]);
  return (
    <Container>
      <Autocomplete
        disableClearable
        options={options || []}
        getOptionLabel={(option) => option.names?.[0]?.value || option || ""}
        value={list}
        onChange={(event, value) => {
          if (value?.length < 4) {
            setList(value);
            onReturn(value.map((item: any) => item.id || item));
          }
        }}
        renderOption={(option) => (
          <>
            {option?.name ? (
              option?.name
            ) : option?.names?.[0]?.value ? (
              option.names[0].value
            ) : (
              <>
                {option?.indexOf("#") !== -1 ? (
                  <div
                    style={{
                      background: `${option}`,
                      width: "100%",
                      height: 15,
                      marginRight: 10,
                      border: "1px solid #CBCBCB",
                      borderRadius: 5,
                      margin: "5px 0",
                    }}
                  />
                ) : (
                  <span>{option}</span>
                )}
              </>
            )}
          </>
        )}
        renderTags={(option) => {
          return option?.map((item: any, index: number) => (
            // eslint-disable-next-line
            <TagsItem key={index} background={item}>
              {item?.name ? (
                <span>{item?.name}</span>
              ) : item?.names?.[0]?.value ? (
                <span>{item?.names?.[0]?.value}</span>
              ) : (
                <>
                  {item.indexOf("#") !== -1 ? (
                    <div className="background" />
                  ) : (
                    <span>{item}</span>
                  )}
                </>
              )}
              <div
                role="button"
                tabIndex={0}
                className="close"
                onKeyUp={() => {
                  setList((prevList) => {
                    const arrayList = [...prevList];
                    arrayList.splice(index, 1);
                    return arrayList;
                  });
                }}
                onClick={() => {
                  setList((prevList) => {
                    const arrayList = [...prevList];
                    arrayList.splice(index, 1);
                    return arrayList;
                  });
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </TagsItem>
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            variant="filled"
            InputProps={{ ...params.InputProps, type: "search" }}
            fullWidth
          />
        )}
        multiple
        disabled={!disabled}
      />
    </Container>
  );
};

const TagsItem = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;
  background: #969494;
  color: #f1f2fb;
  margin: 3px;
  padding: 5px;
  border-radius: 10px;
  font-size: 13px;
  gap: 10px;
  .background {
    background: ${(props) => props.background};
    width: 100%;
    height: 16px;
    border-radius: 10px;
    min-width: 35px;
  }
  .close {
    display: flex;
    min-width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
    background: #f1f2fb;
    color: #969494;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  .MuiAutocomplete-inputRoot {
    padding-right: 8px;
  }
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-top: 2px;
    padding-left: 8px;
  }

  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
    &:focus-within {
      background: #ffffff;
      border: 2px solid #000000;
      border-radius: 5px;
    }
    &:hover {
      background: #ffffff;
    }
    input {
      height: 23px;
    }
    .MuiAutocomplete-tag {
      background: #444444;
      color: #f1f2fb;
    }
  }

  .MuiFormControl-marginDense {
    margin-top: 0;
    margin-bottom: 0;
  }

  .MuiChip-deleteIcon {
    color: #f1f2fb;
  }
  .MuiChip-deleteIcon:hover {
    color: #ffffff;
  }
`;

export default InputTagAutoComplete;
