import * as React from "react";
import styled from "styled-components";
import {
  Dialog,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Input, Select, ButtonCustomize } from "../../commons";
import { UploadPictureProduct } from "..";

import {
  GET_LIST_ROLES,
  CREATE_MENU_STAFF,
  UPDATE_MENU_STAFF_ROLE,
} from "../../api";
import {
  GetListRolesQuery,
  GetListRolesQueryVariables,
  CreateMenuStaffMutation,
  CreateMenuStaffMutationVariables,
  CreateMenuStaffInput,
  UpdateMenuStaffRoleMutation,
  UpdateMenuStaffRoleMutationVariables,
  MenuPayload,
  StaffPayload,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { ValidationForm, Error } from "./vendorFormModal--validation";

type VendorFormModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  refetch: () => void;
  vendorData: StaffPayload | undefined;
};

export type FormMemberStats = CreateMenuStaffInput & {
  confirmPassword: string;
};

const iniatialError: Error = {
  logo: "",
  nameVendor: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  password: "",
  confirmPassword: "",
};

const VendorFormModal = ({
  open,
  title,
  handleClose,
  refetch,
  vendorData,
}: VendorFormModalProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [formMember, setFormMember] = React.useState<FormMemberStats>({
    token,
    menu: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    role: "",
    isVendor: true,
    languageCode: "EN",
  });

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<Error>(iniatialError);

  const { data: listRole, refetch: refetchListRole } = useQuery<
    GetListRolesQuery,
    GetListRolesQueryVariables
  >(GET_LIST_ROLES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        languageCode: "EN",
      },
    },
    skip: !localMenu?.id,
  });

  const [CreateVendor, { loading }] = useMutation<
    CreateMenuStaffMutation,
    CreateMenuStaffMutationVariables
  >(CREATE_MENU_STAFF, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      handleClose();
      refetch();
      refetchListRole();
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully created",
      });
      handleClear();
    },
  });

  const [UpdateVendor] = useMutation<
    UpdateMenuStaffRoleMutation,
    UpdateMenuStaffRoleMutationVariables
  >(UPDATE_MENU_STAFF_ROLE, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      handleClose();
      refetch();
      refetchListRole();
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully updated",
      });
    },
  });

  React.useEffect(() => {
    setFormMember({
      ...formMember,
      firstName: vendorData?.informations?.firstName || "",
      lastName: vendorData?.informations?.lastName || "",
      email: vendorData?.email || "",
      role: vendorData?.role?.id,
      professional: {
        name: vendorData?.informations?.professional?.name,
        logo: vendorData?.informations?.professional?.logo?.id,
      },
    });
  }, [vendorData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormMember({ ...formMember, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormMember({ ...formMember, role: event.target.value as string });
    setFormError({ ...formError, role: "" });
  };

  const handleClear = () => {
    setFormMember({
      token,
      menu: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      role: "",
      isVendor: true,
      languageCode: "EN",
    });
  };

  const handleSubmit = () => {
    if (vendorData?.id) {
      UpdateVendor({
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            id: vendorData?.id,
            role: formMember.role || "",
          },
        },
      });
    } else {
      const validation = ValidationForm(formMember);
      if (Object.entries(validation).length === 0)
        CreateVendor({
          variables: {
            input: {
              menu: localMenu?.id || "",
              token: formMember.token,
              firstName: formMember.firstName,
              lastName: formMember.lastName,
              role: listRole?.getListRoles?.list?.filter(
                (role) => role.id === formMember.role
              )?.[0]?.kind,
              email: formMember.email,
              password: formMember.password,
              languageCode: formMember.languageCode,
              isVendor: formMember.isVendor,
              professional: formMember.professional,
            },
          },
        });
      else {
        setFormError({ ...validation });
        if (validation.logo) {
          snackBarVar({
            open: true,
            severity: "error",
            message: validation.logo,
          });
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        handleClear();
      }}
      maxWidth="md"
    >
      <Wrapper>
        <Box display="flex">
          <Box width={1} marginRight={1}>
            <Typography
              variant="h2"
              style={{ fontWeight: "bold", marginBottom: 8 }}
            >
              {title}
            </Typography>
            <Input
              label="Vendor name"
              name="VendorName"
              value={formMember.professional?.name}
              onChange={(e) => {
                setFormMember({
                  ...formMember,
                  professional: {
                    ...formMember.professional,
                    name: e.target.value,
                  },
                });
                setFormError({
                  ...formError,
                  nameVendor: "",
                });
              }}
              fullWidth
              msgError={formError.nameVendor}
              disabled={!!vendorData?.id}
            />
          </Box>
          <UploadPictureProduct
            uploadTo="Company"
            mode="Base"
            onLoaded={(id) => {
              setFormMember({
                ...formMember,
                professional: {
                  ...formMember.professional,
                  logo: id,
                },
              });
              setFormError({
                ...formError,
                logo: "",
              });
            }}
            urlImage={{
              id: vendorData?.informations?.professional?.logo?.id,
              url: vendorData?.informations?.professional?.logo?.fileUrl,
            }}
            disabled={!!vendorData?.id}
          />
        </Box>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          Set Vendor Info
        </Typography>
        <Input
          label="First name"
          name="firstName"
          value={formMember.firstName}
          onChange={handleChange}
          msgError={formError.firstName}
          disabled={!!vendorData?.id}
          style={{ marginBottom: "10px" }}
        />
        <Input
          label="Last name"
          name="lastName"
          value={formMember.lastName}
          onChange={handleChange}
          msgError={formError.lastName}
          disabled={!!vendorData?.id}
          style={{ marginBottom: "10px" }}
        />
        <Select
          label="Assign A role"
          name="role"
          value={formMember.role}
          onChange={handleRole}
          msgError={formError.role}
        >
          {listRole?.getListRoles?.list?.map((role) => (
            <MenuItem value={role?.id || ""} key={role?.id}>
              {role?.kind}
            </MenuItem>
          ))}
        </Select>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          Set Vendor Account
        </Typography>
        <Input
          label="Email"
          type="email"
          name="email"
          value={formMember.email}
          onChange={handleChange}
          disabled={!!vendorData?.id}
          msgError={formError.email}
          style={{ marginBottom: "10px" }}
        />
        {!vendorData?.id && (
          <Input
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formMember.password}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            msgError={formError.password}
            style={{ marginBottom: "10px" }}
          />
        )}
        {!vendorData?.id && (
          <Input
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formMember.confirmPassword}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            msgError={formError.confirmPassword}
            style={{ marginBottom: "10px" }}
          />
        )}
        <div className="action">
          <ButtonCustomize onClick={handleClose}>Discard</ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
            style={{ marginLeft: "10px" }}
          >
            Save
          </ButtonCustomize>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default VendorFormModal;
const Wrapper = styled.div`
  display: flex;
  width: 480px;
  flex-direction: column;
  padding: 15px;
  .action {
    display: flex;
    justify-content: flex-end;
  }
`;
