/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import { Autocomplete } from "@material-ui/lab";

import { useHistory } from "react-router-dom";

import { BreadCrumbsTitle, Box, Input, ButtonCustomize } from "../../commons";
import vector from "../../img/Vector.png";
import { Wrapper, Note } from "./ClientsGroupFormStyle";
import { ClientsGroupImg } from "../../img";
import {
  GetListCustomersQuery,
  GetListCustomersQueryVariables,
  CreateMenuGroupMutation,
  CreateMenuGroupMutationVariables,
  CustomerPayload,
} from "../../api/types";

import { GET_LIST_CUSTOMERS, CREATECUSTOMERSGROUP } from "../../api";
import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../api/local-state";

const ClientsGroupForm: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const token = localStorage.getItem("token") || "";

  const { data: localMenu, loading: loadingM } =
    useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const [groupName, setGroupName] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<CustomerPayload[]>([]);

  const { data: listClients, loading } = useQuery<
    GetListCustomersQuery,
    GetListCustomersQueryVariables
  >(GET_LIST_CUSTOMERS, {
    variables: {
      input: {
        token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
    fetchPolicy: "network-only",
  });

  const [createGroup, { loading: loadingCreate }] = useMutation<
    CreateMenuGroupMutation,
    CreateMenuGroupMutationVariables
  >(CREATECUSTOMERSGROUP, {
    onCompleted: () => {
      history.push("/group");
    },
  });

  const submitAddGroup = () => {
    createGroup({
      variables: {
        input: {
          token,
          menu: localMenu?.menuItem?.id || "",
          names: [
            {
              languageCode: "EN",
              value: groupName,
            },
          ],
          descriptions: [
            {
              languageCode: null,
              value: null,
            },
          ],
          customers: selectedValues.map((item) => {
            return {
              customer: item.id,
            };
          }),
        },
      },
    });
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({ id: "mainLayout.clients" }),
            intl.formatMessage({
              id: "store.customer.addGroupClient.formCreateGroup",
            }),
          ]}
          icon={ClientsGroupImg}
          iconAlt="group-clients"
        />
        <div className="action">
          <ButtonCustomize
            variant="contained"
            onClick={submitAddGroup}
            color="primary"
            className="btn-action"
            disabled={loadingCreate}
          >
            {intl.formatMessage({ id: "store.customerForm.save" })}
          </ButtonCustomize>
        </div>
      </Note>
      <Box width={1} className="container">
        <div className="vector">
          <img src={vector} alt="vector" className="img-vector" />
          <img
            src={ClientsGroupImg}
            alt="apparence"
            className="img-apparence"
          />
        </div>
        <span className="title">
          {intl.formatMessage({
            id: "store.customer.addGroupClient.formCreateGroup",
          })}
        </span>
        <div className="form">
          <Input
            required
            value={groupName}
            onChange={(event) => {
              setGroupName(event.target.value);
            }}
            label={intl.formatMessage({
              id: "store.customer.group.List.name",
            })}
            style={{ marginBottom: 20 }}
          />
          <Autocomplete
            disableClearable
            disableCloseOnSelect
            onChange={(event, newValue) => {
              setSelectedValues(newValue);
            }}
            options={listClients?.getListCustomers.list || []}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            fullWidth
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "store.customer.group.List",
                })}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={false}
          />
        </div>
      </Box>
    </Wrapper>
  );
};

export default ClientsGroupForm;
