import { createStyles, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    .arrow-back {
      width: 100px;
      color: #1f90cf;
    }
  }
`;

export const useStyles = makeStyles(() =>
  createStyles({
    input: {
      maxWidth: 600,
      marginBottom: 16,
    },
    lastInput: {
      maxWidth: 600,
    },
  })
);
