import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import { useQuery, useReactiveVar, useMutation } from "@apollo/client";
import indexOf from "lodash/indexOf";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { Search } from "../../../components";

import {
  GET_LIST_MENU_TYPES,
  GET_LIST_PRODUCT_ATTRIBUTES,
  DELETE_PRODUCT_ATTRIBUTE,
} from "../../../api";
import {
  GetListProductAttributesQuery,
  GetListProductAttributesQueryVariables,
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  DeleteProductAttributeMutation,
  DeleteProductAttributeMutationVariables,
  MenuPayload,
  ProductAttributePayload,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

import Content from "../../../img/digishop/menu-icons/content.svg";

import { Wrapper, Note } from "./Readers--styles";

const Readers = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const history = useHistory();

  const [listReaders, setListReaders] = React.useState<
    ProductAttributePayload[]
  >([]);
  const [getReader, setGetReader] = React.useState<ProductAttributePayload>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selectReaders, setSelectReaders] = React.useState<string[]>([]);

  const { data } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const menuTypes = data?.getListMenuTypes?.list;

  const idTypeBooks =
    menuTypes?.find((o) => o.identifier === "BOOKS")?.id || "";

  const { data: dataReaders } = useQuery<
    GetListProductAttributesQuery,
    GetListProductAttributesQueryVariables
  >(GET_LIST_PRODUCT_ATTRIBUTES, {
    variables: {
      input: {
        token,
        identifier: "READERS",
        menuType: idTypeBooks,
      },
    },
  });

  React.useEffect(() => {
    const results = dataReaders?.getListProductAttributes?.list?.filter(
      (reader) => reader?.name?.value?.toLowerCase()?.includes(searchTerm)
    );
    if (results) {
      setListReaders(results);
      setRowsPerPage(10);
      setPage(0);
    } else {
      setListReaders(dataReaders?.getListProductAttributes?.list || []);
    }
  }, [searchTerm, dataReaders]);

  const [deleteReader, { loading: loadingDelete }] = useMutation<
    DeleteProductAttributeMutation,
    DeleteProductAttributeMutationVariables
  >(DELETE_PRODUCT_ATTRIBUTE, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successfully",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_LIST_PRODUCT_ATTRIBUTES,
        variables: {
          input: {
            token,
            identifier: "READERS",
            menuType: idTypeBooks,
          },
        },
      },
    ],
  });

  const columns: ColumnsProps = [
    {
      header: (
        <Checkbox
          inputProps={{ "aria-label": "primary checkbox" }}
          checked={
            listReaders.length === selectReaders.length &&
            listReaders.length > 0
          }
          onClick={(e) => {
            e.stopPropagation();
            selectAllReaders();
          }}
        />
      ),
      accessor: "checkbox",
      cellProps: { align: "center", width: "40px" },
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listReaders
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item) => ({
          id: item.id,
          checkbox: (
            <Checkbox
              inputProps={{ "aria-label": "primary checkbox" }}
              checked={indexOf(selectReaders, item.id) !== -1}
              onClick={(e) => {
                e.stopPropagation();
                item?.id && SelectReader(item.id);
              }}
            />
          ),
          name: item.name?.value,
          actions: (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setGetReader(item);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectAllReaders = () => {
    const arraySelectAllReaders: string[] = [];
    if (selectReaders.length < listReaders.length) {
      listReaders?.map(
        (item) => item?.id && arraySelectAllReaders.push(item.id)
      );
    }
    setSelectReaders(arraySelectAllReaders);
  };

  const SelectReader = (Reader: string) => {
    setSelectReaders((prevSelectReader) => {
      const arraySelectReader = [...prevSelectReader];
      const position = indexOf(arraySelectReader, Reader);
      if (position === -1) {
        arraySelectReader.push(Reader);
      } else {
        arraySelectReader.splice(position, 1);
      }
      return arraySelectReader;
    });
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Content management", "Readers"]}
          icon={Content}
          iconAlt="Readers"
        />
        <Link to="/add-readers">
          <ButtonCustomize variant="contained" color="secondary">
            <AddIcon />
            &nbsp; Add new readers
          </ButtonCustomize>
        </Link>
      </Note>
      <Box component={Paper} p={2} borderRadius={10}>
        <Search
          value={searchTerm}
          onSearch={(value) => {
            setSearchTerm(value);
          }}
          style={{ marginBottom: 15 }}
        />
        <Box flex="1" marginTop={3}>
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="No data available"
            tablePaginationProps={{
              labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
              count: listReaders?.length,
              page,
              onPageChange: (_, newPage) => setPage(newPage),
              rowsPerPage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
            }}
          />
        </Box>
      </Box>
      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            history.push({
              pathname: "/edit-readers",
              state: getReader,
            });
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
          style={{ color: "#ff4949" }}
        >
          Delete
        </MenuItem>
      </Menu>
      <Modal
        open={open}
        title="Delete Reader ?"
        message="Are you sure you want to remove this reader ?"
        handleClose={() => setOpen(false)}
        handleContent={() => {
          deleteReader({
            variables: {
              input: {
                token,
                id: getReader?.id || "",
              },
            },
          });
          setOpen(false);
        }}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default Readers;
