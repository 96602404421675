import styled from "styled-components";

export type TreeType = {
  open: boolean;
  level: number;
};

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minMax(260px, 310px) 1fr;
  grid-gap: 15px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    border-bottom: 2px solid #f3f4fa;
    button {
      margin-left: 16px;
    }
  }
  .content {
    height: calc(100vh - 180px);
    overflow-y: overlay;
    position: relative;
  }
  .products {
    padding: 10px 8px 0 8px;
  }
  .menu {
    position: relative;
  }
  hr {
    margin: 5px;
    border: 0;
    border-top: 1px solid #ebebeb;
  }
  .top-bar-actions {
    display: flex;
  }
  .MuiButton-label {
    display: block;
    font-size: 14px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  .search {
    display: inline-grid;
    grid-template-columns: 1fr 35px;
    grid-gap: 5px;
    float: right;
    padding: 3px;
    border: 1px solid #ebebeb;
    border-radius: 7px;
  }
  input.search-input {
    width: 200px;
    padding-left: 10px;
    border: 0;
  }
  button.search-btn {
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 5px;
    background: #f29f05;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    svg path {
      fill: white;
    }
  }
`;

export const EmptyState = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  color: #d1d1d2;
  font-size: 14px;
`;

export const Menu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: auto;
  border: 0;
  background-color: white;
  border-radius: 20px;
  color: #bdbdbd;
  cursor: pointer;
  :hover {
    background-color: #f1f2f5;
  }
`;
