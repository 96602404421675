import { Errors } from "./CustomersForm";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const customerFormValidation = (
  email?: string,
  phoneNumber?: string
): Errors => {
  const errors: Errors = {};
  if (!email) {
    errors.email = "form.fieldRequired";
  } else if (!email.match(emailRegex)) {
    errors.email = "form.invalidEmail";
  }
  if (!phoneNumber) errors.phoneNumber = "form.fieldRequired";
  return errors;
};
