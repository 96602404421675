import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .book__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
`;

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;
