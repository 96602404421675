import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useReactiveVar, useMutation } from "@apollo/client";
import indexOf from "lodash/indexOf";
import find from "lodash/find";

import { ButtonCustomize, SearchBasic, TablePagination } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import {
  GET_LIST_MENU_SIMILAR_PRODUCTS,
  SET_SIMILAR_PRODUCTS,
} from "../../api";
import {
  GetListMenuSimilarProductsQuery,
  GetListMenuSimilarProductsQueryVariables,
  SetSimilarProductsMutation,
  SetSimilarProductsMutationVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

type AssignProductsModalProps = {
  open: boolean;
  onClose: () => void;
};

const AssignProductsModal: React.FC<AssignProductsModalProps> = ({
  open,
  onClose,
}) => {
  const token = localStorage.getItem("token") || "";
  const { id: idProduct } = useParams<{ id?: string }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [nameProduct, setNameProduct] = React.useState<string | undefined>(
    undefined
  );
  const [assignProducts, setAssignProducts] = React.useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const { data: listProducts, refetch } = useQuery<
    GetListMenuSimilarProductsQuery,
    GetListMenuSimilarProductsQueryVariables
  >(GET_LIST_MENU_SIMILAR_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        product: idProduct || "",
        name: nameProduct,
      },
    },
    skip: !(localMenu?.id && token && idProduct),
  });

  const lengthListProduct =
    listProducts?.getListMenuSimilarProducts?.list?.length || 0;

  React.useEffect(() => {
    const arrayIdProducts: string[] = [];
    if (listProducts) {
      listProducts?.getListMenuSimilarProducts?.list?.forEach((product) => {
        if (product.isSimilar) {
          arrayIdProducts.push(product.id!);
        }
      });
      setAssignProducts(arrayIdProducts);
    }
  }, [listProducts]);

  const [SetSimilar, { loading }] = useMutation<
    SetSimilarProductsMutation,
    SetSimilarProductsMutationVariables
  >(SET_SIMILAR_PRODUCTS, {
    onCompleted: () => {
      onClose();
      refetch();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: (error) => {
      if (error.message === "product_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Product not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
    refetchQueries: () => [
      {
        query: GET_LIST_MENU_SIMILAR_PRODUCTS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
            product: idProduct || "",
            isSimilar: true,
          },
        },
      },
    ],
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAllSelectProducts = () => {
    const arrayAllSelectProducts: string[] = [];
    if (assignProducts.length < lengthListProduct)
      listProducts?.getListMenuSimilarProducts?.list?.map((product) =>
        arrayAllSelectProducts.push(product.id!)
      );
    setAssignProducts(arrayAllSelectProducts);
  };

  const handleAssign = (id: string) => {
    setAssignProducts((prevAssignProducts) => {
      const arrayAssignProducts = [...prevAssignProducts];
      const position = indexOf(assignProducts, id);
      if (position !== -1) {
        arrayAssignProducts.splice(position, 1);
      } else {
        arrayAssignProducts.push(id);
      }
      return arrayAssignProducts;
    });
  };

  const columns: ColumnsProps = [
    {
      header: (
        <Checkbox
          checked={lengthListProduct === assignProducts.length}
          inputProps={{ "aria-label": "primary checkbox" }}
          onClick={(e) => {
            e.stopPropagation();
            handleAllSelectProducts();
          }}
        />
      ),
      accessor: "checkbox",
      cellProps: { align: "center", width: "30px" },
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Category",
      accessor: "category",
    },
    {
      header: "SKU",
      accessor: "sku",
    },
  ];

  const renderTableRows = () => {
    return (
      listProducts?.getListMenuSimilarProducts?.list
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((product) => ({
          id: product?.id,
          checkbox: (
            <Checkbox
              checked={indexOf(assignProducts, product.id) !== -1}
              onChange={() => handleAssign(product.id!)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          ),
          name: find(
            product.names,
            (o) => o.languageCode === languageIsMain?.code
          )?.value,
          category: find(
            product?.category?.names,
            (o) => o.languageCode === languageIsMain?.code
          )?.value,
          sku: product.sku,
        })) || []
    );
  };

  const handleSubmit = () => {
    SetSimilar({
      variables: {
        input: {
          token,
          product: idProduct || "",
          count: assignProducts.length,
          products: assignProducts,
          isRandom: false,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box display="flex" width={1} flexDirection="column" minWidth={600}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Assign related products
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <SearchBasic
            value={nameProduct}
            onChange={(value) => setNameProduct(value)}
          />
          <div style={{ maxHeight: 610, overflowX: "auto" }}>
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="List products is empty"
            />
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingLeft={1}
          >
            <Typography variant="body1">
              {assignProducts.length} Items selected
            </Typography>
            <TablePagination
              labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
              count={
                listProducts?.getListMenuSimilarProducts?.list?.length || 0
              }
              page={page}
              onPageChange={(_, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Assign
          </ButtonCustomize>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AssignProductsModal;
