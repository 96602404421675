import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import { useQuery, useReactiveVar, useMutation } from "@apollo/client";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Select,
} from "../../../commons";

import {
  GETLISTCURRENCIES,
  GETLISTCOUNTRIES,
  SET_REDEEM_POINTS_CONFIG,
  GET_LIST_REDEEM_CONFIG,
} from "../../../api";
import {
  GetListCurrenciesQuery,
  GetListCurrenciesQueryVariables,
  GetListCountriesQuery,
  GetListCountriesQueryVariables,
  SetRedeemPointsConfigMutation,
  SetRedeemPointsConfigMutationVariables,
  MenuPayload,
  MenuRedeemPointsConfigPayload,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

import { Wrapper } from "./Country&CurrencyForm--style";

type FormState = {
  country?: string;
  currency?: string;
  balancePoints?: {
    cash?: string;
    points?: string;
  };
  conversionPoints?: {
    cash?: string;
    points?: string;
  };
  isEnabled?: boolean;
};

type MenuRedeemPointsConfigLocation = MenuRedeemPointsConfigPayload & {
  isRequired: boolean;
};

// List ids country to validate by forus
const ListIdCountryByForus = [
  "5cebda2636e7950bc368dc3c",
  "5cebda2636e7950bc368db8c",
];

const CountryAndCurrencyForm = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const dataDetails = useLocation<MenuRedeemPointsConfigLocation>().state;

  const [state, setState] = React.useState<FormState>();

  const { data: listCountries } = useQuery<
    GetListCountriesQuery,
    GetListCountriesQueryVariables
  >(GETLISTCOUNTRIES);

  const { data: listCurencies } = useQuery<
    GetListCurrenciesQuery,
    GetListCurrenciesQueryVariables
  >(GETLISTCURRENCIES);

  React.useEffect(() => {
    if (dataDetails) {
      setState({
        country: dataDetails.country.id,
        currency: dataDetails.currency.id,
        balancePoints: {
          cash: dataDetails?.balancePoints?.cash
            ? String(dataDetails?.balancePoints?.cash)
            : undefined,
          points: dataDetails?.balancePoints?.points
            ? String(dataDetails.balancePoints.points)
            : undefined,
        },
        conversionPoints: {
          cash: dataDetails?.conversionPoints?.cash
            ? String(dataDetails?.conversionPoints?.cash)
            : undefined,
          points: dataDetails?.conversionPoints?.points
            ? String(dataDetails.conversionPoints.points)
            : undefined,
        },
        isEnabled: dataDetails?.isEnabled,
      });
    }
  }, [dataDetails]);

  const [handleSet, { loading }] = useMutation<
    SetRedeemPointsConfigMutation,
    SetRedeemPointsConfigMutationVariables
  >(SET_REDEEM_POINTS_CONFIG, {
    refetchQueries: () => [
      {
        query: GET_LIST_REDEEM_CONFIG,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      history.push("/country-currency");
    },
  });

  const onKeyPressForFloat = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const onKeyPressForNumber = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleBalance = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({
      ...state,
      balancePoints: {
        ...state?.balancePoints,
        [name]: value,
      },
    });
  };
  const handleConvertion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({
      ...state,
      conversionPoints: {
        ...state?.conversionPoints,
        [name]: value,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setState({ ...state, [name]: checked });
  };

  const handleSave = () => {
    if (dataDetails?.isRequired && !state?.isEnabled) {
      snackBarVar({
        open: true,
        severity: "error",
        message: "You need to enable at least one country & currency type",
      });
    } else if (
      state?.country &&
      state?.currency &&
      state?.balancePoints &&
      state?.conversionPoints
    ) {
      handleSet({
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
            country: state.country,
            currency: state.currency,
            balancePoints: {
              cash: parseFloat(state?.balancePoints?.cash || ""),
              points: parseInt(state?.balancePoints?.points || "", 10),
            },
            conversionPoints: {
              cash: parseFloat(state?.conversionPoints?.cash || ""),
              points: parseInt(state.conversionPoints.points || "", 10),
            },
            isEnabled: state?.isEnabled || false,
          },
        },
      });
    }
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "Country & Currency"]}
        iconAlt="products"
      />

      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Link to="/country-currency">
          <ButtonCustomize style={{ color: "#0052b4" }}>
            <ArrowBackIcon />
            &nbsp; Return
          </ButtonCustomize>
        </Link>
        <ButtonCustomize
          variant="contained"
          color="secondary"
          onClick={handleSave}
          disabled={loading}
        >
          Save
        </ButtonCustomize>
      </Box>
      <Box component={Paper} width={1} p={2} marginTop={1}>
        <div className="circle-1">
          <div className="circle-2">
            <div className="circle-3">
              <div className="circle-4">
                <AttachMoneyIcon />
              </div>
            </div>
          </div>
        </div>
        <Typography variant="h2" align="center">
          Add new country & currency
        </Typography>
        <Box maxWidth={900} margin="30px auto 20px auto">
          <Box display="flex">
            <Select
              label="Country"
              value={state?.country || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  country: e.target.value as string,
                })
              }
              style={{ maxWidth: 440 }}
            >
              {listCountries?.getListCountries?.list
                ?.filter((item) => ListIdCountryByForus.includes(item.id!))
                ?.map((country) => (
                  <MenuItem value={country.id!} key={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
            </Select>
            <Select
              label="Currency"
              value={state?.currency || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  currency: e.target.value as string,
                })
              }
            >
              {listCurencies?.getListCurrencies?.list?.map((currency) => (
                <MenuItem value={currency.id!} key={currency.id}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" marginTop={2}>
            <Input
              name="cash"
              label="Balance money"
              value={state?.balancePoints?.cash || ""}
              onChange={handleBalance}
              onKeyPress={onKeyPressForFloat}
              style={{ maxWidth: 440, marginRight: 11 }}
            />
            <Input
              name="points"
              label="Balance points"
              value={state?.balancePoints?.points || ""}
              onKeyPress={onKeyPressForNumber}
              onChange={handleBalance}
            />
          </Box>
          <Box display="flex" marginTop={2} marginBottom={2}>
            <Input
              name="cash"
              label="Convertion money"
              value={state?.conversionPoints?.cash || ""}
              onChange={handleConvertion}
              onKeyPress={onKeyPressForFloat}
              style={{ maxWidth: 440, marginRight: 11 }}
            />
            <Input
              name="points"
              label="Convertion points"
              value={state?.conversionPoints?.points}
              onKeyPress={onKeyPressForNumber}
              onChange={handleConvertion}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.isEnabled || false}
                onChange={handleChange}
                name="isEnabled"
                color="secondary"
              />
            }
            label="Enable country & currency ?"
          />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CountryAndCurrencyForm;
