import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efecec;
  img {
    width: 71px;
    height: 71px;
    object-fit: cover;
  }
`;

type ColorInputType = {
  color: string;
};

export const ColorInput = styled.div<ColorInputType>`
  width: 20px;
  height: 20px;
  border: 2px solid #f1f2fb;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;
