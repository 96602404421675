import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import { FilledInputProps } from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputBase from "@material-ui/core/InputBase";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type InputPhoneProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  dataTestId?: string;
  required?: boolean;
  countryInputProps: PhoneInputProps;
};

const InputPhone = React.forwardRef<HTMLInputElement, InputPhoneProps>(
  (
    { label, msgError, required, countryInputProps, onChange, ...restProps },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const numberRegex = /^[0-9]{1,12}$/;
      const { value } = e.target;
      if (value && !numberRegex.test(value)) return;
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <FormControlStyled
        variant="filled"
        error={msgError !== "" && msgError !== undefined}
        required={required || false}
      >
        <InputLabel className="label" htmlFor="component-filled" shrink>
          {label}
        </InputLabel>

        <Box display="flex" width={1}>
          <PhoneInput
            {...countryInputProps}
            inputProps={{
              disabled: true,
              className: "input__phone",
              ...countryInputProps.inputProps,
            }}
          />
          <InputBase
            ref={ref}
            inputProps={{
              className: "mainInput",
              maxlength: 12,
            }}
            {...restProps}
            onChange={handleChange}
          />
        </Box>
        {msgError && <FormHelperText> {msgError} </FormHelperText>}
      </FormControlStyled>
    );
  }
);

const FormControlStyled = styled(FormControl)`
  width: 100%;
  .label {
    margin-left: 35px;
  }
  .mainInput {
    width: 100%;
    overflow: "hidden";
    /* padding: 27px 10px 7px 10px; */
    padding: 27px 12px 10px 78px;
    background-color: #ffffff;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus {
      border: 2px solid #000000; /*74abff */
      border-radius: 5px;
    }
  }
  .Mui-error {
    .mainInput {
      border: 2px solid #f67070;
    }
  }
  .react-tel-input {
    position: absolute;
    bottom: top;
    left: 0;
    height: 100%;
    z-index: 1;
    width: 88px;
    height: 58px;
    .flag-dropdown {
      border: transparent;
      padding: 2px;
      background-clip: content-box;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
    .input__phone {
      width: 100%;
      height: 100%;
      padding-left: 44px;
      padding-top: 27px;
      padding-bottom: 10px;
      border: none;
      background: transparent;
      cursor: unset !important;
    }
  }
`;

export default InputPhone;
