import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

type BreadcrumbsArray = {
  link: string;
  value: string;
}[];
const Breadcrumbs: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();

  const getBreadcrumbs = (): BreadcrumbsArray => {
    switch (history.location.pathname) {
      case "/menus":
        return [
          {
            link: "menus",
            value: intl.formatMessage({ id: "Menus.subtitle" }),
          },
        ];
      case "/":
        return [
          { link: "", value: intl.formatMessage({ id: "Dashboard.subtitle" }) },
        ];
      case "/items":
        return [
          { link: "menus", value: "Menu" },
          { link: "items", value: intl.formatMessage({ id: "Menus.manage" }) },
        ];
      case "/add-menu":
        return [
          { link: "menus", value: "Menu" },
          {
            link: "add-menu",
            value: intl.formatMessage({ id: "Menus.add" }),
          },
        ];
      case "/update-menu":
        return [
          { link: "menus", value: "Menu" },
          {
            link: "update-menu",
            value: intl.formatMessage({ id: "Menus.update" }),
          },
        ];
      case "/category":
        return [
          { link: "menus", value: "Menu" },
          { link: "items", value: intl.formatMessage({ id: "Menus.manage" }) },
          {
            link: "category",
            value: intl.formatMessage({ id: "Category.title" }),
          },
        ];
      case "/update-category":
        return [
          { link: "menus", value: "Menu" },
          { link: "items", value: intl.formatMessage({ id: "Menus.manage" }) },
          {
            link: "category",
            value: intl.formatMessage({ id: "Category.title" }),
          },
        ];
      case "/product":
        return [
          { link: "menus", value: "Menu" },
          { link: "items", value: intl.formatMessage({ id: "Menus.manage" }) },
          {
            link: "product",
            value: intl.formatMessage({ id: "Products.single" }),
          },
        ];
      case "/promotions":
        return [
          {
            link: "promotions",
            value: intl.formatMessage({ id: "Ads.list" }),
          },
        ];
      case "/promotion":
        return [
          {
            link: "promotions",
            value: intl.formatMessage({ id: "Ads.title" }),
          },
          { link: "promotion", value: intl.formatMessage({ id: "Ads.add" }) },
        ];
      case "/stores":
        return [
          { link: "stores", value: intl.formatMessage({ id: "Ads.title" }) },
        ];
      case "/settings":
        return [
          {
            link: "settings",
            value: intl.formatMessage({ id: "Settings.title" }),
          },
        ];
      case "/style":
        return [
          { link: "menus", value: "Menu" },
          {
            link: "style",
            value: intl.formatMessage({ id: "MenuStyle.title" }),
          },
        ];
      case "/orders":
        return [
          {
            link: "orders",
            value: intl.formatMessage({ id: "Orders.list" }),
          },
        ];
      case "/order-history":
        return [
          {
            link: "orders",
            value: intl.formatMessage({ id: "Orders.listOfHistory" }),
          },
        ];
      default:
        return [
          { link: "", value: intl.formatMessage({ id: "Dashboard.subtitle" }) },
        ];
    }
  };

  const links = getBreadcrumbs();

  return (
    <Wrapper>
      {links !== undefined &&
        links.map((link, index) => {
          return (
            <Crumbs key={`key-${link.link}-${link.value}`}>
              {index !== 0 && (
                <FontAwesomeIcon
                  style={{
                    transform: "rotate(-90deg",
                    marginRight: "10px",
                    marginLeft: "5px",
                  }}
                  icon={faSortDown}
                  color="#00004e"
                />
              )}
              {history.location.pathname !== `/${link.link}` ? (
                <Link to={`/${link.link}`} className="link">
                  <span className="breadCrumb">{link.value}</span>
                </Link>
              ) : (
                <span className="breadCrumb">{link.value}</span>
              )}
            </Crumbs>
          );
        })}
    </Wrapper>
  );
};

const Crumbs = styled.div`
  span.link {
    text-decoration: none;
    color: #00004e;
    margin: 0;
    font-size: 13px;
  }
  span.link:hover {
    text-decoration: underline;
  }
  span.breadCrumb {
    margin-right: 5px;
    color: #00004e;
    font-size: 14px;
    font-weight: 600;
  }
  span.breadCrumb::first-letter {
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div::first-letter {
    text-transform: uppercase;
  }
  margin-left: 15px;
`;

export default Breadcrumbs;
