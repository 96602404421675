import React from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  handlePaymentOrder,
  handleStatusOrder,
} from "../OrderCard/OrderCard--utils";
import { MenuOrderPayload } from "../../api/types";
import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../api/local-state";

import { Print, Separation, ColorInput } from "./OrderToPrint--styles";

type OrderToPrintProps = {
  menuOrder?: MenuOrderPayload;
};

const OrderToPrint = React.forwardRef<HTMLDivElement, OrderToPrintProps>(
  (props, ref) => {
    const { menuOrder } = props;

    const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

    return (
      <div style={{ display: "none", width: "100%" }}>
        <Print ref={ref}>
          <Box display="flex" width={1} justifyContent="space-between">
            <Typography variant="subtitle2">
              Order #{menuOrder?.reference}
            </Typography>
            <Typography variant="subtitle2">{moment().format("L")}</Typography>
          </Box>
          <Box paddingLeft={5} paddingRight={5} marginTop={5}>
            <Typography
              variant="h2"
              style={{ fontWeight: 700, marginBottom: 10 }}
            >
              {localMenu?.menuItem?.names?.[0]?.value}{" "}
            </Typography>
            <Typography
              variant="h2"
              style={{ fontWeight: 700, marginBottom: 10 }}
            >
              {moment(menuOrder?.createdAt).format("lll")}
            </Typography>
            <Box display="flex" width={1}>
              <Box flex="1">
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "capitalize" }}
                >
                  {menuOrder?.customer?.firstName} &nbsp;{" "}
                  {menuOrder?.customer?.lastName}
                </Typography>
                {menuOrder?.deliveryAddress?.primaryPhone?.number && (
                  <Typography variant="subtitle1">
                    {menuOrder.deliveryAddress.primaryPhone?.countryCode} &nbsp;
                    {menuOrder.deliveryAddress.primaryPhone.number}
                  </Typography>
                )}
                <Typography variant="subtitle1">
                  {menuOrder?.customer?.email}
                </Typography>
              </Box>
              <Box flex="1">
                <Typography variant="subtitle1">
                  {menuOrder?.deliveryAddress?.address?.building} &nbsp;
                  {menuOrder?.deliveryAddress?.address?.floor} &nbsp;
                  {menuOrder?.deliveryAddress?.address?.route?.number} &nbsp;
                  {menuOrder?.deliveryAddress?.address?.route?.kind} &nbsp;
                  {menuOrder?.deliveryAddress?.address?.route?.name}
                </Typography>
                <Typography variant="subtitle1">
                  {menuOrder?.deliveryAddress?.address?.city?.name} &nbsp;
                  {menuOrder?.deliveryAddress?.address?.postalCode}
                </Typography>
                <Typography variant="subtitle1">
                  {menuOrder?.deliveryAddress?.address?.country?.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  Delivery&nbsp;:&nbsp;
                  {handleStatusOrder(menuOrder?.paymentStatus || "")}
                </Typography>
                <Typography variant="subtitle1">
                  Payment status &nbsp;:&nbsp;
                  {handlePaymentOrder(menuOrder?.orderStatus || "")}
                </Typography>
              </Box>
            </Box>
            <Separation />
            {menuOrder?.cartDetails?.items?.map((item) => (
              <Box display="flex" width={1} marginTop={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  key={item.id || ""}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700, marginBottom: 5 }}
                  >
                    {item?.product?.names?.[0]?.value}
                  </Typography>
                  {item?.options?.map((option) => (
                    <Box display="flex">
                      <Typography variant="subtitle1">
                        {option?.option?.names?.[0]?.value} &nbsp;:
                      </Typography>
                      <Typography variant="subtitle1" style={{ marginLeft: 5 }}>
                        {option?.value?.names?.[0]?.value?.indexOf("#") ===
                        -1 ? (
                          option?.value?.names?.[0]?.value
                        ) : (
                          <ColorInput
                            color={option?.value?.names?.[0]?.value || ""}
                          />
                        )}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box display="flex" width={1}>
                  <Typography
                    variant="subtitle1"
                    style={{ margin: "0 30px 0 auto" }}
                  >
                    X {item?.quantity}
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginLeft: 5 }}>
                    {item?.unitPrice?.value} {item?.unitPrice?.currency?.code}
                  </Typography>
                </Box>
              </Box>
            ))}
            <Separation />
            <Box
              display="flex"
              width={1}
              flexDirection="column"
              alignItems="flex-end"
            >
              <div>
                <Typography variant="subtitle1">
                  Items &nbsp;: &nbsp;{" "}
                  {menuOrder?.cartDetails?.originalTotalPrice?.value}{" "}
                  {menuOrder?.cartDetails?.originalTotalPrice?.currency?.code}
                </Typography>
                <Typography variant="subtitle1">
                  Shipping &nbsp;: &nbsp; {menuOrder?.deliveredCost}
                </Typography>
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                  Total &nbsp;: &nbsp;{" "}
                  {menuOrder?.cartDetails?.totalPrice?.value}{" "}
                  {menuOrder?.cartDetails?.totalPrice?.currency?.code}
                </Typography>
              </div>
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            style={{ margin: "auto auto 0 auto" }}
          >
            Thank you for your order
          </Typography>
        </Print>
      </div>
    );
  }
);

export default OrderToPrint;
