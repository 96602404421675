import gql from "graphql-tag";

export const CREATE_MENU_STAFF = gql`
  mutation CreateMenuStaff($input: CreateMenuStaffInput!) {
    createMenuStaff(input: $input) {
      id
    }
  }
`;

export const DELETE_MENU_STAFF = gql`
  mutation DeleteMenuStaff($input: DeleteMenuStaffInput!) {
    deleteMenuStaff(input: $input) {
      success
    }
  }
`;

export const GET_MENU_STAFF = gql`
  query GetMenuStaff($input: GetMenuStaffInput!) {
    getMenuStaff(input: $input) {
      list {
        id
        email
        informations {
          firstName
          lastName
          professional {
            name
            logo {
              id
              fileUrl
            }
          }
        }
        role {
          id
          kind
        }
      }
    }
  }
`;

export const UPDATE_MENU_STAFF_ROLE = gql`
  mutation UpdateMenuStaffRole($input: UpdateMenuStaffRoleInput!) {
    updateMenuStaffRole(input: $input) {
      success
    }
  }
`;
