/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Dialog,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  Radio,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
// import local
import { Wrapper } from "./OptionFormModalStyle";
import { Input, Select, InputColorTags } from "../../commons";
import { UploadPictureVaraint } from "..";
import {
  ADDPRODUCTOPTION,
  GETLISTPRODUCTOPTIONS,
  EDITPRODUCTOPTION,
  GETLISTCOMPANYSITES,
  GETLISTPRODUCTVARIANTS,
} from "../../api";
import {
  CreateProductOptionValueInput,
  AddProductOptionMutation,
  AddProductOptionMutationVariables,
  EditProductOptionMutation,
  EditProductOptionMutationVariables,
  GetListCompanySitesQuery,
  GetListCompanySitesQueryVariables,
  CreateNameInput,
  UpdateNameInput,
  InputKind,
  CopiedPictureInput,
  ProductOptionPayload,
  LanguageCode,
  UpdateProductOptionValueInput,
  ProductOptionSiteInput,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

type OptionModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  productOptionPayload?: ProductOptionPayload;
  hasMultiAddress: boolean;
};

const OptionFormModal: React.FC<OptionModalProps> = ({
  open,
  title,
  handleClose,
  productOptionPayload,
  hasMultiAddress,
}) => {
  const token = localStorage.getItem("token") || "";
  const { id } = useParams<{ id?: string }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [names, setNames] = useState<UpdateNameInput[]>([]);
  const [inputKind, setInputKind] = useState<InputKind>("RADIO");
  const [requiredItems, setRequiredItems] = useState<number>(1);
  const [arrayValues, setArrayValues] = useState<
    UpdateProductOptionValueInput[]
  >([]);
  const [hasDefault, setHasDefault] = useState(false);
  const [priceDifference, setPriceDifference] = useState<string[]>([]);
  const [sign, setSign] = useState<string[]>([]);

  const handleArrayValues = () => {
    setArrayValues((prevArrayValues) => {
      const listValues = [...prevArrayValues];
      let isDefault = false;
      if (listValues.length === 0) isDefault = true;
      listValues.push({
        id: null,
        names: undefined,
        picture: undefined,
        copiedPictures: undefined,
        priceDifference: 0.0,
        isDefault,
        sites: [],
      });
      return listValues;
    });
    setPriceDifference((prevPrice) => {
      const arrayPrice = [...prevPrice];
      arrayPrice.push("0.0");
      return arrayPrice;
    });
    setSign((prevSign) => {
      const arraySign = [...prevSign];
      arraySign.push("+");
      return arraySign;
    });
  };

  const handleDeltte = (index: number) => {
    setArrayValues((prevArrayValues) => {
      const listValues = [...prevArrayValues];
      listValues.splice(index, 1);
      return listValues;
    });
  };

  const { data: listCompanySite } = useQuery<
    GetListCompanySitesQuery,
    GetListCompanySitesQueryVariables
  >(GETLISTCOMPANYSITES, {
    variables: {
      token,
      menu: localMenu?.id,
    },
    fetchPolicy: "network-only",
  });

  const [addProductOption, { loading }] = useMutation<
    AddProductOptionMutation,
    AddProductOptionMutationVariables
  >(ADDPRODUCTOPTION, {
    refetchQueries: () => [
      {
        query: GETLISTPRODUCTOPTIONS,
        variables: {
          input: {
            token,
            product: id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
      handleInitial();
    },
  });
  // mutation update product option
  const [updateOption] = useMutation<
    EditProductOptionMutation,
    EditProductOptionMutationVariables
  >(EDITPRODUCTOPTION, {
    refetchQueries: () => [
      {
        query: GETLISTPRODUCTOPTIONS,
        variables: {
          input: {
            token,
            product: id || "",
          },
        },
      },
      {
        query: GETLISTPRODUCTVARIANTS,
        variables: {
          input: {
            token,
            product: id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
      handleInitial();
    },
  });

  const handleValuesOption = (value: string, index: number) => {
    setArrayValues((prevArrayValues) => {
      const listValues = [...prevArrayValues];
      if (listValues?.length > 0 && index < listValues?.length) {
        const arrayNames = [...(listValues[index]?.names || [])];
        const position = findIndex(
          arrayNames,
          (o) => o.languageCode === languageIsMain?.code
        );
        if (position !== -1) {
          arrayNames[position] = {
            ...arrayNames[position],
            value,
          };
        } else {
          arrayNames.push({
            languageCode: languageIsMain?.code as LanguageCode,
            value,
          });
        }
        listValues[index] = {
          ...listValues[index],
          names: arrayNames,
        };
      }
      return listValues;
    });
  };

  const handleOnReturnPictures = (
    index: number,
    picture: string | null,
    copiedPictures: CopiedPictureInput[]
  ) => {
    setArrayValues((prevArrayValues) => {
      const listValues = [...prevArrayValues];
      if (listValues?.length > 0 && index < listValues?.length) {
        listValues[index] = {
          ...listValues[index],
          picture,
          copiedPictures,
        };
      }
      return listValues;
    });
  };

  useEffect(() => {
    const arrayNames: UpdateNameInput[] = [];
    const arrayValuesUpdate: UpdateProductOptionValueInput[] = [];
    const arrayPriceDifference: string[] = [];
    const arraySignPrice: string[] = [];

    productOptionPayload?.names?.map((name) =>
      arrayNames.push({
        id: name.id,
        languageCode: name.languageCode as LanguageCode,
        value: name.value,
      })
    );

    setNames(arrayNames);
    productOptionPayload?.inputKind &&
      setInputKind(productOptionPayload?.inputKind as InputKind);
    productOptionPayload?.requiredItems &&
      setRequiredItems(productOptionPayload.requiredItems);
    productOptionPayload?.hasDefault &&
      setHasDefault(productOptionPayload.hasDefault);

    productOptionPayload?.values?.forEach((value) => {
      const arrayNamesValues: UpdateNameInput[] = [];
      const arraySites: ProductOptionSiteInput[] = [];
      value.names?.map((name) =>
        arrayNamesValues.push({
          id: name.id,
          languageCode: name.languageCode as LanguageCode,
          value: name.value,
        })
      );
      value.sites?.forEach((site) => {
        arraySites.push({
          site: site.site?.id,
          priceDifference: site.priceDifference,
        });
      });
      arrayValuesUpdate.push({
        id: value.id,
        names: arrayNamesValues,
        picture: value.picture?.id,
        priceDifference: value.priceDifference,
        isDefault: value.isDefault,
        sites: arraySites,
      });
      value.priceDifference
        ? arrayPriceDifference.push(String(value.priceDifference))
        : arrayPriceDifference.push("0.0");
      value?.priceDifference && value?.priceDifference >= 0
        ? arraySignPrice.push("+")
        : arraySignPrice.push("-");
    });
    setArrayValues(arrayValuesUpdate);
    setPriceDifference(arrayPriceDifference);
    setSign(arraySignPrice);
  }, [productOptionPayload]);

  // useEffect(() => {
  //   if (!productOptionPayload?.id) {
  //     setNames([]);
  //     setInputKind("RADIO");
  //     setRequiredItems(1);
  //     setArrayValues([]);
  //     setHasDefault(false);
  //     setPriceDifference([]);
  //     setSign([]);
  //   }
  // }, [handleClose]);

  const handleInitial = () => {
    setNames([]);
    setInputKind("RADIO");
    setRequiredItems(1);
    setArrayValues([]);
    setHasDefault(false);
    setPriceDifference([]);
    setSign([]);
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayNames = [...names];
    const position = findIndex(
      arrayNames,
      (o) => o.languageCode === languageIsMain?.code
    );
    if (position !== -1) {
      arrayNames[position] = {
        ...arrayNames[position],
        value: event.target.value,
      };
    } else {
      arrayNames.push({
        languageCode: languageIsMain?.code as LanguageCode,
        value: event.target.value,
      });
    }
    setNames(arrayNames);
  };

  const handleSign = (
    signe: unknown,
    index: number,
    difference?: number | null
  ) => {
    setArrayValues((prevArrayValues) => {
      const listValues = [...prevArrayValues];
      if (listValues?.length > 0 && index < listValues?.length) {
        if (signe === "-" && difference) {
          listValues[index] = {
            ...listValues[index],
            priceDifference: -Math.abs(difference),
          };
        }
        if (difference) {
          listValues[index] = {
            ...listValues[index],
            priceDifference: Math.abs(difference),
          };
        }
      }
      return listValues;
    });
    setPriceDifference((prevPriceDifference) => {
      const arrayPriceDifference = [...prevPriceDifference];
      if (signe === "-" && difference) {
        arrayPriceDifference[index] = String(-Math.abs(difference));
      } else if (difference) {
        arrayPriceDifference[index] = String(Math.abs(difference));
      }

      return arrayPriceDifference;
    });
  };

  const handleRequiredItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setRequiredItems(parseInt(e.target.value, 10));
    if (e.target.value)
      setArrayValues((prevArrayValues) => {
        const listValues = prevArrayValues.map((value, valueIndex) => ({
          ...value,
          isDefault: valueIndex <= +e.target.value - 1,
        }));
        return listValues;
      });
  };

  const handleSignPrice = (index: number) => {
    setArrayValues((prevArrayValues) => {
      const listValues = [...prevArrayValues];
      if (listValues?.length > 0 && index < listValues?.length) {
        listValues[index] = {
          ...listValues[index],
          priceDifference:
            sign[index] === "-" && parseFloat(priceDifference[index]) > 0
              ? -Math.abs(parseFloat(priceDifference[index]))
              : parseFloat(priceDifference[index]),
        };
      }
      return listValues;
    });
  };

  const handleHasDefault = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasDefault(e.target.checked);
    setArrayValues((prevArrayValues) => {
      const listValues = prevArrayValues.map((value, index) => ({
        ...value,
        isDefault: index <= requiredItems - 1,
      }));
      return listValues;
    });
  };

  const handleSetDefault = (checked: boolean, index: number) => {
    if (inputKind !== "CHECKBOX") {
      setArrayValues((prevArrayValues) => {
        const listValues = prevArrayValues.map((optionValue, optionIndex) => ({
          ...optionValue,
          isDefault: optionIndex === index,
        }));
        return listValues;
      });
    } else {
      setArrayValues((prevArrayValues) => {
        const listValues = [...prevArrayValues];
        const countIsDefault =
          listValues?.filter((item) => item.isDefault)?.length || 0;
        if (countIsDefault > requiredItems)
          listValues[index] = {
            ...listValues[index],
            isDefault: checked,
          };
        else if (countIsDefault === requiredItems && checked) {
          listValues[index] = {
            ...listValues[index],
            isDefault: checked,
          };
        }
        return listValues;
      });
    }
  };

  const handleInputKind = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInputKind(event.target.value as InputKind);
    if (event.target.value !== "CHECKBOX") {
      setRequiredItems(1);
      setArrayValues((prevArrayValues) => {
        const listValues = prevArrayValues.map((value, valueIndex) => ({
          ...value,
          isDefault: valueIndex === 0,
        }));
        return listValues;
      });
    }
  };

  const handleSubmit = () => {
    const arrayNameInput: CreateNameInput[] = [];
    const listValues: CreateProductOptionValueInput[] = [];

    names.forEach((name) => {
      arrayNameInput.push({
        value: name.value,
        languageCode: name.languageCode,
      });
    });

    arrayValues.forEach((value) => {
      const namesValues: CreateNameInput[] = [];
      value?.names?.map((name) =>
        namesValues.push({
          languageCode: name.languageCode,
          value: name.value,
        })
      );

      listValues.push({
        names: namesValues,
        picture: value.picture,
        copiedPictures: value.copiedPictures,
        priceDifference: value.priceDifference,
        isDefault: value.isDefault,
        sites: value.sites,
      });
    });

    if (productOptionPayload?.id) {
      updateOption({
        variables: {
          input: {
            token,
            product: id || "",
            id: productOptionPayload.id || "",
            names,
            inputKind,
            requiredItems,
            hasDefault,
            values: arrayValues,
          },
        },
      });
    } else {
      addProductOption({
        variables: {
          input: {
            token,
            product: id || "",
            names: arrayNameInput,
            inputKind,
            requiredItems,
            hasDefault,
            values: listValues,
          },
        },
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        handleInitial();
      }}
      maxWidth="md"
    >
      <Wrapper default={hasDefault} upload={inputKind === "COLOR"}>
        <div className="header-option">
          <h3>{title}</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            Save
          </Button>
        </div>
        <div className="form">
          <Input
            value={
              find(names, (o) => o.languageCode === languageIsMain?.code)
                ?.value || ""
            }
            label="Option name"
            onChange={handleName}
            autoComplete="off"
          />
          <Select
            label="Input Type"
            value={inputKind || ""}
            onChange={handleInputKind}
          >
            <MenuItem value="RADIO">Radio buttons</MenuItem>
            <MenuItem value="CHECKBOX">Check Boxes</MenuItem>
            <MenuItem value="COLOR">Color Selection</MenuItem>
          </Select>
          <Input
            label="Required Item"
            type="number"
            value={requiredItems || ""}
            onChange={handleRequiredItems}
            disabled={inputKind === "COLOR" || inputKind === "RADIO"}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={hasDefault}
              onChange={handleHasDefault}
              name="checkedB"
              color="secondary"
            />
          }
          label="Preselect Default Value"
          style={{ width: "max-content" }}
        />
        <div className="add-value">
          <Button
            variant="contained"
            color="primary"
            onClick={handleArrayValues}
          >
            Add New Value
          </Button>
          {hasMultiAddress && (
            <div style={{ width: 300 }}>
              <Select label="Select a address">
                <MenuItem value="Global"> Global</MenuItem>
                {listCompanySite?.getListCompanySites?.list?.map((site) => (
                  <MenuItem key={site?.id || ""} value={site?.id || ""}>
                    {site?.identifier}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>

        <div className="table-collection-option">
          <div className="table-collection-option__header">
            <span>Option Value</span>
            {inputKind === "COLOR" && <span>Value Image</span>}
            <span>Price Modify</span>
            {hasDefault && <span>Default value</span>}
            <span />
          </div>
          <div className="table-collection-option__list">
            {arrayValues?.map((value, index) => (
              <div
                className="table-collection-option__item"
                key={value.id || index}
              >
                {inputKind !== "COLOR" ? (
                  <Input
                    value={
                      find(
                        value.names,
                        (o) => o.languageCode === languageIsMain?.code
                      )?.value || ""
                    }
                    onChange={(e) => {
                      e.persist();
                      handleValuesOption(e.target.value, index);
                    }}
                  />
                ) : (
                  <InputColorTags
                    onReturn={(list) => handleValuesOption(list[0], index)}
                    inputValue={[
                      find(
                        value.names,
                        (o) => o.languageCode === languageIsMain?.code
                      )?.value || "",
                    ]}
                  />
                )}
                {inputKind === "COLOR" && (
                  <div className="upload-picture">
                    <UploadPictureVaraint
                      idMenu={localMenu?.id || ""}
                      uploadTo="Product"
                      onReturnPictures={(picture, copiedPictures) =>
                        handleOnReturnPictures(index, picture, copiedPictures)
                      }
                      urlImage={{
                        id: productOptionPayload?.values?.[index]?.picture?.id,
                        url: productOptionPayload?.values?.[index]?.picture
                          ?.fileUrl,
                      }}
                    />
                  </div>
                )}
                <div className="price-modify">
                  <div style={{ width: 80 }}>
                    <Select
                      value={sign[index]}
                      onChange={(e) => {
                        setSign((prevSign) => {
                          const arraySign = [...prevSign];
                          arraySign[index] = e.target.value as string;
                          return arraySign;
                        });
                        handleSign(
                          e.target.value,
                          index,
                          value?.priceDifference
                        );
                        handleSignPrice(index);
                      }}
                      onBlur={() => handleSignPrice(index)}
                    >
                      <MenuItem value="+">+</MenuItem>
                      <MenuItem value="-">-</MenuItem>
                    </Select>
                  </div>

                  <Input
                    type="number"
                    value={priceDifference[index]}
                    onChange={(e) => {
                      e.persist();
                      setPriceDifference((prevPrice) => {
                        const arrayPrice = [...prevPrice];
                        if (
                          arrayPrice?.length &&
                          arrayPrice.length > 0 &&
                          index < arrayPrice.length
                        ) {
                          arrayPrice[index] = e.target.value;
                        }
                        return arrayPrice;
                      });
                    }}
                    onBlur={() => {
                      setPriceDifference((prevPrice) => {
                        const arrayPrice = [...prevPrice];
                        if (
                          arrayPrice?.length &&
                          arrayPrice.length > 0 &&
                          index < arrayPrice.length
                        ) {
                          if (
                            sign[index] === "-" &&
                            arrayPrice[index].indexOf("-") === -1
                          ) {
                            arrayPrice[index] = `-${arrayPrice[index]}`;
                          } else if (
                            sign[index] === "+" &&
                            arrayPrice[index].indexOf("-") !== -1
                          ) {
                            arrayPrice[index] = arrayPrice[index].slice(0, 0);
                          }
                        }
                        return arrayPrice;
                      });
                      handleSignPrice(index);
                    }}
                  />
                </div>
                {hasDefault && (
                  <>
                    {inputKind !== "CHECKBOX" ? (
                      <Radio
                        checked={!!value.isDefault}
                        style={{ margin: "0 auto" }}
                        onChange={(e) =>
                          handleSetDefault(e.target.checked, index)
                        }
                      />
                    ) : (
                      <Checkbox
                        checked={!!value.isDefault}
                        style={{ margin: "0 auto" }}
                        onChange={(e) =>
                          handleSetDefault(e.target.checked, index)
                        }
                      />
                    )}
                  </>
                )}

                <IconButton onClick={() => handleDeltte(index)}>
                  <FontAwesomeIcon
                    style={{
                      margin: "0 auto",
                    }}
                    icon={faTrash}
                    color="#F67070"
                    size="xs"
                  />
                </IconButton>
              </div>
            ))}
            {arrayValues?.length === 0 && (
              <div className="list-empty-value">This option has no values</div>
            )}
          </div>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default OptionFormModal;
