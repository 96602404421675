export type Errors = {
  parent?: string;
  identifier?: string;
};

export const ValidationCategoryForm = (
  level: number | null,
  parent: string | null,
  identifier: string
): Errors => {
  const errors: Errors = {};

  if (level === 2) {
    if (!parent) {
      errors.parent = "Validation.required";
    }
  }
  if (!identifier) {
    errors.identifier = "Validation.required";
  }
  return errors;
};

export const getCheckSpecialCaratcters = (status: boolean | undefined) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "error";
    default:
      return "initial";
  }
};
export const getCheckAlphanumeric = (status: boolean | undefined) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "error";
    default:
      return "initial";
  }
};
