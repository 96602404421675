import { SignUpInfo } from "./SignUp";

type Error = Partial<SignUpInfo>;

export const SignUpValidation = (values: SignUpInfo): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "email":
        if (!values.email) {
          errors.email = "form.fieldRequired";
        } else if (
          !values.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          errors.email = "form.invalidEmail";
        }
        break;
      case "password":
        if (!values.password) {
          errors.password = "form.fieldRequired";
        }
        break;
      case "confirmPassword":
        if (!values.confirmPassword) {
          errors.confirmPassword = "form.fieldRequired";
        } else if (values.confirmPassword !== values.password) {
          errors.confirmPassword = "form.fieldRequired"; // fix error message
        }
        break;
      case "domain":
        if (!values.domain) {
          errors.domain = "form.fieldRequired";
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
