import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import { Wrapper } from "./ListDataEmptyStyle";
import cercles from "../../img/cercles.png";
import { Box } from "../../commons";

type ListDataEmptyProps = {
  image: string;
  description: string;
  btnName?: string;
  url?: string;
};

const ListDataEmpty: React.FC<ListDataEmptyProps> = ({
  image,
  description,
  btnName,
  url,
}) => {
  return (
    <Wrapper>
      <Box className="box-empty">
        <div className="vector">
          <img src={cercles} alt="vector" className="img-vector" />
          <img src={image} alt="apparence" className="img-apparence" />
        </div>
        <p>{description}</p>
        {btnName && url && (
          <Link to={url}>
            <Button
              data-testid="add-button"
              variant="contained"
              color="primary"
              disableElevation
              className="btn-add"
            >
              <span className="btn-description">{btnName}</span>
            </Button>
          </Link>
        )}
      </Box>
    </Wrapper>
  );
};

export default ListDataEmpty;
