import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Switch,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// Components
import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Select,
} from "../../../commons";
// Styles
import { useStyles, Note, Wrapper } from "./SubscriptionFormStyle";

const DURATION: Array<{ id: string; value: string }> = [
  { id: "1", value: "Yearly" },
  { id: "2", value: "Monthly" },
];
type SubscriptionFormState = {
  isExistLimit: boolean;
  name: string;
  description: string;
  duration: string | null;
  price: string;
  limit: string;
};
const SubscriptionForm = (): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const params: any = useParams();
  const classes = useStyles();
  const id: string | null = params.id || null;

  // state
  const [state, setState] = React.useState<SubscriptionFormState>({
    isExistLimit: false,
    name: "",
    description: "",
    duration: null,
    price: "",
    limit: "",
  });
  // use Effect
  React.useEffect(() => {
    if (id) {
      // extract information
    }
  }, [id]);
  // functions
  const onClickSave = () => {};
  const onChangeButtonRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, isExistLimit: event.target.checked });
  };
  const onChangeText =
    (prop: keyof SubscriptionFormState) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();
      setState((prevState) => ({ ...prevState, [prop]: event.target.value }));
    };
  const onChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState((prevState) => ({
      ...prevState,
      duration: event.target.value as string,
    }));
  };
  return (
    <Wrapper>
      <Link to="/subscription">
        <ButtonCustomize className="arrow-back">
          <ArrowBackIcon /> &nbsp;
          {intl.formatMessage({ id: "subscriptions.form.return" })}
        </ButtonCustomize>
      </Link>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[intl.formatMessage({ id: "subscriptions.form.create" })]}
          iconAlt="subscription"
        />
        <Box display="flex" flexDirection="row">
          <ButtonCustomize>
            {intl.formatMessage({ id: "subscriptions.form.discard" })}
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={onClickSave}
            style={{ marginLeft: 10 }}
          >
            {intl.formatMessage({ id: "subscriptions.form.save" })}
          </ButtonCustomize>
        </Box>
      </Note>
      <Box component={Paper} padding={2} display="flex" flexDirection="column">
        <Input
          id="name"
          label={intl.formatMessage({ id: "subscriptions.form.title" })}
          value={state.name}
          className={classes.input}
          onChange={onChangeText("name")}
        />
        <Input
          id="description"
          label={intl.formatMessage({ id: "subscriptions.form.description" })}
          value={state.description}
          multiline
          rows={4}
          className={classes.input}
          onChange={onChangeText("description")}
        />
        <Select
          id="duration"
          value={state.duration}
          label={intl.formatMessage({ id: "subscriptions.form.duration" })}
          className={classes.input}
          onChange={onChangeSelect}
        >
          {DURATION.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
        <Input
          id="price"
          label={intl.formatMessage({ id: "subscriptions.form.price" })}
          value={state.price}
          className={classes.input}
          onChange={onChangeText("price")}
          endAdornment={<InputAdornment position="start">QAR</InputAdornment>}
        />
        <FormControlLabel
          className={classes.input}
          control={
            <Switch
              checked={state.isExistLimit}
              onChange={onChangeButtonRadio}
              name="isExistLimit"
            />
          }
          label={intl.formatMessage({ id: "subscriptions.form.limitMessage" })}
        />
        {state.isExistLimit && (
          <Input
            id="limit"
            label={intl.formatMessage({ id: "subscriptions.form.limit" })}
            value={state.limit}
            type="number"
            className={classes.lastInput}
            onChange={onChangeText("limit")}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default SubscriptionForm;
