import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    .search {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      .mainInput {
        padding: 9px 9px 9px;
      }
      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px 0px 0px 0px;
      }
      .note {
        margin: 2px 16px 0px 0px;
      }
    }
    .table-collection {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 15px 15px 5px 15px;
      border-radius: 10px;
      .table-option {
        display: flex;
        justify-content: flex-end;
        span {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        .sort {
          width: 150px;
          .MuiSelect {
            padding: 9px 9px 9px;
            font-size: 13px;
          }
          .MuiTouchRipple-root {
            font-size: 13px;
          }
        }
        margin-bottom: 10px;
      }
    }
  }
  .product__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
`;
