/*  @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import FileDrop from "react-file-drop";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
//
import { upload_files, upload_files_drag } from "../img";
import { Box, File } from "../commons";
import { CopiedPictureInput } from "../api/types";
import { snackBarVar } from "../api/local-state";
// import URL for upload
import { URL } from "../api";

type ImageInput = {
  url: string;
  id: string | null;
};

type UploadType = {
  uploadTo: "Category" | "Product" | "Promotion" | "logo" | null;
  idMenu?: string;
  onIdPicture(id: string | null): void;
  idPicture?: string | null;
  urlImage?: ImageInput | null;
  title: string;
  promotion?: "Poster" | "Cover";
  onLoading?: (loading: boolean) => void;
  onReturnCopiedPictures?: (listPicture: CopiedPictureInput[]) => void;
};

const Upload: React.FC<UploadType> = ({
  idMenu,
  onIdPicture,
  uploadTo,
  idPicture,
  urlImage,
  title,
  promotion,
  onLoading,
  onReturnCopiedPictures,
}) => {
  const [files, setFiles] = React.useState<FileList | null>(null);
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState<ImageInput | null>(null);

  const intl = useIntl();

  useEffect(() => {
    files === null && urlImage && urlImage.id && setImage(urlImage);
  }, [urlImage]);

  const handleDelete = () => {
    setFiles(null);
    onIdPicture(null);
    setImage(null);
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.value = "";
  };

  const getUrl = () => {
    switch (uploadTo) {
      case "logo":
        return `${idMenu}/MENU_LOGO`;
      case "Category":
        return `${idMenu}/MENU_CATEGORY_PICTURE`;
      case "Product":
        return `${idMenu}/CATEGORY_PRODUCT_PICTURE`;
      case "Promotion":
        return "public/MENU_AD_PICTURE";
      default:
        return `${idMenu}/MENU_LOGO`;
    }
  };

  // const url = URL + "/" + idMenu + "/" + getUrl();
  const url = `${URL}/${getUrl()}`;

  const handleFiles = (filesArray: FileList | null) => {
    if (filesArray && filesArray.length >= 1 && filesArray[0]) {
      const sizeImage = filesArray[0].size / 1000000;

      if (sizeImage <= 2) {
        // Initiate the FileReader object.
        const reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(filesArray[0]);
        reader.onload = (e: any) => {
          // Initiate the JavaScript Image object.
          const imageTest = new Image();
          // Set the Base64 string return from FileReader as source.
          imageTest.src = e.target.result;
          imageTest.onload = () => {
            if (imageTest.height >= 400 && imageTest.width >= 400) {
              setImage({ url: imageTest.src, id: null });
              throwXHR();
            } else {
              snackBarVar({
                open: true,
                severity: "error",
                message: intl.formatMessage({
                  id: "Upload.errorResulotionPicture",
                }),
              });
            }
          };
        };
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({
            id: "Upload.errorSizePicture",
          }),
        });
      }

      const throwXHR = () => {
        setFiles(filesArray);
        onLoading && onLoading(true);
        const dataForm = new FormData();
        dataForm.append("files", filesArray[0]);
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", progressHandler, false);
        xhr.open("POST", url);
        xhr.send(dataForm);
        xhr.onload = () => {
          if (xhr.status !== 200) {
            // analyze HTTP status of the response
            // alert(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
            snackBarVar({
              open: true,
              severity: "error",
              message: intl.formatMessage({
                id: "Upload.error",
              }),
            });
            onLoading && onLoading(false);
            setFiles(null);
            setProgress(0);
            setImage(null);
          } else {
            // show the result
            const response = JSON.parse(xhr.response);
            response && onIdPicture(response.files[0].id); // responseText is the server
            onLoading && onLoading(false);
            onReturnCopiedPictures &&
              onReturnCopiedPictures(response.files[0].copied);
          }
        };
        xhr.onerror = (e) => {
          snackBarVar({
            open: true,
            severity: "error",
            message: intl.formatMessage({
              id: "Upload.error",
            }),
          });
          onLoading && onLoading(false);
          setFiles(null);
          setProgress(0);
          setImage(null);
        };
      };
    }
  };

  React.useEffect(() => {
    if (idPicture) onIdPicture(idPicture);
  }, [idPicture]);

  const progressHandler = (event: ProgressEvent) => {
    setProgress((event.loaded / event.total) * 100);
  };

  useEffect(() => {
    const dropArea = document.getElementById("drop-area");
    const dropAreaSpan = document.getElementById("drop-area-span");
    const dropAreaImg = document.getElementById("drop-area-img");

    const handledrag = () => {
      if (dropAreaSpan !== null) dropAreaSpan.classList.add("label-drag-over");
      dropAreaImg?.setAttribute("src", upload_files_drag);
    };
    const handledragLeave = () => {
      if (dropAreaSpan !== null)
        dropAreaSpan.classList.remove("label-drag-over");
      dropAreaImg?.setAttribute("src", upload_files);
    };

    if (dropArea !== null)
      dropArea.addEventListener("dragover", handledrag, false);
    if (dropArea !== null)
      dropArea.addEventListener("dragleave", handledragLeave, false);
    if (dropArea !== null)
      dropArea.addEventListener("drop", handledragLeave, false);
  });

  const getRecommendation = () => {
    switch (uploadTo) {
      case "logo":
        return (
          <span>
            {intl.formatMessage({ id: "Upload.logoRec" })} <br /> 800px * 800px
          </span>
        );
      case "Category":
        return (
          <span>
            {intl.formatMessage({ id: "Upload.categoryRec" })} <br /> 400px *
            400px
          </span>
        );
      case "Product":
        return (
          <span>
            {intl.formatMessage({ id: "Upload.productRec" })} <br /> 1600px *
            1000px
          </span>
        );
      case "Promotion":
        return (
          <span>
            {promotion && promotion === "Cover" ? (
              <span>
                {intl.formatMessage({ id: "Upload.promotion.cover" })}
                <br />
                1050px * 500px
              </span>
            ) : (
              <span>
                {intl.formatMessage({ id: "Upload.promotion.poster" })}
                <br />
                1000 x 1000px ou 1000 x 1400px
              </span>
            )}
          </span>
        );
      default:
        return (
          <span>
            {intl.formatMessage({ id: "Upload.logoRec" })} <br /> 800px * 800px
          </span>
        );
    }
  };

  return (
    <Wrapper>
      <Box p={20} width={1}>
        <h3 className="inner-title">{title}</h3>
        <div className="recommendation">
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            color="#007AFF"
          />
          {getRecommendation()}
        </div>
        <FileDrop onDrop={handleFiles}>
          <div className="drop-area" id="drop-area">
            <img
              style={{ margin: "auto" }}
              id="drop-area-img"
              src={upload_files}
              alt="upload files"
            />
            <div className="drop-content">
              <label className="drop-area-label" id="drop-area-span">
                {intl.formatMessage({ id: "Upload.information" })}
              </label>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {}}
                className="upload-btn"
                disabled={
                  uploadTo === "logo" &&
                  urlImage !== undefined &&
                  urlImage !== null &&
                  urlImage.id !== null
                }
              >
                <input
                  accept="image/*"
                  id="fileInput"
                  type="file"
                  onChange={(e) => handleFiles(e.target.files)}
                />
                <FontAwesomeIcon icon={faCloudUploadAlt} color="#ffffff" />
                <span className="btn-description">
                  {intl.formatMessage({ id: "Upload.information2" })}
                </span>
              </Button>
              {/* <a href="#">
                {intl.formatMessage({ id: "Upload.chooseGallery" })}
              </a> */}
            </div>
          </div>
        </FileDrop>

        {((files && files.length > 0) || image) && (
          <div className="file-list">
            <File
              file={files}
              urlImage={image}
              progress={parseInt(progress.toFixed(2), 10)}
              onDelete={handleDelete}
            />
          </div>
        )}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .btn-description {
    margin-left: 12px;
    font-size: 16px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .drop-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .drop-area {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 15px;
    min-width: 350px;
    background: #fcfcff;
    border: 2px dashed #f1f2fb;
    box-sizing: border-box;
    border-radius: 5px;
    img,
    label,
    button {
      margin: 10px;
    }
    .drop-area-label {
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #afb1c6;
      transition: all 0.3s ease-in-out;
    }
    .label-drag-over {
      color: #74abff;
    }
    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 11px;
      text-align: center;
      text-decoration-line: underline;
      color: rgba(175, 177, 198, 0.7);
    }
  }
  .upload-btn {
    * {
      cursor: pointer;
    }
    input[type="file"] {
      position: absolute;
      height: 35px;
      width: 110px;
      opacity: 0;
      cursor: pointer;
    }
  }
  .inner-title {
    margin: 0 0 15px 0;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .file-list {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px;
    margin-top: 15px;
  }
  .recommendation {
    display: grid;
    grid-template-columns: 35px auto;
    grid-gap: 10px;
    margin-bottom: 15px;
    padding: 10px;
    border: 2px dashed rgba(26, 92, 255, 0.15);
    border-radius: 5px;
    background-color: rgba(26, 92, 255, 0.04);
    svg {
      margin: auto;
      width: 24px;
      height: 24px;
    }
    span {
      margin: auto 0;
      font-size: 13px;
      line-height: 16px;
      color: #64b5f6;
    }
  }
`;

export default Upload;
