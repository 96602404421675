import gql from "graphql-tag";

// create merchant account
export const REGISTER_MERCHANT = gql`
  mutation RegisterMerchant($input: RegisterMerchantInput!) {
    registerMerchant(input: $input) {
      id
    }
  }
`;

// activate account after registration
export const CONFIRM_ACCOUNT = gql`
  mutation ConfirmAccount($input: ConfirmAcountInput!) {
    confirmAccount(input: $input) {
      success
    }
  }
`;
