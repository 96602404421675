import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import {
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Paper,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { WrapperStyled, CustomerIconButton } from "./CustomersStyle";
import { ListDataEmpty, CustomersAddressModal } from "../../components";
import {
  Modal,
  BreadCrumbsTitle,
  ErrorMessage,
  BoxNew as Box,
  TablePagination,
} from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import {
  GET_LIST_CUSTOMERS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_ADDRESS,
  EXPORT_CUSTOMERS,
} from "../../api";

import {
  GetListCustomersQuery,
  CustomerPayload,
  GetListCustomersQueryVariables,
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables,
  RemoveCustomerAddressMutation,
  RemoveCustomerAddressMutationVariables,
  ExportCustomersMutation,
  ExportCustomersMutationVariables,
  AddressBookPayload,
  MenuPayload,
} from "../../api/types";

import { snackBarVar, SelectedMenuVar } from "../../api/local-state";

import {
  Customers as CustomersIcon,
  EmptyAddress,
  SelectCustomer,
} from "../../img";

const Customers: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const intl = useIntl();

  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
    null
  );
  const handleCloseAddressModal = () => {
    setAddressModalOpen(false);
    if (addressToEdit) setAddressToEdit(null);
  };

  const [addressToDelete, setAddressToDelete] = useState<string | null>(null);
  const [addressToEdit, setAddressToEdit] = useState<AddressBookPayload | null>(
    null
  );
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState<
    CustomerPayload | null | undefined
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const { data: listClients, loading: listClientsLoading } = useQuery<
    GetListCustomersQuery,
    GetListCustomersQueryVariables
  >(GET_LIST_CUSTOMERS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    fetchPolicy: "network-only",
    skip: !localMenu?.id,
  });

  const [deleteCustomerAddress, { loading: deleteAddressLoading }] =
    useMutation<
      RemoveCustomerAddressMutation,
      RemoveCustomerAddressMutationVariables
    >(DELETE_CUSTOMER_ADDRESS, {
      refetchQueries: () => [
        {
          query: GET_LIST_CUSTOMERS,
          variables: {
            input: {
              token,
              menu: localMenu?.id || "",
            },
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setAddressToDelete(null);
      },
    });

  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
  >(DELETE_CUSTOMER, {
    refetchQueries: () => [
      {
        query: GET_LIST_CUSTOMERS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      setCustomerToDelete(null);
    },
    onError: (error) => {
      if (error.message === "action_not_allowed") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Action not allowed",
        });
      } else
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
    },
  });

  const [ExportCutomers, { loading: loadingExport }] = useMutation<
    ExportCustomersMutation,
    ExportCustomersMutationVariables
  >(EXPORT_CUSTOMERS, {
    onCompleted: (data) => {
      const a = document.createElement("a");
      a.href = data?.exportCustomers?.fileUrl;
      a.setAttribute("download", "filename.csv");
      a.click();
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const selectedCustomer = selectedCustomerId
    ? listClients?.getListCustomers?.list?.filter(
        (customer) => customer.id === selectedCustomerId
      )?.[0]
    : null;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const trigerDeleteCustomer = (id: string | null | undefined) => {
    deleteCustomer({
      variables: {
        input: {
          token,
          id: id || "",
        },
      },
    });
  };

  const trigerDeleteAddress = (id: string) => {
    deleteCustomerAddress({
      variables: {
        input: {
          id,
          token,
        },
      },
    });
  };

  const handleOnEditCustomer = (customer: CustomerPayload) => {
    history.push({
      pathname: "/modify-customer",
      state: {
        isUpdate: true,
        customerEmail: customer?.email,
        customerFirstName: customer?.firstName,
        customerLastName: customer?.lastName,
        customerPhoneNumber: customer?.phone?.number,
        customerPhoneCode: customer?.phone?.countryCode?.toString(),
        customerID: customer?.id,
      },
    });
  };

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Phone",
      accessor: "phone",
    },
    {
      header: "Origin",
      accessor: "origin",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "120px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listClients?.getListCustomers?.list
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((customer) => ({
          id: customer?.id,
          name: `${customer?.firstName} ${customer?.lastName}`,
          email: customer?.email,
          phone: (
            <Typography variant="subtitle2">
              {customer?.phone?.countryCode} &nbsp; {customer?.phone?.number}
            </Typography>
          ),
          origin: (
            <Typography variant="subtitle2">{customer.origin}</Typography>
          ),
          actions: (
            <>
              <IconButton
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnEditCustomer(customer);
                }}
                disabled={customer.origin === "REGISTERED"}
                style={{
                  margin: "0 4px",
                }}
              >
                <CreateIcon />
              </IconButton>
              <CustomerIconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setCustomerToDelete(customer);
                }}
                disabled={customer.origin === "REGISTERED"}
              >
                <DeleteIcon />
              </CustomerIconButton>
            </>
          ),
        })) || []
    );
  };

  const handleEditAddress = () => {
    const selectedAddress =
      selectedCustomer?.addressBook?.filter(
        (address) => address?.id === selectedAddressId
      )?.[0] || null;
    setAnchorEl(null);
    setAddressModalOpen(true);
    setAddressToEdit(selectedAddress);
  };

  return (
    <WrapperStyled>
      {listClientsLoading && (
        <CircularProgress className="Loading" color="primary" />
      )}
      {listClients !== undefined && !listClientsLoading && (
        <>
          <BreadCrumbsTitle
            labelIntl={["store.customer.title", "store.cutomers.cutomersList"]}
            iconAlt="Customers"
          />
          {listClients !== undefined &&
            listClients.getListCustomers.list.length === 0 && (
              <ListDataEmpty
                image={CustomersIcon}
                description={intl.formatMessage({
                  id: "MenuDetails.listClientsEmpty",
                })}
                btnName={intl.formatMessage({
                  id: "MenuDetails.addClient",
                })}
                url="/create-customer"
              />
            )}
          {listClients?.getListCustomers?.list?.length && (
            <div className="container">
              <Box
                display="flex"
                flexDirection="column"
                component={Paper}
                p={1}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pl={1}
                  py={1}
                  mb={1}
                >
                  <Typography variant="h2">
                    {intl.formatMessage({
                      id: "store.cutomers.cutomersList",
                    })}
                  </Typography>

                  <div>
                    <Button
                      onClick={() =>
                        ExportCutomers({
                          variables: {
                            input: {
                              token,
                              menu: localMenu?.id || "",
                            },
                          },
                        })
                      }
                      disabled={loadingExport}
                      style={{ color: "#1F90CF", marginRight: 15 }}
                    >
                      Export All Customers
                    </Button>

                    <Button
                      component={Link}
                      variant="contained"
                      color="secondary"
                      to={{
                        pathname: "/create-customer",
                        state: {
                          isUpdate: false,
                        },
                      }}
                    >
                      {intl.formatMessage({
                        id: "store.customer.creatClient",
                      })}
                    </Button>
                  </div>
                </Box>
                <Box display="flex" flex={1}>
                  <Table
                    columns={columns}
                    data={renderTableRows()}
                    emptyMessage="Add your first product"
                    onClickRow={(row) =>
                      row?.id && setSelectedCustomerId(row.id)
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <TablePagination
                    labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                    count={listClients.getListCustomers.list?.length || 0}
                    page={page}
                    onPageChange={(_, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
              <Box component={Paper} p={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  pl={1}
                  py={1}
                  mb={1}
                >
                  <Typography variant="h2">
                    {intl.formatMessage({
                      id: "store.customersList.addressList",
                    })}
                  </Typography>
                  {selectedCustomer?.addressBook && selectedCustomer !== null && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setAddressModalOpen(true)}
                    >
                      {intl.formatMessage({
                        id: "store.customersList.creatAddress",
                      })}
                    </Button>
                  )}
                </Box>
                <div className="address-List">
                  {selectedCustomer?.addressBook &&
                  selectedCustomer !== null ? (
                    <>
                      {selectedCustomer?.addressBook?.length > 0 && (
                        <>
                          {selectedCustomer?.addressBook?.map((address) => (
                            <div className="address-item" key={address.id}>
                              <div className="address-info">
                                {/* <span> {address.address?.?.roadNames?.[0].value}</span> */}
                                <span>
                                  {`${address.address?.city?.name} - ${address.address?.postalCode}`}
                                </span>
                                <span>{address.address?.country?.name}</span>
                                {address.primaryPhone?.number !== "" && (
                                  <span className="grayText">
                                    {`${address.primaryPhone?.countryCode?.toString()} ${
                                      address.primaryPhone?.number
                                    }`}
                                  </span>
                                )}
                                {address.isDefault ? (
                                  <span className="primary-address">
                                    {intl.formatMessage({
                                      id: "store.customersList.address.primary",
                                    })}
                                  </span>
                                ) : (
                                  <span className="secondary-address">
                                    {intl.formatMessage({
                                      id: "store.customersList.address.secondary",
                                    })}
                                  </span>
                                )}
                              </div>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorEl(e.currentTarget);
                                    setSelectedAddressId(address?.id || null);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>
                            </div>
                          ))}
                        </>
                      )}
                      {selectedCustomer?.addressBook?.length === 0 && (
                        <div className="address-list-message">
                          <img src={EmptyAddress} alt="addressEmpty" />
                          <span>
                            {`${selectedCustomer.firstName} ${
                              selectedCustomer.lastName
                            } ${intl.formatMessage({
                              id: "store.customersList.address.HasNoAddress",
                            })}`}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="address-list-message">
                      <img src={SelectCustomer} alt="addressEmpty" />
                      <span>
                        {intl.formatMessage({
                          id: "store.customersList.address.selectClient",
                        })}
                      </span>
                    </div>
                  )}
                </div>
              </Box>
              <Menu
                anchorPosition={{
                  left: anchorEl?.getBoundingClientRect().left || 0,
                  top: anchorEl?.getBoundingClientRect().top || 0,
                }}
                anchorOrigin={{ horizontal: "left", vertical: "center" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorReference="anchorPosition"
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => {}}>
                  {intl.formatMessage({
                    id: "store.customersList.MakePrimary",
                  })}
                </MenuItem>
                <MenuItem onClick={handleEditAddress}>
                  {intl.formatMessage({
                    id: "MenuCard.manage",
                  })}
                </MenuItem>
                <MenuItem
                  style={{ color: "#ff4949" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setAddressToDelete(selectedAddressId);
                  }}
                >
                  {intl.formatMessage({
                    id: "MenuCard.delete",
                  })}
                </MenuItem>
              </Menu>
              <Modal
                open={Boolean(customerToDelete)}
                title={intl.formatMessage({
                  id: "store.customer.deleteClient",
                })}
                message={`${intl.formatMessage({
                  id: "store.customer.deleteClient.message",
                })} ${customerToDelete?.firstName} ${
                  customerToDelete?.lastName
                }`}
                handleClose={() => setCustomerToDelete(null)}
                handleContent={() => trigerDeleteCustomer(customerToDelete?.id)}
                action="delete"
                loading={deleteCustomerLoading}
              />
              <Modal
                open={Boolean(addressToDelete)}
                title={intl.formatMessage({
                  id: "store.customer.deleteAddress",
                })}
                message={intl.formatMessage({
                  id: "store.customer.deleteAddress.message",
                })}
                handleClose={() => setAddressToDelete(null)}
                handleContent={() =>
                  addressToDelete && trigerDeleteAddress(addressToDelete)
                }
                action="delete"
                loading={deleteAddressLoading}
              />
              {/* [REFACTOR] without this conditon customersAddressModal will not reset */}
              {addressModalOpen && (
                <CustomersAddressModal
                  open={addressModalOpen}
                  handleClose={handleCloseAddressModal}
                  customerId={selectedCustomerId || ""}
                  menu={localMenu?.id || ""}
                  customerEmail={selectedCustomer?.email || ""}
                  address={addressToEdit}
                />
              )}
            </div>
          )}
        </>
      )}
      {listClients === undefined && !listClientsLoading && <ErrorMessage />}
    </WrapperStyled>
  );
};

export default Customers;
