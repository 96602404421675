import React from "react";
import ContentLoader from "react-content-loader";

const LoadingBrief: React.FC = () => {
  return (
    <ContentLoader height={270} width="100%" speed={2}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="55" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="110" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="165" rx="3" ry="3" width="100%" height="50" />
      <rect x="0" y="220" rx="3" ry="3" width="100%" height="50" />
    </ContentLoader>
  );
};

export default LoadingBrief;
