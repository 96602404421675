import React from "react";
import { Box, Paper, Grid, Typography } from "@material-ui/core";

import { StatsSummaryPayload } from "../../api/types";

type StoreStatisticsProps = {
  summary?: StatsSummaryPayload;
};

const StoreStatistics: React.FC<StoreStatisticsProps> = ({ summary }) => {
  return (
    <Box component={Paper} marginTop={2} p={3}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography
            variant="h1"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            {summary?.totalProducts || 0}
          </Typography>
          <Typography
            variant="h2"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Products
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            variant="h1"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            {summary?.totalCustomers || 0}
          </Typography>
          <Typography
            variant="h2"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Customers
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            variant="h1"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            {summary?.totalNewsletter || 0}
          </Typography>
          <Typography
            variant="h2"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Newsletter sent
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            variant="h1"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            {(summary?.totalGiftcards || 0) + (summary?.totalPromocodes || 0)}
          </Typography>
          <Typography
            variant="h2"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Gift Cards and Coupons
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoreStatistics;
