import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .vendor__container {
    height: calc(100vh - 95px);
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
    .vendor__container-action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .btn-edit {
      color: #1f90cf;
      margin: 0 4px;
    }
    .btn-delete {
      color: #ff4949;
      margin: 0 4px;
    }
  }
`;
