import * as React from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography, Switch } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import debounce from "lodash/debounce";

import { BreadCrumbsTitle, ButtonCustomize } from "../../commons";

import {
  GET_MENU_LEGALITY_SETTINGS,
  UPDATE_MENU_LEGALITY_SETTINGS,
} from "../../api";

import {
  GetMenuLegalitySettingsQuery,
  GetMenuLegalitySettingsQueryVariables,
  UpdateMenuLegalitySettingsMutation,
  UpdateMenuLegalitySettingsMutationVariables,
  UpdateLegalityPageInput,
  MenuPayload,
} from "../../api/types";

import { SelectedMenuVar } from "../../api/local-state";

import { Wrapper, Separator } from "./ListLegality--style";

import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";

const ListLegality: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [consentIsRequired, setConsentIsRequired] = React.useState(false);
  const [approvalIsRequired, setApprovalIsRequired] = React.useState(false);
  const [pages, setPages] = React.useState<UpdateLegalityPageInput[]>([]);

  const { data, refetch } = useQuery<
    GetMenuLegalitySettingsQuery,
    GetMenuLegalitySettingsQueryVariables
  >(GET_MENU_LEGALITY_SETTINGS, {
    variables: {
      input: {
        token,
        id: localMenu?.id || "",
      },
    },
    onCompleted: (legality) => {
      const arrayPages: UpdateLegalityPageInput[] = [];
      legality.getMenuLegalitySettings?.pages?.forEach((item) => {
        if (item.identifier)
          arrayPages.push({
            identifier: item.identifier,
            isPublished: item.isPublished || false,
          });
      });
      setPages(arrayPages);
      legality.getMenuLegalitySettings?.approvalIsRequired &&
        setApprovalIsRequired(true);
      legality.getMenuLegalitySettings.consentIsRequired &&
        setConsentIsRequired(true);
    },
    skip: !localMenu?.id,
  });

  const [UpdateLegalitySetting] = useMutation<
    UpdateMenuLegalitySettingsMutation,
    UpdateMenuLegalitySettingsMutationVariables
  >(UPDATE_MENU_LEGALITY_SETTINGS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        pages,
        approvalIsRequired,
        consentIsRequired,
      },
    },
    onCompleted: () => {
      refetch();
    },
  });

  const dataLegality = data?.getMenuLegalitySettings;

  const handleChange =
    (identifier: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const arrayPosition = [...pages];
      const position = findIndex(
        arrayPosition,
        (o) => o.identifier === identifier
      );
      if (position !== -1) {
        arrayPosition[position] = {
          identifier,
          isPublished: event.target.checked,
        };
      } else {
        arrayPosition.push({ identifier, isPublished: event.target.checked });
      }
      setPages(arrayPosition);
      handleSubmit();
    };

  const handleSubmit = React.useCallback(
    debounce(() => UpdateLegalitySetting(), 2000),
    []
  );

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "Legality"]}
        icon={StoreSettings}
        iconAlt="Payment"
      />
      <Box component={Paper} p={2}>
        <Typography variant="h2" style={{ fontWeight: 700 }}>
          Legal pages and policies
        </Typography>
        <Typography variant="subtitle1" className="description">
          Your store’s legal documents are displayed on your website as separate
          pages. Add the necessary legal pages to inform customers about various
          aspects of your services and protect your business from violating
          rules and regulations.
        </Typography>
        <Separator />
        {/*  Terms & conditions */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Terms & conditions
            </Typography>
            <Typography variant="subtitle2" className="description">
              Describe the legally binding rules, terms, and guidelines that
              everyone has to agree with in order to use your website and
              services.
            </Typography>
          </Box>
          {findIndex(
            dataLegality?.pages,
            (o) => o.identifier === "termsAndconditions"
          ) === -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: {
                    title: "Terms & conditions",
                    identifier: "termsAndconditions",
                  },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: {
                      title: "Terms & conditions",
                      identifier: "termsAndconditions",
                    },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "termsAndconditions")
                    ?.isPublished || false
                }
                onChange={handleChange("termsAndconditions")}
              />
            </div>
          )}
        </Box>

        <Separator />
        {/*  Privacy Policy */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Privacy Policy
            </Typography>
            <Typography variant="subtitle2" className="description">
              Describe how you collect, handle, and process data of your
              customers and visitors.
            </Typography>
          </Box>
          {findIndex(
            dataLegality?.pages,
            (o) => o.identifier === "privacyPolicy"
          ) === -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: {
                    title: "Privacy Policy",
                    identifier: "privacyPolicy",
                  },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: {
                      title: "Privacy Policy",
                      identifier: "privacyPolicy",
                    },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "privacyPolicy")
                    ?.isPublished || false
                }
                onChange={handleChange("privacyPolicy")}
              />
            </div>
          )}
        </Box>
        {/*  Shipping & Payment Info */}
        <Separator />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Shipping & Payment Info
            </Typography>
            <Typography variant="subtitle2" className="description">
              Describe how you ship or deliver your products and accept payments
              for the orders.
            </Typography>
          </Box>
          {findIndex(
            dataLegality?.pages,
            (o) => o.identifier === "shippingAndPaymentInfo"
          ) === -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: {
                    title: "Shipping & Payment Info",
                    identifier: "shippingAndPaymentInfo",
                  },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: {
                      title: "Shipping & Payment Info",
                      identifier: "shippingAndPaymentInfo",
                    },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "shippingAndPaymentInfo")
                    ?.isPublished || false
                }
                onChange={handleChange("shippingAndPaymentInfo")}
              />
            </div>
          )}
        </Box>

        <Separator />
        {/*  Return Policy */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Return Policy
            </Typography>
            <Typography variant="subtitle2" className="description">
              Describe how you make the returns, exchanges, or refunds if
              customers are not satisfied with their orders.
            </Typography>
          </Box>
          {findIndex(
            dataLegality?.pages,
            (o) => o.identifier === "returnPolicy"
          ) === -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: { title: "Return Policy", identifier: "returnPolicy" },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: {
                      title: "Return Policy",
                      identifier: "returnPolicy",
                    },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "returnPolicy")
                    ?.isPublished || false
                }
                onChange={handleChange("returnPolicy")}
              />
            </div>
          )}
        </Box>

        <Separator />
        {/*  About Us */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              About Us
            </Typography>
            <Typography variant="subtitle2" className="description">
              Provide customers with more insight into your business and
              product.
            </Typography>
          </Box>
          {findIndex(dataLegality?.pages, (o) => o.identifier === "aboutUs") ===
          -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: { title: "About Us", identifier: "aboutUs" },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: { title: "About Us", identifier: "aboutUs" },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "aboutUs")?.isPublished ||
                  false
                }
                onChange={handleChange("aboutUs")}
              />
            </div>
          )}
        </Box>
        <Separator />
        {/*  FAQ */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              FAQ
            </Typography>
            {/* <Typography variant="subtitle2" className="description">
              
            </Typography> */}
          </Box>
          {findIndex(dataLegality?.pages, (o) => o.identifier === "faq") ===
          -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: { title: "FAQ", identifier: "faq" },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: { title: "FAQ", identifier: "faq" },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "faq")?.isPublished ||
                  false
                }
                onChange={handleChange("faq")}
              />
            </div>
          )}
        </Box>

        <Separator />
        {/*  Resellers */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Resellers
            </Typography>
            {/* <Typography variant="subtitle2" className="description">
              
            </Typography> */}
          </Box>
          {findIndex(
            dataLegality?.pages,
            (o) => o.identifier === "resellers"
          ) === -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: { title: "Resellers", identifier: "resellers" },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <div>
              <ButtonCustomize
                variant="contained"
                className="action edit"
                onClick={() =>
                  history.push({
                    pathname: "/legality-form",
                    state: { title: "Resellers", identifier: "resellers" },
                  })
                }
              >
                <EditIcon fontSize="small" /> Edit page
              </ButtonCustomize>
              <Switch
                checked={
                  find(pages, (o) => o.identifier === "resellers")
                    ?.isPublished || false
                }
                onChange={handleChange("resellers")}
              />
            </div>
          )}
        </Box>
        <Separator />
        {/*  Term & condition for card payment  */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Term & condition for card payment
            </Typography>
            {/* <Typography variant="subtitle2" className="description">
              
            </Typography> */}
          </Box>
          {findIndex(
            dataLegality?.pages,
            (o) => o.identifier === "termAndconditionForCardPayment"
          ) === -1 ? (
            <ButtonCustomize
              variant="contained"
              className="action"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: {
                    title: "Term & condition for card payment",
                    identifier: "termAndconditionForCardPayment ",
                  },
                })
              }
            >
              <AddIcon fontSize="small" /> Add page
            </ButtonCustomize>
          ) : (
            <ButtonCustomize
              variant="contained"
              className="action edit"
              onClick={() =>
                history.push({
                  pathname: "/legality-form",
                  state: {
                    title: "Term & condition for card payment",
                    identifier: "termAndconditionForCardPayment",
                  },
                })
              }
            >
              <EditIcon fontSize="small" /> Edit page
            </ButtonCustomize>
          )}
        </Box>
      </Box>

      <Box component={Paper} marginTop={3} p={2}>
        <Typography variant="h2" style={{ fontWeight: 700 }}>
          Customer consent
        </Typography>
        <Typography variant="subtitle1" className="description">
          When customers interact with your store, you may request their consent
          to terms under which your business operates. Use these settings to
          change what consent is requested at different stages of the shopping
          process.
        </Typography>
        <Separator />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Require consent to terms and conditions at checkout
            </Typography>
            <Typography variant="subtitle2" className="description">
              Displays an “I agree with the terms and conditions” checkbox
              alongside links to your legal documents on the cart page.
              Customers need to confirm that they accept your terms by checking
              the checkbox before they can continue with their order.
            </Typography>
          </Box>
          <Switch
            checked={consentIsRequired}
            onChange={(e) => {
              setConsentIsRequired(e.target.checked);
              handleSubmit();
            }}
          />
        </Box>

        <Separator />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ fontWeight: 700 }}>
              Request customers’ approval for your marketing emails at checkout
            </Typography>
            <Typography variant="subtitle2" className="description">
              Displays a checkbox that invites customers to subscribe to
              promotional emails from you. Customers who check the checkbox and
              opt in are automatically flagged as willing to accept marketing
              emails.
            </Typography>
          </Box>
          <Switch
            checked={approvalIsRequired}
            onChange={(e) => {
              setApprovalIsRequired(e.target.checked);
              handleSubmit();
            }}
          />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default ListLegality;
