import React from "react";
import styled from "styled-components";
import {
  InputLabel,
  SelectProps,
  FormControl,
  FormHelperText,
  Select as SelectMUI,
} from "@material-ui/core";

type InputProps = SelectProps & { label?: string; msgError?: string };

const Select: React.FC<InputProps> = ({
  label,
  msgError,
  children,
  multiple,
  ...restProps
}) => {
  return (
    <FormControlStyled
      variant="filled"
      error={msgError !== "" && msgError !== undefined}
    >
      <InputLabel htmlFor="component-filled" shrink>
        {label}
      </InputLabel>
      <SelectMUI
        inputProps={{ className: "MuiSelect" }}
        {...restProps}
        multiple={multiple}
      >
        {children}
      </SelectMUI>
      {msgError && <FormHelperText> {msgError} </FormHelperText>}
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)`
  width: 100%;
  /* margin-bottom: 24px !important; */
  .MuiSelect {
    overflow: "hidden";
    background-color: #ffffff;
    padding: 27px 12px 10px;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus-within {
      background-color: #ffffff;
      border: 2px solid #000000;
      border-radius: 5px;
    }
  }
  .Mui-error {
    .MuiSelect {
      border: 2px solid #f67070;
    }
  }
  .MuiFilledInput-root {
    border-radius: 5px;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
  }
`;

export default Select;
