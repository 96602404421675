// Styled component import
import styled from "styled-components";

// Types

export const Wrapper = styled.div`
  ${({ theme }) => `
  display: inline-grid;
  grid-template-columns: 1fr 35px;
  grid-gap: 5px;
  float: right;
  padding: 3px;
  border: 1px solid #b7b7b9;
  border-radius: 7px;
  width: calc(100% - 6px);
  background-color: #ffffff;
  input.search-input {
    width: calc(100% - 10px);
    padding-left: 10px;
    border: 0;
  }
  button.search-btn {
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 5px;
    background: ${theme.palette.secondary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    svg path {
      fill: white;
    }
  }`}
`;
