import gql from "graphql-tag";

export const GETLISTGROUP = gql`
  query GetListMenuGroups($input: GetListMenuGroupsInput!) {
    getListMenuGroups(input: $input) {
      list {
        id
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        customersList {
          id
          firstName
          lastName
        }
        customersCount
      }
    }
  }
`;

export const CREATECUSTOMERSGROUP = gql`
  mutation CreateMenuGroup($input: CreateMenuGroupInput!) {
    createMenuGroup(input: $input) {
      id
    }
  }
`;

export const REMOVEGROUP = gql`
  mutation DeleteMenuGroup($input: DeleteMenuGroupInput!) {
    deleteMenuGroup(input: $input) {
      success
    }
  }
`;

export const UPDATEGROUP = gql`
  mutation UpdateMenuGroup($input: UpdateMenuGroupInput!) {
    updateMenuGroup(input: $input) {
      success
    }
  }
`;
