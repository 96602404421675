import React, { FC, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { TextField, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import find from "lodash/find";

import {
  UploadPictureProduct,
  SettingsHomeCollectionForm,
} from "../../../components";
import {
  BreadCrumbsTitle,
  BoxNew as Box,
  Chip,
  Modal,
  ButtonCustomize,
  LoadingListHomePage,
} from "../../../commons";

import Table, { ColumnsProps } from "../../../commons/Table";

import { removeTypenameProp } from "../../../utils/typesUtils";

import { Wrapper } from "./SettingsHome--styles";

import { UPDATE_HOME_PAGE, GET_HOME_PAGE } from "../../../api";
import {
  GetHomePageQuery,
  GetHomePageQueryVariables,
  UpdateHomePageMutation,
  UpdateHomePageMutationVariables,
  HomePageCollectionPayload,
  HomePageCollectionInput,
  MenuPayload,
  LanguageCode,
  TitleInput,
} from "../../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../../api/local-state";
import { URL_PICTURES } from "../../../api/config";

type Slides = {
  picture: string;
}[];

const HomeSettings: FC = () => {
  const token = localStorage.getItem("token") || "";

  const intl = useIntl();
  const [openCollection, setOpenCollection] = useState(false);
  const [slidesFiles, setSlidesFiles] = useState<Slides>([]);
  const [partnerSlides, setPartnerSlides] = useState<Slides>([]);
  const [collections, setCollections] = useState<HomePageCollectionPayload[]>(
    []
  );

  const [collectionItem, setCollectionItem] =
    useState<HomePageCollectionPayload>();
  const [indexCollection, setIndexCollection] = useState<number | undefined>(
    undefined
  );
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain =
    localMenu?.languages &&
    find(localMenu?.languages, (o) => o.isMain === true)?.language;

  const { data: homePageData, loading } = useQuery<
    GetHomePageQuery,
    GetHomePageQueryVariables
  >(GET_HOME_PAGE, {
    skip: !localMenu?.id,
    variables: { input: { menu: localMenu?.id || "", token } },
  });

  const [updateHome, { loading: loadingUpdate }] = useMutation<
    UpdateHomePageMutation,
    UpdateHomePageMutationVariables
  >(UPDATE_HOME_PAGE, {
    onCompleted: () => {
      snackBarVar({
        message: "Home Page was Updated Successfully",
        open: true,
        severity: "success",
      });
      if (openCollection) setOpenCollection(false);
    },
    refetchQueries: () => [
      {
        query: GET_HOME_PAGE,
        variables: {
          input: { menu: localMenu?.id || "", token },
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  useEffect(() => {
    setCollections(homePageData?.getHomePage?.collections || []);
    setSlidesFiles(
      homePageData?.getHomePage?.slides?.map((slide) => ({
        picture: slide.id || "",
      })) || []
    );
    setPartnerSlides(
      homePageData?.getHomePage?.partnerSlides?.map((slide) => ({
        picture: slide.id || "",
      })) || []
    );
  }, [homePageData]);

  const handleOnDeleteCollection = () => {
    setCollections(collections.filter((_, index) => index !== indexCollection));
    setIndexCollection(undefined);
  };

  const handleOnChangePosition = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const collectionsArr = [...collections];
    const value = parseInt(event.target.value, 10);
    collectionsArr[index] = { ...collectionsArr[index], position: value };
    setCollections(collectionsArr);
  };
  const handleUpdateCollection = (collection?: HomePageCollectionInput) => {
    const collectionsInput: HomePageCollectionInput[] = collections.map(
      (item) => {
        const arrayTitle: TitleInput[] = [];
        item?.titles?.map((title) =>
          arrayTitle.push({
            id: title.id,
            languageCode: title.languageCode as LanguageCode,
            value: title.value,
          })
        );
        return removeTypenameProp({
          ...item,
          picture: item.picture?.id,
          titles: arrayTitle,
        });
      }
    );
    // save slides from the query getHomePage in case the user click on Add Collection
    // const slidesInput = collection
    //   ? homePageData?.getHomePage?.slides?.map((slide) => ({
    //       picture: slide.id,
    //     }))
    //   : slidesFiles.map((slide) => removeTypenameProp(slide));
    if (collection) {
      if (indexCollection !== undefined) {
        collectionsInput[indexCollection] = collection;
        setIndexCollection(undefined);
      } else {
        collectionsInput.push(collection);
      }
    }
    updateHome({
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          // slides: slidesInput || [],
          slides: slidesFiles,
          partnerSlides,
          collections: collectionsInput,
        },
      },
    });
  };

  const handleDeletePartnerSlides = (pictureId: string) => {
    setPartnerSlides((prevSlides) =>
      prevSlides.filter((slideFile) => slideFile?.picture !== pictureId)
    );
  };

  const handleDeleteSlide = (pictureId: string) => {
    setSlidesFiles((prevSlides) =>
      prevSlides.filter((slideFile) => slideFile?.picture !== pictureId)
    );
  };

  const renderTableRows = () => {
    return (
      collections?.map((collection, index) => ({
        position: (
          <TextField
            className="collection__sort__input"
            type="number"
            variant="outlined"
            onChange={(e) => handleOnChangePosition(e, index)}
            value={collection.position}
            size="small"
          />
        ),
        name: find(
          collection.titles,
          (o) => o.languageCode === languageIsMain?.code
        )?.value,
        image: collection.picture?.fileUrl && (
          <img
            className="collection__image"
            src={`${URL_PICTURES}${collection.picture?.fileUrl}`}
            alt="some"
          />
        ),
        tags: collection?.tags?.map((tag) => (
          <Chip key={tag} size="small" label={tag} />
        )),
        actions: (
          <>
            <IconButton
              onClick={() => {
                setCollectionItem(collection);
                setOpenCollection(true);
                setIndexCollection(index);
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              style={{ color: "#ff4949", margin: "0 4px" }}
              onClick={() => setIndexCollection(index)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      })) || []
    );
  };

  const columns: ColumnsProps = [
    { header: "Position", accessor: "position", cellProps: { width: "80px" } },
    { header: "Image", accessor: "image", cellProps: { width: "130px" } },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Tags",
      accessor: "tags",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={1}
        mb={2}
      >
        <BreadCrumbsTitle
          labelIntl={["Store.settings", "Store.settingsHome.title"]}
          iconAlt="Customers"
        />
        <ButtonCustomize
          variant="contained"
          disableElevation
          size="large"
          color="secondary"
          onClick={() => handleUpdateCollection()}
          disabled={loadingUpdate}
        >
          Save home page
        </ButtonCustomize>
      </Box>
      {loading ? (
        <LoadingListHomePage />
      ) : (
        <>
          <Box paper p={2} mb={2}>
            <Typography variant="h2"> Ads Slider </Typography>
            <UploadPictureProduct
              description={intl.formatMessage({
                id: "generalProduct.galleryDescription",
              })}
              uploadTo="collection"
              mode="Gallery"
              urlImageGallery={homePageData?.getHomePage?.slides || []}
              onLoaded={(picture) => {
                setSlidesFiles([...slidesFiles, { picture }]);
              }}
              onDeleted={handleDeleteSlide}
            />
          </Box>

          <Box paper p={2} mb={2}>
            <Typography variant="h2"> Partner Slides </Typography>
            <UploadPictureProduct
              description={intl.formatMessage({
                id: "generalProduct.galleryDescription",
              })}
              uploadTo="collection"
              mode="Gallery"
              urlImageGallery={homePageData?.getHomePage?.partnerSlides || []}
              onLoaded={(picture) => {
                setPartnerSlides([...partnerSlides, { picture }]);
              }}
              onDeleted={handleDeletePartnerSlides}
            />
          </Box>

          <Box paper width={1} p={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h2">Product&apos;s collections</Typography>
              <ButtonCustomize
                variant="contained"
                disableElevation
                size="medium"
                color="primary"
                onClick={() => setOpenCollection(true)}
              >
                Add Collection
              </ButtonCustomize>
            </Box>
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="Add your first collection"
            />
          </Box>
        </>
      )}
      {openCollection && (
        <SettingsHomeCollectionForm
          open={openCollection}
          collectionPayload={collectionItem}
          onClose={() => {
            setOpenCollection(false);
            setCollectionItem(undefined);
            setIndexCollection(undefined);
          }}
          onAddCollection={handleUpdateCollection}
          loading={loadingUpdate}
        />
      )}
      {!openCollection && (
        <Modal
          open={indexCollection !== undefined}
          title="Delete collection"
          message="Do you want to delete this collection"
          handleClose={() => setIndexCollection(undefined)}
          handleContent={() => handleOnDeleteCollection()}
          action="delete"
          loading={false}
        />
      )}
    </Wrapper>
  );
};

export default HomeSettings;
