import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > div {
    display: grid;
  }
  img.logo {
    margin: 40px auto;
  }
  .translate {
    color: #757575;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    /* margin-top: 5px; */
  }
  h3 {
    text-align: center;
    margin-top: 0;
  }
  div.form {
    display: grid;
    grid-row-gap: 15px;
    margin-top: 25px;
  }
`;
