import gql from "graphql-tag";

export const GET_LIST_MENU_TYPES = gql`
  query GetListMenuTypes($input: GetListMenuTypesInput!) {
    getListMenuTypes(input: $input) {
      list {
        id
        identifier
      }
    }
  }
`;

// GET LIST MENUS
export const GETLISTMENUS = gql`
  query getListMenu($token: String!) {
    getListMenus(input: { token: $token }) {
      list {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        languages {
          language {
            id
            code
          }
          isMain
        }
        currency {
          id
          code
        }
        settings {
          currencyDisplay
          pricePrecision
          stockIsActive
          podIsEnabled
          pisIsEnabled
        }
        isPublished
        isMain
      }
    }
  }
`;

// Query Get Menu Details
export const GET_MENU_DETAILS = gql`
  query getMenuDetails($input: GetMenuDetailsInput!) {
    getMenuDetails(input: $input) {
      menu {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        languages {
          language {
            id
            code
          }
          isMain
        }
        currency {
          id
          code
        }

        isMain
        isPublished
      }
    }
  }
`;

// CREATE MENU
export const CREATEMENU = gql`
  mutation CreateMenu($menu: CreateMenuInput!) {
    createMenu(input: $menu) {
      id
      isReached
    }
  }
`;

// UPDATE MENU
export const UPDATEMENU = gql`
  mutation UpdateMenu($menu: UpdateMenuInput!) {
    updateMenu(input: $menu) {
      success
      isReached
    }
  }
`;

// DELETE MENU
export const DELETEMENU = gql`
  mutation DeleteMenu($deleteMenu: DeleteMenuInput!) {
    deleteMenu(input: $deleteMenu) {
      success
    }
  }
`;

// GET LANGUAGE LIST
export const GETLISTLANGUAGES = gql`
  query GetListLanguages {
    getListLanguages {
      list {
        id
        code
      }
    }
  }
`;

// GET COMPANY SITES LIST
export const GETMENULISTCOMPANYSITES = gql`
  query GetListMenuCompanySites($token: String!) {
    getListCompanySites(input: { token: $token }) {
      list {
        id
        names {
          id
          languageCode
          value
        }
      }
    }
  }
`;

// UPDATE LOGO
// export const SETLOGO = gql`
//   mutation SetLogo($token: String!, $id: String!, $logo: String) {
//     updateMenu(input: { token: $token, id: $id, logo: $logo }) {
//       success
//     }
//   }
// `;
