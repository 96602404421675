import gql from "graphql-tag";

export const GET_LIST_CUSTOMERS = gql`
  query GetListCustomers($input: GetListCustomersInput!) {
    getListCustomers(input: $input) {
      list {
        id
        origin
        email
        firstName
        lastName
        phone {
          countryCode
          number
        }
        addressBook {
          id
          primaryPhone {
            countryCode
            number
          }
          address {
            id
            route {
              number
              kind
              name
            }
            floor
            building
            postalCode
            country {
              id
              name
            }
            city {
              id
              name
            }
          }
          isDefault
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
    }
  }
`;

export const ADD_CUSTOMER_ADDRESS = gql`
  mutation AddCustomerAddress($input: AddCustomerAddressInput!) {
    addCustomerAddress(input: $input) {
      id
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      success
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      success
    }
  }
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation RemoveCustomerAddress($input: RemoveCustomerAddressInput!) {
    removeCustomerAddress(input: $input) {
      success
    }
  }
`;

export const EDIT_CUSTOMER_ADDRESS = gql`
  mutation EditCutomerAddress($input: EditCustomerAddressInput!) {
    editCustomerAddress(input: $input) {
      success
    }
  }
`;

export const EXPORT_CUSTOMERS = gql`
  mutation ExportCustomers($input: ExportCustomersInput!) {
    exportCustomers(input: $input) {
      fileUrl
    }
  }
`;
