import _ from "lodash";

import { MenuCategoryPayload } from "../api/types";

export interface MenuCategoryExtended extends MenuCategoryPayload {
  subcategories?: MenuCategoryExtended[];
}

export const regroupCategories = (
  listCategories?: MenuCategoryPayload[]
): MenuCategoryExtended[] => {
  if (!listCategories) return [];

  const categoryByParent = _.groupBy(listCategories, "parent");
  // eslint-disable-next-line
  const newList = categoryByParent.null;
  const getSubcategories = (list: MenuCategoryExtended[]) => {
    // eslint-disable-next-line
    let newArray: MenuCategoryExtended[] = [];
    for (let i = 0; i < list?.length; i++) {
      const categoryId = list[i].id;
      newArray[i] = { ...list[i] };
      if (categoryId && categoryByParent.hasOwnProperty(categoryId)) {
        const subcategories = getSubcategories(categoryByParent[categoryId]);
        newArray[i].subcategories = subcategories;
      }
    }
    return newArray;
  };
  return getSubcategories(newList);
};
