import styled from "styled-components";

export const Wrapper = styled.div`
  .collection__sort__input {
    width: 65px;
  }
  .collection__image {
    min-width: 115px;
    min-height: 60px;
    max-height: 70px;
    object-fit: cover;
    border: 1px solid #e9e9e9;
  }
`;
