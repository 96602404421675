/* eslint-disable */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useQuery } from "@apollo/client";

import * as _ from "lodash";
import { useIntl } from "react-intl";
import Switch from "@material-ui/core/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCheckCircle,
  faSave,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { Box, Input, Breadcrumbs, Select } from "../../commons";
import { EN, FR, AR, ZH, DE, IT, JA, PT, ES, TR } from "../../img";

// import apollo
import { COMPANYORDERAUTHORIZATION } from "../../api";
import {
  CreateNameInput,
  CreateDescriptionInput,
  CheckCompanyOrderAuthorizationQuery,
  CheckCompanyOrderAuthorizationQueryVariables,
  LanguageCode,
} from "../../api/types";

import { UpdateMenuValidation, Error } from "./MenuFormValidation";
import { Note, Wrapper } from "./MenuFormStyles";

type LanguageType = {
  language: string;
  isMain: boolean;
};

type Check = {
  alphanumeric: undefined | boolean;
  specialCaratcters: undefined | boolean;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateMenu: React.FC = () => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";

  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [snackbarMessages, setSnackbarMessages] = React.useState("");
  const [mainLang, setMainLang] = useState<string>();
  const [menuName, setMenuName] = useState<CreateNameInput[]>([
    {
      languageCode: "FR",
      value: "",
    },
  ]);
  const [identifier, setIdentifier] = useState<string | undefined>(undefined);
  const [idMenuName, setIdMenuName] = useState<string | undefined>(undefined);
  const [idMenu, setIdMenu] = useState<string | undefined>(undefined);
  const [publish, setPublish] = useState({
    publish: true,
  });

  const [idCurrency, setIdCurrency] = useState<string | undefined>(undefined);
  // const [deliverySettings, setDeliverySettings] = useState<
  //   DeliverySettingsInput
  // >({});
  const [languageInput, setLanguageInput] = useState<LanguageType[]>([]);
  const [description, setDescription] = useState<CreateDescriptionInput[]>([]);
  const [error, setError] = useState<Error>();
  const [nameError, setNameError] = useState<Check>({
    alphanumeric: undefined,
    specialCaratcters: undefined,
  });
  const [openFormat, setOpenFormat] = useState<boolean>(false);

  // function close Snackbar
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenPreview(false);
  };

  //Query Get Menu Details
  // const { data: MenuDetailsById, loading: loadingMenuDetails } = useQuery<
  //   GetMenuDetailsQuery,
  //   GetMenuDetailsQueryVariables
  // >(GETMENUDETAILS, {
  //   variables: {
  //     input: {
  //       token: token!,
  //       id: location?.state?.input?.id,
  //     },
  //   },
  //   skip: !location?.state?.input?.id,
  //   fetchPolicy: "network-only",
  // });

  // this useEffect use in case update Menu
  // React.useEffect(() => {
  //   let arraySites: SiteInput[] = [];
  //   if (MenuDetailsById?.getMenuDetails?.menu) {
  //     //set id menu
  //     setIdMenu(MenuDetailsById?.getMenuDetails?.menu?.id!);
  //     //set names
  //     MenuDetailsById?.getMenuDetails?.menu?.names &&
  //       setMenuName([
  //         {
  //           ...menuName[0],
  //           value: MenuDetailsById?.getMenuDetails?.menu?.names[0]?.value,
  //         },
  //       ]);
  //     MenuDetailsById?.getMenuDetails?.menu?.identifier &&
  //       setIdentifier(MenuDetailsById?.getMenuDetails?.menu?.identifier);
  //     //set id menu name
  //     MenuDetailsById?.getMenuDetails?.menu?.names &&
  //       MenuDetailsById?.getMenuDetails?.menu?.names[0]?.id &&
  //       setIdMenuName(MenuDetailsById?.getMenuDetails?.menu?.names[0]?.id);
  //     //set stores
  //     MenuDetailsById?.getMenuDetails?.menu?.sites?.map((item) => {
  //       item?.id && arraySites.push({ site: item?.id });
  //     });
  //     setStores(arraySites);
  //     //set publish
  //     setPublish({
  //       publish: MenuDetailsById?.getMenuDetails?.menu?.isPublished
  //         ? MenuDetailsById?.getMenuDetails?.menu?.isPublished
  //         : false,
  //     });
  //     //set id currency
  //     MenuDetailsById?.getMenuDetails?.menu?.currency?.id &&
  //       setIdCurrency(MenuDetailsById?.getMenuDetails?.menu?.currency?.id);
  //     let languages: LanguageType[] = languageInput;

  //     if (
  //       MenuDetailsById?.getMenuDetails?.menu?.languages &&
  //       MenuDetailsById?.getMenuDetails?.menu?.languages?.length > 0
  //     ) {
  //       MenuDetailsById?.getMenuDetails?.menu?.languages?.map((item: any) => {
  //         languages.push({ language: item?.id, isMain: item?.isMain });
  //         if (item?.isMain === true) {
  //           setMainLang(item?.id);
  //         }
  //       });
  //       setLanguageInput(languages);
  //     }

  //     MenuDetailsById?.getMenuDetails?.menu?.characteristics?.map(
  //       (item: any) => {
  //         if (item.kind === "ONLINE_SALE") {
  //           setOnlineSale({
  //             ...onlineSale,
  //             value: item.value,
  //           });
  //         } else if (item.kind === "INSTORE_SALE") {
  //           setInstoreSale({
  //             ...instoreSale,
  //             value: item.value,
  //           });
  //         } else if (item.kind === "WIFI_PASSWORD") {
  //           setWifiPassword({
  //             ...wifiPassword,
  //             value: item.value,
  //           });
  //         } else if (item.kind === "PRICE_PRECISION") {
  //           setPricePrecision({
  //             ...pricePrecision,
  //             value: item.value,
  //           });
  //         }
  //       }
  //     );

  //     setDeliverySettings({
  //       kind: MenuDetailsById?.getMenuDetails?.menu?.deliverySettings
  //         ?.kind as DeliverySettingsKind,
  //       cost: MenuDetailsById?.getMenuDetails?.menu?.deliverySettings?.cost,
  //       limit: MenuDetailsById?.getMenuDetails?.menu?.deliverySettings?.limit,
  //     });
  //   }
  // }, [MenuDetailsById]);

  // useEffect(() => {
  //   let languages: LanguageType[] = languageInput;
  //   if (defaultLanguge !== undefined && !location?.state?.input?.languages) {
  //     languages.push({ language: defaultLanguge[0].id!, isMain: true });
  //     setDescription([...description, { languageCode: "FR", value: "" }]);
  //     setLanguageInput(languages);
  //     setMainLang(defaultLanguge[0].id!);
  //   }
  // }, [languages]);

  /* function */
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenuName([{ ...menuName[0], value: event.target.value }]);
    setError({ ...error, name: undefined });
  };

  const handleIdentifier = (event: React.ChangeEvent<HTMLInputElement>) => {
    let format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
    if (event.target.value) {
      setNameError({ ...nameError, alphanumeric: true });
      if (event.target.value && !format.test(event.target.value)) {
        setNameError({
          ...nameError,
          alphanumeric: true,
          specialCaratcters: true,
        });
      } else {
        setNameError({
          ...nameError,
          alphanumeric: true,
          specialCaratcters: false,
        });
      }
    } else {
      setNameError({ alphanumeric: undefined, specialCaratcters: undefined });
    }
    setIdentifier(
      event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    );
    setError({ ...error, identifier: undefined });
  };

  const handlePublish =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPublish({ ...publish, [name]: event.target.checked });
    };

  const handleChangeLanguage = (
    id: string,
    isMain: boolean,
    code: LanguageCode
  ) => {
    let languages: LanguageType[] = Object.assign([], languageInput);
    let descriptionInput: CreateDescriptionInput[] = Object.assign(
      [],
      description
    );
    if (_.findIndex(languages, { language: id }) === -1) {
      languages.push({ language: id, isMain: isMain });
      setLanguageInput(languages);
      setDescription([...description, { languageCode: code, value: "" }]);
    } else if (
      languages[_.findIndex(languages, { language: id })].isMain !== true &&
      mainLang !== id
    ) {
      languages.splice(_.findIndex(languages, { language: id }), 1);
      descriptionInput.splice(
        _.findIndex(descriptionInput, { languageCode: code }),
        1
      );
      setLanguageInput(languages);
      setDescription(descriptionInput);
    }
  };

  const setMainLanguage = (id: string) => {
    let languages: LanguageType[] = Object.assign([], languageInput);

    languages[_.findIndex(languages, { isMain: true })].isMain = false;

    languages[_.findIndex(languages, { language: id })].isMain = true;
    setLanguageInput(languages);

    setMainLang(id);
  };

  const getLanguage = (code: string) => {
    switch (code) {
      case "FR":
        return {
          name: intl.formatMessage({ id: "Language.french" }),
          flag: FR,
        };
      case "EN":
        return {
          name: intl.formatMessage({ id: "Language.english" }),
          flag: EN,
        };
      case "DE":
        return {
          name: intl.formatMessage({ id: "Language.german" }),
          flag: DE,
        };
      case "ES":
        return {
          name: intl.formatMessage({ id: "Language.spanish" }),
          flag: ES,
        };
      case "IT":
        return {
          name: intl.formatMessage({ id: "Language.italian" }),
          flag: IT,
        };
      case "AR":
        return {
          name: intl.formatMessage({ id: "Language.arabic" }),
          flag: AR,
        };
      case "PT":
        return {
          name: intl.formatMessage({ id: "Language.portogese" }),
          flag: PT,
        };
      case "JA":
        return {
          name: intl.formatMessage({ id: "Language.japanese" }),
          flag: JA,
        };
      case "TR":
        return {
          name: intl.formatMessage({ id: "Language.turkish" }),
          flag: TR,
        };
      case "ZH":
        return {
          name: intl.formatMessage({ id: "Language.chinese" }),
          flag: ZH,
        };
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // let myStr = event.target.value;
    // let newStr = myStr.replace(/,/g, ".");
    // if (newStr.match(/^[-+]?\d*\.?\d*$/)) {
    //   setDeliverySettings({ ...deliverySettings, [event.target.name]: newStr });
    // }
  };

  const handleClick = () => {
    // setDeliverySettings({ ...deliverySettings, cost: 0, limit: 0 });
  };

  //Query Get List Currencies
  // const { data: listCurrency } = useQuery<
  //   GetListCurrenciesQuery,
  //   GetListCurrenciesQueryVariables
  // >(GETLISTCURRENCIES, {
  //   fetchPolicy: "network-only",
  // });

  //for show currency in input price commande
  // const currency = listCurrency?.getListCurrencies?.list?.filter(
  //   (item) => item?.id === idCurrency
  // );

  //query Check COMPANY ORDER AUTHORIZATION

  const { data: CheckAuthorization } = useQuery<
    CheckCompanyOrderAuthorizationQuery,
    CheckCompanyOrderAuthorizationQueryVariables
  >(COMPANYORDERAUTHORIZATION, {
    variables: { token: token! },
  });

  /* mutation Create Menu*/
  // const [CreateMenu, { loading: loadingCreate }] = useMutation<
  //   CreateMenuMutation,
  //   CreateMenuMutationVariables
  // >(CREATEMENU, {
  //   awaitRefetchQueries: true,
  //   refetchQueries: () => [
  //     {
  //       query: GETLISTMENUS,
  //       variables: { token: token },
  //     },
  //   ],
  //   onCompleted: ({ createMenu }) => {
  //     client.writeData({
  //       data: { open: true, alert: successfullyUpdatetd, severity: "success" },
  //     });
  //     history.push({ pathname: "./stores", state: createMenu.isReached });
  //   },
  //   onError: (error) => {
  //     if (error?.graphQLErrors?.[0]?.message === "identifier_already_exists") {
  //       setSnackbarMessages(
  //         intl.formatMessage({ id: "MenuUpdate.identifierAlreadyExists" })
  //       );
  //       setOpen(true);
  //     } else if (
  //       error?.graphQLErrors?.[0]?.message === "identifier_is_not_alphabectic"
  //     ) {
  //       setSnackbarMessages(intl.formatMessage({ id: "format.error" }));
  //       setOpenFormat(true);
  //     }
  //   },
  // });

  /* mutation Update Menu */

  // const [updateMenu, { loading: loadingUpdate }] = useMutation<
  //   UpdateMenuMutation,
  //   UpdateMenuMutationVariables
  // >(UPDATEMENU, {
  //   awaitRefetchQueries: true,
  //   refetchQueries: () => [
  //     {
  //       query: GETLISTMENUS,
  //       variables: { token: token },
  //     },
  //   ],
  //   onCompleted: ({ updateMenu }) => {
  //     client.writeData({ data: { open: true, alert: "" } });
  //     history.push({ pathname: "./stores", state: updateMenu.isReached });
  //   },
  //   onError: (error) => {
  //     if (error?.graphQLErrors?.[0]?.message === "identifier_already_exists") {
  //       setSnackbarMessages(
  //         intl.formatMessage({ id: "MenuUpdate.identifierAlreadyExists" })
  //       );
  //       setOpen(true);
  //     }
  //   },
  // });

  const handleSubmit = () => {
    const validation = UpdateMenuValidation(
      menuName && menuName[0].value!,
      idCurrency!,
      identifier!
    );

    // if (Object.entries(validation).length === 0) {
    //   if (location?.state?.input) {
    //     MenuDetailsById?.getMenuDetails?.menu?.descriptions?.forEach((item) => {
    //       delete item.__typename;
    //     });

    //     MenuDetailsById?.getMenuDetails?.menu?.titles?.forEach((item: any) => {
    //       delete item.__typename;
    //     });

    //     updateMenu({
    //       variables: {
    //         menu: {
    //           token: token,
    //           id: idMenu!,
    //           isPublished: publish.publish,
    //           names: [
    //             {
    //               id: idMenuName,
    //               languageCode: menuName[0].languageCode,
    //               value: menuName[0].value,
    //             },
    //           ],
    //           identifier: identifier,
    //           languages: languageInput,
    //           currency: idCurrency,
    //           logo: MenuDetailsById?.getMenuDetails?.menu?.logo?.id,
    //           descriptions: MenuDetailsById?.getMenuDetails?.menu
    //             ?.descriptions as UpdateDescriptionInput[],
    //           characteristics: [
    //             pricePrecision,
    //             wifiPassword,
    //             instoreSale,
    //             onlineSale,
    //           ],
    //           sites: stores,
    //           titles: MenuDetailsById?.getMenuDetails?.menu
    //             ?.titles as UpdateTitleInput[],
    //           deliverySettings: {
    //             kind: deliverySettings.kind,
    //             cost: parseFloat(String(deliverySettings.cost)),
    //             limit: parseFloat(String(deliverySettings.limit)),
    //           },
    //         },
    //       },
    //     });
    //   } else {
    //     CreateMenu({
    //       variables: {
    //         menu: {
    //           token: token!,
    //           isPublished: publish.publish,
    //           names: menuName,
    //           identifier: identifier,
    //           languages: languageInput,
    //           currency: idCurrency,
    //           logo: null,
    //           characteristics: [
    //             pricePrecision,
    //             wifiPassword,
    //             instoreSale,
    //             onlineSale,
    //           ],
    //           sites: [],
    //           deliverySettings: {
    //             kind: deliverySettings.kind,
    //             cost: parseFloat(String(deliverySettings.cost)),
    //             limit: parseFloat(String(deliverySettings.limit)),
    //           },
    //         },
    //       },
    //     });
    //   }
    // } else {
    //   setError(validation);
    // }
  };

  const getCheckSpecialCaratcters = () => {
    switch (nameError.specialCaratcters) {
      case undefined:
        return "initial";
      case true:
        return "success";
      case false:
        return "error";
    }
  };
  const getCheckAlphanumeric = () => {
    switch (nameError.alphanumeric) {
      case undefined:
        return "initial";
      case true:
        return "success";
      case false:
        return "error";
    }
  };

  return (
    <Wrapper>
      <div className="topbar">
        <Note>
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="2x"
            color="#AFB1C6"
          ></FontAwesomeIcon>
          <div className="content">
            <Breadcrumbs />
            <span>
              {intl.formatMessage({ id: "Store.note1" })}
              {intl.formatMessage({ id: "Store.note2" })}
            </span>
          </div>
        </Note>
        <Button
          data-testid="submit-menu"
          // disabled={loadingCreate || loadingUpdate}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          style={{ position: "relative" }}
        >
          <FontAwesomeIcon
            icon={faSave}
            color={menuName[0].value === "" ? "#a2a2a6" : "#ffffff"}
          />
          <span className="btn-description">
            {intl.formatMessage({ id: "Store.save" })}
          </span>
          {/* {(loadingCreate || loadingUpdate) && (
            <CircularProgress size={24} style={{ position: "absolute" }} />
          )} */}
        </Button>
      </div>
      {
        /*loadingMenuDetails*/ true ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            <div className="topbar">
              {/* <h2>
              {location?.state?.input
                ? menuName[0]?.value
                : intl.formatMessage({ id: "Store.new" })}
            </h2> */}
            </div>
            <div className="main-container">
              <Box width={1}>
                <div className="container">
                  <div className="topbar">
                    {/* <h3>
                      {location?.state?.input
                        ? intl.formatMessage({ id: "Store.updateStore" })
                        : intl.formatMessage({ id: "Store.createStore" })}
                    </h3> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "-15px",
                      }}
                    >
                      <Switch
                        color="primary"
                        checked={publish.publish}
                        onChange={handlePublish("publish")}
                        value="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                      <span className="action-style">
                        {intl.formatMessage({ id: "MenuUpdate.published" })}
                      </span>
                    </div>
                  </div>

                  <div className="box">
                    <div className="sub-box">
                      <span>{intl.formatMessage({ id: "Store.name" })}</span>
                      <Input
                        id="menuName"
                        label={intl.formatMessage({
                          id: "Store.name",
                        })}
                        name="menuName"
                        value={menuName[0]?.value ? menuName[0]?.value : ""}
                        onChange={handleName}
                        style={{
                          width: "300px",
                          marginRight: "30px",
                        }}
                        msgError={
                          error?.name && intl.formatMessage({ id: error?.name })
                        }
                      />
                    </div>
                    <div className="sub-box">
                      <span>
                        {intl.formatMessage({ id: "Store.identifier" })}
                      </span>
                      <Input
                        id="identifier"
                        label={intl.formatMessage({
                          id: "Store.identifier",
                        })}
                        name="identifier"
                        value={identifier || ""}
                        onChange={handleIdentifier}
                        style={{
                          width: "300px",
                        }}
                        msgError={
                          error?.identifier &&
                          intl.formatMessage({ id: error?.identifier })
                        }
                      />
                      <div className="checklist">
                        <div className="tip"></div>
                        <ul>
                          <li className={getCheckAlphanumeric()}>
                            <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                            {intl.formatMessage({ id: "format.alphaneumeric" })}
                          </li>
                          <li className={getCheckSpecialCaratcters()}>
                            <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                            {intl.formatMessage({
                              id: "format.specialCaracters",
                            })}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>

              <Box width={1}>
                <div className="container">
                  <div className="topbar">
                    <h3>
                      {intl.formatMessage({ id: "updateMenu.defaultTheme" })}
                    </h3>
                  </div>
                  <div className="theme-carousel">
                    <div className="theme-info">
                      <div className="theme">
                        <div className="preview">
                          <div
                            className="preview-overlay"
                            onClick={() => {
                              setOpenPreview(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              color="#ffffff"
                            />
                          </div>
                          <img src="#" alt="theme-preview" />
                        </div>
                        <label>Theme name</label>
                        <i></i>
                      </div>
                    </div>
                    <div className="theme-info">
                      <div className="theme">
                        <div className="preview">
                          <div
                            className="preview-overlay"
                            onClick={() => {
                              setOpenPreview(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              color="#ffffff"
                            />
                          </div>
                          <img src="#" alt="theme-preview" />
                        </div>
                        <label>Theme name</label>
                        <i></i>
                      </div>
                    </div>
                    <div className="theme-info">
                      <div className="theme">
                        <div className="preview">
                          <div
                            className="preview-overlay"
                            onClick={() => {
                              setOpenPreview(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              color="#ffffff"
                            />
                          </div>
                          <img src="#" alt="theme-preview" />
                        </div>
                        <label>Theme name</label>
                        <i></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
              <Dialog
                open={openPreview}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending
                    anonymous location data to Google, even when no apps are
                    running.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    Fermer
                  </Button>
                </DialogActions>
              </Dialog>
              <Box width={1}>
                <div className="container">
                  <div className="topbar">
                    <h3>
                      {intl.formatMessage({
                        id: "MenuUpdate.languageSettings",
                      })}
                    </h3>
                  </div>
                  <span style={{ fontSize: "14px" }}>
                    {intl.formatMessage({ id: "Store.menuLanguages" })}
                  </span>
                  <div
                    className="box"
                    style={{ marginTop: "15px" }}
                    data-testid="menu-languages"
                  >
                    {/* {languages !== undefined &&
                    languages.map((language, index) => {
                      return (
                        <Chip
                          key={index}
                          avatar={
                            <Avatar
                              alt={getLanguage(language.code!)?.name}
                              src={getLanguage(language.code!)?.flag}
                            />
                          }
                          label={getLanguage(language.code!)?.name}
                          onClick={() =>
                            handleChangeLanguage(
                              language.id!,
                              false,
                              language.code as LanguageCode
                            )
                          }
                          color={
                            _.findIndex(languageInput, {
                              language: language.id!,
                            }) !== -1
                              ? "primary"
                              : "default"
                          }
                          style={{
                            marginRight: "15px",
                            marginBottom: "10px",
                          }}
                        />
                      );
                    })} */}
                  </div>
                  <span style={{ fontSize: "14px", marginTop: "10px" }}>
                    {intl.formatMessage({ id: "Store.menuMainLanguage" })}
                  </span>
                  <div
                    className="box"
                    style={{ marginTop: "15px" }}
                    data-testid="menu-main-lanugage"
                  >
                    {/* {languages !== undefined &&
                    languages.map((language, index) => {
                      return (
                        <Chip
                          key={index}
                          avatar={
                            <Avatar
                              alt={getLanguage(language.code!)?.name}
                              src={getLanguage(language.code!)?.flag}
                            />
                          }
                          label={getLanguage(language.code!)?.name}
                          onClick={() => setMainLanguage(language.id!)}
                          color={
                            mainLang === language.id! ? "primary" : "default"
                          }
                          style={{
                            marginRight: "15px",
                            marginBottom: "10px",
                          }}
                          disabled={
                            _.findIndex(languageInput, {
                              language: language.id!,
                            }) === -1
                          }
                        />
                      );
                    })} */}
                  </div>
                  <span style={{ fontSize: "14px", marginTop: "10px" }}>
                    Devise
                  </span>
                  <div className="box" style={{ marginTop: "15px" }}>
                    <Select
                      data-testid="input-currency"
                      label="Devise"
                      style={{
                        width: "300px",
                      }}
                      value={idCurrency ? String(idCurrency) : ""}
                      onChange={(e) => {
                        setIdCurrency(e.target.value as string);
                        setError({ ...error, currency: undefined });
                      }}
                      msgError={
                        error?.currency &&
                        intl.formatMessage({ id: error?.currency })
                      }
                    >
                      {/* {listCurrency?.getListCurrencies?.list?.map((item) => (
                      <MenuItem key={item?.id!} value={item?.id!}>
                        {item?.code}
                      </MenuItem>
                    ))} */}
                    </Select>
                  </div>
                </div>
              </Box>

              {CheckAuthorization?.checkCompanyOrderAuthorization
                ?.isAuthorized && (
                <Box width={1}>
                  <div className="container">
                    <div className="topbar">
                      <h3>
                        {intl.formatMessage({ id: "updateMenu.orderSettings" })}
                      </h3>
                    </div>

                    <div className="sub-box">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Switch
                          color="primary"
                          // checked={instoreSale.value === "ON" ? true : false}
                          // onChange={(e) =>
                          //   setInstoreSale({
                          //     kind: "INSTORE_SALE",
                          //     value: e.target.checked ? "ON" : "OFF",
                          //   })
                          // }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <span className="action-style2">
                          {intl.formatMessage({
                            id: "updateMenu.internalOrder",
                          })}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <Switch
                          color="primary"
                          // checked={onlineSale.value === "ON" ? true : false}
                          // onChange={(e) => {
                          //   setOnlineSale({
                          //     kind: "ONLINE_SALE",
                          //     value: e.target.checked ? "ON" : "OFF",
                          //   });
                          // }}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <span className="action-style2">
                          {intl.formatMessage({ id: "updateMenu.onlineOrder" })}
                        </span>
                      </div>
                    </div>
                    {/* {onlineSale.value === "ON" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "14px", marginBottom: "10px" }}>
                        {intl.formatMessage({
                          id: "updateMenu.deliverySettings",
                        })}
                      </span>
                      <RadioGroup
                        aria-label="gender"
                        value={
                          deliverySettings.kind
                            ? String(deliverySettings.kind)
                            : ""
                        }
                        onChange={(e) => {
                          setDeliverySettings({
                            ...deliverySettings,
                            kind: e.target.value as DeliverySettingsKind,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="FREE"
                          control={<Radio color="primary" />}
                          label={intl.formatMessage({ id: "Store.free" })}
                          style={{ width: 90 }}
                          onClick={handleClick}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            value="PAYING"
                            control={<Radio color="primary" />}
                            label={intl.formatMessage({
                              id: "Store.fixedPrice",
                            })}
                            style={{ width: 110 }}
                            onClick={handleClick}
                          />
                          {deliverySettings.kind === "PAYING" && (
                            <React.Fragment>
                              <InputSimple
                                name="cost"
                                value={
                                  deliverySettings.cost
                                    ? String(deliverySettings.cost)
                                    : ""
                                }
                                style={{ width: 190 }}
                                onChange={handleChange}
                                placeholder="Montant"
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  marginRight: 10,
                                  marginLeft: 10,
                                }}
                              >
                                {currency && currency[0]?.code}
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            value="FREE_FROM"
                            control={<Radio color="primary" />}
                            label={intl.formatMessage({
                              id: "Store.freeDeliveryFrom",
                            })}
                            onClick={handleClick}
                          />
                           {deliverySettings.kind === "FREE_FROM" && (
                            <React.Fragment>
                              <InputSimple
                                name="limit"
                                value={
                                  deliverySettings.limit
                                    ? String(deliverySettings.limit)
                                    : ""
                                }
                                style={{ width: 190 }}
                                onChange={handleChange}
                                placeholder={intl.formatMessage({
                                  id: "Store.orderValue",
                                })}
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  marginRight: 10,
                                  marginLeft: 10,
                                }}
                              >
                                {currency && currency[0]?.code}
                                {intl.formatMessage({
                                  id: "updateMenu.deliveryCost",
                                })}
                              </span>
                               <InputSimple
                                name="cost"
                                value={
                                  deliverySettings.cost
                                    ? String(deliverySettings.cost)
                                    : ""
                                }
                                style={{ width: 190 }}
                                onChange={handleChange}
                                placeholder={intl.formatMessage({
                                  id: "updateMenu.deliveryPlaceholder",
                                })}
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  marginRight: 10,
                                  marginLeft: 10,
                                }}
                              >
                                {currency && currency[0]?.code}
                              </span>
                            </React.Fragment>
                          )} 
                        </div>
                      </RadioGroup>
                    </div>
                  )} */}
                  </div>
                </Box>
              )}
            </div>
          </React.Fragment>
        )
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {snackbarMessages}
        </Alert>
      </Snackbar>
      <Snackbar open={openFormat} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {snackbarMessages}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default UpdateMenu;
