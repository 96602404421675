import { LoginInfo } from "./Login";

type Error = Partial<LoginInfo>;

export const loginValidation = (values: LoginInfo): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "email":
        if (!values.email) {
          errors.email = "form.fieldRequired";
        } else if (
          !values.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          errors.email = "form.invalidEmail";
        }
        break;
      case "password":
        if (!values.password) {
          errors.password = "form.fieldRequired";
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
