import { CategoryToAssignPayload } from "../api/types";

export const nameCategory = (
  mainCategory: CategoryToAssignPayload,
  currentCategory: CategoryToAssignPayload,
  language?: string | null
): string | undefined | null => {
  if (mainCategory.level === 1 && currentCategory.level === 1) {
    return mainCategory.names?.find((o) => o.languageCode === language)?.value;
  }
  if (currentCategory.level === 2) {
    return `${
      mainCategory.names?.find((o) => o.languageCode === language)?.value
    } / ${
      currentCategory.names?.find((o) => o.languageCode === language)?.value
    }`;
  }
  if (currentCategory.level && currentCategory.level > 2) {
    return `${
      mainCategory.names?.find((o) => o.languageCode === language)?.value
    } /../ ${
      currentCategory.names?.find((o) => o.languageCode === language)?.value
    }`;
  }
  return "ProductForm.notAssigned";
};
