import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .action {
    display: flex;
    a {
      text-decoration: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .searchInput {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    form {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      input {
        width: 100%;
        padding: 6px 16px;
        border: none;
        border-radius: 5px;
      }
    }
  }
  .container {
    h3 {
      font-size: 20px;
      font-weight: normal;
      color: #444444;
      margin: 0;
    }
    .collections {
      background: #fcfbfb;
      padding: 0 20px;
      margin-top: 20px;
      border: 2px solid #f3f4fa;
      border-radius: 10px;
      .collections__header {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        span {
          flex: 1;
          font-size: 14px;
          color: #444444;
        }
        span:nth-child(0n + 1) {
          max-width: 200px;
          margin-left: 20px;
          text-align: left;
        }
        span:nth-child(0n + 2) {
          text-align: left;
        }
        span:last-child {
          max-width: 100px;
          text-align: center;
        }
      }
      .collections__list {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        .collections__item {
          display: flex;
          justify-content: space-between;
          background: #ffffff;
          border: 2px solid #f3f4fa;
          border-radius: 10px;
          margin-bottom: 5px;
        }
        .collections__item > span {
          display: inline-flex;
          align-items: center;
          flex: 1;
          font-size: 13px;
          color: #444444;
          padding: 8px 0;
          &:nth-child(0n + 1) {
            max-width: 200px;
            margin-left: 20px;
            text-align: left;
          }
          &:nth-child(0n + 2) {
            text-align: left;
          }
          &:last-child {
            max-width: 100px;
            justify-content: center;
          }
        }
        .collections__item:hover {
          border: 2px solid #f29f05;
          box-shadow: 0px 0px 20px rgba(175, 177, 198, 0.28);
          border-radius: 10px;
        }
      }
    }
  }
  .MuiButton-label {
    display: block;
    font-size: 16px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;
