import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .description {
    max-width: 880px;
    margin-top: 10px;
  }
  .action {
    font-weight: 700;
    svg {
      margin-right: 5px;
    }
  }
  .edit {
    margin-right: 20px;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #edeff2;
  margin: 15px 0;
`;
