import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Paper, Typography, MenuItem, Switch } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useQuery, useReactiveVar, useMutation } from "@apollo/client";
import find from "lodash/find";

import { ButtonCustomize, TablePagination, Input, Select } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { AssignProductsModal } from "..";

import {
  GETLISTMENUCATEGORIES,
  SET_SIMILAR_PRODUCTS,
  GET_LIST_MENU_SIMILAR_PRODUCTS,
} from "../../api";
import {
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  SetSimilarProductsMutation,
  SetSimilarProductsMutationVariables,
  GetListMenuSimilarProductsQuery,
  GetListMenuSimilarProductsQueryVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import {
  regroupCategories,
  MenuCategoryExtended,
} from "../../utils/category-utils";

const RelatedProducts: React.FC = () => {
  const token = localStorage.getItem("token") || "";

  const { id: idProduct } = useParams<{ id?: string }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [open, setOpen] = React.useState(false);
  const [randomRelated, setRandomRelated] = React.useState(false);
  const [idCategory, setIdCategory] = React.useState<string | undefined>(
    undefined
  );
  const [count, setCount] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Category",
      accessor: "category",
    },
    {
      header: "SKU",
      accessor: "sku",
    },
  ];

  const { data: listProducts, refetch } = useQuery<
    GetListMenuSimilarProductsQuery,
    GetListMenuSimilarProductsQueryVariables
  >(GET_LIST_MENU_SIMILAR_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        product: idProduct || "",
        isSimilar: true,
      },
    },
    skip: !(localMenu?.id && token && idProduct),
  });

  const renderTableRows = () => {
    return (
      listProducts?.getListMenuSimilarProducts?.list
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((product) => ({
          id: product?.id,
          name: (
            <Typography variant="subtitle1" style={{ margin: "7px 0" }}>
              {
                find(
                  product.names,
                  (o) => o.languageCode === languageIsMain?.code
                )?.value
              }
            </Typography>
          ),
          category: (
            <Typography variant="subtitle1" style={{ margin: "7px 0" }}>
              {
                find(
                  product?.category?.names,
                  (o) => o.languageCode === languageIsMain?.code
                )?.value
              }
            </Typography>
          ),
          sku: (
            <Typography variant="subtitle1" style={{ margin: "7px 0" }}>
              {product.sku}
            </Typography>
          ),
        })) || []
    );
  };

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.id || "" } },
    skip: !(token && localMenu?.id),
  });
  const categoriesListData = listCategory?.getListMenuCategories?.list;

  // refactor list categories
  const categories = React.useMemo(
    () => (categoriesListData ? regroupCategories(categoriesListData) : []),
    [categoriesListData]
  );

  const renderCategorySelectOptions = (
    List: MenuCategoryExtended[]
  ): React.ReactNode => {
    return (
      List.map((category) => [
        <MenuItem
          value={category.id || ""}
          style={{ paddingLeft: `${(category.level || 0) * 10}px` }}
          disabled={!!category?.subcategories}
        >
          {find(category.names, (o) => o.languageCode === languageIsMain?.code)
            ?.value || ""}
        </MenuItem>,

        renderCategorySelectOptions(category.subcategories || []),
      ]) || null
    );
  };

  const [SetSimilar, { loading }] = useMutation<
    SetSimilarProductsMutation,
    SetSimilarProductsMutationVariables
  >(SET_SIMILAR_PRODUCTS, {
    onCompleted: () => {
      refetch();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: (error) => {
      if (error.message === "product_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Product not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const handleRelated = () => {
    if (randomRelated) {
      SetSimilar({
        variables: {
          input: {
            token,
            product: idProduct || "",
            isRandom: randomRelated,
            category: idCategory,
            count,
          },
        },
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <Box display="flex" flexDirection="column" component={Paper} p={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" style={{ fontWeight: 700, marginRight: 10 }}>
            Related Products
          </Typography>
          <Switch
            checked={!randomRelated}
            onChange={() => setRandomRelated(!randomRelated)}
          />
        </Box>
        <ButtonCustomize
          variant="contained"
          color="primary"
          onClick={handleRelated}
          disabled={loading}
        >
          {randomRelated ? "Save" : "Assign products"}
        </ButtonCustomize>
      </Box>

      {!randomRelated && (
        <Box display="flex" flexDirection="column">
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="List similar products is empty"
          />
          <TablePagination
            labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
            count={listProducts?.getListMenuSimilarProducts?.list?.length || 0}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography variant="h2" style={{ fontWeight: 700, marginRight: 10 }}>
          Random related products
        </Typography>
        <Switch
          checked={randomRelated}
          onChange={(e) => setRandomRelated(e.target.checked)}
        />
      </Box>
      {randomRelated && (
        <Box display="flex" alignItems="center">
          <div style={{ width: 300 }}>
            <Input
              type="number"
              label="Count of products"
              value={count}
              onChange={(e) => setCount(+e.target.value)}
            />
          </div>
          <ChevronRightIcon style={{ margin: "0 20px", color: "#4D5E80" }} />
          <div style={{ width: 400 }}>
            <Select
              label="From category"
              value={idCategory}
              onChange={(e) => setIdCategory(e.target.value as string)}
            >
              {renderCategorySelectOptions(categories)}
            </Select>
          </div>
        </Box>
      )}
      <AssignProductsModal open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default RelatedProducts;
