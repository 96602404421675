import React, { useState } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { useQuery, useReactiveVar } from "@apollo/client";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  LoadingListOrders,
} from "../../commons";
import { CardPayment, PaymentFormModal } from "../../components";

import { GET_LIST_PAYMENT_METHODS } from "../../api";
import {
  GetListPaymentMethodsQuery,
  GetListPaymentMethodsQueryVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";

import { Wrapper } from "./Payment--style";

const Payment: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const [open, setOpen] = useState<boolean>(false);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const { data: listPaymentMethods, loading } = useQuery<
    GetListPaymentMethodsQuery,
    GetListPaymentMethodsQueryVariables
  >(GET_LIST_PAYMENT_METHODS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "payment.tite"]}
        icon={StoreSettings}
        iconAlt="Payment"
      />
      <Box display="flex" component={Paper} width={1} p={2}>
        <Box display="flex" width={1} flexDirection="column">
          <Typography
            style={{ fontSize: 22, fontWeight: 700, margin: "0 0 10px 0" }}
          >
            Store payment methods
          </Typography>
          {listPaymentMethods?.getListPaymentMethods?.list?.map((item) => (
            <CardPayment key={item?.id || ""} dataPayment={item} />
          ))}
          {loading && (
            <Box marginTop={2}>
              <LoadingListOrders />
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginLeft={3}
          marginRight={1}
        >
          <Box width="300px" alignItems="center">
            <Typography
              variant="h2"
              style={{ fontWeight: 700, marginBottom: 10 }}
            >
              Add payment method
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "#8587A6", marginBottom: 15 }}
            >
              These are the payment methods available for your store, you can
              add to them custom methods that suits your and your clients needs.
            </Typography>
            <ButtonCustomize
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >
              Add Payment Method
            </ButtonCustomize>
          </Box>
        </Box>
      </Box>
      <PaymentFormModal
        open={open}
        title="Add payment method"
        handleClose={() => setOpen(false)}
      />
    </Wrapper>
  );
};

export default Payment;
