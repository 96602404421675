import gql from "graphql-tag";

export const GETLISTPROMOCODE = gql`
  query GetListPromoCodes($input: GetListPromoCodesInput!) {
    getListPromoCodes(input: $input) {
      list {
        id
        code
        kind
        values {
          percentage
          price {
            value
            currency {
              id
              code
            }
          }
        }
        limits {
          startDate
          endDate
          maximumUses
          isUsedOnce
        }
        requirements {
          kind
          minimumCartTotalPrice
          minimumCartTotalQuantity
          minimumPastOrdersTotalPrice
          minimumPastOrdersTotalQuantity
        }
        targets {
          kind
          customers {
            customer
          }
          groups {
            group
          }
          subscribers {
            subscriber
          }
        }
        filters {
          kind
          collections {
            collection
          }
          categories {
            category
          }
          products {
            product
          }
        }
      }
    }
  }
`;

export const CREATEPROMOCODE = gql`
  mutation CreatePromoCode($input: CreatePromoCodeInput!) {
    createPromoCode(input: $input) {
      id
    }
  }
`;

export const DELETEPROMOCODE = gql`
  mutation DeletePromoCode($input: DeletePromoCodeInput!) {
    deletePromoCode(input: $input) {
      success
    }
  }
`;
