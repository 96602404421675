import React, { useState } from "react";
import { Dialog, Button, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useMutation } from "@apollo/client";

import { Input, DatePicker } from "../../commons";

import { ADD_PRODUCT_INVENTORY_STOCK } from "../../api";
import {
  AddProductInventoryStockMutation,
  AddProductInventoryStockMutationVariables,
  AddProductInventoryStockInput,
} from "../../api/types";

import { snackBarVar } from "../../api/local-state";

import { Wrapper } from "./InventoryStockEntryFormModal--styles";

type StockEntryFormModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  product: string;
  variant?: string;
  site?: string;
  refetch: () => void;
};

const InventoryStockEntryFormModal: React.FC<StockEntryFormModalProps> = ({
  open,
  title,
  handleClose,
  product,
  variant,
  site,
  refetch,
}) => {
  const token = localStorage.getItem("token") || "";

  const [stockEntryForm, setStockEntryForm] =
    useState<AddProductInventoryStockInput>({
      token,
      product: "",
      date: moment(new Date()).utc().format(),
      cost: 0,
      quantity: 0,
    });

  const [quantity, setQuantity] = useState<number>(1);
  const [price, setPrice] = useState("0.00");

  const [CreateProductInventory, { loading }] = useMutation<
    AddProductInventoryStockMutation,
    AddProductInventoryStockMutationVariables
  >(ADD_PRODUCT_INVENTORY_STOCK, {
    onCompleted: () => {
      handleClose();
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully registered!",
      });
      refetch();
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable!",
      });
    },
  });

  const handleSave = () => {
    CreateProductInventory({
      variables: {
        input: {
          ...stockEntryForm,
          quantity,
          cost: parseFloat(price),
          product,
          variant,
          site,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Typography variant="h2" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Input
          label="Quantity"
          value={quantity || ""}
          onChange={(e) => setQuantity(parseFloat(e.target.value))}
        />
        <Input
          label="Cost per item"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label="Acquisition date"
            value={stockEntryForm.date}
            onChange={(date: Date | null) => {
              setStockEntryForm({
                ...stockEntryForm,
                date,
              });
            }}
            format="MM/dd/yyyy"
          />
        </MuiPickersUtilsProvider>
        <Typography variant="body2">
          Entry Status will be set to In Stock
        </Typography>
        <div className="action">
          <Button onClick={handleClose}>Discard</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default InventoryStockEntryFormModal;
