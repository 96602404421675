import React, { useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";

import { Wrapper } from "./TabVarriationStyle";
import { Input } from "../../commons";

type TabPanelProps = {
  index: any;
  value: any;
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        background: "#fff",
        border: "2px solid #F3F4FA",
        borderRadius: 10,
      }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabVarriation: React.FC = () => {
  const [value, setValue] = useState(0);
  const [show, setShow] = useState(true);
  // eslint-disable-next-line
  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Wrapper>
      <Tabs value={value} textColor="primary" onChange={handleChange}>
        <Tab label="Variation 1" {...a11yProps(0)} />
        <Tab label="Variation 2" {...a11yProps(1)} />
        <Tab label="Variation 3" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <h3>Attribute Set 1</h3>
        <div className="sub-title">
          <h4>Attribute header 1</h4>
          <hr />
          {/* eslint-disable-next-line */}
          <i
            className={`arrow ${show && "down"}`}
            role="button"
            tabIndex={0}
            onKeyUp={() => setShow(!show)}
            onClick={() => setShow(!show)}
          />
        </div>
        {show && (
          <>
            <Input label="Attribute 1" style={{ marginBottom: 15 }} />
            <Input label="Attribute 2" style={{ marginBottom: 15 }} />
            <Input label="Attribute 3" />
          </>
        )}
      </TabPanel>
    </Wrapper>
  );
};

export default TabVarriation;
