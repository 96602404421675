import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  > div {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 100px 50px 100px;
    svg {
      margin-bottom: 50px;
    }
    h3 {
      margin: 0;
    }
    p {
      margin: 0 0 30px 0;
    }
  }
`;
