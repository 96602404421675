// Libraries Imports
import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { ButtonCustomize } from "../../../commons";
// Local Imports
import { Wrapper } from "./styles";

// Other Imports
import { ReactComponent as NewStore } from "../../../img/newStore.svg";

const StoreListEmpty: FC = () => {
  return (
    <Wrapper>
      <NewStore />

      <Typography variant="h2" style={{ fontWeight: 700, margin: "10px 0" }}>
        Welcome
      </Typography>

      <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
        Get started by creating your first store.
      </Typography>

      <Link to="/create-store">
        <ButtonCustomize variant="contained" color="secondary" disableElevation>
          Create Store
        </ButtonCustomize>
      </Link>
    </Wrapper>
  );
};

export default StoreListEmpty;
