import React, { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import { Link } from "@material-ui/core";

export type MenuElementType = {
  icon: any;
  label: string;
  link?: string;
  selected?: boolean;
  authorized?: boolean;
  onSelect?: () => void;
};

const MenuElement: FC<MenuElementType> = ({
  icon,
  label,
  link,
  selected,
  authorized,
  onSelect,
}) => {
  const handleClick = () => {
    authorized && onSelect && onSelect();
  };

  return (
    <Link
      // to={authorized ? link : ""}
      to={authorized && link ? link : ""}
      onClick={handleClick}
      // underline="none"
      style={{ margin: "auto", textDecoration: "none" }}
    >
      <Wrapper selected={selected || false} authorized={authorized || false}>
        {icon}
        <label>{label}</label>
      </Wrapper>
    </Link>
  );
};

type WrapperType = {
  selected: boolean;
  authorized: boolean;
};

const Wrapper = styled.div<WrapperType>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: ${(props) => (props.selected ? "#F29F05" : "#f8f8ff")};
  border-radius: 5px;
  color: #444444;
  opacity: ${(props) => (props.authorized ? 1 : 0.5)};
  cursor: ${(props) => (props.authorized ? "pointer" : "not-allowed")};
  svg {
    width: 26px;
    height: 26px;
    margin: 6px 0;
    path {
      fill: #444444;
    }
  }
  label {
    font-weight: bold;
    font-size: 11px;
    text-transform: capitalize;
    cursor: ${(props) => (props.authorized ? "pointer" : "not-allowed")};
  }
`;

export default MenuElement;
