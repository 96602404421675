import React, { useState, useEffect } from "react";
import {
  Dialog,
  Typography,
  MenuItem,
  Box,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useMutation, useReactiveVar } from "@apollo/client";

import { Select, Input, ButtonCustomize } from "../../commons";

import {
  GET_LIST_PAYMENT_METHODS,
  ADD_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD,
} from "../../api";
import {
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables,
  EditPaymentMethodMutation,
  EditPaymentMethodMutationVariables,
  MenuPayload,
  PaymentMethodKind,
  PaymentMethodPayload,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { Container } from "./PaymentFormModal--style";

type PaymentFormModalProps = {
  title: string;
  open: boolean;
  handleClose: () => void;
  dataPayment?: PaymentMethodPayload;
};

const PaymentFormModal: React.FC<PaymentFormModalProps> = ({
  title,
  open,
  handleClose,
  dataPayment,
}) => {
  const token = localStorage.getItem("token") || "";

  const [kind, setKind] = useState<PaymentMethodKind>("STRIPE");
  const [apiPublicKey, setApiPublicKey] = useState<string>("");
  const [apiSecretKey, setApiSecretKey] = useState<string>("");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [AddPaymentMethod, { loading: loadingAdd }] = useMutation<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
  >(ADD_PAYMENT_METHOD, {
    refetchQueries: () => [
      {
        query: GET_LIST_PAYMENT_METHODS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
      setApiPublicKey("");
      setApiSecretKey("");
      setIsEnabled(false);
    },
    onError: (error) => {
      if (error.message === "store_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Store not found",
        });
      } else if (error.message === "payment_method_already_exists") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Payment method already exists",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const [EditPaymentMethod, { loading: loadingEdit }] = useMutation<
    EditPaymentMethodMutation,
    EditPaymentMethodMutationVariables
  >(EDIT_PAYMENT_METHOD, {
    refetchQueries: () => [
      {
        query: GET_LIST_PAYMENT_METHODS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
      setApiPublicKey("");
      setApiSecretKey("");
      setIsEnabled(false);
    },
    onError: (error) => {
      if (error.message === "payment_method_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Payment method not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  useEffect(() => {
    if (dataPayment) {
      dataPayment?.kind && setKind(dataPayment?.kind as PaymentMethodKind);
      dataPayment?.apiPublicKey && setApiPublicKey(dataPayment?.apiPublicKey);
      dataPayment?.apiSecretKey && setApiSecretKey(dataPayment?.apiSecretKey);
      dataPayment?.isEnabled && setIsEnabled(dataPayment?.isEnabled);
    }
  }, [dataPayment]);

  const handleSubmit = () => {
    if (dataPayment?.id) {
      EditPaymentMethod({
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
            id: dataPayment?.id,
            apiPublicKey,
            apiSecretKey,
            isEnabled,
          },
        },
      });
    } else {
      AddPaymentMethod({
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
            kind,
            apiPublicKey,
            apiSecretKey,
            isEnabled,
          },
        },
      });
    }
  };

  const titleSettingPayment = (value: string) => {
    switch (value) {
      case "STRIPE":
        return "stripe";
      case "PAYMEE":
        return "paymee";
      case "TWYLA":
        return "twyla";
      default:
        return "";
    }
  };

  const handleLabelKey1 = (value: string) => {
    switch (value) {
      case "STRIPE":
        return "Public key";
      case "PAYMEE":
        return "Account number";
      case "TWYLA":
        return "Terminal ID";
      default:
        return "";
    }
  };

  const handleLabelKey2 = (value: string) => {
    switch (value) {
      case "STRIPE":
        return "Private key";
      case "PAYMEE":
        return "Api key (token)";
      case "TWYLA":
        return "Password";
      default:
        return "";
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Container>
        <Box display="flex" width={1} justifyContent="space-between">
          <Typography
            variant="h2"
            style={{ fontWeight: 700, marginBottom: 15 }}
          >
            {title}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                onClick={() => setIsEnabled(!isEnabled)}
              />
            }
            label="Enable"
            labelPlacement="start"
          />
        </Box>
        <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
          Choose the type of payment
        </Typography>
        <Select
          label="Type de payment"
          value={kind}
          onChange={(e) => setKind(e.target.value as PaymentMethodKind)}
          disabled={!!dataPayment?.id}
        >
          <MenuItem value="STRIPE">Stripe</MenuItem>
          <MenuItem value="TWYLA">Twyla</MenuItem>
          <MenuItem value="PAYMEE">Paymee</MenuItem>
          {/* <MenuItem>Fatora</MenuItem> */}
        </Select>
        <Typography variant="subtitle1" style={{ margin: "10px 0" }}>
          Set {titleSettingPayment(kind)} settings
        </Typography>
        <Input
          label={handleLabelKey1(kind)}
          value={apiPublicKey}
          onChange={(e) => setApiPublicKey(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Input
          label={handleLabelKey2(kind)}
          value={apiSecretKey}
          onChange={(e) => setApiSecretKey(e.target.value)}
          style={{ marginBottom: 15 }}
        />
        <Box
          display="flex"
          width={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ButtonCustomize style={{ marginRight: 10 }} onClick={handleClose}>
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={loadingAdd || loadingEdit}
          >
            Save
          </ButtonCustomize>
        </Box>
      </Container>
    </Dialog>
  );
};

export default PaymentFormModal;
