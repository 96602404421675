import Tabs from "@material-ui/core/Tabs";
import styled from "styled-components";

const TabsStyled = styled(Tabs)`
  .MuiTab-root {
    padding: 13px 5px;
  }
  .MuiTab-wrapper {
    text-transform: capitalize;
    font-size: 16px;
  }
  .MuiTab-textColorSecondary {
    color: #bcbed1;
    font-weight: 600;
  }
  .MuiTab-textColorSecondary.Mui-selected {
    color: #e99b0c;
  }
`;

export default TabsStyled;
