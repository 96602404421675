import React, { FC } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import TreeItemMUI, { TreeItemProps } from "@material-ui/lab/TreeItem";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

type TreeItemStyledProps = {
  labelText: string;
  out?: boolean;
  low?: boolean;
} & TreeItemProps;

const TreeItem: FC<TreeItemStyledProps> = ({
  labelText,
  children,
  out,
  low,
  ...restProps
}) => {
  return (
    <TreeItemStyled
      label={
        <div>
          <Typography variant="body2">{labelText}</Typography>
          {out && <ReportProblemOutlinedIcon color="error" />}
          {low && <ErrorOutlineIcon color="secondary" />}
        </div>
      }
      {...restProps}
    >
      {children}
    </TreeItemStyled>
  );
};

const TreeItemStyled = styled(TreeItemMUI)`
  .MuiTreeItem-content {
    .MuiTreeItem-label {
      padding: 5px;
    }
    .MuiTypography-root {
      margin: 0;
      & > div {
        display: flex;
        align-items: center;
        padding: 2px svg {
          width: 15px;
          height: 15px;
        }
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .MuiTypography-body2 {
        font-size: 14px;
      }
    }
  }
`;

export default TreeItem;
