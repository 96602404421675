import { UpdateRoleInput } from "../../../api/types";

export type Error = {
  kind?: string;
};

export const ValidationForm = (values: UpdateRoleInput): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "kind":
        if (!values.kind) {
          errors.kind = "This field is required";
        } else if (!values.kind.match(/^[a-zA-Z ]+$/)) {
          errors.kind = "must only accept letters";
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
