import gql from "graphql-tag";

// LOGIN
export const SIGNIN = gql`
  mutation signIn($login: String!, $password: String!) {
    signIn(input: { login: $login, password: $password }) {
      token
      kind
      missingStep
      hasMultiVendor
    }
  }
`;
