import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker, BaseDatePickerProps } from "@material-ui/pickers";

type InputProps = BaseDatePickerProps & {
  label?: string;
  msgError?: string;
  fullWidth?: boolean;
  value?: string | null;
  onChange?: any;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  variant?: "dialog" | "inline" | "static";
};

const DatePicker: React.FC<InputProps> = ({
  label,
  msgError,
  fullWidth,
  value,
  onChange,
  format,
  placeholder,
  disabled,
  variant,
  ...restProps
}) => {
  return (
    <FormControlStyled
      variant="filled"
      error={msgError !== "" && msgError !== undefined}
      fullWidth={fullWidth}
    >
      <InputLabel htmlFor="component-filled" shrink>
        {label}
      </InputLabel>

      <KeyboardDatePicker
        value={value}
        onChange={onChange}
        variant={variant}
        format={format}
        error={msgError !== "" && msgError !== undefined}
        {...restProps}
        placeholder={placeholder}
        disabled={disabled}
      />
      {msgError && <FormHelperText> {msgError} </FormHelperText>}
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)`
  .MuiInputBase-root {
    height: auto;
    overflow: "hidden";
    background-color: #ffffff;
    padding: 21px 12px 3px;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
    &:focus-within {
      border: 2px solid #000000;
    }
  }
  .MuiInputBase-root.Mui-error {
    border: 2px solid #f67070;
  }
  .MuiIconButton-root {
    top: -8px;
    right: -10px;
  }
  .MuiInputBase-root.Mui-disabled {
    background-color: rgb(103 100 100 / 18%);
    &:hover {
      background-color: #ffffff;
    }
  }
`;

export default DatePicker;
