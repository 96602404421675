import React from "react";
import ContentLoader from "react-content-loader";

type LoadingListAddressProps = {
  height?: any;
};

const LoadingList: React.FC<LoadingListAddressProps> = ({ height }) => {
  return (
    <ContentLoader height={height || 600} width="100%" speed={2}>
      <rect x="10" y="20" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="85" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="150" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="215" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="280" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="345" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="410" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="475" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="540" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="605" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="670" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="745" rx="3" ry="3" width="100%" height="50" />
      <rect x="10" y="810" rx="3" ry="3" width="100%" height="50" />
    </ContentLoader>
  );
};

export default LoadingList;
