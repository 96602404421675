import * as React from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import { useReactiveVar, useMutation, useQuery } from "@apollo/client";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import find from "lodash/find";
import { ReactSortable } from "react-sortablejs";
import debounce from "lodash/debounce";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Modal,
} from "../../../commons";
import TableSortable, { ColumnsProps } from "../../../commons/TableSortable";
import { FormMenuItemModal } from "../../../components";

import {
  SET_NAVIGATION,
  GET_MENU_NAVIGATION,
  SET_ORDER_NAVIGATION,
} from "../../../api";
import {
  SetNavigationMutation,
  SetNavigationMutationVariables,
  GetMenuNavigationQuery,
  GetMenuNavigationQueryVariables,
  SetOrderNavigationMutation,
  SetOrderNavigationMutationVariables,
  MenuPayload,
  NavigationKind,
  SubNavigationPayload,
  NavigationOrderInput,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

import { DragIcon } from "../../../img/digishop/menu-icons";
import StoreSettings from "../../../img/digishop/menu-icons/StoreSettings.svg";

interface ItemType {
  id: number;
  navigation: SubNavigationPayload;
}

const FormStoreMenu = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const path = useLocation().pathname;
  const history = useHistory();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [open, setOpen] = React.useState<boolean>(false);
  const [label, setLabel] = React.useState("");
  const [idItem, setIdItem] = React.useState<string | undefined>(undefined);
  const [parent, setParent] = React.useState<string | undefined>(undefined);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [subNavigation, setSubNavigation] =
    React.useState<SubNavigationPayload>();
  const [listMenuNavigation, setListMenuNavigation] = React.useState<
    ItemType[]
  >([]);
  const [orderNavigatioSortable, setOrderNavigatioSortable] = React.useState<
    NavigationOrderInput[]
  >([]);

  const { data, refetch } = useQuery<
    GetMenuNavigationQuery,
    GetMenuNavigationQueryVariables
  >(GET_MENU_NAVIGATION, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
  });

  const dataNavigation = data?.getMenuNavigation?.list;

  React.useEffect(() => {
    if (dataNavigation) {
      setLabel(dataNavigation?.[0]?.label || "");
      const arrayMenuNavigation: ItemType[] = [];

      dataNavigation?.[0]?.navigation
        ?.slice(0)
        ?.reverse()
        ?.forEach((item) =>
          arrayMenuNavigation.push({
            id: item.order!,
            navigation: item,
          })
        );
      setListMenuNavigation(arrayMenuNavigation);
    }
  }, [dataNavigation]);

  const [setNavigation, { loading }] = useMutation<
    SetNavigationMutation,
    SetNavigationMutationVariables
  >(SET_NAVIGATION, {
    refetchQueries: () => [
      {
        query: GET_MENU_NAVIGATION,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      setOpenModalDelete(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "successful operation",
      });
    },
  });

  const [setOrderNavigation] = useMutation<
    SetOrderNavigationMutation,
    SetOrderNavigationMutationVariables
  >(SET_ORDER_NAVIGATION, {
    variables: {
      input: {
        token,
        store: localMenu?.id || "",
        navigationOrder: orderNavigatioSortable,
      },
    },
    onCompleted: () => {
      refetch();
    },
  });

  const handleOrderNavigation = (values: ItemType[]) => {
    const arrayOrderNavigation: NavigationOrderInput[] = [];
    values?.forEach((item, index) => {
      arrayOrderNavigation.push({
        id: item.navigation.id!,
        order: index + 1,
      });
    });
    setOrderNavigatioSortable(arrayOrderNavigation);
  };

  const sendOrder = React.useCallback(
    debounce(() => setOrderNavigation(), 2000),
    []
  );

  const columns: ColumnsProps = [
    {
      header: "",
      accessor: "icon",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "30px" },
    },
    {
      header: "Label",
      accessor: "label",
    },
    {
      header: "Type",
      accessor: "type",
    },
    {
      header: "Link",
      accessor: "link",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listMenuNavigation?.map((item) => ({
        id: item?.navigation?.id,
        icon: <DragIcon className="grabbing" />,
        label: item?.navigation?.label,
        type: item?.navigation?.kind,
        link: item?.navigation?.text,
        actions: (
          <Box display="flex">
            <IconButton
              size="small"
              onClick={() => {
                item?.navigation?.id && setIdItem(item?.navigation?.id);
                setSubNavigation(item?.navigation);
                setOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                item?.navigation?.id && setParent(item?.navigation?.id);
                setOpenModalDelete(true);
              }}
              style={{ color: "#F67070" }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      })) || []
    );
  };

  const handleDeleteItem = () => {
    const findItem = find(
      dataNavigation?.[0]?.navigation,
      (o) => o.id === parent
    );
    setNavigation({
      variables: {
        input: {
          token,
          store: localMenu?.id || "",
          id: findItem?.id,
          kind: findItem?.kind as NavigationKind,
          label: findItem?.label,
          isMain: false,
          isHierarchical: false,
          isDeleted: true,
        },
      },
    });
  };

  const handleSubmit = () => {
    setNavigation({
      variables: {
        input: {
          token,
          store: localMenu?.id || "",
          id: dataNavigation?.[0]?.id,
          kind: "MAIN_NAV",
          label,
          isMain: true,
          isHierarchical: false,
          isDeleted: false,
          order: dataNavigation?.length || 0,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <BreadCrumbsTitle
          labelIntl={[
            "Store settings",
            `${path === "/edit-store-menu" ? "Edit Menu" : "Add Menu"}`,
          ]}
          icon={StoreSettings}
          iconAlt="store menu"
        />
        <Box>
          <ButtonCustomize variant="contained" style={{ marginRight: 10 }}>
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </ButtonCustomize>
        </Box>
      </Box>
      <ButtonCustomize
        onClick={() => history.goBack()}
        style={{ width: 100, color: "#0052B4" }}
      >
        <ArrowBackIcon /> Return
      </ButtonCustomize>
      <Box
        display="flex"
        flexDirection="column"
        component={Paper}
        marginTop={1}
        p={2}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 700, marginBottom: 3 }}
        >
          Menu title
        </Typography>
        <Input
          label="Menu title"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          style={{ width: 450 }}
        />
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 700, marginTop: 10 }}
        >
          Menu Items
        </Typography>
        <Box maxWidth={900} marginTop={1}>
          {renderTableRows() && (
            <TableSortable
              columns={columns}
              data={renderTableRows()}
              emptyMessage="You have no created Item."
              sortable={({ children }: any) => (
                <ReactSortable
                  list={listMenuNavigation}
                  setList={(listData) => {
                    if (
                      isEmpty(
                        differenceWith(listMenuNavigation, listData, isEqual)
                      )
                    ) {
                      setListMenuNavigation(listData);
                      handleOrderNavigation(listData);
                    }
                  }}
                  onSort={() => sendOrder()}
                  animation={200}
                >
                  {children || null}
                </ReactSortable>
              )}
            />
          )}
        </Box>
        <ButtonCustomize
          onClick={() => setOpen(true)}
          className="add-item"
          disabled={(dataNavigation?.length || 0) === 0}
        >
          <AddIcon /> &nbsp; Add menu item
        </ButtonCustomize>
      </Box>
      <FormMenuItemModal
        open={open}
        idItem={idItem}
        parent={dataNavigation?.[0]?.id || ""}
        subNavigation={subNavigation}
        onClose={() => setOpen(false)}
        order={listMenuNavigation?.length + 1}
      />

      <Modal
        open={openModalDelete}
        title="Delete Item"
        message="Are you sure you want to remove this item ?"
        handleClose={() => setOpenModalDelete(false)}
        handleContent={handleDeleteItem}
        action="delete"
      />
    </Wrapper>
  );
};

export default FormStoreMenu;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .add-item {
    width: 145px;
    margin-top: 5px;
    color: #0052b4;
  }
  .MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
  .grabbing {
    cursor: grabbing;
  }
`;
