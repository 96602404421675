// Libraries Imports
import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Tooltip,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import { useMutation } from "@apollo/client";

import { Wrapper } from "./styles";
import { OrderProductItem } from "../../commons";

import { ChevronDown } from "../../img/digishop/menu-icons";
import { ReactComponent as EyeShow } from "../../img/eyeShow.svg";
import { ReactComponent as Print } from "../../img/print.svg";
import defaultDictureProduct from "../../img/digishop/default-picture-product.png";

// API Imports
import {
  URL_THEME_PICTURES,
  UPDATE_MENU_ORDERS_STATUS,
  UPDATE_MENU_ORDER_PAYMENT_STATUS,
  HOST_FORSA,
} from "../../api";
import {
  MenuOrderPayload,
  UpdateMenuOrderStatusMutation,
  UpdateMenuOrderStatusMutationVariables,
  UpdateMenuOrderPaymentStatusMutation,
  UpdateMenuOrderPaymentStatusMutationVariables,
  OrderStatus,
  PaymentStatus,
} from "../../api/types";

import { handleStatusOrder, handlePaymentOrder } from "./OrderCard--utils";
import { Lightning } from "../../img";

// Types

type OrderCardProps = {
  menuOrder: MenuOrderPayload;
  refetchOrders: () => void;
  orderToPrint?: (menuOrder: MenuOrderPayload) => void;
};

const { host } = window.location;

const OrderCard: FC<OrderCardProps> = ({
  menuOrder,
  refetchOrders,
  orderToPrint,
}) => {
  const {
    id,
    reference,
    confirmationPayment,
    createdAt,
    customer,
    deliveryAddress,
    cartDetails,
    orderStatus,
    paymentStatus: paymentSt,
    deliveryMethod,
  } = menuOrder;

  console.log("#menuOrder", menuOrder);

  const kind = localStorage.getItem("kind");
  const histroy = useHistory();
  // States
  const [openOrderInfo, setOpenOrderInfo] = useState<boolean>(false);
  const [openCustomerInfo, setOpenCustomerInfo] = useState<boolean>(false);
  const [openProductsInfo, setOpenProductsInfo] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [fulfillmentStatus, setFulfillmentStatus] = useState<string>("");

  const [thumbnails, setThumbnails] = useState<
    { id: string; picture: string }[]
  >([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElPayment, setAnchorElPayment] = useState<null | HTMLElement>(
    null
  );
  const [anchorElOrder, setAnchorElOrder] = useState<null | HTMLElement>(null);

  // Functions

  const handleExpand = (key: "order" | "customer" | "products") => {
    switch (key) {
      case "order":
        setOpenOrderInfo(!openOrderInfo);
        break;
      case "customer":
        setOpenCustomerInfo(!openCustomerInfo);
        break;
      case "products":
        setOpenProductsInfo(!openProductsInfo);
        break;
      default:
        setOpenOrderInfo(!openOrderInfo);
    }
  };

  useEffect(() => {
    const arrayPicture: { id: string; picture: string }[] = [];
    menuOrder?.cartDetails?.items?.forEach((item) => {
      arrayPicture.push({
        id: item?.product?.picture?.id || "",
        picture:
          URL_THEME_PICTURES?.concat(item?.product?.picture?.fileUrl || "") ||
          "",
      });
    });
    setThumbnails(arrayPicture);
  }, [menuOrder]);

  const countThumbnails = thumbnails?.length || 0;

  useEffect(() => {
    setFulfillmentStatus(orderStatus || "");
    setPaymentStatus(paymentSt || "");
  }, [orderStatus, paymentSt]);

  const [UpdateOrdersStatus, { loading }] = useMutation<
    UpdateMenuOrderStatusMutation,
    UpdateMenuOrderStatusMutationVariables
  >(UPDATE_MENU_ORDERS_STATUS, {
    onCompleted: () => {
      refetchOrders();
    },
  });

  const [UpdateOrdersPaymentStatus, { loading: loadingPayment }] = useMutation<
    UpdateMenuOrderPaymentStatusMutation,
    UpdateMenuOrderPaymentStatusMutationVariables
  >(UPDATE_MENU_ORDER_PAYMENT_STATUS, {
    onCompleted: () => {
      refetchOrders();
    },
  });

  const handleChangeOrderStatus = (idOrder: string, status: OrderStatus) => {
    UpdateOrdersStatus({
      variables: {
        input: {
          order: idOrder,
          status,
        },
      },
    });
    setAnchorElOrder(null);
  };

  const handleChangeOrderPaymentStatus = (
    idOrder: string,
    status: PaymentStatus
  ) => {
    UpdateOrdersPaymentStatus({
      variables: {
        input: {
          order: idOrder,
          status,
        },
      },
    });
    setAnchorElPayment(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Box width={1} p={2} border="2px solid #EFEFEF" borderRadius="10px">
        <div className="header">
          <div>
            <Typography variant="subtitle1" style={{ color: "#8689A3" }}>
              Order N°: {reference}
            </Typography>
            {host === HOST_FORSA && (
              <Typography variant="subtitle1" style={{ color: "#8689A3" }}>
                Confirmation Payment :{" "}
                <span style={{ fontWeight: 700 }}> {confirmationPayment} </span>
              </Typography>
            )}
            <Typography
              variant="subtitle1"
              style={{ textTransform: "capitalize" }}
            >
              {customer?.firstName} &nbsp; {customer?.lastName}
            </Typography>
          </div>
          <span className="order-price">
            Amount: &nbsp;
            <strong>
              {Math.trunc((cartDetails?.totalPrice?.value || 0) * 100) / 100}{" "}
              &nbsp;
              {cartDetails?.totalPrice?.currency?.code}
            </strong>
            {deliveryMethod === "EXPRESS" && (
              <img
                style={{ marginLeft: 5 }}
                src={Lightning}
                alt="lightning_icon"
                width="22"
                height="22"
              />
            )}
          </span>

          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            {moment(createdAt).format("MMMM Do YYYY, h:mm")}
          </Typography>

          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            style={{ width: 35, height: 35 }}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Tooltip title={!openOrderInfo ? "Expand" : "Collapse"}>
            <button
              type="button"
              className="expand"
              onClick={() => handleExpand("order")}
            >
              <ChevronDown
                width="18"
                height="18"
                style={{
                  transform: !openOrderInfo
                    ? "rotate(0deg)"
                    : "rotate(-180deg)",
                }}
              />
            </button>
          </Tooltip>
        </div>
        <div
          className={`order-content ${openOrderInfo ? "show" : "hide"}`}
          style={{ marginTop: 19 }}
        >
          <div className="sub-header">
            <div>
              <Button
                variant="outlined"
                color="secondary"
                disableRipple
                onClick={(event) => setAnchorElPayment(event.currentTarget)}
                disabled={kind === "Vendor" || loadingPayment}
                style={{
                  minWidth: "180px",
                  paddingRight: "25px",
                  paddingLeft: "0",
                }}
              >
                {handlePaymentOrder(paymentStatus)}
                <div className="arrow-down payment">
                  <ChevronDown width="16" height="16" />
                </div>
              </Button>

              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={(event) => setAnchorElOrder(event.currentTarget)}
                style={{
                  minWidth: "180px",
                  paddingRight: "25px",
                  paddingLeft: "0",
                  marginLeft: "30px",
                }}
                disabled={loading || kind === "Vendor"}
              >
                {handleStatusOrder(fulfillmentStatus)}
                <div className="arrow-down fulfillment">
                  <ChevronDown width="16" height="16" />
                </div>
              </Button>
            </div>
          </div>
          <div className="customer-information show">
            <Typography
              variant="subtitle2"
              style={{ textTransform: "capitalize" }}
            >
              {customer?.firstName} &nbsp; {customer?.lastName}:
              <a className="email-link" href={`mailto:${customer?.email}`}>
                {customer?.email}
              </a>
            </Typography>
            <Typography variant="subtitle2">
              {deliveryAddress?.primaryPhone?.countryCode} &nbsp;
              {deliveryAddress?.primaryPhone?.number}
            </Typography>
            <Typography variant="subtitle2">
              {/* {deliveryAddress?.address?.roadNames?.[0]?.value} */}
              {deliveryAddress?.address?.building} &nbsp;
              {deliveryAddress?.address?.floor} &nbsp;
              {deliveryAddress?.address?.route?.number} &nbsp;
              {deliveryAddress?.address?.route?.kind} &nbsp;
              {deliveryAddress?.address?.route?.name}
            </Typography>
            <Typography variant="subtitle2">
              {deliveryAddress?.address?.city?.name} &nbsp;
              {deliveryAddress?.address?.postalCode}
            </Typography>
            <Typography variant="subtitle2">
              {deliveryAddress?.address?.country?.name}
            </Typography>
          </div>

          <div className="order-content">
            <div className="sub-header">
              <div className="title-product">
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 700, marginRight: 10 }}
                >
                  Products
                </Typography>
                <Typography variant="subtitle2" style={{ color: "#AFB1C6" }}>
                  {(cartDetails?.count || 0) <= 1
                    ? `(${cartDetails?.count} item)`
                    : `(${cartDetails?.count} items)`}
                </Typography>
              </div>
              <Tooltip title={!openProductsInfo ? "Expand" : "Collapse"}>
                <button
                  type="button"
                  className="expand"
                  onClick={() => handleExpand("products")}
                >
                  <ChevronDown
                    width="18"
                    height="18"
                    style={{
                      transform: !openProductsInfo
                        ? "rotate(0deg)"
                        : "rotate(-180deg)",
                    }}
                  />
                </button>
              </Tooltip>
            </div>
            {!openProductsInfo && (
              <div className="pictures-products">
                {thumbnails?.map((item, index) => (
                  <>
                    {index <= 4 && (
                      <>
                        {item.id ? (
                          <img
                            src={item?.picture || ""}
                            key={item?.id || ""}
                            alt="product"
                          />
                        ) : (
                          <img src={defaultDictureProduct} alt="product" />
                        )}
                      </>
                    )}
                  </>
                ))}
                {countThumbnails > 4 && <div>+&nbsp;{countThumbnails - 4}</div>}
              </div>
            )}
            {openProductsInfo &&
              menuOrder?.cartDetails?.items?.map((item) => (
                <OrderProductItem key={item.id || ""} data={item} />
              ))}
          </div>
        </div>
      </Box>
      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleCloseAnchorEl();
            histroy.push({
              pathname: "/order",
              state: menuOrder,
            });
          }}
        >
          <EyeShow width="20" height="20" /> &nbsp; View Order
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseAnchorEl();
            orderToPrint && orderToPrint(menuOrder);
          }}
        >
          <Print width="20" height="20" /> &nbsp; Print
        </MenuItem>
      </Menu>
      {/* list for change status payment */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorElPayment}
        getContentAnchorEl={null}
        open={Boolean(anchorElPayment)}
        onClose={() => setAnchorElPayment(null)}
      >
        <MenuItem
          onClick={() => handleChangeOrderPaymentStatus(id || "", "PAID")}
          disabled={paymentStatus === "PAID"}
        >
          Paid
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderPaymentStatus(id || "", "WAITING")}
          disabled={paymentStatus === "WAITING"}
        >
          Awaiting Payment
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderPaymentStatus(id || "", "REFUNDED")}
          disabled={paymentStatus === "REFUNDED"}
        >
          Refund
        </MenuItem>

        <MenuItem
          onClick={() => handleChangeOrderPaymentStatus(id || "", "CANCELED")}
          disabled={paymentStatus === "CANCELED"}
        >
          Cancel
        </MenuItem>
      </Menu>
      {/* list for change status order */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorElOrder}
        getContentAnchorEl={null}
        open={Boolean(anchorElOrder)}
        onClose={() => setAnchorElOrder(null)}
      >
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "WAITING")}
          disabled={fulfillmentStatus === "WAITING"}
        >
          Awaiting processing
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "ONGOING")}
          disabled={fulfillmentStatus === "ONGOING"}
        >
          Processing
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "READY")}
          disabled={fulfillmentStatus === "READY"}
        >
          Ready for pickup
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "IN_DELIVERING")}
          disabled={fulfillmentStatus === "IN_DELIVERING"}
        >
          Shipped
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "CANCELED")}
          disabled={fulfillmentStatus === "CANCELED"}
        >
          Canceled
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "DELIVERED")}
          disabled={fulfillmentStatus === "DELIVERED"}
        >
          Delivered
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeOrderStatus(id || "", "UNDELIVERED")}
          disabled={fulfillmentStatus === "UNDELIVERED"}
        >
          Not delivered
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default OrderCard;
