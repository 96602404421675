/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type InputTagsProps = {
  label?: string;
  onChange: (list: string[]) => void;
  tags: string[];
  lengthNotDelete?: number;
};

const InputTags: React.FC<InputTagsProps> = ({
  label,
  onChange,
  tags,
  lengthNotDelete,
}) => {
  const [value, setValue] = useState("");
  // const [listTags, setListTags] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValue(event.target.value);
  };

  const handleKeywordKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      const arrayListTags = [...tags];
      if (arrayListTags.indexOf(value) === -1 && value !== "") {
        arrayListTags.push(value);
        setValue("");
      }
      onChange(arrayListTags);
    }
  };

  const handleDelete = (index: number) => {
    const arrayListTags = [...tags];
    arrayListTags.splice(index, 1);
    onChange(arrayListTags);
  };

  const length = lengthNotDelete || 0;

  return (
    <Container>
      <div className="tags-container">
        {label && <label> {label} </label>}
        <div className="tags">
          {tags?.map((item, index) => (
            <div key={`key-${item}`} className="tag">
              <span>{item}</span>
              {String(lengthNotDelete) !== "undefined" ? (
                length < index && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="1x"
                    className="iconClose"
                    onClick={() => handleDelete(index)}
                  />
                )
              ) : (
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  className="iconClose"
                  onClick={() => handleDelete(index)}
                />
              )}
            </div>
          ))}
          <input
            value={value}
            onChange={handleChange}
            onKeyPress={handleKeywordKeyPress}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  .tags-container {
    display: flex;
    flex-direction: column;
    border: 2px solid #f1f2ff;
    padding: 9px 5px;
    border-radius: 5px;
    &:focus-within {
      border: 2px solid #000000;
      border-radius: 5px;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        display: flex;
        align-items: center;
        padding: 0 5px;
        background: #000000;
        /* background: #74abff; */
        color: white;
        margin: 5px 5px 2px 5px;
        border-radius: 4px;
        height: 23px;
        font-size: 13px;
        .iconClose {
          width: 15px;
          margin-left: 3px;
          margin-top: 2px;
          padding: 3px;
        }
        .iconClose:hover,
        .iconClose:active {
          background: #c5c5c5;
          padding: 3px;
          border-radius: 50%;
        }
      }
    }
    input {
      flex: 1;
      padding: 3px;
      margin: 0 5px;
      outline: none;
      border: 0;
    }
  }
  label {
    color: #757575;
    font-size: 12px;
    line-height: 1.25;
    margin-left: 5px;
  }
`;

export default InputTags;
