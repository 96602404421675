import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { address as addressIcon } from "../../img";
import vector from "../../img/Vector.png";
import { DialogStyled } from "./CustomersAddressModalStyle";
import { Input, InputPhone } from "../../commons";

import {
  GETLISTCOUNTRIES,
  GETLISTCITIES,
  ADD_CUSTOMER_ADDRESS,
  GET_LIST_CUSTOMERS,
  EDIT_CUSTOMER_ADDRESS,
} from "../../api";
import {
  GetListCountriesQuery,
  GetListCountriesQueryVariables,
  GetListCitiesQuery,
  GetListCitiesQueryVariables,
  AddCustomerAddressMutation,
  AddCustomerAddressMutationVariables,
  EditCutomerAddressMutation,
  EditCutomerAddressMutationVariables,
  CityPayload,
  CountryPayload,
  AddressBookPayload,
  RouteInput,
} from "../../api/types";
import { snackBarVar } from "../../api/local-state";

type ModalFormAddressProps = {
  open: boolean;
  handleClose: (email: string) => void;
  customerId: string;
  menu: string;
  customerEmail: string;
  address?: AddressBookPayload | null;
};

const ModalFormAddress: React.FC<ModalFormAddressProps> = ({
  open,
  handleClose,
  customerId,
  menu,
  customerEmail,
  address,
}) => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();

  const [floor, setFloor] = useState<string>("");
  const [building, setBuilding] = useState<string>("");
  const [route, setRoute] = useState<RouteInput | undefined>(undefined);
  const [countryCode, setCountryCode] = useState<string>("216");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [country, setCountry] = useState<CountryPayload | undefined>();
  const [city, setCity] = useState<CityPayload | undefined>();
  const [checked, setChecked] = useState(false);

  const { data: listCountries } = useQuery<
    GetListCountriesQuery,
    GetListCountriesQueryVariables
  >(GETLISTCOUNTRIES);
  const listCountriesData = listCountries?.getListCountries?.list;

  const { data: listCities } = useQuery<
    GetListCitiesQuery,
    GetListCitiesQueryVariables
  >(GETLISTCITIES, {
    skip: !country?.id,
    variables: {
      countryId: country?.id || "",
    },
  });
  const listCitiesData = listCities?.getListCities?.list;

  const [createAddress] = useMutation<
    AddCustomerAddressMutation,
    AddCustomerAddressMutationVariables
  >(ADD_CUSTOMER_ADDRESS, {
    onCompleted: () => {
      handleClose(customerEmail);
    },
    onError: (error) => {
      if (error.message === "action_not_allowed") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Action not allowed",
        });
      } else if (error.message === "customer_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Customer not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
    refetchQueries: () => [
      {
        query: GET_LIST_CUSTOMERS,
        variables: {
          input: {
            token,
            menu,
          },
        },
      },
    ],
  });

  const [editAddress] = useMutation<
    EditCutomerAddressMutation,
    EditCutomerAddressMutationVariables
  >(EDIT_CUSTOMER_ADDRESS, {
    onCompleted: () => {
      handleClose(customerEmail);
    },
    onError: (error) => {
      if (error.message === "action_not_allowed") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Action not allowed",
        });
      } else if (error.message === "customer_address_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Customer address not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
    refetchQueries: () => [
      {
        query: GET_LIST_CUSTOMERS,
        variables: {
          input: {
            token,
            menu,
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (address) {
      setCountryCode(address?.primaryPhone?.countryCode?.toString() || "");
      setPhoneNumber(address?.primaryPhone?.number || "");
      setPostalCode(address?.address?.postalCode?.toString() || "");
      setFloor(address?.address?.floor || "");
      setBuilding(address?.address?.building || "");
      setRoute(address.address?.route || undefined);
      setCountry(address?.address?.country || undefined);
      setCity(address?.address?.city || undefined);
    }
  }, [address, listCitiesData, listCountriesData]);

  const handleSubmit = () => {
    address
      ? editAddress({
          variables: {
            input: {
              id: address.id || "",
              token,
              address: {
                id: address?.address?.id,
                city: city?.id,
                country: country?.id,
                postalCode: parseInt(postalCode, 10),
                floor,
                building,
                route,
              },
              isDefault: checked,
              primaryPhone: {
                countryCode: parseInt(countryCode, 10),
                number: phoneNumber,
              },
            },
          },
        })
      : createAddress({
          variables: {
            input: {
              customer: customerId,
              menu,
              token: localStorage.getItem("token") || "",
              address: {
                floor,
                building,
                route,
                city: city?.id,
                country: country?.id,
                postalCode: parseInt(postalCode, 10),
              },
              isDefault: checked,
              primaryPhone: {
                countryCode: parseInt(countryCode, 10),
                number: phoneNumber,
              },
            },
          },
        });
  };

  const handleRouteNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoute({ ...route, number: +event.target.value });
  };

  const handleRouteKind = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoute({ ...route, kind: event.target.value });
  };

  const handleRouteName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoute({ ...route, name: event.target.value });
  };

  const handleCheck = (): void => {
    setChecked(!checked);
  };

  return (
    <DialogStyled
      open={open}
      onClose={() => handleClose(customerEmail)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent className="dialogue__content">
        <div className="vector">
          <img src={vector} alt="vector" className="img-vector" />
          <img src={addressIcon} alt="apparence" className="img-apparence" />
        </div>
        <Typography variant="h2" className="dialog__title">
          {intl.formatMessage({ id: "store.customerForm.addAddress" })}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Input
              label="Street"
              value={route?.kind || ""}
              onChange={handleRouteKind}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Route Number"
              value={route?.number || ""}
              type="number"
              onChange={handleRouteNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Route Name"
              value={route?.name || ""}
              onChange={handleRouteName}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Floor"
              value={floor}
              onChange={(e) => setFloor(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Building"
              value={building}
              onChange={(e) => setBuilding(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable
              value={country}
              options={listCountriesData || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, value) => setCountry(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  InputProps={{ ...params.InputProps, type: "search" }}
                  InputLabelProps={{ shrink: true }}
                  label={intl.formatMessage({
                    id: "store.customerForm.addAddress.Country",
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable
              value={city}
              options={listCitiesData || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, value) => setCity(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  InputProps={{ ...params.InputProps, type: "search" }}
                  InputLabelProps={{ shrink: true }}
                  label={intl.formatMessage({
                    id: "store.customerForm.addAddress.City",
                  })}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              type="number"
              label={intl.formatMessage({
                id: "store.customerForm.addAddress.PostalCode",
              })}
              className="postalCodeInput"
            />
          </Grid>
          <Grid item xs={4}>
            <InputPhone
              label={intl.formatMessage({
                id: "store.customerForm.addAddress.Phone",
              })}
              value={phoneNumber}
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
              countryInputProps={{
                value: countryCode,
                onChange: (value) => setCountryCode(value),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  name="makeAddressPrimary"
                />
              }
              label={intl.formatMessage({
                id: "store.customerForm.addAddress.MakePrimary",
              })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog__actions">
        <Button
          variant="text"
          color="primary"
          onClick={() => handleClose(customerEmail)}
        >
          {intl.formatMessage({
            id: "store.customerForm.addaddress.discard",
          })}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {intl.formatMessage({
            id: "store.customerForm.save",
          })}
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

export default ModalFormAddress;
