import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .head-tabs {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e6f8;
    .MuiSelect {
      padding: 9px 12px 9px;
    }
  }
`;
