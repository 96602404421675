import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Nav = styled.nav`
  ${({ theme }) => `
  position: relative;
  display: flex;
  min-width: 300px;
  height: 100vh;
  transition: min-width 0.3s linear;
  .nav-list {
    width: 70px;
    // background: #000000;
    background: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;
    ul {
      margin: 0;
      padding: 20px 0 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .logo {
        display: grid;
        background: ${theme.palette.primary.main};
        z-index: 9;
        margin-bottom: 50px;
        img {
          // width: 75%;
          width: 55px;
          margin: auto;
        }
      }
      .item {
        width: 50px;
        height: 60px;
        margin: 5px;
        border-radius: 25px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        :hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      .item-selected {
        width: 50px;
        height: 60px;
        margin: 5px;
        border-radius: 25px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background-color: ${theme.palette.primary.dark};
      }
    }
  }
  .nav-list-menu {
    position: absolute;
    display: flex;
    width: 230px;
    height: 100%;
    left: 70px;
    flex-direction: column;
    background: #ffffff;
    transition: left 0.3s linear;
    .topbar-container {
      display: flex;
      z-index: 9;
      background: ${theme.palette.primary.main};
      justify-content: center;
      .topbar-actions {
        display: flex;
        flex: 1;
        justify-content: center;
        .store-list svg path {
          fill: white;
          stroke-width: 1px;
          stroke: white;
        }
      }
      .topbar-page-title {
        margin: auto 0;
        color: white;
        h2 {
          margin: 0;
          padding: 0;
          text-align: left;
          height: 45px;
        }
      }
    }
    ul {
      padding: 0;
      .item {
        list-style: none;
        padding-left: 20px;
        cursor: pointer;
      }
      .item:hover,
      .item:active {
        background: #ebebeb;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      .list-option {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
      }
    }
  }
  svg.selected-menu path {
    fill: ${theme.palette.secondary.main} !important;
  }
  svg.menu-item path {
    fill: #d6d6d6 !important;
  }
  .nav-list-menu-hidden {
    left: -230px;
  }
  .logout path {
    fill: #d6d6d6;
  }
  /* ##################################### */
  .main-menu-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 6px 3px 0 3px;
    grid-row-gap: 5px;
  }
  .sub-main-menu-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 3px;
    hr {
      grid-column: span 3;
      width: 90%;
      height: 2px;
      background: #f3f4fa;
      border-radius: 2px;
      margin: 20px auto;
      display: block;
      border: 0;
    }
  }
  .store-list {
    background: ${theme.palette.primary.dark};
    margin: 10px;
    padding: 11px 15px;
    border-radius: 8px;
    color: white;
    position: relative;
    width: 100%;
    height: 40px;
    cursor: pointer;
    .store-list-option {
      position: absolute;
      background-color: white;
      box-shadow: 0px 3px 20px 4px #7575752e;
      color: #444444;
      /* width: calc(100% - 16px); */
      width: 100%;
      left: 0;
      top: 55px;
      border-radius: 8px;
      padding: 7px;
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul {
        width: 100%;
      }
      li.store {
        padding: 8px 15px;
        width: calc(100% - 32px);
        margin: 1px;
        border-radius: 5px;
        cursor: pointer;
        :hover {
          background-color: #f3f4fa75;
        }
      }
      .store-selected {
        display: block;
        background: #f3f4fa;
        padding: 4px 2px;
      }
    }
  }
  .show {
    display: flex;
  }
  .hide {
    display: none;
  }
  .new-store {
    background: #f29f05;
    margin: 10px;
    padding: 10px 15px;
    border-radius: 8px;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 10px;
    }
    svg path {
      fill: white;
    }
  }
  /* .down-arrow {
    width: 20px;
    position: absolute;
    right: 15px;
    top: -2px;
  } */

  .arrow-store {
    position: absolute;
    right: 1px;
    top: 17px;
    height: 2px;
    width: 2px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    cursor: pointer;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transition: transform 0.3s linear;
    margin-right: 15px;
  }
  .down {
    transform: rotate(135deg);
    -webkit-transform: rotate(45deg);
  } `}
`;

export const Divider = styled.div`
  width: 1px;
  height: 60%;
  margin: auto;
  border: 0;
  border-left: 1px solid #f3f4fa52;
`;

export const Separator = styled.div`
  background: #f1f2fb;
  height: 2px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  .snackbar {
    position: fixed;
    top: 100%;
    display: flex;
    min-height: 50px;
    max-width: 830px;
    align-items: center;
    background-color: #f29f05;
    color: #444444;
    font-size: 14px;
    justify-content: space-between;
    transition: all 0.6s cubic-bezier(0.45, -0.32, 0.13, 1.32);
    box-shadow: 0px 5px 13px 1px rgba(97, 97, 97, 0.2);
    transform: translateY(-60px);
    border-radius: 10px;
    opacity: 0;
  }
  p {
    margin: 0 20px;
  }
  div.snack-action {
    min-width: 135px;
    justify-content: space-between;
    display: flex;
  }
  button.update-btn {
    border: 0;
    background-color: black;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    padding: 2px 13px;
  }
  button.close-btn {
    background: transparent;
    border: 0;
    margin: 0 4px;
  }
  .show {
    transform: translateY(0);
    opacity: 1;
  }
  .hide {
    transform: translateY(-60px);
    opacity: 0;
  }
`;
