import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .MuiFab-root {
    color: #fff;
    background-color: #f29f05;
    box-shadow: none;
    width: 30px;
    height: 30px;
    min-height: 30px;
  }
  .MuiFab-root:hover {
    background-color: #ffa805;
  }
  .MuiFab-root:active {
    box-shadow: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .form {
      display: flex;
      flex-direction: column;
      margin-top: 15;
      justify-content: flex-start;
      .row {
        display: flex;
        padding: 20px 0 10px 0;
        justify-content: flex-start;
      }
      .AddTosend {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .MuiFormControl-root {
          width: 350px;
          flex: none;
        }
        .MuiButtonBase-root {
          margin-bottom: 1.5px;
        }
      }
      .chips {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        height: 122px;
        max-height: 123px;
        overflow-y: auto;
        justify-content: flex-start;
        align-content: flex-start;
      }
      .action {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;
