import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { MenuItem, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

import { Box, Input, Select, DatePicker } from "../../commons";
import { Wrapper } from "./StockProductStyle";
import { Validation } from "./StockProductValidation";

import {
  GETLISTCOMPANYSITES,
  GETLISTPRODUCTVARIANTS,
  ADD_PRODUCT_INVENTORY_STOCK,
  GET_LIST_PRODUCT_INVENTORIES,
} from "../../api";
import {
  GetListCompanySitesQuery,
  GetListCompanySitesQueryVariables,
  GetListProductVariantsQuery,
  GetListProductVariantsQueryVariables,
  AddProductInventoryStockMutation,
  AddProductInventoryStockMutationVariables,
  AddProductInventoryStockInput,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

type StockProductProps = {
  hasVariants: boolean;
  hasMultiAddress: boolean;
};

export type StockInfo = {
  quantity: string;
};

const initialState: StockInfo = {
  quantity: "",
};

const StockProduct: React.FC<StockProductProps> = ({
  hasMultiAddress,
  hasVariants,
}) => {
  const history = useHistory();
  const token = localStorage.getItem("token") || "";
  const { id } = useParams<{ id?: string }>();
  const [formStock, setFormStock] = useState<AddProductInventoryStockInput>({
    token,
    product: "",
    date: moment(new Date()).utc().format(),
    cost: 0,
    quantity: 0,
  });
  const [globalQuantity, setGlobalQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [errors, setErrors] = useState<StockInfo>(initialState);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  useEffect(() => {
    setFormStock({
      ...formStock,
      product: id || "",
    });
  }, [id]);

  const { data: listCompanySite } = useQuery<
    GetListCompanySitesQuery,
    GetListCompanySitesQueryVariables
  >(GETLISTCOMPANYSITES, {
    variables: {
      token,
      menu: localMenu?.menuItem?.id,
    },
  });

  const { data: listVariations } = useQuery<
    GetListProductVariantsQuery,
    GetListProductVariantsQueryVariables
  >(GETLISTPRODUCTVARIANTS, {
    variables: {
      input: {
        token,
        product: id || "",
      },
    },
    skip: !id,
  });

  const [CreateProductInventory, { loading }] = useMutation<
    AddProductInventoryStockMutation,
    AddProductInventoryStockMutationVariables
  >(ADD_PRODUCT_INVENTORY_STOCK, {
    refetchQueries: () => [
      {
        query: GET_LIST_PRODUCT_INVENTORIES,
        variables: {
          input: {
            token,
            menu: localMenu?.menuItem?.id || "",
            productName: "",
            availabilities: [],
            pageNumber: 1,
            pageCount: 10,
          },
        },
      },
    ],
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully registered!",
      });
    },
    onError: (error) => {
      if (error.message === "product_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "product_not_found!",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable!",
        });
      }
    },
  });

  const handleSave = () => {
    const validation = Validation(globalQuantity);

    if (Object.entries(validation).length === 0) {
      CreateProductInventory({
        variables: {
          input: {
            ...formStock,
            quantity: parseFloat(globalQuantity),
            cost: parseFloat(price),
          },
        },
      });
    } else {
      setErrors({ ...errors, ...validation });
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Box width={1} p={15}>
        <div className="header">
          <h3> Stock </h3>
          <div>
            <Button
              onClick={() => {
                history.push("/inventory");
              }}
              style={{ color: "#1f90cf", marginRight: 20 }}
            >
              Manage In Inventory
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Save To Inventory
            </Button>
          </div>
        </div>
        <div className="description">
          <p>
            Manage stock your products stock and availability by assigning
            Quantity and the cost of the prodcut.
          </p>
          <p>
            Note: if the &quot;Control availability by stock level&quot; is
            checked and a required option quantity is set to 0 it will
            automaticly make the product unavailable in the store.
          </p>
        </div>

        <div className="form">
          <div className="form-option">
            {hasVariants && (
              <Select
                label="Select a variation"
                value={formStock?.variant || ""}
                onChange={(e) =>
                  setFormStock({
                    ...formStock,
                    variant: e.target?.value as string,
                  })
                }
              >
                {listVariations?.getListProductVariants?.list?.map(
                  (variant) => (
                    <MenuItem value={variant?.id || ""} key={variant?.id || ""}>
                      {variant?.names?.[0]?.value}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
            {hasMultiAddress && (
              <Select
                label="Select a address"
                value={formStock?.site || ""}
                onChange={(e) =>
                  setFormStock({
                    ...formStock,
                    site: e.target.value as string,
                  })
                }
              >
                <MenuItem value="Global"> Global</MenuItem>
                {listCompanySite?.getListCompanySites?.list?.map((site) => (
                  <MenuItem key={site?.id || ""} value={site?.id || ""}>
                    {site?.identifier}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Input
              label="Global Quantity"
              type="number"
              value={globalQuantity}
              onChange={(e) => {
                setGlobalQuantity(e.target.value);
                setErrors({
                  ...errors,
                  quantity: "",
                });
              }}
              msgError={errors?.quantity}
            />
            <Input
              label="Cost per Item"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              onKeyPress={onKeyPress}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Acquisition date"
                value={formStock.date || null}
                onChange={(date: Date | null) =>
                  setFormStock({
                    ...formStock,
                    date,
                  })
                }
                format="dd/MM/yyyy"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </Box>
    </Wrapper>
  );
};

export default StockProduct;
