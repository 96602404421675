import gql from "graphql-tag";

export const GET_MENU_LEGALITY_SETTINGS = gql`
  query getMenuLegalitySettings($input: GetMenuLegalitySettingsInput!) {
    getMenuLegalitySettings(input: $input) {
      pages {
        id
        identifier
        title {
          id
          languageCode
          value
        }
        content {
          id
          languageCode
          value
        }
        styles
        isPublished
      }
      consentIsRequired
      approvalIsRequired
    }
  }
`;

export const GET_MENU_LEGALITY_PAGE = gql`
  query GetMenuLegalityPage($input: GetMenuLegalityPageInput!) {
    getMenuLegalityPage(input: $input) {
      page {
        id
        identifier
        title {
          id
          languageCode
          value
        }
        content {
          id
          languageCode
          value
        }
        styles
        isPublished
      }
    }
  }
`;

export const UPDATE_MENU_LEGALITY_SETTINGS = gql`
  mutation UpdateMenuLegalitySettings(
    $input: UpdateMenuLegalitySettingsInput!
  ) {
    updateMenuLegalitySettings(input: $input) {
      success
    }
  }
`;

export const UPDATE_MENU_LEGALITY_PAGE = gql`
  mutation UpdateMenuLegalityPage($input: UpdateMenuLegalityPageInput!) {
    updateMenuLegalityPage(input: $input) {
      success
    }
  }
`;

export const ADD_MENU_LEGALITY_PAGE = gql`
  mutation AddMenuLegalityPage($input: AddMenuLegalityPageInput!) {
    addMenuLegalityPage(input: $input) {
      id
    }
  }
`;
