import gql from "graphql-tag";

export const GET_LIST_REDEEM_CONFIG = gql`
  query getListRedeemConfig($input: GetListRedeemConfigInput!) {
    getListRedeemConfig(input: $input) {
      list {
        id
        menu
        country {
          id
          countryName
          countryCode
        }
        currency {
          id
          code
          display
        }
        balancePoints {
          cash
          points
        }
        conversionPoints {
          cash
          points
        }
        isEnabled
      }
    }
  }
`;

export const SET_REDEEM_POINTS_CONFIG = gql`
  mutation setRedeemPointsConfig($input: setRedeemPointsConfigInput!) {
    setRedeemPointsConfig(input: $input) {
      id
      menu
      country
      currency
      balancePoints {
        cash
        points
      }
      conversionPoints {
        cash
        points
      }
      isEnabled
    }
  }
`;

export const UNSET_REDEEM_POINTS_CONFIG = gql`
  mutation unsetRedeemPointsConfig($input: unsetRedeemPointsConfigInput!) {
    unsetRedeemPointsConfig(input: $input) {
      success
    }
  }
`;
