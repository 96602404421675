import React, { FC } from "react";

import { Wrapper } from "./styles";

import { ReactComponent as SearchIcon } from "../../img/search.svg";

type SearchType = {
  // eslint-disable-next-line
  value?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
};

const Search: FC<SearchType> = ({ value, onSearch, placeholder, style }) => {
  const [state, setState] = React.useState<string>("");

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  React.useEffect(() => {
    if (value) setState(value);
  }, [value]);

  return (
    <Wrapper style={style}>
      <input
        type="text"
        value={state}
        placeholder={placeholder || "Search"}
        className="search-input"
        onChange={handleOnChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") onSearch(state);
        }}
      />
      <button type="button" className="search-btn">
        <SearchIcon width="22" height="22" />
      </button>
    </Wrapper>
  );
};

export default Search;
