import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Paper,
  Button,
  Typography,
  TextField,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { Autocomplete, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useMutation, useQuery } from "@apollo/client";
import findIndex from "lodash/findIndex";

import { BreadCrumbsTitle, Input, TreeItem } from "../../../commons";
import { AddStaffMemberModal } from "../../../components";

import {
  GET_LIST_PERMISSIONS,
  GET_MENU_STAFF,
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_LIST_ROLES,
} from "../../../api";
import {
  GetListPermissionsQuery,
  GetListPermissionsQueryVariables,
  CreateRoleMutation,
  CreateRoleMutationVariables,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
  UpdateRoleInput,
  UpdateRoleMemberInput,
  UpdateRolePermissionInput,
  CreateRolePermissionInput,
  CreateRoleStaffMemberInput,
  RolePayload,
  GetMenuStaffQuery,
  GetMenuStaffQueryVariables,
  StaffPayload,
} from "../../../api/types";

import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../../api/local-state";

import { ValidationForm, Error } from "./RoleFormValidation";

import { Wrapper, Note } from "./RoleForm--styles";

const iniatialFormError: Error = {
  kind: "",
};

const RoleAdd: React.FC = () => {
  const location = useLocation<RolePayload>();
  const history = useHistory();
  const dataRolePayload = location.state;

  const [open, setOpen] = useState<boolean>(false);
  const [listMember, setListMember] = useState<StaffPayload[]>([]);
  const [listMemberSelected, setListMemberSelected] = useState<StaffPayload[]>(
    []
  );
  const [formRole, setFormRole] = useState<UpdateRoleInput>({
    token: localStorage.getItem("token") || "",
    id: "",
    kind: "",
    permissions: [],
    staff: [],
  });
  const [formError, setFormError] = useState<Error>(iniatialFormError);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: listPermission } = useQuery<
    GetListPermissionsQuery,
    GetListPermissionsQueryVariables
  >(GET_LIST_PERMISSIONS, {
    variables: {
      input: {
        token: localStorage.getItem("token") || "",
        languageCode: "EN",
      },
    },
  });

  // get list kind of permissions in array string
  const listKindPermissions = listPermission?.getListPermissions?.list?.map(
    (item) => item.kind
  );

  // remove duplicates from list kind of permission by Set then convert to array
  const uniqueKindPermissions = Array.from(new Set(listKindPermissions));

  const { data: listStaff } = useQuery<
    GetMenuStaffQuery,
    GetMenuStaffQueryVariables
  >(GET_MENU_STAFF, {
    variables: {
      input: {
        token: localStorage.getItem("token") || "",
        menu: localMenu?.menuItem.id || "",
      },
    },
  });

  const [CreateRole] = useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(CREATE_ROLE, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully created",
      });
      history.push("/role-list");
    },
    refetchQueries: () => [
      {
        query: GET_LIST_ROLES,
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            menu: localMenu?.menuItem?.id || "",
            languageCode: "EN",
          },
        },
      },
      {
        query: GET_MENU_STAFF,
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            menu: localMenu?.menuItem.id || "",
          },
        },
      },
    ],
  });

  const [UpdateRole] = useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(UPDATE_ROLE, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully updated",
      });
      history.push("/role-list");
    },
    refetchQueries: () => [
      {
        query: GET_LIST_ROLES,
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            menu: localMenu?.menuItem?.id || "",
            languageCode: "EN",
          },
        },
      },
      {
        query: GET_MENU_STAFF,
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            menu: localMenu?.menuItem.id || "",
          },
        },
      },
    ],
  });

  React.useEffect(() => {
    const arrayStaff: UpdateRoleMemberInput[] = [];
    const arrayPermissions: UpdateRolePermissionInput[] = [];

    dataRolePayload?.staff?.forEach((staff) =>
      arrayStaff.push({ member: staff?.id || "" })
    );

    dataRolePayload?.permissions?.forEach((permission) =>
      arrayPermissions.push({ permission: permission?.id || "" })
    );

    setFormRole({
      ...formRole,
      id: dataRolePayload?.id || "",
      kind: dataRolePayload?.kind,
      permissions: arrayPermissions,
      staff: arrayStaff,
    });

    const ArrayMember = listStaff?.getMenuStaff?.list?.filter(
      (staff) =>
        findIndex(dataRolePayload?.staff, (o) => o?.id === staff?.id) !== -1 ||
        !staff.role?.kind
    );

    setListMember(ArrayMember || []);
  }, [dataRolePayload, listStaff]);

  React.useEffect(() => {
    const ArrayMemberSelect = listStaff?.getMenuStaff?.list?.filter(
      (staff) =>
        findIndex(formRole.staff, (o) => o?.member === staff?.id) !== -1
    );

    setListMemberSelected(ArrayMemberSelect || []);
  }, [formRole, listStaff]);

  const handleRoleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormRole({ ...formRole, kind: event.target.value });
    setFormError({ kind: "" });
  };

  const handleAssignStaff = (value: StaffPayload[]) => {
    const staffArray: UpdateRoleMemberInput[] = [];
    value?.forEach((item) => staffArray.push({ member: item.id || "" }));
    setFormRole({ ...formRole, staff: staffArray });
  };

  const validationChecked = (idPermission: string): boolean => {
    if (
      findIndex(formRole.permissions, (o) => o.permission === idPermission) !==
      -1
    ) {
      return true;
    }
    return false;
  };

  const permissionArray = formRole?.permissions || [];
  const handlePermission = (idPermission: string) => {
    const position = findIndex(
      formRole.permissions,
      (o) => o.permission === idPermission
    );
    if (position === -1) {
      permissionArray.push({ permission: idPermission });
    } else {
      permissionArray.splice(position, 1);
    }
    setFormRole({ ...formRole, permissions: permissionArray });
  };

  const handleSelectAll = () => {
    const arraySelectAllPermissions: UpdateRolePermissionInput[] = [];
    listPermission?.getListPermissions?.list?.forEach((item) =>
      arraySelectAllPermissions.push({ permission: item.id || "" })
    );
    setFormRole({ ...formRole, permissions: arraySelectAllPermissions });
  };

  const handleSubmit = () => {
    const validation = ValidationForm(formRole);
    if (Object.entries(validation).length === 0) {
      if (dataRolePayload) {
        UpdateRole({
          variables: {
            input: { ...formRole },
          },
        });
      } else {
        const createPermissionsArray: CreateRolePermissionInput[] = [];
        const createStaffMemberArray: CreateRoleStaffMemberInput[] = [];

        formRole.permissions?.forEach((item) =>
          createPermissionsArray.push({ permission: item.permission })
        );

        formRole.staff?.forEach((item) =>
          createStaffMemberArray.push({ member: item.member })
        );

        CreateRole({
          variables: {
            input: {
              token: formRole.token,
              kind: formRole.kind || "",
              menu: localMenu?.menuItem.id || "",
              permissions: createPermissionsArray,
              staff: createStaffMemberArray,
            },
          },
        });
      }
    } else {
      setFormError({ ...formError, ...validation });
    }
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Staff Managment", "Role Creation"]}
          iconAlt="Role"
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Note>
      <Box component={Paper} className="role__container">
        <div className="role__container-form">
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Role Name
          </Typography>
          <Input
            label="Role Name"
            value={formRole.kind || ""}
            onChange={handleRoleName}
            msgError={formError.kind}
          />

          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            Assign Staff
          </Typography>
          <div>
            <Autocomplete
              id="Assign Staff"
              options={listMember}
              getOptionLabel={(option) =>
                option?.informations?.firstName
                  ? `${option.informations.firstName} ${option.informations.lastName}`
                  : ""
              }
              value={listMemberSelected}
              onChange={(event, value) => handleAssignStaff(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Member Name"
                  variant="filled"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              multiple
              fullWidth
            />
            <label htmlFor="icon-button-file" style={{ margin: "auto 0" }}>
              <IconButton
                color="secondary"
                component="span"
                onClick={() => setOpen(true)}
              >
                <AddCircleIcon />
              </IconButton>
            </label>
          </div>
        </div>
        <div className="role__container-form">
          <div>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Permissions
            </Typography>
            <Button style={{ color: "#1F90CF" }} onClick={handleSelectAll}>
              Select All
            </Button>
          </div>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            disableSelection
          >
            {uniqueKindPermissions?.map((item) => (
              <TreeItem
                nodeId={item || ""}
                key={item || ""}
                labelText={item || ""}
              >
                <div className="role__container-form-item">
                  {listPermission?.getListPermissions?.list
                    ?.filter(
                      (permissionFilter) => permissionFilter?.kind === item
                    )
                    ?.map((permission) => (
                      <React.Fragment key={permission?.id || ""}>
                        <div>
                          <Typography variant="body1">
                            {permission?.names?.[0]?.value}
                          </Typography>
                          <Checkbox
                            checked={validationChecked(permission?.id || "")}
                            onChange={() =>
                              handlePermission(permission?.id || "")
                            }
                          />
                        </div>
                        <Typography
                          variant="body2"
                          style={{ color: "#999BB2" }}
                        >
                          {permission?.descriptions?.[0]?.value}
                        </Typography>
                      </React.Fragment>
                    ))}
                </div>
              </TreeItem>
            ))}
          </TreeView>
        </div>
      </Box>
      <AddStaffMemberModal
        title="Add Staff Member"
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Wrapper>
  );
};

export default RoleAdd;
