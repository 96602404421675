import gql from "graphql-tag";

// mutation create menu ad
export const CREATEMENUAD = gql`
  mutation CreateMenuAd($input: CreateMenuAdInput!) {
    createMenuAd(input: $input) {
      id
      isReached
    }
  }
`;

// mutation update menu ad
export const UPDATEMENUAD = gql`
  mutation updateMenuAd($input: UpdateMenuAdInput!) {
    updateMenuAd(input: $input) {
      success
      isReached
    }
  }
`;

// query get list menu products
export const GET_LIST_MENU_PRODUCTS = gql`
  query GetListMenuProducts($input: GetListMenuProductsInput!) {
    getListMenuProducts(input: $input) {
      list {
        id
        identifier
        sku
        names {
          id
          languageCode
          value
        }
        price {
          value
          currency {
            id
            code
          }
        }
        picture {
          id
          fileUrl
        }
        mainCategory {
          id
          names {
            id
            languageCode
            value
          }
          level
        }
        currentCategory {
          id
          names {
            id
            languageCode
            value
          }
          level
        }
        optionsCount
        variantsCount
        isAssigned
        mustCheckAvailaibitly
        salesCount
      }
    }
  }
`;

// query get list menu ads
export const GETLISTMENUADS = gql`
  query GetListMenuAds($token: String!) {
    getListMenuAds(input: { token: $token }) {
      list {
        id
        #       menus {
        #         menu
        #       }
        #       names {
        #         id
        #         languageCode
        #         value
        #       }
        #       kind
        #       pictures {
        #         cover {
        #           id
        #           kind
        #           fileName
        #         }
        #         poster {
        #           id
        #           kind
        #           fileName
        #         }
        #       }
        #       products {
        #         product
        #       }
        #       externalUrl
        #       position
        #       startDate
        #       endDate
        #       isPublished
        #       adClicksCount
        #       urlClicksCount
      }
    }
  }
`;

// mutation delete menu ad

export const DELETEMENUAD = gql`
  mutation deleteMenuId($token: String!, $id: String!) {
    deleteMenuAd(input: { token: $token, id: $id }) {
      success
    }
  }
`;
