import gql from "graphql-tag";

export const ADD_DELIVERY_SERVICE = gql`
  mutation AddDeliveryService($input: AddDeliveryServiceInput!) {
    addDeliveryService(input: $input) {
      success
    }
  }
`;

export const UPDATE_DELIVERY_SERVICE = gql`
  mutation UpdateDeliveryService($input: UpdateDeliveryServiceInput!) {
    updateDeliveryService(input: $input) {
      success
    }
  }
`;

export const GET_MENU_DELIVERY_SETTINGS = gql`
  query GetMenuDeliverySettings($input: GetMenuDeliverySettingsInput!) {
    getMenuDeliverySettings(input: $input) {
      deliveryServices {
        id
        name
        kind
        description
        customDelivery {
          kind
          limit
          cost
        }
        deliveryMethods {
          kind
          deliveryFees {
            min
            max
          }
          estimationTime {
            min
            max
          }
          availableDays
          isActive
        }
        deliveryPackage {
          isActive
          packages {
            picture {
              fileUrl
              id
            }
            name
            kinds {
              size
              cost
            }
          }
        }
        isEnabled
      }
      carriers {
        id
        name
        email
        phone {
          countryCode
          number
        }
      }
    }
  }
`;

export const GET_MENU_DELIVERY_SETTINGS_GENERAL = gql`
  query GetMenuDeliverySettingsGeneral($input: GetMenuDeliverySettingsInput!) {
    getMenuDeliverySettings(input: $input) {
      deliveryServices {
        id
        name
        kind
        isEnabled
      }
    }
  }
`;

export const REMOVE_DELIVERY_SERVICE = gql`
  mutation RemoveDeliveryService($input: RemoveDeliveryServiceInput!) {
    removeDeliveryService(input: $input) {
      success
    }
  }
`;

export const CHECK_SNOONU_CREDENTIALS = gql`
  mutation CheckSnoonuCredentials($input: CheckSnoonuCredentialsInput!) {
    checkSnoonuCredentials(input: $input) {
      success
    }
  }
`;
