import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useReactiveVar, useMutation } from "@apollo/client";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { Search } from "../../../components";

import {
  GET_LIST_REDEEM_CONFIG,
  UNSET_REDEEM_POINTS_CONFIG,
} from "../../../api";
import {
  GetListRedeemConfigQuery,
  GetListRedeemConfigQueryVariables,
  UnsetRedeemPointsConfigMutation,
  UnsetRedeemPointsConfigMutationVariables,
  MenuPayload,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

import {
  Wrapper,
  Note,
  Status,
  CustomerIconButton,
} from "./Country&Currency--style";

const CountryAndCurrency = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [countIsTrue, setCountIsTrue] = React.useState(0);

  const [search, setSearch] = React.useState<string>("");

  const { data, refetch } = useQuery<
    GetListRedeemConfigQuery,
    GetListRedeemConfigQueryVariables
  >(GET_LIST_REDEEM_CONFIG, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const [handleDelete, { loading }] = useMutation<
    UnsetRedeemPointsConfigMutation,
    UnsetRedeemPointsConfigMutationVariables
  >(UNSET_REDEEM_POINTS_CONFIG, {
    onCompleted: () => {
      refetch();
      setOpen(false);
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "Service unavailable",
      });
    },
  });

  React.useEffect(() => {
    const listIsTrue = data?.getListRedeemConfig?.list.filter(
      (obj) => obj.isEnabled
    );

    setCountIsTrue(listIsTrue?.length || 0);
  }, [data]);

  const columns: ColumnsProps = [
    {
      header: "Country",
      accessor: "country",
    },
    {
      header: "Currency",
      accessor: "currency",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      data?.getListRedeemConfig?.list
        ?.filter((obj) =>
          obj?.country?.countryName
            ?.toLowerCase()
            ?.includes(search.toLowerCase())
        )
        ?.map((item) => ({
          country: item.country.countryName,
          currency: item.currency.code,
          status: (
            <Status status={item.isEnabled}>
              {item.isEnabled ? "Active" : "Disabled"}
            </Status>
          ),
          actions: (
            <Box display="flex">
              <Link
                to={{
                  pathname: "/country-currency-form",
                  state: { ...item, isRequired: countIsTrue === 1 },
                }}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>

              <CustomerIconButton
                onClick={() => {
                  item?.id && setId(item.id);
                  item?.country?.id && setCountry(item.country.id);
                  setOpen(true);
                }}
                disabled={countIsTrue === 1 && item.isEnabled}
              >
                <DeleteIcon />
              </CustomerIconButton>
            </Box>
          ),
        })) || []
    );
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Store settings", "Country & Currency"]}
          iconAlt="products"
        />
        <Link to="/country-currency-form">
          <ButtonCustomize
            variant="contained"
            color="secondary"
            style={{ marginLeft: 10 }}
          >
            <AddIcon />
            &nbsp; Add new
          </ButtonCustomize>
        </Link>
      </Note>
      <div className="container">
        <div className="search">
          <Search value={search} onSearch={(value) => setSearch(value)} />
        </div>
        <div className="table-collection">
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="No data available"
          />
        </div>
      </div>
      <Modal
        open={open}
        title="Delete country"
        message="Are you sure you want to remove this country ?"
        handleClose={() => setOpen(false)}
        handleContent={() =>
          handleDelete({
            variables: {
              input: {
                token,
                menu: localMenu?.id || "",
                id,
                country,
              },
            },
          })
        }
        action="delete"
        loading={loading}
      />
    </Wrapper>
  );
};

export default CountryAndCurrency;
