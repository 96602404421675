import gql from "graphql-tag";

export const UPDATE_PRODUCT_DICTIONARY = gql`
  mutation updateProductDictionary($input: UpdateProductDictionaryInput!) {
    updateProductDictionary(input: $input) {
      success
    }
  }
`;

export const UPDATE_CATEGORY_DICTIONARY = gql`
  mutation updateCategoryDictionary($input: UpdateCategoryDictionaryInput!) {
    updateCategoryDictionary(input: $input) {
      success
    }
  }
`;
