/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useRef } from "react";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useQuery, useReactiveVar } from "@apollo/client";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";

import {
  Tree,
  Select,
  TablePagination,
  Box,
  BreadCrumbsTitle,
  ButtonCustomize,
  LoadingListOrders,
} from "../../../commons";
import { Search, OrderCard, OrderToPrint } from "../../../components";

import {
  GETLISTMENUORDERS,
  GET_LIST_MENU_PRODUCTS,
  GET_LIST_VENDOR_BY_MENU,
} from "../../../api";
import {
  GetListMenuOrdersQuery,
  GetListMenuOrdersQueryVariables,
  GetListMenuProductsQuery,
  GetListMenuProductsQueryVariables,
  GetListVendorByMenuQuery,
  GetListVendorByMenuQueryVariables,
  MenuOrderFilter,
  DateFilterInput,
  ProductIdInput,
  OrderStatus,
  PaymentStatus,
  OrderSorting,
  MenuOrderPayload,
  MenuPayload,
} from "../../../api/types";
import { SelectedMenuVar } from "../../../api/local-state";

import { Refresh, ChevronDown } from "../../../img/digishop/menu-icons";
import { Wrapper } from "./styles";

type PriceState = {
  min: string;
  max: string;
};

const OrdersList: FC = () => {
  const token = localStorage.getItem("token") || "";
  const kind = localStorage.getItem("kind");
  const componentRef = useRef<HTMLDivElement>(null);

  // States
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [reference, setReference] = useState<string>("");
  const [filter, setFilter] = useState<MenuOrderFilter | string>("");
  const [vendor, setVendor] = useState<string | undefined>(undefined);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | string>(
    ""
  );
  const [orderStatus, setOrderStatus] = useState<OrderStatus | string>("");
  const [date, setDate] = useState<DateFilterInput | undefined>(undefined);
  const [price, setPrice] = useState<PriceState>();
  const [products, setProducts] = useState<ProductIdInput[]>([]);
  const [arrayProducts, setArrayProducts] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sorting, setSorting] = useState<OrderSorting>("DATE_ASC");
  const [itemMenuOrder, setItemMenuOrder] = useState<MenuOrderPayload>();
  // Queries & Mutaions

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const { data: listVendor } = useQuery<
    GetListVendorByMenuQuery,
    GetListVendorByMenuQueryVariables
  >(GET_LIST_VENDOR_BY_MENU, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
  });

  const {
    data: listOrders,
    loading,
    refetch: refetchListOrders,
  } = useQuery<GetListMenuOrdersQuery, GetListMenuOrdersQueryVariables>(
    GETLISTMENUORDERS,
    {
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          pageNumber: page,
          pageCount: rowsPerPage,
          reference,
          filter: filter ? (filter as MenuOrderFilter) : undefined,
          date,
          price:
            (price &&
              price.min &&
              price.max && {
                min: parseFloat(price.min),
                max: parseFloat(price.max),
              }) ||
            undefined,
          orderStatus: orderStatus ? (orderStatus as OrderStatus) : undefined,
          paymentStatus: paymentStatus
            ? (paymentStatus as PaymentStatus)
            : undefined,
          products,
          sorting,
          vendor,
        },
      },
      skip: !localMenu?.id,
    }
  );

  const lengthOrders = listOrders?.getListMenuOrders.count?.all || 0;

  const { data: listProducts } = useQuery<
    GetListMenuProductsQuery,
    GetListMenuProductsQueryVariables
  >(GET_LIST_MENU_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  // Functions

  const handleRefresh = () => {
    refetchListOrders();
  };

  const handleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleChangePaymentStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentStatus(event.target.value as PaymentStatus);
  };

  const handleChangeOrderStatus = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderStatus(event.target.value as OrderStatus);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });

  useEffect(() => {
    const ProductsId: ProductIdInput[] = [];
    arrayProducts.forEach((item) => ProductsId.push({ product: item }));
    setProducts(ProductsId);
  }, [arrayProducts]);

  return (
    <>
      <BreadCrumbsTitle
        labelIntl={["Sales Management ", "Orders"]}
        iconAlt="Orders"
      />

      <Wrapper>
        <div className="action-bar">
          <div className="view-actins">
            <button type="button" className="refresh" onClick={handleRefresh}>
              <Refresh width="18" />
              <span>Refresh</span>
            </button>
          </div>
          <div className="search-filter-container">
            <div style={{ width: 100, marginRight: 15 }}>
              <Select
                label="Sort by"
                value={sorting}
                onChange={(e) => setSorting(e.target.value as OrderSorting)}
              >
                <MenuItem value="DATE_ASC">
                  <FontAwesomeIcon icon={faSortAmountUpAlt} /> &nbsp;&nbsp; Date
                </MenuItem>
                <MenuItem value="DATE_DESC">
                  <FontAwesomeIcon icon={faSortAmountDownAlt} /> &nbsp;&nbsp;
                  Date
                </MenuItem>
              </Select>
            </div>
            {kind !== "Vendor" && (
              <div style={{ width: 200, marginRight: 15 }}>
                <Select
                  label="Find by vendor"
                  value={vendor}
                  onChange={(e) => setVendor(e.target.value as string)}
                  displayEmpty
                >
                  {listVendor?.getListVendorByMenu?.vendors?.map((item) => (
                    <MenuItem value={item.name || ""} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                  <MenuItem value={undefined}>All</MenuItem>
                </Select>
              </div>
            )}
            <Search
              value={reference}
              onSearch={(value) => setReference(value)}
              placeholder="Search by reference"
              style={{ width: 250, marginRight: 15 }}
            />
            <ButtonCustomize
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleFilter}
              style={{ paddingRight: "35px", height: "40px" }}
            >
              Filter
              <div className="arrow-filter">
                <ChevronDown width="16" height="16" />
              </div>
            </ButtonCustomize>
          </div>
        </div>

        <div className="order-list-content">
          <Box p={16} width={1}>
            {!loading && listOrders?.getListMenuOrders?.list?.length !== 0 && (
              <div className="items-details">
                {listOrders?.getListMenuOrders.list.map((order) => (
                  <OrderCard
                    key={order.id}
                    menuOrder={order}
                    refetchOrders={refetchListOrders}
                    orderToPrint={async (item) => {
                      await setItemMenuOrder(item);
                      handlePrint && handlePrint();
                    }}
                  />
                ))}
                <TablePagination
                  labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                  count={lengthOrders}
                  page={page - 1}
                  onPageChange={(_, newPage) => setPage(newPage + 1)}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            )}
            {loading && <LoadingListOrders />}
            {!loading && listOrders?.getListMenuOrders?.list?.length === 0 && (
              <Typography align="center">No data available</Typography>
            )}
          </Box>

          <div className={`filter ${openFilter ? "open" : "close"}`}>
            <div className="detail-filter">
              <div
                role="button"
                tabIndex={0}
                className="detail-filter__item"
                onClick={() => setFilter("")}
                onKeyUp={() => setFilter("")}
              >
                <span>All Orders</span>
                <span> {listOrders?.getListMenuOrders.count?.all}</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="detail-filter__item"
                onClick={() => setFilter("NEW_UNPAID")}
                onKeyUp={() => setFilter("NEW_UNPAID")}
              >
                <span>Not Paid.Needs To Be Shipped</span>
                <span> {listOrders?.getListMenuOrders.count?.newUnpaid} </span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="detail-filter__item"
                onClick={() => setFilter("NEW_PAID")}
                onKeyUp={() => setFilter("NEW_PAID")}
              >
                <span>Paid. Needs To Be Shipped</span>
                <span> {listOrders?.getListMenuOrders.count?.newPaid}</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="detail-filter__item"
                onClick={() => setFilter("IN_PROCESS")}
                onKeyUp={() => setFilter("IN_PROCESS")}
              >
                <span>Fulfillment In Process</span>
                <span> {listOrders?.getListMenuOrders.count?.inProcess} </span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="detail-filter__item"
                onClick={() => setFilter("PROCESSED")}
                onKeyUp={() => setFilter("PROCESSED")}
              >
                <span>Proccessed</span>
                <span> {listOrders?.getListMenuOrders.count?.processed}</span>
              </div>
            </div>
            <Tree level={1} title="Date" noActions>
              <div className="options clm2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={date?.from || null}
                    variant="inline"
                    inputVariant="outlined"
                    onChange={(event: Date | null) =>
                      setDate({
                        from: event,
                        to: date?.to || null,
                      })
                    }
                    fullWidth
                    focused={false}
                    format="yyyy/MM/dd"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setDate({
                              from: null,
                              to: date?.to || null,
                            });
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    placeholder="yyyy/mm/dd"
                  />
                  <DatePicker
                    value={date?.to || null}
                    variant="inline"
                    inputVariant="outlined"
                    onChange={(event: Date | null) =>
                      setDate({
                        from: date?.from || null,
                        to: event,
                      })
                    }
                    fullWidth
                    focused={false}
                    format="yyyy/MM/dd"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setDate({
                              from: date?.from || null,
                              to: null,
                            });
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    placeholder="yyyy/mm/dd"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Tree>
            <Tree level={1} title="Price Range" noActions>
              <div className="options clm2">
                <input
                  type="number"
                  value={price?.min || ""}
                  onChange={(e) =>
                    setPrice({
                      min: e.target.value,
                      max: price?.max || "",
                    })
                  }
                  className="input"
                />
                <input
                  type="number"
                  value={price?.max || ""}
                  onChange={(e) =>
                    setPrice({
                      min: price?.min || "",
                      max: e.target.value,
                    })
                  }
                  className="input"
                />
              </div>
            </Tree>
            <Tree level={1} title="Payment Status" noActions>
              <div className="options">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="paymentStatus"
                    name="paymentStatus"
                    value={paymentStatus}
                    onChange={handleChangePaymentStatus}
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio size="small" />}
                      label="Any"
                    />
                    <FormControlLabel
                      value="PAID"
                      control={<Radio size="small" />}
                      label="Paid"
                    />
                    <FormControlLabel
                      value="WAITING"
                      control={<Radio size="small" />}
                      label="Awaiting Payment"
                    />
                    <FormControlLabel
                      value="CANCELED"
                      control={<Radio size="small" />}
                      label="Canceled"
                    />
                    <FormControlLabel
                      value="REFUNDED"
                      control={<Radio size="small" />}
                      label="Refunded"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Tree>
            <Tree level={1} title="Fulfillment" noActions>
              <div className="options">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="orderStatus"
                    name="orderStatus"
                    value={orderStatus}
                    onChange={handleChangeOrderStatus}
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio size="small" />}
                      label="Any"
                    />
                    <FormControlLabel
                      value="WAITING"
                      control={<Radio size="small" />}
                      label="Awaiting process"
                    />
                    <FormControlLabel
                      value="ONGOING"
                      control={<Radio size="small" />}
                      label="Processing"
                    />
                    <FormControlLabel
                      value="READY"
                      control={<Radio size="small" />}
                      label="Ready for pickup"
                    />
                    <FormControlLabel
                      value="IN_DELIVERING"
                      control={<Radio size="small" />}
                      label="Shipped"
                    />
                    <FormControlLabel
                      value="CANCELED"
                      control={<Radio size="small" />}
                      label="Canceled"
                    />
                    <FormControlLabel
                      value="DELIVERED"
                      control={<Radio size="small" />}
                      label="Delivered"
                    />
                    <FormControlLabel
                      value="UNDELIVERED"
                      control={<Radio size="small" />}
                      label="Not delivered"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Tree>
            <Tree level={1} title="Products" noActions>
              <div className="options">
                <Select
                  value={arrayProducts}
                  onChange={(e) => setArrayProducts(e.target.value as string[])}
                  multiple
                >
                  {listProducts?.getListMenuProducts?.list?.map((product) => (
                    <MenuItem key={product.id || ""} value={product.id || ""}>
                      {product.names?.[0]?.value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Tree>
          </div>
        </div>
      </Wrapper>

      <OrderToPrint ref={componentRef} menuOrder={itemMenuOrder} />
    </>
  );
};

export default OrdersList;
