/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useQuery } from "@apollo/client";
import _ from "lodash";

import { Modal } from "../commons";
import { URL, URL_THEME_PICTURES, URL_ORDER, URL_LOGO_COMPANY } from "../api";
import { CopiedPictureInput, PictureUrlPayload } from "../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../api/local-state";

import { Trash } from "../img/digishop/menu-icons";

type ImageInput = {
  url?: string | null;
  id?: string | null;
};

type UploadPictureProductProps = {
  title?: string;
  description?: string;
  uploadTo:
    | "Category"
    | "Product"
    | "Promotion"
    | "logo"
    | "collection"
    | "Delivery"
    | "Company"
    | null;
  mode: "Base" | "Gallery";
  urlImage?: ImageInput | null;
  urlImageGallery?: PictureUrlPayload[] | null;
  onLoaded?: (
    picture: string,
    copiedPictures: CopiedPictureInput[],
    url?: string
  ) => void;
  onDeleted?: (pictureId: string) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  onAssign?: (id: string) => void;
};

type ProgressBarType = {
  progress: number;
};

const UploadPictureProduct: React.FC<UploadPictureProductProps> = ({
  title,
  description,
  uploadTo,
  mode,
  onLoaded,
  urlImage,
  urlImageGallery,
  onDeleted,
  style,
  disabled,
  onAssign,
}) => {
  const intl = useIntl();
  const fileUploader = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<ImageInput | null | undefined>(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState<ImageInput[]>([]);

  const [pictureToDelete, setPictureToDelete] = React.useState("");

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);
  const menu = localMenu?.menuItem;

  const handleClick = () => {
    fileUploader.current?.click();
  };

  const getUrl = () => {
    switch (uploadTo) {
      case "logo":
        return `${menu?.id}/MENU_LOGO`;
      case "Category":
        return `${menu?.id}/MENU_CATEGORY_PICTURE`;
      case "Product":
        return `${menu?.id}/CATEGORY_PRODUCT_PICTURE`;
      case "collection":
        return `${menu?.id}/MENU_AD_PICTURE`;
      case "Delivery":
        return `${menu?.id}/DELIVERY_PACKAGE`;
      case "Company":
        return `${menu?.id}/COMPANY_LOGO`;
      case "Promotion":
        return "public/MENU_AD_PICTURE";
      default:
        return `${menu?.id}/MENU_LOGO`;
    }
  };

  const getHost = () => {
    switch (uploadTo) {
      case "Delivery":
        return URL_ORDER;
      case "Company":
        return URL_LOGO_COMPANY;
      default:
        return URL;
    }
  };

  const url = `${getHost()}/${getUrl()}`;

  const handleUpload = (fileArray: FileList | null) => {
    if (fileArray && fileArray[0] !== null && fileArray[0] !== undefined) {
      const sizeImage = fileArray[0].size / 1000000;

      if (sizeImage <= 2) {
        // Initiate the FileReader object.
        const reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(fileArray[0]);

        reader.onload = (e: any) => {
          // Initiate the JavaScript Image object.
          const imageTest = new Image();
          // Set the Base64 string return from FileReader as source.
          imageTest.src = e.target.result;
          imageTest.onload = () => {
            if (imageTest.height >= 400 && imageTest.width >= 400) {
              if (mode === "Base") {
                setImage({ url: imageTest.src, id: null });
              } else {
                setGallery((prevGallery) => {
                  const newFile = {
                    id: null,
                    url: imageTest.src,
                  };
                  return [newFile, ...prevGallery];
                });
              }
              throwXHR(fileArray);
            } else {
              snackBarVar({
                open: true,
                severity: "error",
                message: "Image resolution can't be greater than 400 x 400 px",
              });
            }
          };
        };
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Image size can't be greater than 2 MB",
        });
      }
    }
  };

  const throwXHR = (fileArray: FileList) => {
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("files", fileArray[0]);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", progressHandler, false);
    xhr.open("POST", url);
    xhr.send(dataForm);
    xhr.onload = () => {
      if (xhr.status !== 200) {
        // analyze HTTP status of the response
        // e.g. 404: Not Found
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({
            id: "Upload.error",
          }),
        });
        setImage(null);
        setLoading(false);
      } else {
        // show the result
        const response = JSON.parse(xhr.response);
        // responseText is the server
        response && response.files[0].id && setLoading(false);
        onLoaded &&
          onLoaded(
            response.files[0].id,
            response.files[0].copied,
            response.files[0].url
          );
        if (mode === "Base") {
          setImage((prevImage) => ({
            ...prevImage,
            id: response.files[0].id,
          }));
        }
        // test if mode gallery
        if (mode === "Gallery") {
          setGallery((prevGallery) => {
            const arrayimage = [...prevGallery];
            arrayimage[0] = {
              ...arrayimage[0],
              id: response.files[0].id as string,
            };
            return arrayimage;
          });
        }
      }
    };
    xhr.onerror = (e) => {
      snackBarVar({
        open: true,
        severity: "error",
        message: intl.formatMessage({
          id: "Upload.error",
        }),
      });
      setImage(null);
      setLoading(false);
    };
  };

  const progressHandler = (event: ProgressEvent) => {
    setProgress((event.loaded / event.total) * 100);
  };

  const handleClose = () => {
    setPictureToDelete("");
  };

  const handleDelete = () => {
    if (mode === "Base") {
      setImage({
        id: undefined,
        url: undefined,
      });
    } else {
      setGallery((prevGallery) => {
        const newGallery = prevGallery.filter(
          (picture) => picture?.id !== pictureToDelete
        );
        return newGallery;
      });
    }
    onDeleted && onDeleted(pictureToDelete);
    setPictureToDelete("");
    if (fileUploader.current?.value) fileUploader.current.value = "";
  };

  useEffect(() => {
    if (urlImage && urlImage.id && urlImage.url && image?.url === undefined) {
      setImage({
        id: urlImage.id,
        url: URL_THEME_PICTURES?.concat(urlImage.url),
      });
    }
  }, [urlImage]);

  useEffect(() => {
    if (urlImageGallery) {
      const arrayGallery: ImageInput[] = [];
      urlImageGallery.forEach((img) => {
        // check if the image id doesn't already exist
        const findIndex = arrayGallery.findIndex(
          (stateImage) => stateImage?.id === img?.id
        );
        if (img?.fileUrl && findIndex === -1) {
          const long = arrayGallery.length;
          arrayGallery.push({
            id: img.id,
            url: URL_THEME_PICTURES?.concat(img?.fileUrl),
          });
        }
      });
      setGallery(arrayGallery);
    }
  }, [urlImageGallery]);

  return (
    <Wrapper mode={mode} style={style}>
      {title && <span>{title}</span>}
      <>
        <div
          className={`upload ${disabled && "disabled"} `}
          role="button"
          tabIndex={0}
          onKeyUp={handleClick}
          onClick={handleClick}
          style={{
            display: `${mode === "Gallery" && loading ? "none" : "flex"}`,
          }}
        >
          {image?.url && mode === "Base" ? (
            <div className="preview">
              <IconButton
                className="button__delete"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setPictureToDelete(image.id || "");
                }}
              >
                <Trash />
              </IconButton>
              <img
                src={image?.url}
                alt="file preview"
                className="file-preview"
                role="presentation"
              />
              {loading && (
                <ProgressBar progress={progress}>
                  <div className="progress-done" />
                </ProgressBar>
              )}
            </div>
          ) : (
            <div className="initial-upload">
              <IconButton size="small">
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
              <span>{description}</span>
            </div>
          )}
        </div>
        <input
          ref={fileUploader}
          type="file"
          onChange={(e) => handleUpload(e.target.files)}
        />
      </>

      {mode === "Gallery" &&
        gallery.map((item) => (
          <div className="upload" key={item.id || ""}>
            {item?.url && (
              <div className="preview">
                <IconButton
                  className="button__delete"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    item.id && setPictureToDelete(item.id);
                  }}
                >
                  <Trash />
                </IconButton>
                <img
                  role="presentation"
                  src={item?.url}
                  alt="file preview"
                  className="file-preview"
                />
                {loading && !item?.id && (
                  <ProgressBar progress={progress}>
                    <div className="progress-done" />
                  </ProgressBar>
                )}
              </div>
            )}
            {onAssign && (
              <IconButton
                className="btn_attachment"
                size="small"
                onClick={() => onAssign(item.id!)}
              >
                <AttachmentIcon />
              </IconButton>
            )}
          </div>
        ))}
      <Modal
        open={Boolean(pictureToDelete)}
        title="Deleting picture"
        message="Are you sure?"
        handleClose={handleClose}
        handleContent={handleDelete}
        action="delete"
      />
    </Wrapper>
  );
};

export default UploadPictureProduct;

const Wrapper = styled.div<{ mode: "Base" | "Gallery" }>`
  ${({ theme, mode }) => `
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  gap: 10px;
  flex: ${mode === "Gallery" && 1};
  width: ${mode === "Gallery" ? "100%" : "max-content"};
  height: min-content;

  > span {
    grid-column: 1 / -1;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .upload {
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    border: 2px dashed ${theme.palette.secondary.main};
    border-radius: 10px;
    cursor: pointer;
    .initial-upload {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 35px;
      > span {
        font-size: 12px;
        padding: 0 5px;
        text-align: center;
        line-height: 15px;
        /* margin-top: 5px; */
      }
      .MuiIconButton-sizeSmall {
        color : ${theme.palette.secondary.main}
      }
    }
    .preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      height: 100%;
      width: 100%;
      padding: 2px;
      .button__delete {
        position: absolute;
        top: 2px;
        left: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        pointer-events: initial;
      }
      .file-preview {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
      }
    }
    .btn_attachment {
        position: absolute;
        bottom: 0;
        color : ${theme.palette.secondary.main};
    }
  }
  input {
    display: none;
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-gap: 10px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }`}
`;

const ProgressBar = styled.div<ProgressBarType>`
  position: relative;
  top: -10px;
  background-color: #d8d8d8;
  border-radius: 5px;
  height: 5px;
  width: 80px;
  .progress-done {
    border-radius: 5px;
    height: 5px;
    background: #4fc325;
    width: ${(props) => `${props.progress}%`};
  }
`;
