import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type LinkNavProps = {
  link: string;
  nameLink: string;
  pathname: string;
  arrayPathnames: string[];
};

const LinkNav: React.FC<LinkNavProps> = ({
  link,
  nameLink,
  pathname,
  arrayPathnames,
}) => {
  const TestPathname = (): boolean => {
    if (!arrayPathnames.includes(pathname)) {
      return pathname.lastIndexOf(link.slice(1)) !== -1;
    }
    return arrayPathnames.includes(pathname);
  };

  const validationPathname = (): boolean => {
    if (arrayPathnames.length === 0) return link === pathname;
    return TestPathname();
  };

  return (
    <>
      <Element to={link}>
        <li className={validationPathname() ? "item-selected" : "item"}>
          {nameLink}
        </li>
      </Element>
    </>
  );
};

export default LinkNav;

const Element = styled(Link)`
  ${({ theme }) => `
  text-decoration: none;
  color: inherit;
  .item {
    padding-left: 40px;
    &:hover {
      background-color: #f8f8f8;
    }
  }
  .item-selected {
    background-color: ${theme.palette.secondary.main};
    color: white;
    padding-left: 40px;
  }`}
`;
