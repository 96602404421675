import React, { useState } from "react";
import {
  Dialog,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useQuery, useMutation } from "@apollo/client";

import { Input } from "../../commons";
import {
  CREATE_MENU_STAFF,
  GET_UNASSIGNED_STAFF,
  GET_MENU_STAFF,
} from "../../api";
import {
  CreateMenuStaffMutation,
  CreateMenuStaffMutationVariables,
  CreateMenuStaffInput,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

import { ValidationForm, Error } from "./RoleAddStaffMemberModalValidation";

import { Wrapper } from "./RoleAddStaffMemberModal--styles";

type AddStaffMemberModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
};

export type FormStaffState = CreateMenuStaffInput & {
  confirmPassword: string;
};

const initialFormError: Error = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const AddStaffMemberModal: React.FC<AddStaffMemberModalProps> = ({
  open,
  title,
  handleClose,
}) => {
  const [formStaff, setFormStaff] = React.useState<FormStaffState>({
    token: localStorage.getItem("token") || "",
    menu: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState<Error>(initialFormError);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const [CreateStaffMember, { loading }] = useMutation<
    CreateMenuStaffMutation,
    CreateMenuStaffMutationVariables
  >(CREATE_MENU_STAFF, {
    refetchQueries: () => [
      {
        query: GET_UNASSIGNED_STAFF,
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            menu: localMenu?.menuItem.id || "",
          },
        },
      },
      {
        query: GET_MENU_STAFF,
        variables: {
          input: {
            token: localStorage.getItem("token") || "",
            menu: localMenu?.menuItem.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
      snackBarVar({
        open,
        severity: "success",
        message: "successful operation",
      });
    },
    onError: (error) => {
      if (error.message === "email_already_exists") {
        snackBarVar({
          open,
          severity: "error",
          message: "Email already exists",
        });
      } else
        snackBarVar({
          open,
          severity: "error",
          message: "service unavailable",
        });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormStaff({ ...formStaff, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    const validation = ValidationForm(formStaff);

    if (Object.entries(validation).length === 0) {
      CreateStaffMember({
        variables: {
          input: {
            token: formStaff.token,
            menu: localMenu?.menuItem.id || "",
            firstName: formStaff.firstName,
            lastName: formStaff.lastName,
            email: formStaff.email,
            password: formStaff.password,
            languageCode: "EN",
          },
        },
      });
    } else {
      setFormError({ ...formError, ...validation });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Typography
          variant="h2"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          Set Staff Info
        </Typography>
        <Input
          label="First name"
          name="firstName"
          value={formStaff.firstName}
          onChange={handleChange}
          msgError={formError.firstName}
          style={{ marginBottom: "10px" }}
        />
        <Input
          label="Last name"
          name="lastName"
          value={formStaff.lastName}
          onChange={handleChange}
          msgError={formError.lastName}
        />
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", margin: "5px 0" }}
        >
          Set Staff Account
        </Typography>
        <Input
          label="Email"
          type="email"
          name="email"
          value={formStaff.email}
          onChange={handleChange}
          msgError={formError.email}
          style={{ marginBottom: "10px" }}
        />
        <Input
          label="Password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={formStaff.password}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          msgError={formError.password}
          style={{ marginBottom: "10px" }}
        />
        <Input
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          name="confirmPassword"
          value={formStaff.confirmPassword}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          msgError={formError.confirmPassword}
          style={{ marginBottom: "10px" }}
        />

        <div className="action">
          <Button
            onClick={() => {
              handleClose();
              setFormError(initialFormError);
            }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default AddStaffMemberModal;
