// Libraries Imports
import React, { FC } from "react";
import styled from "styled-components";

// Types
type DropDownType = {
  open: boolean;
  placement?: "bottom" | "top";
};

type WrapperType = {
  show: boolean | null;
  placement?: "bottom" | "top";
};

const DropDown: FC<DropDownType> = ({ open, children, placement }) => {
  return (
    <Wrapper show={open} placement={placement}>
      <ul>{children}</ul>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  right: 0;
  ${(props) =>
    props.placement && props.placement === "bottom"
      ? "top: calc(100% + 8px)"
      : "top: 0"};
  z-index: ${(props) => (props.show ? 99 : -1)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: fit-content;
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
  transition: all 0.15s ease-in-out;
  ul {
    width: max-content;
    margin: 0;
    padding: 0;
    div {
      cursor: ${(props) => props.show && "pointer"};
    }
  }
`;

export default DropDown;
