import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

type PrivateRouteProps = {
  component: any;
} & RouteProps;
const PrivateRoute: React.ComponentType<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      // if not logged
      if (!localStorage.getItem("token")) {
        return <Redirect to="/login" />;
      }
      return <Component {...props} />;
    }}
  />
);

export default PrivateRoute;
