// libraries imports
import React, { useEffect } from "react";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faSave, faPlus } from "@fortawesome/free-solid-svg-icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FilledInput,
  InputLabel,
  Fab,
  IconButton,
  InputAdornment,
} from "@material-ui/core/";
import debounce from "lodash/debounce";
import findIndex from "lodash/findIndex";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TranslateIcon from "@material-ui/icons/Translate";
import find from "lodash/find";
// local imports
import { Box, Modal, Input, BreadCrumbsTitle } from "../../commons";
import { TranslateAddressAccountModal } from "../../components";
import { ValidationChangePassword } from "./ValidationAccount";

import {
  GETMYACCOUNT,
  GETLISTCOUNTRIES,
  GETLISTCITIES,
  CHANGEPASSWORD,
  UPDATE_ACCOUNT,
  GETLISTSOCIALKINDS,
} from "../../api";
import {
  GetMyAccountQuery,
  GetMyAccountQueryVariables,
  UserInformationsInput,
  GetListCountriesQuery,
  UpdateCompanyFiscalInput,
  GetListCountriesQueryVariables,
  CompanyCharacteristicKind,
  UpdateAddressInput,
  GetListCitiesQuery,
  GetListCitiesQueryVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  CompanyCharacteristicInput,
  UpdateMyAccountInput,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  UpdateNameInput,
  UpdateDescriptionInput,
  CompanySocialInput,
  CompanyContactInput,
  GetListSocialKindsQuery,
  GetListSocialKindsQueryVariables,
  MenuPayload,
  LanguageCode,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";
import { getSocialIcon } from "../../utils/getSocialIcon";

import { Wrapper } from "./Account--styles";
import AccountIcon from "../../img/digishop/menu-icons/account.svg";

export type ChangePasswordForm = {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
};

const initialChangePasswordForm: ChangePasswordForm = {
  oldPassword: undefined,
  newPassword: undefined,
  confirmNewPassword: undefined,
};

const Account: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const kind = localStorage.getItem("kind");
  const intl = useIntl();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const languagesAsSecond = localMenu?.languages?.filter(
    (o) => o.isMain === false
  );
  const [open, setOpen] = React.useState(false);
  const [openTranslateRoadName, setOpenTranslateRoadName] =
    React.useState(false);
  const [accountInfo, setAccountInfo] = React.useState<UpdateMyAccountInput>({
    token,
    email: "",
    informations: {
      firstName: "",
      lastName: "",
    },
    accountSettings: { language: "FR", currency: "EUR" },
    company: {
      id: "",
      shortNames: [],
      longNames: [],
      descriptions: [],
      activity: "",
      fiscal: {
        corporateNames: [],
        legalForm: "",
        matriculation: "",
        taxCode: "",
      },
      address: {
        roadNames: [
          { languageCode: languageIsMain?.code as LanguageCode, value: "" },
        ],
        postalCode: undefined,
        country: undefined,
        city: undefined,
      },
      socials: [],
      contacts: [],
      characteristics: [],
    },
    contact: {
      address: null,
      socials: [],
    },
  });

  const [changePassword, setChangePassword] =
    React.useState<ChangePasswordForm>(initialChangePasswordForm);
  const [errorChangePwd, setErrorChangePwd] =
    React.useState<ChangePasswordForm>(initialChangePasswordForm);
  const [socialNetwork, setSocialNetwork] = React.useState<
    CompanySocialInput | undefined
  >(undefined);

  // for open Snackbar(alert)
  const [alert, setAlert] = React.useState(false);
  // message for alert
  const [msgSnackbar, setMsgSnackbar] = React.useState("");
  // for alert succes or error
  const [severity, setSeverity] = React.useState<"success" | "error">(
    "success"
  );

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // Query GETLISTCOUNTRIES
  const [getListCountry, { data: listCountry }] = useLazyQuery<
    GetListCountriesQuery,
    GetListCountriesQueryVariables
  >(GETLISTCOUNTRIES);

  // Query GETLISTCITIES
  const [getListCities, { data: listCities }] = useLazyQuery<
    GetListCitiesQuery,
    GetListCitiesQueryVariables
  >(GETLISTCITIES, {
    variables: {
      countryId: accountInfo.company?.address?.country || "",
    },
  });

  const { data: social } = useQuery<
    GetListSocialKindsQuery,
    GetListSocialKindsQueryVariables
  >(GETLISTSOCIALKINDS, { variables: { input: { languageCode: "EN" } } });

  // Query GETMYACCOUNT
  const { data: myAccount, loading } = useQuery<
    GetMyAccountQuery,
    GetMyAccountQueryVariables
  >(GETMYACCOUNT, {
    variables: {
      token,
      languageCode: languageIsMain?.code as LanguageCode,
    },
  });

  useEffect(() => {
    getListCountry();
    getListCities();
  }, [getListCities, getListCountry]);

  React.useEffect(() => {
    const companyInfo = myAccount?.getMyAccount.account.company;
    const userInfo = myAccount?.getMyAccount.account.informations;

    const roadNames: UpdateNameInput[] = [];
    const shortNames: UpdateNameInput[] = [];
    const longNames: UpdateNameInput[] = [];
    const descriptions: UpdateDescriptionInput[] = [];
    const socials: CompanySocialInput[] = [];
    const contacts: CompanyContactInput[] = [];
    const characteristics: CompanyCharacteristicInput[] = [];

    companyInfo?.address?.roadNames?.forEach((add) =>
      roadNames.push({
        id: add?.id,
        languageCode: add.languageCode as LanguageCode,
        value: add.value,
      })
    );

    const informations: UserInformationsInput = {
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
    };

    const address: UpdateAddressInput = {
      id: companyInfo?.address?.id,
      roadNames,
      postalCode: companyInfo?.address?.postalCode,
      country: companyInfo?.address?.country?.id || undefined,
      city: companyInfo?.address?.city?.id || undefined,
    };

    const fiscal: UpdateCompanyFiscalInput = {
      corporateNames: [
        {
          id: null,
          languageCode: languageIsMain?.code as LanguageCode,
          value: companyInfo?.fiscal?.corporateName,
        },
      ],
      matriculation: companyInfo?.fiscal?.matriculation,
      taxCode: companyInfo?.fiscal?.taxCode,
    };

    const email = myAccount?.getMyAccount.account.email;

    companyInfo?.characteristics?.map((carac) =>
      characteristics.push({
        kind: carac.kind as CompanyCharacteristicKind,
        value: carac.value,
      })
    );

    companyInfo?.socials?.map((item) =>
      socials.push({
        kind: item.kind?.id,
        value: item.value,
      })
    );

    companyInfo?.contacts?.forEach((con) =>
      contacts.push({
        phone: {
          countryCode: con.phone?.countryCode || 216,
          number: con.phone?.number || "",
        },
        email: con?.email,
      })
    );

    const website = companyInfo?.website;
    const activity = myAccount?.getMyAccount.account?.company?.activity;

    setAccountInfo({
      ...accountInfo,
      email,
      informations,
      company: {
        id: companyInfo?.id || "",
        shortNames,
        longNames,
        fiscal,
        address,
        activity,
        descriptions,
        website,
        socials,
        contacts,
        characteristics,
      },
    });
  }, [myAccount]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangePassword({
      ...changePassword,
      [event.target.name]: event.target.value,
    });
    setErrorChangePwd({
      ...errorChangePwd,
      [event.target.name]: undefined,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setChangePassword(initialChangePasswordForm);
  };

  // mutation change password
  const [resetPassword, { loading: loadingChangePwd }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGEPASSWORD, {
    onCompleted: () => {
      handleClose();
      setAlert(true);
      setSeverity("success");
      setMsgSnackbar(intl.formatMessage({ id: "Account.succesChangePwd" }));
    },
    onError: (Error) => {
      setAlert(true);
      setSeverity("error");
      setMsgSnackbar(Error.graphQLErrors[0].message);
    },
  });

  // mutation update account
  const [updateAccount] = useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UPDATE_ACCOUNT, {
    variables: {
      input: {
        token,
        email: accountInfo.email,
        company: accountInfo.company,
        informations: accountInfo.informations,
        accountSettings: accountInfo.accountSettings,
      },
    },
    refetchQueries: () => [
      {
        query: GETMYACCOUNT,
        variables: {
          token,
          languageCode: "EN",
        },
      },
    ],
  });

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleSubmitChangePassword = () => {
    const validation = ValidationChangePassword(changePassword);
    if (Object.entries(validation).length === 0) {
      resetPassword({
        variables: {
          token,
          oldPassword: changePassword.oldPassword!,
          newPassword: changePassword.newPassword!,
        },
      });
    } else {
      setErrorChangePwd(validation);
    }
  };

  const handleUpdate = () => {
    updateAccount().then(() => {
      setMsgSnackbar("Data successfully saved!");
      setAlert(true);
    });
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = { countryCode: 1, number: "" };

    if (
      accountInfo?.company?.contacts &&
      accountInfo?.company?.contacts.length > 0
    ) {
      phone = {
        countryCode: accountInfo.company?.contacts?.[0].phone?.countryCode || 1,
        number: accountInfo.company?.contacts?.[0].phone?.number || "",
      };
      phone.number = e.target.value;
    } else {
      phone.number = e.target.value;
    }
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        contacts: [{ ...accountInfo?.company?.contacts?.[0], phone }],
      },
    });
  };

  const handleCompanyEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let companyEmail = "";

    if (
      accountInfo?.company?.contacts &&
      accountInfo?.company?.contacts.length > 0
    ) {
      companyEmail = accountInfo.company?.contacts?.[0].email || "";
      companyEmail = e.target.value;
    } else {
      companyEmail = e.target.value;
    }
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        contacts: [
          { ...accountInfo?.company?.contacts?.[0], email: companyEmail },
        ],
      },
    });
  };

  const handleCountryCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = { countryCode: 1, number: "" };
    if (
      accountInfo?.company?.contacts &&
      accountInfo?.company?.contacts.length > 0
    ) {
      phone = {
        countryCode:
          accountInfo.company?.contacts?.[0]?.phone?.countryCode || 1,
        number: accountInfo.company?.contacts?.[0]?.phone?.number || "",
      };
      phone.countryCode = parseInt(e.target.value, 10);
    } else {
      phone.countryCode = parseInt(e.target.value, 10);
    }
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        contacts: [{ phone }],
      },
    });
  };

  const handleCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const corporateName: UpdateNameInput = {
      languageCode: languageIsMain?.code as LanguageCode,
      value: e.target.value,
    };
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        fiscal: {
          ...accountInfo.company?.fiscal,
          corporateNames: [corporateName],
        },
      },
    });
  };

  const onChangeCity = React.useCallback(debounce(getListCities, 500), []);

  const onChangeCountry = React.useCallback(debounce(getListCountry, 500), []);

  const handleCountry = (value: any) => {
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        address: {
          ...accountInfo.company?.address,
          country: value.id,
        },
      },
    });
    onChangeCountry();
  };

  const handleCity = (value: any) => {
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        address: {
          ...accountInfo.company?.address,
          city: value.id,
        },
      },
    });
    onChangeCity();
  };

  const handleAddSocial = () => {
    if (socialNetwork) {
      const arraySocial = [...(accountInfo.company?.socials || [])];
      arraySocial.push({
        kind: socialNetwork?.kind,
        value: socialNetwork?.value,
      });
      setAccountInfo({
        ...accountInfo,
        company: {
          ...accountInfo.company,
          socials: arraySocial,
        },
      });
      setSocialNetwork(undefined);
    }
  };

  const handleDeleteSocial = (idSocial: string) => {
    const arraySocial = [...(accountInfo.company?.socials || [])];
    const position = findIndex(arraySocial, (o) => o.kind === idSocial);
    if (position !== -1) {
      arraySocial.splice(position, 1);
      setAccountInfo({
        ...accountInfo,
        company: {
          ...accountInfo.company,
          socials: arraySocial,
        },
      });
    }
  };

  const getSocial = (idSocial?: string | null) => {
    return social?.getListSocialKinds.list.filter(
      (socialNet) => socialNet.id === idSocial
    );
  };

  const handleRoadNames = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        address: {
          ...accountInfo.company?.address,
          roadNames: [
            {
              id: accountInfo.company?.address?.roadNames?.find(
                (o) => o.languageCode === languageIsMain?.code
              )?.id,
              languageCode: languageIsMain?.code as LanguageCode,
              value: event.target.value,
            },
          ],
        },
      },
    });
  };

  const handleRoadNamesTranslate = (values: UpdateNameInput[]) => {
    const arrayRoadNames = [
      ...(accountInfo?.company?.address?.roadNames || []),
    ];

    values?.forEach((item) => {
      const position = findIndex(
        arrayRoadNames,
        (o) => o.languageCode === item.languageCode
      );
      if (position > -1) {
        arrayRoadNames[position] = {
          ...arrayRoadNames[position],
          value: item.value,
        };
      } else {
        arrayRoadNames.push({
          id: item.id,
          languageCode: item.languageCode as LanguageCode,
          value: item.value,
        });
      }
    });

    setAccountInfo({
      ...accountInfo,
      company: {
        ...accountInfo.company,
        address: {
          ...accountInfo.company?.address,
          roadNames: arrayRoadNames,
        },
      },
    });
  };

  return (
    <Wrapper>
      {loading && !myAccount ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="topbar">
            <BreadCrumbsTitle
              labelIntl={["Account", "Account Settings"]}
              icon={AccountIcon}
              iconAlt="Account"
            />
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleUpdate}
            >
              <FontAwesomeIcon icon={faSave} color="#ffffff" />
              <span className="btn-description">
                {intl.formatMessage({ id: "Account.save" })}
              </span>
            </Button>
          </div>
          {/* Informations */}
          <div className="main-container">
            {/* account */}
            <Box p={24} width={1}>
              <h3> {intl.formatMessage({ id: "Account.details" })}</h3>
              <div className="box">
                <div className="box-row" style={{ alignItems: "center" }}>
                  <TextField
                    id="login"
                    label={intl.formatMessage({ id: "Account.email" })}
                    name="login"
                    type="text"
                    variant="filled"
                    value={accountInfo.email || ""}
                    onChange={() => {}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                    }}
                  />
                  <span
                    className="show"
                    style={{ marginTop: "-25px", marginRight: "25px" }}
                  >
                    {intl.formatMessage({ id: "Account.status" })}:{" "}
                    {myAccount?.getMyAccount?.account?.accountActivation
                      ?.activationStatus?.name === "Actif" ? (
                      <div className="status-actif">
                        {
                          myAccount?.getMyAccount?.account?.accountActivation
                            ?.activationStatus?.name
                        }
                      </div>
                    ) : (
                      <div className="status-inactif">
                        {
                          myAccount?.getMyAccount?.account?.accountActivation
                            ?.activationStatus?.name
                        }
                      </div>
                    )}
                  </span>

                  <span style={{ marginTop: "-25px", marginRight: "25px" }}>
                    {intl.formatMessage({ id: "Account.expirationDate" })}:{" "}
                    {myAccount?.getMyAccount?.account?.accountActivation
                      ?.activationDate &&
                      moment(
                        myAccount?.getMyAccount?.account?.accountActivation
                          ?.activationDate
                      ).format("YYYY/MM/DD")}
                  </span>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{ marginTop: "-25px", marginRight: "25px" }}
                    onClick={() => setOpen(true)}
                  >
                    <FontAwesomeIcon icon={faKey} color="#ffffff" />
                    <span className="btn-description">
                      &nbsp;&nbsp;&nbsp;
                      {intl.formatMessage({ id: "Account.changePassword" })}
                    </span>
                  </Button>
                </div>
              </div>
            </Box>
            <Box p={24} width={1}>
              <h3>{intl.formatMessage({ id: "Account.informations" })} </h3>
              <div className="box account-info">
                <div>
                  <span className="input-label">
                    {intl.formatMessage({ id: "Account.fieldActivity" })}
                  </span>

                  <TextField
                    id="activity"
                    label={intl.formatMessage({ id: "Account.activity" })}
                    type="text"
                    variant="filled"
                    value={accountInfo?.company?.activity}
                    onChange={(e) => {
                      setAccountInfo({
                        ...accountInfo,
                        company: {
                          ...accountInfo.company,
                          activity: e.target.value as string,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      marginRight: "30px",
                    }}
                  />
                </div>
                <div className="domain">
                  <span className="input-label">
                    {intl.formatMessage({ id: "Account.companyDomain" })}
                  </span>
                  <TextField
                    id="identfier"
                    label={intl.formatMessage({ id: "Account.companyDomain" })}
                    type="text"
                    variant="filled"
                    value={
                      myAccount?.getMyAccount.account.company?.domain || ""
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "calc(100% - 300px)",
                      marginRight: "30px",
                    }}
                    disabled
                  />
                </div>
              </div>
              <div className="box">
                <div className="box-row">
                  <TextField
                    id="socialReson"
                    label="Raison sociale"
                    name="socialReson"
                    type="text"
                    variant="filled"
                    value={
                      accountInfo?.company?.fiscal?.corporateNames?.find(
                        (o) => o.languageCode === languageIsMain?.code
                      )?.value
                    }
                    onChange={handleCompanyName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "400px",
                      marginRight: "30px",
                      marginBottom: "24px",
                    }}
                  />
                  <TextField
                    id="matriculation"
                    label={intl.formatMessage({ id: "Account.immarticul" })}
                    name="matriculation"
                    type="text"
                    variant="filled"
                    value={accountInfo.company?.fiscal?.matriculation || ""}
                    onChange={(e) =>
                      setAccountInfo({
                        ...accountInfo,
                        company: {
                          ...accountInfo.company,
                          fiscal: {
                            ...accountInfo.company?.fiscal,
                            matriculation: e.target.value,
                          },
                        },
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                      marginBottom: "24px",
                    }}
                  />
                  <TextField
                    id="tva"
                    label={intl.formatMessage({ id: "Account.tva" })}
                    name="tva"
                    type="text"
                    variant="filled"
                    value={accountInfo.company?.fiscal?.taxCode || ""}
                    onChange={(e) =>
                      setAccountInfo({
                        ...accountInfo,
                        company: {
                          ...accountInfo.company,
                          fiscal: {
                            ...accountInfo.company?.fiscal,
                            taxCode: e.target.value,
                          },
                        },
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                      marginBottom: "24px",
                    }}
                  />
                </div>
              </div>
              <div className="box">
                <span className="input-label">
                  {intl.formatMessage({ id: "Account.address" })}
                </span>
                <div className="box-row">
                  <TextField
                    id="Address"
                    label={intl.formatMessage({ id: "Account.address" })}
                    name="Address"
                    type="text"
                    variant="filled"
                    value={
                      accountInfo.company?.address?.roadNames?.find(
                        (o) => o.languageCode === languageIsMain?.code
                      )?.value || ""
                    }
                    onChange={handleRoadNames}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setOpenTranslateRoadName(true)}
                          >
                            <TranslateIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "400px",
                      marginRight: "30px",
                      marginBottom: "24px",
                    }}
                  />
                  <TextField
                    id="postalCode"
                    label={intl.formatMessage({ id: "Account.postal" })}
                    name="postalCode"
                    type="tel"
                    variant="filled"
                    value={accountInfo.company?.address?.postalCode || ""}
                    onChange={(e) => {
                      setAccountInfo({
                        ...accountInfo,
                        company: {
                          ...accountInfo.company,
                          address: {
                            ...accountInfo.company?.address,
                            postalCode: parseInt(e.target.value, 10),
                          },
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                      marginBottom: "24px",
                    }}
                  />

                  <Autocomplete
                    id="country"
                    options={
                      (listCountry && listCountry.getListCountries.list) || []
                    }
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(event, value) => {
                      value && handleCountry(value);
                    }}
                    defaultValue={
                      myAccount?.getMyAccount.account.company?.address?.country
                    }
                    style={{
                      marginBottom: "24px",
                      width: "300px",
                      marginRight: "30px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        name="country"
                        {...params}
                        label={intl.formatMessage({ id: "Account.country" })}
                        variant="filled"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    )}
                  />
                  <Autocomplete
                    id="city"
                    options={
                      (listCities && listCities.getListCities.list) || []
                    }
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(event, value) => {
                      value && handleCity(value);
                    }}
                    defaultValue={
                      myAccount?.getMyAccount.account.company?.address?.city
                    }
                    style={{
                      marginBottom: "24px",
                      width: "300px",
                      marginRight: "30px",
                    }}
                    debug
                    renderInput={(params) => (
                      <TextField
                        name="city"
                        {...params}
                        label={intl.formatMessage({ id: "Account.city" })}
                        variant="filled"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
              <div className="box">
                <span className="input-label">
                  {intl.formatMessage({ id: "Account.phone" })}
                </span>
                <div className="box" style={{ flexDirection: "row" }}>
                  <TextField
                    id="codeCountry"
                    label="Code"
                    name="codeCountry"
                    type="text"
                    variant="filled"
                    value={
                      accountInfo.company?.contacts &&
                      accountInfo.company?.contacts?.length > 0
                        ? accountInfo.company?.contacts?.[0].phone
                            ?.countryCode || ""
                        : ""
                    }
                    onChange={handleCountryCode}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "60px",
                      marginRight: "10px",
                    }}
                  />
                  <TextField
                    id="phone"
                    label={intl.formatMessage({ id: "Account.mobile" })}
                    name="phone"
                    type="text"
                    variant="filled"
                    value={
                      accountInfo.company?.contacts &&
                      accountInfo.company?.contacts?.length > 0
                        ? accountInfo.company?.contacts?.[0].phone?.number || ""
                        : ""
                    }
                    onChange={handlePhone}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                    }}
                  />
                  <TextField
                    id="emailCompany"
                    label={intl.formatMessage({ id: "Account.companyEmail" })}
                    name="emailCompany"
                    type="text"
                    variant="filled"
                    value={
                      accountInfo.company?.contacts &&
                      accountInfo.company?.contacts?.length > 0
                        ? accountInfo.company?.contacts?.[0].email || ""
                        : ""
                    }
                    onChange={handleCompanyEmail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "400px",
                      marginRight: "30px",
                    }}
                  />
                </div>
              </div>
            </Box>
            {/* Personal information */}
            <Box p={24} width={1}>
              <h3>
                {" "}
                {intl.formatMessage({ id: "Account.personalInformation" })}{" "}
              </h3>
              <div className="box">
                <span className="input-label">
                  {intl.formatMessage({ id: "Account.personalInformation" })}
                </span>
                <div className="box-row">
                  <TextField
                    id="firstName"
                    label={intl.formatMessage({ id: "Account.firstName" })}
                    name="firstName"
                    type="text"
                    variant="filled"
                    value={accountInfo.informations?.firstName || ""}
                    onChange={(event) => {
                      setAccountInfo({
                        ...accountInfo,
                        informations: {
                          ...accountInfo.informations,
                          firstName: event.target.value,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                    }}
                  />
                  <TextField
                    id="lastName"
                    label={intl.formatMessage({ id: "Account.lastName" })}
                    name="lastName"
                    type="text"
                    variant="filled"
                    value={accountInfo.informations?.lastName || ""}
                    onChange={(event) => {
                      setAccountInfo({
                        ...accountInfo,
                        informations: {
                          ...accountInfo.informations,
                          lastName: event.target.value,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "300px",
                      marginRight: "30px",
                    }}
                  />
                </div>
              </div>
            </Box>
            {kind !== "Vendor" && (
              <Box p={24} width={1}>
                <h3>
                  {intl.formatMessage({
                    id: "Account.socialMedia",
                  })}
                </h3>
                <div className="box">
                  <span className="input-label">
                    {intl.formatMessage({ id: "Account.Website" })}
                  </span>
                  <TextField
                    id="website"
                    label={intl.formatMessage({ id: "Account.Website" })}
                    name="website"
                    value={accountInfo?.company?.website || ""}
                    onChange={(e) =>
                      setAccountInfo({
                        ...accountInfo,
                        company: {
                          ...accountInfo?.company,
                          website: e.target.value,
                        },
                      })
                    }
                    type="text"
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span style={{ marginTop: 10, fontSize: 14 }}>
                    {intl.formatMessage({
                      id: "Account.selectSocialMedia",
                    })}
                  </span>
                  <div className="social">
                    <FormControl variant="filled" className="form-control">
                      <InputLabel htmlFor="filled-activity" shrink>
                        {intl.formatMessage({ id: "Account.socialMedia" })}
                      </InputLabel>
                      <Select
                        input={
                          <FilledInput name="activity" id="filled-activity" />
                        }
                        value={socialNetwork?.kind || ""}
                        onChange={(e) =>
                          setSocialNetwork({
                            ...socialNetwork,
                            kind: String(e.target.value),
                          })
                        }
                        style={{
                          width: "250px",
                          marginRight: "30px",
                        }}
                      >
                        {social &&
                          social.getListSocialKinds.list.map((socialNet) => {
                            return (
                              <MenuItem
                                key={socialNet.id}
                                value={socialNet.id || ""}
                              >
                                {socialNet.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <TextField
                      id="link"
                      label={intl.formatMessage({ id: "Account.link" })}
                      name="link"
                      value={socialNetwork?.value || ""}
                      onChange={(e) =>
                        setSocialNetwork({
                          ...socialNetwork,
                          value: e.target.value,
                        })
                      }
                      type="text"
                      variant="filled"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Fab
                      style={{ margin: "auto 0 3px 0" }}
                      aria-label="add"
                      color="secondary"
                      onClick={handleAddSocial}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        color="#ffffff"
                      />
                    </Fab>
                  </div>
                  {accountInfo?.company?.socials?.map((item) => {
                    return (
                      <div key={item.kind} className="social-link">
                        <img
                          src={getSocialIcon(getSocial(item.kind)?.[0].name)}
                          alt="social network logo"
                        />
                        <span>{getSocial(item.kind)?.[0].name}</span>
                        <hr />
                        <span>{item.value}</span>
                        <IconButton
                          onClick={() => handleDeleteSocial(item.kind!)}
                        >
                          <HighlightOffIcon color="error" />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              </Box>
            )}
          </div>
        </>
      )}
      <Modal
        open={open}
        title={intl.formatMessage({ id: "Account.changePassword" })}
        action="update"
        handleClose={handleClose}
        handleContent={handleSubmitChangePassword}
        loading={loadingChangePwd}
      >
        <div
          style={{
            width: 380,
            marginBottom: -24,
          }}
        >
          <Input
            id="oldPassword"
            value={changePassword.oldPassword || ""}
            name="oldPassword"
            onChange={handleChange}
            label={intl.formatMessage({ id: "Account.oldPassword" })}
            type="password"
            autoComplete="new-password"
            msgError={
              errorChangePwd.oldPassword &&
              intl.formatMessage({ id: errorChangePwd.oldPassword })
            }
            style={{ marginBottom: 24 }}
          />
          <Input
            id="newPassword"
            value={changePassword.newPassword || ""}
            name="newPassword"
            onChange={handleChange}
            label={intl.formatMessage({ id: "Account.newPassword" })}
            type="password"
            autoComplete="new-password"
            msgError={
              errorChangePwd.newPassword &&
              intl.formatMessage({ id: errorChangePwd.newPassword })
            }
            style={{ marginBottom: 24 }}
          />
          <Input
            id="confirmNewPassword"
            value={changePassword.confirmNewPassword || ""}
            name="confirmNewPassword"
            onChange={handleChange}
            label={intl.formatMessage({ id: "Account.confirmPassword" })}
            type="password"
            autoComplete="new-password"
            msgError={
              errorChangePwd.confirmNewPassword &&
              intl.formatMessage({ id: errorChangePwd.confirmNewPassword })
            }
            style={{ marginBottom: 24 }}
          />
        </div>
      </Modal>
      <TranslateAddressAccountModal
        open={openTranslateRoadName}
        onClose={() => setOpenTranslateRoadName(false)}
        languageIsMain={languageIsMain}
        languages={languagesAsSecond}
        values={accountInfo?.company?.address?.roadNames || []}
        onReturn={(roadNames) => handleRoadNamesTranslate(roadNames)}
      />
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {msgSnackbar}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Account;
