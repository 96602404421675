import styled from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  .arrow-back {
    width: 100px;
    color: #1f90cf;
  }
  .tabs {
    width: 100%;
    border-bottom: 1px solid #edeff2;
    .tab {
      min-width: 80px;
      font-weight: 700;
      cursor: pointer;
      padding: 15px 0 15px 0;
      margin: 0 16px;
    }
    .tab-selected {
        min-width: 80px;
        font-weight: 700;
        color : ${theme.palette.secondary.main};
        cursor: pointer;
        border-bottom: 2px solid ${theme.palette.secondary.main};
        padding: 15px 0 15px 0;
        margin: 0 16px;
    } 
  }
  .title-pricing {
    font-weight: 700;
  }
  .pricing-description {
    color: #6E7586;
    margin : 5px 0 10px 0;
  }
  .disabled {
    cursor: not-allowed !important;
    color: #e0e0e0;
  }
  `}
`;

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;
