import * as React from "react";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useReactiveVar } from "@apollo/client";

import {
  AddPackageInput,
  DeliveryPackageSize,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import { ButtonCustomize, Input, Select } from "../../commons";
import { UploadPictureProduct } from "..";

type Picture = {
  id: string;
  url: string;
};

type SnoonuAddPackageModalProps = {
  open: boolean;
  onClose: () => void;
  onReturn: (data: AddPackageInput, pictures?: Picture) => void;
};

const SnoonuAddPackageModal = ({
  open,
  onClose,
  onReturn,
}: SnoonuAddPackageModalProps): JSX.Element => {
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [objPackage, setObjPackage] = React.useState<AddPackageInput>({
    kinds: [
      {
        size: "SMALL",
        cost: 0,
      },
    ],
  });
  const [cost, setCost] = React.useState("0");
  const [picturesPackage, setPicturesPackage] = React.useState<Picture>();

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setObjPackage({
      ...objPackage,
      [name]: value,
    });
  };

  const handleChangeSize = (event: React.ChangeEvent<{ value: unknown }>) => {
    setObjPackage({
      ...objPackage,
      kinds: [
        {
          cost: objPackage.kinds?.[0].cost,
          size: event.target.value as DeliveryPackageSize,
        },
      ],
    });
  };

  const handleChangeCost = () => {
    setObjPackage({
      ...objPackage,
      kinds: [
        {
          size: objPackage.kinds?.[0].size,
          cost: parseFloat(cost),
        },
      ],
    });
  };

  const handleAdd = () => {
    onReturn(objPackage, picturesPackage);
    onClose();
    setObjPackage({
      kinds: [
        {
          size: "SMALL",
          cost: 0,
        },
      ],
    });
  };

  React.useEffect(() => {
    setObjPackage({
      kinds: [
        {
          size: "SMALL",
          cost: 0,
        },
      ],
    });
  }, [onClose]);

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box display="flex" width={1} flexDirection="column" minWidth={450}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Add package
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="column" padding={2}>
          <UploadPictureProduct
            description="Add package picture"
            uploadTo="Delivery"
            mode="Base"
            onLoaded={(picture, copiedPictures, url) => {
              setObjPackage({
                ...objPackage,
                picture,
              });
              url &&
                setPicturesPackage({
                  id: picture,
                  url,
                });
            }}
          />
          <Input
            name="name"
            label="Package name"
            value={objPackage?.name || ""}
            onChange={handleChangeName}
            style={{ margin: "15px 0" }}
          />
          <Select
            label="Package size"
            value={objPackage?.kinds?.[0]?.size || ""}
            onChange={handleChangeSize}
            style={{ marginBottom: 15 }}
          >
            <MenuItem value="SMALL">Small</MenuItem>
            <MenuItem value="BIG">Big</MenuItem>
          </Select>
          <Input
            name="cost"
            label={`Package Cost ${localMenu?.currency?.code}`}
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            onBlur={handleChangeCost}
            onKeyPress={onKeyPress}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleAdd}
          >
            Add
          </ButtonCustomize>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SnoonuAddPackageModal;
