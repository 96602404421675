import * as React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

import { HOST_FORSA, HOST_RAWI } from "../../../api/config";

import { Wrapper, Note, CustomerIconButton } from "./Plans-style";

const { host } = window.location;

const Plans = (): JSX.Element => {
  const [state, setState] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [packId, setpackId] = React.useState("");

  const handleGetData = (pFamilyId: string, chId: string) => {
    fetch(
      `https://subscription-engine-v1.herokuapp.com/api/pack/get-ch-packs?productFamilyId=${pFamilyId}&channelId=${chId}`
    )
      .then((response) => response.json())
      .then((data) => setState(data?.result));
  };

  React.useEffect(() => {
    if (host === HOST_FORSA)
      handleGetData("614c88c9532d35130cb36f04", "614c8875532d35130cb36efc");
    else if (host === HOST_RAWI)
      handleGetData("614c88a5532d35130cb36f00", "614c8881532d35130cb36efe");
  }, [host]);

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Price",
      accessor: "price",
    },
    {
      header: "Pack Type",
      accessor: "packType",
    },
    {
      header: "Payment Type",
      accessor: "paymentType",
    },
    {
      header: "Payment Frequency",
      accessor: "paymentFrequency",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      state?.map((item: any) => ({
        name: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.packName}
          </Typography>
        ),
        price: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.price}&nbsp;{item.currency}
          </Typography>
        ),
        packType: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.packType}
          </Typography>
        ),
        paymentType: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.paymentType}
          </Typography>
        ),
        paymentFrequency: (
          <Typography variant="subtitle2" style={{ margin: "5px 0" }}>
            {item.paymentFrequency}
          </Typography>
        ),
        actions: (
          <CustomerIconButton
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              setpackId(item._id);
              setOpen(true);
            }}
          >
            <DeleteIcon />
          </CustomerIconButton>
        ),
      })) || []
    );
  };

  const handleDelete = () => {
    fetch(`https://subscription-engine-v1.herokuapp.com/api/pack/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        packId,
      }),
    }).then(() => {
      if (host === HOST_FORSA)
        handleGetData("614c88c9532d35130cb36f04", "614c8875532d35130cb36efc");
      else if (host === HOST_RAWI)
        handleGetData("614c88a5532d35130cb36f00", "614c8881532d35130cb36efe");
      setOpen(false);
    });
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Store settings", "Plans"]}
          iconAlt="products"
        />
        <Link to="/plans-form">
          <ButtonCustomize
            variant="contained"
            color="secondary"
            style={{ marginLeft: 10 }}
          >
            <AddIcon />
            &nbsp; Add new
          </ButtonCustomize>
        </Link>
      </Note>
      <div className="container">
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No data available"
        />
      </div>
      <Modal
        open={open}
        title="Delete plan"
        message="Are you sure you want to remove this plan ?"
        handleClose={() => setOpen(false)}
        handleContent={() => handleDelete()}
        action="delete"
      />
    </Wrapper>
  );
};

export default Plans;
