import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
`;

export const CustomerIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.error.main};
  margin: 0 4px;
`;
