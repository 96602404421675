import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import findIndex from "lodash/findIndex";
import InputAdornment from "@material-ui/core/InputAdornment";
import Avatar from "@material-ui/core/Avatar";
import { ButtonCustomize, Input } from "../../commons";
import { getFlag, getLanguage } from "../../utils";

import {
  LanguageCode,
  MenuLanguagePayload,
  UpdateNameInput,
  LanguagePayload,
} from "../../api/types";

import { DialogStyled } from "./TranslateAddressAccountModal--style";

type TranslateAddressAccountModalProps = {
  open: boolean;
  onClose: () => void;
  languageIsMain?: LanguagePayload | null;
  languages?: MenuLanguagePayload[];
  values: UpdateNameInput[];
  onReturn: (data: UpdateNameInput[]) => void;
};

const TranslateAddressAccountModal = ({
  open,
  onClose,
  languageIsMain,
  languages,
  values,
  onReturn,
}: TranslateAddressAccountModalProps): JSX.Element => {
  const [state, setState] = React.useState<UpdateNameInput[]>([]);

  React.useEffect(() => {
    if (values.length > 0) {
      setState((s) => {
        const arrayRoads = [...s];
        values.forEach((item) => {
          if (item.languageCode !== languageIsMain?.code) {
            arrayRoads.push({
              id: item.id,
              languageCode: item.languageCode as LanguageCode,
              value: item.value,
            });
          }
        });
        return arrayRoads;
      });
    }
  }, [values]);

  const handleChange = (value: string, languageCode: string) => {
    const position = findIndex(state, (o) => o.languageCode === languageCode);

    if (position > -1) {
      setState((prevState) => {
        const arrayData = [...prevState];
        arrayData[position] = {
          ...arrayData[position],
          value,
        };
        return arrayData;
      });
    } else {
      setState((prevState) => {
        const arrayData = [...prevState];
        arrayData.push({
          languageCode: languageCode as LanguageCode,
          value,
        });
        return arrayData;
      });
    }
  };

  return (
    <DialogStyled open={open} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #EDEFF2"
        p={2}
      >
        <Typography variant="h2" style={{ fontWeight: 700 }}>
          Translate address
        </Typography>

        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        paddingLeft={2}
        paddingRight={2}
      >
        {languages?.map((item) => (
          <Input
            label="Address"
            value={
              state?.find((o) => o.languageCode === item?.language?.code)?.value
            }
            onChange={(event) =>
              handleChange(event.target.value, item?.language?.code || "")
            }
            endAdornment={
              <InputAdornment position="end">
                <Avatar
                  alt={getLanguage(item?.language?.code || "")}
                  src={getFlag(item?.language?.code || "")}
                />
              </InputAdornment>
            }
            style={{ marginTop: 16 }}
          />
        ))}

        {languages?.length === 0 && (
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            You don&apos;t have other languages
          </Typography>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={2}
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={2}
      >
        <ButtonCustomize
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={onClose}
        >
          Cancel
        </ButtonCustomize>
        <ButtonCustomize
          variant="contained"
          color="secondary"
          onClick={() => {
            onReturn(state);
            onClose();
          }}
        >
          Ok
        </ButtonCustomize>
      </Box>
    </DialogStyled>
  );
};

export default TranslateAddressAccountModal;
