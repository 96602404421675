import * as React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useQuery, useReactiveVar } from "@apollo/client";
import EditIcon from "@material-ui/icons/Edit";
import find from "lodash/find";

import { ButtonCustomize, TablePagination } from "../../commons";

import { GET_HOME_PAGE } from "../../api";
import {
  GetHomePageQuery,
  GetHomePageQueryVariables,
  MenuPayload,
  TitlePayload,
  LanguageCode,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

type ListTranslateHomePageProps = {
  origineLangage: LanguageCode;
  onHomePage: (titles: TitlePayload[]) => void;
};

const ListTranslateHomePage = ({
  origineLangage,
  onHomePage,
}: ListTranslateHomePageProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const { data: homePageData } = useQuery<
    GetHomePageQuery,
    GetHomePageQueryVariables
  >(GET_HOME_PAGE, {
    skip: !localMenu?.id,
    variables: { input: { menu: localMenu?.id || "", token } },
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Wrapper>
      <div className="list-item">
        {homePageData?.getHomePage?.collections
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((item) => (
            <Row key={item?.titles?.[0]?.id}>
              <Typography variant="subtitle1">
                {find(item?.titles, (o) => o.languageCode === origineLangage)
                  ?.value ? (
                  find(item?.titles, (o) => o.languageCode === origineLangage)
                    ?.value
                ) : (
                  <Typography variant="subtitle2" className="empty">
                    This title is not translated in this language
                  </Typography>
                )}
              </Typography>
              <ButtonCustomize
                onClick={() => {
                  item.titles && onHomePage(item.titles);
                }}
                disabled={
                  !find(item?.titles, (o) => o.languageCode === origineLangage)
                    ?.value
                }
              >
                <EditIcon fontSize="small" style={{ marginRight: 3 }} /> Edit
              </ButtonCustomize>
            </Row>
          ))}
      </div>
      <TablePagination
        labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
        count={homePageData?.getHomePage?.collections?.length || 0}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Wrapper>
  );
};

export default ListTranslateHomePage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .list-item {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    margin: 5px 0 10px 0;
    .MuiButton-root {
      color: #3fb4f6;
    }
    .MuiButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;

const Row = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #eeeffa;
  .empty {
    color: #a9a9a9;
  }
`;
