import styled from "styled-components";

export const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      .MuiButton-label {
        display: block;
        font-size: 14px;
        text-transform: lowercase;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  .description {
    margin: 15px 0;
    p {
      font-size: 12px;
      color: #bcbcbc;
      margin: 0;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
    .form-option {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
  }
`;
