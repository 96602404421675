/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import { useMutation } from "@apollo/client";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  faBookReader,
  faLink,
  faUnlink,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Box from "./Box";
import Modal from "./Modal";

import { URL_DP_SHOP } from "../api/config";
import { MenuPayload } from "../api/types";

import { SELECT_MENU } from "../api/local-state";
import { EN, FR, AR, ZH, DE, IT, JA, PT, ES, TR } from "../img";

type LinkType = {
  link: boolean;
};

type Flags = { [key: string]: string };

type Menu = {
  input: MenuPayload;
  domain: string | null | undefined;
  // handleContent?: (
  //   open: boolean,
  //   alert: string,
  //   severity: "success" | "error"
  // ) => void;
};

type DropDownType = {
  show: boolean | null;
};

type MenuListType = {
  danger?: boolean;
};

const MenuCard: React.FC<Menu> = (props) => {
  const { input, domain } = props;
  const history = useHistory();

  /* states */
  const [OpenMenu, setOpenMenu] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [links, setLinks] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);

  /* Mutations */
  // const [deletMenu, { loading }] = useMutation<
  //   DeleteMenuMutation,
  //   DeleteMenuMutationVariables
  // >(DELETEMENU, {
  //   variables: { deleteMenu: { token: token, id: input?.id! } },
  //   awaitRefetchQueries: true,
  //   refetchQueries: () => [
  //     {
  //       query: GETLISTMENUS,
  //       variables: { token: token },
  //     },
  //   ],
  //   onCompleted: () => {
  //     handleContent && handleContent(true, successfullyDeleted, "success");
  //   },
  //   onError: () => {
  //     handleContent && handleContent(true, microserviceUnvailable, "error");
  //   },
  // });

  /* set Selected Menu in Local Storage */
  const [selectMenu] = useMutation(SELECT_MENU, {
    variables: { id: input?.id || "" },
  });

  // translation
  const intl = useIntl();

  const handleClickAway = () => {
    setOpenMenu(false);
    setLinks(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleOpenDelete = () => {
    setOpenModalDelete(true);
    setOpenMenu(false);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleValues = () => {
    // deletMenu();
    setOpenModalDelete(false);
  };

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  function Alert(alertProps: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertProps} />;
  }

  const copyLink = (type: string) => {
    setOpenSnack(true);
    const el: any = document.createElement("textarea");
    if (type === "qr" /* + input.accessCode */) {
      el.value = [
        URL_DP_SHOP!.slice(0, 8),
        "qr.",
        domain,
        ".",
        URL_DP_SHOP!.slice(8),
        input.identifier,
      ].join("");
    } else {
      el.value = [
        URL_DP_SHOP!.slice(0, 8),
        domain,
        ".",
        URL_DP_SHOP!.slice(8),
        input.identifier,
      ].join("");
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const getFlag: Flags = {
    FR,
    EN,
    AR,
    IT,
    DE,
    ES,
    PT,
    TR,
    ZH,
    JA,
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper>
        <Modal
          open={openModalDelete}
          title={intl.formatMessage({ id: "MenuCard.deleteTitle" })}
          handleClose={handleClose}
          handleContent={handleValues}
          message={intl.formatMessage({ id: "MenuCard.deleteMessage" })}
          action="delete"
          // loading={loading}
        />
        <Box p={20} width={1} position="relative">
          <div className="menucard-topbar">
            <div className="icon">
              <FontAwesomeIcon icon={faBookReader} size="lg" color="#000000" />
            </div>
            <div className="title-wrapper">
              {input?.names?.[0]?.value && (
                <Tooltip title={input.names[0].value}>
                  <h3>{input.names[0].value}</h3>
                </Tooltip>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Linked link={!!input?.isPublished} className="small-icon">
                <div className="status-icon">
                  <FontAwesomeIcon
                    icon={input?.isPublished ? faLink : faUnlink}
                    size="sm"
                    color={input?.isPublished ? "#278e38" : "#d46c6c"}
                  />
                </div>
                <div className="status">
                  <span>
                    {input?.isPublished
                      ? intl.formatMessage({ id: "MenuCard.published" })
                      : intl.formatMessage({ id: "MenuCard.notPublished" })}
                  </span>
                </div>
              </Linked>
            </div>
            <div className="menu-btn">
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <DropDown show={OpenMenu}>
                <ul>
                  <MenuList
                    onClick={() => {
                      selectMenu().then(() => {
                        history.push({ pathname: "/items", state: { input } });
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "MenuCard.manage" })}
                  </MenuList>
                  <MenuList
                    onClick={() => {
                      selectMenu().then(() => {
                        history.push({
                          pathname: "/update-store",
                          state: { input },
                        });
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "MenuCard.settings" })}
                  </MenuList>
                  <MenuList
                    onClick={() => {
                      selectMenu().then(() => {
                        history.push({
                          pathname: "/style",
                        });
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "MenuCard.theme" })}
                  </MenuList>
                  <MenuList danger onClick={handleOpenDelete}>
                    {intl.formatMessage({ id: "MenuCard.delete" })}
                  </MenuList>
                </ul>
              </DropDown>
            </div>
          </div>
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="information-container">
              <label className="card-items">
                {intl.formatMessage({ id: "MenuCard.accessCode" })}:{" "}
              </label>
              <label className="card-items">
                <b>{/* {input?.accessCode} */}</b>
              </label>
              <div style={{ marginTop: "10px" }}>
                <label className="card-items">
                  {intl.formatMessage({ id: "MenuCard.contain" })}:{" "}
                </label>
                <label className="card-items">
                  {/* {input?.categoriesCount} */}
                  {intl.formatMessage({ id: "MenuCard.categories" })}
                </label>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <label className="card-items">
                  {intl.formatMessage({ id: "MenuCard.languages" })}:{" "}
                </label>
                <br />
                {input?.languages?.map((item) => (
                  <img
                    className="card-flag"
                    key={`key-${item.language?.code}`}
                    src={getFlag[item?.language?.code || "FR"]}
                    alt={item?.language?.code || "flag"}
                  />
                ))}
              </div>
            </div>
            <div className="">
              <div className="qr-container" />
              <div className="qr-actions">
                {/* eslint-disable-next-line */}
                <a
                  // href={`${URL_PICTURE_CATEGORY}/${input?.id}/menu_qr_codes/${input?.qrCodePicture?.fileName}`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={e => e.preventDefault()}
                >
                  <button
                    type="button"
                    className="print"
                    // disabled={!input?.qrCodePicture?.fileName}
                    // onClick={downloadQrCode}
                  >
                    <FontAwesomeIcon
                      icon={faQrcode}
                      size="lg"
                      color="#b5bcd2"
                    />
                    <span>
                      {intl.formatMessage({ id: "MenuCard.download" })}
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <DropDownLink show={links}>
            <div className="link-area">
              <input
                // id={"qr" + input.accessCode}
                type="text"
                disabled
                defaultValue={[
                  URL_DP_SHOP!.slice(0, 8),
                  "qr.",
                  domain,
                  ".",
                  URL_DP_SHOP!.slice(8),
                  input.identifier,
                ].join("")}
              />
              <button type="button" onClick={() => copyLink("qr")}>
                {intl.formatMessage({ id: "MenuCard.copy" })}
              </button>
            </div>
          </DropDownLink>
          {/* {input.characteristics?.[
            input.characteristics?.findIndex(
              (caracteristic) => caracteristic.kind === "ONLINE_SALE"
            )
          ]?.value === "ON" && (
            <DropDownLink show={links}>
              <div className="link-area">
                <input
                  id={"shop" + input.accessCode}
                  type="text"
                  disabled
                  defaultValue={[
                    URL_DP_SHOP!.slice(0, 8),
                    domain,
                    ".",
                    URL_DP_SHOP!.slice(8),
                    input.identifier,
                  ].join("")}
                />
                <button onClick={() => copyLink("shop")}>
                  {intl.formatMessage({ id: "MenuCard.copy" })}
                </button>
              </div>
            </DropDownLink>
          )} */}
        </Box>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="success">
            {intl.formatMessage({ id: "MenuCard.linkCopied" })}
          </Alert>
        </Snackbar>
      </Wrapper>
    </ClickAwayListener>
  );
};

const DropDownLink = styled.div<DropDownType>`
  position: relative;
  .link-area {
    margin-top: 10px;
    display: flex;
    align-items: center;
    background-color: #f2f2f6;
    border-radius: 8px;
    cursor: pointer;
    :hover button {
      display: block;
    }
  }
  .dd-btn {
    width: 165px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .url {
    width: 132px;
    cursor: pointer;
  }
  input {
    width: 100%;
    height: 35px;
    background-color: #f2f2f6;
    border-radius: 8px;
    border: 0;
    padding-left: 15px;
  }
  button {
    display: none;
    width: 18%;
    height: 31px;
    border: 0;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    border-radius: 8px;
  }

  .link-seperator {
    height: 16px;
    width: 1px;
    border: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.26);
    margin-left: 10px;
  }
  .link-dropDown {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
    position: absolute;
    bottom: -63px;
    background: white;
    display: ${(props) => (props.show === true ? "flex" : "none")};
    flex-direction: column;
    border-radius: 10px;
    left: 5px;
    padding: 2px;
    z-index: 999;
    label {
      width: 115px;
      padding: 8px 15px;
      margin: 2px;
      border-radius: 6px;
    }
    .selected-link {
      background-color: #f3f4fa;
    }
  }
`;

const Linked = styled.div<LinkType>`
  height: 45px;
  margin-left: auto;
  margin-right: 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) =>
    props.link === true ? "rgb(233, 243, 234)" : "rgb(251, 240, 240)"};
  :hover {
    /*box-shadow: 0px 7px 20px 3px #cad2cba8;*/
    .status {
      width: ${(props) => (props.link === true ? "53px" : "80px")};
    }
    transform: ${(props) =>
      props.link === true ? "translate(-53px)" : "translate(-80px)"};
  }
  .status {
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
    span {
      font-size: 14px;
      color: ${(props) => (props.link === true ? "#278e38" : "#d46c6c")};
    }
  }
  .status-icon {
    width: 45px;
    border-radius: 50%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1;
  margin: 20px auto;
  border: 0;
  border-top: 1px solid #f3f4fa;
`;

const DropDown = styled.div<DropDownType>`
  right: 0;
  top: 0;
  z-index: ${(props) => (props.show ? 99 : -1)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: fit-content;
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
  transition: all 0.15s ease-in-out;
  ul {
    margin: 0;
    padding: 0;
    div {
      cursor: ${(props) => props.show && "pointer"};
    }
  }
`;
const MenuList = styled.div<MenuListType>`
  padding: 12px 16px;
  min-width: 100px;
  border-radius: 5px;
  color: ${(props) => (props.danger ? "#F67070" : "#444444")};
  :hover {
    background-color: ${(props) => (props.danger ? "#FCF1F1" : "#f3f4fa")};
  }
`;

const Wrapper = styled.div`
  .menu-btn {
    position: relative;
    width: 45px;
    height: 45px;
    .MuiIconButton-root {
      padding: 10.5px;
    }
  }
  .card-items {
    font-size: 16px;
  }
  .card-date {
    font-size: 13px;
    color: #bdbdbd;
  }
  .menucard-topbar {
    display: grid;
    grid-template-columns: 45px 1fr 45px 45px;
    grid-gap: 10px;
    .icon {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background-color: rgba(0, 0, 78, 0.1);
    }
    h3 {
      margin: auto 0;
    }
  }
  .MuiSwitch-root {
    width: 36px;
    height: 18px;
    display: inline-flex;
    z-index: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
    border-radius: 20px;
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(18px);
    }
    .MuiSwitch-thumb {
      width: 14px;
      height: 14px;
      margin: 2px;
    }
    .MuiSwitch-thumb {
      box-shadow: none;
    }
    span:first-child {
      padding: 0;
    }
    .PrivateSwitchBase-root-97 {
      padding: 0;
    }
  }
  .action-style {
    font-weight: 500;
    margin-left: 10px;
  }
  .card-flag {
    width: 22px;
    margin: 7px -7px 0px 0px;
    border: 3px solid white;
    border-radius: 50%;
  }
  .link {
    margin: 0px;
    width: 300px;
    height: 57px;
    padding: 14px 10px 10px;
    font-size: 16px;
    border: 0;
    margin-top: 10px;
    background: #f5f5f5;
    border-radius: 8px;
    resize: none;
  }
  .qr-container {
    height: 100px;
    padding: 10px 10px 0px 10px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: rgba(0, 0, 78, 0.05);
    img {
      height: 100%;
    }
  }
  button.print {
    width: 100%;
    height: 40px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 0;
    background: rgba(0, 0, 78, 0.05);
    outline: none;
    transition: all 0.2s ease-in-out;
    span {
      margin-left: 10px;
      color: #b5bcd2;
    }
    span,
    svg {
      color: rgb(0, 0, 78);
    }
    :hover {
      color: #74abff;
      cursor: pointer;
      background-color: rgba(0, 0, 78, 0.15);
    }
  }
  .title-wrapper {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .url {
    font-size: 14px;
    padding: 0 0 2px 15px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export default MenuCard;
