// Styled component import
import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 24px;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  ${({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 20px;
  h3 {
    margin-bottom: 16px;
  }
  .input-container {
    margin-bottom: 20px;
    max-width: 400px;
    position: relative;
  }
  .settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-display-mode {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    svg path {
      fill: #444444;
    }
  }
  .selected {
    background-color: #f29f05;
    svg path {
      fill: #ffffff;
    }
  }
  .item-category-display {
    width: 77px;
    cursor: pointer;
  }
  .item-display-selected {
    width : 79px;
    border-radius: 11px;
    border: 2px solid ${theme.palette.secondary.main};
  }
  .checklist {
    position: absolute;
    right: -240px;
    background-color: ghostwhite;
    border: 1px solid #f1f2ff;
    padding: 5px 10px;
    border-radius: 8px;
    width: 200px;
    top: 00px;
    .tip {
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 10px solid #f1f2ff;
      border-bottom: 7px solid transparent;
      left: -9px;
      position: absolute;
      top: 7px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 13px;
        color: #868894;
        svg {
          margin-right: 8px;
        }
      }
      li.success svg {
        color: #0dca12;
      }
      li.error {
        color: #f67070;
        svg {
          color: #f67070;
        }
      }

      li.initial svg {
        color: #afb1c6;
      }
    }
  }`}
`;

export const Separator = styled.div`
  height: 20px;
`;
