/* eslint-disable  @typescript-eslint/no-unused-expressions */
import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { Dialog, Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Wrapper } from "./ModifyGroupModalStyle";
import { Box, Input } from "../../commons";

import {
  MenuGroupPayload,
  CustomerPayload,
  GetListCustomersQuery,
  GetListCustomersQueryVariables,
  UpdateMenuGroupMutation,
  UpdateMenuGroupMutationVariables,
} from "../../api/types";

import { GET_LIST_CUSTOMERS, UPDATEGROUP, GETLISTGROUP } from "../../api";
import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../api/local-state";

import { ClientsGroupImg } from "../../img";
import vector from "../../img/Vector.png";

type ModifyGroupModalProps = {
  open: boolean;
  handleClose: () => void;
  selected: MenuGroupPayload;
};

const ModifyGroupModal: React.FC<ModifyGroupModalProps> = ({
  open,
  handleClose,
  selected,
}) => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: listClients } = useQuery<
    GetListCustomersQuery,
    GetListCustomersQueryVariables
  >(GET_LIST_CUSTOMERS, {
    variables: {
      input: {
        token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const defaultCustomers: CustomerPayload[] = [];
    listClients?.getListCustomers?.list.forEach((itm) => {
      if (selected?.customersList) {
        for (let i = 0; i < selected.customersList.length; i++) {
          if (selected.customersList[i].id === itm.id) {
            defaultCustomers.push(itm);
          }
        }
      }
    });
    setSelectedValues(defaultCustomers);
    setInputName(selected?.names?.[0]?.value || "");
  }, [listClients, selected]);

  const [selectedValues, setSelectedValues] = React.useState<CustomerPayload[]>(
    []
  );

  const [inputName, setInputName] = React.useState<string>("");

  const [updateGroup, { loading: loadingGroupUpdate }] = useMutation<
    UpdateMenuGroupMutation,
    UpdateMenuGroupMutationVariables
  >(UPDATEGROUP, {
    refetchQueries: () => [
      {
        query: GETLISTGROUP,
        variables: {
          input: {
            token,
            menu: localMenu?.menuItem?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
    },
  });

  const trigerUpdateGroup = () => {
    updateGroup({
      variables: {
        input: {
          token,
          menu: localMenu?.menuItem?.id || "",
          id: selected.id || "",
          names: [
            {
              id: selected?.names?.[0]?.id || "",
              languageCode: "EN",
              value: inputName,
            },
          ],
          descriptions: [
            {
              id: null,
              languageCode: null,
              value: null,
            },
          ],
          customers: selectedValues.map((item) => {
            return {
              customer: item.id,
            };
          }),
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Box width={1} className="container">
          <div className="action">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              style={{ marginRight: 15 }}
            >
              {intl.formatMessage({ id: "Modal.cancel" })}
            </Button>
            <Button
              onClick={trigerUpdateGroup}
              disabled={loadingGroupUpdate}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage({ id: "Modal.update" })}
            </Button>
          </div>

          <div className="vector">
            <img src={vector} alt="vector" className="img-vector" />
            <img
              src={ClientsGroupImg}
              alt="apparence"
              className="img-apparence"
            />
          </div>
          <span className="title">
            {intl.formatMessage({ id: "store.customer.group.modifyGroup" })}
          </span>
          <div className="form">
            <Input
              onChange={(e) => setInputName(e.target.value)}
              value={inputName}
              label={intl.formatMessage({
                id: "store.customer.group.List.name",
              })}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <Autocomplete
              disableClearable
              disableCloseOnSelect
              value={selectedValues}
              onChange={(event, newValue) => {
                setSelectedValues(newValue);
              }}
              options={listClients?.getListCustomers.list || []}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              multiple
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="filled"
                  InputProps={{ ...params.InputProps, type: "search" }}
                  label={intl.formatMessage({
                    id: "store.customer.group.List",
                  })}
                />
              )}
              disabled={false}
            />
          </div>
        </Box>
      </Wrapper>
    </Dialog>
  );
};

export default ModifyGroupModal;
