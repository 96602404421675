import * as React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useReactiveVar } from "@apollo/client";
import find from "lodash/find";

import { Search, ButtonCustomize, TablePagination } from "../../commons";

import { GETLISTMENUCATEGORIES } from "../../api";
import {
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  MenuPayload,
  LanguageCode,
} from "../../api/types";

import { SelectedMenuVar } from "../../api/local-state";

type ListTranslateCategoryProps = {
  origineLangage: LanguageCode;
  onIdCategory: (id: string) => void;
};

const ListTranslateCategory: React.FC<ListTranslateCategoryProps> = ({
  origineLangage,
  onIdCategory,
}) => {
  const token = localStorage.getItem("token") || "";

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [search, setSearch] = React.useState<string | undefined>(undefined);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.id || "", name: search } },
  });
  const categoriesListData = listCategory?.getListMenuCategories?.list;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Wrapper>
      <Search onReturnSearch={(value) => setSearch(value || undefined)} />
      <div className="list-item">
        {categoriesListData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((item) => (
            <Row key={item.id}>
              <Typography variant="subtitle1">
                {find(item?.names, (o) => o.languageCode === origineLangage)
                  ?.value ? (
                  find(item?.names, (o) => o.languageCode === origineLangage)
                    ?.value
                ) : (
                  <Typography variant="subtitle2" className="empty">
                    This category is not translated in this language
                  </Typography>
                )}
              </Typography>
              <ButtonCustomize
                onClick={() => {
                  onIdCategory(item.id!);
                }}
                disabled={
                  !find(item?.names, (o) => o.languageCode === origineLangage)
                    ?.value
                }
              >
                <EditIcon fontSize="small" style={{ marginRight: 3 }} /> Edit
              </ButtonCustomize>
            </Row>
          ))}
      </div>
      <TablePagination
        labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
        count={categoriesListData?.length || 0}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Wrapper>
  );
};

export default ListTranslateCategory;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .input-search {
    .MuiInputBase-root {
      border-radius: 5px;
    }
    .mainInput {
      border-radius: 5px;
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    margin: 5px 0 10px 0;

    .MuiButton-root {
      color: #3fb4f6;
    }
    .MuiButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;

const Row = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #eeeffa;
  .empty {
    color: #a9a9a9;
  }
`;
