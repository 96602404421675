import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { Button, Dialog, CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";
import jwt from "jwt-decode";
import {
  faCloudUploadAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  GetListCompanyThemePicturesQuery,
  GetListCompanyThemePicturesQueryVariables,
} from "../api/types";
import { URL, GETLISTCOMPANYTHEMEPICTURE } from "../api";

type DisplayType = "MenuBackground" | "MenuTitle" | "CatgoryTitle" | undefined;
type SeperatorType = {
  display: DisplayType;
};
type Token = {
  id: string;
  company: string;
  exp: number;
};

export interface GaleryProps {
  open: boolean;
  children?: React.ReactNode;
  handleClose: () => void;
  // eslint-disable-next-line
  onTabSelect: (value: number) => void;
  loading?: boolean;
  display: DisplayType;
}

const Galery: FC<GaleryProps> = ({
  open,
  children,
  handleClose,
  display,
  onTabSelect,
}) => {
  const intl = useIntl();

  const [Open, setOpen] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<number>(1);
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState<string | undefined>(undefined);
  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleCloseGalery = () => {
    setOpen(false);
    handleClose();
    setImage(undefined);
  };

  const token: Token = jwt(localStorage.getItem("token") || "");
  const getUploadKind = () => {
    switch (display) {
      case "CatgoryTitle":
        return `${URL}/${token.company}/COMPANY_CATEGORY_TITLE_BACKGROUND_PICTURE`;
      case "MenuBackground":
        return `${URL}/${token.company}/COMPANY_STYLE_BACKGROUND_PICTURE`;
      case "MenuTitle":
        return `${URL}/${token.company}/COMPANY_MENU_TITLE_BACKGROUND_PICTURE`;
      default:
        return `${URL}/${token.company}/COMPANY_MENU_TITLE_BACKGROUND_PICTURE`;
    }
  };

  const setRefetchData = () => {
    switch (display) {
      case "CatgoryTitle":
        return "COMPANY_CATEGORY_TITLE_BACKGROUND_PICTURE";
      case "MenuBackground":
        return "COMPANY_STYLE_BACKGROUND_PICTURE";
      case "MenuTitle":
        return "COMPANY_MENU_TITLE_BACKGROUND_PICTURE";
      default:
        return "COMPANY_STYLE_BACKGROUND_PICTURE";
    }
  };

  const [getFiles, { loading: loadingRefetch }] = useLazyQuery<
    GetListCompanyThemePicturesQuery,
    GetListCompanyThemePicturesQueryVariables
  >(GETLISTCOMPANYTHEMEPICTURE, {
    variables: {
      input: {
        kind: setRefetchData(),
        token: localStorage.getItem("token") || "",
      },
    },
    fetchPolicy: "network-only",
  });

  const progressHandler = (event: any) => {
    setProgress((event.loaded / event.total) * 100);
  };

  const handleFiles = (files: FileList | null) => {
    if (files && files[0] !== null && files[0] !== undefined) {
      const dataForm = new FormData();
      dataForm.append("files", files[0]);
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", progressHandler, false);
      xhr.open("POST", getUploadKind());
      xhr.send(dataForm);
      xhr.onload = () => {
        if (xhr.status !== 200) {
          // analyze HTTP status of the response
          alert(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else {
          // show the result
          // let response = JSON.parse(xhr.response);
          getFiles(); // responseText is the server
          // eslint-disable-next-line
          !loadingRefetch && setImage(undefined);
        }
      };
    }
  };

  const handleDisplayDeco = (file: FileList) => {
    if (file && file[0] !== null && file[0] !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e: any) => {
        setImage(e.target.result);
      };
    }
  };

  const getRecommendation = () => {
    switch (display) {
      case "CatgoryTitle":
        return (
          <span>
            {intl.formatMessage({ id: "MenuStyle.categoryBgRec" })} <br /> 390px
            * 60px
          </span>
        );
      case "MenuBackground":
        return (
          <span>
            {intl.formatMessage({ id: "MenuStyle.menuBgRec" })} <br /> 2000px *
            1200px
          </span>
        );
      case "MenuTitle":
        return (
          <span>
            {intl.formatMessage({ id: "MenuStyle.titleBgRec" })} <br /> 360px *
            160px
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      aria-labelledby="simple-dialog-title"
      open={Open}
      onClose={handleCloseGalery}
      style={{ borderRadius: "10px" }}
    >
      <Tabs>
        <ul>
          <li className={tab === 1 ? "link" : ""}>
            <div
              role="button"
              tabIndex={0}
              onKeyUp={() => {
                setTab(1);
                onTabSelect(1);
              }}
              onClick={() => {
                setTab(1);
                onTabSelect(1);
              }}
            >
              {intl.formatMessage({ id: "MenuStyle.modalGaleryTab" })}
            </div>
          </li>
          <li className={tab === 2 ? "link" : ""}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                setTab(2);
                onTabSelect(2);
              }}
              onKeyUp={() => {
                setTab(2);
                onTabSelect(2);
              }}
            >
              {intl.formatMessage({ id: "MenuStyle.modalArchiveTab" })}
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              className="upload-btn"
              onClick={handleCloseGalery}
            >
              {intl.formatMessage({ id: "MenuStyle.confirmBtn" })}
            </Button>
          </li>
        </ul>
      </Tabs>
      <Wrapper tab={tab}>
        {tab === 2 && (
          <Recommendation>
            <div className="recommendation">
              <FontAwesomeIcon
                size="lg"
                icon={faExclamationCircle}
                color="#007AFF"
              />
              {getRecommendation()}
            </div>
            <button type="button" className="upload-btn">
              <input
                type="file"
                onChange={(e) => {
                  e.target.files && handleDisplayDeco(e.target.files);
                  handleFiles(e.target.files);
                }}
              />
              <FontAwesomeIcon icon={faCloudUploadAlt} color="#ffffff" />
              <span>{intl.formatMessage({ id: "MenuStyle.downloadBtn" })}</span>
            </button>
          </Recommendation>
        )}
        <div className={`content grid ${display}`}>
          {children}
          {loadingRefetch ? (
            <div className="refetch-loader">
              <CircularProgress />
            </div>
          ) : (
            image &&
            (display === "MenuBackground" ? (
              <div>
                <img
                  style={{ opacity: 0.3 }}
                  className="menu-background-image"
                  src={image}
                  alt="decoration"
                />
                <Progress progress={progress}>
                  <div className="progress-status" />
                </Progress>
              </div>
            ) : (
              <div className="frame">
                <div className="image-frame">
                  <img
                    className="menu-background-image"
                    src={image}
                    alt="decoration"
                  />
                </div>
                <SeperatorProgress />
                <Progress progress={progress}>
                  <div className="progress-status" />
                </Progress>
              </div>
            ))
          )}
          <Seperator display={display} />
        </div>
      </Wrapper>
    </Dialog>
  );
};

type ProgressType = {
  progress: number;
};

type WrapperType = {
  tab: number;
};

const SeperatorProgress = styled.div`
  width: 100%;
  height: 8px;
`;

const Seperator = styled.div<SeperatorType>`
  width: 100%;
  height: 1px;
  grid-column: ${(props) =>
    props.display && props.display === "MenuBackground" ? "span 5" : "span 3"};
`;

const Progress = styled.div<ProgressType>`
  display: flex;
  justify-content: center;
  height: 5px;
  background-color: #f3f4f9;
  border-radius: 12px;
  align-items: center;
  color: #afb1c6;
  font-size: 13px;
  overflow: hidden;
  width: 80px;
  margin: auto;
  position: relative;
  opacity: ${(props) => props.progress >= 100 && 0};
  transition: all 0.2s ease-out;
  transition-delay: 2s;
  .progress-status {
    position: absolute;
    width: ${(props) => `${props.progress}%`};
    background-color: ${(props) =>
      props.progress < 100 ? "#74ABFF" : "#4CD964"};
    left: 0;
    border-radius: 2px;
    transition: all 0.4s ease-in;
    height: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f2fb;
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
  ul:first-child {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      cursor: pointer;
      display: inline-block;
      padding: 15px;
      margin: 0 5px;
      list-style: none;
    }
    .link {
      border-bottom: 2px solid #000000;
      color: #000000;
    }
  }
  ul:nth-child(2) {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      cursor: pointer;
      display: inline-block;
      list-style: none;
      margin: 0 15px;
    }
  }
`;

const Recommendation = styled.div`
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .recommendation {
    display: grid;
    grid-template-columns: 35px auto;
    grid-gap: 10px;
    padding: 10px;
    border: 2px dashed rgba(26, 92, 255, 0.15);
    border-radius: 5px;
    background-color: rgba(26, 92, 255, 0.04);
    svg {
      margin: auto;
      width: 24px;
      height: 24px;
    }
    span {
      margin: auto 0;
      font-size: 13px;
      line-height: 16px;
      color: #64b5f6;
    }
  }
  .upload-btn {
    background-color: #000000;
    border: 0;
    border-radius: 3px;
    padding: 10px;
    color: white;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    span {
      margin-left: 8px;
    }
    input {
      position: absolute;
      height: 160%;
      width: 100%;
      top: -25px;
      left: 0;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const Wrapper = styled.div<WrapperType>`
  overflow-y: scroll;
  .content {
    width: 60vw;
    height: ${(props) => (props.tab === 1 ? "65vh" : "calc(65vh - 72px)")};
    max-height: 65vh;
    padding: 20px;
  }
  .MuiDialog-paperWidthSm {
    max-width: unset;
    width: 60vw;
    height: 50vh;
  }
  .grid {
    display: grid;
    grid-gap: 1%;
  }
  .MenuBackground {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    .menu-background-image {
      width: 100%;
      border-radius: 7px;
    }
  }

  .CatgoryTitle {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    div.image-frame {
      position: relative;
      background: rgba(207, 207, 212, 0.44);
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 8px;
      img {
        width: 100%;
      }
    }
  }
  .MenuTitle {
    grid-template-columns: 32.66% 32.66% 32.66%;
    grid-auto-rows: min-content;
    div.image-frame {
      background: rgba(207, 207, 212, 0.44);
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 10px 0;
      img {
        height: 80px !important;
      }
    }
  }

  .image-frame {
    position: relative;
    height: fit-content;
    :hover > div {
      display: flex;
    }
  }
  .selected {
    transition: all 0.2s ease-in-out;
    transform: translate(0px, -5px);
    img {
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 10px 15px 2px #0000002b;
      border: 3px solid #000000;
      padding: 2px;
      border-radius: 10px;
    }
  }

  .not-selected {
    transition: all 0.2s ease-in-out;
    transform: translate(0px, 0px);
    img {
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 0px 0px 0px #0000002b;
      border: 3px solid transparent;
      padding: 2px;
      border-radius: 10px;
    }
  }

  .selectedFrame {
    border: 3px solid #000000 !important;
    padding: 2px;
    border-radius: 10px;
    transform: translate(0px, -5px) !important;
    transition: all 0.2s ease-in-out;
  }

  .frame {
    transition: all 0.2s ease-in-out;
    border: 3px solid transparent;
    padding: 2px;
    border-radius: 10px;
    transform: translate(0px, 0px);
  }

  .selectedDecoration {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 10px 15px 2px #0000002b;
  }

  .not-selectedDecoration {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 0px 0px #0000002b;
  }

  .selectedDeco {
    display: flex;
    svg {
      width: 12px;
      path {
        fill: #ffffff;
      }
    }
  }
  .refetch-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Galery;
