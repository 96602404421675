import React from "react";
import { useIntl } from "react-intl";
import { Dialog } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

import { Wrapper } from "./ModalPreviewCollectionStyle";
import { Box } from "../../commons";

import { GETFILTERCOLLECTIONSPREVIEW } from "../../api";
import {
  GetFilterCollectionPreviewQuery,
  GetFilterCollectionPreviewQueryVariables,
  GetFilterCollectionPreviewInput,
} from "../../api/types";

type ModalPreviewCollectionProps = {
  open: boolean;
  handleClose: () => void;
  dataInputFilterCollection: GetFilterCollectionPreviewInput;
};

const ModalPreviewCollection: React.FC<ModalPreviewCollectionProps> = ({
  open,
  handleClose,
  dataInputFilterCollection,
}) => {
  const { data } = useQuery<
    GetFilterCollectionPreviewQuery,
    GetFilterCollectionPreviewQueryVariables
  >(GETFILTERCOLLECTIONSPREVIEW, {
    variables: {
      input: dataInputFilterCollection,
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <Wrapper>
        <Box width={1}>
          <table className="data">
            <thead>
              <tr>
                {/* eslint-disable-next-line */}
                <th />
                <th>Identifier</th>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {data?.getFilterCollectionPreview?.filterList?.map((item) => (
                <tr key={item.id}>
                  <td>
                    <FontAwesomeIcon icon={faImage} size="2x" />
                  </td>
                  <td>{item.identifier}</td>
                  <td>{item.names && item.names[0].value}</td>
                  <td>
                    {/* it's a comment because there is a change from api */}
                    {/* {item.shortDescriptions && item.shortDescriptions[0].value} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Wrapper>
    </Dialog>
  );
};

export default ModalPreviewCollection;
