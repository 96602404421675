import styled from "styled-components";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";

export const Wrapper = styled(Dialog)<DialogProps>`
  padding: 10;
  .dialog__content {
    display: flex;
    min-width: 600px;
    padding: 0 25px 20px 15px;
    box-sizing: border-box;
    form {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .autocomplete {
      margin-bottom: 10px;
      .MuiFilledInput-underline:before {
        border-bottom: none;
      }
      .MuiFilledInput-underline:after {
        border-bottom: none;
      }
      .MuiInputBase-root {
        background-color: #ffffff;
        border: 2px solid #f1f2ff;
        border-radius: 5px;
        &:focus-within {
          border: 2px solid #000000;
          border-radius: 5px;
        }
      }
    }
  }
`;
