import * as React from "react";
import ContentLoader from "react-content-loader";

const LoadingListProductInventory = (): JSX.Element => {
  return (
    <ContentLoader
      width="100%"
      height="calc(100vh - 125px)"
      viewBox="0 0 100% calc(100vh - 125px)"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect
        x="12"
        y="0"
        rx="5"
        ry="5"
        width="450"
        height="calc(100vh - 125px)"
      />
      <rect
        x="475"
        y="0"
        rx="5"
        ry="5"
        width="100%"
        height="calc(100vh - 125px)"
      />
    </ContentLoader>
  );
};

export default LoadingListProductInventory;
