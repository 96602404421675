import gql from "graphql-tag";

export const GETLISTPRODUCTVARIANTS = gql`
  query GetListProductVariants($input: GetListProductVariantsInput!) {
    getListProductVariants(input: $input) {
      list {
        id
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        barcode
        sku
        shippingWeight
        picture {
          id
          fileUrl
        }
        priceDifference
        discountPercentage
        options {
          option {
            id
            names {
              id
              languageCode
              value
            }
            inputKind
          }
          value {
            id
            names {
              id
              languageCode
              value
            }
          }
        }
        isPublished
      }
    }
  }
`;

export const ADDPRODUCTVARIANT = gql`
  mutation AddProductVariant($input: AddProductVariantInput!) {
    addProductVariant(input: $input) {
      id
    }
  }
`;

export const UPDATEPRODUCTVARIANT = gql`
  mutation EditProductVariant($input: EditProductVariantInput!) {
    editProductVariant(input: $input) {
      success
    }
  }
`;

export const DELETEPRODUCTVARIANT = gql`
  mutation RemoveProductVariant($input: RemoveProductVariantInput!) {
    removeProductVariant(input: $input) {
      success
    }
  }
`;
