import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faUnlink,
  faUserCircle,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Modal from "./Modal";

import { URL_PICTURE_CATEGORY } from "../api";
import {
  MenuAdPayload,
  // DeleteMenuIdMutation,
  // DeleteMenuIdMutationVariables,
} from "../api/types";
import Box from "./Box";

type PromotionState = {
  menuAd: MenuAdPayload;
};

type DropDownType = {
  show: boolean | null;
};

type MenuListType = {
  danger?: boolean;
};

const Promotion: React.FC<PromotionState> = ({ menuAd }) => {
  const intl = useIntl();
  const history = useHistory();
  const [OpenMenu, setOpenMenu] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [expires, setExpires] = React.useState(0);

  const handleClickAway = () => {
    setOpenMenu(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleOpenDelete = () => {
    setOpenModalDelete(true);
    setOpenMenu(false);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  React.useEffect(() => {
    const today = new Date();
    const enDate = moment(menuAd?.endDate);
    const diff = enDate.diff(today, "days");
    setExpires(diff);
  }, [menuAd]);

  // mutation delete menu ad
  // const [deleteMenuAd, { loading }] = useMutation<
  //   DeleteMenuIdMutation,
  //   DeleteMenuIdMutationVariables
  // >(DELETEMENUAD, {
  //   variables: {
  //     id: menuAd?.id!,
  //     token: token,
  //   },
  //   refetchQueries: [
  //     {
  //       query: GETLISTMENUADS,
  //       variables: { token: token },
  //     },
  //   ],
  //   onCompleted: () => {
  //     setOpenModalDelete(false);
  //   },
  // });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper>
        <Modal
          open={openModalDelete}
          title={intl.formatMessage({ id: "Ads.card.deleteTitle" })}
          handleClose={handleClose}
          handleContent={() => {} /* deleteMenuAd */}
          message={intl.formatMessage({ id: "Ads.card.deleteMessage" })}
          action="delete"
          // loading={loading}
        />
        <Box p={20} position="relative" width={350}>
          <img
            className="pormotion-cover"
            src={`${URL_PICTURE_CATEGORY}/public/menu_ads/${menuAd?.pictures?.cover?.fileName}`}
            alt="promotion"
          />
          <div className="menucard-topbar">
            <label className="card-items">
              {menuAd?.names && menuAd?.names[0]?.value}
            </label>
            <div className="menu-btn">
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <DropDown show={OpenMenu}>
                <ul>
                  <MenuList
                    onClick={() => {
                      history.push({
                        pathname: "/promotion",
                        state: { menuAd },
                      });
                    }}
                  >
                    {intl.formatMessage({ id: "Ads.card.edit" })}
                  </MenuList>
                  <MenuList danger onClick={handleOpenDelete}>
                    {intl.formatMessage({ id: "Ads.card.delete" })}
                  </MenuList>
                </ul>
              </DropDown>
            </div>
          </div>
          <div className="data">
            {menuAd?.endDate && (
              <div className="row">
                <div className="icon red">
                  <FontAwesomeIcon icon={faCalendarDay} color="#F67070" />
                </div>
                <em>{intl.formatMessage({ id: "Ads.card.Expired" })}</em>
                {expires > 0 ? (
                  <span>
                    {`${expires} ${intl.formatMessage({ id: "Ads.card.Day" })}`}
                  </span>
                ) : (
                  <span>
                    {intl.formatMessage({ id: "Ads.card.isExpired" })}
                  </span>
                )}
              </div>
            )}
            <div className="row">
              <div className="icon blue">
                <FontAwesomeIcon icon={faUserCircle} color="#8495FF" />
              </div>
              <em>{intl.formatMessage({ id: "Ads.card.NumberOfClicks" })}</em>
              <span>{menuAd?.adClicksCount}</span>
            </div>
            <div className="row">
              <div className="icon green">
                <FontAwesomeIcon
                  icon={menuAd?.isPublished ? faLink : faUnlink}
                  color="#278e38"
                />
              </div>
              <em>{intl.formatMessage({ id: "Ads.card.status" })}</em>
              <span>
                {menuAd?.isPublished
                  ? intl.formatMessage({ id: "Ads.card.Published" })
                  : intl.formatMessage({ id: "Ads.card.notPublished" })}
              </span>
            </div>
          </div>
        </Box>
      </Wrapper>
    </ClickAwayListener>
  );
};

const DropDown = styled.div<DropDownType>`
  right: 0;
  top: 0;
  z-index: ${(props) => (props.show ? 99 : -1)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: fit-content;
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
  transition: all 0.15s ease-in-out;
  ul {
    margin: 0;
    padding: 0;
    div {
      cursor: ${(props) => props.show && "pointer"};
    }
  }
`;
const MenuList = styled.div<MenuListType>`
  padding: 12px 16px;
  min-width: 100px;
  border-radius: 5px;
  color: ${(props) => (props.danger ? "#F67070" : "#444444")};
  :hover {
    background-color: ${(props) => (props.danger ? "#FCF1F1" : "#f3f4fa")};
  }
`;

const Wrapper = styled.div`
  width: fit-content;
  margin-right: 25px;
  margin-bottom: 25px;
  img.pormotion-cover {
    width: 100%;
    object-fit: fill;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  .menu-btn {
    position: relative;
    width: 45px;
    height: 45px;
    .MuiIconButton-root {
      padding: 10.5px;
    }
  }
  .card-items {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    margin: auto 0;
  }
  .card-date {
    font-size: 13px;
    color: #bdbdbd;
  }
  em {
    font-style: normal;
    color: #757575;
  }
  .menucard-topbar {
    display: grid;
    grid-template-columns: auto 45px;
    .icon {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background-color: rgba(116, 171, 255, 0.15);
    }
    h3 {
      margin: auto 0 auto 15px;
    }
  }
  .time {
    cursor: pointer;
    display: inline-flex;
    width: 80px;
    height: 40px;
    background-color: #f1f2fb;
    border-radius: 8px;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 20px 0px 0;
    * {
      cursor: pointer;
    }
  }
  .block {
    display: block;
  }
  .MuiSwitch-root {
    width: 36px;
    height: 18px;
    display: inline-flex;
    z-index: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
    border-radius: 20px;
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(18px);
    }
    .MuiSwitch-thumb {
      width: 14px;
      height: 14px;
      margin: 2px;
    }
    .MuiSwitch-thumb {
      box-shadow: none;
    }
    span:first-child {
      padding: 0;
    }
    .PrivateSwitchBase-root-97 {
      padding: 0;
    }
  }
  .action-style {
    font-weight: 500;
    margin-left: 10px;
  }
  .row {
    width: 100%;
    display: grid;
    grid-template-columns: 35px 1fr 90px;
    grid-gap: 15px;
    span {
      margin: auto 0;
      text-align: right;
    }
    .icon {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .blue {
      background-color: #e6eaff;
    }
    .red {
      background-color: #fff4f4;
    }
    .green {
      background-color: #e8faeb;
    }
  }
  .data {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 15px;
    margin-top: 15px;
  }
`;

export default Promotion;
