import styled from "styled-components";

export const Print = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  color: #000000;
`;

export const Separation = styled.div`
  width: 100%;
  height: 2px;
  background-color: #c5c5c5;
  margin: 15px 0;
`;

type ColorInputType = {
  color: string;
};

export const ColorInput = styled.div<ColorInputType>`
  width: 20px;
  height: 20px;
  border: 2px solid #f1f2fb;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;
