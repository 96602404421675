import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const DialogStyled = styled(Dialog)`
  .dialog__title {
    display: flex;
    justify-content: flex-end;
  }
  .dialogue__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]
      .MuiAutocomplete-input {
      margin-top: 10px;
    }
  }

  .vector {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    min-height: 200px;
    min-width: 220px;
    .img-vector {
      position: absolute;
      width: 150px;
      z-index: 9;
    }
    .img-apparence {
      width: 50px;
      position: absolute;
      z-index: 99;
    }
    .btn-fab-add {
      position: absolute;
      width: 35px;
      height: 35px;
      top: 141px;
      z-index: 999;
    }
    .MuiFab-root.Mui-disabled {
      color: #fff;
      background-color: #f29f05;
    }
  }
  .dialog__title {
    margin-bottom: 27px;
  }

  .dialog__actions {
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .MuiSelect-root {
    padding: 8px 24px 8px 5px;
  }
  .MuiSvgIcon-root {
    right: 0;
  }
  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiAutocomplete-inputRoot {
    padding-right: 8px;
  }
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-top: 2px;
    padding-left: 8px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]
    .MuiAutocomplete-input {
    padding: 14px 4px;
  }

  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
    &:focus-within {
      background: #ffffff;
      border: 2px solid #000000;
      border-radius: 5px;
    }
    &:hover {
      background: #ffffff;
    }
    input {
      height: 23px;
    }
  }
  .MuiFormControl-marginDense {
    margin-top: 0;
    margin-bottom: 0;
  }

  .MuiFormControl-root {
    height: 100%;
  }
  .MuiInputBase-root {
    height: 100%;
    width: 100%;
  }
`;
