import * as React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";

import { ButtonCustomize, Input } from "../../commons";

import { GET_HOME_PAGE, UPDATE_HOME_PAGE } from "../../api";
import {
  GetHomePageQuery,
  GetHomePageQueryVariables,
  UpdateHomePageMutation,
  UpdateHomePageMutationVariables,
  TitlePayload,
  LanguageCode,
  MenuPayload,
  HomePageSlideInput,
  HomePageCollectionInput,
  TitleInput,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

type FormTranslateHomePageProps = {
  titleHomePage: TitlePayload[];
  language: LanguageCode | undefined;
  origineLangage: LanguageCode;
  onClean: () => void;
};

const FormTranslateHomePage = ({
  titleHomePage,
  language,
  origineLangage,
  onClean,
}: FormTranslateHomePageProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const [title, setTitle] = React.useState<TitlePayload>();

  const { data } = useQuery<GetHomePageQuery, GetHomePageQueryVariables>(
    GET_HOME_PAGE,
    {
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
        },
      },
      onCompleted: (homePage) => {
        const listCollections = homePage.getHomePage?.collections || [];
        listCollections?.forEach((collection) => {
          if (isEqual(collection.titles, titleHomePage)) {
            const findTitle = find(
              collection.titles,
              (o) => o.languageCode === language
            );
            setTitle({
              id: findTitle?.id,
              languageCode: findTitle?.languageCode as LanguageCode,
              value: findTitle?.value,
            });
          }
        });
      },
      skip: !localMenu?.id,
    }
  );

  const idTitle = find(
    titleHomePage,
    (o) => o.languageCode === origineLangage
  )?.id;

  const [updateHomePage, { loading }] = useMutation<
    UpdateHomePageMutation,
    UpdateHomePageMutationVariables
  >(UPDATE_HOME_PAGE, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "microservice_unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_HOME_PAGE,
        variables: { input: { menu: localMenu?.id || "", token } },
      },
    ],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle({
      ...title,
      value: event.target.value,
      languageCode: language,
    });
  };

  const handleSave = () => {
    const arraySlides: HomePageSlideInput[] = [];
    const arrayCollection: HomePageCollectionInput[] = [];
    data?.getHomePage?.slides?.forEach((slide) =>
      arraySlides.push({
        picture: slide.id,
      })
    );

    data?.getHomePage?.collections?.forEach((collection) => {
      const arrayTitle: TitleInput[] = [];
      collection?.titles?.forEach((item) => {
        arrayTitle.push({
          id: item.id,
          languageCode: item?.languageCode as LanguageCode,
          value: item?.value,
        });
      });
      const indexTitleFind = findIndex(arrayTitle, (o) => o.id === idTitle);
      if (indexTitleFind !== -1) {
        if (title?.languageCode === arrayTitle[indexTitleFind].languageCode) {
          arrayTitle[indexTitleFind] = {
            id: title?.id,
            languageCode: title?.languageCode as LanguageCode,
            value: title?.value,
          };
        } else {
          arrayTitle.push({
            id: title?.id,
            languageCode: title?.languageCode as LanguageCode,
            value: title?.value,
          });
        }
      }

      arrayCollection.push({
        position: collection.position,
        tags: collection.tags,
        picture: collection.picture?.id,
        titles: arrayTitle,
      });
    });

    if (localMenu?.id) {
      updateHomePage({
        variables: {
          input: {
            token,
            menu: localMenu.id,
            collections: arrayCollection,
            slides: arraySlides,
          },
        },
      });
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <Typography variant="h3" style={{ fontWeight: 600 }}>
          {find(titleHomePage, (o) => o.languageCode === origineLangage)?.value}
        </Typography>
        <div>
          <ButtonCustomize onClick={onClean} style={{ marginRight: 10 }}>
            Cancel
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </ButtonCustomize>
        </div>
      </div>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            label="Title"
            value={
              find(titleHomePage, (o) => o.languageCode === origineLangage)
                ?.value || ""
            }
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            name="title"
            label="Title"
            value={title?.value || ""}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FormTranslateHomePage;

const Wrapper = styled.div`
  display: flex;
  min-width: 820px;
  flex-direction: column;
  padding: 0 10px 7px 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
`;
