// Styled component import
import styled from "styled-components";

export const Wrapper = styled.div`
  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .view-actins {
    display: flex;
    margin-left: 11px;
  }
  button.refresh {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    padding: 8px 12px;
    color: #03a9f4;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    span {
      padding-bottom: 2px;
    }
    :hover {
      background-color: #eeeff7;
    }
    svg {
      margin-right: 12px;
      path {
        fill: #03a9f4;
      }
    }
  }
  .arrow-filter {
    position: absolute;
    background-color: #4a4a4a;
    width: 25px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    svg {
      stroke-width: 2px;
      path {
        fill: #ffffff;
      }
    }
  }
  .menu {
    position: relative;
  }
  .order-list-content {
    display: grid;
    grid-template-columns: 1fr auto;
    .items-details {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .filter {
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    .detail-filter {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .detail-filter__item {
        display: grid;
        grid-template-columns: 1fr 35px;
        padding-left: 28px;
        cursor: pointer;
        span {
          font-size: 17px;
          margin: 9px 0;
          color: #1f90cf;
        }
        :first-child {
          span {
            color: #000000;
          }
        }
      }
      .detail-filter__item:hover {
        background-color: #f3f3f460;
      }
    }
  }
  .open {
    width: 400px;
  }
  .close {
    width: 0px;
  }
  .options {
    margin-left: 25px;
  }
  .clm2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
  .input {
    height: 43px;
    border: 1px solid #b7b7b9;
    border-radius: 5px;
    padding-left: 15px;
  }
  .MuiOutlinedInput-input {
    height: 32px;
    background: #ffffff;
    padding: 6px 2px 5px 15px;
    font-size: 13px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
  .search-filter-container {
    display: flex;
    align-items: center;
  }
  .MuiSelect {
    background-color: #ffffff;
    padding: 21px 12px 3px;
    border: 1px solid #b7b7b9;
    border-radius: 7px;
  }
  .MuiSelect:focus-within {
    border: 1px solid #b7b7b9;
  }
`;
