import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title img {
    width: 35px;
    height: 35px;
  }
`;
