import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useQuery } from "@apollo/client";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Select,
} from "../../../commons";

import { GETLISTCURRENCIES } from "../../../api";
import {
  GetListCurrenciesQuery,
  GetListCurrenciesQueryVariables,
} from "../../../api/types";
import { HOST_FORSA, HOST_RAWI } from "../../../api/config";

import { Wrapper, Note } from "./Plans-style";

type PlansFormState = {
  packName: string;
  description: string;
  price: string;
  packType: string;
  paymentType: string;
  paymentFrequency?: string;
  currency: string;
  productFamilyId: string;
  packLimitValue?: number;
};

const intialPlansForm: PlansFormState = {
  packName: "",
  description: "",
  price: "",
  packType: "Subscription",
  currency: "",
  paymentType: "",
  productFamilyId: "",
};

const { host } = window.location;

const PlansForm = (): JSX.Element => {
  const histroy = useHistory();

  const [state, setState] = React.useState<PlansFormState>(intialPlansForm);

  const { data: listCurencies } = useQuery<
    GetListCurrenciesQuery,
    GetListCurrenciesQueryVariables
  >(GETLISTCURRENCIES);

  const onKeyPressForFloat = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof state;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  React.useEffect(() => {
    if (host === HOST_FORSA) {
      setState({
        ...state,
        productFamilyId: "614c88c9532d35130cb36f04",
      });
    } else if (host === HOST_RAWI) {
      setState({
        ...state,
        productFamilyId: "614c88a5532d35130cb36f00",
      });
    }
  }, [host]);

  const handleSave = () => {
    fetch("https://subscription-engine-v1.herokuapp.com/api/pack/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...state,
      }),
    }).then((data) => histroy.push("/plans"));
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Store settings", "Plans"]}
          iconAlt="products"
        />
        <Link to="/plans-form">
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSave}
            style={{ marginLeft: 10 }}
          >
            Save
          </ButtonCustomize>
        </Link>
      </Note>
      <div className="container">
        <div style={{ width: 800 }}>
          <Input
            name="packName"
            label="Name"
            value={state.packName}
            onChange={handleChange}
            style={{ marginBottom: 10 }}
          />
          <Input
            name="description"
            label="Description"
            value={state.description}
            multiline
            rows={3}
            onChange={handleChange}
            style={{ marginBottom: 10 }}
          />
          <Select
            name="packType"
            label="Pack Type"
            value={state.packType}
            onChange={handleChangeSelect}
            style={{ marginBottom: 10 }}
            disabled
          >
            <MenuItem value="Subscription">Subscription</MenuItem>
            <MenuItem value="Product">Product</MenuItem>
          </Select>
          <Select
            name="paymentType"
            label="Payment Type"
            value={state.paymentType}
            onChange={handleChangeSelect}
            style={{ marginBottom: 10 }}
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Yearly">Yearly</MenuItem>
          </Select>
          {state.packType === "Subscription" &&
            state.paymentType === "Monthly" && (
              <Select
                name="paymentFrequency"
                label="Payment Frequency"
                value={state.paymentFrequency}
                onChange={handleChangeSelect}
                style={{ marginBottom: 10 }}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
              </Select>
            )}
          <Box display="flex">
            <Input
              name="price"
              label="Price"
              value={state.price}
              onKeyPress={onKeyPressForFloat}
              onChange={handleChange}
              style={{ minWidth: 590, marginRight: 10 }}
            />
            <Select
              name="currency"
              label="Currency"
              value={state.currency}
              onChange={handleChangeSelect}
              style={{ marginBottom: 10 }}
            >
              {listCurencies?.getListCurrencies?.list?.map((currency) => (
                <MenuItem value={currency.code || ""} key={currency.id}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={state.packLimitValue !== undefined}
                onChange={(e) =>
                  setState({
                    ...state,
                    packLimitValue: e.target.checked ? 0 : undefined,
                  })
                }
                name="isExistLimit"
              />
            }
            label="Limit subscriptions product purchase"
            style={{ marginBottom: 5 }}
          />
          {state.packLimitValue !== undefined && (
            <Input
              name="packLimitValue"
              label="Product quantity limit"
              value={state.packLimitValue || ""}
              type="number"
              onChange={handleChange}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default PlansForm;
