import gql from "graphql-tag";

export const GETSUBSCRIBERSLIST = gql`
  query getListMenuSubscribers($input: GetListMenuSubscribersInput!) {
    getListMenuSubscribers(input: $input) {
      list {
        id
        email
        origin
      }
    }
  }
`;
export const CREATESUBSCRIBER = gql`
  mutation CreateMenuSubscriber($input: CreateMenuSubscriberInput!) {
    createMenuSubscriber(input: $input) {
      id
    }
  }
`;
export const DELETESUBSCRIBER = gql`
  mutation DeleteMenuSubscriber($input: DeleteMenuSubscriberInput!) {
    deleteMenuSubscriber(input: $input) {
      success
    }
  }
`;

export const SENDEMAIL = gql`
  mutation SendEmailNewsletter($input: SendEmailNewsletterInput!) {
    sendEmailNewsletter(input: $input) {
      success
    }
  }
`;

// export const GET_LIST_MENU_SUBSCRIBERS = gql`
//   query GetListMenuSubscribers($input: GetListMenuSubscribersInput!) {
//     getListMenuSubscribers(input: $input) {
//       list {
//         id
//         email
//       }
//     }
//   }
// `;
