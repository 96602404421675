import React, { FC, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";

import { Wrapper } from "./styles";
import { Box } from "../../commons";
import { CONFIRM_ACCOUNT } from "../../api/merchant.api";
import { ReactComponent as AccountConfirmed } from "../../img/accountConfirmed.svg";
import {
  ConfirmAccountMutation,
  ConfirmAccountMutationVariables,
} from "../../api/types";
import { snackBarVar } from "../../api/local-state";

const ConfirmAccount: FC = () => {
  const history = useHistory();
  const intl = useIntl();

  // verificatoin code
  const { code } = useParams();

  const [confirmAccount] = useMutation<
    ConfirmAccountMutation,
    ConfirmAccountMutationVariables
  >(CONFIRM_ACCOUNT, {
    variables: { input: { verificationCode: code || "" } },
    onError: (error) => {
      if (error.message.replace(/['"]+/g, "") === "code_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Code not valid",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  useEffect(() => {
    if (code) confirmAccount();
  }, [confirmAccount, code]);

  return (
    <Wrapper>
      <Box>
        <AccountConfirmed width="200" height="200" />
        <h3>{intl.formatMessage({ id: "confirm.header" })}</h3>
        <p>{intl.formatMessage({ id: "confirm.message" })}</p>
        <Button
          data-testid="submit"
          type="submit"
          style={{
            margin: "0px auto 16px",
            position: "relative",
            minWidth: 130,
          }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => history.push("/login")}
        >
          {intl.formatMessage({ id: "confirm.connect" })}
        </Button>
      </Box>
    </Wrapper>
  );
};

export default ConfirmAccount;
