import gql from "graphql-tag";

// Get List Countries
export const GETLISTCOUNTRIES = gql`
  query getListCountries($name: String) {
    getListCountries(input: { name: $name }) {
      list {
        id
        name
      }
    }
  }
`;

// Get List Cities
export const GETLISTCITIES = gql`
  query getListCities($countryId: String!, $name: String) {
    getListCities(input: { countryId: $countryId, name: $name }) {
      list {
        id
        name
      }
    }
  }
`;

// create company site
export const CREATECOMPANYSITE = gql`
  mutation createCompanySite($company: CreateCompanySiteInput!) {
    createCompanySite(input: $company) {
      id
    }
  }
`;

// Get List Company Sites
export const GETLISTCOMPANYSITES = gql`
  query GetListCompanySites($token: String!, $menu: String) {
    getListCompanySites(input: { token: $token, menu: $menu }) {
      list {
        id
        identifier
        address {
          id
          roadNames {
            id
            languageCode
            value
          }
          postalCode
          country {
            id
            name
          }
          city {
            id
            name
          }
        }
      }
    }
  }
`;

// Mutation update company site
export const UPDATECOMPANYSITES = gql`
  mutation UpdateCompanySite($input: UpdateCompanySiteInput!) {
    updateCompanySite(input: $input) {
      success
    }
  }
`;

// Mutation delete comapny site
export const DELETECOMPANYSITES = gql`
  mutation deleteCompanySite($token: String!, $id: String!) {
    deleteCompanySite(input: { token: $token, id: $id }) {
      success
    }
  }
`;
