import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Paper, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useMutation, useReactiveVar, useQuery } from "@apollo/client";
import findIndex from "lodash/findIndex";
import find from "lodash/find";

import { BreadCrumbsTitle, ButtonCustomize } from "../../commons";

import {
  ADD_MENU_LEGALITY_PAGE,
  GET_MENU_LEGALITY_PAGE,
  UPDATE_MENU_LEGALITY_PAGE,
  GET_MENU_LEGALITY_SETTINGS,
} from "../../api";
import {
  AddMenuLegalityPageMutation,
  AddMenuLegalityPageMutationVariables,
  GetMenuLegalityPageQuery,
  GetMenuLegalityPageQueryVariables,
  UpdateMenuLegalityPageMutation,
  UpdateMenuLegalityPageMutationVariables,
  MenuPayload,
  UpdateNameInput,
  LanguageCode,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { Wrapper } from "./LegalityForm--styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";

const LegalityForm: React.FC = () => {
  const { title, identifier } = useLocation<{
    title?: string;
    identifier?: string;
  }>().state;
  const token = localStorage.getItem("token") || "";
  const history = useHistory();

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const { data: dataLegality } = useQuery<
    GetMenuLegalityPageQuery,
    GetMenuLegalityPageQueryVariables
  >(GET_MENU_LEGALITY_PAGE, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        identifier: identifier || "",
      },
    },
    onCompleted: (data) => {
      if (data.getMenuLegalityPage.page) {
        const content =
          data.getMenuLegalityPage?.page?.content?.find(
            (o) => o.languageCode === languageIsMain?.code
          )?.value || "";
        const blocksFromHtml = htmlToDraft(content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    },
    onError: (error) => {
      snackBarVar({
        open: true,
        severity: "error",
        message: error.message,
      });
    },
    skip: !localMenu?.id,
    fetchPolicy: "network-only",
  });

  const [AddLegality, { loading }] = useMutation<
    AddMenuLegalityPageMutation,
    AddMenuLegalityPageMutationVariables
  >(ADD_MENU_LEGALITY_PAGE, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_MENU_LEGALITY_SETTINGS,
        variables: {
          input: {
            token,
            id: localMenu?.id || "",
          },
        },
      },
    ],
  });

  const [UpdateLegality, { loading: loadingUpdate }] = useMutation<
    UpdateMenuLegalityPageMutation,
    UpdateMenuLegalityPageMutationVariables
  >(UPDATE_MENU_LEGALITY_PAGE, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_MENU_LEGALITY_SETTINGS,
        variables: {
          input: {
            token,
            id: localMenu?.id || "",
          },
        },
      },
    ],
  });

  const handleChange = (value: EditorState) => {
    setEditorState(value);
  };

  const handleSave = () => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (dataLegality?.getMenuLegalityPage?.page?.id) {
      const arrayTitles: UpdateNameInput[] = [];
      const arrayContent: UpdateNameInput[] = [];
      dataLegality?.getMenuLegalityPage?.page?.title?.map((item) =>
        arrayTitles.push({
          id: item.id,
          languageCode: item.languageCode as LanguageCode,
          value: item.value,
        })
      );
      dataLegality?.getMenuLegalityPage?.page?.content?.map((item) =>
        arrayContent.push({
          id: item.id,
          languageCode: item.languageCode as LanguageCode,
          value: item.value,
        })
      );

      const position = findIndex(
        arrayContent,
        (o) => o.languageCode === languageIsMain?.code
      );

      if (position !== -1) {
        arrayContent[position] = {
          ...arrayContent[position],
          value: content,
        };
      } else {
        arrayContent.push({
          languageCode: languageIsMain?.code as LanguageCode,
          value: content,
        });
      }

      UpdateLegality({
        variables: {
          input: {
            token,
            id: dataLegality?.getMenuLegalityPage?.page?.id,
            menu: localMenu?.id || "",
            identifier:
              dataLegality?.getMenuLegalityPage?.page?.identifier || "",
            title: arrayTitles,
            content: arrayContent,
            isPublished:
              dataLegality.getMenuLegalityPage.page.isPublished || false,
          },
        },
      });
    } else {
      AddLegality({
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
            identifier: identifier || "",
            title: [
              {
                languageCode: languageIsMain?.code as LanguageCode,
                value: String(title),
              },
            ],
            content: [
              {
                languageCode: languageIsMain?.code as LanguageCode,
                value: content,
              },
            ],
          },
        },
      });
    }
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        width={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <BreadCrumbsTitle
          labelIntl={["Store settings", `Legality - ${title}`]}
          icon={StoreSettings}
          iconAlt="Payment"
        />
        <ButtonCustomize
          variant="contained"
          color="secondary"
          onClick={handleSave}
          disabled={loading || loadingUpdate}
        >
          save
        </ButtonCustomize>
      </Box>
      <ButtonCustomize className="back" onClick={() => history.goBack()}>
        <KeyboardBackspaceIcon /> Return
      </ButtonCustomize>
      <Box component={Paper} marginTop={2} p={2}>
        <Typography variant="h2" style={{ fontWeight: 700, marginBottom: 5 }}>
          Page content
        </Typography>
        <Typography variant="subtitle1" className="description">
          This page is a legal contract between your business and the users of
          your site and services. It clarifies the conditions of use and enables
          you to exclude users who abuse the rules you’ve set, maintain your
          legal rights against potential abusers, and more.
        </Typography>
        <Typography variant="subtitle1" className="description">
          You need a clearly stated contract to protect your website, company,
          and customers. Review your legal pages carefully before adding them.
          When drafting this page for your site, consider including information
          related to the following areas :
        </Typography>
        <Typography variant="subtitle1" className="description">
          - Ownership of the site
        </Typography>
        <Typography variant="subtitle1">
          - Copyright for the material offered on the website and store pages
        </Typography>
        <Typography variant="subtitle1">
          - Liabilities and responsibilities of your business
        </Typography>
        <Typography variant="subtitle1">
          - Requirements for using the store
        </Typography>
        <Typography variant="subtitle1">
          - Notification upon modification of terms
        </Typography>
        <Typography variant="subtitle1">
          - Termination of contract - Contact information
        </Typography>
        <br />
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={handleChange}
          placeholder="Please insert page content ... "
        />
      </Box>
    </Wrapper>
  );
};

export default LegalityForm;
