import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import generatePassword from "generate-password";
import { Grid, Box, Paper, Typography } from "@material-ui/core";

import { WrapperStyled } from "./CustomersFormStyle";
import {
  Input,
  BreadCrumbsTitle,
  InputPhone,
  ButtonCustomize,
} from "../../../commons";
import { customerFormValidation } from "./CutomersForm--utils";

import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from "../../../api";
import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from "../../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../../api/local-state";

import { Customers } from "../../../img";
import cercles from "../../../img/cercles.png";

export type Errors = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
};

const AddClient: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const path = location.pathname;
  const catchState: any = location.state;

  const [email, setEmail] = useState<string>(catchState?.customerEmail || "");
  const [firstName, setFirstName] = useState<string>(
    catchState?.customerFirstName || ""
  );
  const [lastName, setLastName] = useState<string>(
    catchState?.customerLastName || ""
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    catchState?.customerPhoneNumber || ""
  );
  const [countryCode, setCountryCode] = useState<string>(
    catchState?.customerPhoneCode || "216"
  );

  const [errors, setErrors] = useState<Errors>({});

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const [createCustomer] = useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CREATE_CUSTOMER, {
    onError: (createError) => {
      if (createError.message === "email_already_exists") {
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({
            id: "store.customerForm.error.email",
          }),
        });
      }
    },
    onCompleted: () => {
      history.push("/customers");
    },
  });

  const [updateCustomer] = useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UPDATE_CUSTOMER, {
    onError: (updateError) => {
      if (updateError.message === "email_already_exists") {
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({
            id: "store.customerForm.error.email",
          }),
        });
      }
    },
    onCompleted: () => {
      history.push("/customers");
    },
  });

  const handleTitleCustomerForm = (pathValue: string): string => {
    if (pathValue === "/create-customer") return "Create Customer";
    return "Update Customer";
  };

  const handleCreation = () => {
    const generatedPassword = generatePassword.generate({
      length: 8,
      numbers: true,
    });
    const validation = customerFormValidation(email, phoneNumber);
    if (Object.keys(validation).length) {
      setErrors(validation);
    } else {
      createCustomer({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            menu: localMenu?.menuItem?.id || "",
            token: localStorage.getItem("token") || "",
            password: generatedPassword,
            phone: {
              countryCode: parseInt(countryCode, 10),
              number: phoneNumber,
            },
          },
        },
      });
    }
  };

  const handleUpdate = () => {
    const validation = customerFormValidation(email, phoneNumber);
    if (Object.keys(validation).length) {
      setErrors(validation);
    } else {
      updateCustomer({
        variables: {
          input: {
            id: catchState.customerID || "",
            menu: localMenu?.menuItem?.id || "",
            token: localStorage.getItem("token") || "",
            email,
            firstName,
            lastName,
            phone: {
              countryCode: parseInt(countryCode, 10),
              number: phoneNumber,
            },
          },
        },
      });
    }
  };
  const renderEmailMessageError = () => {
    if (errors.email) {
      return intl.formatMessage({
        id: errors.email,
      });
    }
    return undefined;
  };

  return (
    <WrapperStyled>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <BreadCrumbsTitle
          labelIntl={["store.customer.title", handleTitleCustomerForm(path)]}
          iconAlt="Customers"
          ButtonColor="primary"
        />
        <ButtonCustomize
          variant="contained"
          color="primary"
          size="large"
          onClick={
            catchState?.isUpdate === true ? handleUpdate : handleCreation
          }
        >
          {intl.formatMessage({
            id: "store.customerForm.save",
          })}
        </ButtonCustomize>
      </Box>
      <Box
        component={Paper}
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={1}
        className="container"
        p={3}
      >
        <div className="vector">
          <img src={cercles} alt="vector" className="img-vector" />
          <img src={Customers} alt="apparence" className="img-apparence" />
        </div>
        <Typography variant="h2" className="customer__form-title">
          {location.pathname === "/modify-customer"
            ? intl.formatMessage({
                id: "store.customerForm.update.title",
              })
            : intl.formatMessage({
                id: "store.customerForm.formAddclient",
              })}
        </Typography>
        <Grid component="form" container spacing={2} className="customer__form">
          <Grid item xs={6}>
            <Input
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
              }}
              label={intl.formatMessage({
                id: "store.customerForm.inputFirstName",
              })}
              error={Boolean(errors.firstName)}
              msgError={
                errors.firstName &&
                intl.formatMessage({
                  id: errors.firstName,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, lastName: "" }));
              }}
              label={intl.formatMessage({
                id: "store.customerForm.inputLastName",
              })}
              className="lastName"
              error={Boolean(errors.lastName)}
              msgError={
                errors.lastName &&
                intl.formatMessage({
                  id: errors.lastName,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
              }}
              label={intl.formatMessage({
                id: "store.customerForm.inputemail",
              })}
              type="email"
              className="email"
              error={Boolean(errors.email)}
              msgError={renderEmailMessageError()}
            />
          </Grid>
          <Grid item xs={6}>
            <InputPhone
              label={intl.formatMessage({
                id: "store.customerForm.addAddress.Phone",
              })}
              value={phoneNumber}
              onChange={(event) => {
                setPhoneNumber(event.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
              }}
              error={Boolean(errors.phoneNumber)}
              msgError={
                errors.phoneNumber &&
                intl.formatMessage({
                  id: errors.phoneNumber,
                })
              }
              countryInputProps={{
                value: countryCode,
                onChange: (value) => setCountryCode(value),
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </WrapperStyled>
  );
};
export default AddClient;
