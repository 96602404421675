import React from "react";
import { MenuItem } from "@material-ui/core";

import { Wrapper, Seperator } from "./AttributesProductStyle";
import { Box, Select } from "../../commons";
import { TabVarriation } from "..";

const AttributesProduct: React.FC = () => {
  return (
    <Wrapper>
      <Box display="flex" width={1} p={15}>
        <Select label="Select attribute Set">
          <MenuItem value="Attribute set 1">Attribute set 1</MenuItem>
        </Select>
      </Box>
      <Seperator />
      <TabVarriation />
    </Wrapper>
  );
};

export default AttributesProduct;
