import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Dialog,
  Button,
  MenuItem,
  Checkbox,
  Box,
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import findIndex from "lodash/findIndex";
import find from "lodash/find";

import { Input, Select } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { UploadPictureProduct } from "..";

import {
  GETLISTPRODUCTOPTIONS,
  ADDPRODUCTVARIANT,
  UPDATEPRODUCTVARIANT,
  GETLISTPRODUCTVARIANTS,
} from "../../api";
import {
  GetListProductOptionsQuery,
  GetListProductOptionsQueryVariables,
  AddProductVariantMutation,
  AddProductVariantMutationVariables,
  EditProductVariantMutation,
  EditProductVariantMutationVariables,
  CreateNameInput,
  UpdateNameInput,
  CopiedPictureInput,
  VariantOptionInput,
  ProductVariantPayload,
  EditProductVariantInput,
  LanguageCode,
  MenuPayload,
} from "../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../api/local-state";

import { Wrapper, ColorInput } from "./VariantFormModalStyle";

type VariantModalProps = {
  dataVariant?: ProductVariantPayload;
  open: boolean;
  title: string;
  handleClose: () => void;
};

const VariantFormModal: React.FC<VariantModalProps> = ({
  dataVariant,
  open,
  title,
  handleClose,
}) => {
  const token = localStorage.getItem("token") || "";
  const { id: productId } = useParams<{
    id?: string;
  }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [names, setNames] = useState<UpdateNameInput[]>([]);
  const [errorName, setErrorName] = useState<string>("");
  const [picture, setPicture] = useState<string | null>(null);
  const [copiedPictures, setCopiedPictures] = useState<CopiedPictureInput[]>(
    []
  );
  const [variants, setVariants] = useState<VariantOptionInput[]>([]);
  const [weight, setWeight] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [sign, setSign] = useState<string>("+");
  const [priceDifference, setPriceDifference] = useState<string>("");
  const [discountPercentage, setDiscountPercentage] = useState<string>("");
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const { data: listOption } = useQuery<
    GetListProductOptionsQuery,
    GetListProductOptionsQueryVariables
  >(GETLISTPRODUCTOPTIONS, {
    variables: {
      input: {
        token,
        product: productId || "",
      },
    },
    skip: !productId,
  });

  const [AddVariant] = useMutation<
    AddProductVariantMutation,
    AddProductVariantMutationVariables
  >(ADDPRODUCTVARIANT, {
    refetchQueries: () => [
      {
        query: GETLISTPRODUCTVARIANTS,
        variables: {
          input: {
            token,
            product: productId || "",
          },
        },
      },
    ],
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Variation has been added",
      });
      handleClose();
    },
    onError: (error) => {
      if (error.message === "product_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Product_not_found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const [UpdateVariant] = useMutation<
    EditProductVariantMutation,
    EditProductVariantMutationVariables
  >(UPDATEPRODUCTVARIANT, {
    refetchQueries: () => [
      {
        query: GETLISTPRODUCTVARIANTS,
        variables: {
          input: {
            token,
            product: productId || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
    },
    onError: (error) => {
      if (error.message === "product_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Product_not_found",
        });
      } else if (error.message === "variant_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Variant not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const handleOption = (option: string, value: string, index: number) => {
    setVariants((preVariants) => {
      const arrayVariant = [...preVariants];
      if (typeof arrayVariant[index] === "undefined") {
        arrayVariant.push({
          option,
          value,
        });
      } else {
        arrayVariant[index] = {
          option,
          value,
        };
      }
      return arrayVariant;
    });
  };

  const arrayNamesUpdate: UpdateNameInput[] = [];
  const arrayVariantsOption: VariantOptionInput[] = [];

  useEffect(() => {
    setPicture(dataVariant?.picture?.id || null);
    dataVariant?.names?.forEach((name) =>
      arrayNamesUpdate.push({
        id: name?.id,
        languageCode: name.languageCode as LanguageCode,
        value: name.value,
      })
    );

    listOption?.getListProductOptions?.list?.forEach((option) => {
      const position = findIndex(
        dataVariant?.options,
        (o) => o.option?.id === option.id
      );

      if (position !== -1) {
        arrayVariantsOption.push({
          option: dataVariant?.options?.[position]?.option?.id,
          value: dataVariant?.options?.[position]?.value?.id,
        });
      } else {
        arrayVariantsOption.push({
          option: null,
          value: null,
        });
      }
    });
    setNames(arrayNamesUpdate);
    setVariants(arrayVariantsOption);
    dataVariant?.shippingWeight &&
      setWeight(String(dataVariant?.shippingWeight));
    setBarcode(dataVariant?.barcode || "");
    setSku(dataVariant?.sku || "");
    dataVariant?.isPublished && setIsPublished(dataVariant?.isPublished);
    if (dataVariant?.priceDifference) {
      if (dataVariant?.priceDifference < 0) setSign("-");
      setPriceDifference(String(dataVariant?.priceDifference));
    }
    setDiscountPercentage(String(dataVariant?.discountPercentage));
  }, [dataVariant, listOption]);

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayNames = [...names];
    const position = findIndex(
      arrayNames,
      (o) => o.languageCode === languageIsMain?.code
    );

    if (position !== -1) {
      arrayNames[position] = {
        ...arrayNames[position],
        value: event.target.value,
      };
    } else {
      arrayNames.push({
        languageCode: languageIsMain?.code as LanguageCode,
        value: event.target.value,
      });
    }

    setNames(arrayNames);

    setErrorName("");
  };

  const handleChange = (option: string) => {
    setVariants((preVariants) => {
      const arrayVariant = [...preVariants];
      const index = findIndex(arrayVariant, (o) => o.option === option);
      if (index !== -1) {
        arrayVariant[index] = {
          option: null,
          value: null,
        };
      }
      return arrayVariant;
    });
  };

  const columns: ColumnsProps = [
    {
      header: "",
      accessor: "",
      cellProps: { width: "50px" },
    },
    {
      header: "Name",
      accessor: "name",
      cellProps: { width: "250px" },
    },
    {
      header: "Values",
      accessor: "values",
    },
  ];

  const renderTableRows = () => {
    return (
      listOption?.getListProductOptions?.list?.map((option, index) => ({
        id: option?.id,
        "": (
          <Checkbox
            checked={!!variants?.[index]?.option}
            onChange={() => handleChange(option.id || "")}
            color="secondary"
          />
        ),
        name: find(option.names, (o) => o.languageCode === languageIsMain?.code)
          ?.value,
        values: (
          <Select
            value={variants?.[index]?.value || ""}
            onChange={(e) =>
              handleOption(option.id!, e.target.value as string, index)
            }
          >
            {option.values?.map((value) => (
              <MenuItem key={value.id || ""} value={value?.id || ""}>
                {find(
                  value?.names,
                  (o) => o.languageCode === languageIsMain?.code
                )?.value?.indexOf("#") !== -1 ? (
                  <ColorInput
                    color={
                      find(
                        value?.names,
                        (o) => o.languageCode === languageIsMain?.code
                      )?.value || ""
                    }
                  />
                ) : (
                  find(
                    value?.names,
                    (o) => o.languageCode === languageIsMain?.code
                  )?.value
                )}
              </MenuItem>
            ))}
          </Select>
        ),
      })) || []
    );
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
  };

  const handleBarcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value);
  };

  const handleSku = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSku(event.target.value);
  };

  const handlePriceDifference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPriceDifference(event.target.value);
  };

  const handleDiscountPercentage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDiscountPercentage(event.target.value);
  };

  useEffect(() => {
    if (priceDifference) {
      if (sign === "-" && priceDifference.indexOf("-") === -1)
        setPriceDifference("-".concat(priceDifference));
      else if (priceDifference.indexOf("+") === -1) {
        setPriceDifference(priceDifference.substring(1));
      }
    }
  }, [sign]);

  const handleSubmit = () => {
    const arrayVariants: VariantOptionInput[] = [];

    variants.forEach((variant) => {
      if (variant.option) arrayVariants.push(variant);
    });

    if (find(names, (o) => o.languageCode === languageIsMain?.code)?.value) {
      if (dataVariant?.id) {
        const input: EditProductVariantInput = {
          token,
          id: dataVariant?.id,
          product: productId || "",
          names,
          options: arrayVariants,
          picture,
          copiedPictures,
          shippingWeight: parseFloat(weight),
          barcode,
          sku,
          priceDifference: parseFloat(priceDifference),
          discountPercentage: +discountPercentage,
          isPublished,
        };
        // check if picture has changed
        if (copiedPictures.length) {
          input.picture = picture;
          input.copiedPictures = copiedPictures;
        }
        UpdateVariant({
          variables: {
            input,
          },
        });
      } else {
        const arrayNames: CreateNameInput[] = [];
        names.forEach((name) =>
          arrayNames.push({
            languageCode: name.languageCode,
            value: name.value,
          })
        );
        AddVariant({
          variables: {
            input: {
              token,
              product: productId || "",
              names: arrayNames,
              picture,
              copiedPictures,
              options: arrayVariants,
              shippingWeight: parseFloat(weight),
              barcode,
              sku,
              priceDifference: parseFloat(priceDifference),
              discountPercentage: +discountPercentage,
              isPublished,
            },
          },
        });
      }
    } else {
      setErrorName("this field is required");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <div className="header-modal">
          <h3>{title}</h3>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            save
          </Button>
        </div>
        <div className="container">
          <Box display="flex">
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
              <Grid item xs={6}>
                <Input
                  label="Variation Name"
                  value={
                    find(names, (o) => o.languageCode === languageIsMain?.code)
                      ?.value || ""
                  }
                  onChange={handleName}
                  msgError={errorName}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Shipping weight (kg)"
                  value={weight}
                  onKeyPress={onKeyPress}
                  onChange={handleWeight}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Barcode"
                  value={barcode}
                  onChange={handleBarcode}
                />
              </Grid>
              <Grid item xs={6}>
                <Input label="SKU" value={sku} onChange={handleSku} />
              </Grid>
              <Grid item xs={1}>
                <Select
                  value={sign}
                  onChange={(e) => setSign(e.target.value as string)}
                >
                  <MenuItem value="+"> + </MenuItem>
                  <MenuItem value="-"> - </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Input
                  label="Price difference"
                  value={priceDifference}
                  onKeyPress={onKeyPress}
                  onChange={handlePriceDifference}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Discount (%)"
                  type="number"
                  value={discountPercentage}
                  onChange={handleDiscountPercentage}
                />
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" width="200px">
              <UploadPictureProduct
                description="Upload image"
                uploadTo="Product"
                mode="Base"
                onLoaded={(idPicture, copiedPicturesIds) => {
                  setPicture(idPicture);
                  setCopiedPictures(copiedPicturesIds);
                }}
                urlImage={{
                  id: picture,
                  url: dataVariant?.picture?.fileUrl,
                }}
                style={{ margin: "25px 0 10px 40px" }}
                onDeleted={() => {
                  setPicture(null);
                  setCopiedPictures([]);
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublished}
                    onChange={(e) => {
                      setIsPublished(e.target.checked);
                    }}
                    color="secondary"
                  />
                }
                label="Show Product in Store"
              />
            </Box>
          </Box>
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="You have no option created. To create option go to option tag
            and click on add option buton"
          />
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default VariantFormModal;
