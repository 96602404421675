/*  @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  DialogContent,
  Checkbox,
  ListItemText,
  MenuItem,
  Fab,
} from "@material-ui/core";

import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { Wrapper, Tab, RegisterDialog, Actions } from "./styles";
import { ReactComponent as PersonalInfo } from "../../img/personal-info.svg";
import { ReactComponent as StoreInfo } from "../../img/store-info.svg";
import { Input, Select } from "../../commons";
import {
  // GetListCountriesQuery,
  // GetListCountriesQueryVariables,
  GetListCompanyActivitiesQuery,
  GetListCompanyActivitiesQueryVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  AccountSettingsInput,
  LanguageCode,
  CurrencyCode,
  // GetListCurrenciesQuery,
  // GetListCurrenciesQueryVariables,
  UpdateCompanyInput,
  GetListSocialKindQuery,
  GetListSocialKindQueryVariables,
  CompanySocialInput,
  // GetListCitiesQuery,
  // GetListCitiesQueryVariables,
  // CompanyActivityInput,
  // GetCompanyQuery,
  // GetCompanyQueryVariables,
} from "../../api/types";
import {
  // GETLISTCOUNTRIES,
  GETLISTCOMPANYACTIVITIES,
  UPDATE_ACCOUNT,
  GET_LIST_SOCIALS,
  // GETLISTCITIES,
  // GET_COMPANY,
} from "../../api";
import { GET_STEP } from "../../api/local-state";
import { personalInfoValidation } from "./validation";

import {
  Facebook,
  YouTube,
  WhatsApp,
  Instagram,
  Reddit,
  Twitter,
  Snapchat,
  LinkedIn,
  Pinterest,
  Tumblr,
  Google,
  Periscope,
} from "../../img/social";

type RegisterMerchantType = {
  open: boolean;
  handleClose: () => void;
};

type SocialType = { [key: string]: string };

export type PersonalInfoType = {
  firstname: string;
  lastname: string;
  phone: { countryCode: number; number: string };
};

const personalInfoInitialState: PersonalInfoType = {
  firstname: "",
  lastname: "",
  phone: { countryCode: 216, number: "" },
};

const RegisterMerchant: FC<RegisterMerchantType> = ({ open, handleClose }) => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";
  const [openModal, setOpenModal] = React.useState<boolean>(open);

  const { data } = useQuery(GET_STEP);
  useEffect(() => {
    setTab(data?.step - 1);
  }, [data]);

  const email = data?.email || undefined;

  // states
  const [tab, setTab] = React.useState<number>(1);
  const [personalInfo, setPersonalInfo] = React.useState(
    personalInfoInitialState
  );
  const [personalInfoError, setPersonalInfoError] = React.useState({
    firstname: "",
    lastname: "",
    countryCode: "",
    number: "",
  });

  const [accountSettings, setAccontSettings] =
    React.useState<AccountSettingsInput>({ language: "EN", currency: "EUR" });
  const [link, setLink] = React.useState<CompanySocialInput>();
  const [company, setCompany] = React.useState<UpdateCompanyInput>({
    activity: "",
    socials: [],
    website: "",
    fiscal: {},
    address: {
      city: "",
      country: "",
      postalCode: undefined,
      roadNames: [{ value: "" }],
    },
    contacts: [],
    shortNames: [{ value: "" }],
  });

  // social icons
  const socialList: SocialType = {
    Facebook,
    YouTube,
    WhatsApp,
    Instagram,
    Reddit,
    Twitter,
    Snapchat,
    LinkedIn,
    Pinterest,
    Tumblr,
    Google,
    Periscope,
  };

  // queries
  // const { data: companyInfo } = useQuery<
  //   GetCompanyQuery,
  //   GetCompanyQueryVariables
  // >(GET_COMPANY, {
  //   variables: { input: { token, languageCode: "EN" } },
  //   onCompleted: (companyInfo) => {
  //     setCompany({
  //       ...company,
  //       id: companyInfo.getMyAccount.account.company?.id,
  //       // domain: companyInfo?.getMyAccount?.account?.company?.domain,
  //     });
  //   },
  // });

  // Query GETLISTCOUNTRIES
  // const { data: listCountry } = useQuery<
  //   GetListCountriesQuery,
  //   GetListCountriesQueryVariables
  // >(GETLISTCOUNTRIES, { fetchPolicy: "network-only" });

  // Query GETLISTCITIES
  // const [getListCities, { data: listCities }] = useLazyQuery<
  //   GetListCitiesQuery,
  //   GetListCitiesQueryVariables
  // >(GETLISTCITIES, {
  //   variables: {
  //     countryId: company?.address?.country || "",
  //   },
  //   fetchPolicy: "network-only",
  // });

  // Query GETLISTCURRENCIES
  // const { data: listCurencies } = useQuery<
  //   GetListCurrenciesQuery,
  //   GetListCurrenciesQueryVariables
  // >(GETLISTCURRENCIES, { fetchPolicy: "network-only" });

  const { data: Activities } = useQuery<
    GetListCompanyActivitiesQuery,
    GetListCompanyActivitiesQueryVariables
  >(GETLISTCOMPANYACTIVITIES, { variables: { languageCode: "EN" } });

  const { data: SocialKind } = useQuery<
    GetListSocialKindQuery,
    GetListSocialKindQueryVariables
  >(GET_LIST_SOCIALS, { variables: { input: { languageCode: "EN" } } });

  const [updateAccount] = useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UPDATE_ACCOUNT, {
    onCompleted: () => {
      tab > 2 && setOpenModal(false);
    },
  });

  const getActivities = (list: string[]) => {
    const name: string[] = [];
    list.map((activity) => {
      const actName = Activities?.getListCompanyActivities.list.filter(
        (act) => act.id === activity
      );
      return (
        actName && actName.length > 0 && name.push(actName?.[0].name || "")
      );
    });
    return name;
  };

  const handlePersonalnInfo = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPersonalInfoError({ ...personalInfoError, [event.target.name]: "" });
  };

  const handleNext = () => {
    if (tab === 1) {
      const personalInfoFormValidation = personalInfoValidation(personalInfo);
      if (Object.entries(personalInfoFormValidation).length === 0) {
        updateAccount({
          variables: {
            input: {
              token,
              email,
              informations: {
                firstName: personalInfo.firstname,
                lastName: personalInfo.lastname,
              },
              contact: { phone: personalInfo.phone },
            },
          },
        });
        setTab(tab + 1);
        // client.writeData({ data: { step: 3 } });
      } else {
        setPersonalInfoError({
          ...personalInfoError,
          ...personalInfoFormValidation,
        });
      }
    } else {
      updateAccount({
        variables: {
          input: {
            token,
            email,
            informations: {
              firstName: personalInfo.firstname,
              lastName: personalInfo.lastname,
            },
            company,
          },
        },
      });
    }
  };

  const handleLink = () => {
    const socialLinks: CompanySocialInput[] = company.socials || [];
    socialLinks && link && socialLinks.push(link);
    setCompany({ ...company, socials: socialLinks });
    setLink({ ...link, value: "" });
  };

  const removeSocial = (index: number) => {
    const socialLinks: CompanySocialInput[] = [];
    company.socials?.forEach((social, i) => {
      if (index !== i) {
        socialLinks.push(social);
      }
    });
    setCompany({ ...company, socials: socialLinks });
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = { countryCode: 1, number: "" };

    if (company?.contacts && company?.contacts.length > 0) {
      phone = {
        countryCode: company?.contacts?.[0].phone?.countryCode || 1,
        number: company?.contacts?.[0].phone?.number || "",
      };
      phone.number = e.target.value;
    } else {
      phone.number = e.target.value;
    }
    setCompany({
      ...company,
      contacts: [{ phone }],
    });
  };

  const handleCountryCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = { countryCode: 1, number: "" };
    if (company?.contacts && company?.contacts.length > 0) {
      phone = {
        countryCode: company?.contacts?.[0]?.phone?.countryCode || 1,
        number: company?.contacts?.[0]?.phone?.number || "",
      };
      phone.countryCode = parseInt(e.target.value, 10);
    } else {
      phone.countryCode = parseInt(e.target.value, 10);
    }
    setCompany({
      ...company,
      contacts: [{ phone }],
    });
  };

  const getChecked = (id: string) => {
    const listActivities: string[] = [];
    // company?.activities?.forEach((activity) => {
    //   listActivities.push(activity.activity || "");
    // });
    return listActivities.includes(id);
  };

  const handleActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
    // let listActivities: CompanyActivityInput[] = [];
    // listActivities = [];
    // const activities = e.target.value as string[];
    // if (getChecked(activities[activities.length - 1])) {
    //   activities.map((activity) => listActivities.push({ activity }));
    // } else {
    //   activities.map((activity) => listActivities.push({ activity }));
    // }
    setCompany({
      ...company,
      activity: e.target.value,
    });
  };

  const getListSelectedActivities = () => {
    const listSelectedActivities: string[] = [];
    // company?.activities?.forEach((activity) => {
    //   listSelectedActivities.push(activity.activity || "");
    // });
    return listSelectedActivities;
  };

  return (
    <Wrapper>
      <RegisterDialog
        open={open && openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ width: "700px" }}>
          <Tab>
            <div className="tab-header">
              <div
                role="button"
                tabIndex={0}
                className="tab-icon"
                onClick={() => setTab(1)}
                onKeyUp={() => setTab(1)}
              >
                <PersonalInfo fill={tab >= 1 ? "#444444" : "#BDBDBD"} />
                <label>
                  {intl.formatMessage({ id: "merchant.personalInfo" })}
                </label>
              </div>
              <hr className={tab === 2 ? "seperator fill" : "seperator"} />
              <div
                role="button"
                tabIndex={0}
                className="tab-icon"
                onClick={() => setTab(2)}
                onKeyUp={() => setTab(2)}
              >
                <StoreInfo fill={tab >= 2 ? "#444444" : "#BDBDBD"} />
                <label>
                  {intl.formatMessage({ id: "merchant.storeInformation" })}
                </label>
              </div>
            </div>
            <div className="tab-content">
              {tab === 1 ? (
                <div className="sub-tab-2">
                  <Input
                    id="firstname"
                    name="firstname"
                    label={intl.formatMessage({
                      id: "merchant.firstname",
                    })}
                    value={personalInfo.firstname}
                    onChange={(e) => {
                      setPersonalInfo({
                        ...personalInfo,
                        firstname: e.target.value,
                      });
                      handlePersonalnInfo(e);
                    }}
                    dataTestId="firstname"
                    msgError={
                      personalInfoError.firstname &&
                      intl.formatMessage({ id: personalInfoError.firstname })
                    }
                  />
                  <Input
                    id="lastname"
                    name="lastname"
                    label={intl.formatMessage({
                      id: "merchant.lastname",
                    })}
                    value={personalInfo.lastname}
                    onChange={(e) => {
                      setPersonalInfo({
                        ...personalInfo,
                        lastname: e.target.value,
                      });
                      handlePersonalnInfo(e);
                    }}
                    dataTestId="lastname"
                    msgError={
                      personalInfoError.lastname &&
                      intl.formatMessage({ id: personalInfoError.lastname })
                    }
                  />
                  <div className="phone-input">
                    <Input
                      id="countryCode"
                      name="countryCode"
                      label={intl.formatMessage({
                        id: "merchant.countryCode",
                      })}
                      value={personalInfo.phone.countryCode}
                      onChange={(e) => {
                        setPersonalInfo({
                          ...personalInfo,
                          phone: {
                            ...personalInfo.phone,
                            countryCode: parseInt(e.target.value, 10),
                          },
                        });
                        handlePersonalnInfo(e);
                      }}
                      dataTestId="countryCode"
                      msgError={
                        personalInfoError.countryCode &&
                        intl.formatMessage({
                          id: personalInfoError.countryCode,
                        })
                      }
                    />
                    <Input
                      id="number"
                      name="number"
                      label={intl.formatMessage({
                        id: "merchant.phoneNumber",
                      })}
                      value={personalInfo.phone.number}
                      onChange={(e) => {
                        setPersonalInfo({
                          ...personalInfo,
                          phone: {
                            ...personalInfo.phone,
                            number: e.target.value,
                          },
                        });
                        handlePersonalnInfo(e);
                      }}
                      dataTestId="number"
                      msgError={
                        personalInfoError.number &&
                        intl.formatMessage({ id: personalInfoError.number })
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="sub-tab-3">
                  <label htmlFor="select-activity" className="title">
                    Domain d&apos;activité
                  </label>
                  <div className="sp2">
                    {/* <Select
                      id="select-activity"
                      multiple
                      label={intl.formatMessage({
                        id: "merchant.activity",
                      })}
                      value={getListSelectedActivities()}
                      onChange={handleActivity}
                      renderValue={(selected) =>
                        (getActivities(selected as string[]) as string[]).join(
                          ", "
                        )
                      }
                      fullWidth
                      style={{ maxWidth: "630px", gridColumn: "span 2" }}
                      required
                      // msgError={errorForm.activities}
                    >
                      {Activities?.getListCompanyActivities.list.map(
                        (activity) => (
                          <MenuItem
                            key={activity.id!}
                            value={activity.id || ""}
                          >
                            <Checkbox
                              checked={getChecked(activity.id || "")}
                              color="primary"
                            />
                            <ListItemText primary={activity.name} />
                          </MenuItem>
                        )
                      )}
                    </Select> */}
                    <Input
                      id="select-activity"
                      name="select activity"
                      label={intl.formatMessage({
                        id: "merchant.activity",
                      })}
                      value={company.activity || ""}
                      onChange={handleActivity}
                    />
                  </div>
                  <label className="title">
                    {intl.formatMessage({
                      id: "merchant.taxInfo",
                    })}
                  </label>
                  <Input
                    id="corporateName"
                    name="corporateName"
                    label={intl.formatMessage({
                      id: "merchant.corporateName",
                    })}
                    value={company.shortNames?.[0]?.value || ""}
                    onChange={(e) =>
                      setCompany({
                        ...company,
                        shortNames: [
                          { languageCode: "EN", value: e.target.value },
                        ],
                      })
                    }
                    dataTestId="corporateName"
                  />
                  <Input
                    id="taxRegistration"
                    name="taxRegistration"
                    label={intl.formatMessage({
                      id: "merchant.taxRegistration",
                    })}
                    value={company.fiscal?.taxCode}
                    onChange={(e) =>
                      setCompany({
                        ...company,
                        fiscal: { ...company.fiscal, taxCode: e.target.value },
                      })
                    }
                    dataTestId="taxRegistration"
                  />
                  <Input
                    id="vat"
                    name="vat"
                    label={intl.formatMessage({
                      id: "merchant.tavCode",
                    })}
                    value={company.fiscal?.matriculation}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        fiscal: {
                          ...company.fiscal,
                          matriculation: e.target.value,
                        },
                      });
                    }}
                    dataTestId="vat"
                  />

                  <label className="title">
                    {intl.formatMessage({
                      id: "merchant.companyAddress",
                    })}
                  </label>
                  {/* <Autocomplete
                    id="country"
                    options={listCountry && listCountry.getListCountries.list}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      value &&
                        setCompany({
                          ...company,
                          address: { ...company.address, country: value.id },
                        });
                      getListCities();
                    }}
                    defaultValue={company.address?.country}
                    renderInput={(params) => (
                      <TextField
                        name="country"
                        {...params}
                        label={intl.formatMessage({
                          id: "Account.country",
                        })}
                        variant="filled"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    )}
                  /> */}
                  {/* <Autocomplete
                    id="city"
                    options={listCities && listCities.getListCities.list}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      value &&
                        setCompany({
                          ...company,
                          address: { ...company.address, city: value.id },
                        });
                    }}
                    defaultValue={company.address?.city}
                    renderInput={(params) => (
                      <TextField
                        name="country"
                        {...params}
                        label={intl.formatMessage({
                          id: "Account.city",
                        })}
                        variant="filled"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    )}
                  /> */}
                  <i />
                  <div className="sp2">
                    <Input
                      id="address"
                      name="address"
                      label={intl.formatMessage({
                        id: "merchant.address",
                      })}
                      value={company.address?.roadNames?.[0]?.value || ""}
                      onChange={(e) =>
                        setCompany({
                          ...company,
                          address: {
                            ...company.address,
                            roadNames: [
                              { languageCode: "EN", value: e.target.value },
                            ],
                          },
                        })
                      }
                      dataTestId="address"
                    />
                  </div>
                  <Input
                    id="postalCode"
                    name="postalCode"
                    label={intl.formatMessage({
                      id: "merchant.postCode",
                    })}
                    type="tel"
                    value={company.address?.postalCode}
                    onChange={(e) => {
                      if (!Number.isNaN(parseInt(e.target.value, 10))) {
                        setCompany({
                          ...company,
                          address: {
                            ...company.address,
                            postalCode: parseInt(e.target.value, 10),
                          },
                        });
                      }
                    }}
                    dataTestId="postalCode"
                  />

                  <label htmlFor="countryCode" className="title">
                    Phone Number
                  </label>
                  <Input
                    id="countryCode"
                    name="countryCode"
                    label={intl.formatMessage({
                      id: "merchant.countryCode",
                    })}
                    value={company.contacts?.[0]?.phone?.countryCode}
                    onChange={handleCountryCode}
                    dataTestId="countryCode"
                  />
                  <div className="sp2">
                    <Input
                      id="phone"
                      name="phone"
                      label={intl.formatMessage({
                        id: "merchant.phoneNumber",
                      })}
                      value={company.contacts?.[0]?.phone?.number}
                      onChange={handlePhone}
                      dataTestId="phone"
                    />
                  </div>
                  <label htmlFor="select-language" className="title">
                    Default settings
                  </label>
                  <Select
                    id="select-language"
                    label={intl.formatMessage({
                      id: "merchant.language",
                    })}
                    value={accountSettings.language}
                    onChange={(e) =>
                      setAccontSettings({
                        ...accountSettings,
                        language: e.target.value as LanguageCode,
                      })
                    }
                    fullWidth
                  >
                    <MenuItem key={1} value="EN">
                      {intl.formatMessage({
                        id: "merchant.english",
                      })}
                    </MenuItem>
                    <MenuItem key={2} value="FR">
                      {intl.formatMessage({
                        id: "merchant.french",
                      })}
                    </MenuItem>
                  </Select>
                  <Select
                    label={intl.formatMessage({
                      id: "merchant.currency",
                    })}
                    value={accountSettings.currency}
                    onChange={(e) =>
                      setAccontSettings({
                        ...accountSettings,
                        currency: e.target.value as CurrencyCode,
                      })
                    }
                    fullWidth
                  >
                    {/* {listCurencies?.getListCurrencies.list.map(
                      (currency, index) => {
                        return (
                          <MenuItem key={index} value={currency.code || ""}>
                            {currency.code}
                          </MenuItem>
                        );
                      }
                    )} */}
                  </Select>
                  <label className="title">
                    {intl.formatMessage({
                      id: "merchant.website",
                    })}
                  </label>
                  <div className="sp3">
                    <Input
                      id="website"
                      name="website"
                      label={intl.formatMessage({
                        id: "merchant.website",
                      })}
                      value={company.website}
                      onChange={(e) =>
                        setCompany({ ...company, website: e.target.value })
                      }
                      dataTestId="website"
                    />
                  </div>
                  <label className="title">
                    {intl.formatMessage({
                      id: "merchant.social",
                    })}
                  </label>
                  <Select
                    label={intl.formatMessage({
                      id: "merchant.social",
                    })}
                    value={link?.kind}
                    onChange={(e) =>
                      setLink({
                        ...link,
                        kind: e.target.value as string,
                      })
                    }
                    fullWidth
                  >
                    {SocialKind?.getListSocialKinds.list.map((social) => {
                      return (
                        <MenuItem key={social.id} value={social.id || ""}>
                          {social.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <div className="sp2 link">
                    <Input
                      id="link"
                      name="link"
                      label={intl.formatMessage({
                        id: "merchant.link",
                      })}
                      value={link?.value}
                      onChange={(e) =>
                        setLink({ ...link, value: e.target.value })
                      }
                      dataTestId="link"
                    />
                    <Fab color="primary" aria-label="add" onClick={handleLink}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        color="#ffffff"
                      />
                    </Fab>
                  </div>
                </div>
              )}
              {company.socials?.map((socialLink, index) => {
                return (
                  <div className="socialLink" key={socialLink.value}>
                    <img
                      src={
                        socialList[
                          SocialKind?.getListSocialKinds.list
                            .filter(
                              (linkKind) => linkKind.id === socialLink?.kind
                            )?.[0]
                            ?.name?.split("+")?.[0] || "Facebook"
                        ]
                      }
                      alt="social network"
                    />
                    <p>{socialLink?.value}</p>
                    <button
                      type="button"
                      className="remove-social"
                      onClick={() => removeSocial(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        size="lg"
                        color="#c5c8d2"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </Tab>
        </DialogContent>
        <Actions className="actions">
          <Button onClick={handleClose} color="primary">
            {intl.formatMessage({
              id: "merchant.skip",
            })}
          </Button>
          <Button
            onClick={handleNext}
            color="primary"
            autoFocus
            variant="contained"
          >
            {tab === 1
              ? intl.formatMessage({
                  id: "merchant.next",
                })
              : intl.formatMessage({
                  id: "merchant.finish",
                })}
          </Button>
        </Actions>
      </RegisterDialog>
    </Wrapper>
  );
};

export default RegisterMerchant;
