import * as React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useQuery, useReactiveVar } from "@apollo/client";
import EditIcon from "@material-ui/icons/Edit";
import find from "lodash/find";

import { ButtonCustomize } from "../../commons";

import { GET_MENU_LEGALITY_SETTINGS } from "../../api";

import {
  GetMenuLegalitySettingsQuery,
  GetMenuLegalitySettingsQueryVariables,
  MenuPayload,
  LanguageCode,
} from "../../api/types";

import { SelectedMenuVar } from "../../api/local-state";

type TranslateLegalityProps = {
  origineLangage: LanguageCode;
  onLegality: (identifier: string) => void;
};

const TranslateLegality = ({
  origineLangage,
  onLegality,
}: TranslateLegalityProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const { data } = useQuery<
    GetMenuLegalitySettingsQuery,
    GetMenuLegalitySettingsQueryVariables
  >(GET_MENU_LEGALITY_SETTINGS, {
    variables: {
      input: {
        token,
        id: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  return (
    <Wrapper>
      <div className="list-item">
        {data?.getMenuLegalitySettings?.pages?.map((item) => (
          <Row key={item?.id}>
            <Typography variant="subtitle1">
              {find(item?.title, (o) => o.languageCode === origineLangage)
                ?.value ? (
                find(item?.title, (o) => o.languageCode === origineLangage)
                  ?.value
              ) : (
                <Typography variant="subtitle2" className="empty">
                  This title is not translated in this language
                </Typography>
              )}
            </Typography>
            <ButtonCustomize
              onClick={() => {
                item.identifier && onLegality(item.identifier);
              }}
              disabled={
                !find(item?.title, (o) => o.languageCode === origineLangage)
                  ?.value
              }
            >
              <EditIcon fontSize="small" style={{ marginRight: 3 }} /> Edit
            </ButtonCustomize>
          </Row>
        ))}
      </div>
    </Wrapper>
  );
};

export default TranslateLegality;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .list-item {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    margin: 5px 0 10px 0;
    .MuiButton-root {
      color: #3fb4f6;
    }
    .MuiButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;

const Row = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #eeeffa;
  .empty {
    color: #a9a9a9;
  }
`;
