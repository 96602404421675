import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #eae8e8;
  border-radius: 10px;
  padding: 16px;
  margin-top: 10px;
  .img-logo-payment {
    width: 65px;
    object-fit: cover;
  }
  .title-payment {
    text-transform: lowercase;
    margin-top: 5px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;
