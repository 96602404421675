import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutoCompleteStyled = styled(Autocomplete)<{ error?: boolean }>`
  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  .MuiFilledInput-underline:after {
    border-bottom: none;
  }
  .MuiInputBase-root {
    background-color: #ffffff;
    border: 2px solid ${(props) => (props.error ? "#f67070" : "#f1f2ff")};
    border-radius: 5px;
    &:focus-within {
      border: 2px solid #000000;
      border-radius: 5px;
    }
  }
`;

export default AutoCompleteStyled;
