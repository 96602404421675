import * as React from "react";
import styled from "styled-components";

import { ReactComponent as SearchIcon } from "../img/search.svg";

type SearchBasicProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SearchBasic: React.FC<SearchBasicProps> = ({ value, onChange }) => {
  return (
    <Search>
      <input
        type="text"
        placeholder="Search"
        className="search-input"
        value={value || ""}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
      <button type="button" className="search-btn">
        <SearchIcon width="22" height="22" />
      </button>
    </Search>
  );
};

export default SearchBasic;

const Search = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 35px;
  grid-gap: 5px;
  float: right;
  padding: 3px;
  border: 1px solid #ebebeb;
  border-radius: 7px;
  margin-bottom: 15px;

  input.search-input {
    padding-left: 10px;
    border: 0;
  }
  button.search-btn {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    background: #f29f05;
    cursor: pointer;
    border: 0;
    border-radius: 5px;
    padding: 0;
    margin: 0;
    svg path {
      fill: white;
    }
  }
`;
