import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import AppsIcon from "@material-ui/icons/Apps";
import Tooltip from "@material-ui/core/Tooltip";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import indexOf from "lodash/indexOf";
import find from "lodash/find";

import { Wrapper, Note } from "./ListProductsStyle";
import {
  Input,
  Select,
  LoadingList,
  Modal,
  BoxNew as Box,
  ButtonCustomize,
  BreadCrumbsTitle,
  ProductGrid,
  TablePagination,
} from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { ListDataEmpty, ProductImportCSVModal } from "../../components";
import { Tabs } from "./Products--types";

import {
  GET_LIST_MENU_PRODUCTS,
  URL_THEME_PICTURES,
  DELETE_CATEGORY_PRODUCTS,
  EXPORT_PRODUCTS,
} from "../../api";
import {
  GetListMenuProductsQuery,
  GetListMenuProductsQueryVariables,
  DeleteCategoryProductMutation,
  DeleteCategoryProductMutationVariables,
  ProductSorting,
  ExportProductsMutation,
  ExportProductsMutationVariables,
  MenuPayload,
} from "../../api/types";
import {
  SelectedMenuVar,
  SelectedProduct,
  formProductRequiredVar,
} from "../../api/local-state";

import defaultDictureProduct from "../../img/digishop/default-picture-product.png";
import { products } from "../../img";
import { ImportIcon, ExportIcon } from "../../img/digishop/menu-icons";
import { nameCategory } from "../../utils";

const ListProducts: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const intl = useIntl();
  const [name, setName] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idProduct, setIdProduct] = useState<string | null | undefined>();
  const [sortBy, setSortBy] = useState<ProductSorting>("NAME_ASC");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalImportCSV, setOpenModalImportCSV] = useState<boolean>(false);
  const [selectProducts, setSelectProducts] = useState<string[]>([]);
  const [dataExist, setDataExist] = useState(false);
  const [searchByBarCode, setSearchByBarCode] = useState<string | undefined>(
    undefined
  );
  const [displayMode, setDisplayMode] = useState<"grid" | "list">("grid");

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [GetlistMenuProducts, { data: listMenuProducts, loading, refetch }] =
    useLazyQuery<GetListMenuProductsQuery, GetListMenuProductsQueryVariables>(
      GET_LIST_MENU_PRODUCTS,
      {
        onCompleted: (list) => {
          if (name === "") {
            if (list?.getListMenuProducts?.list?.length > 0) {
              setDataExist(true);
            }
          }
        },
      }
    );

  const lengthListProduct =
    listMenuProducts?.getListMenuProducts?.list?.length || 0;

  // mutation delete category product
  const [DeleteProduct, { loading: loadingDelete }] = useMutation<
    DeleteCategoryProductMutation,
    DeleteCategoryProductMutationVariables
  >(DELETE_CATEGORY_PRODUCTS, {
    refetchQueries: () => [
      {
        query: GET_LIST_MENU_PRODUCTS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      refetch && refetch();
      handleClose();
    },
  });

  const [exportProdcuts, { loading: loadingExportProducts }] = useMutation<
    ExportProductsMutation,
    ExportProductsMutationVariables
  >(EXPORT_PRODUCTS, {
    onCompleted: (data) => {
      const a = document.createElement("a");
      a.href = data?.exportProducts?.fileUrl;
      a.setAttribute("download", "filename.csv");
      a.click();
    },
  });

  useEffect(() => {
    GetlistMenuProducts({
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          name,
          sorting: sortBy,
        },
      },
    });
  }, [token, localMenu]);

  const handleOnEdit = (tab: Tabs, id?: string) => {
    setAnchorEl(null);
    history.push({
      pathname: `/managment-product/${id || idProduct}`,
      state: {
        tab,
      },
    });
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = (id: string) => {
    setIdProduct(id);
    setOpenModalDelete(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    formProductRequiredVar({
      identifier: "",
      name: "",
    });
  }, []);

  const columns: ColumnsProps = [
    {
      header: (
        <Checkbox
          checked={
            lengthListProduct === selectProducts.length && lengthListProduct > 0
          }
          inputProps={{ "aria-label": "primary checkbox" }}
          onClick={(e) => {
            e.stopPropagation();
            handleAllSelectProducts();
          }}
        />
      ),
      accessor: "checkbox",
      cellProps: { align: "center", width: "30px" },
    },
    {
      header: "Image",
      accessor: "image",
    },
    {
      header: intl.formatMessage({ id: "ProductForm.productName" }),
      accessor: "productName",
    },
    {
      header: intl.formatMessage({ id: "ProductForm.Category" }),
      accessor: "category",
    },
    {
      header: intl.formatMessage({ id: "ProductForm.numberOfOption" }),
      accessor: "optionsNumber",
      headerCellProps: { align: "center" },
      cellProps: { align: "center" },
    },
    {
      header: intl.formatMessage({
        id: "ProductForm.numberOfVariation",
      }),
      accessor: "vartionsNumber",
      headerCellProps: { align: "center" },
      cellProps: { align: "center" },
    },
    {
      header: intl.formatMessage({ id: "ProductForm.basePrice" }),
      accessor: "basePrice",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
    {
      header: intl.formatMessage({ id: "ProductForm.status" }),
      accessor: "status",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listMenuProducts?.getListMenuProducts?.list
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((product) => ({
          id: product?.id,
          checkbox: (
            <Checkbox
              checked={indexOf(selectProducts, product.id) !== -1}
              inputProps={{ "aria-label": "primary checkbox" }}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectProducts(product.id!);
              }}
            />
          ),
          image: product.picture?.id ? (
            <img
              className="product__image"
              src={`${URL_THEME_PICTURES}${product.picture?.fileUrl}`}
              alt="prodcut img"
            />
          ) : (
            <img
              className="product__image"
              src={defaultDictureProduct}
              alt="prodcut img"
            />
          ),
          productName: find(
            product.names,
            (o) => o.languageCode === languageIsMain?.code
          )?.value,
          optionsNumber: product.optionsCount,
          vartionsNumber: product.variantsCount,
          category:
            product.mainCategory &&
            product.currentCategory &&
            nameCategory(
              product.mainCategory,
              product.currentCategory,
              languageIsMain?.code
            ) === "ProductForm.notAssigned"
              ? intl.formatMessage({
                  id: `${nameCategory(
                    product.mainCategory!,
                    product.currentCategory!
                  )}`,
                })
              : nameCategory(
                  product.mainCategory!,
                  product.currentCategory!,
                  languageIsMain?.code
                ),
          basePrice: `${product.price?.value || ""} ${
            product.price?.currency?.code || ""
          }`,
          status: product?.mustCheckAvailaibitly && <AccessTimeIcon />,
          actions: (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIdProduct(product?.id);
                SelectedProduct(product);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          ),
        })) || []
    );
  };

  const getListProducts = (sorting?: ProductSorting) => {
    if (localMenu?.id) {
      GetlistMenuProducts({
        variables: {
          input: {
            token,
            menu: localMenu.id,
            name,
            sorting: sorting || sortBy,
          },
        },
      });
      if (sorting) {
        setSortBy(sorting);
      }
    }
  };

  const handleSelectProducts = (product: string) => {
    setSelectProducts((prevSelectProducst) => {
      const ArraySelectProdcuts = [...prevSelectProducst];
      const position = indexOf(ArraySelectProdcuts, product);
      if (position === -1) {
        ArraySelectProdcuts.push(product);
      } else {
        ArraySelectProdcuts.splice(position, 1);
      }
      return ArraySelectProdcuts;
    });
  };

  const handleAllSelectProducts = () => {
    const arrayAllSelectProducts: string[] = [];
    if (selectProducts.length < lengthListProduct)
      listMenuProducts?.getListMenuProducts?.list?.map((product) =>
        arrayAllSelectProducts.push(product.id!)
      );
    setSelectProducts(arrayAllSelectProducts);
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({ id: "ProductForm.ProductManagement" }),
            intl.formatMessage({ id: "ProductForm.ProductsList" }),
          ]}
          icon={products}
          iconAlt="products"
        />

        <Box>
          <ButtonCustomize
            color="primary"
            onClick={() => setOpenModalImportCSV(true)}
          >
            <ImportIcon /> &nbsp;
            <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
              Import
            </span>
          </ButtonCustomize>
          {lengthListProduct > 0 && (
            <ButtonCustomize
              variant="contained"
              color="primary"
              onClick={() => {
                history.push("/managment-product");
                SelectedProduct(undefined);
              }}
              style={{ marginLeft: 10 }}
            >
              {intl.formatMessage({ id: "ProductForm.newProduct" })}
            </ButtonCustomize>
          )}
        </Box>
      </Note>

      {dataExist && !loading && (
        <div className="container">
          <div className="search">
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") getListProducts();
              }}
              placeholder="Search for"
            />
            <ButtonCustomize
              variant="contained"
              color="secondary"
              onClick={() => getListProducts()}
            >
              {intl.formatMessage({ id: "ProductForm.search" })}
            </ButtonCustomize>
          </div>
          <div className="table-collection">
            <div className="table-option">
              <Box display="flex" width={1} justifyContent="space-between">
                <Box display="flex">
                  <Typography
                    variant="subtitle1"
                    style={{
                      textTransform: "capitalize",
                      margin: "auto 15px auto 0",
                    }}
                  >
                    {selectProducts.length} items selected
                  </Typography>
                  <ButtonCustomize
                    style={{ color: "#1F90CF" }}
                    onClick={() =>
                      exportProdcuts({
                        variables: {
                          input: {
                            token,
                            menu: localMenu?.id || "",
                            products: selectProducts,
                          },
                        },
                      })
                    }
                    disabled={loadingExportProducts}
                  >
                    <ExportIcon style={{ marginRight: 7 }} />
                    {selectProducts.length === 0
                      ? "export all item"
                      : "export selection"}
                  </ButtonCustomize>
                </Box>
                <Box display="flex">
                  <span style={{ marginRight: 14 }}>Search by barcode </span>
                  <Input
                    value={searchByBarCode}
                    onChange={(e) =>
                      setSearchByBarCode(e.target.value || undefined)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        GetlistMenuProducts({
                          variables: {
                            input: {
                              token,
                              menu: localMenu?.id || "",
                              name,
                              sorting: sortBy,
                              barcode: searchByBarCode,
                            },
                          },
                        });
                      }
                    }}
                    style={{ width: 200, marginRight: 14 }}
                  />
                  <span style={{ marginRight: 14 }}>
                    {intl.formatMessage({ id: "ProductForm.sortBy" })}
                  </span>
                  <div className="sort">
                    <Select
                      placeholder="Sort by"
                      value={sortBy}
                      onChange={(e) => {
                        const value = e.target.value as ProductSorting;
                        getListProducts(value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="NAME_ASC">
                        <FontAwesomeIcon icon={faSortAmountUpAlt} />{" "}
                        &nbsp;&nbsp;
                        {intl.formatMessage({ id: "ProductForm.name" })}
                      </MenuItem>
                      <MenuItem value="NAME_DESC">
                        <FontAwesomeIcon icon={faSortAmountDownAlt} />
                        &nbsp;&nbsp;
                        {intl.formatMessage({ id: "ProductForm.name" })}
                      </MenuItem>
                      <MenuItem value="PRICE_ASC">
                        <FontAwesomeIcon icon={faSortAmountUpAlt} />{" "}
                        &nbsp;&nbsp;
                        {intl.formatMessage({ id: "ProductForm.Price" })}
                      </MenuItem>
                      <MenuItem value="PRICE_DESC">
                        <FontAwesomeIcon icon={faSortAmountDownAlt} />{" "}
                        &nbsp;&nbsp;
                        {intl.formatMessage({ id: "ProductForm.Price" })}
                      </MenuItem>
                      <MenuItem value="DATE_ASC">
                        <FontAwesomeIcon icon={faSortAmountUpAlt} />{" "}
                        &nbsp;&nbsp;
                        {intl.formatMessage({ id: "ProductForm.Date" })}
                      </MenuItem>
                      <MenuItem value="DATE_DESC">
                        <FontAwesomeIcon icon={faSortAmountDownAlt} />{" "}
                        &nbsp;&nbsp;
                        {intl.formatMessage({ id: "ProductForm.Date" })}
                      </MenuItem>
                    </Select>
                  </div>
                  <Tooltip title="Display grid" placement="top">
                    <ButtonCustomize
                      size="small"
                      onClick={() => setDisplayMode("grid")}
                      style={{
                        background: displayMode === "grid" ? "#eeeeee" : "",
                      }}
                    >
                      <AppsIcon />
                    </ButtonCustomize>
                  </Tooltip>
                  <Tooltip title="Display list" placement="top">
                    <ButtonCustomize
                      size="small"
                      onClick={() => setDisplayMode("list")}
                      style={{
                        background: displayMode === "list" ? "#eeeeee" : "",
                        marginRight: 3,
                      }}
                    >
                      <FormatListBulletedIcon />
                    </ButtonCustomize>
                  </Tooltip>
                </Box>
              </Box>
            </div>
            <Box flex="1">
              {displayMode === "list" ? (
                <Table
                  columns={columns}
                  data={renderTableRows()}
                  emptyMessage="No data available"
                  onClickRow={(row) =>
                    row.id && handleOnEdit("general", row.id)
                  }
                />
              ) : (
                <>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    marginBottom={1}
                    gridGap={10}
                  >
                    {listMenuProducts?.getListMenuProducts?.list
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((product) => (
                        <ProductGrid
                          key={product.id}
                          productData={product}
                          onReturn={(id, pAnchorEl) => {
                            setIdProduct(id);
                            setAnchorEl(pAnchorEl);
                          }}
                        />
                      ))}
                  </Box>
                  {listMenuProducts?.getListMenuProducts?.list?.length ===
                    0 && (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      style={{ marginBottom: 15 }}
                    >
                      No data available
                    </Typography>
                  )}
                </>
              )}
              {listMenuProducts?.getListMenuProducts?.list?.length && (
                <Box display="flex" justifyContent="end">
                  <TablePagination
                    labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                    count={listMenuProducts?.getListMenuProducts?.list?.length}
                    page={page}
                    onPageChange={(_, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              )}
            </Box>
          </div>
        </div>
      )}

      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleOnEdit("general")}>
          {intl.formatMessage({
            id: "ProductForm.editProduct",
          })}
        </MenuItem>
        <MenuItem onClick={() => handleOnEdit("options")}>
          {intl.formatMessage({
            id: "ProductForm.editOption",
          })}
        </MenuItem>
        <MenuItem onClick={() => handleOnEdit("variations")}>
          {intl.formatMessage({
            id: "ProductForm.editVariation",
          })}
        </MenuItem>
        <MenuItem onClick={() => handleOnEdit("relatedProducts")}>
          Related Products
        </MenuItem>
        <MenuItem
          style={{ color: "#ff4949" }}
          onClick={() => {
            idProduct && handleDelete(idProduct);
            setAnchorEl(null);
          }}
        >
          {intl.formatMessage({
            id: "ProductForm.deleteVariation",
          })}
        </MenuItem>
      </Menu>

      {loading && <LoadingList />}
      {!loading && !dataExist && (
        <ListDataEmpty
          image={products}
          description={intl.formatMessage({ id: "ProductForm.listEmpty" })}
          btnName={intl.formatMessage({ id: "ProductForm.addProduct" })}
          url="/managment-product"
        />
      )}
      <Modal
        open={openModalDelete}
        title={intl.formatMessage({ id: "ProductForm.modalDeleteTitle" })}
        message={intl.formatMessage({
          id: "ProductForm.modalDeleteDescription",
        })}
        handleClose={handleClose}
        handleContent={() =>
          idProduct && DeleteProduct({ variables: { token, id: idProduct } })
        }
        action="delete"
        loading={loadingDelete}
      />
      <ProductImportCSVModal
        idMenu={localMenu?.id || ""}
        open={openModalImportCSV}
        title="Import Product"
        handleClose={() => setOpenModalImportCSV(false)}
        refetchListProducts={refetch}
      />
    </Wrapper>
  );
};

export default ListProducts;
