import { formProductRequiredVar } from "../../../api/local-state";

export const validation = (identifier?: string, name?: string): boolean => {
  const errors = {
    identifier: "",
    name: "",
  };
  let isValid = true;
  if (!identifier) {
    isValid = false;
    errors.identifier = "This file is required";
  }
  if (!name) {
    isValid = false;
    errors.name = "This file is required";
  }
  formProductRequiredVar(errors);
  return isValid;
};
