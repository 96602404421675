import * as React from "react";
import styled from "styled-components";
import { Typography, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Input, ButtonCustomize } from "../../commons";

import { SnoonuLoginValidation, Error } from "./SnoonuLoginValidation";

type SnoonuLoginProps = {
  loading: boolean;
  onReturn: (email?: string, password?: string) => void;
};

const SnoonuLogin = ({ loading, onReturn }: SnoonuLoginProps): JSX.Element => {
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<Error>();

  const handleConnect = () => {
    const validation = SnoonuLoginValidation(email, password);
    if (Object.entries(validation).length === 0) {
      onReturn(email, password);
    } else {
      setErrors({
        email: validation.email,
        password: validation.password,
      });
    }
  };

  return (
    <Form>
      <Typography variant="subtitle2" className="subtitle">
        Snoonu requires an account login to be used for delivery.
      </Typography>
      <Typography variant="subtitle2" className="subtitle">
        If you don’t have an account, request{" "}
        <a href="https://snoonu.com/" target="_blank" rel="noreferrer">
          snoonu’s website{" "}
        </a>{" "}
        for more information.
      </Typography>
      <Input
        label="Email"
        value={email || ""}
        onChange={(e) => {
          setEmail(e.target.value);
          setErrors({
            ...errors,
            email: undefined,
          });
        }}
        msgError={errors?.email}
        style={{ marginTop: 20 }}
      />
      <Input
        label="Password"
        type={showPassword ? "text" : "password"}
        value={password || ""}
        onChange={(e) => {
          setPassword(e.target.value);
          setErrors({
            ...errors,
            password: undefined,
          });
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        msgError={errors?.password}
        style={{ marginTop: 20 }}
      />
      <ButtonCustomize
        variant="contained"
        color="secondary"
        className="btn-connect"
        onClick={handleConnect}
        disabled={loading}
      >
        Connect snoonu
      </ButtonCustomize>
    </Form>
  );
};

const Form = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  .subtitle {
    max-width: 400px;
  }
  .btn-connect {
    width: 150px;
    margin-top: 20px;
  }
`;

export default SnoonuLogin;
