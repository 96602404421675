import React from "react";
import ContentLoader from "react-content-loader";

const LoadingListCategores: React.FC = () => {
  return (
    <ContentLoader viewBox="0 0 778 400" width={778} height={400}>
      <rect x="20" y="20" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="65" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="110" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="155" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="200" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="245" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="290" rx="0" ry="0" width="265" height="35" />
      <rect x="20" y="335" rx="0" ry="0" width="265" height="35" />
    </ContentLoader>
  );
};

export default LoadingListCategores;
