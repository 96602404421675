import gql from "graphql-tag";
import { makeVar, InMemoryCache } from "@apollo/client";
// import local
import { MenuPayload, MenuProductPayload } from "./types";

export type LocalState = {
  menu: MenuPayload;
  language: string;
  product: MenuProductPayload;
};

export type SnackBar =
  | {
      open: boolean;
      severity: "info" | "success" | "warning" | "error" | undefined;
      message: string;
    }
  | undefined;

type ProductInputRequired =
  | {
      identifier: string;
      name: string;
    }
  | undefined;

export const MENU_FRAGMENT = gql`
  fragment menu on MenuPayload {
    id
    names {
      id
      languageCode
      value
    }
    languages {
      language {
        id
        code
      }
      isMain
    }
    sites {
      id
    }
    accessCode
    logo {
      id
      kind
      fileName
    }
    urlCode
    isPublished
    categoriesCount
    characteristics {
      kind
      value
    }
  }
`;

// mutation
export const SELECT_MENU = gql`
  mutation selectMenu($id: String) {
    selectMenu(id: $id) @client
  }
`;

// query
export const GET_SELECTED_MENU = gql`
  {
    menu @client {
      id
      names {
        id
        languageCode
        value
      }
      titles {
        id
        languageCode
        value
      }
      descriptions {
        id
        languageCode
        value
      }
      languages {
        language {
          id
          code
        }
        isMain
      }
      currency {
        id
        code
      }
      sites {
        id
      }
      accessCode
      logo {
        id
        kind
        fileName
      }
      urlCode
      isPublished
      categoriesCount
      characteristics {
        kind
        value
      }
      copiedLogos {
        id
        kind
        fileName
      }
    }
  }
`;

// query
export const GET_LANGUAGE = gql`
  {
    language @client
  }
`;

export const GET_STEP = gql`
  {
    step @client
    email @client
  }
`;

// Reactive variable

// set Selected Store (Menu) in Local State
export const SelectedMenuVar = makeVar<MenuPayload | undefined>(undefined);

// set Selected product
export const SelectedProduct = makeVar<MenuProductPayload | undefined>(
  undefined
);

export const snackBarVar = makeVar<SnackBar>(undefined);

// set input required from form product general
export const formProductRequiredVar = makeVar<ProductInputRequired>(undefined);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        productItem: {
          read() {
            return SelectedProduct();
          },
        },
        menuItem: {
          read() {
            return SelectedMenuVar();
          },
        },
        snackBar: {
          read() {
            return snackBarVar();
          },
        },
        formProduct: {
          read() {
            return formProductRequiredVar();
          },
        },
      },
    },
  },
  addTypename: false,
});

// get Selected prodct
export type GetMenuLocalQuery = {
  menuItem: MenuPayload;
};
export const GET_MENU_LOCAL = gql`
  query @client {
    menuItem
  }
`;

export type GetProductLocalQuery = {
  productItem: MenuProductPayload;
};

// get Selected prodct
export const GET_PRODUCT_LOCAL = gql`
  query @client {
    productItem
  }
`;

// get snackBar
export type GetSnackBarLocalQuery = {
  snackBar: SnackBar;
};

export const GET_SNACKBAR_LOCAL = gql`
  {
    snackBar @client
  }
`;

// get input required from form product general
export type GetRequiredFormProductQuery = {
  formProduct: ProductInputRequired;
};

export const GET_REQUIRED_FORM_PRODUCT_LOCAL = gql`
  {
    formProduct @client
  }
`;
