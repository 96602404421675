import gql from "graphql-tag";

export const GET_LIST_PAYMENT_METHODS = gql`
  query getListPaymentMethods($input: GetListPaymentMethodsInput!) {
    getListPaymentMethods(input: $input) {
      list {
        id
        kind
        apiPublicKey
        apiSecretKey
        apiClientId
        isEnabled
      }
    }
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod($input: AddPaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      id
    }
  }
`;

export const EDIT_PAYMENT_METHOD = gql`
  mutation editPaymentMethod($input: EditPaymentMethodInput!) {
    editPaymentMethod(input: $input) {
      success
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation removePaymentMethod($input: RemovePaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      success
    }
  }
`;
