import * as React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useReactiveVar } from "@apollo/client";
import find from "lodash/find";

import { Search, ButtonCustomize, TablePagination } from "../../commons";

import { GET_LIST_MENU_PRODUCTS } from "../../api";
import {
  GetListMenuProductsQuery,
  GetListMenuProductsQueryVariables,
  MenuPayload,
  LanguageCode,
} from "../../api/types";

import { SelectedMenuVar } from "../../api/local-state";

type ListTranslateProductProps = {
  origineLangage: LanguageCode;
  onIdProduct: (id: string) => void;
};

const ListTranslateProduct: React.FC<ListTranslateProductProps> = ({
  origineLangage,
  onIdProduct,
}) => {
  const token = localStorage.getItem("token") || "";

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [search, setSearch] = React.useState<{
    name?: string;
    sku?: string;
    category?: string;
    barcode?: string;
  }>({
    name: undefined,
    sku: undefined,
    category: undefined,
    barcode: undefined,
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const { data: listMenuProducts } = useQuery<
    GetListMenuProductsQuery,
    GetListMenuProductsQueryVariables
  >(GET_LIST_MENU_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        name: search.name,
        barcode: search.barcode,
      },
    },
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (value: string, mode: string) => {
    setSearch({ [mode]: value || undefined });
  };

  return (
    <Wrapper>
      <Search
        listModeSearch={["name", "barcode"]}
        onReturnSearch={(value, mode) =>
          value && mode && handleSearch(value, mode)
        }
      />
      <div className="list-item">
        {listMenuProducts?.getListMenuProducts?.list
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((item) => (
            <Row key={item.id}>
              <Typography variant="subtitle1">
                {find(item?.names, (o) => o.languageCode === origineLangage)
                  ?.value ? (
                  find(item?.names, (o) => o.languageCode === origineLangage)
                    ?.value
                ) : (
                  <Typography variant="subtitle2" className="empty">
                    This product is not translated in this language
                  </Typography>
                )}
              </Typography>

              <ButtonCustomize
                onClick={() => {
                  onIdProduct(item.id!);
                }}
                disabled={
                  !find(item?.names, (o) => o.languageCode === origineLangage)
                    ?.value
                }
              >
                <EditIcon fontSize="small" style={{ marginRight: 3 }} /> Edit
              </ButtonCustomize>
            </Row>
          ))}
      </div>
      <TablePagination
        labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
        count={listMenuProducts?.getListMenuProducts?.list?.length || 0}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Wrapper>
  );
};

export default ListTranslateProduct;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .list-item {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    margin: 5px 0 10px 0;
    .MuiButton-root {
      color: #3fb4f6;
    }
    .MuiButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;

const Row = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeffa;
  .empty {
    color: #a9a9a9;
  }
`;
