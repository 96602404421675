import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    .search {
      margin-bottom: 15px;
      .mainInput {
        padding: 9px 9px 9px;
        border-radius: 5px;
      }
    }
    .table-collection {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 16px;
      border-radius: 10px;
      .MuiIconButton-root {
        padding: 8px;
      }
    }
  }
`;

export const Status = styled.div<{ status?: boolean }>`
  color: ${({ theme, status }) =>
    status ? theme.palette.success.main : theme.palette.error.main};
`;

export const CustomerIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.error.main};
  margin: 0 4px;
`;
