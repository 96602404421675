import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  Button,
  Typography,
  Radio,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FileDrop from "react-file-drop";

import { URL_PICTURES } from "../../api";
import { snackBarVar } from "../../api/local-state";

import {
  ShopifyLogo,
  WixLogo,
  DigishopImportCSV,
} from "../../img/digishop/menu-icons";
import { import_file, success } from "../../img";

import { Wrapper } from "./ProductImportCSVModal--styles";

type ProductImportCSVModalProps = {
  idMenu: string;
  open: boolean;
  title: string;
  handleClose: () => void;
  refetchListProducts?: () => void;
};

const ProductImportCSVModal: React.FC<ProductImportCSVModalProps> = ({
  idMenu,
  open,
  title,
  handleClose,
  refetchListProducts,
}) => {
  const [file, setFile] = useState<FileList | null>(null);
  const [template, setTemplate] = useState<string>("digishop");
  const [overWrite, setOverWrite] = useState<boolean>(false);
  const [count, setCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate(event.target.value);
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOverWrite(event.target.checked);
  };

  const handleFiles = (filesArray: FileList | null) => {
    filesArray && setFile(filesArray);
  };

  const handleError = () => {
    snackBarVar({
      open: true,
      severity: "error",
      message: "service unavailable",
    });
  };

  const handleUpload = () => {
    const url = `${URL_PICTURES}/menu/uploads/csv?menu=${idMenu}&template=${template}&overwrite=${overWrite}`;
    if (file && file[0] !== null && file[0] !== undefined) {
      setLoading(true);
      const dataForm = new FormData();
      dataForm.append("files", file[0]);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.send(dataForm);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.response);
          setCount(response.count);
          refetchListProducts && refetchListProducts();
        } else {
          handleError();
        }
        setLoading(false);
      };
      xhr.onerror = () => {
        handleError();
        setLoading(false);
      };
    }
  };

  useEffect(() => {
    return () => {
      setCount(undefined);
      setLoading(false);
      setFile(null);
    };
  }, [handleClose]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Typography
          variant="h2"
          style={{ fontWeight: "bold", margin: "5px 0 10px 0" }}
        >
          {title}
        </Typography>

        {count === undefined && (
          <>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", margin: "5px 0" }}
            >
              Select a template :
            </Typography>
            <Box display="flex" marginTop="5px" marginBottom="10px">
              <Box
                display="flex"
                height="38px"
                alignItems="center"
                border="1px solid #E2E4F3"
                borderRadius="5px"
                marginRight="15px"
                paddingRight="15px"
              >
                <Radio
                  value="digishop"
                  checked={template === "digishop"}
                  onChange={handleChange}
                  size="small"
                />
                <DigishopImportCSV />
              </Box>
              <Box
                display="flex"
                height="38px"
                alignItems="center"
                border="1px solid #E2E4F3"
                borderRadius="5px"
                marginRight="15px"
                paddingRight="15px"
              >
                <Radio
                  value="shopify"
                  checked={template === "shopify"}
                  onChange={handleChange}
                  size="small"
                />
                <ShopifyLogo />
              </Box>
              <Box
                display="flex"
                height="38px"
                alignItems="center"
                border="1px solid #E2E4F3"
                borderRadius="5px"
                paddingRight="15px"
              >
                <Radio
                  value="wix"
                  checked={template === "wix"}
                  onChange={handleChange}
                  size="small"
                />
                <WixLogo />
              </Box>
            </Box>

            <Typography variant="body2" style={{ marginBottom: 10 }}>
              Take a look at our sample{" "}
              <Link to="exemple.csv" target="_blank" download>
                CSV
              </Link>
              &nbsp; to see an exemple of the format required.
            </Typography>

            <FileDrop onDrop={handleFiles}>
              <div className="drop-area" id="drop-area">
                <Box display="flex" m="auto auto 15px auto;">
                  <img
                    id="drop-area-img"
                    src={import_file}
                    alt="upload files"
                  />
                  {file && (
                    <Typography
                      variant="subtitle1"
                      style={{ marginLeft: "10px" }}
                    >
                      {file?.[0].name}
                    </Typography>
                  )}
                </Box>
                <div className="drop-content">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {}}
                    className="upload-btn"
                  >
                    <input
                      accept=".csv"
                      id="fileInput"
                      type="file"
                      onChange={(e) => handleFiles(e.target.files)}
                    />
                    <span className="btn-description">Add File</span>
                  </Button>
                  <label
                    htmlFor="info"
                    className="drop-area-label"
                    id="drop-area-span"
                  >
                    or drag and drop files in the container to upload.
                  </label>
                </div>
              </div>
            </FileDrop>

            <FormControlLabel
              control={
                <Checkbox
                  checked={overWrite}
                  onChange={handleChecked}
                  name="checked"
                  color="secondary"
                  size="small"
                />
              }
              label="Overwrite any existing products that has the same identifier."
            />

            <div className="action">
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "10px" }}
                onClick={handleUpload}
                disabled={loading}
              >
                Upload
              </Button>
            </div>
          </>
        )}
        {count !== undefined && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="20px"
          >
            <img src={success} alt="success" />
            <Typography
              variant="h2"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Import has completed
            </Typography>
            <Typography variant="subtitle1">
              {count} &nbsp;products imported
            </Typography>

            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              onClick={handleClose}
            >
              <span className="btn-description">Return to products</span>
            </Button>
          </Box>
        )}
      </Wrapper>
    </Dialog>
  );
};

export default ProductImportCSVModal;
