import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    display: flex;
    min-width: 500px;
    flex-direction: column;
  }
  .MuiAvatar-root {
    width: 35px;
    height: 35px;
    margin-right: 7px;
  }
`;
