export default {
  // MainLayout
  "mainLayout.products": "Products",
  "mainLayout.categories": "Categories",
  "mainLayout.posts": "Posts",
  "mainLayout.appearance": "Appearance",
  "mainLayout.plugins": "Plugins",
  "mainLayout.settings": "Settings",
  "mainLayout.inventory": "Inventory",
  "mainLayout.clients": "Clients",
  "mainLayout.pages": "Pages",
  "mainLayout.order": "orders",
  "mainLayout.dictionary": "Dictionary",
  "mainLayout.address": "Address",
  "mainLayout.general": "General",
  "mainLayout.giftCards": "Gifts Cards",
  //  Forms
  "form.fieldRequired": "This field is requiered",
  "form.invalidEmail": "Invalid email",
  "form.errorConfirmPassword": "password don't match",
  "form.password": "Password",
  "form.confirmPassword": "Confirm password",

  //  Login
  "login.email": "Email",
  "login.password": "Password",
  "login.login": "Login",
  "login.signup": "Create an account",
  "login.remember": "Remember me",
  "login.forgetPassword": "Forget Password",
  // signup
  "signup.title": "Create a new account",
  "signup.signup": "Create Account",
  "signup.email": "Email",
  "signup.password": "Password",
  "signup.confirmPassword": "Confirm password",
  "signup.domaine": "Domain",
  "signup.emailExist": "Email is already in use!",
  "signup.domainExist": "Domain is already in use!",
  "signup.registerComplete":
    "Your account has been created, check you mailbox!",
  // confirm accont
  "confirm.header": "Your email is confirmed!",
  "confirm.message": "Connect to access your account! ",
  "confirm.connect": "Connect",
  // sign out
  "sign.out": "Sign out",
  //  Settings
  "Settings.title": "Settings",
  "Settings.information": "Manage your settings, add new ones to your list or",
  "Settings.information2": "edit and delete existing ones!",
  "Settings.label": "Menu languages",
  // register merchant
  "merchant.personalInfo": "Personal Information",
  "merchant.storeInformation": "Store Information",
  "merchant.firstname": "Name",
  "merchant.lastname": "Last name",
  "merchant.countryCode": "Country Code",
  "merchant.phoneNumber": "Phone Number",
  "merchant.activity": "Activities",
  "merchant.taxInfo": "Tax Informations",
  "merchant.corporateName": "Store name",
  "merchant.taxRegistration": "Tax registration ",
  "merchant.tavCode": "TAV Code",
  "merchant.companyAddress": "Address",
  "merchant.address": "Address",
  "merchant.postCode": "Postal Code",
  "merchant.language": "Language",
  "merchant.english": "English",
  "merchant.french": "French",
  "merchant.currency": "Currency",
  "merchant.website": "Website",
  "merchant.social": "Social Network",
  "merchant.link": "Link",
  "merchant.skip": "Skip",
  "merchant.next": "Next",
  "merchant.finish": "Finish",
  // snack message
  "Snack.message":
    "Maintain an up tp date profile information to garantee a clear communication with your clients",
  "Snack.update": "Update",
  //  Account
  "Account.title": "My Account",
  "Account.setting": "Setting",
  "Account.details": "Account details",
  "Account.published": "Published",
  "Account.manageAccount": "Manage Your Account",
  "Account.save": "Save",
  "Account.informations": "Account information",
  "Account.fieldActivity": "Field Of Activity",
  "Account.activity": "Activity",
  "Account.fiscal": "Tax Details",
  "Account.immarticul": "Tax Registration",
  "Account.tva": "TVA Code",
  "Account.address": "Address",
  "Account.phone": "Phone number",
  "Account.postal": "Postal Code",
  "Account.country": "Country",
  "Account.city": "City",
  "Account.mobile": "Phone Number",
  "Account.companyEmail": "Company Email",
  "Account.personalInformation": "Personal information",
  "Account.firstContact": "First Contact",
  "Account.firstName": "First Name",
  "Account.lastName": "Last Name",
  "Account.email": "Email",
  "Account.login": "Login",
  "Account.password": "Password",
  "Account.connexionInformation": "Connection Information",
  "Account.changePassword": "Change password",
  "Account.oldPassword": "Old password",
  "Account.newPassword": "New password",
  "Account.confirmPassword": "Confirm password",
  "Account.status": "Status",
  "Account.expirationDate": "Expiration Date",
  "Account.succesChangePwd": "Password successfully changed",
  "Account.companyDomain": "Unique Identifier",
  "Account.socialMedia": "Social media",
  "Account.Website": "Website",
  "Account.selectSocialMedia": "Select social media",
  "Account.link": "Link",
  //  Ads
  "Ads.title": "Advertising",
  "Ads.list": "Advertising list",
  "Ads.add": "Add publicity",
  "Ads.information": "Manage your Advertising, add new ones to your list or",
  "Ads.information2": "edit and delete existing ones!",
  "Ads.notice":
    "Your contract does not give you the right to publish another advertisement.",
  "Ads.notice2":
    "Your new advertisement has been added, but it remains unpublished for the moment.",
  "Ads.listEmpty":
    "The list of your advertisements is still empty, please add your first advertisement",
  "Ads.addFirst": "Add my first advertisement",
  // Ads Card
  "Ads.card.Expired": "Expires in",
  "Ads.card.Day": "day(s)",
  "Ads.card.isExpired": "Expired",
  "Ads.card.NumberOfClicks": "Number of clicks",
  "Ads.card.Published": "Published",
  "Ads.card.notPublished": "Not Published",
  "Ads.card.edit": "Edit",
  "Ads.card.delete": "Delete",
  "Ads.card.deleteTitle": "Delete advertisement",
  "Ads.card.deleteMessage": "do you want to delete this ad?",
  "Ads.card.status": "Status",

  // Ads Form
  "Ads.form.title": "Publicity",
  "Ads.form.information": "Create a new advertisement or update",
  "Ads.form.information2": "existing advertisements!",
  "Ads.form.btnSave": "Save",
  "Ads.form.newAdvertisement": "New advertisement",
  "Ads.form.published": "Posted on the store",
  "Ads.form.nameAd": "Name of advertisement *",
  "Ads.form.selectStore": "Select store",
  "Ads.form.uploadCover": "Upload cover image",
  "Ads.form.uploadPoster": "Upload poster image",
  "Ads.form.adPositionTitle": "Advertising position",
  "Ads.form.adPosition1": "Display advertising on the home page",
  "Ads.form.adPosition2": "Display advertising in category pages",
  "Ads.form.adPosition3": "Display advertising on the home and category pages",
  "Ads.form.AdditionalInformation": "Additional information",
  "Ads.form.startDate": "Start date *",
  "Ads.form.endDate": "End date *",
  "Ads.form.type": "Select the type",
  "Ads.form.imageOnly": "Image Only",
  "Ads.form.imageWithProduct": "Image with product",
  "Ads.form.imageWithUrl": "Image with URL",
  "Ads.form.products": "Select the products",
  "Ads.form.product": "Products *",
  "Ads.form.externalUrl": "External URL *",
  // Modal
  "Modal.add": "Add",
  "Modal.update": "Update",
  "Modal.delete": "Delete",
  "Modal.cancel": "Cancel",
  "Modal.close": "Close",
  "Modal.notice": "Understood",
  //  Menus
  "Menus.title": "Menu",
  "Menus.subtitle": "Menus List",
  "Menus.manage": "Manage Menu",
  "Menus.update": "Update",
  "Menus.information": "Manage your menus, add new ones to your list or",
  "Menus.information2": "edit and delete existing ones!",
  "Menus.add": "Add Menu",
  "Menus.notice":
    "Your contract does not give you the right to publish another menu.",
  "Menus.notice2":
    "Your new menu has been added, but it remains unpublished for the moment.",
  "Menus.listEmpty":
    "Your menu list is still empty, please add your first menu.",
  // Menu Card
  "MenuCard.manage": "Manage",
  "MenuCard.settings": "Settings",
  "MenuCard.theme": "Theme",
  "MenuCard.delete": "Delete",
  "MenuCard.accessCode": "Access Code",
  "MenuCard.contain": "Contain",
  "MenuCard.categories": "Categories",
  "MenuCard.languages": "Languages",
  "MenuCard.download": "Download",
  "MenuCard.published": "Published",
  "MenuCard.notPublished": "Not Published",
  "MenuCard.deleteTitle": "Delete menu",
  "MenuCard.deleteMessage": "Do you want to delete this menu?",
  //  Update Menu
  "MenuUpdate.update": "Update",
  "MenuUpdate.information1":
    "Manage your menus and theme, add new ones to your list or",
  "MenuUpdate.information2": "Edit and delete existing ones!",
  "MenuUpdate.save": "Save",
  "MenuUpdate.published": "Published",
  "MenuUpdate.menuName": "Menu Name",
  "MenuUpdate.identifier": "Name in URL",
  "MenuUpdate.menuLanguages": "Menu Languages",
  "MenuUpdate.menuMainLanguage": "Menu Main Languages",
  "MenuUpdate.createMenu": "Creation of a new menu",
  "MenuUpdate.updateMenu": "Update",
  "MenuUpdate.identifierAlreadyExists": "Category's name in URL already exists",
  "MenuUpdate.languageSettings": "Language",
  //  Menu Details
  "MenuDetails.information1": "Manage your menus, add new ones to your list or",
  "MenuDetails.information2": "Edit and delete existing ones!",
  "MenuDetails.published": "Published",
  "MenuDetails.category": "Categorie",
  "MenuDetails.categories": "Categories",
  "MenuDetails.product": "Product",
  "MenuDetails.products": "Products",
  "MenuDetails.listCategoriesEmpty": "The list of categories is still empty",
  "MenuDetails.listProductsEmpty":
    "The list of products for this category is still empty. Add products here",
  "MenuDetails.filterBy": "Filter by",
  "MenuDetails.productName": "Product name",
  "MenuDetails.price": "Price",
  "MenuDetails.placehoder.search": "Search for",
  "MenuDetails.btn.search": "Search",
  "MenuDetails.addProduct": "Add product",
  "MenuDetails.listEmptyProducts": "You don't have any product",

  "MenuDetails.addClient": "Add client",
  "MenuDetails.listClientsEmpty": "You have no Clients",
  // Category Form
  "CategoryForm.information1":
    "Manage your menus and themes, add new ones to your list or",
  "CategoryForm.information2": "Edit and delete existing ones!",
  "CategoryForm.categories": "Category",
  "CategoryForm.save": "Save",
  "CategoryForm.title": "Creation of new category",
  "CategoryForm.show": "Visible on the store",
  "CategoryForm.categoryParent": "Parent Category",
  "CategoryForm.category": "Category",
  "CategoryForm.subCategory": "Sub-category",
  "CategoryForm.identifier": "Name in URL",
  "CategoryForm.useImages": "Use images for this category",
  "CategoryForm.languageSettings": "Language Settings",
  "CategoryForm.uploadTitle": "Upload Category Images",
  "CategoryForm.editCategory": "Edit Category",
  "CategoryForm.identifierAlreadyExists": "Category name in URL already exists",
  // Upload
  "Upload.information": "Drag & drop your images",
  "Upload.information2": "or select files",
  "Upload.download": "Download",
  "Upload.chooseGallery": "Choose from gallery",
  //  Products
  "Products.title": "Products",
  "Products.single": "Product",
  //  Dashboard
  "Dashboard.title": "Dashboard",
  "Dashboard.subtitle": "Statistics",
  "Dashboard.selectStore": "Select the store",
  "Dashboard.yearAndMonth": "Year and month",
  "Dashboard.datePicker": "enLocale",
  "Dashboard.vuePerProduct": "Views by product",
  "Dashboard.vuePerCategory": "Views by categories",
  "Dashboard.consultation": "Weekly consultation",
  "Dashboard.january": "January",
  "Dashboard.february": "February",
  "Dashboard.march": "March",
  "Dashboard.april": "April",
  "Dashboard.may": "May",
  "Dashboard.june": "June",
  "Dashboard.july": "July",
  "Dashboard.august": "August",
  "Dashboard.september": "September",
  "Dashboard.october": "October",
  "Dashboard.november": "November",
  "Dashboard.december": "December",
  "Dashboard.monday": "Monday",
  "Dashboard.tuesday": "Tuesday",
  "Dashboard.wednesday": "Wednesday",
  "Dashboard.thursday": "Thursday",
  "Dashboard.friday": "Friday",
  "Dashboard.saturday": "Saturday",
  "Dashboard.sunday": "Sunday",
  //  Category
  "Category.title": "Category",
  // Category Card
  "CategoryCard.edit": "Edit",
  "CategoryCard.delete": "Delete",
  "CategoryCard.contains": "Contains : ",
  "CategoryCard.items": "Item(s)",
  "CategoryCard.status": "Status",
  // Language Table
  "LanguageTable.fields.name": "Name",
  "languageTable.placeholder": "Fill this field",
  "LanguageTable.fields.description": "Description",
  "LanguageTable.fields.longDescription": "Long description",
  // forget password
  "forget.message":
    "Check your inbox, an email has been sent with a link to reset your password.",
  "forget.newMessage": "Your password has been successfully reset!",
  "forget.login": "Log in",
  // Reset Password
  "resetPassword.label.resetPassword":
    "Please enter your username or email address. You will receive a link to reset your password via email.",
  "resetPassword.label.newPassword": "Please enter your new password.",
  "resetPassword.username": "Mail address",
  "resetPassword.cancel": "Cancel",
  "resetPassword.resetPassword": "Reset",
  "resetPassword.password": "New password",
  "resetPassword.confirm": "Confirm your password",
  "resetPassword.error": "Passwords do not match",
  // Table Row
  "TableRow.name": "Name",
  "TableRow.price": "Price",
  "TableRow.parentCategory": "parentCategory",
  "TableRow.status": "Status",
  "TableRow.isPublished": "Published",
  "TableRow.isNotPublished": "Not Published",
  "TableRow.btnEdit": "Edit",
  "TableRow.btnDelete": "Delete",
  "TableRow.modalTitle": "Delete Product",
  "TableRow.modalMessage": "You are about to delete this product?",
  "TableRow.view": "View",
  //  Form validation message
  "Validation.required": "This field is required",
  "Validation.notConfirmPassword": "The two passwords are not identical",

  // Orders
  "Orders.title": "Orders",
  "Orders.list": "Orders list",
  "Orders.ofTheDay": "Orders of the day",
  "Orders.listOfHistory": "Order history list",
  "Orders.historical": "Historical",
  "Orders.totalNumber": "Total number",
  "Orders.waitingForTreatment": "Waiting for treatment",
  "Orders.beingProcessed": "Being processed",
  "Orders.delivered": "Delivered",
  "Orders.WAITING": "Pending",
  "Orders.CANCELED": "Canceled",
  "Orders.ONGOING": "Pending",
  "Orders.READY": "Ready for delivery",
  "Orders.DELIVERED": "Delivered",
  "Orders.UNDELIVERED": "Undelivered",
  "Orders.IN_DELIVERING": "In delivery",
  "Orders.byAll": "All",
  "Orders.byWaitting": "Waiting",
  "Orders.byOngoing": "Pending",
  "Orders.byTreaties": "Done",
  "Orders.placeholderSearch": "Search by order reference",
  "Orders.placeholderSearchWithDate": "Search with date",
  "Orders.btnSearch": "Search",
  "Orders.reference": "Reference",
  "Orders.customer": "Customer",
  "Orders.phone": "Phone",
  "Orders.dateOfCreation": "Date of creation",
  "Orders.latestChange": "Latest change",
  "Orders.status": "Status",
  "Orders.origin": "Origin",
  // menuStyle
  "MenuStyle.title": "First page content",
  "MenuStyle.welcomMessage": "Welcome message",
  "MenuStyle.menuTitle": "Title",
  "MenuStyle.infos": "Addiotional informations",
  "MenuStyle.infoMessage": "for exemple you can enter your WIFI password",
  "MenuStyle.price": "Price display mode",
  "MenuStyle.theme": "Theme",
  "MenuStyle.bgColor": "Background color",
  "MenuStyle.bgImage": "Background style",
  "MenuStyle.font": "Font",
  "MenuStyle.fontPrimary": "Primary font style",
  "MenuStyle.fontSecondary": "Secondary font style",
  "MenuStyle.color": "Color Palette",
  "MenuStyle.colorPrimary": "Primary color",
  "MenuStyle.colorSeconday": "Primary color",
  "MenuStyle.colorAccent": "Accent color",
  "MenuStyle.colorTag": "Color hex-code",
  "MenuStyle.titleDeco": 'Background style of the title "Menu"',
  "MenuStyle.categoryDeco": "Catagories Background style",
  "MenuStyle.changeBtn": "Change",
  "MenuStyle.saveBtn": "Save",
  "MenuStyle.confirmBtn": "Confirm",
  "MenuStyle.downloadBtn": "Upload",
  "MenuStyle.priceDigit1": "1 digit after the decimal point",
  "MenuStyle.priceDigit2": "2 digits after the decimal point",
  "MenuStyle.priceDigit3": "3 digits after the decimal point",
  "MenuStyle.modalGaleryTab": "Choose from galery",
  "MenuStyle.modalArchiveTab": "My files",
  "MenuStyle.menuBgRec": "Background image recommended resolution",
  "MenuStyle.titleBgRec":
    '"Menu" Title background image recommended resolution',
  "MenuStyle.categoryBgRec": "Category background image recommended resolution",
  "MenuStyle.snack": "All changeshave been successfully saved!",
  "MenuStyle.tip1": "Manage your menu here, add new informations",
  "MenuStyle.tip2": "or change the theme",

  // categoryCard
  "CategoryCard.modalTitle": "Delete Category",
  "CategoryCard.modalMessage": "You are about to delete this category!",

  // file
  "File.modalTitle": "Delete Image",
  "File.modalMessage": "You are about to delete this image!",
  "File.tooltipDelete": "Delete",
  "File.onUpload": "Uploading",
  "File.uploaded": "Uploaded",

  // Menu card
  "MenuCard.copy": "Copied",
  "MenuCard.linkCopied": "Link copied!",

  // languageTable
  "Language.french": "French",
  "Language.arabic": "Arabic",
  "Language.english": "English",
  "Language.german": "German",
  "Language.italian": "Italian",
  "Language.spanish": "Spanish",
  "Language.portogese": "Portuguese",
  "Language.turkish": "Turkish",
  "Language.chinese": "Chinese",
  "Language.japanese": "Japanese",
  "Language.placeholder": "Fill in this field",

  // ModalDetailOrder
  "ModalDetailOrder.refrence": "Reference",
  "ModalDetailOrder.clientInfo": "Client's information",
  "ModalDetailOrder.fullName": "Full name",
  "ModalDetailOrder.phone": "Phone",
  "ModalDetailOrder.email": "Email",
  "ModalDetailOrder.note": "Note",
  "ModalDetailOrder.orderInfo": "Order informations",
  "ModalDetailOrder.orderDeliveryCost": "Delivery cost",
  "ModalDetailOrder.total": "Total",

  // ModalPromotion
  "ModalPromotion.title": "Promotion title",
  "ModalPromotion.description": "Short description",
  "ModalPromotion.startDate": "Start date",
  "ModalPromotion.endDate": "End date",

  // Upload
  "Upload.logoRec": "Logo recommended resolution",
  "Upload.categoryRec": "Category image  recommended resolution",
  "Upload.productRec": "Product image recommended resolution",
  "Upload.promotion.cover": "Cover recommended resolution",
  "Upload.promotion.poster": "Poster recommended resolution",
  "Upload.error":
    "It seems that you are trying to upload a file with the wrong extension. Please choose a file with one of these extensions: .jpeg, .jpg, .png",
  "Upload.errorSizePicture": "Image size can't be greater than 2 MB",
  "Upload.errorResulotionPicture":
    "Image resolution should be a minimum of 400 x 400 px",
  // MenuCard
  "MenuCard.addMenu": "Add my first menu",

  // ProductForm
  "ProductForm.ProductManagement": "Product management",
  "ProductForm.ProductsList": "Products list",
  "ProductForm.ProductCreation": "Product creation",
  "ProductForm.ProductUpdate": "Product update",
  "ProductForm.status": "Status",
  "ProductForm.title": "Products",
  "ProductForm.save": "Save",
  "ProductForm.publish": "Visible on the menu",
  "ProductForm.price": "Price in ",
  "ProductForm.discount": "Discount in percentage % ",
  "ProductForm.afterDiscount": "Price after discount",
  "ProductForm.buyingPrice": "Buying price",
  "ProductForm.changeCatTitle": "Change product's category",
  "ProductForm.quantity": "Quantity (Stock)",
  "ProductForm.category": "Categorye",
  "ProductForm.subCategory": "Subcateory",
  "ProductForm.none": "None",
  "ProductForm.managedBy": "Managed by",
  "ProductForm.piece": "Piece",
  "ProductForm.kilogram": "Kilogram",
  "ProductForm.metre": "Metre",
  "ProductForm.gram": "Gram",
  "ProductForm.notice": "The price to choose is for 100 grams",
  "ProductForm.name.in.url": "Product name in URL",
  "ProductForm.informations.product": "Product information",
  "ProductForm.pricing.information": "Pricing information",
  "ProductForm.uploadImages": "Upload product images",
  "ProductForm.identifierAlreadyExists": "Product name in URL already exists",
  "ProductForm.tags": "Tags",
  "ProductForm.vendor": "Vendor",
  "ProductForm.typeOfProduct": "Type of product",
  "ProductForm.vendorName": "Vendor name",
  "ProductForm.variants": "Variants",
  "ProductForm.descriptionVariants":
    "This product has several options, p. ex. different sizes or colors.",
  "ProductForm.AddVariants": "Add an option",
  "ProductForm.variants.reset": "Reset",
  "ProductForm.DeleteVariants": "Remove",
  "ProductForm.title.category": "Category",
  "ProductForm.category.name": "Category name",
  "ProductForm.title.subCategory": "Sub category",
  "ProductForm.sucCategory.name": "Sub-category name",
  "ProductForm.differentAddresses": "Different price for each address",
  "ProductForm.differentStock": "different stock for each address",
  "ProductForm.newProduct": "New product",
  "ProductForm.search": "Search",
  "ProductForm.sortBy": "Sort by",
  "ProductForm.productName": "Product Name",
  "ProductForm.Category": "Category",
  "ProductForm.numberOfOption": "Number of option",
  "ProductForm.numberOfVariation": "Number of variation",
  "ProductForm.basePrice": "Base price",
  "ProductForm.listEmpty":
    "Your Product list is still empty, please add your first product",
  "ProductForm.addProduct": "Add product",
  "ProductForm.notAssigned": "Not assigned",
  "ProductForm.name": "Name",
  "ProductForm.Price": "Price",
  "ProductForm.Date": "Date",
  "ProductForm.editProduct": "Edit product",
  "ProductForm.editOption": "Edit option",
  "ProductForm.editVariation": "Edit variation",
  "ProductForm.deleteVariation": "Delete product",
  "ProductForm.modalDeleteTitle": "Delete product ?",
  "ProductForm.modalDeleteDescription":
    "Are you sure you want to remove this product ?",
  "ProductForm.general": "General",
  "ProductForm.attributes": "Attributes",
  "ProductForm.options": "Options",
  "ProductForm.variation": "Variation",
  "ProductForm.stock": "Stock",
  "ProductForm.pricing": "Pricing",
  "ProductForm.initialPrice": "Initial price ",
  "ProductForm.sales": "Discount (%)",
  "ProductForm.afterSales": "After discount",
  "ProductForm.productsSettings": "Products settings",

  "ProductForm.showInStore": "Show product in store",
  "ProductForm.hasVariations": "Has variations",
  "ProductForm.hasMultiLanguages": "Has multi languages",
  "ProductForm.manageDictionary": "Manage Dictionary",
  "ProductForm.activateMultiSites": "Activate multiaddress",

  // Store
  "Store.title": "Stores",
  "Stores.subtitle": "List of stores",
  "Store.modalTitle": "Add store",
  "Store.note1": "Manage your stores, add new ones to your list or ",
  "Store.note2": "edit and delete existing ones!",
  "Store.addStore": "Add store",
  "Store.save": "Save",
  "Store.new": "new store",
  "Store.createStore": "Creation of a new magazine",
  "Store.updateStore": "Update",
  "Store.stores": "Stores",
  "Store.published": "Published",
  "Store.name": "Store name",
  "Store.identifier": "Store name in URL",
  "Store.menuLanguages": "Store Languages",
  "Store.menuMainLanguage": "Store Main Languages",
  "Store.free": "Free",
  "Store.fixedPrice": "Fixed price",
  "Store.freeDeliveryFrom": "Free delivery from",
  "Store.orderValue": "Order value",
  "Store.manage": "Manage Store",
  "Store.address": "Address",
  "Store.phone": "Phone number",
  "Store.action": "Actions",
  "Store.edit": "Edit",
  "Store.delete": "Delete",
  "Store.lineperPage": "Lines per page",
  "Store.modalEdit": "Edit store",
  "Store.modalDelete": "Delete store",
  "Store.modalDeleteMessage": "You want to delete this store?",
  "Store.addFisrtStore": "Add my first store",
  "Store.listEmpty":
    "Your store list is still empty, please add your first store",

  // store settings (address)
  "Store.settings": "Settings",
  "Store.settings.addresses": "List of addresses",
  "Store.settings.addressesListEmpty": "You don't have any address",
  "Store.settings.addAdress": "Add address",
  "Store.settings.formAddAdress": "Creating an address",
  "Store.settings.formUpdateAdress": "Update an address",
  "Store.settings.inputName": "Name",
  "Store.settings.inputAddress": "Address",
  "Store.settings.inputCountry": "Country",
  "Store.settings.inputCity": "City",
  "Store.settings.save": "save",
  "Store.settings.newAddress": "New address",
  "Store.modalTitleDelete": "Delete address",
  "Store.modalMessageDelete": "Do you want to delete this address?",

  // store settings (list address)
  "Store.settings.name": "Name",
  "Store.settings.address": "Address",
  "Store.settings.country": "Country",
  "Store.settings.city": "City",
  // settings > Home Page
  "Store.settingsHome.title": "HomePage",
  //  store Clients
  "store.cutomers.cutomersList": "Customers list",
  "store.customer.title": "Customers",
  "store.customer.creatClient": "Create Customer",
  "store.customer.addclient": "Create Client",
  "store.customersList.address.primary": "Primary",
  "store.customersList.address.secondary": "Secondary",
  "store.customerForm.save": "Save",
  "store.customerForm.addaddress.discard": "Cancel",
  "store.customerForm.formAddclient": "Client Creation",
  "store.customerForm.inputFirstName": "First Name",
  "store.customerForm.inputLastName": "Last Name",
  "store.customerForm.inputemail": "E-Mail",
  "store.customerForm.inputPhone": "Phone",
  "store.customerForm.AddressList": "Address Liste",
  "store.customerForm.ChooseAddress": "Choose your principal address",
  "store.customerForm.AddressListEmpty": "Client has no address",
  "store.customerForm.addAddress": "Client's address creation",
  "store.customerForm.addAddress.City": "City",
  "store.customerForm.addAddress.Country": "Country",
  "store.customerForm.addAddress.PostalCode": "PostalCode",
  "store.customerForm.addAddress.Phone": "Phone",
  "store.customerForm.error.missing.firstName": "First name is missing",
  "store.customerForm.error.missing.lastName": "Last name is missing",
  "store.customerForm.error.email": "E-Mail is already existing",
  "store.customerForm.error.missing.phone": "Phone is missing",
  "store.customerForm.error.valid.email": "Not a valid E-Mail address",
  "store.customerForm.error.valid.phone": "Not a valid Phone number",
  "store.customersList.addressList": "Customer's address list",
  "store.customersList.address.selectClient":
    "Select a customer to see his address list",
  "store.customersList.creatAddress": "Add Address",
  "store.customersList.address.HasNoAddress": "has no address",
  "store.customerForm.addAddress.MakePrimary": "Make it your principal address",
  "store.customerForm.addAddress.RoadName": "Street Name",
  "store.customerForm.address.error": "You need to choose a principal address",
  "store.customer.modifyclient": "Modify Customer",
  "store.customerForm.update.title": "Update Customer's Information",
  "store.customerForm.cancel": "Cancel",
  "store.customerForm.update": "Update",
  "store.customer.group.ListEmpty": "You have no Group",
  "store.customer.group.addList": "Add Group",
  "store.customer.group.List": "Group List",
  "store.customer.group.List.name": "Name",
  "store.customer.group.List.numberClients": "Number of clients",
  "store.customer.group.List.customerFirstName": "Customer's First Name",
  "store.customer.group.List.customerLastName": "Customer's Last Name",
  "store.customer.group.List.action": "Actions",
  "store.customer.group.List.action.edit": "Edit",
  "store.customer.group.List.action.delete": "Delete",
  "store.customer.group.modifyGroup": "Edit Group",
  "store.customer.addGroupClient.formCreateGroup": "Create a Group",
  "store.customer.group.deleteGroup": "Delete Group",
  "store.customer.group.deleteGroup.message":
    "Are you sure you want to delete ",
  "store.customer.newsletter": "Newsletter",
  "store.customer.newsletter.object": "Object",
  "store.customer.newsletter.message": "Message",
  "store.customer.newsletter.sentTo": "Send to",
  "store.customer.newsletter.email": "E-mail",
  "store.customer.newsletter.send": "Send",
  "store.customer.newsletter.AddEmail": "Add E-mail",
  "store.customer.deleteAddress": "Deleting Address",
  "store.customer.deleteAddress.message":
    "Are you sure you want to delete the address",
  "store.customersList.MakePrimary": "Make it principal",
  "store.customer.deleteClient": "Deleting Customer",
  "store.customer.deleteClient.message":
    "Are you sure you want to delete customer",
  // Store PromoCode
  "Store.PromoCode.delete": "Kill Promo Code",
  "Store.PromoCode.delete.message": "Are you sure you want to kill the promo",
  "Store.ProductManagment": "Product Managment",
  "Store.ProductManagment.Promo": "Promo Code",
  "Store.PromoCode.AddPromo": "Add Promo Code",
  "Store.PromoCode.list": "Promo Code list",
  "Store.PromoCode.promoCode": "Promo Code",
  "Store.PromoCode.Type": "Promo Type",
  "Store.PromoCode.action": "Actions",
  "Store.PromoCode.kill": "Kill Promo",
  "Store.PromoCode.AddPromo.Generate": "Add New Promo Code",
  "Store.PromoCode.Generate": "Generate",
  "Store.PromoCode.type.percentage": "Percentage",
  "Store.PromoCode.type.fixed": "Fixed Price",
  "Store.PromoCode.type.freeShip": "Free Shipping",
  "Store.PromoCode.AddPromo.applied": "Applied to",
  "Store.PromoCode.applied.order": "Order",
  "Store.PromoCode.applied.collection": "Collections",
  "Store.PromoCode.applied.categorie": "Categories",
  "Store.PromoCode.applied.product": "Products",
  "Store.PromoCode.AddPromo.available": "Available to",
  "Store.PromoCode.available.allusers": "All Users",
  "Store.PromoCode.available.specCustomer": "Specific customers",
  "Store.PromoCode.available.newsletterSub": "Newsletter Subscriber",
  "Store.PromoCode.available.specGroup": "Specific Group",
  "Store.PromoCode.AddPromo.MinReq": "Minimum Requirements",
  "Store.PromoCode.AddPromo.MinReq.allusers": "All Users",
  "Store.PromoCode.AddPromo.MinReq.MinPurAmmount": "Minimum Purchase amount",
  "Store.PromoCode.AddPromo.MinReq.MinQuanItems": "Minimum Quantity of items",
  "Store.PromoCode.AddPromo.MinReq.MinVerOrdPurchase":
    "Minimum Verified Orders purchase",
  "Store.PromoCode.AddPromo.MinReq.MinVerOrdAmmount":
    "Minimum Verified Orders amount",
  "Store.PromoCode.AddPromo.limit": "Limits",
  "Store.PromoCode.AddPromo.limit.NbTimesUsed": "Number of times used",
  "Store.PromoCode.AddPromo.limit.oneUse": "Limit to one use by customer",
  "Store.PromoCode.AddPromo.limit.startDate": "Start date",
  "Store.PromoCode.AddPromo.limit.endDate": "End date",
  "Store.promocode.error.PromoCode": "Promo Code is missing! ",
  "Store.promocode.error.percentage": "Percentage Type needs a value!",
  "Store.promocode.error.fixedPrice": "Fixed Type needs a value!",
  "Store.PromoCode.listEmpty": "You don't have any promo code",
  // updateMenu
  "updateMenu.new": "New menu",
  "updateMenu.orderSettings": "Order settings",
  "updateMenu.internalOrder": "Accept in-shop orders",
  "updateMenu.onlineOrder": "Accepte online orders",
  "updateMenu.deliverySettings": "Delivery cost settings",
  "updateMenu.deliveryCost": "or specify delivery cost",
  "updateMenu.deliveryPlaceholder": "Amount",
  // fomat
  "format.error": "Check format!",
  "format.alphaneumeric": "Alphaneumeical caracters",
  "format.specialCaracters": "No special characters",
  // Modal Edit Order
  KILOGRAM: "kilo",
  METER: "metre",
  GRAM: "100 gram",
  PIECE: "piece",

  // Array Options Variants
  "arrayOptionsVariants.varaiant": "Variant",
  "arrayOptionsVariants.picture": "Picture",
  "arrayOptionsVariants.quantity": "Quantity",
  "arrayOptionsVariants.barcode": "Barcode",
  "arrayOptionsVariants.buyingPrice": "Buying price",
  "arrayOptionsVariants.initialPrice": "Initial price",
  "arrayOptionsVariants.balance": "Balance",

  // Collection
  "collection.title": "Collection",
  "collection.listCollection": "List of collections",
  "collection.createCollection": "Creating a collection",
  "appearance.addCollection": "Add collection",
  "appearance.listEmpty": "You don't have any collection",
  "appearance.seeResult": "See result",
  "appearance.save": "Save",

  // gift card
  "giftCard.create": "Create a Gift Card",
  "giftCard.cardName": "Gift Card Name",
  "giftCard.customers": "Customers List",
  "giftCard.groups": "Groups List",
  "giftCard.cardValue": "Value",
  "giftCard.error": "Please Fill in the card name to proceed!",
  "giftCard.success": "Your gift card has been successfuly created!",
  "giftCard.list": "Gift cards' list",
  "giftCard.emptyDescription": "You have no gift cards at the moment!",
  "giftCard.newCard": "Create a gift card",
  "giftCard.info": "Gift card informations",

  // Main Menu
  "menu.products": "Products",
  "menu.inventory": "Inventory",
  "menu.category": "Categories",
  "menu.appearance": "Appearence",
  "menu.pages": "Pages",
  "menu.theme": "Theme",
  "menu.orders": "Orders",
  "menu.history": "History",
  "menu.marketing": "Marketing",
  "menu.giftCards": "Gift Cards",
  "menu.promoCodes": "Promo Codes",
  "menu.promotions": "Promotions",
  "menu.customers": "Customers",
  "menu.groups": "Groups",
  "menu.newsletter": "NewsLetter",
  "menu.plugins": "Plugins",
  "menu.settings": "Settings",
  "menu.address": "Address",
  "menu.billing": "Billing",
  "menu.dictionary": "Dictionary",
  "menu.collections": "Collections",
  // Inventory
  "inventory.title": "Inventory",
  "inventory.list": "List of inventories",
  "inventory.new": "New inventory",
  "inventory.addresses": "Addresses",
  "inventory.collectionName": "Collection name",
  "inventory.Variation": "Variation",
  "inventory.Quantity": "Quantity",
  "inventory.sellingPrice": "Selling price",
  "inventory.Action": "Action quantity",
  "inventory.lessThanOrEqual": "Price less than or equal",
  "inventory.greaterThanOrEqual": "Price greater than or equal",

  "inventory.productInformation": "Product information",
  "inventory.productLabel": "Product label",
  "inventory.Address": "Address",
  "inventory.buyingPrice": "Buying price",
  "inventory.add": "Add",
  "inventory.price": "Price",
  "inventory.save": "Save",
  // ErrorMessage
  "ErrorMessage.Message": "Something wrong has happened!",
  "ErrorMessage.refresh": "Refresh",

  // General Product
  "generalProduct.baseImage": "Base image",
  "generalProduct.baseImageDescription": "Upload image",
  "generalProduct.gallery": "Gallery",
  "generalProduct.galleryDescription": "Add Image to gallery",
  "generalProduct.identifier": "Product name in url",
  "generalProduct.productName": "Product name",
  "generalProduct.TypeOfProduct": "Type of product",
  "generalProduct.physicalProduct": "Physical product",
  "generalProduct.digitalProduct": "Digital product",
  "generalProduct.managedBy": "Managed by",
  "generalProduct.piece": "Piece",
  "generalProduct.gram": "Gram",
  "generalProduct.kiloGram": "Kilogram",
  "generalProduct.meter": "Meter",
  "generalProduct.description": "Description",
  "generalProduct.vendor": "Vendor",
  "generalProduct.tag": "Tag",
  "generalProduct.titleListCategory": "Assign this product to a categorie",
  "generalProduct.categoryList": "Category list",

  // Option Product
  "optionProduct.title": "Option list",
  "optionProduct.createOption": "Create Option",
  "optionProduct.info1":
    "Product Option is used for products that has different caracteristics. ",
  "optionProduct.info2":
    "Size and color are common Examples of product options. ",
  "optionProduct.info3":
    "Options can be used with variation to create a more a more detailed product and offer more diversity for your customers.",
  "optionProduct.name": "Name",
  "optionProduct.values": "Values",
  "optionProduct.edit": "Edit",
  "optionProduct.delete": "Delete",
  "optionProduct.listOptionEmpty": "There is no created options",
  "optionProduct.modalDeleteTitle": "Delete option ?",
  "optionProduct.modalDeleteDescription":
    "Are you sure you want to remove this option ?",
  // payment
  "payment.tite": "Payment",
  // translate
  "translate.title": "Translations",
  // Subscriptions
  "subscriptions.bundlesManagement": "Bundles Management - ",
  "subscriptions.subscription": "Subscription",
  "subscriptions.newSubscription": "Add new subscription",
  "subscriptions.subscriptionTitle": "Subscription Title",
  "subscriptions.duration": "Duration",
  "subscriptions.price": "Price",
  "subscriptions.productLimit": "Product Limit",
  "subscriptions.status": "Status",
  "subscriptions.form.return": "Return",
  "subscriptions.form.create": "Create new subscription",
  "subscriptions.form.save": "Save",
  "subscriptions.form.discard": "Discard",
  "subscriptions.form.title": "Subscription title",
  "subscriptions.form.description": "Subscription description",
  "subscriptions.form.duration": "Subscription duration management",
  "subscriptions.form.price": "Subscription price",
  "subscriptions.form.limitMessage": "Limit subscriptions product purchase",
  "subscriptions.form.limit": "Product quantity limit",
};
