import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Tabs, Tab, Box } from "@material-ui/core";
import _ from "lodash";

import { Wrapper, Seperator } from "./TabLanguageStyle";
import { Input } from "../../commons";
import { getLanguage } from "../../utils";
import {
  MenuLanguagePayload,
  UpdateNameInput,
  UpdateDescriptionInput,
  LanguageCode,
  NamePayload,
  DescriptionPayload,
} from "../../api/types";

interface TabLanguageProps {
  languages?: MenuLanguagePayload[] | null;
  title?: string;
  onReturn: (
    listName: UpdateNameInput[],
    listDesc: UpdateDescriptionInput[],
    listDescLong: UpdateDescriptionInput[]
  ) => void;
  NameInput?: NamePayload[] | null;
  shortDescriptionsInput?: DescriptionPayload[] | null;
  longDescriptionsInput?: DescriptionPayload[] | null;
}

interface TabPanelProps {
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        background: "#fff",
        border: "2px solid #F3F4FA",
        borderRadius: 10,
      }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabLanguage: React.FC<TabLanguageProps> = ({
  title,
  languages,
  onReturn,
  NameInput,
  shortDescriptionsInput,
  longDescriptionsInput,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(0);

  const [nameInput, setNameInput] = useState<UpdateNameInput[]>([]);
  const [descriptionInput, setDescriptionInput] = useState<
    UpdateDescriptionInput[]
  >([]);
  const [descriptionLongInput, setDescriptionLongInput] = useState<
    UpdateDescriptionInput[]
  >([]);
  // eslint-disable-next-line
  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(_.findIndex(languages, (o) => o.isMain === true) || 0);

    const ArrayName: UpdateNameInput[] = [];
    const ArrayDescription: UpdateDescriptionInput[] = [];
    const ArrayDescriptionLong: UpdateDescriptionInput[] = [];

    languages?.forEach((language) => {
      const valueName = _.find(
        NameInput,
        (o) => o.languageCode === (language.language?.code as LanguageCode)
      );

      const valueDescription = _.find(
        shortDescriptionsInput,
        (o) => o.languageCode === (language.language?.code as LanguageCode)
      );

      const valueLongDescription = _.find(
        longDescriptionsInput,
        (o) => o.languageCode === (language.language?.code as LanguageCode)
      );

      ArrayName.push({
        languageCode: language.language?.code as LanguageCode,
        value: valueName?.value || null,
      });
      ArrayDescription.push({
        languageCode: language.language?.code as LanguageCode,
        value: valueDescription?.value || null,
      });
      ArrayDescriptionLong.push({
        languageCode: language.language?.code as LanguageCode,
        value: valueLongDescription?.value || null,
      });
    });
    setNameInput(ArrayName);
    setDescriptionInput(ArrayDescription);
    setDescriptionLongInput(ArrayDescriptionLong);
  }, [languages, NameInput, shortDescriptionsInput, longDescriptionsInput]);

  const handleReturn = () => {
    onReturn(nameInput, descriptionInput, descriptionLongInput);
  };

  return (
    <Wrapper>
      <Tabs value={value} textColor="primary" onChange={handleChange}>
        {languages?.map((language, index) => (
          <Tab
            key={language.language?.id || index}
            label={getLanguage(language?.language?.code || "")}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {languages?.map((language, index) => (
        <TabPanel
          key={language?.language?.id || index}
          value={value}
          index={index}
        >
          <h3>{title}</h3>
          <Input
            value={nameInput?.[index]?.value || ""}
            label={intl.formatMessage({ id: "LanguageTable.fields.name" })}
            style={{ maxWidth: 420 }}
            onChange={(e) => {
              e.persist();
              setNameInput((prevName) => {
                const arrayName = [...prevName];
                arrayName[index].value = e.target.value;
                return arrayName;
              });
              handleReturn();
            }}
          />
          <Seperator />
          <Input
            value={descriptionInput?.[index]?.value || ""}
            label={intl.formatMessage({
              id: "LanguageTable.fields.description",
            })}
            onChange={(e) => {
              e.persist();
              setDescriptionInput((prevDescription) => {
                const arrayDescription = [...prevDescription];
                arrayDescription[index].value = e.target.value;
                return arrayDescription;
              });
              handleReturn();
            }}
            rows={2}
            multiline
          />
          <Seperator />
          <Input
            value={descriptionLongInput?.[index]?.value || ""}
            label={intl.formatMessage({
              id: "LanguageTable.fields.longDescription",
            })}
            onChange={(e) => {
              e.persist();
              setDescriptionLongInput((prevDescriptionLong) => {
                const arrayDescriptionLong = [...prevDescriptionLong];
                arrayDescriptionLong[index].value = e.target.value;
                return arrayDescriptionLong;
              });
              handleReturn();
            }}
            rows={3}
            multiline
          />
        </TabPanel>
      ))}
    </Wrapper>
  );
};

export default TabLanguage;
