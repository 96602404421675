import { EN, FR, AR, ZH, DE, IT, JA, PT, ES, TR } from "../img";

export const getLanguage = (language: string) => {
  switch (language) {
    case "EN":
      return "English";
    case "FR":
      return "Français";
    case "AR":
      return "العربية";
    case "DE":
      return "Deutsche";
    case "IT":
      return "Italiano";
    case "PT":
      return "Português";
    case "TR":
      return "Türk";
    case "ES":
      return "Española";
    case "JA":
      return "日本人";
    case "ZH":
      return "中文";
    default:
      return "";
  }
};

export const getFlag = (language: string) => {
  switch (language) {
    case "EN":
      return EN;
    case "FR":
      return FR;
    case "AR":
      return AR;
    case "DE":
      return DE;
    case "IT":
      return IT;
    case "PT":
      return PT;
    case "TR":
      return TR;
    case "ES":
      return ES;
    case "JA":
      return JA;
    case "ZH":
      return ZH;
    default:
      return "";
  }
};
