import styled from "styled-components";

export const Seperator = styled.div`
  width: 100%;
  height: 20px;
`;

export const Wrapper = styled.div`
  background-color: #f8f8ff;
  color: #444444;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  h3 {
    margin: 0 0 20px 0;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .MuiTabs-root {
    min-height: unset;
    .MuiTabs-fixed {
      display: flex;
      min-height: 38px;
      justify-content: flex-end;
      .MuiTab-textColorPrimary {
        top: 7px;
        min-width: 100px;
        height: 38px;
        background: #fff;
        color: #c4c4c4;
        margin: 6px 5px 0 5px;
        border-width: 2px 2px 0 2px;
        border-style: solid;
        border-color: #f3f4fa;
        border-radius: 15px 15px 0 0;
        text-transform: capitalize;
        font-weight: bold;
      }
    }
    .MuiTab-root {
      min-height: unset;
    }
    .MuiTab-textColorPrimary.Mui-selected {
      top: 0;
      color: #444444;
      height: 45px;
      font-weight: bold;
    }
    .MuiTabs-indicator {
      background-color: unset;
    }
  }
`;
