import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    display: flex;
    font-size: 24px;
    font-weight: 300;
    color: #444444;
    img {
      width: 40px;
      margin-right: 15px;
    }
    span {
      font-weight: 600;
    }
  }
  .action {
    display: flex;
    width: 250px;
    justify-content: space-between;
    .btn-action {
      .MuiButton-label {
        font-size: 16px;
        text-transform: capitalize;
      }
    }
    a {
      text-decoration: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .container-1 {
    margin-bottom: 10px;
    min-height: 640px;
    transition: min-height 0.3s linear;
    .header {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 20px;
        color: #444444;
      }
      .arrow {
        height: 2px;
        width: 2px;
        border: solid #444444;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 2px;
        cursor: pointer;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transition: transform 0.3s linear;
      }
      .down {
        transform: rotate(135deg);
        -webkit-transform: rotate(45deg);
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        gap: 15px;

        margin-top: 30px;
      }
      .row__items {
        display: flex;
        gap: 15px;
        margin-top: 30px;
        .name {
          display: flex;
          min-width: 200px;
          padding-top: 5px;
          .img-option {
            margin-top: 5px;
            width: auto;
            height: 35px;
            img {
              width: 25px;
              object-fit: contain;
            }
          }
          span {
            margin-left: 10px;
            font-size: 16px;
            color: #444444;
          }
        }
        .items {
          display: flex;
          /* min-width: 700px; */
          flex: 1;
          max-width: 900px;
          .item-1 {
            display: flex;
            width: 75px;
            height: 100%;
            justify-content: space-between;
            align-items: flex-start;
            .checkbox-1 {
              height: 100%;
              .dashed {
                width: 0;
                height: 0;
                transition: height 0.3s, width 0.3s;
                transition-delay: 0.2s;
                border-left: 1px dashed #bdbdbd;
                border-bottom: 1px dashed #bdbdbd;
                margin-left: 50%;
              }
              .dashed-show {
                width: 20px;
                height: calc(100% - 62px);
              }
            }
            .checkbox-2 {
              display: flex;
              height: 100%;
              align-items: flex-end;
              visibility: visible;
              transition: visibility 0.1s;
              transition-delay: 0.3s;
            }
            .checkbox-2-hidden {
              visibility: hidden;
            }
          }
          .item-2 {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            .input-tag-1 {
              width: calc(100% - 122px);
              margin-left: -20px;
            }
            .input-tag-2 {
              display: flex;
              min-width: 660px;
              min-height: 40px;
              margin-left: 10px;
              visibility: visible;
              transition: visibility 0.2s, min-height 0.3s, margin-top 0.3s,
                margin-bottom 0.3s linear;
              transition-delay: 0.2s;
              margin-top: 35px;
              margin-bottom: 0;
              .input-tag__span {
                display: flex;
                align-items: flex-end;
                color: #444444;
                font-size: 14px;
                padding-bottom: 8px;
              }
            }
            .input-tag-2-hidden {
              min-height: -10px;
              visibility: hidden;
              margin-top: 0;
              margin-bottom: -30px;
            }
          }
          .calendar {
            display: flex;
            width: calc(100% - 85px);
            gap: 15px;
            margin-left: 10px;
            .MuiInputBase-root {
              padding: 2px 12px 1px;
            }
            .MuiIconButton-root {
              top: 0;
              right: -10px;
            }
          }
        }
      }
      .add-variantion {
        display: flex;
        margin-left: 217px;
        align-items: center;
        /* margin-top: 5px; */
        font-size: 14px;
        .MuiFab-root {
          width: 35px;
          height: 25px;
          margin-right: 15px;
        }
      }
    }
  }
  .container-1__hidden {
    min-height: 80px;
    /* transition: min-height 0.1s linear; */
  }

  .options {
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 20px;
      color: #444444;
      font-style: normal;
      font-weight: normal;
      margin: 0;
    }
    .items-option {
      display: flex;
      .item-input {
        display: flex;
        flex: 1;
        flex-direction: column;
        span {
          width: 200px;
          font-size: 16px;
          color: #444444;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        padding-right: 30px;
      }
      .item-sort {
        display: flex;
        flex: 1;
        flex-direction: column;

        span {
          font-size: 16px;
          color: #444444;
        }
        .sort-by {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .arrow {
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            border: 0.5px solid #444444;
            border-radius: 50%;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
              color: #ffffff;
              background: #d4d4d4;
            }
          }
          .arrow-selected {
            color: #ffffff;
            background: #f29f05;
            &:hover {
              color: #ffffff;
              background: #f29f05;
            }
          }
          span {
            font-size: 14px;
          }
        }
      }
      .item-select {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 10px;
        span {
          font-size: 14px;
        }
      }
      .item-select > span {
        margin-left: 12px;
        font-size: 16px;
        color: #444444;
      }
    }
  }
  .MuiSelect {
    padding: 6px 12px 10px;
  }
  .MuiSelect-select.Mui-disabled {
    background-color: rgba(255, 255, 255, 0.04);
    &:hover {
      background: #ffffff;
    }
  }
  .input-simple {
    .MuiInputBase-input.Mui-disabled {
      background-color: rgb(103 100 100 / 18%);
      &:hover {
        background: #ffffff;
      }
    }
  }
`;
