import React from "react";

import { Box, Typography } from "@material-ui/core";

import { URL_THEME_PICTURES } from "../../api";
import { CartItemPayload } from "../../api/types";

import { Wrapper, ColorInput } from "./OrderProductDetailsItem--styles";

type OrderProductItemType = {
  data?: CartItemPayload;
};

const OrderProductDetailsItem: React.FC<OrderProductItemType> = ({ data }) => {
  return (
    <Wrapper>
      <Box display="flex" alignItems="center" width="40px">
        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
          {data?.quantity} X
        </Typography>
      </Box>
      {data?.product?.picture?.fileUrl && (
        <img
          src={URL_THEME_PICTURES?.concat(data.product.picture.fileUrl)}
          alt="product"
        />
      )}
      <Box flex="1" marginLeft="15px">
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 700, marginBottom: "5px" }}
        >
          {data?.product?.names?.[0]?.value}
        </Typography>
        {data?.options?.map((option) => (
          <Box display="flex">
            <Typography variant="subtitle2" style={{ color: "#AFB1C6" }}>
              {option?.option?.names?.[0]?.value} &nbsp;:
            </Typography>
            <Typography variant="subtitle2" style={{ marginLeft: 5 }}>
              {option?.value?.names?.[0]?.value?.indexOf("#") === -1 ? (
                option?.value?.names?.[0]?.value
              ) : (
                <ColorInput color={option?.value?.names?.[0]?.value || ""} />
              )}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
        {data?.totalPrice?.value} {data?.totalPrice?.currency?.code}
      </Typography>
    </Wrapper>
  );
};

export default OrderProductDetailsItem;
