// Styled component import
import styled from "styled-components";

// Types

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    display: grid;
    grid-template-columns: 1fr 190px 180px 40px 40px;
    grid-column-gap: 15px;
    align-items: center;
  }
  .sub-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 15px;

    .arrow-down {
      position: absolute;
      width: 25px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      svg {
        stroke-width: 2px;
        path {
          fill: #ffffff;
        }
      }
    }
    .payment {
      background-color: #f6c104;
    }
    .fulfillment {
      background-color: #4a4a4a;
    }

    .title-product {
      display: flex;
      height: 35px;
      align-items: center;
      margin-top: 5px;
    }
  }
  button.expand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: auto;
    border: 0;
    background-color: white;
    border-radius: 20px;
    color: #bdbdbd;
    cursor: pointer;
    :hover {
      background-color: #f1f2f5;
    }
  }
  .card-menu {
    position: relative;
    margin: auto;
  }
  .order-content {
    display: block;
    /* padding-top: 15px; */
    .pictures-products {
      display: flex;
      align-items: center;
      img {
        width: 31px;
        height: 31px;
        margin-right: 10px;
        border: 1px solid #e6e7ea;
        border-radius: 5px;
        object-fit: cover;
      }
      & > div {
        display: flex;
        width: 31px;
        height: 31px;
        align-items: center;
        justify-content: center;
        background-color: #cacbce;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        border-radius: 5px;
      }
    }
  }
  a.email-link {
    color: #03a9f4;
    margin-left: 5px;
  }
  span.order-price {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 0 13px;
    width: fit-content;
    min-width: 120px;
    height: 35px;
    border-radius: 5px;
    font-size: 14px;
  }
  .customer-information,
  .product-information {
    margin-top: 16px;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
`;
