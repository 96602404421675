import * as React from "react";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import Select from "./Select";
import Input from "./Input";

type SearchProps = {
  value?: string;
  listModeSearch?: string[];
  onReturnSearch: (value: string, mode: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
};

const Search: React.FC<SearchProps> = ({
  value,
  listModeSearch,
  onReturnSearch,
  placeholder,
  style,
}) => {
  const [mode, setMode] = React.useState("name");
  const [state, setState] = React.useState<string>("");
  React.useEffect(() => {
    if (value) setState(value);
  }, [value]);
  return (
    <SearchStyle style={style}>
      {listModeSearch && listModeSearch.length > 0 && (
        <div style={{ width: 200 }}>
          <Select
            value={mode}
            onChange={(e) => setMode(e.target.value as string)}
          >
            {listModeSearch?.map((item) => (
              <MenuItem
                key={item}
                value={item}
                style={{ textTransform: "capitalize" }}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className="input-search">
        <Input
          value={state}
          onChange={(e) => setState(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") onReturnSearch(state, mode);
          }}
          placeholder={placeholder}
        />
      </div>
    </SearchStyle>
  );
};

export default Search;

export const SearchStyle = styled.div`
  display: flex;
  margin: 10px 0;
  .MuiSelect {
    border: 1px solid #d2d9e1;
    border-right: none;
    padding: 10px 12px 10px;
    border-radius: 5px 0 0 5px;
    text-transform: capitalize;
    &:focus-within {
      border: 1px solid #d2d9e1;
      border-right: none;
      border-radius: 5px 0 0 5px;
    }
  }
  .input-search {
    width: 100%;
    .MuiInputBase-root {
      border: 1px solid #d2d9e1;
      border-radius: 0 5px 5px 0;
    }
    .mainInput {
      padding: 10px 12px 10px;
      border-radius: 0 5px 5px 0;
    }
  }
`;
