import styled from "styled-components";
import { Dialog, DialogActions } from "@material-ui/core";

export const Wrapper = styled.div`
  .MuiDialog-paperWidthSm {
    max-width: 750px;
  }
`;

export const RegisterDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 750px;
  }
  .MuiFormControl-root {
    margin-bottom: 0 !important;
  }
  .MuiDialogContent-root {
    padding: 25px;
  }
`;

export const Actions = styled(DialogActions)`
  padding: 15px 25px 15px 25px !important;
  justify-content: space-between !important;
  border-top: 2px solid #f1f2fb;
`;

export const Tab = styled.div`
  .tab-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    margin: 20px 100px;
  }
  hr.seperator {
    width: 100%;
    height: 1px;
    background-color: #bdbdbd;
    border: 0;
    margin: 48.5px auto auto auto;
  }
  div.tab-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    svg {
      margin: auto;
    }
    label {
      margin-top: 15px;
      text-align: center;
      line-height: 18px;
    }
  }
  div.sub-tab-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-top: 50px;
  }
  div.sub-tab-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 20px;
    margin-top: 50px;
  }
  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
    .MuiFilledInput-root:focus-within {
      border: 2px solid #74abff;
    }
    .MuiFilledInput-root:hover {
      background-color: rgba(0, 0, 0, 0);
    }
    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-fullWidth.MuiInputBase-formControl {
      background: #ffffff;
    }
    .show {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: #ffffff;
  }
  .MuiInputBase-root.Mui-disabled {
    color: black;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #757575;
  }
  .title {
    grid-column: span 3;
  }
  .sp2 {
    grid-column: span 2;
  }
  .sp3 {
    grid-column: span 3;
  }
  hr.fill {
    background-color: #444444;
  }
  div.phone-input {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 25px;
  }
  div.link {
    display: grid;
    grid-template-columns: 1fr 55px;
    grid-column-gap: 25px;
  }
  .socialLink {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    margin-top: 15px;
    padding: 5px;
    background-color: ghostwhite;
    border-radius: 8px;
    height: 39px;
    grid-column-gap: 15px;
    img {
      width: 60%;
      margin: auto;
    }
    p {
      margin: auto 0;
    }
    button.remove-social {
      cursor: pointer;
      background-color: transparent;
      outline: none;
      border: 0;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
`;
