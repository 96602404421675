import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import { Wrapper } from "./Address--styles";
import { ListDataEmpty, AddressUpdateModal } from "../../components";
import { Modal, BreadCrumbsTitle, ButtonCustomize } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import { GETLISTCOMPANYSITES, DELETECOMPANYSITES } from "../../api";
import {
  GetListCompanySitesQuery,
  GetListCompanySitesQueryVariables,
  DeleteCompanySiteMutation,
  DeleteCompanySiteMutationVariables,
  CompanySitePayload,
} from "../../api/types";
import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../api/local-state";

import { address } from "../../img";

const Address: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();

  const [addressToEdit, setAddressToEdit] = useState<CompanySitePayload | null>(
    null
  );
  const [addressToDelete, setAddressToDelete] =
    useState<CompanySitePayload | null>(null);

  // GET SELECTED MENU FROM LOCAL STATE
  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);
  const menuId = localMenu?.menuItem?.id || "";

  // Query Get List Cities
  const { data: listCompanySite } = useQuery<
    GetListCompanySitesQuery,
    GetListCompanySitesQueryVariables
  >(GETLISTCOMPANYSITES, {
    skip: !menuId,
    variables: {
      token,
      menu: menuId,
    },
    fetchPolicy: "network-only",
  });
  const AddressList = listCompanySite?.getListCompanySites?.list;
  // Mutation delete company site
  const [deleteCompany, { loading: loadingDelete }] = useMutation<
    DeleteCompanySiteMutation,
    DeleteCompanySiteMutationVariables
  >(DELETECOMPANYSITES, {
    variables: {
      id: addressToDelete?.id || "",
      token: localStorage.getItem("token") || "",
    },
    refetchQueries: () => [
      {
        query: GETLISTCOMPANYSITES,
        variables: {
          token: localStorage.getItem("token") || "",
          menu: menuId,
        },
      },
    ],
    onCompleted: () => {
      setAddressToDelete(null);
    },
  });

  const columns: ColumnsProps = [
    {
      header: intl.formatMessage({ id: "Store.settings.name" }),
      accessor: "name",
    },
    {
      header: intl.formatMessage({ id: "Store.settings.address" }),
      accessor: "address",
    },
    {
      header: intl.formatMessage({ id: "Store.settings.country" }),
      accessor: "country",
    },
    {
      header: intl.formatMessage({ id: "Store.settings.city" }),
      accessor: "city",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];
  const renderTableRows = () => {
    return (
      AddressList?.map((addressItem) => ({
        id: addressItem?.id,
        name: addressItem?.identifier,
        address: addressItem.address?.roadNames?.[0]?.value || "",
        country: addressItem?.address?.country?.name || "",
        city: addressItem?.address?.city?.name || "",
        actions: (
          <>
            <IconButton
              size="small"
              color="primary"
              style={{ color: "#444444", margin: "0 4px" }}
              onClick={(e) => {
                e.stopPropagation();
                setAddressToEdit(addressItem);
              }}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              style={{ color: "#ff4949", margin: "0 4px" }}
              onClick={(e) => {
                e.stopPropagation();
                setAddressToDelete(addressItem);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      })) || []
    );
  };

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <BreadCrumbsTitle
          labelIntl={["Store.settings", "Store.settings.addresses"]}
          icon={address}
          iconAlt="Customers"
        />

        {AddressList && AddressList.length > 0 && (
          <Link to="/setting-add-address">
            <ButtonCustomize variant="contained" color="primary" size="large">
              {intl.formatMessage({ id: "Store.settings.newAddress" })}
            </ButtonCustomize>
          </Link>
        )}
      </Box>
      {AddressList && AddressList.length > 0 && (
        <Box component={Paper} p={2} className="container">
          <Typography variant="h2" className="address__list-title">
            {intl.formatMessage({ id: "Store.settings.addresses" })}
          </Typography>
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="Assign or add your first product"
            onClickRow={(row) => {
              if (row.id) {
                const selectedAddress = AddressList?.filter(
                  (addressItem) => addressItem.id === row.id
                )?.[0];
                selectedAddress && setAddressToEdit(selectedAddress);
              }
            }}
          />
        </Box>
      )}
      {/* if list appearance is empty */}
      {AddressList && AddressList.length === 0 && (
        <ListDataEmpty
          image={address}
          description={intl.formatMessage({
            id: "Store.settings.addressesListEmpty",
          })}
          btnName={intl.formatMessage({ id: "Store.settings.addAdress" })}
          url="/setting-add-address"
        />
      )}
      <AddressUpdateModal
        open={Boolean(addressToEdit)}
        handleClose={() => setAddressToEdit(null)}
        dataCompanySite={addressToEdit}
      />
      {addressToDelete && (
        <Modal
          open={Boolean(addressToDelete)}
          title={intl.formatMessage({ id: "Store.modalTitleDelete" })}
          message={intl.formatMessage({ id: "Store.modalMessageDelete" })}
          handleClose={() => setAddressToDelete(null)}
          handleContent={() => deleteCompany()}
          action="delete"
          loading={loadingDelete}
        />
      )}
    </Wrapper>
  );
};

export default Address;
