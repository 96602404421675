import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .back {
    color: #0052b4;
    width: 100px;
    svg {
      margin-right: 5px;
    }
  }
  .description {
    max-width: 900px;
    margin-top: 10px;
  }
  .wrapper-class {
    min-height: 350px;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #d2d9e1;
    border-radius: 8px;
  }
  .rdw-editor-main {
    height: calc(100% - 50px);
  }
  .toolbar-class {
    border: none;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .public-DraftStyleDefault-ltr {
    padding-right: 10px;
    padding-left: 10px;
  }
  .public-DraftEditorPlaceholder-root {
    padding-right: 10px;
    padding-left: 10px;
  }
  .rdw-option-wrapper {
    border: none;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #f29f05;
    border-radius: 5px;
  }
  .rdw-dropdown-wrapper {
    border: none;
  }
`;
