import { FormMemberStats } from "./StaffMemberFormModal";

export type Error = {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  password?: string;
  confirmPassword?: string;
};

export const ValidationForm = (values: FormMemberStats): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "firstName":
        if (!values.firstName) {
          errors.firstName = "This field is required";
        } else if (!values.firstName.match(/^[a-zA-Z ]+$/)) {
          errors.firstName = "must only accept letters";
        }
        break;
      case "lastName":
        if (!values.lastName) {
          errors.lastName = "This field is required";
        } else if (!values.lastName.match(/^[a-zA-Z ]+$/)) {
          errors.lastName = "must only accept letters";
        }
        break;
      case "email":
        if (!values.email) {
          errors.email = "This field is required";
        } else if (
          !values.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          errors.email = "Invalid Email";
        }
        break;
      case "role":
        if (!values.role) {
          errors.role = "This field is required";
        }
        break;
      case "password":
        if (!values.password) {
          errors.password = "This field is required";
        } else if (
          !values.password.match(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
          )
        ) {
          errors.password =
            "must contain at least one capital letter, lower case letters, and numbers with total maxlength=8";
        }
        break;
      case "confirmPassword":
        if (!values.confirmPassword) {
          errors.confirmPassword = "This field is required";
        } else if (values.confirmPassword !== values.password) {
          errors.confirmPassword = "must match the previous password field";
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
