// Libraries Imports
import React, { FC } from "react";
import styled from "styled-components";

// Types
type DropDownItemType = {
  danger?: boolean;
  onClick: () => void;
};

const DropDownItem: FC<DropDownItemType> = ({ danger, onClick, children }) => {
  return (
    <Wrapper danger={danger} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<DropDownItemType>`
  display: flex;
  align-items: center;
  padding: 6px;
  height: 40px;
  width: calc(100% - 12px);
  min-width: 120px;
  border-radius: 5px;
  color: ${(props) => (props.danger ? "#F67070" : "#444444")};
  span {
    display: block;
    margin: 0 12px !important;
    font-size: 14px;
  }
  svg {
    margin: 0 0 0 9px !important;
  }
  svg path {
    fill: ${(props) => (props.danger ? "#F67070" : "#444444")} !important;
  }
  :hover {
    background-color: ${(props) => (props.danger ? "#FCF1F1" : "#f3f3f4")};
  }
`;

export default DropDownItem;
