import * as React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from "@apollo/client";

import { ButtonCustomize } from "../../commons";

import { GET_DTONE_LIST_SERVICES, GET_DTONE_LIST_PRODUCTS } from "../../api";
import {
  GetDtoneListServicesQuery,
  GetDtoneListServicesQueryVariables,
  GetDtoneListProductsQuery,
  GetDtoneListProductsQueryVariables,
  GetDtOneListProductsInput,
} from "../../api/types";

import { getDtoneListCountries } from "../../utils";

type AssignProductTypeModalProps = {
  open: boolean;
  onReturn: (id: string, name: string) => void;
  onClose: () => void;
};

const initialDtoneProduct = {
  serviceId: 0,
  countryIsoCode: "",
  page: 1,
  perPage: 100,
};

const AssignProductTypeModal = ({
  open,
  onReturn,
  onClose,
}: AssignProductTypeModalProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const [dtoneProduct, setDtoneProduct] =
    React.useState<GetDtOneListProductsInput>({
      ...initialDtoneProduct,
      token,
    });
  const [providerApiID, setProviderApiID] = React.useState("");
  const [providerApiName, setProviderApiName] = React.useState("");

  const { data: dataServices } = useQuery<
    GetDtoneListServicesQuery,
    GetDtoneListServicesQueryVariables
  >(GET_DTONE_LIST_SERVICES, {
    variables: {
      input: {
        token,
      },
    },
  });

  const listServices = dataServices?.getDtoneListServices?.list || [];

  const { data: dataProducts, loading } = useQuery<
    GetDtoneListProductsQuery,
    GetDtoneListProductsQueryVariables
  >(GET_DTONE_LIST_PRODUCTS, {
    variables: {
      input: {
        ...dtoneProduct,
      },
    },
    skip: !dtoneProduct.serviceId || !dtoneProduct?.countryIsoCode,
  });

  const listProducts = dataProducts?.getDtoneListProducts?.list || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Assign Product
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <Autocomplete
            id="DTOneService"
            options={listServices}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(event, value) =>
              value?.id &&
              setDtoneProduct({ ...dtoneProduct, serviceId: value.id })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="DTOne Service"
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
            )}
            style={{ marginBottom: 16 }}
          />

          <Autocomplete
            id="DTOneCountry"
            options={getDtoneListCountries}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(event, value) =>
              value?.isoCode &&
              setDtoneProduct({
                ...dtoneProduct,
                countryIsoCode: value?.isoCode,
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="DTOne Country"
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
            )}
            style={{ marginBottom: 16 }}
          />

          <Autocomplete
            id="DTOneProduct"
            options={listProducts}
            getOptionLabel={(option) =>
              `${option?.operatorName} - ${option.name}` || ""
            }
            onChange={(event, value) => {
              value?.id && setProviderApiID(String(value.id));
              value?.name && setProviderApiName(value.name);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="DTOne Product"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{ marginTop: -20 }}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            loading={loading}
            disabled={!dtoneProduct?.serviceId || !dtoneProduct?.countryIsoCode}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={2}
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={() => {
              onReturn(providerApiID, providerApiName);
              onClose();
              setDtoneProduct({ ...initialDtoneProduct, token });
            }}
            disabled={!providerApiID}
          >
            Assign
          </ButtonCustomize>
        </Box>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  min-width: 500px;
  flex-direction: column;
  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  .MuiFilledInput-underline:after {
    border-bottom: none;
  }
  .MuiInputBase-root {
    background-color: #ffffff;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus-within {
      border: 2px solid #000000;
      border-radius: 5px;
    }
  }
`;

export default AssignProductTypeModal;
