import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Paper, Typography, Tab, MenuItem } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useQuery, useReactiveVar } from "@apollo/client";

import {
  ListTranslateProduct,
  TranslateProductForm,
  ListTranslateCategory,
  TranslateCategoryForm,
  ListTranslateHomePage,
  FormTranslateHomePage,
  TranslateLegality,
  FormTranslateLegality,
} from "../../components";
import {
  BreadCrumbsTitle,
  TabsStyled,
  Select,
  ButtonCustomize,
} from "../../commons";
import { getLanguage, getFlag } from "../../utils";

import { GETLISTMENUS } from "../../api";
import {
  LanguageCode,
  LanguagePayload,
  GetListMenuQuery,
  GetListMenuQueryVariables,
  MenuPayload,
  TitlePayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import { Wrapper } from "./Translate-styles";

import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={2} paddingBottom={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Translate: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const [value, setValue] = React.useState(0);
  const [origineLanguage, setOrigineLanguage] =
    React.useState<LanguageCode>("EN");
  const [selectLanguage, setSelectLanguage] = React.useState<
    LanguageCode | undefined
  >(undefined);
  const [showTranslate, setShowTranslate] = React.useState<boolean>(false);

  const [idProduct, setIdProduct] = React.useState<string | undefined>(
    undefined
  );
  const [idCategory, setIdCategory] = React.useState<string | undefined>(
    undefined
  );

  const [titleHomePage, setTitleHomePage] = React.useState<
    TitlePayload[] | undefined
  >(undefined);

  const [identifierLegality, setIdentifierLegality] = React.useState<
    string | undefined
  >(undefined);

  const [listLanguages, setListLanguages] = React.useState<LanguagePayload[]>(
    []
  );

  const { data } = useQuery<GetListMenuQuery, GetListMenuQueryVariables>(
    GETLISTMENUS,
    {
      variables: {
        token,
      },
    }
  );

  React.useEffect(() => {
    const menu = data?.getListMenus?.list?.find(
      (item) => item?.id === localMenu?.id
    );
    const arrayLanguages: LanguagePayload[] = [];
    menu?.languages?.forEach((item) => {
      if (item.isMain) {
        setOrigineLanguage(item.language?.code as LanguageCode);
      } else {
        if (!selectLanguage) {
          setSelectLanguage(item.language?.code as LanguageCode);
        }
        arrayLanguages.push({
          id: item?.language?.id,
          code: item.language?.code,
        });
        setShowTranslate(true);
      }
    });
    setListLanguages(arrayLanguages);
  }, [data?.getListMenus?.list, localMenu]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "translate.title"]}
        icon={StoreSettings}
        iconAlt="translate"
      />

      <Box component={Paper} width={1} paddingRight={2} paddingLeft={2}>
        {showTranslate ? (
          <>
            <div className="head-tabs">
              <TabsStyled
                value={value}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleChange}
              >
                <Tab
                  label="Products"
                  {...a11yProps(0)}
                  onClick={() => setIdProduct(undefined)}
                />
                <Tab
                  label="Categorie"
                  {...a11yProps(1)}
                  onClick={() => setIdCategory(undefined)}
                />

                <Tab
                  label="Home page"
                  {...a11yProps(2)}
                  onClick={() => setTitleHomePage(undefined)}
                />

                <Tab
                  label="Legality"
                  {...a11yProps(3)}
                  onClick={() => setIdentifierLegality(undefined)}
                />
              </TabsStyled>
              <Box display="flex">
                <img
                  src={getFlag(origineLanguage)}
                  alt="flag"
                  style={{ width: 18, marginRight: 5 }}
                />
                <Typography variant="body1" style={{ margin: "auto 0" }}>
                  {getLanguage(origineLanguage)}
                </Typography>

                <NavigateNextIcon
                  style={{ color: "#C1C3D4", margin: "auto 8px" }}
                />

                <div style={{ width: 200 }}>
                  <Select
                    value={String(selectLanguage)}
                    onChange={(e) => {
                      setSelectLanguage(e.target.value as LanguageCode);
                    }}
                  >
                    {listLanguages?.map((item) => (
                      <MenuItem key={item?.id} value={item.code!}>
                        <Box display="flex" alignItems="center">
                          <img
                            src={getFlag(item?.code || "") || ""}
                            alt={item?.code || "flag"}
                            style={{ width: 18, marginRight: 5 }}
                          />
                          {getLanguage(item?.code || "")}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
            </div>
            <TabPanel value={value} index={0}>
              {!idProduct ? (
                <ListTranslateProduct
                  origineLangage={origineLanguage}
                  onIdProduct={(id) => setIdProduct(id)}
                />
              ) : (
                <TranslateProductForm
                  idProduct={idProduct}
                  language={selectLanguage}
                  origineLangage={origineLanguage}
                  onCleanIdProduct={() => setIdProduct(undefined)}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {!idCategory ? (
                <ListTranslateCategory
                  origineLangage={origineLanguage}
                  onIdCategory={(id) => setIdCategory(id)}
                />
              ) : (
                <TranslateCategoryForm
                  idCategory={idCategory}
                  language={selectLanguage}
                  origineLangage={origineLanguage}
                  onCleanIdCategory={(id) => setIdCategory(id)}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {!titleHomePage ? (
                <ListTranslateHomePage
                  origineLangage={origineLanguage}
                  onHomePage={(item) => setTitleHomePage(item)}
                />
              ) : (
                <FormTranslateHomePage
                  titleHomePage={titleHomePage}
                  language={selectLanguage}
                  origineLangage={origineLanguage}
                  onClean={() => setTitleHomePage(undefined)}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {!identifierLegality ? (
                <TranslateLegality
                  origineLangage={origineLanguage}
                  onLegality={(identifier) => setIdentifierLegality(identifier)}
                />
              ) : (
                <FormTranslateLegality
                  identifier={identifierLegality}
                  language={selectLanguage}
                  origineLangage={origineLanguage}
                />
              )}
            </TabPanel>
          </>
        ) : (
          <Typography align="center" style={{ padding: "10px 0" }}>
            Multi languages is disabled.
            <Link to="/general-settings">
              <ButtonCustomize
                variant="contained"
                color="secondary"
                style={{ marginLeft: 15 }}
              >
                Enable multi languages
              </ButtonCustomize>
            </Link>
          </Typography>
        )}
      </Box>
    </Wrapper>
  );
};

export default Translate;
