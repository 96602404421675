import styled from "styled-components";
import ChipMUI from "@material-ui/core/Chip";

const Chip = styled(ChipMUI)`
  margin: 2px 4px;
  color: #ffffff;
  background: #000000;
  border-radius: 4px;
`;

export default Chip;
