import React from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import { MenuPayload } from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import { MainLayout } from "../../commons";

import {
  Menus,
  Account,
  Settings,
  MenuForm,
  ProductForm,
  Collection,
  AddCollection,
  Address,
  AddressCreate,
  Customers,
  CustomersForm,
  ClientsGroup,
  ClientsGroupFrom,
  ClientsNewsletter,
  PromoCode,
  PromoCodeForm,
  GiftCards,
  GiftCardsList,
  Inventory,
  ListProducts,
  StoreListEmpty,
  StoreCreate,
  OrdersList,
  OrderDetails,
  SettingsHome,
  SettingsHomeCshop,
  RoleList,
  RoleForm,
  Members,
  Payment,
  GeneralSettings,
  Analytics,
  Translate,
  ListLegality,
  LegalityForm,
  PickupDelivery,
  AddDeliveryMethode,
  EditDeliveryMethode,
  ListVendor,
  ListSubscriptions,
  StoreMenu,
  FormStoreMenu,
  ListProductType,
  ProductTypeForm,
  Writers,
  Readers,
  WritersForm,
  ReadersForm,
  Stories,
  StoresProductType,
  StoreProductTypeForm,
  Subscriptions,
  SubscriptionForm,
  CountryAndCurrency,
  CountryAndCurrencyForm,
  Plans,
  PlansForm,
  SettingsHomeRawi,
  Calendar,
  AccountVendor,
} from "..";

import { HOST_CSHOP, HOST_RAWI } from "../../api";

// New imports
// Category module
import { CategoryList, CategoryCreate } from "../Categories";

const { host } = window.location;

const Layout: React.FC = () => {
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  function getKindContent() {
    return (
      <Switch>
        <Route path="/account" component={Account} exact />
        <Route path="/account-vendor" component={AccountVendor} exact />
        <Route path="/settings" component={Settings} exact />
        <Route path="/stores" component={Menus} exact />
        <Route path="/add-store" component={MenuForm} exact />
        <Route path="/update-store" component={MenuForm} exact />
        <Route path="/managment-product" component={ProductForm} exact />
        <Route path="/managment-product/:id" component={ProductForm} exact />
        <Route path="/product" component={ListProducts} exact />
        <Route path="/collection" component={Collection} exact />
        <Route path="/add-collection" component={AddCollection} exact />
        <Route path="/setting-address" component={Address} exact />
        <Route path="/setting-add-address" component={AddressCreate} exact />
        <Route path="/customers" component={Customers} exact />
        <Route path="/create-customer" component={CustomersForm} exact />
        <Route path="/modify-customer" component={CustomersForm} exact />
        <Route path="/group" component={ClientsGroup} exact />
        <Route path="/gift-cards" component={GiftCardsList} exact />
        <Route path="/add-gift-card" component={GiftCards} exact />
        <Route path="/add-customer-group" component={ClientsGroupFrom} exact />
        <Route path="/newsletter" component={ClientsNewsletter} exact />
        <Route path="/inventory" component={Inventory} exact />
        <Route path="/promo-code" component={PromoCode} exact />
        <Route path="/add-promo-code" component={PromoCodeForm} exact />
        {/* Category */}
        <Route path="/categories" component={CategoryList} exact />
        <Route path="/create-category" component={CategoryCreate} exact />
        <Route path="/edit-category" component={CategoryCreate} exact />
        {/* Store */}
        {/* test if store created */}
        {!localMenu?.id && (
          <Route path="/home" component={StoreListEmpty} exact />
        )}
        {!localMenu?.id && (
          <Route path="/create-store" component={StoreCreate} exact />
        )}
        {/* Orders */}
        <Route path="/orders-list" component={OrdersList} exact />
        <Route path="/order" component={OrderDetails} exact />
        <Route path="/settings-home" component={SettingsHome} exact />
        <Route
          path="/settings-home-cshop"
          component={SettingsHomeCshop}
          exact
        />
        <Route path="/settings-home-rawi" component={SettingsHomeRawi} exact />
        {/* {Staff Managment} */}
        <Route path="/role-list" component={RoleList} exact />
        <Route path="/role-form" component={RoleForm} exact />
        <Route path="/members" component={Members} exact />
        {/* general setting */}
        <Route path="/payment" component={Payment} exact />
        <Route path="/general-settings" component={GeneralSettings} exact />
        <Route path="/analytics" component={Analytics} exact />
        <Route path="/translate" component={Translate} exact />
        <Route path="/legality" component={ListLegality} exact />
        <Route path="/legality-form" component={LegalityForm} exact />
        <Route path="/delivery" component={PickupDelivery} exact />
        <Route path="/add-delivery" component={AddDeliveryMethode} exact />
        <Route path="/edit-delivery" component={EditDeliveryMethode} exact />
        <Route path="/vendor" component={ListVendor} exact />
        <Route path="/subscriptions" component={ListSubscriptions} exact />
        <Route path="/store-menu" component={StoreMenu} exact />
        <Route path="/add-store-menu" component={FormStoreMenu} exact />
        <Route path="/edit-store-menu" component={FormStoreMenu} exact />
        <Route path="/product-type" component={ListProductType} exact />
        <Route path="/add-product-type" component={ProductTypeForm} exact />
        <Route
          path="/edit-product-type/:id"
          component={ProductTypeForm}
          exact
        />
        <Route path="/writers" component={Writers} exact />
        <Route path="/add-writers" component={WritersForm} exact />
        <Route path="/edit-writers" component={WritersForm} exact />
        <Route path="/readers" component={Readers} exact />
        <Route path="/add-readers" component={ReadersForm} exact />
        <Route path="/edit-readers" component={ReadersForm} exact />
        <Route path="/stories" component={Stories} exact />
        <Route path="/stores-type" component={StoresProductType} exact />
        <Route path="/add-stores-type" component={StoreProductTypeForm} exact />
        <Route
          path="/edit-stores-type"
          component={StoreProductTypeForm}
          exact
        />
        <Route path="/subscription" component={Subscriptions} exact />
        <Route path="/create-subscription" component={SubscriptionForm} exact />
        <Route
          path="/edit-subscription/:id"
          component={SubscriptionForm}
          exact
        />
        <Route path="/country-currency" component={CountryAndCurrency} exact />
        <Route
          path="/country-currency-form"
          component={CountryAndCurrencyForm}
          exact
        />
        <Route path="/plans" component={Plans} exact />
        <Route path="/plans-form" component={PlansForm} exact />
        <Route path="/calendar" component={Calendar} exact />
        {host === HOST_CSHOP || host === HOST_RAWI ? (
          <Redirect from="*" to="/product-type" />
        ) : (
          <Redirect from="*" to="/product" />
        )}
      </Switch>
    );
  }

  return (
    <MainLayout>
      <Container>{getKindContent()}</Container>
    </MainLayout>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dedede;
  }
`;

export default Layout;
