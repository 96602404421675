export const getUrlStores = (pathname: string): boolean => {
  const path = pathname.includes("/managment-product")
    ? "/managment-product"
    : pathname;

  switch (path) {
    case "/home":
      return true;
    case "/create-store":
      return true;
    case "/items":
      return true;
    // Sales management
    case "/orders-list":
      return true;
    case "/order":
      return true;
    // product management
    case "/product":
      return true;
    case "/managment-product":
      return true;
    case "/categories":
      return true;
    case "/create-category":
      return true;
    case "/edit-category":
      return true;
    case "/inventory":
      return true;
    case "/gift-cards":
      return true;
    case "/add-gift-card":
      return true;
    case "/promo-code":
      return true;
    // Analytics
    case "/analytics":
      return true;
    // Staff management
    case "/role-list":
      return true;
    case "/role-form":
      return true;
    case "/members":
      return true;
    case "/vendor":
      return true;
    // Marketing
    case "/newsletter":
      return true;
    case "/subscriptions":
      return true;
    // Customers
    case "/customers":
      return true;
    case "/create-customer":
      return true;
    case "/modify-customer":
      return true;
    case "/group":
      return true;
    case "/add-customer-group":
      return true;
    // Store settings
    case "/setting-address":
      return true;
    case "/setting-add-address":
      return true;
    case "/settings-home":
      return true;
    case "/payment":
      return true;
    case "/general-settings":
      return true;
    case "/translate":
      return true;
    case "/store-menu":
      return true;
    case "/legality":
      return true;
    case "/delivery":
      return true;
    case "/add-delivery":
      return true;
    case "/edit-delivery":
      return true;
    // Books
    case "/product-type":
      return true;
    case "/add-product-type":
      return true;
    // Writers && Readers
    case "/writers":
      return true;
    case "/readers":
      return true;
    case "/stores-type":
      return true;
    case "/add-stores-type":
      return true;
    case "/edit-stores-type":
      return true;
    default:
      return false;
  }
};
