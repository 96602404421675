import styled from "styled-components";

type ColorInputType = {
  color: string;
};

export const Wrapper = styled.div`
  .header-option {
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .info {
    color: #bcbcbc;
    font-size: 12px;
    margin: 20px 0 10px 0;
  }
  .option {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    align-items: center;
    margin-bottom: 10px;
    span {
      font-size: 13px;
    }
  }
`;

export const ColorInput = styled.div<ColorInputType>`
  width: 20px;
  height: 20px;
  border: 2px solid #f1f2fb;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  margin-right: 5px;
`;
