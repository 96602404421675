import gql from "graphql-tag";

export const CREATECATEGORYPRODUCT = gql`
  mutation CreateCategoryProduct($input: CreateCategoryProductInput!) {
    createCategoryProduct(input: $input) {
      id
    }
  }
`;

export const GETCATEGORYPRODUCTDETAILS = gql`
  query GetCategoryProductDetails($input: GetCategoryProductDetailsInput!) {
    getCategoryProductDetails(input: $input) {
      product {
        id
        category
        identifier
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        kind
        tags
        vendor
        vendorId
        brand
        unit {
          kind
          default
        }
        picture {
          id
          fileUrl
        }
        gallery {
          id
          fileUrl
        }
        salesCount
      }
    }
  }
`;

export const EDITPRODUCTSETTINGS = gql`
  mutation EditProductSettings($input: EditProductSettingsInput!) {
    editProductSettings(input: $input) {
      success
    }
  }
`;

export const GETPRODUCTSETTINGS = gql`
  query GetProductSettings($input: GetProductSettingsInput!) {
    getProductSettings(input: $input) {
      productSettings {
        id
        barcode
        sku
        shippingWeight
        price {
          value
          currency {
            id
            code
          }
        }
        discount {
          percentage
          price {
            value
            currency {
              id
              code
            }
          }
        }
        hasMultiLanguages
        hasMultiSites
        hasVariants
        isPublished
        mustCheckAvailaibitly
        deliveryMode
      }
    }
  }
`;

export const ADDPRODUCTOPTION = gql`
  mutation AddProductOption($input: AddProductOptionInput!) {
    addProductOption(input: $input) {
      id
    }
  }
`;

export const GETLISTPRODUCTOPTIONS = gql`
  query GetListProductOptions($input: GetListProductOptionsInput!) {
    getListProductOptions(input: $input) {
      list {
        id
        names {
          id
          languageCode
          value
        }
        inputKind
        requiredItems
        hasDefault
        values {
          id
          names {
            id
            languageCode
            value
          }
          picture {
            id
            fileUrl
          }
          priceDifference
          isDefault
          sites {
            site {
              id
              identifier
              names {
                id
                languageCode
                value
              }
            }
            priceDifference
          }
        }
      }
    }
  }
`;

export const REMOVEPRODUCTOPTION = gql`
  mutation RemoveProductOption($input: RemoveProductOptionInput!) {
    removeProductOption(input: $input) {
      success
    }
  }
`;

export const EDITPRODUCTOPTION = gql`
  mutation EditProductOption($input: EditProductOptionInput!) {
    editProductOption(input: $input) {
      success
    }
  }
`;

export const ADD_PRODUCT_PICTURE = gql`
  mutation AddProductPicture($input: AddProductPictureInput!) {
    addProductPicture(input: $input) {
      id
    }
  }
`;

export const REMOVE_PRODUCT_PICTURE = gql`
  mutation RemoveProductPicture($input: RemoveProductPictureInput!) {
    removeProductPicture(input: $input) {
      success
    }
  }
`;

export const GET_LIST_CATEGORY_PRODUCTS = gql`
  query GetListCategoryProducts($input: GetListCategoryProductsInput!) {
    getListCategoryProducts(input: $input) {
      list {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        price {
          value
          currency {
            id
            code
          }
        }
        picture {
          id
          fileUrl
        }
        isPublished
      }
    }
  }
`;

export const DELETE_CATEGORY_PRODUCTS = gql`
  mutation deleteCategoryProduct($token: String!, $id: String!) {
    deleteCategoryProduct(input: { token: $token, id: $id }) {
      success
    }
  }
`;

export const UPDATECATEGORYPRODUCT = gql`
  mutation updateCategoryProduct($input: UpdateCategoryProductInput!) {
    updateCategoryProduct(input: $input) {
      success
    }
  }
`;

export const SETCATEGORYPRODUCTSORDER = gql`
  mutation setCategoryProductsOrder($products: SetCategoryProductsOrderInput!) {
    setCategoryProductsOrder(input: $products) {
      success
    }
  }
`;

export const GETLISTCURRENCIES = gql`
  query getListCurrencies {
    getListCurrencies {
      list {
        id
        code
      }
    }
  }
`;

export const ASSIGNCATEGORYPRODUCT = gql`
  mutation AssignCategoryProducts($input: AssignCategoryProductsInput!) {
    assignCategoryProducts(input: $input) {
      success
    }
  }
`;

export const EXPORT_PRODUCTS = gql`
  mutation exportProducts($input: ExportProductsInput!) {
    exportProducts(input: $input) {
      fileUrl
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($input: GetProductDetailsInput!) {
    getProductDetails(input: $input) {
      product {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        kind
        tags
        unit {
          kind
          default
        }
        options {
          id
          names {
            id
            languageCode
            value
          }
          values {
            id
            names {
              id
              languageCode
              value
            }
          }
        }
        variants {
          id
          names {
            id
            languageCode
            value
          }
          options {
            option
            value
          }
        }
      }
    }
  }
`;

// GetListMenuSimilarProducts

export const GET_LIST_MENU_SIMILAR_PRODUCTS = gql`
  query GetListMenuSimilarProducts($input: GetListMenuSimilarProductsInput!) {
    getListMenuSimilarProducts(input: $input) {
      list {
        id
        sku
        names {
          id
          languageCode
          value
        }
        category {
          id
          names {
            id
            languageCode
            value
          }
        }
        isSimilar
      }
    }
  }
`;

export const SET_SIMILAR_PRODUCTS = gql`
  mutation SetSimilarProducts($input: SetSimilarProductsInput!) {
    setSimilarProducts(input: $input) {
      success
    }
  }
`;
