import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  .MuiButton-label {
    display: block;
    font-size: 14px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .role__container {
    display: flex;
    .role__container-form {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 10px;
      & > div {
        display: flex;
        justify-content: space-between;
        .MuiButton-label {
          display: block;
          font-size: 14px;
          text-transform: lowercase;
          ::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .MuiFilledInput-root {
        border-radius: 5px;
        border: 2px solid #f1f2fb;
        background-color: #ffffff;
        ::before {
          content: none;
        }
        ::after {
          content: none;
        }
        &:focus-within {
          background: #ffffff;
          border: 2px solid #000000;
          border-radius: 5px;
        }
        &:hover {
          background: #ffffff;
        }
        input {
          height: 23px;
        }
      }
      .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]
        .MuiAutocomplete-input {
        padding: 6px 4px;
      }
      .MuiChip-root {
        height: 29px;
      }
      .role__container-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .PrivateSwitchBase-root-2 {
            padding: 5px;
          }
        }
      }
    }
  }
`;
