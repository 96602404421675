import React from "react";
import { useIntl } from "react-intl";
import { Box, Paper, Typography, MenuItem } from "@material-ui/core";
import { useReactiveVar, useQuery } from "@apollo/client";

import { BreadCrumbsTitle, Select } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import { GETSUBSCRIBERSLIST } from "../../api";
import {
  GetListMenuSubscribersQuery,
  GetListMenuSubscribersQueryVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import { NewsletterImg } from "../../img";

type FilterByEnum = "SUBSCRIBED" | "CREATED" | undefined;

const ListSubscriptions = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [filterBy, setFilterBy] = React.useState<FilterByEnum>(undefined);

  const { data } = useQuery<
    GetListMenuSubscribersQuery,
    GetListMenuSubscribersQueryVariables
  >(GETSUBSCRIBERSLIST, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        origin: filterBy,
      },
    },
    skip: !localMenu?.id,
  });

  const columns: ColumnsProps = [
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Origin",
      accessor: "origin",
    },
  ];

  const renderTableRows = () => {
    return (
      data?.getListMenuSubscribers?.list
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item) => ({
          id: item.id,
          email: <Typography variant="subtitle1"> {item.email} </Typography>,
          origin: <Typography variant="subtitle1"> {item.origin} </Typography>,
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box display="flex" flexDirection="column">
      <BreadCrumbsTitle
        labelIntl={[
          intl.formatMessage({ id: "mainLayout.clients" }),
          "List Subscriptions",
        ]}
        icon={NewsletterImg}
        iconAlt="NewsletterImg"
      />
      <Box component={Paper} p={2}>
        <div style={{ width: 200, margin: "0 0 16px auto" }}>
          <Select
            label="Filter by"
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value as FilterByEnum)}
            displayEmpty
          >
            <MenuItem value={undefined}>All</MenuItem>
            <MenuItem value="SUBSCRIBED">Subscribed</MenuItem>
            <MenuItem value="CREATED">Created</MenuItem>
          </Select>
        </div>

        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No list subscriber"
          tablePaginationProps={{
            labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
            count: data?.getListMenuSubscribers?.list?.length || 0,
            page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
        />
      </Box>
    </Box>
  );
};

export default ListSubscriptions;
