export default {
  // MainLayout
  "mainLayout.products": "Produits",
  "mainLayout.categories": "Catégories",
  "mainLayout.posts": "Des postes",
  "mainLayout.appearance": "Apparence",
  "mainLayout.plugins": "Plugins",
  "mainLayout.settings": "Paramètres",
  "mainLayout.inventory": "Inventaire",
  "mainLayout.clients": "Clients",
  "mainLayout.pages": "Pages",
  "mainLayout.order": "Commandes",
  "mainLayout.dictionary": "Dictionnaire",
  "mainLayout.address": "Adresse",
  "mainLayout.general": "Génerale",
  "mainLayout.giftCards": "Gifts Cards",
  //  Forms
  "form.fieldRequired": "Ce champs est obligatoire",
  "form.invalidEmail": "Email invalide",
  "form.errorConfirmPassword": "Les mots de passe ne correspondent pas",
  "form.password": "Mot de passe",
  "form.confirmPassword": "Confirmer le mot de passe",
  //  Login
  "login.email": "Email",
  "login.password": "Mot de passe",
  "login.login": "Se connecter",
  "login.signup": "Créer un compte",
  "login.remember": " Rester connecté",
  "login.forgetPassword": "Mot de passe oublié",
  // signup
  "signup.title": "Créer un nouveau compte",
  "signup.signup": "Créer compte",
  "signup.email": "Email",
  "signup.password": "Mot de passe",
  "signup.confirmPassword": "Confirmer le mot de passe",
  "signup.domaine": "Domaine",
  "signup.emailExist": "Email déjà utilisé!",
  "signup.domainExist": "Nom de domaine déjà utilisé!",
  "signup.registerComplete":
    "Compte créer avec succées, verfier votre boite mail.",
  // confirm accont
  "confirm.header": "Votre email a été confirmé avec succée",
  "confirm.message": "Connectez vous, pour accedez a votre espace!",
  "confirm.connect": "Se connectez",
  // sign out
  "sign.out": "Déconnexion",
  //  Settings
  "Settings.title": "Paramètres",
  "Settings.information":
    "Gérez vos menus, ajoutez-en de nouveaux à votre liste ou",
  "Settings.information2": "éditez et supprimez les existants!",
  "Settings.label": "Paramètre de la langue",
  // register merchant
  "merchant.personalInfo": "Informations Personnel",
  "merchant.storeInformation": "Information du magasin",
  "merchant.firstname": "Nom",
  "merchant.lastname": "Prènom",
  "merchant.countryCode": "Code Pays",
  "merchant.phoneNumber": "Numéro de téléphone",
  "merchant.activity": "Activités",
  "merchant.taxInfo": "Informations de tax",
  "merchant.corporateName": "Nom de l'établissement",
  "merchant.taxRegistration": "Enregistré sous N°",
  "merchant.tavCode": "Code TVA",
  "merchant.companyAddress": "Adresse de l'établissement",
  "merchant.address": "Adresse",
  "merchant.postCode": "Code Postale",
  "merchant.language": "Langue",
  "merchant.english": "Anglais",
  "merchant.french": "Français",
  "merchant.currency": "Devise",
  "merchant.website": "Site Web",
  "merchant.social": "Résaux Sociaux",
  "merchant.link": "Lien",
  "merchant.skip": "Passer",
  "merchant.next": "Suivant",
  "merchant.finish": "Terminer",
  // snack message
  "Snack.message":
    "Maintenez les informations de votre profil à jour afin de garantir une communication claire avec vos clients",
  "Snack.update": "Mètre à jour",
  //  Account
  "Account.title": "Mon Compte",
  "Account.setting": "Paramètres",
  "Account.details": "Détails du compte",
  "Account.published": "Publié",
  "Account.manageAccount": "Gérez votre compte",
  "Account.save": "Enregister",
  "Account.informations": "Informations du compte",
  "Account.fieldActivity": "Domaine d'activité",
  "Account.activity": "Activité",
  "Account.fiscal": "Details fiscale",
  "Account.immarticul": "Immatriculation fiscale",
  "Account.tva": "Code TVA",
  "Account.address": "Adresse",
  "Account.phone": "Téléphone",
  "Account.postal": "Code postal",
  "Account.country": "Pays",
  "Account.city": "Ville",
  "Account.mobile": "Numéro du téléphone",
  "Account.companyEmail": "Email de l'entreprise",
  "Account.personalInformation": "Informations personnel",
  "Account.firstContact": " Premier contact",
  "Account.firstName": "Nom",
  "Account.lastName": "Prénom",
  "Account.email": "Email",
  "Account.login": "Login",
  "Account.password": "Mot de passe",
  "Account.connexionInformation": "Information de connexion",
  "Account.changePassword": "Changer le mot de passe",
  "Account.oldPassword": "Ancien mot de passe",
  "Account.newPassword": "Nouveau mot de passe",
  "Account.confirmPassword": "Confirmer le mot de passe",
  "Account.status": "Statut",
  "Account.expirationDate": "Date d'éxpiration",
  "Account.succesChangePwd": "Mot de passe changé avec succes",
  "Account.companyDomain": "Identifiant unique",
  "Account.socialMedia": "Réseaux sociaux",
  "Account.Website": "Site web",
  "Account.selectSocialMedia": "Selectionner le réseaux social",
  "Account.link": "Lien",
  //  Ads
  "Ads.title": "Publicités",
  "Ads.list": "La liste  des publicités",
  "Ads.add": "Ajouter une publicité",
  "Ads.information":
    "Gérez vos publicités, ajoutez-en de nouveaux à votre liste ou",
  "Ads.information2": "éditez et supprimez les existants!",
  "Ads.notice":
    "Votre contrat ne vous donne le droit de publier un autre publicité.",
  "Ads.notice2":
    "Votre nouvelle publicité est bien ajouté mais il reste non publié pour le moment.",
  "Ads.listEmpty":
    "La liste de vos publicités est encore vide, veuillez ajouter votre premier publicité.",
  "Ads.addFirst": "Ajouter mon premier publicité",
  // Ads Card
  "Ads.card.Expired": "Expire dans",
  "Ads.card.Day": "jour(s)",
  "Ads.card.isExpired": "Expiré",
  "Ads.card.NumberOfClicks": "Nombre de clique",
  "Ads.card.Published": "Publié",
  "Ads.card.notPublished": "Non publié",
  "Ads.card.edit": "Modifier",
  "Ads.card.delete": "Supprimer",
  "Ads.card.deleteTitle": "Supprimer publicité",
  "Ads.card.deleteMessage": "voulez-vous supprimer cette publicité?",
  // Ads Form
  "Ads.form.title": "Publicité",
  "Ads.form.information": "Crée une nouvelle publicité ou métre à jour",
  "Ads.form.information2": "les publicités existants!",
  "Ads.form.btnSave": "Enregistrer",
  "Ads.form.newAdvertisement": "Nouvelle publicité",
  "Ads.form.published": "Publié sur le magasin",
  "Ads.form.nameAd": "Nom de la publicité",
  "Ads.form.selectStore": "Selectionner le magasin",
  "Ads.form.uploadCover": "Télécharger l'image de la couverture",
  "Ads.form.uploadPoster": "Télécharger l'image du postaire",
  "Ads.form.adPositionTitle": "Position publicité",
  "Ads.form.adPosition1": "Afficher la publicité dans la page d'accueil",
  "Ads.form.adPosition2": "Afficher la publicité dans les pages catégories",
  "Ads.form.adPosition3":
    "Afficher la publicité dans la page d'accueil et dans les pages des catégories",
  "Ads.form.AdditionalInformation": "Informations supplémentaires",
  "Ads.form.startDate": "Date de début *",
  "Ads.form.endDate": "Date de fin *",
  "Ads.form.type": "Sélectionner le type",
  "Ads.form.imageOnly": "Image Uniquement",
  "Ads.form.imageWithProduct": "Image avec le produit",
  "Ads.form.imageWithUrl": "Image avec URL",
  "Ads.form.products": "Sélectionner le produits",
  "Ads.form.product": "Produits *",
  "Ads.form.externalUrl": "URL externe *",
  // Modal
  "Modal.add": "Ajouter",
  "Modal.update": "Modifier",
  "Modal.delete": "Supprimer",
  "Modal.cancel": "Annuler",
  "Modal.close": "Fermer",
  "Modal.notice": "Compris",
  //  Menus
  "Menus.title": "Menus",
  "Menus.subtitle": "Liste des menus",
  "Menus.manage": "Gérer menu",
  "Menus.update": "Mettre à jour",
  "Menus.information":
    "Gérez vos menus, ajoutez-en de nouveaux à votre liste ou",
  "Menus.information2": "éditez et supprimez les existants!",
  "Menus.add": "Ajouter un menu",
  "Menus.notice":
    "Votre contrat ne vous donne le droit de publier un autre menu.",
  "Menus.notice2":
    "Votre nouveau menu est bien ajouté mais il reste non publié pour le moment.",
  "Menus.listEmpty":
    "La liste de vos menus est encore vide, veuillez ajouter votre premier menu.",
  // Menu Card
  "MenuCard.manage": "Gérer",
  "MenuCard.settings": "Paramètres",
  "MenuCard.theme": "Thème",
  "MenuCard.delete": "Supprimer",
  "MenuCard.accessCode": "Code d'accès",
  "MenuCard.contain": "Contient",
  "MenuCard.categories": "Catégories",
  "MenuCard.languages": "Langues",
  "MenuCard.download": "Télécharger",
  "MenuCard.published": "Publié",
  "MenuCard.notPublished": "Non publié",
  "MenuCard.deleteTitle": "Supprimer menu",
  "MenuCard.deleteMessage": "Voulez-vous supprimer ce menu?",
  // Ads Card
  "Ads.card.status": "Etat",
  //  Update Menu
  "MenuUpdate.update": "Métre à jour",
  "MenuUpdate.information1":
    "Gérez vos menus et vos thèmes, ajoutez-en des nouveaux à votre liste ou",
  "MenuUpdate.information2": "éditez et supprimez les existants!",
  "MenuUpdate.save": "Enregistrer",
  "MenuUpdate.published": "Publié",
  "MenuUpdate.menuName": "Nom du menu",
  "MenuUpdate.identifier": "Nom du menu dans l'URL",
  "MenuUpdate.menuLanguages": "Les langues secondaires du menu",
  "MenuUpdate.menuMainLanguage": "La langue principale du menu",
  "MenuUpdate.createMenu": "Création d'un nouveau menu",
  "MenuUpdate.updateMenu": "Mettre à jour",
  "MenuUpdate.identifierAlreadyExists": "Le nom du menu dans URL existe déjà",
  "MenuUpdate.languageSettings": "Langues",
  //  Menu Details
  "MenuDetails.information1":
    "Gérez vos menus, ajoutez-en de nouveaux à votre liste ou",
  "MenuDetails.information2": "éditez et supprimez les existants!",
  "MenuDetails.published": "Publié",
  "MenuDetails.category": "Catégorie",
  "MenuDetails.categories": "Catégories",
  "MenuDetails.product": "Produit",
  "MenuDetails.products": "Produits",
  "MenuDetails.listCategoriesEmpty": "La liste des catégories est encore vide",
  "MenuDetails.listProductsEmpty":
    "La liste des produits pour cette catégorie est encore vide. Ajoutez des produits par ici",
  "MenuDetails.filterBy": "Filtrer par",
  "MenuDetails.productName": "Nom du produit",
  "MenuDetails.price": "Prix",
  "MenuDetails.placehoder.search": "Vous pouvez chercher",
  "MenuDetails.btn.search": "Rechercher",
  "MenuDetails.addProduct": "Ajouter produit",
  "MenuDetails.listEmptyProducts": "Vous n'avez pas aucun produit",
  "MenuDetails.addClient": "Ajouter client",
  "MenuDetails.listClientsEmpty": "Vous n'avez aucun client",
  // Category Form
  "CategoryForm.information1":
    "Gérez vos menus et thèmes, ajoutez-en de nouveaux à votre liste ou",
  "CategoryForm.information2": "éditez et supprimez les existants!",
  "CategoryForm.categories": "Catégories",
  "CategoryForm.save": "Enregistrer",
  "CategoryForm.title": "Création d'une nouvelle catégorie",
  "CategoryForm.show": "Visible sur le magasin",
  "CategoryForm.categoryParent": "Catégorie parent",
  "CategoryForm.category": "Catégorie",
  "CategoryForm.subCategory": "Sous-catégorie",
  "CategoryForm.identifier": "Le Nom dans l'URL",
  "CategoryForm.useImages": "Utiliser des images pour cette catégorie",
  "CategoryForm.languageSettings": "Informations catégorie",
  "CategoryForm.uploadTitle": "télécharger l'image du catégorie",
  "CategoryForm.editCategory": "Modifier catégorie",
  "CategoryForm.identifierAlreadyExists":
    "Le nom du category dans l'URL existe déjà",

  // Upload
  "Upload.information": "Drag & drop vos images ou bien",
  "Upload.information2": "Selectionner",
  "Upload.download": "Télécherger",
  "Upload.chooseGallery": "Choisir de la galerie",

  //  Products
  "Products.title": "Produits",
  "Products.single": "Produit",
  //  Dashboard
  "Dashboard.title": "Tableau de bord",
  "Dashboard.subtitle": "Statistiques",
  "Dashboard.selectStore": "Sélectionnez le magasin",
  "Dashboard.yearAndMonth": "Année et mois",
  "Dashboard.datePicker": "frLocale",
  "Dashboard.vuePerProduct": "Les produits les plus visualisés",
  "Dashboard.vuePerCategory": "Les catégories les plus visualisées",
  "Dashboard.consultation": "Consultation hebdomadaire",
  "Dashboard.january": "Janvier",
  "Dashboard.february": "Février",
  "Dashboard.march": "Mars",
  "Dashboard.april": "Avril",
  "Dashboard.may": "Mai",
  "Dashboard.june": "Juin",
  "Dashboard.july": "juillet",
  "Dashboard.august": "Août",
  "Dashboard.september": "Septembre",
  "Dashboard.october": "October",
  "Dashboard.november": "November",
  "Dashboard.december": "December",
  "Dashboard.monday": "Lundi",
  "Dashboard.tuesday": "Mardi",
  "Dashboard.wednesday": "Mercredi",
  "Dashboard.thursday": "Jeudi",
  "Dashboard.friday": "Vendredi",
  "Dashboard.saturday": "Samdi",
  "Dashboard.sunday": "Dimanche",
  //  Category
  "Category.title": "Catégorie",
  // Category Card
  "CategoryCard.edit": "Modifier",
  "CategoryCard.delete": "Supprimer",
  "CategoryCard.contains": "Contient : ",
  "CategoryCard.items": "Article(s)",
  "CategoryCard.status": "Etat",
  // Language Table
  "languageTable.placeholder": "Remplir ce champ",
  "LanguageTable.fields.name": "Nom",
  "LanguageTable.fields.description": "Description",
  "LanguageTable.fields.longDescription": "Description longue",
  // forget password
  "forget.message":
    "Check your inbox, an email has been sent with a link to reset your password.",
  "forget.newMessage": "Your password has been successfully reset!",
  "forget.login": "Se connecter",
  // Reset Password
  "resetPassword.label.resetPassword":
    "Veuillez saisir votre identifiant ou adresse mail. Vous recevrez un lien pour réinitialiser votre mot de passe par e-mail.",
  "resetPassword.label.newPassword":
    "Veuillez saisir votre nouveau mot de passe.",
  "resetPassword.username": "Adresse mail",
  "resetPassword.cancel": "Annuler",
  "resetPassword.resetPassword": "Réinitialiser",
  "resetPassword.password": "Nouveau mot de passe",
  "resetPassword.confirm": "Confirmer le mot de passe",
  "resetPassword.error": "les mots de passe ne sont pas identiques",
  // Table Row
  "TableRow.name": "Nom",
  "TableRow.price": "Prix",
  "TableRow.parentCategory": "Catégorie parent",
  "TableRow.status": "État",
  "TableRow.isPublished": "Publié",
  "TableRow.isNotPublished": "Non Publié",
  "TableRow.btnEdit": "éditer",
  "TableRow.btnDelete": "Supprimer",
  "TableRow.modalTitle": "Supprimer produit",
  "TableRow.modalMessage": "Voulez-vous supprimer ce produit?",
  "TableRow.view": "Voir",
  //  Form validation message
  "Validation.required": "Ce champ est obligatoire",
  "Validation.notConfirmPassword":
    "Les deux mots de passe ne sont pas identiques",

  // Orders
  "Orders.title": "Commandes",
  "Orders.list": "liste des commandes",
  "Orders.ofTheDay": "Les commandes du jour",
  "Orders.listOfHistory": "Liste historique des commandes",
  "Orders.historical": "Historique",
  "Orders.totalNumber": "Nombre total",
  "Orders.waitingForTreatment": "En attente de traitment",
  "Orders.beingProcessed": "En cours de traitement",
  "Orders.delivered": "Livrées",
  "Orders.WAITING": "En attente de traitement",
  "Orders.CANCELED": "Annulé",
  "Orders.ONGOING": "En cours de preparation ",
  "Orders.READY": "Prêt pour la livraison",
  "Orders.DELIVERED": "Livré",
  "Orders.UNDELIVERED": "Non livré",
  "Orders.IN_DELIVERING": "En cours de livraison",
  "Orders.byAll": "Tout",
  "Orders.byWaitting": "En Attente",
  "Orders.byOngoing": "En préparation",
  "Orders.byTreaties": "Traités",
  "Orders.placeholderSearch": "Chercher avec la référence de la commande",
  "Orders.placeholderSearchWithDate": "Chercher avec une date",
  "Orders.btnSearch": "Chercher",
  "Orders.reference": "Référence",
  "Orders.customer": "Client",
  "Orders.phone": "Téléphone",
  "Orders.dateOfCreation": "Date de création",
  "Orders.latestChange": "Dernière modification",
  "Orders.status": "Statut",
  "Orders.origin": "Origine",
  // menuStyle
  "MenuStyle.title": "Contenu de la première page",
  "MenuStyle.welcomMessage": "Message d'accueil",
  "MenuStyle.menuTitle": "Titre",
  "MenuStyle.infos": "Informations complémentaires",
  "MenuStyle.infoMessage": "Entrer votre code WIFI par exemple",
  "MenuStyle.price": "Mode d'affichage des prix",
  "MenuStyle.theme": "Thème",
  "MenuStyle.bgColor": "Couleur de l'arriére plan",
  "MenuStyle.bgImage": "Style de l'arriére plan",
  "MenuStyle.font": "Police",
  "MenuStyle.fontPrimary": "Style de police primaire",
  "MenuStyle.fontSecondary": "Style de police secondaire",
  "MenuStyle.color": "La palette des couleurs",
  "MenuStyle.colorPrimary": "Couleur primaire",
  "MenuStyle.colorSeconday": "Couleur secondaire",
  "MenuStyle.colorAccent": "Couleurd' accent",
  "MenuStyle.colorTag": "Code couleur",
  "MenuStyle.titleDeco": 'Style d\'arriére plan du titre "Menu"',
  "MenuStyle.categoryDeco": "Style d'arriére plan des catégories",
  "MenuStyle.changeBtn": "Changer",
  "MenuStyle.saveBtn": "Enregistrer",
  "MenuStyle.confirmBtn": "Valider",
  "MenuStyle.downloadBtn": "Télécharger",
  "MenuStyle.priceDigit1": "Un seul chiffre après le virgule",
  "MenuStyle.priceDigit2": "Deux chiffres après le virgule",
  "MenuStyle.priceDigit3": "Trois chiffres après le virgule",
  "MenuStyle.modalGaleryTab": "Choisir de la galerie",
  "MenuStyle.modalArchiveTab": "Mes images",
  "MenuStyle.menuBgRec": "Résolution recommandée pour l'image du fond",
  "MenuStyle.titleBgRec":
    'Résolution recommandée pour la décoration du titre "Menu"',
  "MenuStyle.categoryBgRec":
    "Résolution recommandée pour la décoration du catégorie",
  "MenuStyle.snack": "Tous les changements ont été bien enregister!",
  "MenuStyle.tip1": "Gérez votre menu, ajoutez-en des informations",
  "MenuStyle.tip2": "supplementaires, ou bine éditez le thème!",

  // categoryCard
  "CategoryCard.modalTitle": "Supprimer catégorie",
  "CategoryCard.modalMessage": "Voulez-vous supprimer cette catégorie?",

  // file
  "File.modalTitle": "Supprimer image",
  "File.modalMessage": "Voulez-vous supprimer cette image?",
  "File.tooltipDelete": "Supprimer",
  "File.onUpload": "Téléchargement",
  "File.uploaded": "Téléchargé",

  // Menu card
  "MenuCard.copy": "Copier",
  "MenuCard.linkCopied": "Lien copié!",

  // languageTable
  "Language.french": "Français",
  "Language.arabic": "Arabe",
  "Language.english": "Anglais",
  "Language.german": "Allemand",
  "Language.italian": "Italien",
  "Language.spanish": "Espagnole",
  "Language.portogese": "Portugaise",
  "Language.turkish": "Turque",
  "Language.chinese": "Chinois",
  "Language.japanese": "Japonais",
  "Language.placeholder": "Remplir çe champ",

  // ModalDetailOrder
  "ModalDetailOrder.refrence": "Référence",
  "ModalDetailOrder.clientInfo": "Information du client",
  "ModalDetailOrder.fullName": "Nom et prénom",
  "ModalDetailOrder.phone": "Téléphone",
  "ModalDetailOrder.email": "Email",
  "ModalDetailOrder.note": "Note",
  "ModalDetailOrder.orderInfo": "Information sur la commande",
  "ModalDetailOrder.orderDeliveryCost": "Frais de livraison",
  "ModalDetailOrder.total": "Totale",

  // ModalPromotion
  "ModalPromotion.title": "Titre de la promotion",
  "ModalPromotion.description": "Courte description de la promotion",
  "ModalPromotion.startDate": "Date de début",
  "ModalPromotion.endDate": "Date de fin",

  // Upload
  "Upload.logoRec": "Résolution recommandée pour le logo",
  "Upload.categoryRec": "Résolution recommandée pour l'image du catégorie",
  "Upload.productRec": "Résolution recommandée pour l'image du produit",
  "Upload.promotion.cover": "Résolution recommandée pour la couverture",
  "Upload.promotion.poster": "Résolution recommandée pour le poster",
  "Upload.error":
    "Il semble que vous essayez de télécharger un fichier avec la mauvaise extension. Veuillez choisir un fichier avec l'une de ces extensions: .jpeg, .jpg, .png",
  "Upload.errorSizePicture":
    "La taille de l'image ne peut pas être supérieure à 2 Mo",
  "Upload.errorResulotionPicture":
    "La résolution de l'image doit être au moins 400 x 400 pixels",
  // MenuCard
  "MenuCard.addMenu": "Ajouter mon premier menu",

  // ProductForm
  "ProductForm.ProductManagement": "Gestion des Produits",
  "ProductForm.ProductsList": "Liste des produits",
  "ProductForm.ProductCreation": "Création du produit",
  "ProductForm.ProductUpdate": "Mise à jour du produit",
  "ProductForm.status": "Statut",
  "ProductForm.title": "Produits",
  "ProductForm.save": "Enregistrer",
  "ProductForm.publish": "Visible sur le menu",
  "ProductForm.price": "Prix en ",
  "ProductForm.discount": "Réduction en pourcentage % ",
  "ProductForm.afterDiscount": "Prix après la réduction ",
  "ProductForm.buyingPrice": "Prix d'achat",
  "ProductForm.changeCatTitle": "Modifier la catégorie de produits",
  "ProductForm.quantity": "Quantité (Stock)",
  "ProductForm.category": "Catégorie",
  "ProductForm.subCategory": "Sous catégorie",
  "ProductForm.none": "Aucune",
  "ProductForm.managedBy": "Gérer par",
  "ProductForm.piece": "Pièce",
  "ProductForm.kilogram": "Kilogramme",
  "ProductForm.metre": "Mètre",
  "ProductForm.gram": "Gramme",
  "ProductForm.notice": "Le prix a choisir est pour 100 grammes",
  "ProductForm.name.in.url": "Nom de produit dans l'url",
  "ProductForm.informations.product": "les informations du produit",
  "ProductForm.pricing.information": "Les Informations tarifaires du produit",
  "ProductForm.uploadImages": "Télécharger les images du produit",
  "ProductForm.identifierAlreadyExists":
    "Le nom du produit dans URL existe déjà",
  "ProductForm.tags": "Mots clés",
  "ProductForm.vendor": "Vendeur",
  "ProductForm.typeOfProduct": "Type de produit",
  "ProductForm.vendorName": "Nom du vendeur",
  "ProductForm.variants": "Variantes",
  "ProductForm.descriptionVariants":
    "Ce produit comporte plusieurs options, p. ex. différentes tailles ou couleurs.",
  "ProductForm.AddVariants": "Ajouter une option",
  "ProductForm.variants.reset": "Réinitialisation",
  "ProductForm.DeleteVariants": "Supprimer",
  "ProductForm.title.category": "Catégorie",
  "ProductForm.category.name": "Nom de catégorie",
  "ProductForm.title.subCategory": "Sous-catégorie",
  "ProductForm.sucCategory.name": "Nom de sous-catégorie",
  "ProductForm.differentAddresses": "Prix différent pour chaque adresse",
  "ProductForm.differentStock": "Stock différent pour chaque adresse",
  "ProductForm.newProduct": "Nouveau produit",
  "ProductForm.search": "Chercher",
  "ProductForm.sortBy": "Trier par",
  "ProductForm.productName": "Nom du produit",
  "ProductForm.Category": "Categorie",
  "ProductForm.numberOfOption": "Nombre d'option",
  "ProductForm.numberOfVariation": "Nombre de variation",
  "ProductForm.basePrice": "Prix ​​de base",
  "ProductForm.listEmpty":
    " Votre liste de produits est toujours vide, veuillez ajouter votre premier produit",
  "ProductForm.addProduct": "Ajouter un produit",
  "ProductForm.notAssigned": "Non attribué",
  "ProductForm.name": "Nom",
  "ProductForm.Price": "Prix",
  "ProductForm.Date": "Date",
  "ProductForm.editProduct": "Modifier le produit",
  "ProductForm.editOption": "Modifier l'option",
  "ProductForm.editVariation": "Modifier la variante",
  "ProductForm.deleteVariation": "Supprimer le produit",
  "ProductForm.modalDeleteTitle": "Supprimer produit ?",
  "ProductForm.modalDeleteDescription":
    "Êtes-vous sûr de vouloir supprimer ce produit ?",
  "ProductForm.general": "Générale",
  "ProductForm.attributes": "Les attributs",
  "ProductForm.options": "Options",
  "ProductForm.variation": "Variation",
  "ProductForm.stock": "Stock",
  "ProductForm.pricing": "Tarification",
  "ProductForm.initialPrice": "Prix ​​initial ",
  "ProductForm.sales": "Solde (%)",
  "ProductForm.afterSales": "Après solde",
  "ProductForm.productsSettings": "Paramètres du produit",

  "ProductForm.showInStore": "Afficher le produit dans ma boutique",
  "ProductForm.hasVariations": "Activer les variations",
  "ProductForm.hasMultiLanguages": "Activer multi langue",
  "ProductForm.manageDictionary": "Gérer le dictionnaire",
  "ProductForm.activateMultiSites": "Activer multi adresse",

  // Store
  "Store.title": "Magasin",
  "Stores.subtitle": "Liste des magasins",
  "Store.modalTitle": "Ajouter emplacement",
  "Store.note1":
    "Gérez vos magasins, ajoutez-en de nouveaux à votre liste ou modifiez",
  "Store.note2": "et supprimez les magasins existants!",
  "Store.addStore": "Ajouter un magasin",
  "Store.save": "Enregistrer",
  "Store.new": "Nouveau magasin",
  "Store.createStore": "Création d'un nouveau magasin",
  "Store.updateStore": "Mettre à jour",
  "Store.stores": "Emplacements",
  "Store.published": "Publié",
  "Store.name": "Nom du magasin",
  "Store.identifier": "Nom du magasin dans l'URL",
  "Store.menuLanguages": "Les langues secondaires du magasin",
  "Store.menuMainLanguage": "La langue principale du magasin",
  "Store.free": "Gratuit",
  "Store.fixedPrice": "Prix fixe",
  "Store.freeDeliveryFrom": "Livraison gratuite à partir de",
  "Stroe.orderValue": "Valeur de la commande",
  "Store.manage": "Gérer magasin",
  "Store.address": "Adresse",
  "Store.phone": "Numéro de téléphone",
  "Store.action": "Actions",
  "Store.edit": "Editer",
  "Store.delete": "Supprimer",
  "Store.lineperPage": "Lignes par page",
  "Store.modalEdit": "Modifier Magasin",
  "Store.modalDelete": "Supprimer Magasin",
  "Store.modalDeleteMessage": "Voulez-vous supprimmer cet magasin?",
  "Store.addFisrtStore": "Ajouter mon premier magasin",
  "Store.listEmpty":
    "La liste de vos magasins est encore vide, veuillez ajouter votre premier magasin.",

  // store settings (address)
  "Store.settings": "Paramètres",
  "Store.settings.addresses": "Liste des adresses",
  "Store.settings.addressesListEmpty": "Vous n'avez pas aucune adresse",
  "Store.settings.addAdress": "Ajouter adresse",
  "Store.settings.formAddAdress": "Création d'une adresse",
  "Store.settings.formUpdateAdress": "Mise à jour une adresse",
  "Store.settings.inputName": "Nom",
  "Store.settings.inputAddress": "Adresse",
  "Store.settings.inputCountry": "Pays",
  "Store.settings.inputCity": "Ville",
  "Store.settings.save": "Enregistrer",
  "Store.settings.newAddress": "Nouvelle Adresse",
  "Store.modalTitleDelete": "Supprimer adresse",
  "Store.modalMessageDelete": "Voulez-vous supprimer cette adresse?",
  // store settings (list address)
  "Store.settings.name": "Nom",
  "Store.settings.address": "Adresse",
  "Store.settings.country": "Pays",
  "Store.settings.city": "Ville",
  // settings > Home Page
  "Store.settingsHome.title": "HomePage",

  //  store Clients
  "store.cutomers.cutomersList": "Liste des Clients",
  "Store.client": "Clients",
  "store.customer.creatClient": "Créer un client",
  "store.customer.addclient": "Creation Client",
  "store.customerForm.save": "Enregistrer",
  "store.customersList.address.primary": "Primaire",
  "store.customersList.address.secondary": "Secondaire",
  "store.customerForm.addaddress.discard": "Annuler",
  "store.customerForm.formAddclient": "Creation d'un client",
  "store.customerForm.inputFirstName": "Nom",
  "store.customerForm.inputLastName": "Prenom",
  "store.customerForm.inputemail": "E-Mail",
  "store.customerForm.inputPhone": "Telephone",
  "store.customerForm.AddressList": "Liste des adresse",
  "store.customerForm.ChooseAddress": "Choisir votre adresse principale",
  "store.customerForm.addAddress": "Creation d'une adresse du client",
  "store.customerForm.AddressListEmpty": "Client n'a pas des adresse",
  "store.customersList.addressList": "Liste d'adresses du client",
  "store.customersList.address.selectClient":
    "Sélectionnez un client pour voir sa liste d'adresses",
  "Store.customersList.creatAddress": "Ajoutez adresse",
  "store.customersList.address.HasNoAddress": "n'a pas d'adresse",
  "store.customerForm.addAddress.City": "Ville",
  "store.customerForm.addAddress.Country": "Pays",
  "store.customerForm.addAddress.PostalCode": "Code Postal",
  "store.customerForm.addAddress.Phone": "Telephone",
  "store.customerForm.error.missing.firstName": "Nom est manquant",
  "store.customerForm.error.missing.lastName": "Prenom est manquant",
  "store.customerForm.error.email": "E-Mail est deja existant",
  "store.customerForm.error.missing.phone": "Phone est manquant",
  "store.customerForm.error.valid.email": "E-Mail Invalide",
  "store.customerForm.error.valid.phone": "Numero Telephone Invalide",
  "store.customerForm.addAddress.MakePrimary":
    "Faites-en votre adresse principale",
  "store.customerForm.addAddress.RoadName": "Rue",
  "store.customerForm.address.error": "Il faux choissir une adresse principale",
  "store.customer.modifyclient": "Modifier le client",
  "store.customerForm.update.title": "Mettre à jour les informations du client",
  "store.customerForm.cancel": "Annuler",
  "store.customerForm.update": "Mettre a jour",
  "store.customer.group.ListEmpty": "Vous n'avez aucun Groupe",
  "store.customer.group.addList": "Ajouter Groupe",
  "store.customer.group.List": "Liste des groupes",
  "store.customer.group.List.name": "Nom",
  "store.customer.group.List.numberClients": "Nombre des clients",
  "store.customer.group.List.customerFirstName": "Prénom du client",
  "store.customer.group.List.customerLastName": "Nom de famille du client",
  "store.customer.group.List.action": "Actions",
  "store.customer.group.List.action.edit": "Editer",
  "store.customer.group.List.action.delete": "Supprimer",
  "store.customer.group.modifyGroup": "Editer le Groupe",
  "store.customer.addGroupClient.formCreateGroup": "Creation d'un Groupe",
  "store.customer.group.deleteGroup": "Supprimer Groupe",
  "store.customer.group.deleteGroup.message": "Voulez-vous supprimer le groupe",
  "store.customer.newsletter": "Newsletter",
  "store.customer.newsletter.object": "Object",
  "store.customer.newsletter.message": "Message",
  "store.customer.newsletter.sentTo": "Envoyer à",
  "store.customer.newsletter.email": "E-mail",
  "store.customer.newsletter.send": "Envoyer",
  "store.customer.newsletter.AddEmail": "Ajouter E-mail",
  "store.customer.deleteAddress": "Deleting Address",
  "store.customer.deleteAddress.message":
    "Are you sure you want to delete the address",
  "store.customersList.MakePrimary": "Make it your principal",
  "store.customer.deleteClient": "Suppression du client",
  "store.customer.deleteClient.message":
    "Voulez-vous vraiment supprimer le client",
  // Store PromoCode
  "Store.PromoCode.delete": "Kill Promo Code",
  "Store.PromoCode.delete.message": "Voulez-vous desactiver le Promo Code",
  "Store.ProductManagment": "Gestion Des Produit",
  "Store.ProductManagment.Promo": "Promo Code",
  "Store.PromoCode.AddPromo": "Ajouter Promo Code",
  "Store.PromoCode.list": "Promo Code list",
  "Store.PromoCode.promoCode": "Promo Code",
  "Store.PromoCode.Type": "Promo Type",
  "Store.PromoCode.action": "Actions",
  "Store.PromoCode.kill": "Kill Promo",
  "Store.PromoCode.AddPromo.Generate": "Creation du Promo Code",
  "Store.PromoCode.Generate": "Produire",
  "Store.PromoCode.type.percentage": "Pourcentage",
  "Store.PromoCode.type.fixed": "Prix Fixe",
  "Store.PromoCode.type.freeShip": "Livraison Gratuite",
  "Store.PromoCode.AddPromo.applied": "Appliqué à",
  "Store.PromoCode.applied.order": "Ordre",
  "Store.PromoCode.applied.collection": "Collections",
  "Store.PromoCode.applied.categorie": "Categories",
  "Store.PromoCode.applied.product": "Produits",
  "Store.PromoCode.AddPromo.available": "Disponible pour",
  "Store.PromoCode.available.allusers": "Tous les utilisateurs",
  "Store.PromoCode.available.specCustomer": "Client spécifique",
  "Store.PromoCode.available.newsletterSub": "Newsletter Abonné",
  "Store.PromoCode.available.specGroup": "Groupe spécifique",
  "Store.PromoCode.AddPromo.MinReq": "Minimum Requis",
  "Store.PromoCode.AddPromo.MinReq.allusers": "Tous les utilisateurs",
  "Store.PromoCode.AddPromo.MinReq.MinPurAmmount": "Montant minimum d'achat",
  "Store.PromoCode.AddPromo.MinReq.MinQuanItems": "Quantité minimum d'articles",
  "Store.PromoCode.AddPromo.MinReq.MinVerOrdPurchase":
    "Achat minimum de commandes vérifiées",
  "Store.PromoCode.AddPromo.MinReq.MinVerOrdAmmount":
    "Montant minimum des commandes vérifiées",
  "Store.PromoCode.AddPromo.limit": "Limits",
  "Store.PromoCode.AddPromo.limit.NbTimesUsed": "Nombre de fois utilisé",
  "Store.PromoCode.AddPromo.limit.oneUse":
    "Limite à une utilisation par le client",
  "Store.PromoCode.AddPromo.limit.startDate": "Date de début",
  "Store.PromoCode.AddPromo.limit.endDate": "Date de fin",
  "Store.promocode.error.PromoCode": "Promo Code est manquant!",
  "Store.promocode.error.percentage":
    "Le type de pourcentage nécessite une valeur!",
  "Store.promocode.error.fixedPrice":
    "Le type de Prix fixe nécessite une valeur!",
  "Store.PromoCode.listEmpty": "vous n'avez pas de code promo",
  // updateMenu
  "updateMenu.new": "Nouveau menu",
  "updateMenu.orderSettings": "Les paramètres des commandes",
  "updateMenu.internalOrder": "Accepter les commandes interne",
  "updateMenu.onlineOrder": "Accepter les commande en ligne",
  "updateMenu.deliverySettings": "Paramètres de frais de livraison",
  "updateMenu.deliveryCost": "sinon le coût de la livraison",
  "updateMenu.deliveryPlaceholder": "Montant",
  // format
  "format.error": "Verifier le format!",
  "format.alphaneumeric": "Caractères alphanumérique",
  "format.specialCaracters": "Pas de caratères spéciales",
  // Modal Edit Order
  KILOGRAM: "kilo",
  METER: "mètre",
  GRAM: "100 gram",
  PIECE: "pièce",

  // Array Options Variants

  "arrayOptionsVariants.varaiant": "Variant",
  "arrayOptionsVariants.picture": "Image",
  "arrayOptionsVariants.quantity": "Quantité",
  "arrayOptionsVariants.barcode": "Code a barre",
  "arrayOptionsVariants.buyingPrice": "Prix d'achat",
  "arrayOptionsVariants.initialPrice": "Prix initial",
  "arrayOptionsVariants.balance": "Solde",

  // Collection
  "collection.title": "Collection",
  "collection.listCollection": "List des collection",
  "collection.createCollection": "Creation d'une collection",
  "appearance.addCollection": "Ajouter collection",
  "appearance.listEmpty": "Vous n'avez pas aucune collection",
  "appearance.seeResult": "Voir résultat",
  "appearance.save": "Enregistrer",

  // gift card
  "giftCard.create": "Creation d'une carte cadeau",
  "giftCard.cardName": "Nom du carte cadeau",
  "giftCard.customers": "Liste des clients",
  "giftCard.groups": "Liste des Groupes",
  "giftCard.cardValue": "Valeur de la Carte",
  "giftCard.error": "Veuillez remplir le nom de la carte pour continuer!",
  "giftCard.success": "Votre carte a été créé avec succés!",
  "giftCard.list": "List des cartes cadeaux",
  "giftCard.emptyDescription": "Vous n'avez aucune carte cadeau!",
  "giftCard.newCard": "Créer carte cadeau",
  "giftCard.info": "Informations de la carte cadeau",

  // Main Menu
  "menu.products": "Produits",
  "menu.inventory": "Inventaire",
  "menu.category": "Catégories",
  "menu.appearance": "Apparence",
  "menu.pages": "Pages",
  "menu.theme": "Thème",
  "menu.orders": "Commandes",
  "menu.history": "Historique",
  "menu.marketing": "Marketing",
  "menu.giftCards": "Gift Cards",
  "menu.promoCodes": "Promo Codes",
  "menu.promotions": "Promotions",
  "menu.customers": "Clients",
  "menu.groups": "Groupes",
  "menu.newsletter": "NewsLetter",
  "menu.plugins": "Plugins",
  "menu.settings": "Paramètres",
  "menu.address": "Adresse",
  "menu.billing": "Facturation",
  "menu.dictionary": "Dictionnaire",
  "menu.collections": "Collections",
  // Inventory
  "inventory.title": "Inventaire",
  "inventory.list": "Liste des inventaires",
  "inventory.new": "Nouvel inventaire",
  "inventory.addresses": "Adresses",
  "inventory.collectionName": "Nom du collection",
  "inventory.Variation": "Variation",
  "inventory.Quantity": "Quantité",
  "inventory.sellingPrice": "Prix de vente",
  "inventory.Action": "Action quantité",
  "inventory.lessThanOrEqual": "Prix inférieure ou égale",
  "inventory.greaterThanOrEqual": "Prix supérieur ou égale",

  "inventory.productInformation": "Information du produit",
  "inventory.productLabel": "Libellé produit",
  "inventory.Address": "Addresse",
  "inventory.buyingPrice": "Prix d'achat",
  "inventory.add": "Ajouter",
  "inventory.price": "Prix",
  "inventory.save": "Enregistrer",
  // ErrorMessage
  "ErrorMessage.Message": "Une erreur est survenue!",
  "ErrorMessage.refresh": "Rafraîchir",

  // General Product
  "generalProduct.baseImage": "Image de base",
  "generalProduct.baseImageDescription": "Télécharger l'image",
  "generalProduct.gallery": "Galerie",
  "generalProduct.galleryDescription": "Ajouter une image à la galerie",
  "generalProduct.identifier": "Nom de produit dans l'url",
  "generalProduct.productName": "Nom de produit",
  "generalProduct.TypeOfProduct": "Type de produit",
  "generalProduct.physicalProduct": "Produit physique",
  "generalProduct.digitalProduct": "Produit numérique",
  "generalProduct.managedBy": "Gérer par",
  "generalProduct.piece": "Pièce",
  "generalProduct.gram": "Gramme",
  "generalProduct.kiloGram": "Kilogramme",
  "generalProduct.meter": "Mètre",
  "generalProduct.description": "Description",
  "generalProduct.vendor": "Vendeur",
  "generalProduct.tag": "Etiquette",
  "generalProduct.titleListCategory": "Attribuer ce produit à une catégorie",
  "generalProduct.categoryList": "Liste des catégories",

  // Option Product
  "optionProduct.title": "Liste d'options",
  "optionProduct.createOption": "Créer une option",
  "optionProduct.info1":
    "L'option de produit est utilisée pour les produits qui ont des caractéristiques différentes. ",
  "optionProduct.info2":
    "La taille et la couleur sont des exemples courants d'options de produits. ",
  "optionProduct.info3":
    "Les options peuvent être utilisées avec variation pour créer un produit plus détaillé et offrir plus de diversité à vos clients.",
  "optionProduct.name": "Nom",
  "optionProduct.values": "Valeurs",
  "optionProduct.edit": "Modifier",
  "optionProduct.delete": "Supprimer",
  "optionProduct.listOptionEmpty": "Il n'y a pas d'options créées",
  "optionProduct.modalDeleteTitle": "Supprimer l'option ?",
  "optionProduct.modalDeleteDescription":
    "Êtes-vous sûr de vouloir supprimer cette option ?",
  // payment
  "payment.tite": "Paiement",
  // translate
  "translate.title": "Traductions",
  // Subscriptions
  "subscriptions.bundlesManagement": "Gestion des lots - ",
  "subscriptions.subscription": "Abonnement",
  "subscriptions.newSubscription": "Ajouter un nouvel abonnement",
  "subscriptions.subscriptionTitle": "Titre de l'abonnement",
  "subscriptions.duration": "Durée",
  "subscriptions.price": "Prix",
  "subscriptions.productLimit": "Limite de produit",
  "subscriptions.status": "Statut",
  "subscriptions.form.return": "Retour",
  "subscriptions.form.create": "Créer un nouvel abonnement",
  "subscriptions.form.save": "Sauvegarder",
  "subscriptions.form.discard": "Annuler",
  "subscriptions.form.title": "Titre de l'abonnement",
  "subscriptions.form.description": "Descriptif de l'abonnement",
  "subscriptions.form.duration": "Gestion de la durée d'abonnement",
  "subscriptions.form.price": "Prix de l'abonnement",
  "subscriptions.form.limitMessage":
    "Limiter l'achat de produits de l'abonnement",
  "subscriptions.form.limit": "Limite de quantité de produit",
};
