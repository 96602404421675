import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, IconButton, Box, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import find from "lodash/find";

import { Wrapper, ColorInput } from "./OptionsProductStyle";
import { OptionFormModal } from "..";
import { Modal } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import { GETLISTPRODUCTOPTIONS, REMOVEPRODUCTOPTION } from "../../api";
import {
  GetListProductOptionsQuery,
  GetListProductOptionsQueryVariables,
  RemoveProductOptionMutation,
  RemoveProductOptionMutationVariables,
  ProductOptionPayload,
  MenuPayload,
} from "../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../api/local-state";

type OptionsProductProps = {
  hasMultiAddress: boolean;
};

const OptionsProduct: React.FC<OptionsProductProps> = ({ hasMultiAddress }) => {
  const token = localStorage.getItem("token") || "";
  const { id } = useParams<{
    id?: string;
  }>();
  const intl = useIntl();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idOption, setIdOption] = useState<string | undefined>(undefined);

  const [productOptionPayload, setProductOptionPayload] = useState<
    ProductOptionPayload | undefined
  >(undefined);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data } = useQuery<
    GetListProductOptionsQuery,
    GetListProductOptionsQueryVariables
  >(GETLISTPRODUCTOPTIONS, {
    variables: {
      input: {
        token,
        product: id || "",
      },
    },
    skip: !id,
  });

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const [RemoveOption, { loading: loadingDelete }] = useMutation<
    RemoveProductOptionMutation,
    RemoveProductOptionMutationVariables
  >(REMOVEPRODUCTOPTION, {
    variables: {
      input: {
        token,
        id: idOption || "",
        product: id || "",
      },
    },
    refetchQueries: () => [
      {
        query: GETLISTPRODUCTOPTIONS,
        variables: {
          input: {
            token,
            product: id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      handleClose();
      setProductOptionPayload(undefined);
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Values",
      accessor: "values",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRows = () => {
    return (
      data?.getListProductOptions?.list?.map((option) => ({
        id: option?.id,
        name: find(
          option?.names,
          (o) => o.languageCode === languageIsMain?.code
        )?.value,
        values: (
          <Box display="flex">
            {option.values?.map((value) =>
              find(
                value?.names,
                (o) => o.languageCode === languageIsMain?.code
              )?.value?.indexOf("#") !== -1 ? (
                <ColorInput
                  color={
                    find(
                      value?.names,
                      (o) => o.languageCode === languageIsMain?.code
                    )?.value || ""
                  }
                  key={value.id}
                />
              ) : find(
                  value?.names,
                  (o) => o.languageCode === languageIsMain?.code
                )?.value ? (
                `${
                  find(
                    value?.names,
                    (o) => o.languageCode === languageIsMain?.code
                  )?.value
                } `
              ) : (
                ""
              )
            )}
          </Box>
        ),
        actions: (
          <>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setProductOptionPayload(option);
                option.id && setIdOption(option.id);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      })) || []
    );
  };

  return (
    <Wrapper>
      <Box
        width={1}
        p={2}
        bgcolor="#ffffff"
        border="2px solid #f3f4fa"
        borderRadius="10px"
      >
        <div className="header-option">
          <h3>{intl.formatMessage({ id: "optionProduct.title" })}</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalCreate(true)}
          >
            {intl.formatMessage({ id: "optionProduct.createOption" })}
          </Button>
        </div>
        <div className="info">
          {intl.formatMessage({ id: "optionProduct.info1" })}
          {intl.formatMessage({ id: "optionProduct.info2" })}
          {intl.formatMessage({ id: "optionProduct.info3" })}
        </div>

        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="Add your first product"
        />
      </Box>
      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpenModalCreate(true);
          }}
        >
          {intl.formatMessage({ id: "optionProduct.edit" })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpenModalDelete(true);
          }}
          style={{ color: "#ff4949" }}
        >
          {intl.formatMessage({ id: "optionProduct.delete" })}
        </MenuItem>
      </Menu>
      <OptionFormModal
        open={openModalCreate}
        title={intl.formatMessage({ id: "optionProduct.createOption" })}
        handleClose={() => {
          setOpenModalCreate(false);
          setProductOptionPayload(undefined);
        }}
        productOptionPayload={productOptionPayload}
        hasMultiAddress={hasMultiAddress}
      />
      <Modal
        open={openModalDelete}
        title={intl.formatMessage({ id: "optionProduct.modalDeleteTitle" })}
        message={intl.formatMessage({
          id: "optionProduct.modalDeleteDescription",
        })}
        handleClose={() => {
          handleClose();
          setProductOptionPayload(undefined);
        }}
        handleContent={RemoveOption}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default OptionsProduct;
