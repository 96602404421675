/* *** TO REFACTOR *** */
/* The component is unique to category and should be commons to more uses */

// Libraries Imports
import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { Tooltip, MenuItem, Menu, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// Local Imports
import Modal from "./Modal";

import {
  DeleteCategoryProductMutation,
  DeleteCategoryProductMutationVariables,
} from "../api/types";
import { DELETEMENUCATEGORY, GETLISTMENUCATEGORIES, HOST_RAWI } from "../api";
import { GET_MENU_LOCAL } from "../api/local-state";

// Other Imports
import { ChevronDown } from "../img/digishop/menu-icons";
import { ReactComponent as EyeShow } from "../img/eyeShow.svg";
import { ReactComponent as EyeHide } from "../img/eyeHide.svg";
import { ReactComponent as More } from "../img/more.svg";

// Types
type TreeType = {
  id?: string;
  title?: string | null;
  isPublished?: boolean;
  noActions?: boolean;
  level: number;
  onSelect?: () => void;
  onAddSubcategory?: () => void;
};

const { host } = window.location;

const Tree: React.FC<TreeType> = ({
  id,
  title,
  isPublished,
  level,
  noActions,
  onSelect,
  onAddSubcategory,
  children,
}) => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();

  // States
  const [open, setOpen] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: localMenu } = useQuery(GET_MENU_LOCAL);

  const [deleteCategory] = useMutation<
    DeleteCategoryProductMutation,
    DeleteCategoryProductMutationVariables
  >(DELETEMENUCATEGORY, {
    refetchQueries: () => [
      {
        query: GETLISTMENUCATEGORIES,
        variables: { input: { token, menu: localMenu?.menuItem?.id || "" } },
      },
    ],
    onCompleted: () => setOpenModalDelete(false),
  });

  // Functions
  const handleEdit = () => {
    history.push({ pathname: "edit-category", state: { id, level } });
  };

  const handleOnDelete = () => {
    id && deleteCategory({ variables: { token, id } });
  };

  const handleMaxLevel = (path: string): number => {
    switch (path) {
      case HOST_RAWI:
        return 2;
      default:
        return 3;
    }
  };

  return (
    <StyledTree open={open} level={level}>
      <div className="main-tree">
        {children ? (
          <Tooltip title="Expand">
            <button
              type="button"
              className="chevron"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <ChevronDown
                width="18"
                height="18"
                style={{
                  transform:
                    open === false ? "rotate(-90deg)" : "rotate(-180deg)",
                }}
              />
            </button>
          </Tooltip>
        ) : (
          <i />
        )}
        <div role="button" tabIndex={0} onClick={onSelect} onKeyUp={onSelect}>
          <h4>{title}</h4>
        </div>
        {isPublished ? (
          <Tooltip
            title={!isPublished ? "Not visible in store" : "Visible in store"}
          >
            <button type="button">
              {!isPublished ? (
                <EyeHide width="20" height="20" />
              ) : (
                <EyeShow width="20" height="20" />
              )}
            </button>
          </Tooltip>
        ) : (
          <i />
        )}
        {!noActions ? (
          <div className="menu">
            <button type="button" onClick={(e) => setAnchorEl(e.currentTarget)}>
              <More width="20" height="20" />
            </button>
          </div>
        ) : (
          <i />
        )}
      </div>
      {children && open && <div>{children}</div>}
      <Modal
        open={openModalDelete}
        title="Delete category"
        message="Do you want to delete this category"
        handleClose={() => setOpenModalDelete(false)}
        handleContent={handleOnDelete}
        action="delete"
        loading={false}
      />
      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onAddSubcategory && onAddSubcategory();
            setAnchorEl(null);
          }}
          disabled={level === handleMaxLevel(host)}
        >
          <AddCircleOutlineOutlinedIcon />
          <Typography variant="body1" style={{ marginLeft: 5 }}>
            Add a Subcategory
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleEdit();
            setAnchorEl(null);
          }}
        >
          <EditOutlinedIcon />
          <Typography variant="body1" style={{ marginLeft: 5 }}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModalDelete(true);
            setAnchorEl(null);
          }}
        >
          <DeleteOutlineIcon style={{ color: "#F67070" }} />
          <Typography
            variant="body1"
            style={{ marginLeft: 5, color: "#F67070" }}
          >
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </StyledTree>
  );
};

// styled component types
type TreeComponentType = {
  open: boolean;
  level: number;
};

const StyledTree = styled.div<TreeComponentType>`
  .main-tree {
    padding-right: 12px;
    padding-left: ${(props) => props.level * 12}px;
    cursor: pointer;
  }
  .main-tree:hover {
    background-color: #f3f3f460;
  }
  svg {
    margin: auto;
    transition: all 0.4s ease-in-out;
    /* path {
      fill: #6c6d7b;
    } */
  }
  h4 {
    font-size: 17px;
    font-weight: 400;
    padding: 18px 0;
  }
  .main-tree {
    display: grid;
    grid-template-columns: 35px 1fr 35px 35px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin: auto;
    border: 0;
    background-color: transparent;
    border-radius: 20px;
    color: #bdbdbd;
    cursor: pointer;
    :hover {
      background-color: #f1f2f5;
    }
  }
  .menu {
    position: relative;
    margin: auto;
  }
`;

export default Tree;
