import { CreateCompanySiteInput } from "../../../api/types";
import { Errors } from "./AddressCreate";

export const AddressCreateValidation = (
  values: CreateCompanySiteInput
): Errors => {
  const errors: Errors = {};
  if (!values.identifier) errors.identifier = "form.fieldRequired";
  if (!values.address) errors.address = "form.fieldRequired";
  if (!values.address?.country) errors.country = "form.fieldRequired";
  if (!values.address?.city) errors.city = "form.fieldRequired";
  return errors;
};
