import * as React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import { useQuery, useReactiveVar } from "@apollo/client";

import { ButtonCustomize, SearchBasic } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import {
  GET_LIST_MENU_TYPES,
  GET_MENU_TYPE_PRODUCTS_BOOKS,
  URL_THEME_PICTURES,
} from "../../api";
import {
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  GetMenuTypeProductsQuery,
  GetMenuTypeProductsQueryVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import defaultPicture from "../../img/digishop/defaultPicture.jpg";

type KindEnum = "PRODUCT";

type AssignSlideHomePageProps = {
  open: boolean;
  onClose: () => void;
  onReturn: (kind: KindEnum, id: string) => void;
  typeKind: string | undefined | null;
  idObject: string | undefined | null;
};

const AssignSlideHomePage = ({
  open,
  onClose,
  onReturn,
  typeKind,
  idObject,
}: AssignSlideHomePageProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nameProduct, setNameProduct] = React.useState<string | undefined>(
    undefined
  );

  const [state, setState] = React.useState<{
    kind: KindEnum;
    id: string;
  }>({
    kind: "PRODUCT",
    id: "",
  });

  React.useEffect(() => {
    if (idObject)
      setState({
        ...state,
        id: idObject,
      });
  }, [idObject]);

  const { data } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const menuTypes = data?.getListMenuTypes?.list;

  const menuType = menuTypes?.find((o) => o.identifier === "BOOKS")?.id || "";

  const { data: listProducts } = useQuery<
    GetMenuTypeProductsQuery,
    GetMenuTypeProductsQueryVariables
  >(GET_MENU_TYPE_PRODUCTS_BOOKS, {
    variables: {
      input: {
        token,
        menuType,
        name: nameProduct,
        pageNumber: page,
        pageCount: rowsPerPage,
      },
    },
    skip: !menuType,
  });

  const totalProducts = listProducts?.getMenuTypeProducts?.total || 0;

  const columns: ColumnsProps = [
    {
      header: "",
      accessor: "",
      cellProps: { width: "50px" },
    },
    {
      header: "Image",
      accessor: "image",
      cellProps: { width: "80px" },
    },
    {
      header: "Name",
      accessor: "name",
    },
  ];

  const renderTableRows = () => {
    return (
      listProducts?.getMenuTypeProducts?.list?.map((product) => ({
        id: product?.id,
        "": (
          <Radio
            checked={state.id === product?.id}
            onChange={() => product?.id && handleChange(product.id)}
          />
        ),
        name: product.name?.value,
        image: product?.picture?.fileUrl ? (
          <img
            className="book__image"
            src={`${URL_THEME_PICTURES}${product.picture.fileUrl}`}
            alt="book_image"
          />
        ) : (
          <img className="book__image" src={defaultPicture} alt="book_image" />
        ),
      })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChange = (id: string) => {
    setState({
      ...state,
      id,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Assign Product
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <SearchBasic
            value={nameProduct}
            onChange={(value) => setNameProduct(value)}
          />
          <Box className="box-table">
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="No data available"
              tablePaginationProps={{
                labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
                count: totalProducts,
                page: page - 1,
                onPageChange: (_, newPage) => setPage(newPage + 1),
                rowsPerPage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingLeft={2}
          paddingRight={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={async () => {
              await onReturn(state.kind, state.id);
              onClose();
            }}
            disabled={!state.id}
            style={{ marginRight: 10 }}
          >
            Assign
          </ButtonCustomize>
        </Box>
      </Container>
      ;
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  min-width: 700px;
  flex-direction: column;

  .box-table {
    flex: 1;
    max-height: 710px;
    overflow-x: auto;
  }

  .book__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
`;

export default AssignSlideHomePage;
