import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Button, Typography } from "@material-ui/core";

interface BreadCrumbsPropsType {
  labelIntl: string[];
  icon?: string;
  iconAlt: string;
  buttonOnClick?: () => void;
  ButtonColor?: "inherit" | "primary" | "secondary" | "default";
  ButtonLabelIntl?: string;
}

const BreadCrumbsTitle: React.FC<BreadCrumbsPropsType> = ({
  labelIntl,
  icon,
  iconAlt,
  buttonOnClick,
  ButtonColor,
  ButtonLabelIntl,
}) => {
  const intl = useIntl();
  return (
    <Container>
      <div className="title">
        {icon && <img src={icon} alt={iconAlt} />}
        {labelIntl.map((value, index) => {
          if (index !== labelIntl.length - 1) {
            return (
              <Typography variant="h1" style={{ fontWeight: 300 }}>
                {intl.formatMessage({ id: value })}
                &nbsp; | &nbsp;
              </Typography>
            );
          }
          return (
            <Typography variant="h1" style={{ fontWeight: 600 }}>
              {intl.formatMessage({ id: value })}
            </Typography>
          );
        })}
      </div>
      <div>
        {buttonOnClick !== undefined &&
          ButtonColor !== undefined &&
          ButtonLabelIntl !== undefined && (
            <Button
              variant="contained"
              color={ButtonColor}
              onClick={buttonOnClick}
            >
              {intl.formatMessage({ id: ButtonLabelIntl })}
            </Button>
          )}
      </div>
    </Container>
  );
};
export const Container = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    display: flex;
    align-items: center;
    color: #42424a;
    img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }
  .MuiButton-label {
    display: block;
    font-size: 14px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export default BreadCrumbsTitle;
