import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .role__container {
    height: calc(100vh - 95px);
    flex-direction: column;
    padding: 15px;
    .role__container-action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      .MuiButton-label {
        display: block;
        font-size: 14px;
        text-transform: lowercase;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
`;
