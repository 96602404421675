import React, { FC } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { URL_PICTURE_CATEGORY } from "../api";

type ProductCardType = {
  name?: string;
  progress: number;
  idMenu?: string;
  fileName?: string;
  urlType: "category_products" | "menu_categories";
  // progress: "up" | "down";
};

const ProductCard: FC<ProductCardType> = ({
  name,
  progress,
  idMenu,
  fileName,
  urlType,
}) => {
  return (
    <Wrapper>
      {fileName ? (
        <img
          src={`${URL_PICTURE_CATEGORY}/${idMenu}/${urlType}/${fileName}`}
          alt="pizza"
        />
      ) : (
        <FontAwesomeIcon
          icon={faStore}
          size="lg"
          color="#74ABFF"
          className="icon"
        />
      )}
      <div className="product-info">
        <label>{name}</label>
      </div>
      <div className="figure">
        <label className={progress > 0 ? "up" : "down"}>
          {Math.abs(progress)}
          &nbsp;
        </label>
        {progress > 0 ? (
          <FontAwesomeIcon icon={faArrowUp} size="sm" className="up" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} size="sm" className="down" />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto 60px;
  grid-gap: 10px;
  padding: 10px;
  background: #fafbff;
  border-radius: 10px;
  .product-info {
    display: grid;
    grid-template-rows: auto auto;
    label::first-letter {
      text-transform: uppercase;
    }
    .description {
      font-size: 13px;
      color: #c3c0d0;
    }
  }
  .figure {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 12px;
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
  }
  .icon {
    width: 35px;
    height: auto;
    border-radius: 8px;
    margin-left: 2px;
  }
  label.progress {
    display: block;
    text-align: center;
    margin: auto 5px;
  }
  .up {
    color: #4cd964;
  }
  .down {
    color: #f67070;
  }
`;

export default ProductCard;
