import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 250px;
  align-items: center;
  font-size: 24px;
  img {
    width: 24px;
    margin-right: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    label {
      margin-top: 30px;
      font-size: 22px;
    }
  }
  .giftCard-form {
    width: 100%;
    max-width: 650px;
    margin: 30px auto 0 auto;
  }
  .gift-card_client-list {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .chips-list {
    margin-top: 15px;
    > div {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: #f1f2ff;
  }
  .PrivateNotchedOutline-root-120 {
    border-width: 2px;
    border-color: #f1f2ff;
  }
`;
