import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { TextField, Box, Grid, Paper, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery, useMutation } from "@apollo/client";

import { Wrapper } from "./AddressCreate--styles";
import { address } from "../../../img";
import cercles from "../../../img/cercles.png";
import { Input, BreadCrumbsTitle, ButtonCustomize } from "../../../commons";
import {
  GETLISTCOUNTRIES,
  GETLISTCITIES,
  CREATECOMPANYSITE,
  GETLISTCOMPANYSITES,
} from "../../../api";
import {
  GetListCountriesQuery,
  GetListCountriesQueryVariables,
  GetListCitiesQuery,
  GetListCitiesQueryVariables,
  CreateCompanySiteMutation,
  CreateCompanySiteMutationVariables,
  CreateCompanySiteInput,
} from "../../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../../api/local-state";

import { AddressCreateValidation } from "./AddressCreate--utils";

export type Errors = {
  identifier?: string;
  address?: string;
  country?: string;
  city?: string;
};

const AddressCreate: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);
  const menuId = localMenu?.menuItem?.id || "";

  const [companySite, setCompanySite] = useState<CreateCompanySiteInput>({
    token: localStorage.getItem("token") || "",
    menu: menuId,
  });
  const [errors, setErrors] = useState<Errors>({});

  const { data: listCountries } = useQuery<
    GetListCountriesQuery,
    GetListCountriesQueryVariables
  >(GETLISTCOUNTRIES);

  const { data: listCities } = useQuery<
    GetListCitiesQuery,
    GetListCitiesQueryVariables
  >(GETLISTCITIES, {
    variables: {
      countryId: companySite?.address?.country || "",
    },
  });

  const [createCompanySite, { loading }] = useMutation<
    CreateCompanySiteMutation,
    CreateCompanySiteMutationVariables
  >(CREATECOMPANYSITE, {
    refetchQueries: () => [
      {
        query: GETLISTCOMPANYSITES,
        variables: {
          token: localStorage.getItem("token") || "",
          menu: menuId,
        },
      },
    ],
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successful operation!",
      });
      history.push("/setting-address");
    },
  });

  const handleSubmit = () => {
    const errorsValidation = AddressCreateValidation(companySite);
    if (Object.keys(errorsValidation).length) {
      setErrors(errorsValidation);
    } else {
      createCompanySite({
        variables: {
          company: companySite,
        },
      });
    }
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({ id: "Store.settings" }),
            intl.formatMessage({ id: "Store.settings.addAdress" }),
          ]}
          icon={address}
          iconAlt="Customers"
        />
        <ButtonCustomize
          variant="contained"
          color="primary"
          disabled={loading}
          size="large"
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: "Store.settings.save" })}
        </ButtonCustomize>
      </Box>

      <Box
        component={Paper}
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        flex={1}
        p={2}
      >
        <div className="vector">
          <img src={cercles} alt="vector" className="img-vector" />
          <img src={address} alt="apparence" className="img-apparence" />
        </div>
        <Typography variant="h2" className="address__form-title">
          {intl.formatMessage({ id: "Store.settings.formAddAdress" })}
        </Typography>
        <Grid container component="form" className="form" spacing={2}>
          <Grid item xs={6}>
            <Input
              label={intl.formatMessage({
                id: "Store.settings.inputName",
              })}
              value={companySite?.identifier || ""}
              onChange={(e) =>
                setCompanySite({
                  ...companySite,
                  identifier: e.target.value,
                })
              }
              placeholder={intl.formatMessage({
                id: "Store.settings.inputName",
              })}
              error={Boolean(errors?.identifier)}
              msgError={
                errors.identifier &&
                intl.formatMessage({
                  id: errors.identifier,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label={intl.formatMessage({
                id: "Store.settings.inputAddress",
              })}
              value={companySite?.address?.roadNames?.[0]?.value || ""}
              onChange={(e) => {
                setCompanySite({
                  ...companySite,
                  address: {
                    ...companySite.address,
                    roadNames: [
                      {
                        languageCode: "EN",
                        value: e.target.value,
                      },
                    ],
                  },
                });
              }}
              placeholder={intl.formatMessage({
                id: "Store.settings.inputAddress",
              })}
              error={Boolean(errors?.address)}
              msgError={
                errors.address &&
                intl.formatMessage({
                  id: errors.address,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disableClearable
              options={listCountries?.getListCountries?.list || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, value) => {
                setCompanySite({
                  ...companySite,
                  address: {
                    ...companySite.address,
                    country: value.id,
                    city: "",
                  },
                });
              }}
              // defaultValue={companySite?.address?.country || undefined}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  variant="filled"
                  fullWidth
                  InputProps={{ ...params.InputProps, type: "search" }}
                  placeholder={intl.formatMessage({
                    id: "Store.settings.inputCountry",
                  })}
                  error={Boolean(errors?.country)}
                  helperText={
                    errors?.country &&
                    intl.formatMessage({
                      id: errors?.country,
                    })
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disableClearable
              options={listCities?.getListCities?.list || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, value) => {
                setCompanySite({
                  ...companySite,
                  address: {
                    ...companySite.address,
                    city: value.id,
                  },
                });
              }}
              // defaultValue={companySite?.address?.city || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  variant="filled"
                  fullWidth
                  InputProps={{ ...params.InputProps, type: "search" }}
                  placeholder={intl.formatMessage({
                    id: "Store.settings.inputCity",
                  })}
                  error={Boolean(errors?.city)}
                  helperText={
                    errors?.city &&
                    intl.formatMessage({
                      id: errors?.city,
                    })
                  }
                />
              )}
              disabled={!companySite?.address?.country}
            />
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default AddressCreate;
