import gql from "graphql-tag";

export const ADD_PRODUCT_INVENTORY_STOCK = gql`
  mutation addProductInventoryStock($input: AddProductInventoryStockInput!) {
    addProductInventoryStock(input: $input) {
      id
    }
  }
`;

export const GET_LIST_PRODUCT_INVENTORIES = gql`
  query GetListProductInventories($input: GetListProductInventoriesInput!) {
    getListProductInventories(input: $input) {
      list {
        product {
          id
          identifier
          names {
            id
            languageCode
            value
          }
        }
        variants {
          variant {
            id
            names {
              id
              languageCode
              value
            }
          }
          availability
        }
      }
      pageInfo {
        total
        perPage
        currentPage
        lastPage
        hasNextPage
      }
    }
  }
`;

export const GET_PRODUCT_INVENTORY_DETAILS = gql`
  query GetProductInventoryDetails($input: GetProductInventoryDetailsInput!) {
    getProductInventoryDetails(input: $input) {
      price {
        value
        currency {
          id
          code
        }
      }
      barcode
      sku
      availableQuantity
      itemsCount
      list {
        id
        date
        cost {
          value
          currency {
            id
            code
          }
        }
        availableQuantity
        totalQuantity
        stockStatus
      }
    }
  }
`;

export const REMOVE_PRODUCT_INVENTORY = gql`
  mutation RemoveProductInventory($input: RemoveProductInventoryInput!) {
    removeProductInventory(input: $input) {
      success
    }
  }
`;

export const ADD_PRODUCT_INVENTORY_LOSS = gql`
  mutation AddProductInventoryLoss($input: AddProductInventoryLossInput!) {
    addProductInventoryLoss(input: $input) {
      id
    }
  }
`;
