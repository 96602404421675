import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const DialogStories = styled(Dialog)`
  .autocomplete {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiFilledInput-underline:before {
      border-bottom: none;
    }
    .MuiFilledInput-underline:after {
      border-bottom: none;
    }
    .MuiInputBase-root {
      background-color: #ffffff;
      border: 2px solid #f1f2ff;
      border-radius: 5px;
      &:focus-within {
        border: 2px solid #000000;
        border-radius: 5px;
      }
    }
  }
`;
