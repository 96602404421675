// Libraries Imports
import React, { FC, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Typography, Box, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";

// Local Imports
import { Wrapper, Separator } from "./styles";

// Common Imports
import { BreadCrumbsTitle, OrderProductDetailsItem } from "../../../commons";
import {
  handleStatusOrder,
  handlePaymentOrder,
} from "../../../components/OrderCard/OrderCard--utils";

// Components Imports

// API Imports
import {
  UPDATE_MENU_ORDERS_STATUS,
  GETLISTMENUORDERS,
  UPDATE_MENU_ORDER_PAYMENT_STATUS,
} from "../../../api";
import {
  MenuOrderPayload,
  UpdateMenuOrderStatusMutation,
  UpdateMenuOrderStatusMutationVariables,
  UpdateMenuOrderPaymentStatusMutation,
  UpdateMenuOrderPaymentStatusMutationVariables,
  OrderStatus,
  PaymentStatus,
} from "../../../api/types";
import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../../api/local-state";

// Other Imports
import { ChevronDown } from "../../../img/digishop/menu-icons";
import { ReactComponent as Back } from "../../../img/back.svg";

// Types

type OrderDetailsProps = {
  refetchOrders: () => void;
};

const OrderDetails: FC<OrderDetailsProps> = () => {
  // Local variables
  const history = useHistory();
  const {
    id,
    reference,
    createdAt,
    cartDetails,
    customer,
    deliveredCost,
    deliveryAddress,
    orderStatus,
    paymentStatus: paymentSt,
  } = useLocation<MenuOrderPayload>().state;
  const token = localStorage.getItem("token");
  const kind = localStorage.getItem("kind");
  // States
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [fulfillmentStatus, setFulfillmentStatus] = useState<string>("");

  const [anchorElPayment, setAnchorElPayment] = useState<null | HTMLElement>(
    null
  );
  const [anchorElOrder, setAnchorElOrder] = useState<null | HTMLElement>(null);

  // Queries & Mutaions

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const [UpdateOrdersStatus, { loading }] = useMutation<
    UpdateMenuOrderStatusMutation,
    UpdateMenuOrderStatusMutationVariables
  >(UPDATE_MENU_ORDERS_STATUS, {
    refetchQueries: () => [
      {
        query: GETLISTMENUORDERS,
        variables: {
          input: {
            token,
            menu: localMenu?.menuItem.id || "",
            pageNumber: 1,
            pageCount: 10,
          },
        },
      },
    ],
  });

  const [UpdateOrdersPaymentStatus, { loading: loadingPayment }] = useMutation<
    UpdateMenuOrderPaymentStatusMutation,
    UpdateMenuOrderPaymentStatusMutationVariables
  >(UPDATE_MENU_ORDER_PAYMENT_STATUS, {
    refetchQueries: () => [
      {
        query: GETLISTMENUORDERS,
        variables: {
          input: {
            token,
            menu: localMenu?.menuItem.id || "",
            pageNumber: 1,
            pageCount: 10,
          },
        },
      },
    ],
  });
  // Functions

  useEffect(() => {
    setFulfillmentStatus(orderStatus || "");
    setPaymentStatus(paymentSt || "");
  }, [orderStatus, paymentSt]);

  const handleChangeOrderStatus = (idOrder: string, status: OrderStatus) => {
    UpdateOrdersStatus({
      variables: {
        input: {
          order: idOrder,
          status,
        },
      },
    });
    setFulfillmentStatus(status as string);
    setAnchorElOrder(null);
  };

  const handleChangeOrderPaymentStatus = (
    idOrder: string,
    status: PaymentStatus
  ) => {
    UpdateOrdersPaymentStatus({
      variables: {
        input: {
          order: idOrder,
          status,
        },
      },
    });
    setPaymentStatus(status as string);
    setAnchorElOrder(null);
  };

  return (
    <>
      <BreadCrumbsTitle
        labelIntl={["Sales Management ", "Orders"]}
        iconAlt="Orders"
      />
      <Wrapper>
        <div className="action-bar">
          <div className="view-actins">
            <button
              type="button"
              className="refresh"
              onClick={() => history.push("/orders-list")}
            >
              <Back width="18" />
              <span>Back to orders</span>
            </button>
          </div>
        </div>
        <Typography variant="h2" style={{ marginBottom: "15px" }}>
          Order N°:{reference}
        </Typography>
        <div className="order-list-content">
          <div>
            <Box
              bgcolor="#ffffff"
              p={2}
              border="2px solid #EFEFEF"
              borderRadius="10px"
            >
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h2" style={{ fontWeight: "bold" }}>
                  {moment(createdAt).format("MMMM Do YYYY, h:mm")}
                </Typography>
                <Typography variant="h2" style={{ fontWeight: "bold" }}>
                  {Math.trunc((cartDetails?.totalPrice?.value || 0) * 100) /
                    100}
                  &nbsp;
                  {cartDetails?.totalPrice?.currency?.code}
                </Typography>
              </Box>

              <Button
                variant="outlined"
                color="secondary"
                disableRipple
                onClick={(event) => setAnchorElPayment(event.currentTarget)}
                disabled={kind === "Vendor" || loadingPayment}
                style={{
                  minWidth: "180px",
                  paddingRight: "25px",
                  paddingLeft: "0",
                  margin: "20px 0 5px 0",
                }}
              >
                {handlePaymentOrder(paymentStatus)}
                <div className="arrow-down payment">
                  <ChevronDown width="16" height="16" />
                </div>
              </Button>

              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={(event) => setAnchorElOrder(event.currentTarget)}
                style={{
                  minWidth: "180px",
                  paddingRight: "25px",
                  paddingLeft: "0",
                  margin: "20px 0 5px 30px",
                }}
                disabled={loading || kind === "Vendor"}
              >
                {handleStatusOrder(fulfillmentStatus)}
                <div className="arrow-down fulfillment">
                  <ChevronDown width="16" height="16" />
                </div>
              </Button>
            </Box>
            <Separator />
            <Box
              bgcolor="#ffffff"
              p={2}
              border="2px solid #EFEFEF"
              borderRadius="10px"
            >
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Products
              </Typography>
              {cartDetails?.items?.map((item) => (
                <OrderProductDetailsItem key={item.id || ""} data={item} />
              ))}
              <Box display="flex" justifyContent="flex-end">
                <div>
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginTop: 15,
                    }}
                  >
                    Items &nbsp;: &nbsp;{" "}
                    {Math.trunc(
                      (cartDetails?.originalTotalPrice?.value || 0) * 100
                    ) / 100}{" "}
                    {cartDetails?.originalTotalPrice?.currency?.code}
                  </Typography>
                  <Typography variant="subtitle1">
                    Shipping &nbsp;: &nbsp;{" "}
                    {Math.trunc((deliveredCost || 0) * 100) / 100}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Total &nbsp;: &nbsp;
                    {Math.trunc((cartDetails?.totalPrice?.value || 0) * 100) /
                      100}
                    &nbsp;
                    {cartDetails?.totalPrice?.currency?.code}
                  </Typography>
                </div>
              </Box>
            </Box>
          </div>
          <div>
            <Box
              bgcolor="#ffffff"
              p={2}
              border="2px solid #EFEFEF"
              borderRadius="10px"
              maxHeight="auto"
            >
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                Cutomer information
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "capitalize" }}
              >
                {customer?.firstName} &nbsp; {customer?.lastName}
              </Typography>
              <Typography variant="subtitle2">
                <a className="email-link" href={`mailto:${customer?.email}`}>
                  {customer?.email}
                </a>
              </Typography>
              <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
                {deliveryAddress?.primaryPhone?.countryCode} &nbsp;
                {deliveryAddress?.primaryPhone?.number}
              </Typography>

              <Typography variant="subtitle2">
                {deliveryAddress?.address?.building} &nbsp;
                {deliveryAddress?.address?.floor} &nbsp;
                {deliveryAddress?.address?.route?.number} &nbsp;
                {deliveryAddress?.address?.route?.kind} &nbsp;
                {deliveryAddress?.address?.route?.name}
              </Typography>
              <Typography variant="subtitle2">
                {deliveryAddress?.address?.city?.name} &nbsp;
                {deliveryAddress?.address?.postalCode}
              </Typography>
              <Typography variant="subtitle2">
                {deliveryAddress?.address?.country?.name}
              </Typography>
            </Box>
          </div>
        </div>
        {/* list for change status payment */}
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorEl={anchorElPayment}
          getContentAnchorEl={null}
          open={Boolean(anchorElPayment)}
          onClose={() => setAnchorElPayment(null)}
        >
          <MenuItem
            onClick={() => handleChangeOrderPaymentStatus(id || "", "PAID")}
            disabled={paymentStatus === "PAID"}
          >
            Paid
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderPaymentStatus(id || "", "WAITING")}
            disabled={paymentStatus === "WAITING"}
          >
            Awaiting Payment
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderPaymentStatus(id || "", "REFUNDED")}
            disabled={paymentStatus === "REFUNDED"}
          >
            Refund
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderPaymentStatus(id || "", "CANCELED")}
            disabled={paymentStatus === "CANCELED"}
          >
            Cancel
          </MenuItem>
        </Menu>
        {/* list for change status order */}
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorEl={anchorElOrder}
          getContentAnchorEl={null}
          open={Boolean(anchorElOrder)}
          onClose={() => setAnchorElOrder(null)}
        >
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "WAITING")}
            disabled={fulfillmentStatus === "WAITING"}
          >
            Awaiting processing
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "ONGOING")}
            disabled={fulfillmentStatus === "ONGOING"}
          >
            Processing
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "READY")}
            disabled={fulfillmentStatus === "READY"}
          >
            Ready for pickup
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "IN_DELIVERING")}
            disabled={fulfillmentStatus === "IN_DELIVERING"}
          >
            Shipped
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "CANCELED")}
            disabled={fulfillmentStatus === "CANCELED"}
          >
            Canceled
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "DELIVERED")}
            disabled={fulfillmentStatus === "DELIVERED"}
          >
            Delivered
          </MenuItem>
          <MenuItem
            onClick={() => handleChangeOrderStatus(id || "", "UNDELIVERED")}
            disabled={fulfillmentStatus === "UNDELIVERED"}
          >
            Not delivered
          </MenuItem>
        </Menu>
      </Wrapper>
    </>
  );
};

export default OrderDetails;
