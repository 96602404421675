import gql from "graphql-tag";

export const GET_LIST_ROLES = gql`
  query GetListRoles($input: GetListRolesInput!) {
    getListRoles(input: $input) {
      list {
        id
        kind
        permissions {
          id
          kind
          names {
            id
            languageCode
            value
          }
        }
        staff {
          id
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_OWNER = gql`
  query GetCompanyOwner($input: GetCompanyOwnerInput!) {
    getCompanyOwner(input: $input) {
      owner {
        id
        email
        informations {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_LIST_PERMISSIONS = gql`
  query GetListPermissions($input: GetListPermissionsInput!) {
    getListPermissions(input: $input) {
      list {
        id
        kind
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
      }
    }
  }
`;

export const GET_UNASSIGNED_STAFF = gql`
  query getUnassignedStaff($input: GetUnassignedStaffInput!) {
    getUnassignedStaff(input: $input) {
      list {
        id
        email
        informations {
          firstName
          lastName
        }
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      success
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      success
    }
  }
`;

export const GET_STAFF_INFORMATION = gql`
  query getStaffInformation($input: GetStaffInformationInput!) {
    getStaffInformation(input: $input) {
      information {
        id
        email
        role {
          id
          kind
        }
        permissions {
          kind
          values {
            id
            names {
              id
              languageCode
              value
            }
            descriptions {
              id
              languageCode
              value
            }
          }
        }
      }
    }
  }
`;
