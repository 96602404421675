import { ColumnsProps } from "../commons/Table";

export const columnsRawi: ColumnsProps = [
  {
    header: "Image",
    accessor: "image",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Writer",
    accessor: "writer",
  },
  {
    header: "Reader",
    accessor: "reader",
  },
  {
    header: "Duration",
    accessor: "duration",
  },
  {
    header: "Categorie",
    accessor: "categorie",
  },
  {
    header: "Actions",
    accessor: "actions",
    headerCellProps: { align: "center" },
    cellProps: { align: "center", width: "80px" },
  },
];

export const columnsCwallet: ColumnsProps = [
  {
    header: "Image",
    accessor: "image",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Store",
    accessor: "store",
  },
  {
    header: "Categorie",
    accessor: "categorie",
  },
  {
    header: "Actions",
    accessor: "actions",
    headerCellProps: { align: "center" },
    cellProps: { align: "center", width: "80px" },
  },
];
