import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 480px;
  flex-direction: column;
  padding: 20px;
  .action {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .btn-description {
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .drop-area {
    display: grid;
    grid-template-rows: 40% 60%;
    padding: 15px;
    min-width: 350px;
    background: #fcfcff;
    border: 2px dashed #f1f2fb;
    box-sizing: border-box;
    border-radius: 5px;
    label,
    button {
      margin: 10px;
    }
    .drop-area-label {
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #afb1c6;
      transition: all 0.3s ease-in-out;
    }
    .label-drag-over {
      color: #74abff;
    }
    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 11px;
      text-align: center;
      text-decoration-line: underline;
      color: rgba(175, 177, 198, 0.7);
    }
  }
  .drop-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .upload-btn {
    * {
      cursor: pointer;
    }
    input[type="file"] {
      position: absolute;
      height: 35px;
      width: 110px;
      opacity: 0;
      cursor: pointer;
    }
  }
  .MuiFormControlLabel-root {
    margin-top: 5px;
  }
`;
