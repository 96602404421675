import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 480px;
  flex-direction: column;
  padding: 15px;
  .action {
    display: flex;
    justify-content: flex-end;
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
