import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 20px;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .topbar {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .btn-description {
      margin-left: 12px;
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  h2 {
    margin: 15px 0;
  }
  .box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 5px 0;
  }
  .box-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  span.input-label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 14px;
    margin-bottom: 15px;
  }
  .status-actif {
    display: inline-flex;
    width: 60px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background: #4cd964;
    color: #ffffff;
    border-radius: 5px;
    font-style: normal;
    margin-left: 15px;
  }
  .account-info {
    margin-bottom: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    > div {
      display: flex;
      flex-direction: column;
    }
  }
  .status-inactif {
    display: inline-flex;
    width: 60px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background: #ed2800;
    color: #ffffff;
    border-radius: 5px;
    font-style: normal;
    margin-left: 15px;
  }
  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
    .MuiFilledInput-root:focus-within {
      border: 2px solid #74abff;
    }
    .MuiFilledInput-root:hover {
      background-color: rgba(0, 0, 0, 0);
    }
    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-fullWidth.MuiInputBase-formControl {
      background: #ffffff;
    }
    .form-control {
      width: 300px;
      margin-bottom: 24px;
    }
    .show {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: #ffffff;
  }
  .MuiInputBase-root.Mui-disabled {
    color: black;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #757575;
  }
  .loading {
    display: flex;
    flex: 1;
    height: calc(100vh - 250px);
    justify-content: center;
    align-items: center;
  }
  .social {
    display: grid;
    grid-template-columns: 250px 1fr 60px;
    grid-gap: 30px;
    > div {
      margin-top: 15px;
    }
  }
  .social-link {
    padding: 10px;
    background: #fafbff;
    border-radius: 8px;
    margin: 15px 0 0 0;
    display: grid;
    grid-template-columns: 25px 100px 3px auto 50px;
    grid-gap: 20px;
    span {
      margin: auto 0;
    }
    img {
      width: 100%;
      margin: auto 0;
    }
    svg {
    }
    hr {
      height: 100%;
      margin: auto;
      border: 0;
      border-left: 2px solid #dddee0;
    }
  }
`;
