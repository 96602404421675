export type Error = {
  name?: string;
  currency?: string;
  identifier?: string;
};

export const UpdateMenuValidation = (
  value: string,
  currency: string,
  identifier: string
): Error => {
  const error: Error = {
    name: undefined,
    currency: undefined,
    identifier: undefined,
  };

  if (!value) {
    error.name = "Validation.required";
  } else {
    delete error.name;
  }

  if (!currency) {
    error.currency = "Validation.required";
  } else {
    delete error.currency;
  }

  if (!identifier) {
    error.identifier = "Validation.required";
  } else {
    delete error.identifier;
  }

  return error;
};
