import React from "react";

import ContentLoader from "react-content-loader";

const LoadingListOrders: React.FC = () => {
  return (
    <ContentLoader viewBox="0 0 100% 550" height={550} width="100%">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
      <rect x="0" y="113" rx="5" ry="5" width="100%" height="100" />
      <rect x="0" y="226" rx="5" ry="5" width="100%" height="100" />
      <rect x="0" y="338" rx="5" ry="5" width="100%" height="100" />
      <rect x="0" y="451" rx="5" ry="5" width="100%" height="100" />
    </ContentLoader>
  );
};

export default LoadingListOrders;
