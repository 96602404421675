import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { useIntl } from "react-intl";

interface SearchInputProps {
  // eslint-disable-next-line
  changeFun: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submit: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ changeFun, submit }) => {
  const handleOnsubmit = (e: any) => {
    e.preventDefault();
    submit();
  };

  const intl = useIntl();
  return (
    <Wrapper>
      <form onSubmit={handleOnsubmit}>
        <input
          type="text"
          name="SearchClient"
          id="SearchClient"
          placeholder={intl.formatMessage({ id: "MenuDetails.btn.search" })}
          onChange={changeFun}
        />
        <Button
          data-testid="submit"
          type="submit"
          style={{
            color: "#FFFFFF",
            position: "relative",
            minWidth: 130,
          }}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {intl.formatMessage({ id: "MenuDetails.btn.search" })}
        </Button>
      </form>
    </Wrapper>
  );
};
export default SearchInput;

const Wrapper = styled.div`
  margin: 20px 0;
  form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      width: 100%;
      padding: 6px 16px;
      border: none;
      border-radius: 5px;
    }
  }
`;
