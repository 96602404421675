import React from "react";

import { Box, Typography } from "@material-ui/core";

import { URL_THEME_PICTURES } from "../../api";
import { CartItemPayload } from "../../api/types";

import { Wrapper, ColorInput } from "./OrderProductItemStyles";

import defaultDictureProduct from "../../img/digishop/default-picture-product.png";

type OrderProductItemType = {
  data?: CartItemPayload;
};

const OrderProductItem: React.FC<OrderProductItemType> = ({ data }) => {
  return (
    <Wrapper>
      {data?.product?.picture?.fileUrl ? (
        <img
          src={URL_THEME_PICTURES?.concat(data.product.picture.fileUrl)}
          alt="product"
        />
      ) : (
        <img src={defaultDictureProduct} alt="product" />
      )}
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
          {data?.quantity} X {data?.product?.names?.[0]?.value}
        </Typography>
        {data?.options?.map((option) => (
          <Box display="flex">
            <Typography variant="subtitle2" style={{ color: "#AFB1C6" }}>
              {option?.option?.names?.[0]?.value} &nbsp;:
            </Typography>
            <Typography variant="subtitle2" style={{ marginLeft: 5 }}>
              {option?.value?.names?.[0]?.value?.indexOf("#") === -1 ? (
                option?.value?.names?.[0]?.value
              ) : (
                <ColorInput color={option?.value?.names?.[0]?.value || ""} />
              )}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" marginLeft="15px">
        <Typography variant="subtitle2" style={{ color: "#AFB1C6" }}>
          Total &nbsp;:
        </Typography>
        <Typography variant="subtitle2" style={{ marginLeft: 5 }}>
          {data?.totalPrice?.value} {data?.totalPrice?.currency?.code}
        </Typography>
      </Box>
    </Wrapper>
  );
};

export default OrderProductItem;
