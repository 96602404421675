import * as React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation, useReactiveVar, useQuery } from "@apollo/client";
import findIndex from "lodash/findIndex";

import { ButtonCustomize, Input, Select } from "../../commons";
import { UploadPictureProduct, UploadFile } from "..";

import {
  UPDATE_MENU_TYPE_PRODUCT,
  CREATE_MENU_TYPE_PRODUCT,
  GET_LIST_MENU_TYPES,
  GET_MENU_TYPE_PRODUCTS_BOOKS_STORIES,
} from "../../api";
import {
  CreateMenuTypeProductMutation,
  CreateMenuTypeProductMutationVariables,
  UpdateMenuTypeProductMutation,
  UpdateMenuTypeProductMutationVariables,
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  GetMenuTypeProductsStoriesQuery,
  GetMenuTypeProductsStoriesQueryVariables,
  UpdateMenuTypeProductInput,
  MenuTypeProductPayload,
  KeyValueInput,
  MenuPayload,
} from "../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../api/local-state";

import { DialogStories } from "./StoriesFormModal-styles";

type StoriesFormModalProps = {
  idMenuType: string;
  open: boolean;
  title: string;
  story: MenuTypeProductPayload | undefined;
  onClose: () => void;
  onRefetch: () => void;
};

const initialState: UpdateMenuTypeProductInput = {
  token: localStorage.getItem("token") || "",
  id: "",
  isPublished: true,
};

const StoriesFormModal = ({
  idMenuType,
  open,
  title,
  story,
  onClose,
  onRefetch,
}: StoriesFormModalProps): JSX.Element => {
  const [stateStory, setStateStory] =
    React.useState<UpdateMenuTypeProductInput>(initialState);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  React.useEffect(() => {
    if (story) {
      const arrayCustomFields: KeyValueInput[] = [];
      story?.customFields?.map((item) =>
        arrayCustomFields.push({
          key: item.key,
          value: item.value,
        })
      );
      setStateStory({
        ...stateStory,
        id: story.id!,
        picture: story?.picture?.id,
        customFields: arrayCustomFields,
        description: {
          id: story?.description?.id,
          value: story?.description?.value,
        },
        relatedTo: story?.relatedTo,
      });
    }
  }, [story]);

  const { data } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token: stateStory.token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });
  const menuTypes = data?.getListMenuTypes?.list;
  const menuType = menuTypes?.find((o) => o.identifier === "BOOKS")?.id || "";

  const { data: listProducts } = useQuery<
    GetMenuTypeProductsStoriesQuery,
    GetMenuTypeProductsStoriesQueryVariables
  >(GET_MENU_TYPE_PRODUCTS_BOOKS_STORIES, {
    variables: {
      input: {
        token: stateStory.token,
        menuType,
        pageNumber: 1,
        pageCount: 200,
      },
    },
  });

  const [createStorie, { loading: loadingCreate }] = useMutation<
    CreateMenuTypeProductMutation,
    CreateMenuTypeProductMutationVariables
  >(CREATE_MENU_TYPE_PRODUCT, {
    onCompleted: () => {
      onRefetch();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
      handleClose();
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const [updateStorie, { loading: loadingUpdate }] = useMutation<
    UpdateMenuTypeProductMutation,
    UpdateMenuTypeProductMutationVariables
  >(UPDATE_MENU_TYPE_PRODUCT, {
    onCompleted: () => {
      onRefetch();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
      handleClose();
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const handleSave = () => {
    if (story?.id) {
      updateStorie({
        variables: {
          input: {
            ...stateStory,
          },
        },
      });
    } else {
      createStorie({
        variables: {
          input: {
            token: stateStory.token,
            menuType: idMenuType,
            picture: stateStory?.picture,
            copiedPictures: stateStory?.copiedPictures,
            customFields: stateStory?.customFields,
            description: stateStory?.description?.value
              ? stateStory.description.value
              : null,
            relatedTo: stateStory.relatedTo,
          },
        },
      });
    }
  };

  const handleDuration = (event: React.ChangeEvent<{ value: unknown }>) => {
    const duration = [...(stateStory?.customFields || [])];

    const position = findIndex(duration, (o) => o.key === "DURATION");

    if (position !== -1) {
      duration[position] = {
        ...duration?.[position],
        value: event.target.value as string,
      };
    } else {
      duration.push({
        key: "DURATION",
        value: event.target.value as string,
      });
    }

    setStateStory({
      ...stateStory,
      customFields: duration,
    });
  };

  const handleAudioFile = (url: string) => {
    const arrayAudioFile = [...(stateStory?.customFields || [])];

    const position = findIndex(arrayAudioFile, (o) => o.key === "AUDIO_FILE");

    if (position !== -1) {
      arrayAudioFile[position] = {
        ...arrayAudioFile?.[position],
        value: url,
      };
    } else {
      arrayAudioFile.push({
        key: "AUDIO_FILE",
        value: url,
      });
    }

    setStateStory({
      ...stateStory,
      customFields: arrayAudioFile,
    });
  };

  const handleClose = () => {
    onClose();
    setStateStory(initialState);
  };

  return (
    <DialogStories open={open} onClose={handleClose} maxWidth="md">
      <Box display="flex" width={1} flexDirection="column" minWidth={600}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <UploadPictureProduct
            title="Story picture"
            mode="Base"
            uploadTo="Product"
            urlImage={{
              id: story?.picture?.id,
              url: story?.picture?.fileUrl,
            }}
            onLoaded={(picture, copiedPictures) => {
              setStateStory({
                ...stateStory,
                picture,
                copiedPictures,
              });
            }}
          />
          <Box style={{ margin: "10px 0 18px" }}>
            <Typography variant="subtitle1" style={{ marginBottom: 5 }}>
              Audio sample
            </Typography>
            <UploadFile
              uploadTo="AUDIO_FILE"
              onLoaded={(kind, url) => {
                handleAudioFile(url);
              }}
              fileName={
                stateStory?.customFields?.find((o) => o.key === "AUDIO_FILE")
                  ?.value || ""
              }
              onUpdate={() => {}}
            />
          </Box>
          <div className="autocomplete">
            <Autocomplete
              id="Product"
              value={listProducts?.getMenuTypeProducts?.list?.find(
                (o) => o.id === stateStory.relatedTo
              )}
              options={listProducts?.getMenuTypeProducts?.list || []}
              getOptionLabel={(option) => option?.name?.value || ""}
              onChange={(event, value) =>
                value?.id &&
                setStateStory({
                  ...stateStory,
                  relatedTo: value.id,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product"
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <Box style={{ marginTop: 16 }}>
            <Select
              label="Set story duration"
              value={
                stateStory?.customFields?.find((o) => o.key === "DURATION")
                  ?.value || ""
              }
              onChange={handleDuration}
            >
              <MenuItem value="24 Hours">24 Hours</MenuItem>
              <MenuItem value="48 Hours">48 Hours</MenuItem>
              <MenuItem value="72 Hours">72 Hours</MenuItem>
              <MenuItem value="One week">One week</MenuItem>
              <MenuItem value="Two weeks">Two weeks</MenuItem>
              <MenuItem value="One month">One month</MenuItem>
            </Select>
          </Box>
          <Box style={{ marginTop: 16 }}>
            <Input
              label="Text content"
              value={stateStory?.description?.value || ""}
              onChange={(e) =>
                setStateStory({
                  ...stateStory,
                  description: {
                    ...stateStory?.description,
                    value: e.target.value,
                  },
                })
              }
              rows={3}
              multiline
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={handleClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={loadingCreate || loadingUpdate}
          >
            Submit
          </ButtonCustomize>
        </Box>
      </Box>
    </DialogStories>
  );
};

export default StoriesFormModal;
