import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Box, Typography, Tooltip, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ButtonCustomize } from "../../commons";

import { DeliveryServicePayload } from "../../api/types";

import { Delivery, SnoonuSmall } from "../../img/digishop/menu-icons";

type DeliveryCardProps = {
  DeliveryPayload: DeliveryServicePayload;
  onDelete: (id: string) => void;
};

const DeliveryCard = ({
  DeliveryPayload,
  onDelete,
}: DeliveryCardProps): JSX.Element => {
  const history = useHistory();
  const { id, name, isEnabled } = DeliveryPayload;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    history.push({
      pathname: "/edit-delivery",
      state: DeliveryPayload,
    });
    setAnchorEl(null);
  };

  const handleRemove = () => {
    id && onDelete(id);
    setAnchorEl(null);
  };

  return (
    <Card>
      <Box>
        <Box display="flex" alignItems="center" className="header">
          <Delivery />
          <Typography variant="subtitle2" className="subtitle">
            Services delivery method
          </Typography>
        </Box>
        <Box
          display="flex"
          width={130}
          alignItems="center"
          justifyContent="space-between"
          style={{ margin: "8px 0 5px 0" }}
        >
          <Typography variant="h3" className="bold-name">
            {name}
          </Typography>
          {name === "Snoonu" && <SnoonuSmall />}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <ButtonCustomize
          variant="contained"
          className="btn-menu"
          onClick={handleClick}
        >
          Actions <ExpandMoreIcon />
        </ButtonCustomize>
        <Tooltip title={isEnabled ? "Enabled" : "disabled"}>
          <Status checked={isEnabled || false} />
        </Tooltip>
      </Box>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleEdit} style={{ width: 110 }}>
          Edit
        </MenuItem>
        <MenuItem
          onClick={handleRemove}
          style={{ width: 110, color: "#ff4949" }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #edeff2;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  margin-top: 15px;
  .header {
    svg {
      margin-top: 1px;
      margin-right: 7px;
    }
    .subtitle {
      color: #8190a1;
    }
  }
  .bold-name {
    font-weight: 700;
    margin-top: -4px;
  }
  .btn-menu {
    margin-right: 15px;
    svg {
      margin-left: 5px;
    }
  }
`;

const Status = styled.div<{ checked: boolean }>`
  ${({ theme, checked }) => `
  width: 20px;
  height: 20px;
  background-color: ${
    checked ? theme.palette.success.main : theme.palette.error.main
  };
  border-radius: 50%;
  margin-left: 10px;
  `}
`;

export default DeliveryCard;
