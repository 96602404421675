import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const WrapperStyled = styled.div`
  .Loading {
    position: absolute;
    top: 48%;
    right: 48%;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr minmax(350px, 400px);
    grid-gap: 12px;

    .address-item {
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      padding: 10px 0;
      border-bottom: 2px solid #f3f4fa;
      .address-info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .primary-address {
          color: #3fb4f6;
          font-weight: 600;
        }
        .secondary-address {
          color: #c7c7c7;
          font-weight: 300;
        }
        .grayText {
          color: #c7c7c7;
        }
      }
    }
    a {
      text-decoration: none !important;
    }
    .address-List {
      height: 100%;
    }
    .address-list-message {
      height: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .MuiButton-label {
    display: block;
    font-size: 14px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const CustomerIconButton = styled(IconButton)`
  color: #f67070;
  margin: 0 4px;
`;
