/* eslint-disable no-restricted-syntax */

// eslint-disable-next-line
export function removeTypenameProp<T extends Object>(
  obj: T
): Omit<T, "__typename"> {
  let tempObj = {};
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      // need to check if the prop is an object and neither array or null
      if (
        typeof obj[property] === "object" &&
        obj[property] !== null &&
        // eslint-disable-next-line
        !(obj[property] as Object).hasOwnProperty("length")
      ) {
        tempObj = { ...tempObj, [property]: removeTypenameProp(obj[property]) };
      } else if (property !== "__typename") {
        tempObj = { ...tempObj, [property]: obj[property] };
      }
    }
  }
  return tempObj as Omit<T, "__typename">;
}
