import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ThemeProvider as ScProvider } from "styled-components";
import {
  MuiThemeProvider,
  createMuiTheme,
  Snackbar,
  CssBaseline,
} from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";
import { Alert } from "@material-ui/lab";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { lighten, darken } from "polished";

import enMessages from "./language/en";
import frMessages from "./language/fr";
import { PrivateRoute, PublicRoute } from "./commons";
import {
  Login,
  SignUp,
  ResetPassword,
  Layout,
  ConfirmAccount,
} from "./modules";
import "./App.css";

import { COMPANY_CONFIG, HOST_CSHOP, HOST_FORSA, HOST_RAWI } from "./api";
import {
  GetCompanyConfigQuery,
  GetCompanyConfigQueryVariables,
  GetCompanyConfigPayload,
} from "./api/types";
import {
  GET_LANGUAGE,
  GET_SNACKBAR_LOCAL,
  snackBarVar,
  GetSnackBarLocalQuery,
} from "./api/local-state";

const { host } = window.location;

const initialTheme = createMuiTheme({
  palette: {
    // ********* theme for default digishop ***************
    primary: { main: "#000000", contrastText: "#fff" },
    secondary: { main: "#f29f05", contrastText: "#fff" },
    text: { primary: "#444444", secondary: "#757575" },
    error: { main: "#F67070", contrastText: "#fff" },
    warning: { main: "#FCF1F1", contrastText: "#F67070" },
    success: { main: "#4CD964", contrastText: "#fff" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    h1: {
      fontSize: "28px",
      fontWeight: 400,
    },
    h2: {
      fontSize: "18px",
      fontWeight: 400,
    },
    h3: {
      fontSize: "16px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 400,
    },
    body1: {
      fontSize: "14px",
    },
    body2: {
      fontSize: "12px",
    },
  },
});

export const scTheme = {
  ...initialTheme,
};

export const ThemeConfigContext = React.createContext<
  GetCompanyConfigPayload | undefined
>(undefined);

const messages = {
  en: enMessages,
  fr: frMessages,
};

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

const App: React.FC = () => {
  const { data } = useQuery(GET_LANGUAGE);
  const language = data ? (data.language as keyof typeof messages) : "en";
  const [muiTheme, setMuiTheme] = React.useState(initialTheme);

  const { data: localSnackBar } =
    useQuery<GetSnackBarLocalQuery>(GET_SNACKBAR_LOCAL);

  const { data: dataConfig } = useQuery<
    GetCompanyConfigQuery,
    GetCompanyConfigQueryVariables
  >(COMPANY_CONFIG, {
    variables: {
      domain: host,
    },
    onCompleted: (config) => {
      const { colors } = config.getCompanyConfig;
      if (colors && colors.primary && colors.secondary) {
        setMuiTheme({
          ...muiTheme,
          palette: {
            ...muiTheme.palette,
            primary: {
              ...muiTheme.palette.primary,
              main: colors.primary,
              contrastText: "#fff",
              light: lighten(0.2, colors.primary),
              dark: darken(0.2, colors.primary),
            },
            secondary: {
              main: colors.secondary,
              contrastText: "#fff",
              light: lighten(0.2, colors.secondary),
              dark: darken(0.2, colors.secondary),
            },
          },
        });
      }
    },
  });

  const getFaviconEl = () => document.getElementById("favicon");

  React.useEffect(() => {
    const favicon: any = getFaviconEl(); // Accessing favicon element
    if (host === HOST_RAWI) {
      setMuiTheme({
        ...muiTheme,
        palette: {
          ...muiTheme.palette,
          primary: {
            ...muiTheme.palette.primary,
            main: "#233A7A",
            contrastText: "#fff",
            light: lighten(0.2, "#233A7A"),
            dark: darken(0.1, "#233A7A"),
          },
          secondary: {
            main: "#F58220",
            contrastText: "#fff",
            light: lighten(0.2, "#F58220"),
            dark: darken(0.2, "#F58220"),
          },
        },
      });
      favicon.href = "/favicon-rawi.ico";
      document.title = "Rawi";
    }
    if (host === HOST_CSHOP) {
      setMuiTheme({
        ...muiTheme,
        palette: {
          ...muiTheme.palette,
          primary: {
            ...muiTheme.palette.primary,
            main: "#1F2532",
            contrastText: "#fff",
            light: lighten(0.2, "#1F2532"),
            dark: darken(0.2, "#1F2532"),
          },
          secondary: {
            main: "#F9CD10",
            contrastText: "#fff",
            light: lighten(0.2, "#F9CD10"),
            dark: darken(0.2, "#F9CD10"),
          },
        },
      });
      favicon.href = "/favicon-cshop.ico";
      document.title = "Cshop";
    }
    if (host === HOST_FORSA) {
      setMuiTheme({
        ...muiTheme,
        palette: {
          ...muiTheme.palette,
          primary: {
            ...muiTheme.palette.primary,
            main: "#2982EA",
            contrastText: "#fff",
            light: lighten(0.2, "#2982EA"),
            dark: darken(0.2, "#2982EA"),
          },
          secondary: {
            main: "#F57415",
            contrastText: "#fff",
            light: lighten(0.2, "#F57415"),
            dark: darken(0.2, "#F57415"),
          },
        },
      });
      favicon.href = "/favicon-forus.ico";
      document.title = "Forus";
    }
  }, [host]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    snackBarVar({ open: false, severity: undefined, message: "" });
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="App">
        <MuiThemeProvider theme={muiTheme}>
          <ThemeConfigContext.Provider value={dataConfig?.getCompanyConfig}>
            <ScProvider /* theme={scTheme} */ theme={muiTheme}>
              {/* MUI's styles will inject before styled-components */}
              <StylesProvider injectFirst>
                <CssBaseline />
                <Router>
                  <Switch>
                    <PublicRoute path="/login" component={Login} exact />
                    <PublicRoute
                      path="/confirm/:code"
                      component={ConfirmAccount}
                      exact
                    />
                    <PublicRoute path="/signup" component={SignUp} exact />
                    <PublicRoute
                      path="/reset-password"
                      component={ResetPassword}
                      exact
                    />
                    <PrivateRoute path="/" component={Layout} />
                  </Switch>
                </Router>
              </StylesProvider>
            </ScProvider>
          </ThemeConfigContext.Provider>
        </MuiThemeProvider>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={localSnackBar?.snackBar?.open}
          autoHideDuration={4000}
          onClose={handleClose}
          TransitionComponent={TransitionUp}
        >
          <Alert variant="filled" severity={localSnackBar?.snackBar?.severity}>
            <strong>{localSnackBar?.snackBar?.message}</strong>
          </Alert>
        </Snackbar>
      </div>
    </IntlProvider>
  );
};

export default App;
