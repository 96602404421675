import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Tabs, Tab, Box, Switch } from "@material-ui/core";
import { Wrapper } from "./TabAddressesStyle";

import { Input } from "../../commons";

import {
  CurrencyPayload,
  // ProductSiteInput,
  CompanySitePayload,
} from "../../api/types";

type ProductSite = {
  site: string | null;
  price?: {
    value: string | null;
    currency?: string | null;
  };
  discount?: {
    percentage?: number | null;
    price: {
      value: string | null;
      currency?: string | null;
    };
  };
  quantity?: string | null;
};

type TabAddressesProps = {
  title?: string;
  addresses?: CompanySitePayload[];
  currency?: CurrencyPayload | null;
};

type TabPanelProps = {
  index: any;
  value: any;
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        background: "#fff",
        border: "2px solid #F3F4FA",
        borderRadius: 10,
      }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabAddresses: React.FC<TabAddressesProps> = ({
  title,
  addresses,
  currency,
  // sites,
  // onReturn,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(0);
  const [differentPrice, setDifferentPrice] = useState(false);
  const [differentStock, setDifferentStock] = useState(false);
  const [state, setState] = useState<ProductSite[]>([]);

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    addresses?.forEach((address) => {
      setState((pervState) => {
        const arraySite = [...pervState];
        address.id && arraySite.push({ site: address.id });
        return arraySite;
      });
    });
  }, [addresses]);

  const handleChangePrice = (action: boolean) => {
    setState((prevState) => {
      const arrayState = [...prevState];
      if (action) {
        for (let i = 1; i < arrayState.length; i++) {
          arrayState[i] = {
            ...arrayState[i],
            discount: {
              percentage: null,
              price: {
                ...arrayState[i].discount?.price,
                value: null,
              },
            },
            price: {
              currency: arrayState[i].price?.currency,
              value: null,
            },
          };
        }
      } else {
        for (let i = 1; i < arrayState.length; i++) {
          arrayState[i] = {
            ...arrayState[i],
            discount: {
              percentage: arrayState[0].discount?.percentage,
              price: {
                ...arrayState[0].discount?.price,
                value: arrayState[0].discount?.price.value || "",
              },
            },
            price: {
              currency: arrayState[0].price?.currency,
              value: arrayState[0].price?.value || "",
            },
          };
        }
      }
      return arrayState;
    });
  };

  const handleChangeStock = (action: boolean) => {
    setState((prevState) => {
      const arrayState = [...prevState];
      if (action) {
        for (let i = 1; i < arrayState.length; i++) {
          arrayState[i] = {
            ...arrayState[i],
            quantity: null,
          };
        }
      } else {
        for (let i = 1; i < arrayState.length; i++) {
          arrayState[i] = {
            ...arrayState[i],
            quantity: arrayState[0].quantity!,
          };
        }
      }
      return arrayState;
    });
  };

  const handleReturn = () => {
    // let arraySites: ProductSiteInput[] = [];
    // state.forEach((item, index) => {
    //   arraySites.push({
    //     site: item.site,
    //     price: {
    //       currency: item.price?.currency,
    //       value: parseFloat(item.price?.value!),
    //     },
    //     discount: {
    //       percentage: item.discount?.percentage,
    //       price: {
    //         currency: item.discount?.price?.currency,
    //         value:
    //           (item.discount?.price?.value &&
    //             parseFloat(item.discount?.price?.value)) ||
    //           0,
    //       },
    //     },
    //     quantity: (item.quantity && parseFloat(item.quantity)) || 0,
    //   });
    // });
    // onReturn && onReturn(arraySites, differentPrice, differentStock);
  };

  useEffect(() => {
    if (!differentPrice && !differentStock) {
      setValue(0);
    }
    handleReturn();
  }, [differentPrice, differentStock]);

  const long = addresses?.length || 0;

  return (
    <Wrapper>
      <Tabs value={value} textColor="primary" onChange={handleChange}>
        {addresses?.map((address, index) => (
          <Tab
            label={address.identifier}
            {...a11yProps(index)}
            key={address.id}
            disabled={!differentPrice && !differentStock}
          />
        ))}
      </Tabs>
      {addresses?.map((address, index) => (
        <TabPanel value={value} index={index} key={address.id}>
          <h3>{title}</h3>
          <div className="switchs-options">
            <div>
              <Switch
                color="secondary"
                checked={differentPrice}
                onChange={(e) => {
                  setDifferentPrice(e.target.checked);
                  handleChangePrice(e.target.checked);
                }}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span className="action-style">
                {intl.formatMessage({ id: "ProductForm.differentAddresses" })}
              </span>
            </div>
            <div>
              <Switch
                color="secondary"
                checked={differentStock}
                onChange={(e) => {
                  setDifferentStock(e.target.checked);
                  handleChangeStock(e.target.checked);
                }}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span className="action-style">
                {intl.formatMessage({ id: "ProductForm.differentStock" })}
              </span>
            </div>
          </div>
          <div className="form-inputs-tab">
            <Input
              label={
                intl.formatMessage({ id: "ProductForm.price" }) + currency?.code
              }
              value={state?.[index]?.price?.value || ""}
              onChange={(e) => {
                e.persist();
                setState((prevState) => {
                  const arraySits = [...prevState];
                  arraySits[index].price = {
                    currency: currency?.id,
                    value: e.target.value,
                  };
                  for (let i = index + 1; i < long; i++) {
                    arraySits[i] = {
                      ...arraySits[index],
                      site: arraySits[i]?.site,
                    };
                  }
                  return arraySits;
                });
              }}
              onBlur={handleReturn}
              disabled={!differentPrice ? index > 0 : false}
            />
            <Input
              label={intl.formatMessage({ id: "ProductForm.discount" })}
              value={state?.[index]?.discount?.percentage || ""}
              onChange={(e) => {
                e.persist();
                setState((prevState) => {
                  const arraySits = [...prevState];
                  const priceValue = arraySits?.[index]?.price?.value || "";
                  arraySits[index].discount = {
                    percentage: parseInt(e.target.value, 10),
                    price: {
                      currency: currency?.id,
                      value: String(
                        parseFloat(priceValue) -
                          (parseFloat(priceValue) *
                            parseInt(e.target.value, 10)) /
                            100
                      ),
                    },
                  };
                  for (let i = index + 1; i < long; i++) {
                    arraySits[i] = {
                      ...arraySits[index],
                      site: arraySits[i].site,
                    };
                  }
                  return arraySits;
                });
              }}
              onBlur={handleReturn}
              disabled={!differentPrice ? index > 0 : false}
            />
            <Input
              label={intl.formatMessage({ id: "ProductForm.afterDiscount" })}
              value={state?.[index]?.discount?.price?.value || ""}
              onChange={(e) => {
                e.persist();
                setState((prevState) => {
                  const arraySits = [...prevState];
                  const priceValue = arraySits?.[index]?.price?.value || "";
                  const resultPercentage =
                    ((parseFloat(priceValue) - parseInt(e.target.value, 10)) /
                      parseFloat(priceValue)) *
                    100;
                  arraySits[index].discount = {
                    percentage: resultPercentage,
                    price: {
                      currency: currency?.id,
                      value: e.target.value,
                    },
                  };
                  for (let i = index + 1; i < long; i++) {
                    arraySits[i] = {
                      ...arraySits[index],
                      site: arraySits[i].site,
                    };
                  }
                  return arraySits;
                });
              }}
              onBlur={handleReturn}
              disabled={!differentPrice ? index > 0 : false}
            />
            <div>
              <h3>{intl.formatMessage({ id: "ProductForm.quantity" })}</h3>
              <Input
                label={intl.formatMessage({
                  id: "ProductForm.quantity",
                })}
                value={state?.[index]?.quantity || ""}
                onChange={(e) => {
                  e.persist();
                  setState((prevState) => {
                    const arraySits = [...prevState];
                    arraySits[index].quantity = e.target.value;
                    for (let i = index + 1; i < long; i++) {
                      arraySits[i] = {
                        ...arraySits[index],
                        site: arraySits[i].site,
                      };
                    }
                    return arraySits;
                  });
                }}
                onBlur={handleReturn}
                disabled={!differentStock ? index > 0 : false}
              />
            </div>
          </div>
        </TabPanel>
      ))}
    </Wrapper>
  );
};

export default TabAddresses;
