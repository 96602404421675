/* eslint-disable  @typescript-eslint/no-unused-vars */

import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
// import URL for upload
import { URL, URL_THEME_PICTURES } from "../api";
import { CopiedPictureInput } from "../api/types";
import { snackBarVar } from "../api/local-state";

type ImageInput = {
  url?: string | null;
  id?: string | null;
};

type UploadType = {
  uploadTo: "Category" | "Product" | "Promotion" | "logo" | null;
  idMenu?: string;
  idPicture?: string | null;
  urlImage?: ImageInput | null;
  onReturnPictures?: (
    id: string | null,
    listPicture: CopiedPictureInput[]
  ) => void;
  imageBase64?: ImageInput | null;
  onReturnimageBase64?: (imageBase64?: ImageInput | null) => void;
};

const UploadPictureVaraint: React.FC<UploadType> = ({
  uploadTo,
  idMenu,
  idPicture,
  urlImage,
  onReturnPictures,
  imageBase64,
  onReturnimageBase64,
}) => {
  const intl = useIntl();
  const fileUploader = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState<FileList | null>(null);
  const [image, setImage] = React.useState<ImageInput | null | undefined>(null);

  useEffect(() => {
    setImage(imageBase64);
  }, [imageBase64]);

  const handleClick = () => {
    fileUploader.current?.click();
  };

  const getUrl = () => {
    switch (uploadTo) {
      case "logo":
        return `${idMenu}/MENU_LOGO`;
      case "Category":
        return `${idMenu}/MENU_CATEGORY_PICTURE`;
      case "Product":
        return `${idMenu}/CATEGORY_PRODUCT_PICTURE`;
      case "Promotion":
        return "public/MENU_AD_PICTURE";
      default:
        return `${idMenu}/MENU_LOGO`;
    }
  };

  const url = `${URL}/${getUrl()}`;

  const handleUpload = (fileArray: FileList | null) => {
    fileArray && setFile(fileArray);
    if (fileArray && fileArray[0] !== null && fileArray[0] !== undefined) {
      setLoading(true);
      const dataForm = new FormData();
      dataForm.append("files", fileArray[0]);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.send(dataForm);
      xhr.onload = () => {
        if (xhr.status !== 200) {
          // analyze HTTP status of the response
          // alert(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
          snackBarVar({
            open: true,
            severity: "error",
            message: intl.formatMessage({
              id: "Upload.error",
            }),
          });
          setImage(null);
          setFile(null);
          setLoading(false);
        } else {
          // show the result
          const response = JSON.parse(xhr.response);
          // responseText is the server
          response && response.files[0].id && setLoading(false);
          onReturnPictures &&
            response &&
            onReturnPictures(response.files[0].id, response.files[0].copied);
        }
      };
      xhr.onerror = (e) => {
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({
            id: "Upload.error",
          }),
        });
        setImage(null);
        setFile(null);
        setLoading(false);
      };
    }
  };

  useEffect(() => {
    if (file && file[0] !== null && file[0] !== undefined) {
      // setImage(null);
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e: any) => {
        setImage({ url: e.target.result, id: null });
        onReturnimageBase64 &&
          onReturnimageBase64({ url: e.target.result, id: null });
      };
    }
  }, [file]);

  useEffect(() => {
    if (file === null && urlImage && urlImage.id !== null && urlImage.url) {
      setImage({
        id: urlImage.id,
        url: URL_THEME_PICTURES?.concat(urlImage.url),
      });
    }
  }, [urlImage]);

  return (
    <Container>
      {image?.url && !loading ? (
        <img
          role="presentation"
          src={image?.url}
          alt="file preview"
          className="file-preview"
          onClick={handleClick}
        />
      ) : (
        <FontAwesomeIcon
          icon={faImage}
          size="2x"
          className={loading ? "icon-upload__loading" : "icon-upload"}
          onClick={handleClick}
        />
      )}
      <input
        ref={fileUploader}
        type="file"
        disabled={loading}
        onChange={(e) => handleUpload(e.target.files)}
      />
    </Container>
  );
};

export default UploadPictureVaraint;

export const Container = styled.div`
  .icon-upload {
    min-width: 35px;
    height: auto;
    padding: 0 3px;
    cursor: pointer;
    border: 3px solid #dcdcdc;
    border-radius: 10px;
  }
  .icon-upload__loading {
    width: 35px;
    height: auto;
    padding: 0 3px;
    cursor: pointer;
    border: 3px solid #dcdcdc;
    border-radius: 10px;
    animation: colorchange 2s;
    animation-iteration-count: infinite;
  }
  input {
    display: none;
  }

  .file-preview {
    min-width: 35px;
    height: 30px;
    border-radius: 10px;
    object-fit: cover;
    border: 3px solid #dcdcdc;
    padding: 2px;
  }

  @keyframes colorchange {
    0% {
      border-top: 3px solid #558ee4;
      border-radius: 10px;
    }
    25% {
      border-right: 3px solid #558ee4;
      border-radius: 10px;
    }
    50% {
      border-bottom: 3px solid #558ee4;
      border-radius: 10px;
    }
    75% {
      border-left: 3px solid #558ee4;
      border-radius: 10px;
    }
  }
`;
