import React, { useEffect } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";

import { Modal } from ".";

type ImageInput = {
  url: string;
  id: string | null;
};

type FileType = {
  file: FileList | null;
  progress: number;
  // eslint-disable-next-line
  onDelete: (id: string) => void;
  urlImage?: ImageInput | null;
};

type ProgressType = {
  progress: number;
};

const File: React.FC<FileType> = ({ file, progress, onDelete, urlImage }) => {
  const intl = useIntl();

  const [image, setImage] = React.useState<ImageInput | null>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  useEffect(() => {
    if (file && file[0] !== null && file[0] !== undefined) {
      setImage(null);
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e: any) => {
        setImage({ url: e.target.result, id: null });
      };
    }
  }, [file]);

  useEffect(() => {
    setImage(urlImage);
  }, [urlImage]);

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleValues = () => {
    onDelete(image?.id || "");
    setOpenModalDelete(false);
  };

  return (
    <Wrapper progress={progress}>
      <Modal
        open={openModalDelete}
        title={intl.formatMessage({ id: "File.modalTitle" })}
        handleClose={handleClose}
        handleContent={handleValues}
        message={intl.formatMessage({ id: "File.modalMessage" })}
        action="delete"
      />
      <img src={image?.url} alt="file preview" className="file-preview" />
      {!image || (file && file[0]) ? (
        <div className="file-upload-container">
          <label className="file-name">{file && file[0].name}</label>
          {progress === 100 && (
            <Tooltip
              title={intl.formatMessage({ id: "File.tooltipDelete" })}
              placement="bottom"
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => {
                  setOpenModalDelete(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} color="#F67070" size="sm" />
              </button>
            </Tooltip>
          )}
        </div>
      ) : (
        <Tooltip
          title={intl.formatMessage({ id: "File.tooltipDelete" })}
          placement="bottom"
        >
          <button
            type="button"
            className="cancel-btn mt"
            onClick={() => setOpenModalDelete(true)}
          >
            <FontAwesomeIcon icon={faTrash} color="#F67070" size="sm" />
          </button>
        </Tooltip>
      )}
      {(!image || (file && file[0])) && (
        <div className="actions">
          <Progress progress={progress}>
            <label className="contrast">
              {intl.formatMessage({ id: "File.onUpload" })}
            </label>
            <div className="progress-status">
              <label
                className={progress < 100 ? "percentage" : "percentage done"}
              >
                {progress < 100
                  ? intl.formatMessage({ id: "File.onUpload" })
                  : intl.formatMessage({ id: "File.uploaded" })}
              </label>
            </div>
          </Progress>
        </div>
      )}
    </Wrapper>
  );
};

const Progress = styled.div<ProgressType>`
  display: flex;
  justify-content: center;
  height: 24px;
  background-color: #f3f4f9;
  border-radius: 12px;
  align-items: center;
  color: #afb1c6;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  .percentage {
    color: white;
    margin-left: 35px;
  }
  label.done {
    margin-left: 49px;
  }
  .contrast {
    position: absolute;
    color: #afb1c6;
  }
  .progress-status {
    position: absolute;
    width: ${(props) => `${props.progress}%`};
    background-color: ${(props) =>
      props.progress < 100 ? "#74ABFF" : "#4CD964"};
    left: 0;
    border-radius: 2px;
    transition: all 0.4s ease-in;
    height: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;

const Wrapper = styled.div<ProgressType>`
  background: #fafbff;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-rows: 100px auto auto;
  .file-upload-container {
    display: grid;
    grid-template-columns: auto 24px;
    padding: 10px 0 0 0;
  }
  .file-preview {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: contain;
  }
  .cancel-btn {
    width: 24px;
    height: 24px;
    border: 0;
    outline: none;
    background-color: #fcf1f1;
    border-radius: 12px;
    margin: auto;
    cursor: pointer;
  }
  .actions {
    display: grid;
    grid-template-columns: auto;
    height: 34px;
    transition: all ease-in-out;
    overflow: hidden;
    animation: ${(props) =>
      props.progress === 100 && "show 0.4s ease-in-out 3s forwards"};
  }
  .file-name {
    color: rgba(97, 97, 97, 0.9);
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 24px;
  }
  .mt {
    margin: 10px auto 0;
  }
  @keyframes show {
    0% {
      height: 34px;
    }
    100% {
      height: 0px;
    }
  }
`;

export default File;
