import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Switch,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useMutation, useReactiveVar } from "@apollo/client";

import { Modal } from "../../commons";
import { PaymentFormModal } from "..";

import { REMOVE_PAYMENT_METHOD, GET_LIST_PAYMENT_METHODS } from "../../api";
import {
  RemovePaymentMethodMutation,
  RemovePaymentMethodMutationVariables,
  PaymentMethodPayload,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { Fatora, Stripe, Twyla, Paymee } from "../../img/digishop/menu-icons";

import { Container } from "./CardPayment--style";

type CardPaymentProps = {
  dataPayment: PaymentMethodPayload;
};

const CardPayment: React.FC<CardPaymentProps> = ({ dataPayment }) => {
  const token = localStorage.getItem("token") || "";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [RemovePaymentMethod, { loading: loadingDelete }] = useMutation<
    RemovePaymentMethodMutation,
    RemovePaymentMethodMutationVariables
  >(REMOVE_PAYMENT_METHOD, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        id: dataPayment?.id || "",
      },
    },
    onCompleted: () => {
      setOpenModalDelete(false);
    },
    refetchQueries: () => [
      {
        query: GET_LIST_PAYMENT_METHODS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onError: (error) => {
      if (error.message === "payment_method_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Payment method not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      {dataPayment.kind === "STRIPE" && <Stripe />}
      {dataPayment.kind === "TWYLA" && (
        <img src={Twyla} className="img-logo-payment" alt="twyla" />
      )}
      {dataPayment.kind === "PAYMEE" && (
        <img src={Paymee} className="img-logo-payment" alt="paymee" />
      )}
      <Box width={1} marginLeft={2}>
        <Typography variant="h2" style={{ fontWeight: 700 }}>
          Credit or debit card
        </Typography>
        <Typography variant="subtitle1" className="title-payment">
          {dataPayment.kind}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Switch
          size="small"
          checked={dataPayment?.isEnabled || false}
          onClick={(e) => e.preventDefault()}
        />
      </Box>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setOpen(true);
          }}
        >
          Modify
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenModalDelete(true);
          }}
          style={{ color: "#F67070" }}
        >
          Delete
        </MenuItem>
      </Menu>
      <PaymentFormModal
        open={open}
        title="Modify payment method"
        handleClose={() => setOpen(false)}
        dataPayment={dataPayment}
      />
      <Modal
        open={openModalDelete}
        title="Delete payment method"
        message="Are you sure you want to remove payment method"
        handleClose={() => setOpenModalDelete(false)}
        handleContent={RemovePaymentMethod}
        action="delete"
        loading={loadingDelete}
      />
    </Container>
  );
};

export default CardPayment;
