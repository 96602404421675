export type Errors = {
  parent?: string;
  identifier?: string;
};

export const ValidationCategoryForm = (identifier: string): Errors => {
  const errors: Errors = {};

  if (!identifier) {
    errors.identifier = "Validation.required";
  }
  return errors;
};

export const getCheckSpecialCaratcters = (status: boolean | undefined) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "error";
    default:
      return "initial";
  }
};
export const getCheckAlphanumeric = (status: boolean | undefined) => {
  switch (status) {
    case undefined:
      return "initial";
    case true:
      return "success";
    case false:
      return "error";
    default:
      return "initial";
  }
};
