import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Button,
  Checkbox,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQuery } from "@apollo/client";

import { Wrapper, Note, DropDown, MenuList } from "./CollectionStyle";
import { apparence } from "../../img";
import { Box, LoadingList } from "../../commons";
import { ListDataEmpty } from "../../components";
import { GETLISTFILTERCOLLECTIONS } from "../../api";
import {
  GetListFilterCollectionsQuery,
  GetListFilterCollectionsQueryVariables,
  FilterCollectionPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

const Collection: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();
  const ref = useRef<any>(null);

  const [getFilterCollection, setGetFilterCollection] =
    useState<FilterCollectionPayload | null>(null);

  const [OpenMenu, setOpenMenu] = useState<boolean>(false);

  const menu = SelectedMenuVar();
  const { data: listFilterCollections, loading } = useQuery<
    GetListFilterCollectionsQuery,
    GetListFilterCollectionsQueryVariables
  >(GETLISTFILTERCOLLECTIONS, {
    variables: {
      token,
      menu: menu?.id || "",
    },
  });

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleClickAway = () => {
    setOpenMenu(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper>
        <Note>
          <div className="title">
            <img src={apparence} alt="products" />
            {intl.formatMessage({ id: "ProductForm.ProductManagement" })}
            &nbsp;| &nbsp;
            <span>
              {intl.formatMessage({ id: "collection.listCollection" })}
            </span>
          </div>
          <div className="action">
            <Link to="add-collection">
              <Button
                variant="contained"
                color="primary"
                className="btn-action"
              >
                Nouvelle collection
              </Button>
            </Link>
          </div>
        </Note>
        {listFilterCollections?.getListFilterCollections?.list &&
          listFilterCollections.getListFilterCollections.list.length > 0 && (
            <Box p={20} width={1} className="container">
              <h3>Liste des collections</h3>
              <div className="collections" ref={ref}>
                <div className="collections__header">
                  <span>Nom du collection</span>
                  <span>Description</span>
                  <span>Categorie</span>
                  <span>Produit</span>
                  <span>Variations</span>
                  <span>Prix</span>
                  <span />
                </div>
                <div className="collections__list">
                  {listFilterCollections?.getListFilterCollections?.list?.map(
                    (item) => (
                      <div className="collections__item" key={item.id}>
                        <span>{item?.names?.[0]?.value}</span>
                        <span>{item?.descriptions?.[0]?.value}</span>
                        <span>
                          <Checkbox
                            checked={
                              !!(
                                item?.categories && item.categories?.length > 0
                              ) || false
                            }
                            inputProps={{ "aria-label": " checkbox" }}
                            disabled
                          />
                        </span>
                        <span>
                          <Checkbox
                            checked={
                              !!(item?.products && item.products?.length > 0) ||
                              false
                            }
                            inputProps={{ "aria-label": " checkbox" }}
                            disabled
                          />
                        </span>
                        <span>
                          {/* it's a comment because there is a change from api */}
                          <Checkbox
                            // checked={
                            //   !!(item?.variants && item.variants?.length > 0) ||
                            //   false
                            // }
                            inputProps={{ "aria-label": " checkbox" }}
                            disabled
                          />
                        </span>
                        <span>
                          <Checkbox
                            checked={
                              !!(
                                item?.priceRange &&
                                Object.keys(item.priceRange).length > 0
                              ) || false
                            }
                            inputProps={{ "aria-label": " checkbox" }}
                            disabled
                          />
                        </span>
                        <span>
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={() => {
                              handleOpenMenu();
                              setGetFilterCollection(item);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <DropDown
                            show={
                              OpenMenu && getFilterCollection?.id === item.id
                            }
                          >
                            <ul>
                              <MenuList
                                onClick={() => {
                                  setOpenMenu(false);
                                }}
                              >
                                {intl.formatMessage({ id: "MenuCard.manage" })}
                              </MenuList>
                              <MenuList
                                danger
                                onClick={() => {
                                  setOpenMenu(false);
                                }}
                              >
                                {intl.formatMessage({ id: "MenuCard.delete" })}
                              </MenuList>
                            </ul>
                          </DropDown>
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Box>
          )}
        {loading && <LoadingList height={ref?.current?.offsetHeight} />}
        {/* if list appearance is empty */}
        {listFilterCollections?.getListFilterCollections?.list &&
          listFilterCollections.getListFilterCollections.list.length === 0 && (
            <ListDataEmpty
              image={apparence}
              description={intl.formatMessage({ id: "appearance.listEmpty" })}
              btnName={intl.formatMessage({ id: "appearance.addCollection" })}
              url="add-appearance"
            />
          )}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default Collection;
