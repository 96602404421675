import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Paper,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import findIndex from "lodash/findIndex";

import {
  GETLISTLANGUAGES,
  GETLISTCURRENCIES,
  GETLISTMENUS,
  UPDATEMENU,
  GET_LIST_PAYMENT_METHODS,
  GET_MENU_DELIVERY_SETTINGS_GENERAL,
} from "../../../api";
import {
  GetListLanguagesQuery,
  GetListCurrenciesQuery,
  GetListCurrenciesQueryVariables,
  GetListMenuQuery,
  GetListMenuQueryVariables,
  UpdateMenuMutation,
  UpdateMenuMutationVariables,
  GetListPaymentMethodsQuery,
  GetListPaymentMethodsQueryVariables,
  MenuPayload,
  UpdateNameInput,
  UpdateDescriptionInput,
  LanguageInput,
  LanguageCode,
  GetMenuDeliverySettingsGeneralQuery,
  GetMenuDeliverySettingsGeneralQueryVariables,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Select,
} from "../../../commons";

import StoreSettings from "../../../img/digishop/menu-icons/StoreSettings.svg";

import { Wrapper } from "./GeneralSettings--styles";

const GeneralSettings: React.FC = () => {
  const token = localStorage.getItem("token") || "";

  const intl = useIntl();

  const [idCurrency, setIdCurrency] = useState<string>("");
  const [currencyDisplay, setCurrencyDisplay] = useState<string>("");
  const [podIsEnabled, setPodIsEnabled] = useState<boolean>(false);
  const [pricePrecision, setPricePrecision] = useState<number>(0);
  const [stockIsActive, setStockIsActive] = useState<boolean>(false);
  const [pisIsEnabled, setPisIsEnabled] = useState<boolean>(false);
  const [enableMultilanguage, setEnableMultilanguage] =
    useState<boolean>(false);
  const [primaryLanguage, setPrimaryLanguage] = useState<string>("");
  const [secondaryLanguages, setSecondaryLanguages] = useState<string[]>([]);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const { data: languageList } = useQuery<GetListLanguagesQuery>(
    GETLISTLANGUAGES,
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: listCurrencies } = useQuery<
    GetListCurrenciesQuery,
    GetListCurrenciesQueryVariables
  >(GETLISTCURRENCIES);

  const { data, refetch } = useQuery<
    GetListMenuQuery,
    GetListMenuQueryVariables
  >(GETLISTMENUS, {
    variables: {
      token,
    },
    skip: !token,
    onCompleted: (dataMenu) => {
      const arraySecondaryLanguages: string[] = [];

      const menu = dataMenu?.getListMenus?.list?.filter(
        (item) => item?.id === localMenu?.id
      )?.[0];

      if (menu) {
        menu.currency?.id && setIdCurrency(menu.currency.id);
        menu.settings?.currencyDisplay &&
          setCurrencyDisplay(menu.settings?.currencyDisplay);
        menu.settings?.podIsEnabled &&
          setPodIsEnabled(menu.settings.podIsEnabled);
        menu?.settings?.pricePrecision &&
          setPricePrecision(menu.settings.pricePrecision);
        menu?.settings?.stockIsActive &&
          setStockIsActive(menu.settings.stockIsActive);
        menu?.settings?.pisIsEnabled &&
          setPisIsEnabled(menu?.settings?.pisIsEnabled);
        menu?.languages?.forEach((item) => {
          if (item.isMain) {
            item?.language?.id && setPrimaryLanguage(item?.language?.id);
          } else {
            item?.language?.id &&
              arraySecondaryLanguages.push(item?.language?.id);
          }
        });
        setSecondaryLanguages(arraySecondaryLanguages);
      }
    },
  });

  const { data: listPaymentMethods } = useQuery<
    GetListPaymentMethodsQuery,
    GetListPaymentMethodsQueryVariables
  >(GET_LIST_PAYMENT_METHODS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
  });

  const { data: listDelivery } = useQuery<
    GetMenuDeliverySettingsGeneralQuery,
    GetMenuDeliverySettingsGeneralQueryVariables
  >(GET_MENU_DELIVERY_SETTINGS_GENERAL, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
  });

  useEffect(() => {
    secondaryLanguages?.length > 0 && setEnableMultilanguage(true);
  }, [secondaryLanguages]);

  const [UpdateMenu, { loading }] = useMutation<
    UpdateMenuMutation,
    UpdateMenuMutationVariables
  >(UPDATEMENU, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Operation successful",
      });
      refetch();
    },
    onError: (error) => {
      if (error.message === "menu_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Menu not found",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const getLanguage = (code: string) => {
    switch (code) {
      case "FR":
        return intl.formatMessage({ id: "Language.french" });
      case "EN":
        return intl.formatMessage({ id: "Language.english" });
      case "DE":
        return intl.formatMessage({ id: "Language.german" });

      case "ES":
        return intl.formatMessage({ id: "Language.spanish" });

      case "IT":
        return intl.formatMessage({ id: "Language.italian" });

      case "AR":
        return intl.formatMessage({ id: "Language.arabic" });

      case "PT":
        return intl.formatMessage({ id: "Language.portogese" });

      case "JA":
        return intl.formatMessage({ id: "Language.japanese" });

      case "TR":
        return intl.formatMessage({ id: "Language.turkish" });

      case "ZH":
        return intl.formatMessage({ id: "Language.chinese" });

      default:
        return null;
    }
  };

  const handlePaymentManagment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const lengthListPayment =
      listPaymentMethods?.getListPaymentMethods?.list?.length || 0;
    if (lengthListPayment > 0) {
      setPodIsEnabled(event.target.checked);
    }
  };

  const handlePickup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataDelivery =
      listDelivery?.getMenuDeliverySettings?.deliveryServices;
    const position = findIndex(dataDelivery, (o) => o.isEnabled === true);
    if (position !== -1) setPisIsEnabled(event.target.checked);
  };

  const handleSubmit = () => {
    const arrayNameInput: UpdateNameInput[] = [];
    const arrayDescriptionInput: UpdateDescriptionInput[] = [];
    const arrayLanguageInput: LanguageInput[] = [];

    const menu = data?.getListMenus?.list?.filter(
      (item) => item?.id === localMenu?.id
    )?.[0];

    menu?.names?.forEach((name) =>
      arrayNameInput.push({
        id: name?.id || "",
        languageCode: name?.languageCode as LanguageCode,
        value: name?.value,
      })
    );

    menu?.descriptions?.forEach((description) =>
      arrayDescriptionInput.push({
        id: description?.id,
        languageCode: description?.languageCode as LanguageCode,
        value: description?.value,
      })
    );

    arrayLanguageInput.push({
      language: primaryLanguage,
      isMain: true,
    });

    secondaryLanguages?.forEach((language) =>
      arrayLanguageInput.push({
        language,
        isMain: false,
      })
    );

    UpdateMenu({
      variables: {
        menu: {
          token,
          id: menu?.id || "",
          identifier: menu?.identifier || "",
          names: arrayNameInput,
          descriptions: arrayDescriptionInput,
          languages: arrayLanguageInput,
          currency: idCurrency,
          settings: {
            currencyDisplay,
            podIsEnabled,
            pricePrecision,
            stockIsActive,
            pisIsEnabled,
          },
          isPublished: menu?.isPublished,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        width={1}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <BreadCrumbsTitle
          labelIntl={["Store settings", "General Settings"]}
          icon={StoreSettings}
          iconAlt="Payment"
        />
        <ButtonCustomize
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={loading}
        >
          Save
        </ButtonCustomize>
      </Box>
      <Box display="flex" flexDirection="column" component={Paper} p={2}>
        <Typography style={{ fontSize: 22, fontWeight: 700, margin: 0 }}>
          Product Settings
        </Typography>
        <Box marginTop={1}>
          <Typography variant="h2" style={{ marginBottom: 8 }}>
            Currency
          </Typography>
          <Box display="flex" width={800} marginBottom={1}>
            <Select
              label="Select Currency"
              value={idCurrency}
              style={{ marginRight: 10 }}
              onChange={(e) => setIdCurrency(e.target.value as string)}
            >
              {listCurrencies?.getListCurrencies?.list?.map((currency) => (
                <MenuItem key={currency?.id || ""} value={currency?.id || ""}>
                  {currency?.code}
                </MenuItem>
              ))}
            </Select>
            <Input
              label="Currency appearance in store"
              value={currencyDisplay}
              onChange={(e) => setCurrencyDisplay(e.target.value)}
            />
          </Box>
          <Typography variant="h2" style={{ marginBottom: 8 }}>
            Payment settings
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={podIsEnabled}
                onChange={handlePaymentManagment}
                color="secondary"
              />
            }
            label="Enable hand to hand payment managment"
            labelPlacement="end"
          />

          <Typography
            variant="subtitle2"
            style={{ color: "#85879E", maxWidth: 600, marginBottom: 5 }}
          >
            Enabling this feature will lend you the ability to make payment
            changments on order manualy and track hand to hand payment
            applications. A hand to hand option will be added to your payment
            method in the store automaticaly.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={pisIsEnabled}
                onChange={handlePickup}
                color="secondary"
              />
            }
            label="pickup from retail"
            labelPlacement="end"
          />
          <Typography variant="h2" style={{ marginTop: 10 }}>
            Price
          </Typography>
          <Typography variant="subtitle1" style={{ margin: "5px 0 10px 0" }}>
            Select Value Precision
          </Typography>
          <Box display="flex">
            <ButtonCustomize
              variant="contained"
              color={pricePrecision === 0 ? "secondary" : "default"}
              onClick={() => setPricePrecision(0)}
              style={{ marginRight: 10 }}
            >
              0
            </ButtonCustomize>
            <ButtonCustomize
              variant="contained"
              color={pricePrecision === 1 ? "secondary" : "default"}
              onClick={() => setPricePrecision(1)}
              style={{ marginRight: 10 }}
            >
              0.0
            </ButtonCustomize>
            <ButtonCustomize
              variant="contained"
              color={pricePrecision === 2 ? "secondary" : "default"}
              onClick={() => setPricePrecision(2)}
              style={{ marginRight: 10 }}
            >
              0.00
            </ButtonCustomize>
            <ButtonCustomize
              variant="contained"
              color={pricePrecision === 3 ? "secondary" : "default"}
              onClick={() => setPricePrecision(3)}
            >
              0.000
            </ButtonCustomize>
          </Box>
          <Typography variant="h2" style={{ margin: "15px 0 8px 0" }}>
            Product managment
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={stockIsActive}
                onClick={() => setStockIsActive(!stockIsActive)}
                color="secondary"
              />
            }
            label="Let stock control availablity of products"
            labelPlacement="end"
          />
          <Typography
            variant="subtitle2"
            style={{ color: "#85879E", maxWidth: 600 }}
          >
            Setting “Let stock control availablity of products” will set a
            product unavailable in store if the product’s quantity equals to 0.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        component={Paper}
        marginTop={2}
        p={2}
      >
        <Typography style={{ fontSize: 22, fontWeight: 700, margin: 0 }}>
          Change Store Language settings
        </Typography>

        {languageList?.getListLanguages?.list && (
          <Autocomplete
            options={languageList?.getListLanguages?.list || []}
            getOptionLabel={(option) => getLanguage(option.code || "") || ""}
            value={languageList?.getListLanguages?.list?.find(
              (item) => item?.id === primaryLanguage
            )}
            onChange={(event, value) => setPrimaryLanguage(value?.id || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Set Primary language"
                InputLabelProps={{ shrink: true }}
                style={{ maxWidth: 500, margin: "15px 0" }}
              />
            )}
          />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={enableMultilanguage}
              onClick={() => {
                setEnableMultilanguage(!enableMultilanguage);
                setSecondaryLanguages([]);
              }}
              color="secondary"
            />
          }
          label="Enable Multilanguage"
          style={{ width: 200 }}
        />

        {languageList?.getListLanguages?.list && enableMultilanguage && (
          <Autocomplete
            options={languageList?.getListLanguages?.list || []}
            getOptionLabel={(option) => getLanguage(option.code || "") || ""}
            value={languageList?.getListLanguages?.list?.filter(
              (item) => item.id && secondaryLanguages.includes(item.id)
            )}
            onChange={(event, value) => {
              const arrayLanguages: string[] = [];
              value.map((item) => item.id && arrayLanguages.push(item.id));
              setSecondaryLanguages(arrayLanguages);
            }}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Set Secondary language"
                InputLabelProps={{ shrink: true }}
                style={{ maxWidth: 500, marginTop: 15 }}
              />
            )}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default GeneralSettings;
