import { ChangePasswordForm } from "./Account";

type Error = ChangePasswordForm;

export const ValidationChangePassword = (values: ChangePasswordForm): Error => {
  const errors: Error = {
    oldPassword: undefined,
    newPassword: undefined,
    confirmNewPassword: undefined,
  };

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "oldPassword":
        if (!values.oldPassword) {
          errors.oldPassword = "Validation.required";
        } else {
          delete errors.oldPassword;
        }
        break;
      case "newPassword":
        if (!values.newPassword) {
          errors.newPassword = "Validation.required";
        } else {
          delete errors.newPassword;
        }
        break;
      case "confirmNewPassword":
        if (!values.confirmNewPassword) {
          errors.confirmNewPassword = "Validation.required";
        } else if (!(values.newPassword === values.confirmNewPassword)) {
          errors.confirmNewPassword = "Validation.notConfirmPassword";
        } else {
          delete errors.confirmNewPassword;
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
