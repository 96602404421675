import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 250px;
  align-items: center;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  .topbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-description {
      margin-left: 12px;
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  h2 {
    margin: 15px 0;
  }
`;
