import gql from "graphql-tag";

export const GET_EASYPAY_LIST_CATEGORIES = gql`
  query GetEasypayListCategories($input: GetEasyPayListCategoriesInput!) {
    getEasypayListCategories(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GET_EASYPAY_LIST_REGIONS = gql`
  query GetEasypayListRegions($input: GetEasyPayListRegionsInput!) {
    getEasypayListRegions(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GET_EASYPAY_LIST_CARDS = gql`
  query GetEasypayListCards($input: GetEasyPayListCardsInput!) {
    getEasypayListCards(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GET_EASYPAY_LIST_CARD_CLASSES = gql`
  query GetEasypayListCardClasses($input: GetEasyPayListCardClassesInput!) {
    getEasypayListCardClasses(input: $input) {
      list {
        id
        name
        imageUrl
        price
        retailPrice
      }
    }
  }
`;
