import { Button } from "@material-ui/core";
import styled from "styled-components";

const ButtonCustomize = styled(Button)`
  min-width: 40px;
  max-height: 40px;
  text-transform: capitalize;
`;

export default ButtonCustomize;
