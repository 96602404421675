/* eslint-disable  @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { Tooltip, ClickAwayListener } from "@material-ui/core";
import { useApolloClient, useQuery } from "@apollo/client";

// eslint-disable-next-line
import { RegisterMerchant } from "../../components";
import ListMenuNav from "../ListMenuNav";
import { getUrlStores } from "../../utils";

import { GETLISTMENUS, HOST_CSHOP, HOST_FORSA, HOST_RAWI } from "../../api";
import { GET_STEP, SelectedMenuVar } from "../../api/local-state";
import { GetListMenuQuery, GetListMenuQueryVariables } from "../../api/types";

import { Container, Divider, Nav, Wrapper } from "./MainLayoutStyle";
import { Store, Account, Signout, Add } from "../../img/digishop/menu-icons";
import opLogo from "../../img/digishop/op-logo.png";
import rawiLogo from "../../img/Rawi/rawiLogo.svg";
import cwalletLogo from "../../img/cwallet/cwalletLogo.svg";
import forusLogoWhite from "../../img/forus/forus-logo-white.png";

type ListItemState = {
  id?: string;
  name?: string;
  selected?: boolean;
};

const { host } = window.location;

const MainLayout: React.FC<ListItemState> = ({
  id,
  name,
  selected,
  children,
}) => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const client = useApolloClient();
  const intl = useIntl();
  const { pathname } = history.location;

  const [open, setOpen] = useState<boolean>(false);
  const [hidden, setHidden] = useState(true);
  const [openStoreList, setOpenStoreList] = useState<boolean>(false);
  const [logo, setLogo] = useState(opLogo);

  const { data } = useQuery(GET_STEP);
  const { data: listStores } = useQuery<
    GetListMenuQuery,
    GetListMenuQueryVariables
  >(GETLISTMENUS, {
    variables: {
      token,
    },
    onCompleted: ({ getListMenus }) => {
      SelectedMenuVar(getListMenus.list[0]);
      if (getListMenus.list.length === 0) {
        history.push("/home");
      }
    },
  });

  const getKind = async () => {
    const kind = (await localStorage.getItem("kind")) || "";
    return kind;
  };

  useEffect(() => {
    if (host === HOST_RAWI) {
      setLogo(rawiLogo);
    }
    if (host === HOST_CSHOP) {
      setLogo(cwalletLogo);
    }
    if (host === HOST_FORSA) {
      setLogo(forusLogoWhite);
    }
  }, [host]);

  useEffect(() => {
    if (data && data.step) {
      document.getElementById("snack")?.classList.remove("hide");
      document.getElementById("snack")?.classList.add("show");
    }
  }, [data]);

  return (
    <Wrapper>
      <Nav id="nav" style={hidden ? { minWidth: "70px" } : {}}>
        <div className="nav-list">
          <ul>
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <Tooltip
              title={intl.formatMessage({ id: "Store.title" })}
              placement="right"
              arrow
              onClick={() => setHidden(!hidden)}
            >
              <li className={getUrlStores(pathname) ? "item-selected" : "item"}>
                <Store
                  className={
                    getUrlStores(pathname) ? "selected-menu" : "menu-item"
                  }
                />
              </li>
            </Tooltip>
          </ul>
          <ul>
            <Tooltip
              title={intl.formatMessage({ id: "Account.title" })}
              placement="right"
              arrow
            >
              <li
                className={pathname === "/account" ? "item-selected" : "item"}
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    getKind().then((kind) => {
                      kind === "Vendor"
                        ? history.push("/account-vendor")
                        : history.push("/account");
                    });
                    setHidden(true);
                  }}
                  onKeyUp={() => {
                    getKind().then((kind) => {
                      kind === "Vendor"
                        ? history.push("/account-vendor")
                        : history.push("/account");
                    });
                    setHidden(true);
                  }}
                >
                  <Account
                    width="26"
                    className={
                      pathname === "/account" ? "selected-menu" : "menu-item"
                    }
                  />
                </div>
              </li>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage({ id: "sign.out" })}
              placement="right"
              arrow
            >
              <li className={pathname === "/login" ? "item-selected" : "item"}>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    history.push("/login");
                    localStorage.clear();
                    client.resetStore();
                  }}
                  onKeyUp={() => {
                    history.push("/login");
                    localStorage.clear();
                    client.resetStore();
                  }}
                  data-testid="button-logout"
                >
                  <Signout width="32" className="logout" />
                </div>
              </li>
            </Tooltip>
          </ul>
        </div>

        {/* ############ MENU  ############ */}
        <div
          className={`nav-list-menu ${hidden && "nav-list-menu-hidden"}`}
          id="nav-list-menu"
        >
          <ClickAwayListener onClickAway={() => setOpenStoreList(false)}>
            <div className="topbar-container">
              <Divider />
              <div className="topbar-actions">
                {listStores && listStores?.getListMenus?.list?.length > 0 ? (
                  <div
                    role="button"
                    tabIndex={0}
                    className="store-list"
                    onKeyUp={() => setOpenStoreList(!openStoreList)}
                    onClick={() => setOpenStoreList(!openStoreList)}
                  >
                    <span>
                      {SelectedMenuVar() &&
                        SelectedMenuVar()?.names?.[0]?.value}
                    </span>
                    {/* <ChevronDown className="down-arrow" /> */}
                    <i
                      className={`arrow-store ${openStoreList ? "down" : ""}`}
                    />
                    <div
                      className={`store-list-option ${
                        openStoreList ? "show" : "hide"
                      }`}
                    >
                      <ul>
                        {listStores?.getListMenus.list?.map((store) => (
                          <li key={store?.id}>
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => SelectedMenuVar(store)}
                              onKeyUp={() => SelectedMenuVar(store)}
                              className={
                                SelectedMenuVar()?.id === store?.id
                                  ? "store store-selected"
                                  : "store"
                              }
                            >
                              <span>{store?.names?.[0]?.value}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div
                    className="new-store"
                    id="store__button--create"
                    role="button"
                    tabIndex={0}
                    onKeyUp={() => {
                      setOpenStoreList(!openStoreList);
                      history.push("/create-store");
                    }}
                    onClick={() => {
                      setOpenStoreList(!openStoreList);
                      history.push("/create-store");
                    }}
                  >
                    <Add width="20" />
                    <span>Create your first store</span>
                  </div>
                )}
              </div>
            </div>
          </ClickAwayListener>

          {/* MENU NAVIGATION */}
          {listStores?.getListMenus.list.length !== 0 && <ListMenuNav />}
        </div>
      </Nav>
      <Container>{children}</Container>
      <RegisterMerchant open={open} handleClose={() => setOpen(false)} />
    </Wrapper>
  );
};

export default MainLayout;
