import gql from "graphql-tag";

export const GET_STORE_STATS_SUMMARY = gql`
  query getStoreStatsSummary($input: GetStoreStatsSummaryInput!) {
    getStoreStatsSummary(input: $input) {
      summary {
        totalProducts
        totalCustomers
        totalNewsletter
        totalGiftcards
        totalPromocodes
      }
    }
  }
`;

export const GET_STORE_VISITS = gql`
  query getStoreVisits($input: GetStoreVisitsInput!) {
    getStoreVisits(input: $input) {
      list {
        date
        details {
          hour
          count
        }
        dayVisits
      }
    }
  }
`;

export const GET_STORE_REVENUE = gql`
  query GetStoreRevenue($input: GetStoreRevenueInput!) {
    getStoreRevenue(input: $input) {
      list {
        date
        dailyRevenue
        details {
          hour
          count
        }
      }
    }
  }
`;
