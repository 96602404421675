import gql from "graphql-tag";

// get gift cards list
export const GET_GIFT_CARDS = gql`
  query GetGiftCards($input: GetListGiftCardsInput!) {
    getListGiftCards(input: $input) {
      list {
        id
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        price {
          value
          currency {
            id
            code
          }
        }
        customers {
          customer
        }
        groups {
          group
        }
        codes {
          email
          code
        }
      }
    }
  }
`;

// Create gift cards
export const CREATE_GIFT_CARD = gql`
  mutation CreateGiftCard($input: CreateGiftCardInput!) {
    createGiftCard(input: $input) {
      id
    }
  }
`;
