import * as React from "react";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useReactiveVar, useMutation } from "@apollo/client";

import { ButtonCustomize, Input, Select } from "../../commons";

import {
  GETLISTMENUCATEGORIES,
  GET_MENU_LEGALITY_SETTINGS,
  GET_MENU_NAVIGATION,
  SET_NAVIGATION,
} from "../../api";

import {
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  GetMenuLegalitySettingsQuery,
  GetMenuLegalitySettingsQueryVariables,
  SetNavigationMutation,
  SetNavigationMutationVariables,
  NavigationKind,
  MenuPayload,
  SubNavigationPayload,
} from "../../api/types";

import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

type FormMenuItemModalProps = {
  open: boolean;
  idItem: string | undefined;
  parent: string | undefined;
  subNavigation: SubNavigationPayload | undefined;
  order: number;
  onClose: () => void;
};

const FormMenuItemModal = ({
  open,
  idItem,
  parent,
  subNavigation,
  onClose,
  order,
}: FormMenuItemModalProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [label, setLabel] = React.useState<string | undefined>(undefined);
  const [typeLink, setTypeLink] = React.useState<NavigationKind>("CUSTOM_LINK");
  const [text, setText] = React.useState<string | undefined>(undefined);
  const [linkId, setLinkId] = React.useState<string | undefined>(undefined);
  const [isHierarchical, setIsHierarchical] = React.useState<boolean>(false);

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: typeLink !== "CATEGORY_LINK",
  });

  const listCategoryLevel1 = listCategory?.getListMenuCategories?.list?.filter(
    (item) => item.level === 1
  );

  const { data: listLigality } = useQuery<
    GetMenuLegalitySettingsQuery,
    GetMenuLegalitySettingsQueryVariables
  >(GET_MENU_LEGALITY_SETTINGS, {
    variables: {
      input: {
        token,
        id: localMenu?.id || "",
      },
    },
    skip: typeLink !== "PAGE_LINK",
  });

  const listPagesLink = listLigality?.getMenuLegalitySettings?.pages;

  React.useEffect(() => {
    if (subNavigation) {
      subNavigation.label && setLabel(subNavigation.label);
      subNavigation.kind && setTypeLink(subNavigation.kind as NavigationKind);
      subNavigation.text && setText(subNavigation.text);
      subNavigation.linkTo && setLinkId(subNavigation.linkTo);
      subNavigation.isHierarchical &&
        setIsHierarchical(subNavigation.isHierarchical);
    }
  }, [subNavigation]);

  const [handleItem, { loading }] = useMutation<
    SetNavigationMutation,
    SetNavigationMutationVariables
  >(SET_NAVIGATION, {
    onCompleted: () => {
      onClose();
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
      setLabel(undefined);
      setTypeLink("CUSTOM_LINK");
      setText(undefined);
      setLinkId(undefined);
      setIsHierarchical(false);
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_MENU_NAVIGATION,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
  });

  const handleInitial = () => {
    setLabel(undefined);
    setTypeLink("CUSTOM_LINK");
    setText(undefined);
    setLinkId(undefined);
    setIsHierarchical(false);
  };

  const handleSubmit = () => {
    handleItem({
      variables: {
        input: {
          token,
          id: idItem,
          store: localMenu?.id || "",
          label,
          kind: typeLink,
          text,
          link_id: linkId,
          parent,
          isMain: false,
          isHierarchical,
          isDeleted: false,
          order,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        handleInitial();
      }}
      maxWidth="md"
    >
      <Box display="flex" width={1} flexDirection="column" minWidth={450}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          marginBottom={2}
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Package
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          {typeLink !== "CATEGORY_LINK" && (
            <Input
              label="Item label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              style={{ marginBottom: 15 }}
            />
          )}
          <Select
            label="Link Type"
            value={typeLink}
            onChange={(e) => {
              setTypeLink(e.target.value as NavigationKind);
              e.target.value === "CATEGORY_LINK" && setLabel(undefined);
            }}
            style={{ marginBottom: 15 }}
          >
            <MenuItem value="CATEGORY_LINK">Category Link</MenuItem>
            <MenuItem value="CUSTOM_LINK">Custom link</MenuItem>
            <MenuItem value="PAGE_LINK">Page link</MenuItem>
          </Select>
          {typeLink === "CATEGORY_LINK" && (
            <Select
              label="Choose category"
              value={linkId}
              onChange={(e) => setLinkId(e.target.value as string)}
              style={{ marginBottom: 10 }}
            >
              {listCategoryLevel1?.map((item) => (
                <MenuItem value={item?.id || ""} key={item.id}>
                  {item?.names?.[0]?.value}
                </MenuItem>
              ))}
            </Select>
          )}
          {typeLink === "CUSTOM_LINK" && (
            <Input
              label="Set URL"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          )}
          {typeLink === "PAGE_LINK" && (
            <Select
              label="Choose page"
              value={linkId}
              onChange={(e) => setLinkId(e.target.value as string)}
            >
              {listPagesLink?.map((item) => (
                <MenuItem value={item.id || ""} key={item.id}>
                  {item.title?.[0]?.value}
                </MenuItem>
              ))}
            </Select>
          )}
          {typeLink === "CATEGORY_LINK" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  checked={isHierarchical}
                  onChange={(e) => setIsHierarchical(e.target.checked)}
                  color="secondary"
                />
              }
              label="Include sub-categories drop down menu"
            />
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => {
              onClose();
              handleInitial();
            }}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </ButtonCustomize>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FormMenuItemModal;
