import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  WeekView,
  Appointments,
} from "@devexpress/dx-react-scheduler-material-ui";

import appointments from "./today-appointments";

import { BreadCrumbsTitle } from "../../commons";

import { Wrapper } from "./calendar-styles";

const Calendar = (): JSX.Element => {
  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Sales Management ", "Calendar"]}
        iconAlt="calendar"
      />
      <Paper>
        <Scheduler data={appointments} height={700}>
          <WeekView startDayHour={9} endDayHour={19} />
          <Appointments />
        </Scheduler>
      </Paper>
    </Wrapper>
  );
};

export default Calendar;
