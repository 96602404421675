/* eslint-disable */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
  CircularProgress,
  Box,
  IconButton,
  Typography,
  Chip,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import generatePassword from "generate-password";
import { useQuery, useMutation } from "@apollo/client";

import { BreadCrumbsTitle, InputSimple, Input } from "../../commons";

import {
  GET_LIST_MENU_PRODUCTS,
  GETLISTFILTERCOLLECTIONS,
  GETLISTMENUCATEGORIES,
  GET_LIST_CUSTOMERS,
  GETSUBSCRIBERSLIST,
  GETLISTGROUP,
  CREATEPROMOCODE,
} from "../../api";
import {
  GetListFilterCollectionsQuery,
  GetListFilterCollectionsQueryVariables,
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  GetListMenuProductsQuery,
  GetListMenuProductsQueryVariables,
  GetListCustomersQuery,
  GetListCustomersQueryVariables,
  GetListMenuSubscribersQuery,
  GetListMenuSubscribersQueryVariables,
  GetListMenuGroupsQuery,
  GetListMenuGroupsQueryVariables,
  FilterCollectionPayload,
  MenuCategoryPayload,
  MenuProductPayload,
  CustomerPayload,
  MenuSubscriberPayload,
  MenuGroupPayload,
  CreatePromoCodeMutation,
  CreatePromoCodeMutationVariables,
  PromoCodeKind,
  AllOrSpecific,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

import { PromoCodeImg } from "../../img";

import { Wrapper, Note, Container } from "./PromoCodeFormStyle";

interface MainErrorType {
  isError: {
    promo: boolean;
    percentage: boolean;
    fixed: boolean;
  };
  promoError: string;
  percentageError: string;
  fixedPriceError: string;
}

const PromoCodeForm: React.FC = () => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";
  const history = useHistory();

  const [valuePercentage, setValuePercentage] = useState<string>("");
  const [fixedPrice, setFixedPrice] = useState<string>("");
  const [nbTimesUsedValue, setNbTimesUsedValue] = useState<string>("");
  const [minimumCartTotalQuantityValue, setMinimumCartTotalQuantityValue] =
    useState<string>("");
  const [minimumCartTotalPriceValue, setMinimumCartTotalPriceValue] =
    useState<string>("");
  const [
    minimumPastOrdersTotalQuantityValue,
    setMinimumPastOrdersTotalQuantityValue,
  ] = useState<string>("");
  const [
    minimumPastOrdersTotalPriceValue,
    setMinimumPastOrdersTotalPriceValue,
  ] = useState<string>("");

  const [errorHandling, setErrorHandling] = useState<MainErrorType>({
    isError: {
      percentage: false,
      promo: false,
      fixed: false,
    },
    promoError: "Store.promocode.error.PromoCode",
    percentageError: "Store.promocode.error.percentage",
    fixedPriceError: "Store.promocode.error.fixedPrice",
  });

  const [valueType, setValueType] = React.useState<string>("PERCENTAGE");
  const [valueApplied, setValueApplied] = React.useState<string>("");
  const [valueAvailable, setValueAvailable] = React.useState<string>("");
  const [valueMinReq, setValueMinReq] = React.useState<string>("");

  const [showNewPromo, setShowNewPromo] = useState<boolean>(true);
  const [showApplied, setShowApplied] = useState<boolean>(true);
  const [showAvailable, setShowAvailable] = useState<boolean>(true);
  const [showMinReq, setShowMinReq] = useState<boolean>(true);
  const [showLimit, setShowLimit] = useState<boolean>(true);

  const [nbTimesUsed, setNbTimesUsed] = useState<boolean>(false);
  const [oneUse, setOneUse] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<boolean>(false);

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const [selectedCollection, setSelectedCollection] = React.useState<
    FilterCollectionPayload[]
  >([]);
  const [selectedCategorie, setSelectedCategorie] = React.useState<
    MenuCategoryPayload[]
  >([]);
  const [PromoCode, setPromoCode] = useState<string>("");
  const [selectedGroup, setSelectedGroup] = useState<MenuGroupPayload[]>([]);
  const [selectedNewsletterSub, setSelectedNewsletterSub] = useState<
    MenuSubscriberPayload[]
  >([]);
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerPayload[]>(
    []
  );
  const [selectedProducts, setSelectedProducts] = useState<
    MenuProductPayload[]
  >([]);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: listMenuProducts } = useQuery<
    GetListMenuProductsQuery,
    GetListMenuProductsQueryVariables
  >(GET_LIST_MENU_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
    skip: !localMenu?.menuItem?.id,
  });

  const { data: collectionList, loading: loadingCollection } = useQuery<
    GetListFilterCollectionsQuery,
    GetListFilterCollectionsQueryVariables
  >(GETLISTFILTERCOLLECTIONS, {
    variables: {
      token: token,
      menu: localMenu?.menuItem?.id || "",
    },
  });
  const { data: categorieList, loading: loadingCategorie } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.menuItem?.id || "" } },
  });

  const { data: customersList, loading: loadingCustomers } = useQuery<
    GetListCustomersQuery,
    GetListCustomersQueryVariables
  >(GET_LIST_CUSTOMERS, {
    variables: {
      input: {
        token: token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
  });
  const { data: subscribersList, loading: loadingSubscribers } = useQuery<
    GetListMenuSubscribersQuery,
    GetListMenuSubscribersQueryVariables
  >(GETSUBSCRIBERSLIST, {
    variables: {
      input: {
        token: token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
  });
  const { data: groupList, loading: loadingGroup } = useQuery<
    GetListMenuGroupsQuery,
    GetListMenuGroupsQueryVariables
  >(GETLISTGROUP, {
    variables: {
      input: {
        token: token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
  });

  const [addPromoCode, { loading: loadingPromo }] = useMutation<
    CreatePromoCodeMutation,
    CreatePromoCodeMutationVariables
  >(CREATEPROMOCODE, {
    onCompleted: () => {
      history.push("/promo-code");
    },
    onError: (error) => {
      if (error.message === "code_already_exists")
        snackBarVar({
          open: true,
          severity: "error",
          message: "code already exists",
        });
      else if (error.message === "invalid_request")
        snackBarVar({
          open: true,
          severity: "error",
          message: "Invalid request",
        });
      else
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
    },
  });

  const checkErrors = () => {
    let errorHandlingClone = Object.assign({}, errorHandling);
    if (valueType === "PERCENTAGE") {
      if (valuePercentage === "") {
        errorHandlingClone.isError.percentage = true;
      } else {
        errorHandlingClone.isError.percentage = false;
      }
    } else if (valueType === "FIXED_PRICE") {
      if (fixedPrice === "") {
        errorHandlingClone.isError.fixed = true;
      } else {
        errorHandlingClone.isError.fixed = false;
      }
    }
    if (PromoCode === "") {
      errorHandlingClone.isError.promo = true;
    } else {
      errorHandlingClone.isError.promo = false;
    }
    setErrorHandling(errorHandlingClone);
    handleCreation();
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueType((event.target as HTMLInputElement).value);
    setErrorHandling({
      ...errorHandling,
      isError: {
        ...errorHandling.isError,
        fixed: false,
        percentage: false,
      },
    });
    handleResetInput();
  };
  const handleAppliedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueApplied((event.target as HTMLInputElement).value);
    handleResetApplied();
  };
  const handleAvailableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueAvailable((event.target as HTMLInputElement).value);
    handleResetAvailable();
  };
  const handleMinReqChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueMinReq((event.target as HTMLInputElement).value);
    handleResetMinReq();
  };

  const handlePromoGeneration = () => {
    let promo = generatePassword.generate({
      length: 8,
      uppercase: true,
      lowercase: false,
    });
    setPromoCode(promo);
    setErrorHandling({
      ...errorHandling,
      isError: {
        ...errorHandling.isError,
        promo: false,
      },
    });
  };

  const handleCreation = () => {
    if (
      !errorHandling.isError.fixed &&
      !errorHandling.isError.percentage &&
      !errorHandling.isError.promo
    ) {
      let values_price: number | null = null;
      let values_percentage: number | null = null;
      let values_currency: string | null = null;
      let startDt: Date | null = null;
      let endDt: Date | null = null;
      let maximumUses: number | null = null;
      let type_kind: PromoCodeKind | null = null;

      if (valueType === "PERCENTAGE") {
        values_percentage = parseInt(valuePercentage);
        type_kind = "PERCENTAGE";
      } else if (valueType === "FIXED_PRICE") {
        values_price = parseInt(fixedPrice);
        values_currency = localMenu?.menuItem?.currency?.code || "";
        type_kind = "FIXED_PRICE";
      } else {
        type_kind = "FREE_SHIPPING";
      }
      if (startDate) {
        startDt = selectedStartDate;
      }
      if (endDate) {
        endDt = selectedEndDate;
      }
      if (nbTimesUsed) {
        maximumUses = parseInt(nbTimesUsedValue);
      }
      let reqKind: AllOrSpecific = "ALL";
      let minimumCartTotalPrice: number | null = null;
      let minimumCartTotalQuantity: number | null = null;
      let minimumPastOrdersTotalPrice: number | null = null;
      let minimumPastOrdersTotalQuantity: number | null = null;

      if (valueMinReq !== "All-users" && valueMinReq !== "") {
        reqKind = "SPECIFIC";
        if (valueMinReq === "MinQuantityofItems") {
          minimumCartTotalQuantity = parseInt(minimumCartTotalQuantityValue);
        } else if (valueMinReq === "MinPurchaseAmount") {
          minimumCartTotalPrice = parseInt(minimumCartTotalPriceValue);
        } else if (valueMinReq === "MinVerifiedOrderPurchase") {
          minimumPastOrdersTotalQuantity = parseInt(
            minimumPastOrdersTotalQuantityValue
          );
        } else if (valueMinReq === "MinVerifiedOrderAmount") {
          minimumPastOrdersTotalPrice = parseInt(
            minimumPastOrdersTotalPriceValue
          );
        }
      }
      let kindTarget: AllOrSpecific = "ALL";
      let selectedCus: { customer: string }[] | null = null;
      let selectedSubs: { subscriber: string }[] | null = null;
      let selectedGrp: { group: string }[] | null = null;

      if (valueAvailable !== "allUsers" && valueAvailable !== "") {
        kindTarget = "SPECIFIC";
        if (valueAvailable === "specCustomer") {
          selectedCus = [];
          selectedCustomers.forEach((item) => {
            selectedCus?.push({ customer: item?.id || "" });
          });
        } else if (valueAvailable === "newsletterSub") {
          selectedSubs = [];
          selectedNewsletterSub.forEach((item) => {
            selectedSubs?.push({ subscriber: item?.id || "" });
          });
        } else if (valueAvailable === "specGroup") {
          selectedGrp = [];
          selectedGroup.forEach((item) => {
            selectedGrp?.push({ group: item?.id || "" });
          });
        }
      }
      let kindFilter: AllOrSpecific = "ALL";
      let selectedColl: { collection: string }[] | null = null;
      let selectedCat: { category: string }[] | null = null;
      let selectedProd: { product: string }[] | null = null;

      if (valueApplied !== "Order" && valueApplied !== "") {
        kindFilter = "SPECIFIC";
        if (valueApplied === "collection") {
          selectedColl = [];
          selectedCollection.forEach((item) => {
            selectedColl?.push({ collection: item?.id || "" });
          });
        } else if (valueApplied === "categorie") {
          selectedCat = [];
          selectedCategorie.forEach((item) => {
            selectedCat?.push({ category: item?.id || "" });
          });
        } else if (valueApplied === "product") {
          selectedProd = [];
          selectedProducts.forEach((item) => {
            selectedProd?.push({ product: item?.id || "" });
          });
        }
      }

      addPromoCode({
        variables: {
          input: {
            token: token,
            menu: localMenu?.menuItem?.id || "",
            code: PromoCode,
            kind: type_kind,
            values: {
              percentage: values_percentage,
              price: {
                currency: localMenu?.menuItem?.currency?.id || "",
                value: values_price,
              },
            },
            limits: {
              endDate: endDt,
              isUsedOnce: oneUse,
              maximumUses: maximumUses,
              startDate: startDt,
            },
            filters: {
              categories: selectedCat,
              collections: selectedColl,
              products: selectedProd,
              kind: kindFilter,
            },
            requirements: {
              minimumCartTotalPrice: minimumCartTotalPrice,
              minimumCartTotalQuantity: minimumCartTotalQuantity,
              minimumPastOrdersTotalPrice: minimumPastOrdersTotalPrice,
              minimumPastOrdersTotalQuantity: minimumPastOrdersTotalQuantity,
              kind: reqKind,
            },
            targets: {
              customers: selectedCus,
              groups: selectedGrp,
              subscribers: selectedSubs,
              kind: kindTarget,
            },
          },
        },
      });
    }
  };

  const handleResetInput = () => {
    setValuePercentage("");
    setFixedPrice("");
  };

  const handleResetApplied = () => {
    setSelectedCollection([]);
    setSelectedCategorie([]);
    setSelectedProducts([]);
  };

  const handleResetAvailable = () => {
    setSelectedCustomers([]);
    setSelectedNewsletterSub([]);
    setSelectedGroup([]);
  };

  const handleResetMinReq = () => {
    setMinimumCartTotalPriceValue("");
    setMinimumCartTotalQuantityValue("");
    setMinimumPastOrdersTotalQuantityValue("");
    setMinimumPastOrdersTotalPriceValue("");
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({ id: "Store.ProductManagment" }),
            intl.formatMessage({ id: "Store.ProductManagment.Promo" }),
          ]}
          icon={PromoCodeImg}
          iconAlt="PromoCodeImg"
        />
        <div className="action">
          <Button
            variant="contained"
            color="primary"
            className="btn-action"
            onClick={checkErrors}
            disabled={loadingPromo}
          >
            {intl.formatMessage({ id: "appearance.save" })}
            {loadingPromo && (
              <CircularProgress size={24} style={{ position: "absolute" }} />
            )}
          </Button>
        </div>
      </Note>
      <Box
        p={3}
        width={1}
        className={`container-1 ${!showNewPromo && "container-1__hidden"}`}
      >
        <div className="header">
          <Typography variant="h2">
            {intl.formatMessage({ id: "Store.PromoCode.AddPromo.Generate" })}
          </Typography>
          <IconButton
            onClick={() => {
              setShowNewPromo(!showNewPromo);
            }}
          >
            <i className={`arrow ${showNewPromo && "down"}`} />
          </IconButton>
        </div>
        {showNewPromo && (
          <>
            <div className="error_container">
              {errorHandling.isError.promo && (
                <span>
                  {intl.formatMessage({ id: errorHandling.promoError })}
                </span>
              )}
              {errorHandling.isError.fixed && (
                <span>
                  {intl.formatMessage({ id: errorHandling.fixedPriceError })}
                </span>
              )}
              {errorHandling.isError.percentage && (
                <span>
                  {intl.formatMessage({ id: errorHandling.percentageError })}
                </span>
              )}
            </div>
            <div className="form generatePromo">
              <div className="row generate">
                <Input
                  label="Promo Code"
                  value={PromoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  fullWidth
                  error={errorHandling.isError.promo}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-action"
                  onClick={handlePromoGeneration}
                >
                  {intl.formatMessage({ id: "Store.PromoCode.Generate" })}
                </Button>
              </div>
              <Typography variant="subtitle1" style={{ marginTop: "25px" }}>
                {intl.formatMessage({ id: "Store.PromoCode.Type" })}
              </Typography>
              <div className="row">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="Type"
                    name="Type1"
                    value={valueType}
                    onChange={handleTypeChange}
                  >
                    <div className="percentage RadioWithInput">
                      <FormControlLabel
                        value="PERCENTAGE"
                        control={<Radio color="primary" />}
                        label={intl.formatMessage({
                          id: "Store.PromoCode.type.percentage",
                        })}
                      />
                      <InputSimple
                        disabled={valueType !== "PERCENTAGE"}
                        value={valuePercentage}
                        onKeyPress={onKeyPress}
                        onChange={(event) =>
                          setValuePercentage(event.target.value)
                        }
                        error={errorHandling.isError.percentage}
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                      />
                    </div>
                    <div className="Fixed RadioWithInput">
                      <FormControlLabel
                        value="FIXED_PRICE"
                        control={<Radio color="primary" />}
                        label={intl.formatMessage({
                          id: "Store.PromoCode.type.fixed",
                        })}
                      />
                      <InputSimple
                        disabled={valueType !== "FIXED_PRICE"}
                        value={fixedPrice}
                        onKeyPress={onKeyPress}
                        onChange={(event) => setFixedPrice(event.target.value)}
                        error={errorHandling.isError.fixed}
                        endAdornment={
                          <InputAdornment position="end">
                            {localMenu?.menuItem?.currency?.code}
                          </InputAdornment>
                        }
                      />
                    </div>
                    <FormControlLabel
                      value="FREE_SHIPPING"
                      control={<Radio color="primary" />}
                      label={intl.formatMessage({
                        id: "Store.PromoCode.type.freeShip",
                      })}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </>
        )}
      </Box>
      <Box
        p={3}
        width={1}
        className={`container-1 ${!showApplied && "container-1__hidden"}`}
      >
        <div className="header">
          <Typography variant="h2">
            {intl.formatMessage({ id: "Store.PromoCode.AddPromo.applied" })}
          </Typography>
          <IconButton
            onClick={() => {
              setShowApplied(!showApplied);
            }}
          >
            <i className={`arrow ${showApplied && "down"}`} />
          </IconButton>
        </div>
        {showApplied && (
          <div className="form appliedTo">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="applied"
                name="applied1"
                value={valueApplied}
                onChange={handleAppliedChange}
              >
                <div className="row">
                  <FormControlLabel
                    value="Order"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.applied.order",
                    })}
                  />
                </div>
                <div className="RadioWithInput row">
                  <FormControlLabel
                    value="collection"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.applied.collection",
                    })}
                  />
                  <Container>
                    <Autocomplete
                      disableClearable
                      disabled={valueApplied !== "collection"}
                      value={selectedCollection}
                      onChange={(
                        event: any,
                        newValue: FilterCollectionPayload[]
                      ) => {
                        setSelectedCollection(newValue);
                      }}
                      options={
                        collectionList?.getListFilterCollections.list || []
                      }
                      getOptionLabel={(option) =>
                        option?.names?.[0]?.value || ""
                      }
                      multiple
                      renderTags={(
                        value: FilterCollectionPayload[],
                        getTagProps
                      ) => (
                        <div className="chip_container">
                          {value.map((option, index) => (
                            <Chip
                              variant="default"
                              label={option?.names?.[0]?.value}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="filled"
                          InputProps={{ ...params.InputProps, type: "search" }}
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: "store.customer.group.List",
                          })}
                        />
                      )}
                    />
                  </Container>
                </div>
                <div className="RadioWithInput row">
                  <FormControlLabel
                    value="categorie"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.applied.categorie",
                    })}
                  />
                  <Container>
                    <Autocomplete
                      disableClearable
                      disabled={valueApplied !== "categorie"}
                      value={selectedCategorie}
                      onChange={(
                        event: any,
                        newValue: MenuCategoryPayload[]
                      ) => {
                        setSelectedCategorie(newValue);
                      }}
                      options={categorieList?.getListMenuCategories.list || []}
                      getOptionLabel={(option) =>
                        option?.names?.[0]?.value || ""
                      }
                      multiple
                      renderTags={(
                        value: MenuCategoryPayload[],
                        getTagProps
                      ) => (
                        <div className="chip_container">
                          {value.map((option, index) => (
                            <Chip
                              variant="default"
                              label={option?.names?.[0]?.value}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="filled"
                          InputProps={{ ...params.InputProps, type: "search" }}
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: "store.customer.group.List",
                          })}
                        />
                      )}
                    />
                  </Container>
                </div>
                <div className="RadioWithInput row">
                  <FormControlLabel
                    value="product"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.applied.product",
                    })}
                  />
                  <Container>
                    <Autocomplete
                      disableClearable
                      disabled={valueApplied !== "product"}
                      value={selectedProducts}
                      onChange={(event, newValue) => {
                        setSelectedProducts(newValue);
                      }}
                      options={
                        listMenuProducts?.getListMenuProducts?.list || []
                      }
                      getOptionLabel={(option) =>
                        option?.names?.[0]?.value || ""
                      }
                      multiple
                      renderTags={(value, getTagProps) => (
                        <div className="chip_container">
                          {value.map((option, index) => (
                            <Chip
                              variant="default"
                              label={option?.names?.[0]?.value}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="filled"
                          InputProps={{ ...params.InputProps, type: "search" }}
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: "store.customer.group.List",
                          })}
                        />
                      )}
                    />
                  </Container>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </Box>
      <Box
        p={3}
        width={1}
        className={`container-1 ${!showAvailable && "container-1__hidden"}`}
      >
        <div className="header">
          <Typography variant="h2">
            {intl.formatMessage({ id: "Store.PromoCode.AddPromo.available" })}
          </Typography>
          <IconButton
            onClick={() => {
              setShowAvailable(!showAvailable);
            }}
          >
            <i className={`arrow ${showAvailable && "down"}`} />
          </IconButton>
        </div>
        {showAvailable && (
          <div className="form AvailableTo">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="available"
                name="available1"
                value={valueAvailable}
                onChange={handleAvailableChange}
              >
                <div className="row">
                  <FormControlLabel
                    value="allUsers"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.available.allusers",
                    })}
                  />
                </div>
                <div className="RadioWithInput  row">
                  <FormControlLabel
                    value="specCustomer"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.available.specCustomer",
                    })}
                  />
                  <Container>
                    <Autocomplete
                      disableClearable
                      disabled={valueAvailable !== "specCustomer"}
                      value={selectedCustomers}
                      onChange={(event: any, newValue: CustomerPayload[]) => {
                        setSelectedCustomers(newValue);
                      }}
                      options={customersList?.getListCustomers.list || []}
                      getOptionLabel={(option) =>
                        option?.firstName + " " + option?.lastName
                      }
                      multiple
                      renderTags={(value: CustomerPayload[], getTagProps) => (
                        <div className="chip_container">
                          {value.map((option, index) => (
                            <Chip
                              variant="default"
                              label={option?.firstName + " " + option?.lastName}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="filled"
                          InputProps={{ ...params.InputProps, type: "search" }}
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: "store.customer.group.List",
                          })}
                        />
                      )}
                    />
                  </Container>
                </div>
                <div className="RadioWithInput  row">
                  <FormControlLabel
                    value="newsletterSub"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.available.newsletterSub",
                    })}
                  />
                  <Container>
                    <Autocomplete
                      disableClearable
                      disabled={valueAvailable !== "newsletterSub"}
                      value={selectedNewsletterSub}
                      onChange={(
                        event: any,
                        newValue: MenuSubscriberPayload[]
                      ) => {
                        setSelectedNewsletterSub(newValue);
                      }}
                      options={
                        subscribersList?.getListMenuSubscribers.list || []
                      }
                      getOptionLabel={(option) => option?.email || ""}
                      multiple
                      renderTags={(
                        value: MenuSubscriberPayload[],
                        getTagProps
                      ) => (
                        <div className="chip_container">
                          {value.map((option, index) => (
                            <Chip
                              variant="default"
                              label={option?.email}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="filled"
                          InputProps={{ ...params.InputProps, type: "search" }}
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: "store.customer.group.List",
                          })}
                        />
                      )}
                    />
                  </Container>
                </div>
                <div className="RadioWithInput  row">
                  <FormControlLabel
                    value="specGroup"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.available.specGroup",
                    })}
                  />
                  <Container>
                    <Autocomplete
                      disableClearable
                      disabled={valueAvailable !== "specGroup"}
                      value={selectedGroup}
                      onChange={(event: any, newValue: MenuGroupPayload[]) => {
                        setSelectedGroup(newValue);
                      }}
                      options={groupList?.getListMenuGroups.list || []}
                      getOptionLabel={(option) =>
                        option?.names?.[0].value || ""
                      }
                      multiple
                      renderTags={(value: MenuGroupPayload[], getTagProps) => (
                        <div className="chip_container">
                          {value.map((option, index) => (
                            <Chip
                              variant="default"
                              label={option?.names?.[0].value}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="filled"
                          InputProps={{ ...params.InputProps, type: "search" }}
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: "store.customer.group.List",
                          })}
                        />
                      )}
                    />
                  </Container>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </Box>
      <Box
        p={3}
        width={1}
        className={`container-1 ${!showMinReq && "container-1__hidden"}`}
      >
        <div className="header">
          <Typography variant="h2">
            {intl.formatMessage({ id: "Store.PromoCode.AddPromo.MinReq" })}
          </Typography>
          <IconButton
            onClick={() => {
              setShowMinReq(!showMinReq);
            }}
          >
            <i className={`arrow ${showMinReq && "down"}`} />
          </IconButton>
        </div>
        {showMinReq && (
          <div className="form MinReqForm">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="minreq"
                name="minreq1"
                value={valueMinReq}
                onChange={handleMinReqChange}
              >
                <div className="row">
                  <FormControlLabel
                    value="All-users"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.AddPromo.MinReq.allusers",
                    })}
                  />
                </div>
                <div className="RadioWithInput  row">
                  <FormControlLabel
                    value="MinPurchaseAmount"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.AddPromo.MinReq.MinPurAmmount",
                    })}
                  />
                  <InputSimple
                    value={minimumCartTotalPriceValue}
                    onKeyPress={onKeyPress}
                    onChange={(event) =>
                      setMinimumCartTotalPriceValue(event.target.value)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {localMenu?.menuItem?.currency?.code}
                      </InputAdornment>
                    }
                    disabled={valueMinReq !== "MinPurchaseAmount"}
                  />
                </div>
                <div className="RadioWithInput  row">
                  <FormControlLabel
                    value="MinQuantityofItems"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.AddPromo.MinReq.MinQuanItems",
                    })}
                  />
                  <InputSimple
                    type="number"
                    value={minimumCartTotalQuantityValue}
                    onKeyPress={onKeyPress}
                    onChange={(event) =>
                      setMinimumCartTotalQuantityValue(event.target.value)
                    }
                    disabled={valueMinReq !== "MinQuantityofItems"}
                    endAdornment={
                      <InputAdornment position="end">Items</InputAdornment>
                    }
                  />
                </div>
                <div className="RadioWithInput  row">
                  <FormControlLabel
                    value="MinVerifiedOrderPurchase"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.AddPromo.MinReq.MinVerOrdPurchase",
                    })}
                  />
                  <InputSimple
                    type="number"
                    value={minimumPastOrdersTotalQuantityValue}
                    onKeyPress={onKeyPress}
                    onChange={(event) =>
                      setMinimumPastOrdersTotalQuantityValue(event.target.value)
                    }
                    endAdornment={
                      <InputAdornment position="end">Items</InputAdornment>
                    }
                    disabled={valueMinReq !== "MinVerifiedOrderPurchase"}
                  />
                </div>
                <div className="RadioWithInput row">
                  <FormControlLabel
                    value="MinVerifiedOrderAmount"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "Store.PromoCode.AddPromo.MinReq.MinVerOrdAmmount",
                    })}
                  />
                  <InputSimple
                    value={minimumPastOrdersTotalPriceValue}
                    onKeyPress={onKeyPress}
                    onChange={(event) =>
                      setMinimumPastOrdersTotalPriceValue(event.target.value)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {localMenu?.menuItem?.currency?.code}
                      </InputAdornment>
                    }
                    disabled={valueMinReq !== "MinVerifiedOrderAmount"}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </Box>
      <Box
        p={3}
        width={1}
        className={`container-1 ${!showLimit && "container-1__hidden"}`}
      >
        <div className="header">
          <Typography variant="h2">
            {intl.formatMessage({ id: "Store.PromoCode.AddPromo.limit" })}
          </Typography>
          <IconButton onClick={() => setShowLimit(!showLimit)}>
            <i className={`arrow ${showLimit && "down"}`} />
          </IconButton>
        </div>
        {showLimit && (
          <div className="form limitform">
            <FormControl>
              <div className="RadioWithInput row">
                <FormControlLabel
                  value="NbTimesUsed"
                  control={
                    <Checkbox
                      checked={nbTimesUsed}
                      onChange={() => {
                        setNbTimesUsed(!nbTimesUsed);
                        setNbTimesUsedValue("");
                      }}
                      color="secondary"
                    />
                  }
                  label={intl.formatMessage({
                    id: "Store.PromoCode.AddPromo.limit.NbTimesUsed",
                  })}
                />
                <InputSimple
                  type="number"
                  value={nbTimesUsedValue}
                  onKeyPress={onKeyPress}
                  onChange={(event) => setNbTimesUsedValue(event.target.value)}
                  disabled={!nbTimesUsed}
                />
              </div>
              <div className="collection row">
                <FormControlLabel
                  value="OneUseByCustomer"
                  control={
                    <Checkbox
                      checked={oneUse}
                      onChange={() => setOneUse(!oneUse)}
                      color="secondary"
                    />
                  }
                  label={intl.formatMessage({
                    id: "Store.PromoCode.AddPromo.limit.oneUse",
                  })}
                />
              </div>
              <div className="datePickerContainer RadioWithInput row">
                <FormControlLabel
                  value="StartDate"
                  control={
                    <Checkbox
                      checked={startDate}
                      onChange={() => {
                        setStartDate(!startDate);
                        setSelectedStartDate(null);
                      }}
                      color="secondary"
                    />
                  }
                  label={intl.formatMessage({
                    id: "Store.PromoCode.AddPromo.limit.startDate",
                  })}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    format="yyyy/MM/dd HH:mm"
                    autoOk={false}
                    ampm={false}
                    disablePast
                    value={selectedStartDate}
                    onChange={(date) => setSelectedStartDate(date)}
                    disabled={!startDate}
                    inputVariant="filled"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="datePickerContainer RadioWithInput row">
                <FormControlLabel
                  value="endDate"
                  control={
                    <Checkbox
                      checked={endDate}
                      onChange={() => {
                        setEndDate(!endDate);
                        setSelectedEndDate(null);
                      }}
                      color="secondary"
                    />
                  }
                  label={intl.formatMessage({
                    id: "Store.PromoCode.AddPromo.limit.endDate",
                  })}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    format="yyyy/MM/dd HH:mm"
                    autoOk={false}
                    ampm={false}
                    disablePast
                    value={selectedEndDate}
                    onChange={(date) => setSelectedEndDate(date)}
                    disabled={!endDate}
                    inputVariant="filled"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </FormControl>
          </div>
        )}
      </Box>
    </Wrapper>
  );
};

export default PromoCodeForm;
