import { UpdateMenuTypeProductInput } from "../../api/types";

export type ErrorsType = {
  name?: string;
  apiProvider?: string;
  apiProviderID?: string;
};

export type ErrorsTypeRawi = {
  name?: string;
  writer?: string;
  reader?: string;
  category?: string;
  picture?: string;
};

export const validationRawi = (
  value: UpdateMenuTypeProductInput
): ErrorsTypeRawi => {
  const errors: ErrorsTypeRawi = {};
  Object.keys(value).forEach((key: string) => {
    switch (key) {
      case "name":
        if (!value.name?.value) {
          errors.name = "This is field required";
        } else {
          delete errors.name;
        }
        break;
      case "attributes":
        if (!value?.attributes?.[0]) {
          errors.writer = "This is field required";
        } else {
          delete errors.writer;
        }
        if (!value?.attributes?.[1]) {
          errors.reader = "This is field required";
        } else {
          delete errors.reader;
        }
        break;
      case "category":
        if (!value?.category) {
          errors.category = "This is field required";
        } else {
          delete errors.category;
        }
        break;
      case "picture":
        if (!value.picture) {
          errors.picture = "You need to upload base image !";
        } else {
          delete errors.picture;
        }
        break;

      default:
        break;
    }
  });
  return errors;
};

export const validation = (value: UpdateMenuTypeProductInput): ErrorsType => {
  const errors: ErrorsType = {};

  Object.keys(value).forEach((key: string) => {
    switch (key) {
      case "name":
        if (!value.name?.value) {
          errors.name = "This is field required";
        } else {
          delete errors.name;
        }
        break;
      case "customFields":
        if (
          !value?.customFields?.find((o) => o.key === "API_PROVIDER")?.value
        ) {
          errors.apiProvider = "This is field required";
        } else {
          delete errors.apiProvider;
        }
        if (
          !value?.customFields?.find((o) => o.key === "API_PRODUCT_ID")?.value
        ) {
          errors.apiProviderID = "This is field required";
        } else {
          delete errors.apiProviderID;
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
