import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

import { GET_MENU_NAVIGATION, REMOVE_MENU_NAVIGATION } from "../../../api";
import {
  GetMenuNavigationQuery,
  GetMenuNavigationQueryVariables,
  RemoveMenuNavigationMutation,
  RemoveMenuNavigationMutationVariables,
  MenuPayload,
  SubNavigationPayload,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

import StoreSettings from "../../../img/digishop/menu-icons/StoreSettings.svg";

const StoreMenu = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [open, setOpen] = React.useState<boolean>(false);
  const [idMainItem, setIdMainItem] = React.useState<string>("");

  const { data } = useQuery<
    GetMenuNavigationQuery,
    GetMenuNavigationQueryVariables
  >(GET_MENU_NAVIGATION, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const listNavigation = data?.getMenuNavigation?.list;

  const columns: ColumnsProps = [
    {
      header: "Title",
      accessor: "title",
    },
    {
      header: "Items",
      accessor: "items",
    },

    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  const [removeMainItem, { loading }] = useMutation<
    RemoveMenuNavigationMutation,
    RemoveMenuNavigationMutationVariables
  >(REMOVE_MENU_NAVIGATION, {
    variables: {
      input: {
        token,
        store: localMenu?.id || "",
        id: idMainItem,
      },
    },
    refetchQueries: () => [
      {
        query: GET_MENU_NAVIGATION,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      setOpen(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "successful operation",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const showNavigation = (navigation: SubNavigationPayload[]) => {
    return navigation?.map((item) => ` ${item.label}`);
  };

  const renderTableRows = () => {
    return (
      listNavigation?.map((item) => ({
        id: item?.id,
        title: item?.label,
        items: showNavigation(item?.navigation || []).toString(),
        actions: (
          <Box display="flex">
            <IconButton
              size="small"
              onClick={() => history.push("/edit-store-menu")}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                item?.id && setIdMainItem(item?.id);
                setOpen(true);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        ),
      })) || []
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <BreadCrumbsTitle
        labelIntl={["Store settings", "Store Menu"]}
        icon={StoreSettings}
        iconAlt="store menu"
      />
      <Box component={Paper} p={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Menus list
          </Typography>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={() =>
              history.push({
                pathname: "/add-store-menu",
                state: listNavigation?.[0].navigation,
              })
            }
            disabled={(listNavigation?.length || 0) > 0}
          >
            Add Menu
          </ButtonCustomize>
        </Box>
        <Typography variant="subtitle1" style={{ color: "#687584" }}>
          Menus can help your customers navigate around your online store.
        </Typography>
        <Typography variant="subtitle1" style={{ color: "#687584" }}>
          You can also create nested menus to display drop-down menus, and group
          categories or pages together.
        </Typography>
        <Box flex="1" marginTop={2}>
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="You have no created menu."
          />
        </Box>
      </Box>
      <Modal
        open={open}
        title="Delete Menu"
        message="Are you sure you want to remove this menu ?"
        handleClose={() => setOpen(false)}
        handleContent={removeMainItem}
        action="delete"
        loading={loading}
      />
    </Box>
  );
};

export default StoreMenu;
