import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, IconButton, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useQuery, useMutation } from "@apollo/client";
import find from "lodash/find";

import {
  BreadCrumbsTitle,
  Modal,
  TablePagination,
  Search,
} from "../../commons";
import { ListDataEmpty, ModifyGroupModal } from "../../components";
import { ClientsGroupImg } from "../../img";

import { GETLISTGROUP, REMOVEGROUP } from "../../api";
import {
  MenuGroupPayload,
  GetListMenuGroupsQuery,
  GetListMenuGroupsQueryVariables,
  DeleteMenuGroupMutation,
  DeleteMenuGroupMutationVariables,
} from "../../api/types";
import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../api/local-state";

import { Wrapper, Note } from "./ClientsGroupStyle";

const ClientsGroup: React.FC = () => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    "store.customer.group.List.name"
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: groupList } = useQuery<
    GetListMenuGroupsQuery,
    GetListMenuGroupsQueryVariables
  >(GETLISTGROUP, {
    variables: {
      input: {
        token,
        menu: localMenu?.menuItem?.id || "",
      },
    },
    fetchPolicy: "network-only",
    skip: !localMenu?.menuItem?.id,
  });

  const [removeGroup, { loading: loadingRemove }] = useMutation<
    DeleteMenuGroupMutation,
    DeleteMenuGroupMutationVariables
  >(REMOVEGROUP, {
    refetchQueries: () => [
      {
        query: GETLISTGROUP,
        variables: {
          input: {
            token,
            menu: localMenu?.menuItem?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      setDeleteModal(false);
    },
  });

  const trigerDeleteGroup = (id: string | null | undefined) => {
    removeGroup({
      variables: {
        input: {
          token,
          id: id || "",
        },
      },
    });
  };
  const count = groupList?.getListMenuGroups?.list?.length || 0;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
  };

  const [selectedGroup, setSelectedGroup] = useState<MenuGroupPayload>({});
  const [listGroup, setListGroup] = useState<MenuGroupPayload[] | undefined>(
    []
  );

  useEffect(() => {
    setListGroup(groupList?.getListMenuGroups.list);
  }, [groupList]);

  const handleSearchChange = (value: string) => {
    const newArray: MenuGroupPayload[] = [];
    if (selectedFilter === "Full Name") {
      find(groupList?.getListMenuGroups.list, (o) => {
        if (o.names?.[0].value?.toLowerCase()?.includes(value)) {
          newArray.push(o);
        }
      });
    }
    if (selectedFilter === "First Name") {
      find(groupList?.getListMenuGroups.list, (o) => {
        find(o.customersList, (t) => {
          if (t.firstName?.toLowerCase()?.includes(value)) {
            newArray.push(o);
          }
        });
      });
    }
    if (selectedFilter === "Last Name") {
      find(groupList?.getListMenuGroups.list, (o) => {
        o.customersList?.forEach((customer) => {
          if (customer?.lastName?.toLowerCase()?.includes(value)) {
            newArray.push(o);
          }
        });
      });
    }
    if (!value) setListGroup(groupList?.getListMenuGroups.list);
    else setListGroup(newArray);
  };

  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({
              id: "mainLayout.clients",
            }),
            intl.formatMessage({
              id: "store.customer.group.List",
            }),
          ]}
          iconAlt="ClientsGroupImg"
        />
        {groupList?.getListMenuGroups?.list &&
          groupList?.getListMenuGroups?.list?.length > 0 && (
            <div className="action">
              <Link to="/add-customer-group">
                <Button variant="contained" color="secondary">
                  {intl.formatMessage({
                    id: "store.customer.group.addList",
                  })}
                </Button>
              </Link>
            </div>
          )}
      </Note>

      <div className="container">
        {groupList?.getListMenuGroups?.list &&
          groupList?.getListMenuGroups?.list?.length > 0 && (
            <Box display="flex" flexDirection="column">
              <div className="searchInput">
                <Search
                  listModeSearch={["Full Name", "First Name", "Last Name"]}
                  onReturnSearch={(value, mode) => {
                    setSelectedFilter(mode);
                    handleSearchChange(value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <Box width={1}>
                <h3>
                  {intl.formatMessage({
                    id: "store.customer.group.List",
                  })}
                </h3>
                <div className="collections">
                  <div className="collections__header">
                    <span>
                      {intl.formatMessage({
                        id: "store.customer.group.List.name",
                      })}
                    </span>
                    <span>
                      {intl.formatMessage({
                        id: "store.customer.group.List.numberClients",
                      })}
                    </span>
                    <span>
                      {intl.formatMessage({
                        id: "store.customer.group.List.action",
                      })}
                    </span>
                  </div>
                  <div className="collections__list">
                    {listGroup
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((item) => (
                        <>
                          <div className="collections__item" key={item.id}>
                            <span>{item.names?.[0].value}</span>
                            <span>{item.customersCount}</span>
                            <span>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setModalOpen(true);
                                  setSelectedGroup(item);
                                }}
                                style={{ color: "#444444" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setSelectedGroup(item);
                                }}
                                style={{ color: "#ff4949" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          </div>
                        </>
                      ))}
                  </div>
                  <Box display="flex" justifyContent="flex-end">
                    <TablePagination
                      labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                      count={count}
                      page={page}
                      onPageChange={(_, newPage) => setPage(newPage)}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Box>
                </div>
              </Box>
              <ModifyGroupModal
                open={modalOpen}
                handleClose={handleCloseModal}
                selected={selectedGroup}
              />
              <Modal
                open={deleteModal}
                title={intl.formatMessage({
                  id: "store.customer.group.deleteGroup",
                })}
                message={`${intl.formatMessage({
                  id: "store.customer.group.deleteGroup.message",
                })} ${selectedGroup?.names?.[0]?.value}`}
                handleClose={() => setDeleteModal(false)}
                handleContent={() => trigerDeleteGroup(selectedGroup.id)}
                action="delete"
                loading={loadingRemove}
              />
            </Box>
          )}
        {groupList?.getListMenuGroups?.list &&
          groupList?.getListMenuGroups?.list?.length === 0 && (
            <ListDataEmpty
              image={ClientsGroupImg}
              description={intl.formatMessage({
                id: "store.customer.group.ListEmpty",
              })}
              btnName={intl.formatMessage({
                id: "store.customer.group.addList",
              })}
              url="/add-customer-group"
            />
          )}
      </div>
    </Wrapper>
  );
};
export default ClientsGroup;
