import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { MenuItem, FormControl, Box, Typography } from "@material-ui/core";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { Wrapper, Seperator } from "./GeneralProductStyle";
import { Input, Select, InputTags } from "../../commons";
import { UploadPictureProduct } from "..";

import {
  GETLISTMENUCATEGORIES,
  REMOVE_PRODUCT_PICTURE,
  ADD_PRODUCT_PICTURE,
  GET_LIST_MENU_PRODUCTS,
  GET_MENU_STAFF,
} from "../../api";
import {
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  ProductUnitKind,
  ProductKind,
  UpdateCategoryProductInput,
  LanguageCode,
  RemoveProductPictureMutation,
  RemoveProductPictureMutationVariables,
  AddProductPictureMutation,
  AddProductPictureMutationVariables,
  CategoryProductDetailsPayload,
  GetMenuStaffQuery,
  GetMenuStaffQueryVariables,
  MenuPayload,
  UpdateDescriptionInput,
  ProductGalleryInput,
  CopiedPictureInput,
} from "../../api/types";
import {
  GET_REQUIRED_FORM_PRODUCT_LOCAL,
  GetRequiredFormProductQuery,
  formProductRequiredVar,
  SelectedMenuVar,
} from "../../api/local-state";

import {
  regroupCategories,
  MenuCategoryExtended,
} from "../../utils/category-utils";

type GeneralProductProps = {
  idProduct?: string;
  categoryParent?: string;
  detailsProduct?: CategoryProductDetailsPayload;
  categoryProductPayload?: UpdateCategoryProductInput;
  gallery: ProductGalleryInput[];
  onReturnFormProduct: (product: UpdateCategoryProductInput) => void;
  onGallery: (gallery: ProductGalleryInput[]) => void;
  refetchProduct: () => void;
};

type PictureType = {
  picture?: string;
  copiedPictures?: CopiedPictureInput[];
};

const GeneralProduct: React.FC<GeneralProductProps> = ({
  idProduct,
  categoryParent,
  onReturnFormProduct,
  detailsProduct,
  categoryProductPayload,
  gallery,
  onGallery,
  refetchProduct,
}) => {
  const token = localStorage.getItem("token") || "";
  const hasMultiVendor = localStorage.getItem("hasMultiVendor");
  const kind = localStorage.getItem("kind");
  const intl = useIntl();

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const dataCategoryProduct: UpdateCategoryProductInput = {
    ...categoryProductPayload,
    token,
    id: idProduct || "",
  };

  const { data: localRequired } = useQuery<GetRequiredFormProductQuery>(
    GET_REQUIRED_FORM_PRODUCT_LOCAL
  );
  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [statePicture, setStatePicture] = React.useState<PictureType>();

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.id || "" } },
  });
  const categoriesListData = listCategory?.getListMenuCategories?.list;

  const { data: listVendor } = useQuery<
    GetMenuStaffQuery,
    GetMenuStaffQueryVariables
  >(GET_MENU_STAFF, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        kind: "VENDOR",
      },
    },
    skip: !localMenu?.id,
  });

  const [AddProductPicture] = useMutation<
    AddProductPictureMutation,
    AddProductPictureMutationVariables
  >(ADD_PRODUCT_PICTURE, {
    refetchQueries: () => [
      {
        query: GET_LIST_MENU_PRODUCTS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      refetchProduct();
    },
  });

  const [RemoveProductPicture] = useMutation<
    RemoveProductPictureMutation,
    RemoveProductPictureMutationVariables
  >(REMOVE_PRODUCT_PICTURE, {
    refetchQueries: () => [
      {
        query: GET_LIST_MENU_PRODUCTS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onCompleted: () => {
      refetchProduct();
    },
  });

  useEffect(() => {
    if (detailsProduct && languageIsMain) {
      const content = find(
        detailsProduct.descriptions,
        (o) => o.languageCode === languageIsMain.code
      )?.value;
      if (content) {
        const blocksFromHtml = htmlToDraft(content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [detailsProduct]);

  // set category if the user was redirected from categorylist
  useEffect(() => {
    if (categoryParent) {
      onReturnFormProduct({
        ...dataCategoryProduct,
        category: categoryParent,
      });
    }
  }, [categoryParent]);

  const handleIdentifier = (event: React.ChangeEvent<HTMLInputElement>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      identifier: event.target.value.replace(/ /g, "-"),
    });
    formProductRequiredVar({
      identifier: "",
      name: localRequired?.formProduct?.name || "",
    });
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayNames = [...(dataCategoryProduct?.names || [])];
    const position = findIndex(
      arrayNames,
      (o) => o.languageCode === languageIsMain?.code
    );

    if (position !== -1) {
      arrayNames[position] = {
        ...arrayNames[position],
        value: event.target.value,
      };
    } else {
      arrayNames.push({
        languageCode: languageIsMain?.code as LanguageCode,
        value: event.target.value,
      });
    }
    onReturnFormProduct({
      ...dataCategoryProduct,
      names: arrayNames,
    });
    formProductRequiredVar({
      identifier: localRequired?.formProduct?.identifier || "",
      name: "",
    });
  };

  const handleKind = (event: React.ChangeEvent<{ value: unknown }>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      kind: event.target.value as ProductKind,
    });
  };

  const handleUnit = (event: React.ChangeEvent<{ value: unknown }>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      unit: {
        kind: event.target.value as ProductUnitKind,
        default: event.target.value === "GRAM" ? 100 : 1,
      },
    });
  };

  const handleDescription = (value: EditorState) => {
    if (value) setEditorState(value);
  };

  const handleDescriptionConvertToString = () => {
    const arrayDescription: UpdateDescriptionInput[] = [];
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    dataCategoryProduct?.descriptions?.map((item) =>
      arrayDescription.push({
        id: item.id,
        languageCode: item.languageCode as LanguageCode,
        value: item.value,
      })
    );

    const position = findIndex(
      arrayDescription,
      (o) => o.languageCode === languageIsMain?.code
    );

    if (position !== -1) {
      arrayDescription[position] = {
        ...arrayDescription[position],
        value: content,
      };
    } else {
      arrayDescription.push({
        languageCode: languageIsMain?.code as LanguageCode,
        value: content,
      });
    }

    onReturnFormProduct({
      ...dataCategoryProduct,
      descriptions: arrayDescription,
    });
  };

  const handleVendor = (event: React.ChangeEvent<HTMLInputElement>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      vendor: event.target.value,
    });
  };

  const handleVendorID = (event: React.ChangeEvent<{ value: unknown }>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      vendorId: event.target.value as string,
    });
  };

  const handleBrand = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onReturnFormProduct({
      ...dataCategoryProduct,
      brand: value,
    });
  };

  const handleTags = (listTags: string[]) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      tags: listTags,
    });
  };

  const handleCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      category: event.target.value as string,
    });
  };

  const handleSalesCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      salesCount: event.target.value,
    });
  };

  const renderCategorySelectOptions = (
    List: MenuCategoryExtended[]
  ): React.ReactNode => {
    return (
      List.map((category) => [
        <MenuItem
          value={category.id || ""}
          style={{ paddingLeft: `${(category.level || 0) * 10}px` }}
          disabled={!!category?.subcategories}
        >
          {category.names?.[0].value || ""}
        </MenuItem>,

        renderCategorySelectOptions(category.subcategories || []),
      ]) || null
    );
  };
  // refactor list categories
  const categories = useMemo(
    () => (categoriesListData ? regroupCategories(categoriesListData) : []),
    [categoriesListData]
  );

  React.useEffect(() => {
    onReturnFormProduct({
      ...dataCategoryProduct,
      ...statePicture,
    });
  }, [statePicture]);

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /[0-9]/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        width={1}
        p={2}
        bgcolor="#fff"
        borderRadius="10px"
        border="2px solid #f3f4fa"
        style={{ position: "relative" }}
      >
        <UploadPictureProduct
          title={intl.formatMessage({ id: "generalProduct.baseImage" })}
          description={intl.formatMessage({
            id: "generalProduct.baseImageDescription",
          })}
          uploadTo="Product"
          mode="Base"
          onLoaded={(picture, copiedPictures) => {
            setStatePicture({
              picture,
              copiedPictures,
            });
          }}
          urlImage={{
            id: detailsProduct?.picture?.id,
            url: detailsProduct?.picture?.fileUrl,
          }}
          onDeleted={() => {
            onReturnFormProduct({
              ...dataCategoryProduct,
              picture: null,
              copiedPictures: null,
            });
          }}
        />
        <div className="seperator-upload" />
        <UploadPictureProduct
          title={intl.formatMessage({ id: "generalProduct.gallery" })}
          description={intl.formatMessage({
            id: "generalProduct.galleryDescription",
          })}
          uploadTo="Product"
          mode="Gallery"
          onLoaded={(picture, copiedPictures) => {
            if (idProduct) {
              AddProductPicture({
                variables: {
                  input: {
                    token,
                    product: idProduct || "",
                    picture,
                    copiedPictures,
                  },
                },
              });
            } else {
              const arrayGallery = [...gallery];
              arrayGallery.push({
                picture,
                copiedPictures,
              });
              onGallery(arrayGallery);
            }
          }}
          urlImageGallery={detailsProduct?.gallery || undefined}
          onDeleted={(pictureId) => {
            if (idProduct) {
              RemoveProductPicture({
                variables: {
                  input: {
                    id: pictureId,
                    product: idProduct,
                    token,
                  },
                },
              });
            } else {
              const arrayGallery = [...gallery];
              const position = findIndex(
                arrayGallery,
                (o) => o?.picture === pictureId
              );
              arrayGallery?.splice(position, 1);
              onGallery(arrayGallery);
            }
          }}
        />
        <Typography variant="subtitle2" className="info-upload-picture">
          <FontAwesomeIcon
            size="2x"
            icon={faExclamationCircle}
            color="#007AFF"
          />
          &nbsp; &nbsp; Product image recommended resolution 400px * 400px
        </Typography>
      </Box>
      <Seperator />
      <Box
        width={1}
        p={2}
        bgcolor="#fff"
        borderRadius="10px"
        border="2px solid #f3f4fa"
      >
        <div className="row">
          <Input
            label={intl.formatMessage({ id: "generalProduct.identifier" })}
            value={dataCategoryProduct?.identifier || ""}
            onChange={handleIdentifier}
            msgError={localRequired?.formProduct?.identifier}
            required
          />
        </div>
      </Box>
      <Seperator />
      <Box
        width={1}
        p={2}
        bgcolor="#fff"
        borderRadius="10px"
        border="2px solid #f3f4fa"
      >
        <div className="row">
          <Input
            label={intl.formatMessage({ id: "generalProduct.productName" })}
            value={
              find(
                dataCategoryProduct.names,
                (o) => o.languageCode === languageIsMain?.code
              )?.value || ""
            }
            onChange={handleName}
            msgError={localRequired?.formProduct?.name}
            required
            style={{ marginBottom: 15 }}
          />
        </div>
        <div className="row">
          <Select
            label={intl.formatMessage({ id: "generalProduct.TypeOfProduct" })}
            value={dataCategoryProduct.kind || ""}
            onChange={handleKind}
            style={{ marginBottom: 15 }}
          >
            <MenuItem value="PHYSICAL">
              {intl.formatMessage({ id: "generalProduct.physicalProduct" })}
            </MenuItem>
            <MenuItem value="DIGITAL">
              {intl.formatMessage({ id: "generalProduct.digitalProduct" })}
            </MenuItem>
          </Select>
          {dataCategoryProduct.kind === "PHYSICAL" && (
            <Select
              label={intl.formatMessage({ id: "generalProduct.managedBy" })}
              value={dataCategoryProduct.unit?.kind || ""}
              onChange={handleUnit}
            >
              <MenuItem value="PIECE">
                {intl.formatMessage({ id: "generalProduct.piece" })}
              </MenuItem>
              <MenuItem value="GRAM">
                {intl.formatMessage({ id: "generalProduct.gram" })}
              </MenuItem>
              <MenuItem value="KILOGRAM">
                {intl.formatMessage({ id: "generalProduct.kiloGram" })}
              </MenuItem>
              <MenuItem value="METER">
                {intl.formatMessage({ id: "generalProduct.meter" })}
              </MenuItem>
            </Select>
          )}
        </div>
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={handleDescription}
          placeholder="Please insert descriptions ... "
          onContentStateChange={handleDescriptionConvertToString}
        />
      </Box>
      <Seperator />
      <Box
        width={1}
        p={2}
        bgcolor="#fff"
        borderRadius="10px"
        border="2px solid #f3f4fa"
      >
        <div className="row">
          {kind !== "Vendor" && (
            <>
              {hasMultiVendor === "true" ? (
                <Select
                  label={intl.formatMessage({ id: "generalProduct.vendor" })}
                  value={dataCategoryProduct.vendorId || ""}
                  onChange={handleVendorID}
                >
                  {listVendor?.getMenuStaff?.list?.map((vendor) => (
                    <MenuItem value={vendor.id || ""} key={vendor.id}>
                      {vendor.informations?.professional?.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Input
                  label={intl.formatMessage({ id: "generalProduct.vendor" })}
                  value={dataCategoryProduct.vendor || ""}
                  onChange={handleVendor}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            </>
          )}
          <Input
            label="Brand"
            value={dataCategoryProduct.brand || ""}
            onChange={handleBrand}
          />
          <InputTags
            label={intl.formatMessage({ id: "generalProduct.tag" })}
            tags={dataCategoryProduct.tags || []}
            onChange={(listTags) => handleTags(listTags)}
          />
          <Input
            label="Sales count"
            value={dataCategoryProduct.salesCount || ""}
            onKeyPress={onKeyPress}
            onChange={handleSalesCount}
          />
        </div>
      </Box>
      <Seperator />
      <Box
        width={1}
        p={2}
        bgcolor="#fff"
        borderRadius="10px"
        border="2px solid #f3f4fa"
      >
        <FormControl fullWidth>
          <Typography variant="h3" className="title">
            {intl.formatMessage({ id: "generalProduct.titleListCategory" })}
          </Typography>

          <Select
            label={intl.formatMessage({ id: "generalProduct.categoryList" })}
            value={dataCategoryProduct.category || ""}
            onChange={handleCategory}
          >
            {renderCategorySelectOptions(categories)}
          </Select>
        </FormControl>
      </Box>
    </Wrapper>
  );
};

export default GeneralProduct;
