import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 600px;
  padding: 20px;
  .header-modal {
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 16px;
    }
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .MuiTypography-body1 {
    font-size: 10px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 5px;
  }
`;

type ColorInputType = {
  color: string;
};

export const ColorInput = styled.div<ColorInputType>`
  width: 100%;
  height: 10px;
  border: 2px solid #f1f2fb;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  margin: 5px 0;
`;
