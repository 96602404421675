import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { IconButton, Typography, Box, Paper } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import find from "lodash/find";

import { Modal, ButtonCustomize } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { VariantFormModal } from "..";

import {
  GETLISTPRODUCTVARIANTS,
  DELETEPRODUCTVARIANT,
  URL_THEME_PICTURES,
} from "../../api";
import {
  GetListProductVariantsQuery,
  GetListProductVariantsQueryVariables,
  RemoveProductVariantMutation,
  RemoveProductVariantMutationVariables,
  ProductVariantPayload,
  MenuPayload,
} from "../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../api/local-state";

import { Wrapper } from "./VariationProduct--styles";

type VariationProductProps = {
  hasVariation: boolean;
  onActivateVariation: (active: boolean) => void;
};

const VariationProduct: React.FC<VariationProductProps> = ({
  hasVariation,
  onActivateVariation,
}) => {
  const token = localStorage.getItem("token") || "";
  const { id: productId } = useParams<{
    id?: string;
  }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const [open, setOpen] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [idVariant, setIdVariant] = useState<string>("");
  const [titleFormVariant, setTitleFormVariant] = useState<string>("");
  const [dataVariant, setDataVariant] = useState<
    ProductVariantPayload | undefined
  >(undefined);

  const { data: listVariations } = useQuery<
    GetListProductVariantsQuery,
    GetListProductVariantsQueryVariables
  >(GETLISTPRODUCTVARIANTS, {
    variables: {
      input: {
        token,
        product: productId || "",
      },
    },
    skip: !productId,
  });

  const [DeleteVariant, { loading: loadingDelete }] = useMutation<
    RemoveProductVariantMutation,
    RemoveProductVariantMutationVariables
  >(DELETEPRODUCTVARIANT, {
    variables: {
      input: {
        token,
        product: productId || "",
        id: idVariant,
      },
    },
    onCompleted: () => {
      setOpenModalDelete(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "Variation has been deleted",
      });
    },
    refetchQueries: () => [
      {
        query: GETLISTPRODUCTVARIANTS,
        variables: {
          input: {
            token,
            product: productId || "",
          },
        },
      },
    ],
  });

  const handleClose = () => {
    setOpen(false);
    setDataVariant(undefined);
  };

  const columns: ColumnsProps = [
    {
      header: "Image",
      accessor: "image",
      cellProps: { width: "100px" },
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Options",
      accessor: "options",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "110px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listVariations?.getListProductVariants?.list?.map((variant) => ({
        id: variant?.id,
        image: variant.picture?.fileUrl && (
          <img
            className="variation__image"
            src={`${URL_THEME_PICTURES}${variant.picture?.fileUrl}`}
            alt="picture_variant"
          />
        ),
        name: find(
          variant.names,
          (o) => o.languageCode === languageIsMain?.code
        )?.value,
        options: variant.options?.map(
          (option) =>
            find(
              option?.option?.names,
              (o) => o.languageCode === languageIsMain?.code
            )?.value || ""
        ),
        actions: (
          <>
            <IconButton
              size="small"
              color="primary"
              style={{ color: "#444444", margin: "0 4px" }}
              onClick={(e) => {
                e.stopPropagation();
                setDataVariant(variant);
                setOpen(true);
                setTitleFormVariant("Edit Variation");
              }}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              style={{ color: "#ff4949", margin: "0 4px" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalDelete(true);
                variant.id && setIdVariant(variant.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      })) || []
    );
  };

  return (
    <Wrapper>
      <Box component={Paper} width={1} p={2}>
        <div className="header-variants">
          <h3> Variation List </h3>
          {hasVariation && (
            <ButtonCustomize
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(true);
                setTitleFormVariant("Create Variation");
              }}
            >
              Create Variation
            </ButtonCustomize>
          )}
        </div>
        <div className="info">
          Variation allows for a collection of possible options that your
          customers can choose from. It allows for more diversity with
          individual stock managment, price, image of each variation of the base
          product.
        </div>
        {hasVariation && (
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="Add your first variation"
            onClickRow={(row) => {
              if (row.id) {
                const selectedVariant =
                  listVariations?.getListProductVariants.list.filter(
                    (item) => item.id === row.id
                  )?.[0];
                setDataVariant(selectedVariant);
                setOpen(true);
              }
            }}
          />
        )}
        {!hasVariation && (
          <Box
            display="flex"
            width={1}
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
              Variation Is Disabled In Product Setting
            </Typography>
            <ButtonCustomize
              variant="contained"
              color="secondary"
              onClick={() => onActivateVariation(true)}
            >
              Activate Variation
            </ButtonCustomize>
          </Box>
        )}
      </Box>
      {open && (
        <VariantFormModal
          open={open}
          title={titleFormVariant}
          handleClose={handleClose}
          dataVariant={dataVariant}
        />
      )}

      <Modal
        open={openModalDelete}
        title="Delete Varaint ?"
        message="Are you sure you want to remove this option ?"
        handleClose={() => setOpenModalDelete(false)}
        handleContent={DeleteVariant}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default VariationProduct;
