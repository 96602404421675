import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    span {
      margin-left: 15px;
      color: #afb1c6;
      font-weight: 100;
      font-style: italic;
      font-size: 13px;
    }
  }
`;

export const Wrapper = styled.div`
  margin-top: -10px;
  .main-container {
    display: grid;
    /* grid-template-columns: auto 400px; */
    grid-gap: 20px;
  }
  .topbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-description {
      margin-left: 12px;
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  h2 {
    margin: 5px 0 15px 0;
  }
  h3 {
    margin: 0 0 20px 0;
  }
  .MuiChip-deleteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    .selected {
      background: #74abff;
      color: white;
      border-radius: 50%;
    }
  }
  .MuiSwitch-root {
    width: 36px;
    height: 18px;
    display: inline-flex;
    z-index: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
    border-radius: 20px;
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(18px);
    }
    .MuiSwitch-thumb {
      width: 14px;
      height: 14px;
      margin: 2px;
    }
    .MuiSwitch-thumb {
      box-shadow: none;
    }
    span:first-child {
      padding: 0;
    }
    .PrivateSwitchBase-root-97 {
      padding: 0;
    }
  }
  .loading {
    display: flex;
    width: 100%;
    height: calc(100vh - 350px);
    justify-content: center;
    align-items: center;
  }
  .action-style {
    font-weight: 500;
    margin-left: 10px;
  }
  .action-style2 {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    margin-left: 10px;
  }
  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 24px 15px 24px;
    .box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 5px;
      .sub-box {
        display: flex;
        flex-direction: column;
        position: relative;
        span {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          line-height: 14px;
          margin-bottom: 15px;
        }
        .MuiFilledInput-root {
          border-radius: 5px;
          border: 2px solid #f1f2fb;
          background-color: #ffffff;
          ::before {
            content: none;
          }
          ::after {
            content: none;
          }
        }
        .MuiFilledInput-root:focus-within {
          border: 2px solid #74abff;
        }
        .MuiFilledInput-root:hover {
          background-color: rgba(0, 0, 0, 0);
        }
        .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-fullWidth.MuiInputBase-formControl {
          background: #ffffff;
        }
        .form-control {
          width: 300px;
          margin-bottom: 24px;
        }
        .show-chip {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .card-flag {
          width: 20px;
          margin: 10px 10px 0px 0;
        }
      }
    }
  }
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
  .checklist {
    position: absolute;
    right: -240px;
    background-color: ghostwhite;
    padding: 5px 10px;
    border-radius: 8px;
    width: 200px;
    top: 30px;
    .tip {
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 10px solid ghostwhite;
      border-bottom: 7px solid transparent;
      left: -9px;
      position: absolute;
      top: 7px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 13px;
        color: #868894;
        svg {
          margin-right: 8px;
        }
      }
      /* color: #0dca12; */
      li.success svg {
        color: #0dca12;
      }

      /* color: #f67070; */
      li.error {
        color: #f67070;
        svg {
          color: #f67070;
        }
      }

      li.initial svg {
        color: #afb1c6;
      }
    }
  }
  .theme-carousel {
    padding-bottom: 15px;
    width: calc(100vw - 180px);
    overflow-x: auto;
    white-space: nowrap;
  }
  .theme-info {
    width: 300px;
    height: 250px;
    display: inline-block;
    margin-right: 20px;
    div.theme {
      display: grid;
      grid-template-columns: 1fr auto;
      div.preview {
        grid-column: span 2;
        width: 100%;
        height: 200px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        img.theme-preview {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        div.preview-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          z-index: 9;
          transition: all 0.4s ease-in-out;
          cursor: pointer;
        }
        div.preview-overlay:hover {
          opacity: 1;
        }
      }
      label {
        font-size: 14px;
        margin: 8px 0;
      }
    }
  }
`;
