import React, { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Switch, Button, Typography } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";

import { Wrapper } from "./ProductFormStyle";
import { validation } from "./ProductForm-utils";
import {
  GeneralProduct,
  AttributesProduct,
  OptionsProduct,
  VariationProduct,
  StockProduct,
  RelatedProducts,
} from "../../../components";
import { BreadCrumbsTitle, Box, Input } from "../../../commons";
import { Tabs } from "../Products--types";

import {
  CREATECATEGORYPRODUCT,
  GET_LIST_MENU_PRODUCTS,
  EDITPRODUCTSETTINGS,
  GETPRODUCTSETTINGS,
  UPDATECATEGORYPRODUCT,
  GETCATEGORYPRODUCTDETAILS,
  GET_LIST_TAGS_BY_MENU,
  HOST_FORSA,
} from "../../../api";
import {
  CreateCategoryProductMutation,
  CreateCategoryProductMutationVariables,
  EditProductSettingsMutation,
  EditProductSettingsMutationVariables,
  EditProductSettingsInput,
  GetProductSettingsQuery,
  GetProductSettingsQueryVariables,
  UpdateCategoryProductMutation,
  UpdateCategoryProductMutationVariables,
  UpdateCategoryProductInput,
  GetCategoryProductDetailsQuery,
  GetCategoryProductDetailsQueryVariables,
  UpdateNameInput,
  UpdateDescriptionInput,
  LanguageCode,
  ProductKind,
  ProductUnitKind,
  ProductGalleryInput,
  CreateNameInput,
  CreateDescriptionInput,
} from "../../../api/types";
import {
  GET_MENU_LOCAL,
  snackBarVar,
  formProductRequiredVar,
} from "../../../api/local-state";

import { products } from "../../../img";

const { host } = window.location;

const ProductForm: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<{ tab?: Tabs; categoryParent?: string }>();
  const categoryParent = location.state?.categoryParent;
  const { id } = useParams<{
    id?: string;
  }>();
  const token = localStorage.getItem("token") || "";

  const [mode, setMode] = useState<Tabs>("general");
  // doubles for price input to allow writing float
  const [priceInput, setPriceInput] = useState<string>("");
  const [priceDiscountInput, setPriceDiscountInput] = useState("");

  const [showPopupSave, setShowPopupSav] = useState(false);

  const [generalProduct, setGeneralProduct] =
    useState<UpdateCategoryProductInput>();

  const [formProductSetting, setFormProductSetting] =
    useState<EditProductSettingsInput>({
      token,
      id: "",
    });
  const [redirection, setRedirection] = useState(false);
  const [shippingWeight, setShippingWeight] = useState("");
  const [galleryProduct, setGalleryProduct] = useState<ProductGalleryInput[]>(
    []
  );

  const { data: localMenu } = useQuery(GET_MENU_LOCAL);

  const {
    data: categoryDetailsProduct,
    loading: loadingDetailsProduct,
    refetch: refetchDetailsProduct,
  } = useQuery<
    GetCategoryProductDetailsQuery,
    GetCategoryProductDetailsQueryVariables
  >(GETCATEGORYPRODUCTDETAILS, {
    variables: {
      input: { id: id!, token },
    },
    skip: !id,
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const detailsProductPayload =
    categoryDetailsProduct?.getCategoryProductDetails?.product;

  useEffect(() => {
    if (detailsProductPayload) {
      const arrayNames: UpdateNameInput[] = [];
      const arrayDescription: UpdateDescriptionInput[] = [];

      detailsProductPayload?.names?.map((item) =>
        arrayNames.push({
          id: item.id,
          languageCode: item.languageCode as LanguageCode,
          value: item.value,
        })
      );

      detailsProductPayload?.descriptions?.map((item) =>
        arrayDescription.push({
          id: item.id,
          languageCode: item.languageCode as LanguageCode,
          value: item.value,
        })
      );

      const productPayload: UpdateCategoryProductInput = {
        token,
        id: detailsProductPayload.id!,
        identifier: detailsProductPayload?.identifier,
        names: arrayNames,
        kind: detailsProductPayload?.kind as ProductKind,
        unit: {
          kind: detailsProductPayload?.unit?.kind as ProductUnitKind,
          default: detailsProductPayload?.unit?.default,
        },
        descriptions: arrayDescription,
        vendor: detailsProductPayload?.vendor,
        vendorId: detailsProductPayload?.vendorId,
        brand: detailsProductPayload?.brand,
        tags: detailsProductPayload?.tags,
        category: detailsProductPayload?.category,
        picture: detailsProductPayload?.picture?.id,
        salesCount: detailsProductPayload?.salesCount,
      };

      setGeneralProduct({ ...productPayload });
    }
  }, [detailsProductPayload]);

  useEffect(() => {
    if (categoryParent) {
      setGeneralProduct({
        token,
        id: "",
        category: categoryParent,
      });
    }
  }, [categoryParent]);

  const { data: dataProductSetting, refetch: refetchProductSetting } = useQuery<
    GetProductSettingsQuery,
    GetProductSettingsQueryVariables
  >(GETPRODUCTSETTINGS, {
    variables: {
      input: { id: id!, token },
    },
    skip: !id,
  });

  const [createProduct, { loading }] = useMutation<
    CreateCategoryProductMutation,
    CreateCategoryProductMutationVariables
  >(CREATECATEGORYPRODUCT, {
    onCompleted: (data) => {
      const idParam = data?.createCategoryProduct?.id;
      editProductSetting({
        variables: {
          input: { ...formProductSetting, id: idParam },
        },
        refetchQueries: () => [
          {
            query: GET_LIST_MENU_PRODUCTS,
            variables: {
              input: {
                token,
                menu: localMenu?.menuItem?.id || "",
                name: "",
                sorting: "NAME_ASC",
              },
            },
          },
          {
            query: GET_LIST_TAGS_BY_MENU,
            variables: {
              input: {
                token,
                menu: localMenu?.menuItem?.id || "",
              },
            },
          },
        ],
      });

      if (redirection) history.push("/product");
      else
        history.push({
          pathname: `/managment-product/${idParam}`,
        });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "Product already exists!",
      });
      setShowPopupSav(false);
    },
  });

  const [editProductSetting, { loading: loadingProductSetting }] = useMutation<
    EditProductSettingsMutation,
    EditProductSettingsMutationVariables
  >(EDITPRODUCTSETTINGS, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully registered!",
      });
      setShowPopupSav(false);
      refetchProductSetting();
      refetchDetailsProduct();
    },
  });

  const [UpdateProduct] = useMutation<
    UpdateCategoryProductMutation,
    UpdateCategoryProductMutationVariables
  >(UPDATECATEGORYPRODUCT, {
    onCompleted: () => {
      editProductSetting({
        variables: {
          input: {
            ...formProductSetting,
            id: id!,
          },
        },
        refetchQueries: () => [
          {
            query: GET_LIST_MENU_PRODUCTS,
            variables: {
              input: {
                token,
                menu: localMenu?.menuItem?.id || "",
                name: "",
                sorting: "NAME_ASC",
              },
            },
          },
          {
            query: GET_LIST_TAGS_BY_MENU,
            variables: {
              input: {
                token,
                menu: localMenu?.menuItem?.id || "",
              },
            },
          },
        ],
        onCompleted: () => {
          snackBarVar({
            open: true,
            severity: "success",
            message: "successfully registered!",
          });
          setShowPopupSav(false);
        },
      });
      if (redirection) history.push("/product");
      else
        history.push({
          pathname: `/managment-product/${id}`,
        });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const handleSave = () => {
    if (id) {
      if (
        validation(
          generalProduct?.identifier || "",
          generalProduct?.names?.[0]?.value || ""
        )
      )
        UpdateProduct({
          variables: {
            input: {
              ...generalProduct,
              token,
              id: id!,
            },
          },
        });
    } else if (
      validation(
        generalProduct?.identifier || "",
        generalProduct?.names?.[0]?.value || ""
      )
    ) {
      const arrayNames: CreateNameInput[] = [];
      const arrayDescription: CreateDescriptionInput[] = [];

      generalProduct?.names?.map((name) =>
        arrayNames.push({
          languageCode: name.languageCode as LanguageCode,
          value: name.value,
        })
      );

      generalProduct?.descriptions?.map((description) =>
        arrayDescription.push({
          languageCode: description.languageCode as LanguageCode,
          value: description.value,
        })
      );

      createProduct({
        variables: {
          input: {
            token,
            menu: localMenu?.menuItem?.id || "",
            category: generalProduct?.category,
            categories: generalProduct?.categories,
            identifier: generalProduct?.identifier,
            names: arrayNames,
            descriptions: arrayDescription,
            kind: generalProduct?.kind,
            tags: generalProduct?.tags,
            vendor: generalProduct?.vendor,
            vendorId: generalProduct?.vendorId,
            brand: generalProduct?.brand,
            unit: generalProduct?.unit,
            picture: generalProduct?.picture,
            copiedPictures: generalProduct?.copiedPictures,
            gallery: galleryProduct,
            salesCount: generalProduct?.salesCount,
          },
        },
      });
    }
  };

  useEffect(() => {
    location.state?.tab && setMode(location.state.tab);
  }, [location]);

  useEffect(() => {
    const dataSetting = dataProductSetting?.getProductSettings.productSettings;
    setFormProductSetting({
      ...formProductSetting,
      id: dataSetting?.id || "",
      barcode: dataSetting?.barcode,
      sku: dataSetting?.sku,
      shippingWeight: dataSetting?.shippingWeight,
      price: {
        currency: dataSetting?.price?.currency?.id,
        value: dataSetting?.price?.value,
      },
      discount: {
        percentage: dataSetting?.discount?.percentage,
        price: {
          currency: dataSetting?.discount?.price?.currency?.id,
          value: dataSetting?.discount?.price?.value,
        },
      },
      hasMultiLanguages: dataSetting?.hasMultiLanguages,
      hasMultiSites: dataSetting?.hasMultiSites,
      hasVariants: dataSetting?.hasVariants,
      deliveryMode: dataSetting?.deliveryMode,
      isPublished: dataSetting?.isPublished,
      mustCheckAvailaibitly: dataSetting?.mustCheckAvailaibitly,
    });

    dataSetting?.shippingWeight &&
      setShippingWeight(String(dataSetting?.shippingWeight));

    dataSetting?.price?.value &&
      setPriceInput(dataSetting?.price?.value?.toString() || "");
    dataSetting?.discount?.price?.value &&
      setPriceDiscountInput(
        dataSetting?.discount?.price?.value?.toString() || ""
      );
  }, [dataProductSetting]);

  const handleMode = (option: Tabs) => {
    if (!id) {
      if (
        validation(
          generalProduct?.identifier || "",
          generalProduct?.names?.[0]?.value || ""
        )
      )
        snackBarVar({
          open: true,
          severity: "info",
          message: "You're should create product first!",
        });
    } else {
      setMode(option);
      formProductRequiredVar(undefined);
    }
  };
  const handleModaleSave = () => {
    if (id) {
      if (generalProduct?.identifier && generalProduct.names) {
        setShowPopupSav(true);
      }
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handlCalculateDiscount = (
    originalPrice: number,
    discountPercentage: number
  ) => {
    if (discountPercentage && originalPrice) {
      const discountedPrice =
        originalPrice - (originalPrice * discountPercentage) / 100;

      setFormProductSetting({
        ...formProductSetting,
        discount: {
          ...formProductSetting.discount,
          price: {
            currency: localMenu?.menuItem?.currency?.id,
            value: Math.trunc(discountedPrice * 100) / 100,
          },
        },
      });
      setPriceDiscountInput(
        (Math.trunc(discountedPrice * 100) / 100).toString()
      );
    } else {
      setFormProductSetting({
        ...formProductSetting,
        discount: {
          ...formProductSetting.discount,
          price: {
            currency: localMenu?.menuItem?.currency?.id,
            value: null,
          },
        },
      });
      setPriceDiscountInput("");
    }
  };

  const handleCalculatePercent = (
    originalPrice: number,
    discountPrice: number
  ) => {
    if (originalPrice && discountPrice) {
      const discountPercentage = 100 - (discountPrice * 100) / originalPrice;
      setFormProductSetting({
        ...formProductSetting,
        discount: {
          ...formProductSetting.discount,
          percentage: Math.trunc(discountPercentage * 100) / 100,
        },
      });
    } else {
      setFormProductSetting({
        ...formProductSetting,
        discount: {
          ...formProductSetting.discount,
          percentage: null,
        },
      });
    }
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={[
          intl.formatMessage({ id: "ProductForm.ProductManagement" }),
          intl.formatMessage({ id: "ProductForm.ProductsList" }),
        ]}
        icon={products}
        iconAlt="products"
      />
      <div className="container">
        <div>
          <nav>
            <div
              role="button"
              tabIndex={0}
              onKeyUp={() => handleMode("general")}
              onClick={() => handleMode("general")}
              className={`mode ${mode === "general" && "selected"}`}
            >
              {intl.formatMessage({ id: "ProductForm.general" })}
            </div>
            {/* commented temporarily */}
            {/* <div
              role="button"
              tabIndex={0}
              onKeyUp={() => handleMode("attributes")}
              onClick={() => handleMode("attributes")}
              className={`mode ${mode === "attributes" && "selected"}`}
            >
              {intl.formatMessage({ id: "ProductForm.attributes" })}
            </div> */}
            <div
              role="button"
              tabIndex={0}
              onKeyUp={() => {
                handleMode("options");
                setShowPopupSav(false);
              }}
              onClick={() => {
                handleMode("options");
                setShowPopupSav(false);
              }}
              className={`mode ${mode === "options" && "selected"}`}
            >
              {intl.formatMessage({ id: "ProductForm.options" })}
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyUp={() => handleMode("variations")}
              onClick={() => handleMode("variations")}
              className={`mode ${mode === "variations" && "selected"}`}
            >
              {intl.formatMessage({ id: "ProductForm.variation" })}
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyUp={() => {
                handleMode("stock");
                setShowPopupSav(false);
              }}
              onClick={() => {
                handleMode("stock");
                setShowPopupSav(false);
              }}
              className={`mode ${mode === "stock" && "selected"}`}
            >
              {intl.formatMessage({ id: "ProductForm.stock" })}
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyUp={() => {
                handleMode("stock");
                setShowPopupSav(false);
              }}
              onClick={() => {
                handleMode("relatedProducts");
                setShowPopupSav(false);
              }}
              className={`mode ${mode === "relatedProducts" && "selected"}`}
            >
              Related Products
            </div>
          </nav>
          {mode === "general" && (
            <GeneralProduct
              onReturnFormProduct={(product) => {
                // testing product state to not show the popup when initializing the object
                if (
                  generalProduct &&
                  generalProduct.identifier &&
                  product.identifier
                ) {
                  if (!isEqual(generalProduct, product)) {
                    setShowPopupSav(true);
                  }
                }
                !loadingDetailsProduct &&
                  setGeneralProduct({ ...generalProduct, ...product });
              }}
              gallery={galleryProduct}
              onGallery={(images) => setGalleryProduct(images)}
              idProduct={id}
              categoryParent={categoryParent}
              detailsProduct={detailsProductPayload}
              categoryProductPayload={generalProduct}
              refetchProduct={refetchDetailsProduct}
            />
          )}
          {mode === "attributes" && <AttributesProduct />}
          {mode === "options" && (
            <OptionsProduct
              hasMultiAddress={formProductSetting.hasMultiSites || false}
            />
          )}
          {mode === "variations" && (
            <VariationProduct
              hasVariation={formProductSetting?.hasVariants || false}
              onActivateVariation={(active) => {
                setFormProductSetting({
                  ...formProductSetting,
                  hasVariants: active,
                });
                handleModaleSave();
              }}
            />
          )}
          {mode === "stock" && (
            <StockProduct
              hasVariants={formProductSetting.hasVariants || false}
              hasMultiAddress={formProductSetting.hasMultiSites || false}
            />
          )}
          {mode === "relatedProducts" && <RelatedProducts />}
        </div>

        <div className="form-pricing">
          <Box width={1} p={10}>
            {!formProductSetting.hasVariants && (
              <>
                <Typography variant="h3" className="title">
                  Product identifier
                </Typography>
                <Input
                  label="Barcode"
                  value={formProductSetting.barcode}
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      barcode: e.target.value,
                    });
                    handleModaleSave();
                  }}
                  style={{ margin: "10px 0" }}
                />
                <Input
                  label="SKU"
                  value={formProductSetting.sku}
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      sku: e.target.value,
                    });
                    handleModaleSave();
                  }}
                  style={{ margin: "10px 0" }}
                />
                <Input
                  label="Shipping weight"
                  value={shippingWeight || ""}
                  onKeyPress={onKeyPress}
                  onChange={(e) => {
                    setShippingWeight(e.target.value);
                    handleModaleSave();
                  }}
                  onBlur={() =>
                    setFormProductSetting({
                      ...formProductSetting,
                      shippingWeight: parseFloat(shippingWeight),
                    })
                  }
                  style={{ margin: "10px 0" }}
                />
              </>
            )}

            <Typography variant="h3" className="title">
              {intl.formatMessage({ id: "ProductForm.pricing" })}
            </Typography>
            <Input
              value={priceInput}
              label={`${intl.formatMessage({
                id: "ProductForm.initialPrice",
              })} (${localMenu?.menuItem?.currency?.code})`}
              onChange={(e) => {
                setPriceInput(e.target.value);
                handleModaleSave();
                handlCalculateDiscount(
                  +e.target.value,
                  formProductSetting?.discount?.percentage || 0
                );
                handleCalculatePercent(
                  +e.target.value,
                  formProductSetting?.discount?.price?.value || 0
                );
              }}
              onBlur={(e) => {
                const value = parseFloat(e.target.value);
                setFormProductSetting({
                  ...formProductSetting,
                  price: {
                    currency: localMenu?.menuItem?.currency?.id,
                    value,
                  },
                });
                if (!Number.isNaN(value)) setPriceInput(value.toString());
              }}
              onKeyPress={onKeyPress}
              style={{ margin: "10px 0" }}
            />

            <Input
              label={intl.formatMessage({ id: "ProductForm.sales" })}
              value={formProductSetting.discount?.percentage}
              type="number"
              onChange={async (e) => {
                await setFormProductSetting({
                  ...formProductSetting,
                  discount: {
                    ...formProductSetting.discount,
                    percentage: +e.target.value,
                  },
                });

                handleModaleSave();
              }}
              onKeyPress={onKeyPress}
              onKeyUp={(e) => {
                const target = e.target as HTMLTextAreaElement;
                handlCalculateDiscount(
                  formProductSetting?.price?.value || 0,
                  +target.value
                );
              }}
              style={{ margin: "10px 0" }}
            />
            <Input
              value={priceDiscountInput}
              label={`${intl.formatMessage({
                id: "ProductForm.afterSales",
              })} (${localMenu?.menuItem?.currency?.code})`}
              onChange={(e) => {
                setPriceDiscountInput(e.target.value);
                handleCalculatePercent(
                  formProductSetting?.price?.value || 0,
                  +e.target.value
                );
                handleModaleSave();
              }}
              onBlur={(e) => {
                const value = parseFloat(e.target.value);
                setFormProductSetting({
                  ...formProductSetting,
                  discount: {
                    ...formProductSetting.discount,
                    price: {
                      currency: localMenu?.menuItem?.currency?.id,
                      value,
                    },
                  },
                });
                if (!Number.isNaN(value))
                  setPriceDiscountInput(value.toString());
              }}
              onKeyPress={onKeyPress}
              style={{ margin: "10px 0" }}
            />
            <div className="option-switch">
              <Typography variant="h3" className="title">
                {intl.formatMessage({ id: "ProductForm.productsSettings" })}
              </Typography>
              <div>
                <Switch
                  color="secondary"
                  checked={formProductSetting.isPublished || false}
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      isPublished: e.target.checked,
                    });
                    handleModaleSave();
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span>
                  {intl.formatMessage({ id: "ProductForm.showInStore" })}
                </span>
              </div>
              <div>
                <Switch
                  color="secondary"
                  checked={formProductSetting.hasVariants || false}
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      hasVariants: e.target.checked,
                    });
                    handleModaleSave();
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span>
                  {intl.formatMessage({ id: "ProductForm.hasVariations" })}
                </span>
              </div>
              {/* has MultiLanguages commented */}
              <div>
                <Switch
                  color="secondary"
                  checked={formProductSetting.hasMultiLanguages || false}
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      hasMultiLanguages: e.target.checked,
                    });
                    handleModaleSave();
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span>
                  {intl.formatMessage({ id: "ProductForm.hasMultiLanguages" })}
                </span>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ color: "#1F90CF" }}>
                  {intl.formatMessage({ id: "ProductForm.manageDictionary" })}
                </span> */}
              </div>
              {/* #### it's a comment because multi address is not completed #### */}
              {/* <div>
                <Switch
                  color="secondary"
                  checked={formProductSetting.hasMultiSites || false}
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      hasMultiSites: e.target.checked,
                    });
                    handleModaleSave();
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  disabled
                />
                <span>
                  {intl.formatMessage({ id: "ProductForm.activateMultiSites" })}
                </span>
              </div> */}
              {host !== HOST_FORSA && (
                <div>
                  <Switch
                    color="secondary"
                    checked={formProductSetting.mustCheckAvailaibitly || false}
                    onChange={(e) => {
                      setFormProductSetting({
                        ...formProductSetting,
                        mustCheckAvailaibitly: e.target.checked,
                      });
                      handleModaleSave();
                    }}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <span>Must check availibility</span>
                </div>
              )}
              <div>
                <Switch
                  color="secondary"
                  checked={
                    formProductSetting.deliveryMode === "EXPRESS" || false
                  }
                  onChange={(e) => {
                    setFormProductSetting({
                      ...formProductSetting,
                      deliveryMode: e.target.checked ? "EXPRESS" : "STANDARD",
                    });
                    handleModaleSave();
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span>Express Delivery</span>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ color: "#1F90CF" }}>
                  {intl.formatMessage({ id: "ProductForm.manageDictionary" })}
                </span> */}
              </div>
            </div>
          </Box>
        </div>
      </div>

      {showPopupSave && (
        <div className="popup-save">
          <span>There are unsaved modification on these page</span>
          <div>
            <Button
              onClick={async () => {
                await setRedirection(true);
                handleSave();
              }}
              disabled={loading || loadingProductSetting}
              style={{ color: "#1f90cf", marginRight: "10px" }}
            >
              Save and exit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              disabled={loading && loadingProductSetting}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default ProductForm;
