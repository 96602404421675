// Import libraries
import TablePaginationMUI from "@material-ui/core/TablePagination";
import { withStyles, createStyles } from "@material-ui/core/styles";

const TablePagination = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
      border: "none",
    },
    toolbar: {
      padding: 0,
    },
  })
)(TablePaginationMUI);

export default TablePagination;
