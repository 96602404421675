import React from "react";
import styled from "styled-components";
import { FilledInputProps } from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { InputBase } from "@material-ui/core";

type InputProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
};

const InputSimple: React.FC<InputProps> = ({
  msgError,
  fullWidth,
  label,
  className,
  disabled,
  ...restProps
}) => {
  let elmClass = "mainInput";
  if (className !== undefined) {
    elmClass = `${elmClass} ${className}`;
  }
  return (
    <FormControlStyled
      variant="filled"
      error={msgError !== "" && msgError !== undefined}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {label && <Label> {label} </Label>}
      <InputBase inputProps={{ className: elmClass }} {...restProps} />
      {msgError && <FormHelperText> {msgError} </FormHelperText>}
    </FormControlStyled>
  );
};

const Label = styled.div`
  color: #444444;
  font-size: 14px;
  margin-bottom: 10px;
`;

const FormControlStyled = styled(FormControl)`
  flex: 1;
  .MuiInputBase-root {
    overflow: "hidden";
    background-color: #ffffff;
    padding: 8px 12px;
    border: 2px solid #f1f2ff;
    /* padding-left: 5px;
    padding-right: 5px; */
    border-radius: 5px;
    .mainInput {
      padding: 0;
      margin: 0;
    }
    .MuiInputAdornment-root {
      p {
        font-size: 0.95rem;
        opacity: 0.7;
        margin-right: 0;
      }
    }
    &:focus-within {
      border: 2px solid #000000;
      border-radius: 5px;
    }
  }
  .Mui-error {
    .MuiInputBase-root {
      border: 2px solid #f67070;
      border-radius: 5px;
    }
  }
`;

export default InputSimple;
