import * as React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from "@apollo/client";

import { ButtonCustomize } from "../../commons";

import {
  GET_EASYPAY_LIST_CATEGORIES,
  GET_EASYPAY_LIST_REGIONS,
  GET_EASYPAY_LIST_CARDS,
  GET_EASYPAY_LIST_CARD_CLASSES,
} from "../../api";
import {
  GetEasypayListCategoriesQuery,
  GetEasypayListCategoriesQueryVariables,
  GetEasypayListRegionsQuery,
  GetEasypayListRegionsQueryVariables,
  GetEasypayListCardsQuery,
  GetEasypayListCardsQueryVariables,
  GetEasypayListCardClassesQuery,
  GetEasypayListCardClassesQueryVariables,
  GetEasyPayListCardsInput,
} from "../../api/types";

type AssignProductTypeEasyPayModalProps = {
  open: boolean;
  onReturn: (id: string, name: string) => void;
  onClose: () => void;
};

const AssignProductTypeEasyPayModal = ({
  open,
  onReturn,
  onClose,
}: AssignProductTypeEasyPayModalProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const [stateCards, setStateCards] = React.useState<GetEasyPayListCardsInput>({
    token,
    catId: "",
    regionId: "",
  });
  const [cardId, setCardId] = React.useState("");
  const [cardClassId, setCardClassId] = React.useState("");
  const [cardClassName, setCardClassName] = React.useState("");

  const { data: dataCategories } = useQuery<
    GetEasypayListCategoriesQuery,
    GetEasypayListCategoriesQueryVariables
  >(GET_EASYPAY_LIST_CATEGORIES, {
    variables: {
      input: { token },
    },
  });

  const listCategories = dataCategories?.getEasypayListCategories?.list || [];

  const { data: dataRegions } = useQuery<
    GetEasypayListRegionsQuery,
    GetEasypayListRegionsQueryVariables
  >(GET_EASYPAY_LIST_REGIONS, {
    variables: {
      input: { token },
    },
  });

  const listRegions = dataRegions?.getEasypayListRegions?.list || [];

  const { data: dataCards, loading: loadingCards } = useQuery<
    GetEasypayListCardsQuery,
    GetEasypayListCardsQueryVariables
  >(GET_EASYPAY_LIST_CARDS, {
    variables: {
      input: {
        ...stateCards,
      },
    },
    skip: !stateCards.catId || !stateCards.regionId,
  });

  const listCards = dataCards?.getEasypayListCards?.list || [];

  const { data: dataCardClass, loading } = useQuery<
    GetEasypayListCardClassesQuery,
    GetEasypayListCardClassesQueryVariables
  >(GET_EASYPAY_LIST_CARD_CLASSES, {
    variables: {
      input: {
        token,
        cardId,
      },
    },
    skip: !cardId,
  });

  const listCardClass = dataCardClass?.getEasypayListCardClasses?.list || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEFF2"
          p={2}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Assign Product
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <Autocomplete
            id="EasyPayCategory"
            options={listCategories}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(event, value) =>
              value?.id && setStateCards({ ...stateCards, catId: value.id })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="EasyPay Category"
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
            )}
            style={{ marginBottom: 16 }}
          />
          <Autocomplete
            id="EasyPayRegion"
            options={listRegions}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(event, value) =>
              value?.id && setStateCards({ ...stateCards, regionId: value.id })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="EasyPay Region"
                variant="filled"
                InputLabelProps={{ shrink: true }}
              />
            )}
            style={{ marginBottom: 16 }}
          />
          <Autocomplete
            id="EasyPayCard"
            options={listCards}
            getOptionLabel={(option) => option.name || ""}
            onChange={(event, value) => value?.id && setCardId(value.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="EasyPay Card"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingCards && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{ marginTop: -20 }}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            loading={loadingCards}
            disabled={!stateCards.catId || !stateCards.regionId}
            style={{ marginBottom: 16 }}
          />
          <Autocomplete
            id="EasyPayCardClass"
            options={listCardClass}
            getOptionLabel={(option) => option.name || ""}
            onChange={(event, value) => {
              value?.id && setCardClassId(value.id);
              value?.name && setCardClassName(value.name);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="EasyPay Card Class"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{ marginTop: -20 }}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            loading={loading}
            disabled={!cardId}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={2}
          paddingLeft={2}
          paddingRight={2}
          paddingBottom={2}
        >
          <ButtonCustomize
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Discard
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={() => {
              onReturn(cardClassId, cardClassName);
              onClose();
              setStateCards({
                token,
                catId: "",
                regionId: "",
              });
              setCardId("");
              setCardClassId("");
            }}
            disabled={!cardClassId}
          >
            Assign
          </ButtonCustomize>
        </Box>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  min-width: 500px;
  flex-direction: column;
  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  .MuiFilledInput-underline:after {
    border-bottom: none;
  }
  .MuiInputBase-root {
    background-color: #ffffff;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus-within {
      border: 2px solid #000000;
      border-radius: 5px;
    }
  }
`;

export default AssignProductTypeEasyPayModal;
