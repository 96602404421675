import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useReactiveVar } from "@apollo/client";

import find from "lodash/find";
import { URL_THEME_PICTURES } from "../api";
import { MenuProductPayload, MenuPayload } from "../api/types";
import { SelectedMenuVar } from "../api/local-state";

import { nameCategory } from "../utils";
import { Tabs } from "../modules/Products/Products--types";

type ProductGridProps = {
  productData: MenuProductPayload;
  onReturn: (id: string, anchorEl: HTMLElement | null) => void;
};

export default function ProductGrid({
  productData,
  onReturn,
}: ProductGridProps) {
  const intl = useIntl();
  const history = useHistory();
  const [idProduct, setIdProduct] = React.useState<string | null | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  const handleOnEdit = (tab: Tabs, id?: string) => {
    setAnchorEl(null);
    history.push({
      pathname: `/managment-product/${id || idProduct}`,
      state: {
        tab,
      },
    });
  };

  return (
    <Container>
      <CardHeader
        action={
          <Box display="flex" alignItems="center">
            {productData.mustCheckAvailaibitly && (
              <Tooltip title="Delivery up to 6 hours" placement="top">
                <AccessTimeIcon style={{ marginRight: 5 }} />
              </Tooltip>
            )}
            <IconButton
              aria-label="settings"
              onClick={(e) => {
                productData?.id && onReturn(productData.id, e.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        }
        title={
          find(
            productData.names,
            (o) => o.languageCode === languageIsMain?.code
          )?.value
        }
      />
      <CardMedia
        className="media"
        image={`${URL_THEME_PICTURES}${productData.picture?.fileUrl}`}
        title="Paella dish"
      />
      <CardContent>
        <Typography variant="subtitle2">
          <b>{intl.formatMessage({ id: "ProductForm.Category" })}</b> &nbsp; :
          &nbsp;
          {productData.mainCategory &&
          productData.currentCategory &&
          nameCategory(
            productData.mainCategory,
            productData.currentCategory,
            languageIsMain?.code
          ) === "ProductForm.notAssigned"
            ? intl.formatMessage({
                id: `${nameCategory(
                  productData.mainCategory!,
                  productData.currentCategory!
                )}`,
              })
            : nameCategory(
                productData.mainCategory!,
                productData.currentCategory!,
                languageIsMain?.code
              )}
        </Typography>
        <Typography variant="subtitle2">
          <b>{intl.formatMessage({ id: "ProductForm.numberOfOption" })}</b>
          &nbsp; : &nbsp; {productData.optionsCount}
        </Typography>
        <Typography variant="subtitle2">
          <b>{intl.formatMessage({ id: "ProductForm.numberOfVariation" })}</b>
          &nbsp; : &nbsp; {productData.variantsCount}
        </Typography>
      </CardContent>
      <Menu
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleOnEdit("general")}>
          {intl.formatMessage({
            id: "ProductForm.editProduct",
          })}
        </MenuItem>
        <MenuItem onClick={() => handleOnEdit("options")}>
          {intl.formatMessage({
            id: "ProductForm.editOption",
          })}
        </MenuItem>
        <MenuItem onClick={() => handleOnEdit("variations")}>
          {intl.formatMessage({
            id: "ProductForm.editVariation",
          })}
        </MenuItem>
        <MenuItem onClick={() => handleOnEdit("relatedProducts")}>
          Related Products
        </MenuItem>
        <MenuItem style={{ color: "#ff4949" }}>
          {intl.formatMessage({
            id: "ProductForm.deleteVariation",
          })}
        </MenuItem>
      </Menu>
    </Container>
  );
}

const Container = styled(Card)`
  width: 280px;
  max-width: 280px;
  border: 2px solid #f1f2ff;
  border-radius: 5px;
  box-shadow: none;
  .MuiTypography-displayBlock {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
  }
  .media {
    height: 120px;
    width: 120px;
    object-fit: contain;
    margin: auto;
  }
`;
