import React, { useState } from "react";
import { Dialog, Button, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { Input } from "../../commons";

import { ADD_PRODUCT_INVENTORY_LOSS } from "../../api";
import {
  AddProductInventoryLossMutation,
  AddProductInventoryLossMutationVariables,
} from "../../api/types";

import { Wrapper } from "./InventoryLostQuantityModal--styles";

import { snackBarVar } from "../../api/local-state";

type DetailsInventory = {
  id?: string;
  date?: string;
  availableQuantity?: number;
  totalQuantity?: number;
  cost?: {
    currency?: string;
    value?: number;
  };
};

type LostQuantityModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  detailsInventory: DetailsInventory;
  refetch: () => void;
};

const InventoryLostQuantityModal: React.FC<LostQuantityModalProps> = ({
  open,
  title,
  handleClose,
  detailsInventory,
  refetch,
}) => {
  const token = localStorage.getItem("token") || "";

  const [lostStock, setLostStock] = useState<number>(0);
  const [note, setNote] = useState<string>("");

  const [AddInventoryLoss] = useMutation<
    AddProductInventoryLossMutation,
    AddProductInventoryLossMutationVariables
  >(ADD_PRODUCT_INVENTORY_LOSS, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successful operation!",
      });
      refetch();
      handleClose();
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable!",
      });
    },
  });

  const handleSave = () => {
    AddInventoryLoss({
      variables: {
        input: {
          token,
          id: detailsInventory?.id || "",
          date: moment(new Date()).utc(),
          quantity: lostStock,
          notes: note,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Typography variant="h2" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>

        <Typography variant="h2">Product&apos;s Entry Info</Typography>
        <div className="details-inventroy">
          <div>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Acquisittion date
            </Typography>
            <Typography variant="body2">
              {detailsInventory?.date &&
                moment(detailsInventory.date).format("L")}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Cost per item
            </Typography>
            <Typography variant="body2">
              {detailsInventory?.cost?.value}&nbsp;
              {detailsInventory?.cost?.currency}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Quantity
            </Typography>
            <Typography variant="body2">
              {detailsInventory?.availableQuantity}&nbsp;/&nbsp;
              {detailsInventory?.totalQuantity}
            </Typography>
          </div>
        </div>
        <Typography variant="h2">Set Lost Quantity</Typography>
        <Input
          label="Quantity"
          type="number"
          value={lostStock || ""}
          onChange={(e) => setLostStock(+e.target.value)}
          msgError={
            detailsInventory?.availableQuantity &&
            lostStock > detailsInventory.availableQuantity
              ? "Lost Quantity cant be more than available quantity"
              : ""
          }
        />
        <Typography variant="h2">Leave a note</Typography>
        <Input
          label="Note"
          rows={5}
          multiline
          value={note}
          onChange={(e) => setNote(e.target.value)}
          maxLength={255}
        />
        <div className="calculate-quantity">
          <div>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Old Quantity
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Lost
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              New Quantity
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              {detailsInventory?.availableQuantity}&nbsp;/&nbsp;
              {detailsInventory?.totalQuantity}
            </Typography>
            <Typography variant="body2">- {lostStock || 0}</Typography>
            <Typography variant="body2">
              {(detailsInventory?.availableQuantity || 0) - (lostStock || 0)}
              &nbsp;/&nbsp;
              {detailsInventory?.totalQuantity}
            </Typography>
          </div>
        </div>
        <div className="action">
          <Button onClick={handleClose}>Discard</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              !!(
                detailsInventory?.availableQuantity &&
                lostStock > detailsInventory.availableQuantity
              )
            }
          >
            Save
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default InventoryLostQuantityModal;
