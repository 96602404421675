// Libraries imports
import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import Alert from "@material-ui/lab/Alert";
import {
  Snackbar,
  CircularProgress,
  Switch,
  Box as BoxMaterialUI,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import findIndex from "lodash/findIndex";
import find from "lodash/find";

// Local Imports
import { Wrapper, Separator, Note } from "./styles";
import {
  BreadCrumbsTitle,
  Box,
  Input,
  ButtonCustomize,
} from "../../../commons";
import { Upload } from "../../../components";
import {
  ValidationCategoryForm,
  getCheckSpecialCaratcters,
  getCheckAlphanumeric,
} from "./utils";

// Components Imports

// API imports
import { URL_PICTURES } from "../../../api/config";
import {
  CREATEMENUCATEGORY,
  GETMENUCATEGORYDETAILS,
  UPDATEMENUCATEGORIES,
  GETLISTMENUCATEGORIES,
  HOST_CSHOP,
  HOST_RAWI,
} from "../../../api";
import {
  CreateMenuCategoryMutation,
  CreateMenuCategoryMutationVariables,
  CopiedPictureInput,
  CreateNameInput,
  CreateDescriptionInput,
  UpdateNameInput,
  UpdateDescriptionInput,
  CategoryDisplay,
  GetMenuCategoryDetailsQuery,
  GetMenuCategoryDetailsQueryVariables,
  UpdateMenuCategoryMutation,
  UpdateMenuCategoryMutationVariables,
  MenuPayload,
  LanguageCode,
} from "../../../api/types";
import { GET_MENU_LOCAL, SelectedMenuVar } from "../../../api/local-state";

// Other Imports
import { ReactComponent as Grid } from "../../../img/grid.svg";
import { ReactComponent as List } from "../../../img/list.svg";
import {
  HomeDisplay,
  GridDisplay,
  ListDisplay,
  SlidesDisplay,
  OtherDisplay,
} from "../../../img/digishop/menu-icons";

// Types

type Check = {
  alphanumeric: undefined | boolean;
  specialCaratcters: undefined | boolean;
};

type LocationType = {
  id?: string;
  level?: number;
  parent?: string;
  main?: string;
};

const { host } = window.location;

const CategoryCreate: FC = () => {
  // Local variables
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<LocationType>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  // States
  const [identifier, setIdentifier] = React.useState<string>("");
  const [isPublished, setIsPublished] = React.useState<boolean>(true);
  const [hasMultiLanguages, setHasMultiLanguages] =
    React.useState<boolean>(true);
  const [display, setDisplay] = React.useState<CategoryDisplay>("GRID");
  const [level, setLevel] = React.useState<number>(1);
  const [parent, setParent] = React.useState<string | null>(null);
  const [main, setMain] = React.useState<string | null>(null);
  const [idPicture, setIdPicture] = React.useState<string | null>();
  const [copiedPicture, setCopiedPicture] = React.useState<
    CopiedPictureInput[]
  >([]);
  const [names, setName] = React.useState<UpdateNameInput[]>([]);
  const [descriptions, setDescriptions] = React.useState<
    UpdateDescriptionInput[]
  >([]);

  const [snackbarMessages, setSnackbarMessages] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({
    parent: "",
    identifier: "",
  });
  const [nameError, setNameError] = React.useState<Check>({
    alphanumeric: undefined,
    specialCaratcters: undefined,
  });
  const [openFormat, setOpenFormat] = React.useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = React.useState<boolean>(false);

  // Queries & Mutaions
  const { data: LocalMenu } = useQuery(GET_MENU_LOCAL);

  const { data: categoryDetailsData } = useQuery<
    GetMenuCategoryDetailsQuery,
    GetMenuCategoryDetailsQueryVariables
  >(GETMENUCATEGORYDETAILS, {
    skip: !location.state.id,
    variables: { input: { token, id: location.state.id || "" } },
    onCompleted: (data) => {
      const arrayNames: UpdateNameInput[] = [];
      const arrayDescription: UpdateDescriptionInput[] = [];
      const category = data?.getMenuCategoryDetails.category;
      setIdentifier(category?.identifier || "");

      category.names?.map((name) =>
        arrayNames.push({
          id: name.id,
          languageCode: name.languageCode as LanguageCode,
          value: name.value,
        })
      );

      category.descriptions?.map((description) =>
        arrayDescription.push({
          id: description.id,
          languageCode: description.languageCode as LanguageCode,
          value: description.value,
        })
      );

      setName(arrayNames);
      setDescriptions(arrayDescription);
      setHasMultiLanguages(category?.hasMultiLanguages || false);
      setParent(category?.parent || null);
      setMain(category?.main || "");
      category?.level && setLevel(category?.level);
      setDisplay((category?.display as CategoryDisplay) || "GRID");
      setIsPublished(category?.isPublished || false);
      setIdPicture(category?.picture?.id);
    },
  });

  const [createMenuCategory, { loading }] = useMutation<
    CreateMenuCategoryMutation,
    CreateMenuCategoryMutationVariables
  >(CREATEMENUCATEGORY, {
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.message === "identifier_already_exists") {
        setSnackbarMessages(
          intl.formatMessage({ id: "CategoryForm.identifierAlreadyExists" })
        );
        setOpen(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          identifier: "CategoryForm.identifierAlreadyExists",
        }));
      } else if (
        error?.graphQLErrors?.[0]?.message === "identifier_is_not_alphabectic"
      ) {
        setSnackbarMessages(intl.formatMessage({ id: "format.error" }));
        setOpenFormat(true);
      }
    },
    refetchQueries: () => [
      {
        query: GETLISTMENUCATEGORIES,
        variables: { input: { token, menu: localMenu?.id } },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      history.push("/categories");
    },
  });

  const category = categoryDetailsData?.getMenuCategoryDetails.category;

  const [updateCategory] = useMutation<
    UpdateMenuCategoryMutation,
    UpdateMenuCategoryMutationVariables
  >(UPDATEMENUCATEGORIES, {
    variables: {
      menu: {
        token,
        menu: LocalMenu?.menuItem?.id || "",
        id: location.state.id || "",
        identifier,
        names,
        descriptions,
        isPublished,
        hasMultiLanguages,
        level,
        main,
        parent,
        display,
        picture: idPicture,
        copiedPictures: copiedPicture,
      },
    },
    onCompleted: () => {
      history.push("/categories");
    },
    refetchQueries: () => [
      {
        query: GETMENUCATEGORYDETAILS,
        variables: { input: { token, id: location.state.id || "" } },
      },
      {
        query: GETLISTMENUCATEGORIES,
        variables: { input: { token, menu: localMenu?.id } },
      },
    ],
    awaitRefetchQueries: true,
  });

  // Functions
  useEffect(() => {
    if (location?.pathname === "/create-category") {
      setLevel(location.state.level || 1);
      setParent(location.state.parent || null);
      setMain(location.state.main || null);
    }
  }, [location]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFormat(false);
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayNames = [...names];
    const position = findIndex(
      arrayNames,
      (o) => o.languageCode === languageIsMain?.code
    );
    if (position !== -1) {
      arrayNames[position] = {
        ...arrayNames[position],
        value: event.target.value,
      };
    } else {
      arrayNames.push({
        languageCode: languageIsMain?.code as LanguageCode,
        value: event.target.value,
      });
    }
    setName(arrayNames);
  };
  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDescription = [...descriptions];
    const position = findIndex(
      arrayDescription,
      (o) => o.languageCode === languageIsMain?.code
    );

    if (position !== -1) {
      arrayDescription[position] = {
        ...arrayDescription[position],
        value: event.target.value,
      };
    } else {
      arrayDescription.push({
        languageCode: languageIsMain?.code as LanguageCode,
        value: event.target.value,
      });
    }
    setDescriptions(arrayDescription);
  };

  const handleFormat = (ch: string): string => {
    if (ch.indexOf(" ") >= 0) {
      return ch.replace(/ /g, "-");
    }
    return ch.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  const handleOnChangeIdentifier = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const format = /[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
    if (event.target.value) {
      setNameError({ ...nameError, alphanumeric: true });
      if (event.target.value && !format.test(event.target.value)) {
        setNameError({
          ...nameError,
          alphanumeric: true,
          specialCaratcters: true,
        });
      } else {
        setNameError({
          ...nameError,
          alphanumeric: true,
          specialCaratcters: false,
        });
      }
    } else {
      setNameError({ alphanumeric: undefined, specialCaratcters: undefined });
    }

    setIdentifier(handleFormat(event.target.value));

    setErrors((prevErrors) => ({ ...prevErrors, identifier: "" }));
  };

  const handleOnSubmit = () => {
    const validation = ValidationCategoryForm(level, parent, identifier);

    // check the table "found" if there is 0 and validation if retrun empty
    if (Object.entries(validation).length === 0) {
      const arrayNames: CreateNameInput[] = [];
      const arrayDescription: CreateDescriptionInput[] = [];

      names.map((name) =>
        arrayNames.push({
          languageCode: name.languageCode,
          value: name.value,
        })
      );

      descriptions.map((description) =>
        arrayDescription.push({
          languageCode: description.languageCode,
          value: description.value,
        })
      );

      // create Menu Category
      createMenuCategory({
        variables: {
          category: {
            token,
            menu: LocalMenu?.menuItem?.id || "",
            identifier,
            level,
            parent,
            main,
            descriptions: arrayDescription,
            display,
            hasMultiLanguages,
            picture: idPicture,
            isPublished,
            pictureIsVisible: true,
            names: arrayNames,
            copiedPictures: copiedPicture,
          },
        },
      });
    }
  };

  return (
    <>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Product Management", "Categories"]}
          iconAlt="Category"
        />
        <ButtonCustomize
          data-testid="submit-button"
          style={{ position: "relative", color: "#FFFFFF" }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            location.state.id ? updateCategory() : handleOnSubmit();
          }}
          disabled={loading || loadingUpload}
        >
          <span className="btn-description">
            {intl.formatMessage({ id: "CategoryForm.save" })}
          </span>
          {loading && (
            <CircularProgress size={24} style={{ position: "absolute" }} />
          )}
        </ButtonCustomize>
      </Note>
      <Wrapper>
        <Box width={1} p={16}>
          <h3>Category Informations</h3>
          <div className="input-container">
            <Input
              id="categoryUrl"
              name="categoryUrl"
              label="url"
              value={identifier || ""}
              onChange={handleOnChangeIdentifier}
              dataTestId="categoryUrl"
              msgError={
                errors?.identifier &&
                intl.formatMessage({ id: errors.identifier })
              }
            />
            <div className="checklist">
              <div className="tip" />
              <ul>
                <li className={getCheckAlphanumeric(nameError.alphanumeric)}>
                  <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                  {intl.formatMessage({ id: "format.alphaneumeric" })}
                </li>
                <li
                  className={getCheckSpecialCaratcters(
                    nameError.specialCaratcters
                  )}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                  {intl.formatMessage({
                    id: "format.specialCaracters",
                  })}
                </li>
              </ul>
            </div>
          </div>
          <div className="input-container">
            <Input
              id="categoryName"
              name="categoryName"
              label="Name"
              value={
                find(names, (o) => o.languageCode === languageIsMain?.code)
                  ?.value || ""
              }
              onChange={handleName}
              dataTestId="categoryName"
            />
          </div>
          <Input
            name="description"
            id="description"
            rows={5}
            multiline
            label="description"
            value={
              find(descriptions, (o) => o.languageCode === languageIsMain?.code)
                ?.value || ""
            }
            onChange={handleDescription}
          />
        </Box>
        <div>
          <Upload
            title={intl.formatMessage({ id: "CategoryForm.uploadTitle" })}
            idMenu={LocalMenu?.menuItem?.id || ""}
            uploadTo="Category"
            idPicture={category?.picture?.id || ""}
            urlImage={{
              id: category?.picture?.id || "",
              url: category?.picture?.fileUrl
                ? `${URL_PICTURES}${category?.picture?.fileUrl}`
                : "",
            }}
            onIdPicture={(id) => setIdPicture(id)}
            onLoading={(uploadLoading) => {
              setLoadingUpload(uploadLoading);
            }}
            onReturnCopiedPictures={(listPictues) =>
              setCopiedPicture(listPictues)
            }
          />
          <Separator />
          {host === HOST_RAWI &&
            location?.state?.level &&
            location.state.level === 1 && (
              <Box width={1} p={16}>
                <h3>Category Settings</h3>
                <div className="settings">
                  <label htmlFor="isPublished">Visible in store</label>
                  <Switch
                    id="isPublished"
                    color="secondary"
                    checked={isPublished}
                    onChange={() => {
                      setIsPublished(!isPublished);
                    }}
                    value={isPublished}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
                {host !== HOST_CSHOP && (
                  <>
                    <div className="settings">
                      <label htmlFor="has-multi-languages">
                        Supports Multiple languages
                      </label>
                      <Switch
                        id="has-multi-languages"
                        color="secondary"
                        checked={hasMultiLanguages}
                        onChange={() => {
                          setHasMultiLanguages(!hasMultiLanguages);
                        }}
                        value={hasMultiLanguages}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </div>
                    {host !== HOST_RAWI && (
                      <div className="settings" style={{ marginTop: 8 }}>
                        <Typography variant="subtitle2">
                          Display mode
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <button
                            type="button"
                            className={`item-display-mode ${
                              display === "GRID" && "selected"
                            }`}
                            onClick={() => {
                              setDisplay("GRID");
                            }}
                          >
                            <Grid width="22" height="22" />
                          </button>
                          <button
                            type="button"
                            className={`item-display-mode ${
                              display === "LIST" && "selected"
                            }`}
                            onClick={() => {
                              setDisplay("LIST");
                            }}
                          >
                            <List width="22" height="22" />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {host === HOST_RAWI && (
                  <BoxMaterialUI marginTop={2}>
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: 10 }}
                    >
                      Category display style
                    </Typography>
                    <BoxMaterialUI display="flex" height="132px" gridGap={6}>
                      <BoxMaterialUI
                        onClick={() => {
                          setDisplay("HOME");
                        }}
                      >
                        <img
                          src={HomeDisplay}
                          className={`item-category-display ${
                            display === "HOME" && "item-display-selected"
                          }`}
                          alt="Home Display"
                        />
                      </BoxMaterialUI>
                      <BoxMaterialUI
                        onClick={() => {
                          setDisplay("GRID");
                        }}
                      >
                        <img
                          src={GridDisplay}
                          className={`item-category-display ${
                            display === "GRID" && "item-display-selected"
                          }`}
                          alt="Grid Display"
                        />
                      </BoxMaterialUI>
                      <BoxMaterialUI
                        onClick={() => {
                          setDisplay("LIST");
                        }}
                      >
                        <img
                          src={ListDisplay}
                          className={`item-category-display ${
                            display === "LIST" && "item-display-selected"
                          }`}
                          alt="List Display"
                        />
                      </BoxMaterialUI>
                      <BoxMaterialUI
                        onClick={() => {
                          setDisplay("SLIDES");
                        }}
                      >
                        <img
                          src={SlidesDisplay}
                          className={`item-category-display ${
                            display === "SLIDES" && "item-display-selected"
                          }`}
                          alt="Slides Display"
                        />
                      </BoxMaterialUI>
                      <BoxMaterialUI
                        onClick={() => {
                          setDisplay("OTHER");
                        }}
                      >
                        <img
                          src={OtherDisplay}
                          className={`item-category-display ${
                            display === "OTHER" && "item-display-selected"
                          }`}
                          alt="Other Display"
                        />
                      </BoxMaterialUI>
                    </BoxMaterialUI>
                  </BoxMaterialUI>
                )}
              </Box>
            )}
        </div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => setOpen(false)}
            severity="error"
          >
            {snackbarMessages}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFormat}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {snackbarMessages}
          </Alert>
        </Snackbar>
      </Wrapper>
    </>
  );
};

export default CategoryCreate;
