// Styled component import
import styled from "styled-components";

// Types

export const Note = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  svg {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  span {
    font-weight: 600;
  }
`;

export const Wrapper = styled.div`
  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .view-actins {
    display: flex;
  }
  button.refresh {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    padding: 0 12px 8px 0px;
    color: #03a9f4;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    span {
      padding-bottom: 2px;
    }

    svg {
      margin-right: 12px;
      path {
        fill: #03a9f4;
      }
    }
  }
  .order-list-content {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-column-gap: 20px;
  }
  a.email-link {
    color: #03a9f4;
  }
  .details {
    display: flex;
    color: #949494;
    justify-content: space-between;
    padding: 10px 0;
  }
  .arrow-down {
    position: absolute;
    width: 25px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    svg {
      stroke-width: 2px;
      path {
        fill: #ffffff;
      }
    }
  }
  .payment {
    background-color: #f6c104;
  }
  .fulfillment {
    background-color: #4a4a4a;
  }
  .MuiButton-label {
    display: block;
    font-size: 14px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 20px;
`;
