import { StockInfo } from "./StockProduct";

type Error = Partial<StockInfo>;

export const Validation = (quantity: string): Error => {
  const errors: Error = {};

  if (!quantity) {
    errors.quantity = "This field is require";
  }

  return errors;
};
