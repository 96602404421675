/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Chip } from "@material-ui/core";
import { Send, Add } from "@material-ui/icons";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";

import {
  Box,
  CheckModal,
  Input,
  BreadCrumbsTitle,
  ButtonCustomize,
} from "../../commons";

import {
  GETSUBSCRIBERSLIST,
  CREATESUBSCRIBER,
  DELETESUBSCRIBER,
  SENDEMAIL,
} from "../../api";
import {
  GetListMenuSubscribersQuery,
  GetListMenuSubscribersQueryVariables,
  CreateMenuSubscriberMutation,
  CreateMenuSubscriberMutationVariables,
  DeleteMenuSubscriberMutation,
  DeleteMenuSubscriberMutationVariables,
  SendEmailNewsletterMutation,
  SendEmailNewsletterMutationVariables,
  MenuPayload,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
  SelectedMenuVar,
} from "../../api/local-state";

import { NewsletterImg } from "../../img";

import { Wrapper } from "./ClientsNewsletterStyle";

const ClientsNewsletter: React.FC = () => {
  const intl = useIntl();
  const token = localStorage.getItem("token") || "";

  const [subscriberEmail, setSubscriberEmail] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [object, setObject] = useState<string>("");
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [openSendModal, setOpenSendModal] = useState<boolean>(false);

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const {
    data: subscribersList,
    loading: loadingSubs,
    refetch: refetchSubscribersList,
  } = useQuery<
    GetListMenuSubscribersQuery,
    GetListMenuSubscribersQueryVariables
  >(GETSUBSCRIBERSLIST, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        origin: "CREATED",
      },
    },
    skip: !localMenu?.id,
  });

  const [deleteSubscriber, { loading: loadingDeleteSubs }] = useMutation<
    DeleteMenuSubscriberMutation,
    DeleteMenuSubscriberMutationVariables
  >(DELETESUBSCRIBER, {
    onCompleted: () => {
      refetchSubscribersList();
      snackBarVar({
        open: true,
        severity: "success",
        message: "Operation delete is successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
  });

  const [createSubscriber, { loading: loadingCreateSubs }] = useMutation<
    CreateMenuSubscriberMutation,
    CreateMenuSubscriberMutationVariables
  >(CREATESUBSCRIBER, {
    refetchQueries: () => [
      {
        query: GETSUBSCRIBERSLIST,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
    onError: (error) => {
      if (error.message === "email_already_exists") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Email already exists",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Service unavailable",
        });
      }
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Email add with success",
      });
      refetchSubscribersList();
    },
  });

  const [sendEmail, { loading: loadingSend }] = useMutation<
    SendEmailNewsletterMutation,
    SendEmailNewsletterMutationVariables
  >(SENDEMAIL, {
    onCompleted: (data) => {
      if (data.sendEmailNewsletter.success) {
        setSendSuccess(true);
        setOpenSendModal(true);
      } else {
        setSendSuccess(false);
        setOpenSendModal(true);
      }
    },
  });

  const handleDeleteChip = (id: string) => {
    deleteSubscriber({
      variables: {
        input: {
          id,
          token,
        },
      },
    });
  };

  const handleCreateSubs = () => {
    const testEmail = /\S+@\S+\.\S+/;
    if (subscriberEmail === "") {
      setErrorEmail("This field is empty");
    } else if (!testEmail.test(subscriberEmail)) {
      setErrorEmail("Email invalid");
    } else if (testEmail.test(subscriberEmail)) {
      createSubscriber({
        variables: {
          input: {
            email: subscriberEmail,
            menu: localMenu?.id || "",
          },
        },
      });
    }
  };

  const handleSendEmail = () => {
    if (object !== "" && message !== "") {
      sendEmail({
        variables: {
          input: {
            menu: localMenu?.id || "",
            token,
            message,
            subject: object,
          },
        },
      });
    }
  };

  const handleCloseModal = () => {
    setOpenSendModal(false);
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={[
          intl.formatMessage({ id: "mainLayout.clients" }),
          intl.formatMessage({ id: "store.customer.newsletter" }),
        ]}
        icon={NewsletterImg}
        iconAlt="NewsletterImg"
      />

      <div className="container">
        <Box p={20} width={1} className="container">
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "store.customer.newsletter" })}
          </Typography>
          <div className="form">
            <div className="row">
              <Input
                label={intl.formatMessage({
                  id: "store.customer.newsletter.object",
                })}
                value={object}
                onChange={(e) => {
                  setObject(e.target.value);
                }}
              />
            </div>
            <div className="row">
              <Input
                label={intl.formatMessage({
                  id: "store.customer.newsletter.message",
                })}
                multiline
                rows={6}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
            <div className="row AddTosend">
              <Input
                label={intl.formatMessage({
                  id: "store.customer.newsletter.sentTo",
                })}
                value={subscriberEmail}
                onChange={(e) => {
                  setSubscriberEmail(e.target.value);
                  setErrorEmail("");
                }}
                className="emailInput"
                msgError={errorEmail}
              />
              <ButtonCustomize
                variant="contained"
                color="secondary"
                className="btn-action"
                startIcon={<Add />}
                onClick={handleCreateSubs}
                disabled={loadingCreateSubs}
                style={{ marginLeft: "30px" }}
              >
                {intl.formatMessage({
                  id: "store.customer.newsletter.AddEmail",
                })}
              </ButtonCustomize>
            </div>
            <div className="row chips">
              {subscribersList?.getListMenuSubscribers.list.map((item) => (
                <Chip
                  label={item.email}
                  onDelete={() => handleDeleteChip(item?.id || "")}
                  color="primary"
                  style={{ margin: " 5px 5px 0" }}
                />
              ))}
            </div>
            <div className="row action">
              <ButtonCustomize
                variant="contained"
                color="secondary"
                className="btn-action"
                startIcon={<Send />}
                onClick={handleSendEmail}
                disabled={loadingSend}
              >
                {intl.formatMessage({
                  id: "store.customer.newsletter.send",
                })}
              </ButtonCustomize>
            </div>
          </div>
        </Box>
        <CheckModal
          open={openSendModal}
          handleClose={handleCloseModal}
          toCheck={sendSuccess}
          onPassMessage="Check email success"
          onFailMessage="Check email failed"
        />
      </div>
    </Wrapper>
  );
};

export default ClientsNewsletter;
