import { ApolloClient } from "@apollo/client";
// import local
import { cache } from "./local-state";

const client = new ApolloClient({
  cache,
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
});

export default client;
