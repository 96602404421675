import * as React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";

import { BreadCrumbsTitle, ButtonCustomize, Input } from "../../../commons";
import {
  GET_LIST_MENU_TYPES,
  CREATE_PRODUCT_ATTRIBUTE,
  GET_LIST_PRODUCT_ATTRIBUTES,
  UPDATE_PRODUCT_ATTRIBUTE,
} from "../../../api";
import {
  GetListMenuTypesQuery,
  GetListMenuTypesQueryVariables,
  CreateProductAttributeMutation,
  CreateProductAttributeMutationVariables,
  UpdateProductAttributeMutation,
  UpdateProductAttributeMutationVariables,
  IdValueInput,
  ProductAttributePayload,
  MenuPayload,
} from "../../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../../api/local-state";

const StoreProductTypeForm = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const storePayload = useLocation<ProductAttributePayload>().state;
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const history = useHistory();

  const { data } = useQuery<
    GetListMenuTypesQuery,
    GetListMenuTypesQueryVariables
  >(GET_LIST_MENU_TYPES, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const menuTypes = data?.getListMenuTypes?.list;
  const idTypeBooks =
    menuTypes?.find((o) => o.identifier === "PRODUCTS")?.id || "";

  const [name, setName] = React.useState<IdValueInput>();
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (storePayload) {
      setName({
        id: storePayload?.name?.id,
        value: storePayload.name?.value,
      });
    }
  }, [storePayload]);

  const [createStore, { loading: loadingCreate }] = useMutation<
    CreateProductAttributeMutation,
    CreateProductAttributeMutationVariables
  >(CREATE_PRODUCT_ATTRIBUTE, {
    onCompleted: () => {
      history.push("/stores-type");
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successfully",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_LIST_PRODUCT_ATTRIBUTES,
        variables: {
          input: {
            token,
            identifier: "STORES",
            menuType: idTypeBooks,
          },
        },
      },
    ],
  });

  const [updateStore, { loading: loadingUpdate }] = useMutation<
    UpdateProductAttributeMutation,
    UpdateProductAttributeMutationVariables
  >(UPDATE_PRODUCT_ATTRIBUTE, {
    onCompleted: () => {
      history.push("/stores-type");
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successfully",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_LIST_PRODUCT_ATTRIBUTES,
        variables: {
          input: {
            token,
            identifier: "STORES",
            menuType: idTypeBooks,
          },
        },
      },
    ],
  });

  const handleSave = () => {
    if (name?.value) {
      if (storePayload?.id) {
        updateStore({
          variables: {
            input: {
              token,
              id: storePayload.id,
              name,
            },
          },
        });
      } else {
        createStore({
          variables: {
            input: {
              token,
              menuType: idTypeBooks,
              identifier: "STORES",
              name: name.value,
            },
          },
        });
      }
    } else {
      setError("this field is required");
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <BreadCrumbsTitle
          labelIntl={["Products management", "Store"]}
          iconAlt="Store"
        />
        <Box display="flex">
          <ButtonCustomize
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={loadingCreate || loadingUpdate}
          >
            Save
          </ButtonCustomize>
        </Box>
      </Box>
      <Link to="/stores-type">
        <ButtonCustomize style={{ color: "#1f90cf", marginBottom: 10 }}>
          <ArrowBackIcon /> &nbsp; Return
        </ButtonCustomize>
      </Link>
      <Box component={Paper} p={2} borderRadius={10}>
        <Input
          label="Store’s name"
          value={name?.value || ""}
          onChange={(e) => {
            setName({
              ...name,
              value: e.target.value,
            });
          }}
          msgError={error}
          style={{ width: 400 }}
        />
      </Box>
    </Box>
  );
};

export default StoreProductTypeForm;
