import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const DialogStyled = styled(Dialog)`
  .dialog__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 15px;
    .vector {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      min-width: 220px;
      .img-vector {
        position: absolute;
        width: 150px;
        z-index: 9;
      }
      .img-apparence {
        width: 50px;
        position: absolute;
        z-index: 99;
      }
      .btn-fab-add {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 141px;
        z-index: 999;
      }
      .MuiFab-root.Mui-disabled {
        color: #fff;
        background-color: #f29f05;
      }
    }
    .address__form-title {
      margin-bottom: 55px;
    }
    .form {
      max-width: 700px;
    }
  }
`;
