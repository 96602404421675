import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type ItemOptionProps = {
  verifiedUrl?: boolean;
  link: string;
  title: string;
  src: string;
  alt: string;
};

const ItemOption: React.FC<ItemOptionProps> = ({
  verifiedUrl,
  link,
  title,
  src,
  alt,
}) => {
  return (
    <Wrapper>
      <Link to={link}>
        <div
          className={
            verifiedUrl ? "list-option__item-selected" : "list-option__item"
          }
        >
          <img src={src} alt={alt} />
          <span>{title}</span>
        </div>
      </Link>
    </Wrapper>
  );
};

export default ItemOption;

const Wrapper = styled.div`
  .list-option__item {
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    margin: 5px;
    color: #444444;
    border-radius: 5px;
    img {
      /* width: 40px; */
      width: 30px;
      height: 25px;
      margin-top: 7px;
    }
    span {
      /* margin-top: -5px; */
      font-size: 10px;
    }
  }
  .list-option__item:hover {
    background: #f7ebc3;
  }
  .list-option__item-selected {
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f6c104;
    margin: 5px;
    color: #444444;
    border-radius: 5px;
    img {
      /* width: 40px; */
      width: 30px;
      height: 25px;
      margin-top: 7px;
    }
    span {
      /* margin-top: -5px; */
      font-size: 10px;
    }
  }
  a {
    text-decoration: none;
  }
`;
