import React, { useRef } from "react";
import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CirclePicker } from "react-color";
import rgb512 from "../img/RGB-512.webp";

type InputColorTagsProps = {
  // eslint-disable-next-line
  onReturn: (list: string[]) => void;
  inputValue?: string[];
  lengthNotDelete?: number;
  multiple?: boolean;
};

type ColorInputType = {
  color: string;
};

const arrayColors = [
  "#000000",
  "#03224C",
  "#6d071a",
  "#a76d13",
  "#C8AD7F",
  "#ff7f00",
  "#ff0000",
  "#fd6c9e",
  "#f7f700",
  "#bef574",
  "#708d23",
  "#008000",
  "#0000FF",
  "#7f00ff",
  "#77b5fe",
  "#25fde9",
  "#808080",
  "#ffffff",
];

const InputColorTags: React.FC<InputColorTagsProps> = ({
  onReturn,
  inputValue,
  lengthNotDelete,
  multiple,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [listTags, setListTags] = React.useState<string[]>([]);
  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  const handleDelete = (index: number) => {
    setListTags((prevListTags) => {
      const arrayListTags = [...prevListTags];
      arrayListTags.splice(index, 1);
      onReturn(arrayListTags);
      return arrayListTags;
    });
  };

  React.useEffect(() => {
    if (inputValue) setListTags(inputValue);
  }, [inputValue]);

  const handleClickAway = () => {
    setShowPicker(false);
  };

  const handleShowPicker = () => {
    setShowPicker(!showPicker);
  };

  const length = lengthNotDelete || 0;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container>
        <div className={showPicker ? "tags-container focus" : "tags-container"}>
          <div
            ref={ref}
            className="tags"
            role="button"
            tabIndex={0}
            onClick={handleClickAway}
            onKeyUp={handleClickAway}
          >
            {listTags.map((item, index) => {
              return (
                item && (
                  <div key={`key-${item}`} className="tag">
                    <span>
                      <ColorInput color={item} />
                    </span>
                    {String(lengthNotDelete) !== "undefined" ? (
                      length < index && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          className="iconClose"
                          onClick={() => handleDelete(index)}
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="1x"
                        className="iconClose"
                        onClick={() => handleDelete(index)}
                      />
                    )}
                  </div>
                )
              );
            })}
          </div>
          <div
            className="action-color"
            role="button"
            tabIndex={0}
            onClick={handleShowPicker}
            onKeyUp={handleShowPicker}
          >
            <img src={rgb512} alt="rgb512" />
          </div>
        </div>

        <Popper
          open={showPicker}
          anchorEl={ref?.current}
          className="picker__popup"
          placement="top-start"
          style={{
            zIndex: 9999,
            padding: 8,
            background: "#f6f6f6",
            borderRadius: 8,
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <CirclePicker
            colors={arrayColors}
            onChangeComplete={(color) => {
              setListTags((prevListTags) => {
                const arrayListTags = [...prevListTags];
                if (arrayListTags.indexOf(color.hex) === -1) {
                  if (multiple) {
                    arrayListTags.push(color.hex);
                  } else {
                    arrayListTags[0] = color.hex;
                  }
                }
                onReturn(arrayListTags);
                return arrayListTags;
              });
            }}
          />
        </Popper>
      </Container>
    </ClickAwayListener>
  );
};

const ColorInput = styled.div<ColorInputType>`
  width: 20px;
  height: 20px;
  border: 2px solid #f1f2fb;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const Container = styled.div`
  width: 100%;
  .tags-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    .tags {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      .tag {
        display: flex;
        align-items: center;
        padding: 3px;
        margin: 3px;
        background: #444444;
        color: white;
        border-radius: 4px;
        .iconClose {
          width: 15px;
          margin-left: 3px;
          margin-top: 2px;
          padding: 3px;
        }
        .iconClose:hover,
        .iconClose:active {
          padding: 3px;
          background: #c5c5c5;
          border-radius: 50%;
        }
      }
    }

    .action-color {
      display: flex;
      width: 40px;
      align-items: center;
      justify-content: center;
      img {
        width: 90%;
        &:hover,
        &:active {
          background: #c5c5c5;
          border-radius: 50%;
        }
      }
    }
  }
  .focus {
    border: 2px solid #000000;
    border-radius: 5px;
  }
  .picker__popup {
  }
`;

export default InputColorTags;
