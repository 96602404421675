import React, { useState, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DialogStyled } from "./AddressUpdateModal--styles";
import { Input } from "../../commons";

import {
  GETLISTCOUNTRIES,
  GETLISTCITIES,
  UPDATECOMPANYSITES,
  GETLISTCOMPANYSITES,
} from "../../api";
import {
  GetListCountriesQuery,
  GetListCountriesQueryVariables,
  GetListCitiesQuery,
  GetListCitiesQueryVariables,
  CompanySitePayload,
  UpdateCompanySiteInput,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  LanguageCode,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

import { AddressUpdateModalValidation } from "./AddressUpdateModal--utils";
import { address } from "../../img";
import cercles from "../../img/cercles.png";

export type Errors = {
  identifier?: string;
  address?: string;
  country?: string;
  city?: string;
};

type AddressUpdateModalProps = {
  open: boolean;
  handleClose: () => void;
  dataCompanySite?: CompanySitePayload | null;
};

const AddressUpdateModal: React.FC<AddressUpdateModalProps> = ({
  open,
  handleClose,
  dataCompanySite,
}) => {
  const intl = useIntl();

  // GET SELECTED MENU FROM LOCAL STATE
  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);
  const menuId = localMenu?.menuItem?.id || "";

  const [updateCompanySite, setUpdateCompanySite] =
    useState<UpdateCompanySiteInput>({
      token: localStorage.getItem("token") || "",
      id: dataCompanySite?.id || "",
    });

  // control autocomplete inputs
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    setUpdateCompanySite({
      ...updateCompanySite,
      id: dataCompanySite?.id || "",
      menu: menuId,
      identifier: dataCompanySite?.identifier,
      address: {
        id: dataCompanySite?.address?.id,
        city: dataCompanySite?.address?.city?.id,
        country: dataCompanySite?.address?.country?.id,
        roadNames: [
          {
            id: dataCompanySite?.address?.roadNames?.[0]?.id,
            languageCode: dataCompanySite?.address?.roadNames?.[0]
              ?.languageCode as LanguageCode,
            value: dataCompanySite?.address?.roadNames?.[0]?.value,
          },
        ],
      },
    });
  }, [dataCompanySite, menuId]);

  const { data: listCountries } = useQuery<
    GetListCountriesQuery,
    GetListCountriesQueryVariables
  >(GETLISTCOUNTRIES);

  const { data: listCities } = useQuery<
    GetListCitiesQuery,
    GetListCitiesQueryVariables
  >(GETLISTCITIES, {
    variables: {
      countryId: updateCompanySite?.address?.country || "",
    },
  });

  const [updateSite] = useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UPDATECOMPANYSITES, {
    variables: {
      input: updateCompanySite,
    },
    refetchQueries: () => [
      {
        query: GETLISTCOMPANYSITES,
        variables: {
          token: localStorage.getItem("token") || "",
          menu: menuId,
        },
      },
    ],
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successful operation!",
      });
      handleClose();
    },
  });

  const handleSubmit = () => {
    const validationErrors = AddressUpdateModalValidation(updateCompanySite);
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
    } else {
      updateSite();
    }
  };

  const countryValue = useMemo(() => {
    return listCountries?.getListCountries.list.filter(
      (country) => country.id === updateCompanySite?.address?.country
    )?.[0];
  }, [
    updateCompanySite?.address?.country,
    listCountries?.getListCountries.list,
  ]);

  const cityValue = useMemo(() => {
    return listCities?.getListCities.list.filter(
      (city) => city.id === updateCompanySite?.address?.city
    )?.[0];
  }, [updateCompanySite?.address?.city, listCities?.getListCities.list]);

  return (
    <DialogStyled open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent dividers={false} className="dialog__content">
        <div className="vector">
          <img src={cercles} alt="vector" className="img-vector" />
          <img src={address} alt="apparence" className="img-apparence" />
        </div>
        <Typography variant="h2" className="address__form-title">
          {intl.formatMessage({ id: "Store.settings.formAddAdress" })}
        </Typography>
        <Grid container component="form" className="form" spacing={2}>
          <Grid item xs={6}>
            <Input
              label={intl.formatMessage({
                id: "Store.settings.inputName",
              })}
              value={updateCompanySite?.identifier}
              onChange={(e) =>
                setUpdateCompanySite({
                  ...updateCompanySite,
                  identifier: e.target.value,
                })
              }
              placeholder={intl.formatMessage({
                id: "Store.settings.inputName",
              })}
              error={Boolean(errors?.identifier)}
              msgError={
                errors.identifier &&
                intl.formatMessage({
                  id: errors.identifier,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label={intl.formatMessage({
                id: "Store.settings.inputAddress",
              })}
              value={updateCompanySite?.address?.roadNames?.[0]?.value}
              onChange={(e) =>
                setUpdateCompanySite({
                  ...updateCompanySite,
                  address: {
                    ...updateCompanySite.address,
                    roadNames: [
                      {
                        ...updateCompanySite.address?.roadNames?.[0],
                        languageCode: "EN",
                        value: e.target.value,
                      },
                    ],
                  },
                })
              }
              placeholder={intl.formatMessage({
                id: "Store.settings.inputAddress",
              })}
              error={Boolean(errors?.address)}
              msgError={
                errors.address &&
                intl.formatMessage({
                  id: errors.address,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disableClearable
              options={listCountries?.getListCountries?.list || []}
              getOptionLabel={(option) => option.name || ""}
              value={countryValue}
              onChange={(event, value) => {
                setUpdateCompanySite({
                  ...updateCompanySite,
                  address: {
                    ...updateCompanySite.address,
                    country: value.id,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  variant="filled"
                  fullWidth
                  InputProps={{ ...params.InputProps, type: "search" }}
                  placeholder={intl.formatMessage({
                    id: "Store.settings.inputCountry",
                  })}
                  error={Boolean(errors?.country)}
                  helperText={
                    errors?.country &&
                    intl.formatMessage({
                      id: errors?.country,
                    })
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disableClearable
              options={listCities?.getListCities?.list || []}
              getOptionLabel={(option) => option.name || ""}
              defaultValue={cityValue}
              onChange={(event, value) => {
                setUpdateCompanySite({
                  ...updateCompanySite,
                  address: {
                    ...updateCompanySite.address,
                    city: value.id,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "new-password",
                  }}
                  placeholder={intl.formatMessage({
                    id: "Store.settings.inputCity",
                  })}
                  error={Boolean(errors?.city)}
                  helperText={
                    errors?.city &&
                    intl.formatMessage({
                      id: errors?.city,
                    })
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={handleClose}>
          {intl.formatMessage({ id: "Modal.cancel" })}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: "Store.settings.save" })}
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

export default AddressUpdateModal;
