import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DialogStyled = styled(Dialog)`
  .dialog__labels {
    font-size: 16px;
    font-weight: bold;
  }
`;
