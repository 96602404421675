import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 165px);
  justify-content: center;
  align-items: center;
  .box-empty {
    display: flex;
    width: 490px;
    height: 520px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    a {
      text-decoration: none;
    }
    .vector {
      position: relative;
      display: flex;
      width: 220px;
      height: 220px;
      justify-content: center;
      align-items: center;
      .img-vector {
        position: absolute;
        width: 220px;
        z-index: 9;
      }
      .img-apparence {
        width: 80px;
        position: absolute;
        z-index: 99;
      }
      .btn-fab-add {
        position: absolute;
        top: 190px;
        left: 82px;
        z-index: 999;
      }
    }
    p {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 37px;
      margin: 0 40px;
    }

    .btn-description {
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
