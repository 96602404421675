import gql from "graphql-tag";

// get menu theme
export const GETMENUTHEME = gql`
  query GetMenuTheme($input: GetMenuthemeInput!) {
    getMenTheme(input: $input) {
      menuTheme {
        id
        menu
        backgroundStyle {
          kind
          value
        }
        fontStyles {
          kind
          value
        }
        colorPalette {
          kind
          value
        }
        titleBackground {
          kind
          value
        }
      }
    }
  }
`;

export const GETLISTMENUTHEMEPICTURES = gql`
  query GetListMenuThemePictues($input: GetListMenuThemePicturesInput!) {
    getListMenuThemePictures(input: $input) {
      list
    }
  }
`;

export const UPDATEMENUTHEME = gql`
  mutation UpdateMenuTheme($input: UpdateMenuThemeInput!) {
    updateMenuTheme(input: $input) {
      success
    }
  }
`;

export const GETLISTTHEMEFONTS = gql`
  query GetListThemeFonts($input: GetListThemeFontsInput!) {
    getListThemeFonts(input: $input) {
      list {
        id
        value
      }
    }
  }
`;

export const GETLISTCOMPANYTHEMEPICTURE = gql`
  query GetListCompanyThemePictures($input: GetListCompanyThemePicturesInput!) {
    getListCompanyThemePictures(input: $input) {
      list
    }
  }
`;
