import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  .option {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const Wrapper = styled.div<{ checked: boolean }>`
  display: flex;
  flex-direction: column;
  .container {
    display: grid;
    grid-template-columns: 460px minmax(480px, 1fr);
    gap: 15px;
    .box-1 {
      flex-direction: column;
      height: calc(100vh - 130px);
      padding: 15px;
      .header-filter {
        display: flex;
        flex-direction: column;
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          & > button {
            & > span {
              font-size: 14px;
              color: #1f90cf;
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
        .show-filter {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          background: #fcfcfc;
          margin-bottom: 15px;
          .MuiFormControlLabel-root {
            height: 30px;
            margin-left: 0;
            .MuiTypography-body1 {
              font-size: 14px;
            }
          }
        }
      }
      .list-products {
        display: flex;
        flex-direction: column;
        height: calc(100% - 203px);
        overflow-y: auto;
        margin-top: 15px;
      }
      .list-products-filter {
        display: flex;
        flex-direction: column;
        height: calc(100% - 308px);
        overflow-y: auto;
        margin-top: 15px;
      }
    }
    .box-2 {
      height: calc(100vh - 130px);
      padding: 15px;
      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .MuiButton-label {
          display: block;
          font-size: 14px;
          text-transform: lowercase;
          ::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
      }
      .MuiTableContainer-root {
        height: ${(props) =>
          props.checked ? "calc(100vh - 377px)" : "calc(100vh - 300px)"};
        overflow-y: auto;
      }
      .footer-pagination {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
      .product-list-details-empty {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        align-items: center;
        justify-content: center;
        p {
          font-size: 18px;
        }
      }
    }
  }
`;

export const SpanStatus = styled.span<{ status: string }>`
  font-size: 14px;
  color: ${(props) =>
    props.status === "IN"
      ? "#4CD964"
      : props.status === "OUT"
      ? "#F67070"
      : "#444444"};
`;
