// libraries imports
import React, { FC } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// local imports
import { SignUpValidation } from "./SignUpValidation";
import { Wrapper } from "./styles";
import logo from "../../img/digishop/digishop-logo-LightMode.png";
import { Box, Input } from "../../commons";
import { GET_LANGUAGE, snackBarVar } from "../../api/local-state";
import { REGISTER_MERCHANT } from "../../api/merchant.api";
import {
  RegisterMerchantMutation,
  RegisterMerchantMutationVariables,
} from "../../api/types";

export type SignUpInfo = {
  email: string;
  password: string;
  confirmPassword: string;
  domain: string;
};

const initialErrorState: SignUpInfo = {
  email: "",
  password: "",
  confirmPassword: "",
  domain: "",
};

const SignUp: FC = () => {
  const client = useApolloClient();
  const intl = useIntl();
  // ???????
  const kind = "5e1361ea1e45d7114720c186";
  const status = "5e14508c3c08430aa5158fa4";

  // states
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [domain, setDomaine] = React.useState<string>("");
  const [SignUpError, setSignUpError] =
    React.useState<SignUpInfo>(initialErrorState);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  // mutation
  const [registerMerchant, { loading }] = useMutation<
    RegisterMerchantMutation,
    RegisterMerchantMutationVariables
  >(REGISTER_MERCHANT, {
    variables: {
      input: { email, password, kind, domain, activationStatus: status },
    },
    onCompleted: (data) => {
      if (data.registerMerchant.id) {
        snackBarVar({
          open: true,
          severity: "success",
          message: intl.formatMessage({ id: "signup.registerComplete" }),
        });
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setDomaine("");
      }
    },
    onError: (error) => {
      if (error.message === "email_already_exists") {
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({ id: "signup.emailExist" }),
        });
      } else if (error.message === "domain_already_exists") {
        snackBarVar({
          open: true,
          severity: "error",
          message: intl.formatMessage({ id: "signup.domainExist" }),
        });
      } else if (error.message === "domain_is_not_alphabectic") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Domaine should be alphabetic",
        });
      } else if (error.message === "domain_contains_number") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Domaine should be alphabetic",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  // functions

  // const handleChangeLanguage = (languageCode: string) => {
  //   client.writeQuery({
  //     query: GET_LANGUAGE,
  //     data: { language: languageCode },
  //   });
  // };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validation = SignUpValidation({
      email,
      password,
      confirmPassword,
      domain,
    });

    if (Object.entries(validation).length === 0) {
      registerMerchant();
    } else {
      setSignUpError({ ...SignUpError, ...validation });
    }
  };

  return (
    <Wrapper>
      <Box p={24} width={550}>
        <img width="150" src={logo} alt="DigiPages" className="logo" />
        <h3>{intl.formatMessage({ id: "signup.title" })}</h3>
        <form onSubmit={() => {}}>
          <div className="form">
            <Input
              id="email"
              name="email"
              label={intl.formatMessage({ id: "signup.email" })}
              dataTestId="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setSignUpError({ ...SignUpError, [e.target.name]: "" });
              }}
              value={email}
              msgError={
                SignUpError.email &&
                intl.formatMessage({ id: SignUpError.email })
              }
            />
            <Input
              id="password"
              name="password"
              label={intl.formatMessage({ id: "signup.password" })}
              dataTestId="password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
                setSignUpError({ ...SignUpError, [e.target.name]: "" });
              }}
              value={password}
              msgError={
                SignUpError.password &&
                intl.formatMessage({ id: SignUpError.password })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Input
              id="confirm-password"
              name="confirmPassword"
              label={intl.formatMessage({ id: "signup.confirmPassword" })}
              dataTestId="confirm-password"
              type="password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setSignUpError({ ...SignUpError, [e.target.name]: "" });
              }}
              value={confirmPassword}
              msgError={
                SignUpError.confirmPassword &&
                intl.formatMessage({ id: SignUpError.confirmPassword })
              }
            />
            <Input
              id="domaine"
              name="domain"
              label={intl.formatMessage({ id: "signup.domaine" })}
              dataTestId="domaine"
              type="text"
              onChange={(e) => {
                setDomaine(e.target.value);
                setSignUpError({ ...SignUpError, [e.target.name]: "" });
              }}
              value={domain}
              msgError={
                SignUpError.domain &&
                intl.formatMessage({ id: SignUpError.domain })
              }
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "14px",
              }}
            />
            <div style={{ display: "flex" }}>
              <Button
                data-testid="submit"
                type="submit"
                style={{
                  margin: "0px auto 16px",
                  position: "relative",
                  minWidth: 130,
                }}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: "signup.signup" })}
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{ position: "absolute" }}
                  />
                )}
              </Button>
            </div>
          </div>
        </form>
      </Box>
      <div
        style={{
          display: "flex",
          width: 550,
          justifyContent: "flex-end",
          padding: 7,
        }}
      >
        {/* <div className="translate">
          <span
            role="button"
            tabIndex={0}
            onKeyUp={() => handleChangeLanguage("en")}
            onClick={() => handleChangeLanguage("en")}
            style={{ cursor: "pointer" }}
          >
            English
          </span>
          &nbsp;|&nbsp;
          <span
            role="button"
            tabIndex={0}
            onKeyUp={() => handleChangeLanguage("fr")}
            onClick={() => handleChangeLanguage("fr")}
            style={{ cursor: "pointer" }}
          >
            French
          </span>
          &nbsp;
        </div> */}
      </div>
    </Wrapper>
  );
};

export default SignUp;
