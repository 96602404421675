import { Errors } from "./GiftCardForm";

export const GiftCardFormValidation = (
  cardName?: string,
  cardValue?: number | null
): Errors => {
  const errors: Errors = {};
  if (!cardName) errors.cardName = "form.fieldRequired";
  if (!cardValue) errors.cardValue = "form.fieldRequired";
  return errors;
};
