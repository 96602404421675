import gql from "graphql-tag";

export const COMPANY_CONFIG = gql`
  query GetCompanyConfig($domain: String!) {
    getCompanyConfig(input: { domain: $domain }) {
      logoUrl
      colors {
        primary
        secondary
        footer
        header
      }
    }
  }
`;
