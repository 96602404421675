/* eslint-disable  no-nested-ternary */
import React, { FC } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import { DialogActions, Button, Checkbox } from "@material-ui/core";
import findIndex from "lodash/findIndex";

import { TablePagination } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

// API Imports
import {
  GET_LIST_MENU_PRODUCTS,
  ASSIGNCATEGORYPRODUCT,
  GET_LIST_CATEGORY_PRODUCTS,
} from "../../../api";
import {
  GetListMenuProductsQuery,
  GetListMenuProductsQueryVariables,
  AssignCategoryProductsMutation,
  AssignCategoryProductsMutationVariables,
  CategoryProductIdInput,
} from "../../../api/types";
import { SelectedMenuVar } from "../../../api/local-state";

// Types
type ModalProductListProps = {
  handleClose: () => void;
  category: string;
  search: string;
};

const ModalProductList: FC<ModalProductListProps> = ({
  category,
  handleClose,
  search,
}) => {
  // Local variables
  const token = localStorage.getItem("token") || "";
  const menu = SelectedMenuVar();

  // States
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [products, setProducts] = React.useState<CategoryProductIdInput[]>([]);

  // Queries & Mutaions
  const { data } = useQuery<
    GetListMenuProductsQuery,
    GetListMenuProductsQueryVariables
  >(GET_LIST_MENU_PRODUCTS, {
    variables: {
      input: {
        token,
        menu: (menu && menu?.id) || "",
        name: search,
        currentCategory: category,
      },
    },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getListMenuProducts }) => {
      const arrayProducts = [...products];
      getListMenuProducts.list.forEach((product) => {
        if (product.currentCategory?.id === category) {
          if (!arrayProducts.find((o) => o.product === product.id))
            arrayProducts.push({ product: product.id || "" });
        }
      });
      setProducts(arrayProducts);
    },
  });

  const [assignProducts] = useMutation<
    AssignCategoryProductsMutation,
    AssignCategoryProductsMutationVariables
  >(ASSIGNCATEGORYPRODUCT, {
    variables: { input: { token, category, products } },
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: () => [
      {
        query: GET_LIST_CATEGORY_PRODUCTS,
        variables: { input: { token, category } },
      },
    ],
  });

  const handleProduct = (checked: boolean, id: string) => {
    const selectedProducts = Object.assign([], products);
    if (checked) {
      selectedProducts.push({ product: id });
    } else {
      selectedProducts.splice(selectedProducts.indexOf({ product: id }), 1);
    }
    setProducts(selectedProducts);
  };

  // Local Data
  const productsList = data?.getListMenuProducts.list;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Value",
      accessor: "value",
    },
    {
      header: "Category",
      accessor: "category",
    },
  ];

  const renderTableRows = () => {
    return (
      productsList
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((product) => ({
          id: product?.id,
          name: (
            <>
              <Checkbox
                checked={
                  findIndex(products, (o) => o.product === product?.id) !== -1
                }
                onChange={(e) =>
                  handleProduct(e.target.checked, product.id || "")
                }
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              {product.names?.[0]?.value}
            </>
          ),
          value: (
            <>
              {product.price?.value} &nbsp;{product.price?.currency?.code}
            </>
          ),
          category: product?.currentCategory?.names?.[0]?.value,
        })) || []
    );
  };

  return (
    <Wrapper>
      <div className="content products">
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="List products is empty"
        />
      </div>
      <DialogActions style={{ padding: "16px" }}>
        <TablePagination
          labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
          count={productsList?.length || 0}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => assignProducts()}
          color="secondary"
          variant="contained"
          disableElevation
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Wrapper>
  );
};

// Types
const Wrapper = styled.div`
  padding: 0 8px;
  .content.products {
    height: 70vh;
    overflow-y: scroll;
  }

  .modal-header {
    display: flex;
    align-items: center;
  }
  .search {
    display: inline-grid;
    grid-template-columns: 1fr 35px;
    grid-gap: 5px;
    float: right;
    padding: 3px;
    border: 1px solid #ebebeb;
    border-radius: 7px;
  }
  input.search-input {
    width: 200px;
    padding-left: 10px;
    border: 0;
  }
  button.search-btn {
    width: 35px;
    height: 35px;
    border: 0;
    border-radius: 5px;
    background: #f29f05;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .MuiButton-label {
    display: block;
    font-size: 14px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export default ModalProductList;
