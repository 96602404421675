import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";

// eslint-disable-next-line
import { Box } from "../commons";

type CheckModalProps = {
  open: boolean;
  handleClose: () => void;
  toCheck: boolean;
  onPassMessage: string;
  onFailMessage: string;
};

const CheckModal: React.FC<CheckModalProps> = ({
  open,
  handleClose,
  toCheck,
  onPassMessage,
  onFailMessage,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Wrapper>
        <Box width={1} className="container">
          <div className="Icon">
            {toCheck ? (
              <CheckCircleOutlineIcon htmlColor="green" fontSize="large" />
            ) : (
              <ErrorOutlineIcon htmlColor="red" fontSize="small" />
            )}
          </div>
          <div className="message">
            <span>{toCheck ? onPassMessage : onFailMessage}</span>
          </div>
        </Box>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    .message {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

export default CheckModal;
