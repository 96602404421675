import gql from "graphql-tag";

export const GET_BEAMANDGO_LIST_CATEGORIES = gql`
  query getBeamandgoListCategories($input: GetBeamAndGoListCategoriesInput!) {
    getBeamandgoListCategories(input: $input) {
      list
    }
  }
`;

export const GET_BEAMANDGO_LIST_PRODUCTS = gql`
  query GetBeamandgoListProducts($input: GetBeamAndGoListProductsInput!) {
    getBeamandgoListProducts(input: $input) {
      list {
        id
        identifier
        name
        description
        typeId
        unitPrice
        unitPriceCurrency
        imageUrl
        minQuantity
        maxQuantity
        stockQuantity
        isBranchSpecific
        isShippingRequired
        isBirthDateRequired
        accountSid
        shippingAndHandlingFee
      }
    }
  }
`;
