import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  RadioGroup,
  Radio,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DeleteIcon from "@material-ui/icons/Delete";
import { useReactiveVar, useMutation } from "@apollo/client";
import findIndex from "lodash/findIndex";
import find from "lodash/find";

import {
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Select,
} from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { SnoonuAddPackageModal } from "../../components";

import {
  URL_THEME_PICTURES,
  UPDATE_DELIVERY_SERVICE,
  GET_MENU_DELIVERY_SETTINGS,
} from "../../api";
import {
  DeliveryServicePayload,
  UpdateDeliveryServiceMutation,
  UpdateDeliveryServiceMutationVariables,
  MenuPayload,
  UpdateDeliveryMethodInput,
  UpdateDeliveryPackageInput,
  DeliveryPackageInput,
  DeliveryPackageKindInput,
  DeliveryPackageSize,
  AddPackageInput,
  DeliveryKind,
  CustomDeliveryInput,
  CustomDeliveryKind,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { Wrapper } from "./EditDeliveryMethode--styles";

import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";
import DefaultLogpackage from "../../img/digishop/defaultLogpackage.svg";

type Picture = {
  id: string;
  url: string;
};

const EditDeliveryMethode = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const deliveryPayload = useLocation<DeliveryServicePayload>().state;

  const [open, setOpen] = React.useState(false);
  const [deliveryMethods, setDeliveryMethods] = React.useState<
    UpdateDeliveryMethodInput[]
  >([]);
  const [deliveryPackage, setDeliveryPackage] =
    React.useState<UpdateDeliveryPackageInput>();
  const [picturesPackage, setPicturesPackage] = React.useState<Picture[]>([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isEnabled, setIsEnabled] = React.useState<boolean>(false);
  const [kindDelivery, setKindDelivery] =
    React.useState<DeliveryKind>("SNOONU");
  const [nameDelivery, setNameDelivery] = React.useState<string | undefined>(
    undefined
  );
  const [customDelivery, setCustomDelivery] =
    React.useState<CustomDeliveryInput>();

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  React.useEffect(() => {
    const arrayAvailabelMethods: UpdateDeliveryMethodInput[] = [];
    const arrayPackages: DeliveryPackageInput[] = [];
    const arrayPictures: Picture[] = [];
    if (deliveryPayload) {
      deliveryPayload.deliveryMethods?.forEach((item) => {
        arrayAvailabelMethods.push({
          kind: item.kind,
          deliveryFees: item.deliveryFees,
          availableDays: item.availableDays,
          isActive: item.isActive,
        });
      });
      deliveryPayload?.deliveryPackage?.packages?.forEach((item) => {
        const arrayKind: DeliveryPackageKindInput[] = [];
        item.kinds?.forEach((kind) =>
          arrayKind.push({
            cost: kind.cost,
            size: kind?.size?.toUpperCase() as DeliveryPackageSize,
          })
        );
        arrayPackages.push({
          name: item.name,
          picture: item.picture?.id,
          kinds: arrayKind,
        });

        item.picture?.id &&
          arrayPictures.push({
            id: item.picture?.id,
            url: item.picture?.fileUrl || "",
          });
      });

      setDeliveryMethods(arrayAvailabelMethods);
      setDeliveryPackage({
        isActive: deliveryPayload.deliveryPackage?.isActive,
        packages: arrayPackages,
      });
      setPicturesPackage(arrayPictures);
      deliveryPayload?.isEnabled && setIsEnabled(deliveryPayload?.isEnabled);
      deliveryPayload?.kind &&
        setKindDelivery(deliveryPayload?.kind as DeliveryKind);
      deliveryPayload?.name && setNameDelivery(deliveryPayload?.name);
      setCustomDelivery({
        kind: deliveryPayload?.customDelivery?.kind as CustomDeliveryKind,
        cost: deliveryPayload?.customDelivery?.cost,
        limit: deliveryPayload?.customDelivery?.limit,
      });
    }
  }, [deliveryPayload]);

  const columns: ColumnsProps = [
    {
      header: "Picture",
      accessor: "picture",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Size",
      accessor: "size",
    },
    {
      header: "Cost",
      accessor: "cost",
    },
    {
      header: "Action",
      accessor: "action",
    },
  ];

  const renderTableRows = () => {
    return (
      deliveryPackage?.packages
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item, index) => ({
          id: index,
          picture: item.picture ? (
            <img
              src={
                `${URL_THEME_PICTURES}${
                  find(picturesPackage, (o) => o.id === item.picture)?.url
                }` || ""
              }
              className="product__image"
              alt="img-package"
            />
          ) : (
            <img
              src={DefaultLogpackage}
              className="product__image"
              alt="img-package"
            />
          ),
          name: item.name,
          size: item.kinds?.[0]?.size,
          cost: item.kinds?.[0]?.cost,
          action: (
            <IconButton
              size="small"
              onClick={() => handleDeleteFromPackaging(index)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          ),
        })) || []
    );
  };

  const handleDeleteFromPackaging = (index: number) => {
    const arrayPackages = [...(deliveryPackage?.packages || [])];
    arrayPackages.splice(index, 1);
    setDeliveryPackage({
      ...deliveryPackage,
      packages: arrayPackages,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [updateDelivery, { loading }] = useMutation<
    UpdateDeliveryServiceMutation,
    UpdateDeliveryServiceMutationVariables
  >(UPDATE_DELIVERY_SERVICE, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Operation successful",
      });
      history.push("/delivery");
    },
    refetchQueries: () => [
      {
        query: GET_MENU_DELIVERY_SETTINGS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
  });

  const handleActiveDeliveryExpress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setDeliveryMethods((prevDeliveryMethods) => {
      const arrayDeliveryMethods = [...prevDeliveryMethods];
      const index = findIndex(
        arrayDeliveryMethods,
        (o) => o.kind === "EXPRESS"
      );
      if (index !== -1) {
        arrayDeliveryMethods[index] = {
          ...arrayDeliveryMethods[index],
          isActive: event.target.checked,
        };
      }
      return arrayDeliveryMethods;
    });
  };

  const handleDeliveryFeels =
    (kind: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const { name, value } = event.target;
      setDeliveryMethods((prevDeliveryMethods) => {
        const arrayDeliveryMethods = [...prevDeliveryMethods];
        const index = findIndex(arrayDeliveryMethods, (o) => o.kind === kind);
        if (index !== -1) {
          arrayDeliveryMethods[index] = {
            ...arrayDeliveryMethods[index],
            deliveryFees: {
              ...arrayDeliveryMethods[index].deliveryFees,
              [name]: parseFloat(value) >= 0 && parseFloat(value),
            },
          };
        }
        return arrayDeliveryMethods;
      });
    };

  const handleActiveDeliveryPackage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setDeliveryPackage({
      ...deliveryPackage,
      isActive: event.target.checked,
    });
  };

  const handleDeliveryPackage = (
    packageInput: AddPackageInput,
    url?: Picture
  ) => {
    const arrayPackages = [...(deliveryPackage?.packages || [])];

    arrayPackages?.push({
      name: packageInput.name,
      picture: packageInput.picture,
      kinds: packageInput.kinds as DeliveryPackageKindInput[],
    });
    setDeliveryPackage({
      ...deliveryPackage,
      packages: arrayPackages,
    });
    setPicturesPackage((prevPicturesPackage) => {
      const arrayUrls = [...prevPicturesPackage];
      url && arrayUrls.push(url);
      return arrayUrls;
    });
  };

  const handleSubmit = () => {
    updateDelivery({
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          id: deliveryPayload.id || "",
          name: nameDelivery,
          description: deliveryPayload.description,
          customDelivery,
          deliveryMethods,
          deliveryPackage,
          isEnabled,
        },
      },
    });
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "Edit delivery method"]}
        icon={StoreSettings}
        iconAlt="translate"
      />
      <ButtonCustomize className="back" onClick={() => history.goBack()}>
        <KeyboardBackspaceIcon /> Return
      </ButtonCustomize>
      <Box
        height="calc(100vh - 200px)"
        component={Paper}
        marginTop={2}
        style={{
          overflowX: "auto",
          boxSizing: "border-box",
          padding: "4px 16px 16px 16px",
        }}
      >
        <Box
          display="flex"
          width={1}
          alignItems="center"
          justifyContent="space-between"
          marginBottom={-2}
        >
          <Typography variant="h3" className="bold">
            Delivery Settings
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                name="acivate"
                color="secondary"
                onChange={(e) => setIsEnabled(e.target.checked)}
              />
            }
            label="Activate Delivery method"
            className="btn-switch"
            labelPlacement="start"
          />
        </Box>
        {kindDelivery === "SNOONU" && (
          <Box marginBottom={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    find(deliveryMethods, (o) => o.kind === "EXPRESS")
                      ?.isActive || false
                  }
                  name="acivate"
                  color="secondary"
                  onChange={handleActiveDeliveryExpress}
                />
              }
              label="Activate Snoonu express"
              className="btn-switch"
            />
            <Typography variant="h3" className="bold">
              Delivery services fees
            </Typography>
            <Typography
              variant="subtitle1"
              className="bold"
              style={{ margin: "8px 0 4px 0" }}
            >
              Snoonu fees
            </Typography>
            <div className="row">
              <Typography variant="subtitle2"> Range (km) </Typography>
              <Typography variant="subtitle2"> Fees per order </Typography>
            </div>
            <div className="row">
              <Typography variant="subtitle1"> 0 km - 20 km </Typography>
              <Input
                name="min"
                label={localMenu?.currency?.code || ""}
                value={
                  find(deliveryMethods, (o) => o.kind === "STANDARD")
                    ?.deliveryFees?.min || ""
                }
                type="number"
                onChange={handleDeliveryFeels("STANDARD")}
              />
            </div>
            <div className="row">
              <Typography variant="subtitle1"> 20 km and more </Typography>
              <Input
                name="max"
                label={localMenu?.currency?.code || ""}
                value={
                  find(deliveryMethods, (o) => o.kind === "STANDARD")
                    ?.deliveryFees?.max || ""
                }
                type="number"
                onChange={handleDeliveryFeels("STANDARD")}
              />
            </div>
            {find(deliveryMethods, (o) => o.kind === "EXPRESS")?.isActive && (
              <Box>
                <Typography
                  variant="subtitle1"
                  className="bold"
                  style={{ margin: "15px 0 5px 0" }}
                >
                  Snoonu express fees
                </Typography>
                <div className="row">
                  <Typography variant="subtitle2"> Range (km) </Typography>
                  <Typography variant="subtitle2"> Fees per order </Typography>
                </div>
                <div className="row">
                  <Typography variant="subtitle1"> 0 km - 20 km </Typography>
                  <Input
                    name="min"
                    label={localMenu?.currency?.code || ""}
                    value={
                      find(deliveryMethods, (o) => o.kind === "EXPRESS")
                        ?.deliveryFees?.min || ""
                    }
                    type="number"
                    onChange={handleDeliveryFeels("EXPRESS")}
                  />
                </div>
                <div className="row">
                  <Typography variant="subtitle1"> 20 km and more </Typography>
                  <Input
                    name="max"
                    label={localMenu?.currency?.code || ""}
                    value={
                      find(deliveryMethods, (o) => o.kind === "EXPRESS")
                        ?.deliveryFees?.max || ""
                    }
                    type="number"
                    onChange={handleDeliveryFeels("EXPRESS")}
                  />
                </div>
              </Box>
            )}
          </Box>
        )}
        {kindDelivery === "CUSTOM" && (
          <Box display="flex" flexDirection="column" marginTop={2}>
            <Typography
              variant="h3"
              style={{ fontWeight: 700, marginBottom: 10 }}
            >
              Delivery name at checkout
            </Typography>
            <Input
              label="Set the name which customers will see at checkout"
              value={nameDelivery}
              onChange={(e) => setNameDelivery(e.target.value)}
              style={{ maxWidth: 500 }}
            />
            <Typography
              variant="h3"
              style={{ fontWeight: 700, margin: "15px 0 10px 0" }}
            >
              Set delivery fees
            </Typography>
            <RadioGroup
              value={customDelivery?.kind}
              onChange={(e) =>
                setCustomDelivery({
                  ...customDelivery,
                  kind: e.target.value as CustomDeliveryKind,
                  cost: undefined,
                  limit: undefined,
                })
              }
              aria-label="fees"
              name="customized-radios"
            >
              <FormControlLabel
                value="FREE"
                control={<Radio />}
                label="Free delivery"
              />
              <FormControlLabel
                value={
                  customDelivery?.kind === "PAYING_AMOUNT"
                    ? "PAYING_AMOUNT"
                    : "PAYING_PERCENT"
                }
                control={<Radio />}
                label="Fixed fee"
              />
              {(customDelivery?.kind === "PAYING_AMOUNT" ||
                customDelivery?.kind === "PAYING_PERCENT") && (
                <Box display="flex" width={500} className="paying-amount">
                  <Select
                    value={customDelivery.kind}
                    onChange={(e) =>
                      setCustomDelivery({
                        ...customDelivery,
                        kind: e.target.value as CustomDeliveryKind,
                      })
                    }
                    style={{ marginRight: 15 }}
                  >
                    <MenuItem value="PAYING_AMOUNT">Amount</MenuItem>
                    <MenuItem value="PAYING_PERCENT">Percent</MenuItem>
                  </Select>
                  <Input
                    type="number"
                    value={customDelivery.cost}
                    onChange={(e) =>
                      setCustomDelivery({
                        ...customDelivery,
                        cost: parseFloat(e.target.value),
                        limit: 0,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {customDelivery.kind === "PAYING_PERCENT"
                          ? "%"
                          : localMenu?.currency?.code}
                      </InputAdornment>
                    }
                  />
                </Box>
              )}
              <FormControlLabel
                value="FREE_FROM"
                control={<Radio />}
                label="Conditinal fee"
              />
              {customDelivery?.kind === "FREE_FROM" && (
                <Box
                  display="flex"
                  width={500}
                  alignItems="center"
                  marginBottom={1}
                >
                  <Input
                    label="From order cost of"
                    type="number"
                    value={customDelivery.limit}
                    onChange={(e) =>
                      setCustomDelivery({
                        ...customDelivery,
                        limit: parseFloat(e.target.value),
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {localMenu?.currency?.code}
                      </InputAdornment>
                    }
                  />
                  <Typography> Else </Typography>
                  <Input
                    label="Set delivery fee"
                    type="number"
                    value={customDelivery.cost}
                    onChange={(e) =>
                      setCustomDelivery({
                        ...customDelivery,
                        cost: parseFloat(e.target.value),
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {localMenu?.currency?.code}
                      </InputAdornment>
                    }
                  />
                </Box>
              )}
            </RadioGroup>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Switch
            checked={deliveryPackage?.isActive || false}
            onChange={handleActiveDeliveryPackage}
          />
          <Typography variant="h3" className="bold">
            Gift packaging
          </Typography>
        </Box>
        <Typography variant="subtitle2" className="description">
          Turn this on to set the package and gift wrap variants that you
          providefor this delivery and rates system, packages will contain their
          own cost and picture that will be displayed in the store to let
          customers choose at their liking, keeping this option disabled
          customers will not have the option to choose packages options.
        </Typography>
        {deliveryPackage?.isActive && (
          <Box marginTop={2}>
            <Typography variant="h3" className="bold">
              Packages
            </Typography>
            <Box width={800} marginTop={3}>
              <Table
                columns={columns}
                data={renderTableRows()}
                emptyMessage="You have no packages. add one"
                tablePaginationProps={{
                  labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
                  count: deliveryPackage?.packages?.length || 0,
                  onPageChange: (_, newPage) => setPage(newPage),
                  page,
                  rowsPerPage,
                  onChangeRowsPerPage: handleChangeRowsPerPage,
                }}
              />
            </Box>
            <ButtonCustomize
              variant="contained"
              className="btn-add"
              onClick={() => setOpen(true)}
            >
              Add package
            </ButtonCustomize>
            <SnoonuAddPackageModal
              open={open}
              onClose={() => setOpen(false)}
              onReturn={(data, url) => handleDeliveryPackage(data, url)}
            />
          </Box>
        )}
        <ButtonCustomize
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          className="btn-save"
          disabled={loading}
        >
          Save delivery method
        </ButtonCustomize>
      </Box>
    </Wrapper>
  );
};

export default EditDeliveryMethode;
