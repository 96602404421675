import gql from "graphql-tag";

export const CREATEFILTERCOLLECTION = gql`
  mutation createFilterCollection($input: CreateFilterCollectionInput!) {
    createFilterCollection(input: $input) {
      id
    }
  }
`;

export const GETMENUPRODUCTOPTIONS = gql`
  query getMenuProductOptions($menu: String!, $category: String) {
    getMenuProductOptions(input: { menu: $menu, category: $category }) {
      options {
        kind
        values
      }
    }
  }
`;

export const GETLISTFILTERCOLLECTIONS = gql`
  query getListFilterCollections($token: String!, $menu: String!) {
    getListFilterCollections(input: { token: $token, menu: $menu }) {
      list {
        id
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        categories {
          category
        }
        products {
          product
        }
        priceRange {
          min
          max
        }
      }
    }
  }
`;

export const GETFILTERCOLLECTIONSPREVIEW = gql`
  query getFilterCollectionPreview($input: GetFilterCollectionPreviewInput!) {
    getFilterCollectionPreview(input: $input) {
      filterList {
        id
        identifier
        names {
          languageCode
          value
        }
        picture {
          id
          fileUrl
        }
      }
    }
  }
`;
