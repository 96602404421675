import React from "react";
import { useHistory } from "react-router";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Box, Input, LoadingLogin } from "../../commons";
import { GET_LANGUAGE } from "../../api/local-state";
import { SIGNIN, HOST_RAWI, HOST_CSHOP, HOST_FORSA } from "../../api";
import { SignInMutation, SignInMutationVariables } from "../../api/types";
import { loginValidation } from "./loginValidation";
import {
  userNotFound,
  invalidPasswordGraphql,
  microserviceUnvailable,
} from "../../utils";

import digishopLogo from "../../img/digishop/digishop-logo-LightMode.png";
import rawiLogo from "../../img/Rawi/rawiLogoBlue.svg";
import cwalletLogoBlack from "../../img/cwallet/cwalletLogoBlack.svg";
import forusLogo from "../../img/forus/forus-logo.svg";

export type LoginInfo = {
  email: string;
  password: string;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialState: LoginInfo = {
  password: "",
  email: "",
};

const { host } = window.location;

const Login: React.FC = () => {
  const history = useHistory();
  const client = useApolloClient();

  // const [rememberMe, setRememberMe] = React.useState(true);
  const [loginInfo, setLoginInfo] = React.useState<LoginInfo>(initialState);
  const [loginError, setLoginError] = React.useState<LoginInfo>(initialState);
  const [open, setOpen] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState("");
  const [logo, setLogo] = React.useState(digishopLogo);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const intl = useIntl();

  const handleChangeLanguage = (languageCode: string) => {
    client.writeQuery({
      query: GET_LANGUAGE,
      data: { language: languageCode },
    });
  };

  React.useEffect(() => {
    if (host === HOST_RAWI) {
      setLogo(rawiLogo);
    }
    if (host === HOST_CSHOP) {
      setLogo(cwalletLogoBlack);
    }
    if (host === HOST_FORSA) {
      setLogo(forusLogo);
    }
  }, [host]);

  // Mutation signIn
  const [signInMutation, { loading }] = useMutation<
    SignInMutation,
    SignInMutationVariables
  >(SIGNIN, {
    onCompleted: ({ signIn }) => {
      if (
        signIn.kind === "Manager" ||
        signIn.kind === "Staff" ||
        signIn.kind === "Vendor"
      ) {
        localStorage.setItem("token", signIn.token);
        localStorage.setItem("kind", signIn.kind);
        localStorage.setItem("hasMultiVendor", String(signIn.hasMultiVendor));
        if (host === HOST_CSHOP || host === HOST_RAWI) {
          history.push("/product-type");
        } else {
          history.push("/product");
        }
      } else {
        setErrorMessages("Compte invalid");
        setOpen(true);
      }
    },
    onError: (error) => {
      if (
        error?.graphQLErrors?.[0]?.message?.replace(/["]/g, "") ===
        "user_not_found"
      ) {
        setErrorMessages(userNotFound);
      } else if (
        error?.graphQLErrors?.[0]?.message?.replace(/["]/g, "") ===
        "invalid_password"
      ) {
        setErrorMessages(invalidPasswordGraphql);
      } else setErrorMessages(microserviceUnvailable);

      setOpen(true);
    },
    variables: {
      login: loginInfo.email,
      password: loginInfo.password,
    },
  });

  const handleLoginInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginInfo({ ...loginInfo, [event.target.name]: event.target.value });
    setLoginError({ ...loginError, [event.target.name]: "" });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validation = loginValidation(loginInfo);

    if (Object.entries(validation).length === 0) {
      signInMutation().then(() => {
        // change version apollo
        // response &&
        //   !response.errors &&
        // client.writeData({
        //   data: {
        //     step: response.data?.signIn?.missingStep,
        //     email: loginInfo.email,
        //   },
        // });
      });
    } else {
      setLoginError({ ...loginError, ...validation });
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Wrapper>
      {
        /* loadingConfig */ false ? (
          <LoadingLogin />
        ) : (
          <Box p={24} width={400}>
            <img width="150" src={logo} alt="DigiPages" className="logo" />

            <form onSubmit={handleSubmit}>
              <Input
                id="email"
                name="email"
                label={intl.formatMessage({ id: "login.email" })}
                value={loginInfo.email}
                onChange={handleLoginInfo}
                msgError={
                  loginError.email &&
                  intl.formatMessage({ id: loginError.email })
                }
                dataTestId="email"
              />
              <div style={{ width: "100%", height: "20px" }} />
              <Input
                id="password"
                name="password"
                label={intl.formatMessage({ id: "login.password" })}
                value={loginInfo.password}
                onChange={handleLoginInfo}
                msgError={
                  loginError.password &&
                  intl.formatMessage({ id: loginError.password })
                }
                dataTestId="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* <span>
               <Checkbox
                data-testid="remember"
                checked={rememberMe}
                color="primary"
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <label style={{ fontWeight: 500, fontSize: "13px" }}>
                {intl.formatMessage({ id: "login.remember" })}
              </label> 
            </span> */}
                {/* eslint-disable-next-line */}
                <Link
                  style={{
                    cursor: "pointer",
                    color: "#C4C4C4",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    history.push("/reset-password");
                  }}
                >
                  {intl.formatMessage({ id: "login.forgetPassword" })}
                </Link>
                {host !== HOST_CSHOP && host !== HOST_RAWI && (
                  <>
                    {/* eslint-disable-next-line */}
                    <Link
                      style={{
                        cursor: "pointer",
                        color: "#C4C4C4",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        history.push("/signup");
                      }}
                    >
                      {intl.formatMessage({ id: "signup.signup" })}
                    </Link>
                  </>
                )}
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  data-testid="submit"
                  type="submit"
                  style={{
                    color: "#FFFFFF",
                    margin: "30px auto 16px",
                    position: "relative",
                    minWidth: 130,
                  }}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={loading}
                >
                  {intl.formatMessage({ id: "login.login" })}
                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{ position: "absolute" }}
                    />
                  )}
                </Button>
              </div>
            </form>
          </Box>
        )
      }
      {/* <div
        style={{
          display: "flex",
          width: 400,
          justifyContent: "flex-end",
          padding: 7,
        }}
      >
         <div className="translate">
          <span
            role="button"
            tabIndex={0}
            onKeyUp={() => handleChangeLanguage("en")}
            onClick={() => handleChangeLanguage("en")}
            style={{ cursor: "pointer" }}
          >
            English
          </span>
          &nbsp;|&nbsp;
          <span
            role="button"
            tabIndex={0}
            onClick={() => handleChangeLanguage("fr")}
            onKeyUp={() => handleChangeLanguage("fr")}
            style={{ cursor: "pointer" }}
          >
            French
          </span>
          &nbsp;
        </div> 
      </div> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessages}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > div {
    display: grid;
  }
  img.logo {
    margin: 40px auto;
  }
  .translate {
    color: #757575;
    padding: 0;
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    /* margin-top: 5px; */
  }
`;

export default Login;
