import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .back {
    color: #0052b4;
    width: 100px;
    svg {
      margin-right: 5px;
    }
  }
  .bold {
    font-weight: 700;
  }
  .MuiLinearProgress-colorSecondary {
    background-color: #edeff2;
  }
  .details-stepper {
    font-weight: 700;
    color: #f29f05;
  }
  .card {
    border: 1px solid #edeff2;
    border-radius: 5px;
    padding: 16px;
  }
`;
