import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Chip, FormControl, Select, Box, Paper } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation } from "@apollo/client";

import { Wrapper } from "./GiftCardForm--styles";
import { GiftCardFormValidation } from "./GiftCardForm--utils";
import {
  BreadCrumbsTitle,
  Input as TextInput,
  ButtonCustomize,
} from "../../commons";

import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";
import { GET_LIST_CUSTOMERS } from "../../api/customer.api";
import { GETLISTGROUP } from "../../api/customerGroup.api";
import { CREATE_GIFT_CARD, GET_GIFT_CARDS } from "../../api/giftCard.api";
import {
  GetListCustomersQuery,
  GetListCustomersQueryVariables,
  GetListMenuGroupsQuery,
  GetListMenuGroupsQueryVariables,
  CreateGiftCardMutation,
  CreateGiftCardMutationVariables,
  PriceInput,
} from "../../api/types";

import { GiftCard, CreateGiftCard } from "../../img";

export type Errors = {
  cardName?: string;
  cardValue?: string;
};

const GiftCardForm: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const token = localStorage.getItem("token") || "";

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);
  const menu = localMenu?.menuItem;

  const currency = menu?.currency?.id || "";
  const [cardName, setCardName] = React.useState<string>("");
  const [cardValue, setCardValue] = React.useState<PriceInput>({
    value: 0,
    currency,
  });
  const [cardValueInput, setCardValueInput] = React.useState("");
  const [customersList, setCustomersList] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<Errors>({});
  const [groupList, setGroupList] = React.useState<string[]>([]);

  // queries
  const { data: listClients, loading } = useQuery<
    GetListCustomersQuery,
    GetListCustomersQueryVariables
  >(GET_LIST_CUSTOMERS, {
    variables: {
      input: {
        token,
        menu: menu?.id || "",
      },
    },
    fetchPolicy: "network-only",
  });
  const customers = listClients?.getListCustomers.list;

  const { data: listGroups, loading: LaodingGroups } = useQuery<
    GetListMenuGroupsQuery,
    GetListMenuGroupsQueryVariables
  >(GETLISTGROUP, {
    variables: {
      input: {
        token,
        menu: menu?.id || "",
      },
    },
    fetchPolicy: "network-only",
  });
  const groups = listGroups?.getListMenuGroups.list;

  const getCustomers = () => {
    return customersList.map((customerId) => ({ customer: customerId }));
  };

  const getGroups = () => {
    return groupList.map((groupId) => ({ group: groupId }));
  };

  // mutations
  const [createGiftCard] = useMutation<
    CreateGiftCardMutation,
    CreateGiftCardMutationVariables
  >(CREATE_GIFT_CARD, {
    variables: {
      input: {
        token,
        menu: menu?.id || "",
        names: [{ languageCode: "FR", value: cardName }],
        price: cardValue,
        descriptions: [{ languageCode: "FR", value: "" }],
        customers: getCustomers(),
        groups: getGroups(),
      },
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: intl.formatMessage({ id: "giftCard.success" }),
      });
      history.push("gift-cards");
    },
    refetchQueries: () => [
      {
        query: GET_GIFT_CARDS,
        variables: { input: { menu: menu?.id || "", token } },
      },
    ],
  });

  // function
  const selectCustomer = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCustomersList([...customersList, event.target.value as string]);
  };

  const handleDeleteCustomer = (id: string) => {
    const customersTemp = customersList;
    const idIndex = customersTemp.indexOf(id);
    customersTemp.splice(idIndex, 1);
    setCustomersList([...customersTemp]);
  };

  const selectGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGroupList([...groupList, event.target.value as string]);
  };

  const handleDeleteGroup = (id: string) => {
    const groupTemp = groupList;
    const idIndex = groupTemp.indexOf(id);
    groupTemp.splice(idIndex, 1);
    setGroupList([...groupTemp]);
  };

  const handleSubmit = () => {
    const errorsValidation = GiftCardFormValidation(cardName, cardValue?.value);
    if (Object.keys(errorsValidation).length) {
      setErrors(errorsValidation);
    } else {
      createGiftCard();
    }
  };
  // local variables

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <BreadCrumbsTitle
          labelIntl={["mainLayout.giftCards", "Add Gift Cards"]}
          icon={GiftCard}
          iconAlt="Customers"
        />

        <ButtonCustomize
          data-testid="save-button"
          variant="contained"
          color="primary"
          disableElevation
          className="btn-save"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faSave} color="#ffffff" />
          <span style={{ marginLeft: "15px" }}>
            {intl.formatMessage({ id: "Account.save" })}
          </span>
        </ButtonCustomize>
      </Box>
      <Box
        component={Paper}
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={2}
        width={1}
      >
        <div className="header">
          <CreateGiftCard />
          <label>{intl.formatMessage({ id: "giftCard.create" })}</label>
        </div>
        <div className="giftCard-form">
          <div className="gift-card_client-list">
            <TextInput
              id="giftCardName"
              name="Gift_Card_Name"
              label={intl.formatMessage({
                id: "giftCard.cardName",
              })}
              value={cardName}
              onChange={(e) => {
                setCardName(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  cardName: "",
                }));
              }}
              dataTestId="GiftCardName"
              error={Boolean(errors.cardName)}
              msgError={
                errors.cardName &&
                intl.formatMessage({
                  id: errors.cardName,
                })
              }
            />
            <TextInput
              id="cardValue"
              name="Value"
              label={`${intl.formatMessage({
                id: "giftCard.cardValue",
              })} (${menu?.currency?.code})`}
              value={cardValueInput}
              onChange={(e) => {
                setCardValueInput(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  cardValue: "",
                }));
              }}
              onBlur={(e) => {
                let value = parseFloat(e.target.value);
                if (Number.isNaN(value)) value = 0;
                setCardValueInput(value.toString());
                setCardValue({
                  ...cardValue,
                  value: parseFloat(e.target.value),
                });
              }}
              dataTestId="cardValue"
              error={Boolean(errors.cardValue)}
              msgError={
                errors.cardValue &&
                intl.formatMessage({
                  id: errors.cardValue,
                })
              }
            />
            <div>
              <label>
                {intl.formatMessage({
                  id: "giftCard.customers",
                })}
              </label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  value={
                    customersList.length > 0
                      ? customersList[customersList.length]
                      : ""
                  }
                  onChange={selectCustomer}
                >
                  <option aria-label="None" value="" />
                  {!loading &&
                    customers?.map((customer) => {
                      return (
                        <option value={customer.id || ""}>
                          {`${customer.firstName} ${customer.lastName}`}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
              <div className="chips-list">
                {customersList.map((customerId) => {
                  return (
                    <Chip
                      label={`${
                        customers?.filter(
                          (customer) => customer.id === customerId
                        )?.[0]?.firstName
                      } ${
                        customers?.filter(
                          (customer) => customer.id === customerId
                        )?.[0]?.lastName
                      }`}
                      onDelete={() => handleDeleteCustomer(customerId || "")}
                      color="primary"
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <label>
                {intl.formatMessage({
                  id: "giftCard.groups",
                })}
              </label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  value={customersList[customersList.length]}
                  onChange={selectGroup}
                >
                  <option aria-label="None" value="" />
                  {!LaodingGroups &&
                    groups?.map((group) => {
                      return (
                        <option value={group.id || ""}>
                          {group.names?.[0].value}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
              <div className="chips-list">
                {groupList.map((groupId) => {
                  return (
                    <Chip
                      label={
                        groups?.filter((group) => group.id === groupId)?.[0]
                          ?.names?.[0].value || ""
                      }
                      onDelete={() => handleDeleteGroup(groupId || "")}
                      color="primary"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Wrapper>
  );
};

export default GiftCardForm;
