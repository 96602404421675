import styled from "styled-components";

export const Wrapper = styled.div<{ default: boolean; upload: boolean }>`
  display: flex;
  min-width: 500px;
  flex-direction: column;
  padding: 20px;
  .header-option {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    h3 {
      font-size: 16;
      font-weight: 700;
    }
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
  }
  .MuiTypography-body1 {
    font-size: 12px;
  }
  .add-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 10px;
    button {
      height: 40px;
      .MuiButton-label {
        display: block;
        font-size: 14px;
        text-transform: lowercase;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  .table-collection-option {
    display: flex;
    flex-direction: column;
    .table-collection-option__header {
      display: grid;
      grid-template-columns: ${(props) =>
        props.default && props.upload
          ? "1fr 70px 200px 80px 60px"
          : props.default
          ? "1fr 200px 80px 60px"
          : props.upload
          ? "1fr 70px 200px 60px"
          : "1fr 200px 60px"};
      gap: 5px;
      background: #f3f3f3;
      border-radius: 10px;
      span {
        font-size: 12px;
        line-height: 35px;
        left: 10px;
        text-align: center;
        color: #444444;
      }
    }
    .table-collection-option__list {
      display: flex;
      flex-direction: column;
      .table-collection-option__item {
        display: grid;
        grid-template-columns: ${(props) =>
          props.default && props.upload
            ? "1fr 70px 200px 80px 60px"
            : props.default
            ? "1fr 200px 80px 60px"
            : props.upload
            ? "1fr 70px 200px 60px"
            : "1fr 200px 60px"};
        gap: 5px;
        height: 50px;
        align-items: center;
        justify-content: center;
        .mainInput {
          padding: 8px;
        }
        .upload-picture {
          margin: 0 auto;
          padding-top: 5px;
        }
        .price-modify {
          display: flex;
          gap: 5px;
          .MuiSelect {
            padding: 8px;
          }
        }
        .MuiFormControlLabel-root {
          margin-left: 0;
          margin-right: 0;
        }
        .MuiIconButton-root {
          font-size: 1.3rem;
          padding: 15px;
          margin: 0 auto;
        }
      }
      .list-empty-value {
        height: 15px;
        padding: 10px;
        font-size: 13px;
      }
    }
  }
`;
