export type Error = {
  email?: string;
  password?: string;
};

export const SnoonuLoginValidation = (
  email?: string,
  password?: string
): Error => {
  const errors: Error = {};

  if (!email) {
    errors.email = "This field is required";
  } else if (
    !email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    errors.email = "Invalid email";
  }
  if (!password) {
    errors.password = "This field is required";
  }

  return errors;
};
