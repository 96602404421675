import React from "react";
import { Checkbox, IconButton, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  BoxNew as Box,
  BreadCrumbsTitle,
  ButtonCustomize,
  Input,
  Table,
} from "../../commons";
import { ColumnsProps } from "../../commons/Table";
import { Note, Wrapper } from "./SubscriptionsStyle";
import { Tabs } from "../Products/Products--types";

const Subscriptions = (): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();

  // state
  const [state, setState] = React.useState({
    search: "",
    page: 0,
    rowsPerPage: 10,
  });
  const list = [
    {
      id: "1",
      title: "Basic package",
      duration: "1 month",
      price: "120QAR",
      limit: "5/Month",
      status: "Active",
    },
    {
      id: "2",
      title: "Basic package",
      duration: "1 month",
      price: "120QAR",
      limit: "5/Month",
      status: "Active",
    },
  ];
  // functions
  const onClickNewSubscription = () => {
    history.push("/create-subscription");
  };
  const onChangeTextSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setState((prevState) => ({ ...prevState, search: event.target.value }));
  };
  const onKeyDownSearch = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      // search product
    }
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = Number.parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, rowsPerPage }));
  };
  const onChangePage = (_: unknown, newPage: number) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  };
  const onClickRow = (row: any) => {
    // history.push({
    //   pathname: `/managment-product/${id || idProduct}`,
    //   state: {
    //     tab,
    //   },
    // });
  };
  const onClickEdit =
    (item: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
    };
  const onClickDelete =
    (item: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
    };
  const renderTableRows = () => {
    return (
      list
        ?.slice(
          state.page * state.rowsPerPage,
          state.page * state.rowsPerPage + state.rowsPerPage
        )
        ?.map((item) => ({
          id: item?.id,
          checkbox: (
            <Checkbox
              checked={false}
              inputProps={{ "aria-label": "primary checkbox" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ),
          title: item.title,
          duration: item.duration,
          price: item.price,
          limit: item.limit,
          status: item.status,
          actions: (
            <Box display="flex" flexDirection="row">
              <IconButton onClick={onClickEdit(item)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={onClickDelete(item)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          ),
        })) || []
    );
  };
  const columns: ColumnsProps = [
    {
      header: (
        <Checkbox
          checked={false}
          inputProps={{ "aria-label": "primary checkbox" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
      accessor: "checkbox",
      cellProps: { align: "center", width: "30px" },
    },
    {
      header: intl.formatMessage({ id: "subscriptions.subscriptionTitle" }),
      accessor: "title",
    },
    {
      header: intl.formatMessage({ id: "subscriptions.duration" }),
      accessor: "duration",
    },
    {
      header: intl.formatMessage({ id: "subscriptions.price" }),
      accessor: "price",
      headerCellProps: { align: "center" },
      cellProps: { align: "center" },
    },
    {
      header: intl.formatMessage({
        id: "subscriptions.productLimit",
      }),
      accessor: "limit",
      headerCellProps: { align: "center" },
      cellProps: { align: "center" },
    },
    {
      header: intl.formatMessage({ id: "subscriptions.status" }),
      accessor: "status",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];
  return (
    <Wrapper>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({ id: "subscriptions.bundlesManagement" }),
            intl.formatMessage({ id: "subscriptions.subscription" }),
          ]}
          iconAlt="subscription"
        />
        <ButtonCustomize
          variant="contained"
          color="secondary"
          onClick={onClickNewSubscription}
          style={{ marginLeft: 10 }}
        >
          <AddIcon /> &nbsp;
          {intl.formatMessage({ id: "subscriptions.newSubscription" })}
        </ButtonCustomize>
      </Note>
      <div className="container">
        <div className="table-collection">
          <div className="search">
            <Input
              value={state.search}
              onChange={onChangeTextSearch}
              onKeyDown={onKeyDownSearch}
              placeholder="Search for"
            />
            {/* <Box className="actions">
              <Typography variant="caption" color="inherit" className="note">
                2 subscriptions selectionnés
              </Typography>

              <ButtonCustomize
                variant="text"
                color="inherit"
                onClick={() => {}}
              >
                <DeleteIcon color="inherit" /> &nbsp; Supprimer
              </ButtonCustomize>
            </Box> */}
          </div>
          <Box flex="1">
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="No data available for search"
              onClickRow={onClickRow}
              // tablePaginationProps={{
              //   count: list.length,
              //   page: state.page,
              //   rowsPerPage: state.rowsPerPage,
              //   labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
              //   onChangePage,
              //   onChangeRowsPerPage,
              // }}
            />
          </Box>
        </div>
      </div>
    </Wrapper>
  );
};

export default Subscriptions;
