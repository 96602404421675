import React, { FC } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { useIntl } from "react-intl";

import { Input } from ".";

type ColorpickerType = {
  value: string;
  description: string;
  // eslint-disable-next-line
  onChange: (value: string) => void;
};

type ColorInputType = {
  color: string;
};

const Colorpicker: FC<ColorpickerType> = ({ value, onChange, description }) => {
  const intl = useIntl();

  const [showPicker, setShowPicker] = React.useState<boolean>(false);
  const handleChangeColor = (color: string) => {
    onChange(color);
  };

  const handleClickAway = () => {
    setShowPicker(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="box">
        <span className="input-label">{description}</span>

        <Input
          readOnly
          label={intl.formatMessage({ id: "MenuStyle.colorTag" })}
          type="text"
          fullWidth
          value={value}
          endAdornment={
            <ColorInput
              color={value}
              onClick={() => setShowPicker(!showPicker)}
            />
          }
        />
        <div className={showPicker ? "color-picker show" : "color-picker"}>
          <ChromePicker
            color={value}
            disableAlpha
            onChange={(color: any) => handleChangeColor(color)}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

const ColorInput = styled.div<ColorInputType>`
  width: 36px;
  height: 36px;
  border: 2px solid #f1f2fb;
  border-radius: 8px;
  position: absolute;
  right: 12px;
  background-color: ${(props) => props.color};
  cursor: pointer;
`;

export default Colorpicker;
