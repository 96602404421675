import React, { useState, useEffect } from "react";
import { Box, Paper, Button, Typography, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery, useMutation } from "@apollo/client";

import { StaffMemberFormModal } from "../../components";
import { BreadCrumbsTitle, Modal } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import {
  GET_MENU_STAFF,
  GET_COMPANY_OWNER,
  DELETE_MENU_STAFF,
} from "../../api";
import {
  GetMenuStaffQuery,
  GetMenuStaffQueryVariables,
  StaffPayload,
  GetCompanyOwnerQuery,
  GetCompanyOwnerQueryVariables,
  DeleteMenuStaffMutation,
  DeleteMenuStaffMutationVariables,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

import { Wrapper } from "./Members--styles";

import { Owner } from "../../img/digishop/menu-icons";

const Members: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [collections, setCollections] = useState<StaffPayload[]>([]);
  const [staffData, setStaffData] = useState<StaffPayload | undefined>(
    undefined
  );
  const [open, setOpen] = useState<boolean>(false);
  const [idMember, setIdMember] = useState<string>("");

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: companyOwner } = useQuery<
    GetCompanyOwnerQuery,
    GetCompanyOwnerQueryVariables
  >(GET_COMPANY_OWNER, {
    variables: {
      input: {
        token: localStorage.getItem("token") || "",
        menu: localMenu?.menuItem?.id || "",
      },
    },
    skip: !localMenu?.menuItem?.id,
  });

  const { data: listMember, refetch } = useQuery<
    GetMenuStaffQuery,
    GetMenuStaffQueryVariables
  >(GET_MENU_STAFF, {
    variables: {
      input: {
        menu: localMenu?.menuItem.id || "",
        token: localStorage.getItem("token") || "",
      },
    },
    skip: !localMenu?.menuItem.id,
  });

  const [DeleteMember, { loading: loadingDelete }] = useMutation<
    DeleteMenuStaffMutation,
    DeleteMenuStaffMutationVariables
  >(DELETE_MENU_STAFF, {
    variables: {
      input: {
        token: localStorage.getItem("token") || "",
        id: idMember,
      },
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      refetch();
      setOpen(false);
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully deleted",
      });
    },
  });

  useEffect(() => {
    setCollections(listMember?.getMenuStaff?.list || []);
  }, [listMember]);

  const columns: ColumnsProps = [
    {
      header: "Staff Name",
      accessor: "name",
    },
    {
      header: "Staff Email",
      accessor: "email",
    },
    {
      header: "Role",
      accessor: "role",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  const renderTableRows = () => {
    return (
      [
        {
          name: (
            <Typography variant="subtitle1">
              <Owner /> &nbsp;
              {companyOwner?.getCompanyOwner?.owner?.informations?.firstName}
              &nbsp;
              {companyOwner?.getCompanyOwner?.owner?.informations?.lastName}
              &nbsp; (you)
            </Typography>
          ),
          email: (
            <Typography variant="subtitle1">
              {companyOwner?.getCompanyOwner?.owner?.email}
            </Typography>
          ),
          role: <Typography variant="subtitle1">Owner</Typography>,
        },
        ...collections?.map((collection) => ({
          name: (
            <Typography variant="subtitle1">
              {collection?.informations?.firstName} &nbsp;
              {collection?.informations?.lastName}
            </Typography>
          ),
          email: (
            <Typography variant="subtitle1">{collection?.email}</Typography>
          ),
          role: (
            <Typography variant="subtitle1">
              {collection?.role?.kind}
            </Typography>
          ),
          actions: (
            <div style={{ display: "flex" }}>
              <IconButton
                size="small"
                color="primary"
                style={{ color: " #1F90CF", margin: "0 4px" }}
                onClick={() => {
                  setOpenModal(true);
                  setStaffData(collection);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                style={{ color: "#ff4949", margin: "0 4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                  setIdMember(collection.id || "");
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        })),
      ] || []
    );
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Staff Managment", "Staff Members"]}
        iconAlt="Role"
      />
      <Box component={Paper} className="member__container">
        <div className="member__container-action">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(true);
              setStaffData(undefined);
            }}
          >
            Create member
          </Button>
        </div>
        <Table columns={columns} data={renderTableRows()} />
      </Box>
      <StaffMemberFormModal
        title="Staff Member"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        refetch={refetch}
        staffData={staffData}
      />
      <Modal
        open={open}
        title="Delete member?"
        message="Are you sure you want to remove this member?"
        handleClose={() => setOpen(false)}
        handleContent={DeleteMember}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default Members;
