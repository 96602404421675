import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, CircularProgress } from "@material-ui/core";
import { MenuCard, Modal, Breadcrumbs } from "../../commons";
import { GETLISTMENUS, GETMYACCOUNT } from "../../api";
import {
  GetListMenuQuery,
  GetListMenuQueryVariables,
  GetMyAccountQuery,
  GetMyAccountQueryVariables,
} from "../../api/types";

const Menus: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const location = useLocation();
  const isReached = location.state;
  // //for open Snackbar(alert)
  // const [open, setOpen] = React.useState(false);
  // //message for alert
  // const [msgSnackbar, setMsgSnackbar] = React.useState("");
  // //for alert succes or error
  // const [severity, setSeverity] = React.useState<"success" | "error">();
  const [openNotice, setOpenNotice] = React.useState(false);

  const intl = useIntl();

  const { data, loading } = useQuery<
    GetListMenuQuery,
    GetListMenuQueryVariables
  >(GETLISTMENUS, {
    variables: {
      token,
    },
    skip: !token,
    fetchPolicy: "network-only",
  });

  const { data: myAccount, loading: loadingMyAccount } = useQuery<
    GetMyAccountQuery,
    GetMyAccountQueryVariables
  >(GETMYACCOUNT, {
    variables: {
      token,
      languageCode: "FR",
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (isReached) {
      setOpenNotice(true);
    }
  }, [isReached]);

  const handleCloseNotice = () => {
    setOpenNotice(false);
    // history.replace(isReached, false);
  };

  return (
    <Wrapper>
      <Modal
        open={openNotice}
        title="Notice"
        action="notice"
        handleClose={handleCloseNotice}
        handleContent={() => {}}
      >
        {intl.formatMessage({ id: "Menus.notice" })} <br />
        {intl.formatMessage({ id: "Menus.notice2" })}
      </Modal>
      <div className="topbar">
        <Note>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" color="#AFB1C6" />
          <div className="content">
            <Breadcrumbs />
            <span>
              {intl.formatMessage({ id: "Store.note1" })}
              {intl.formatMessage({ id: "Store.note2" })}
            </span>
          </div>
        </Note>
        {data?.getListMenus?.list && data?.getListMenus?.list?.length > 0 && (
          <Button
            data-testid="add-menu-button"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              history.push("/add-store");
            }}
          >
            <FontAwesomeIcon icon={faPlus} color="#ffffff" />
            <span className="btn-description">
              {intl.formatMessage({ id: "Store.addStore" })}
            </span>
          </Button>
        )}
      </div>
      <Seperator />
      {loading && loadingMyAccount && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
      {!loading && !loadingMyAccount && (
        <div className="show-menu-card">
          {data?.getListMenus?.list?.map((item) => (
            <MenuCard
              key={item.id}
              input={item}
              domain={myAccount?.getMyAccount.account.company?.domain}
            />
          ))}
        </div>
      )}
      {data?.getListMenus?.list && data?.getListMenus?.list?.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            height: "calc(100vh - 350px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#afb1c6", marginBottom: "35px" }}>
            <b>{intl.formatMessage({ id: "Store.listEmpty" })}</b>
          </p>
          <Button
            data-testid="add-menu-button"
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              history.push("/add-store");
            }}
          >
            <FontAwesomeIcon icon={faPlus} color="#ffffff" />
            <span className="btn-description">
              {intl.formatMessage({ id: "Store.addFisrtStore" })}
            </span>
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

const Note = styled.div`
  display: flex;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    span {
      margin-left: 15px;
      color: #afb1c6;
      font-weight: 100;
      font-style: italic;
      font-size: 13px;
    }
  }
`;

const Seperator = styled.div`
  width: 100%;
  height: 20px;
`;

const Wrapper = styled.div`
  margin-top: -10px;
  .topbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-description {
      margin-left: 12px;
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  h2 {
    margin: 15px 0;
  }
  .show-menu-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .loading {
    display: flex;
    width: 100%;
    height: calc(100vh - 350px);
    justify-content: center;
    align-items: center;
  }
  /* @media only screen and (min-width: 768px) {
    .show-menu-card {             
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 1000px) {
    .show-menu-card {
      grid-template-columns: 1fr 1fr;
    }
  } */
`;

export default Menus;
