import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import findIndex from "lodash/findIndex";

import { BreadCrumbsTitle, ButtonCustomize, Modal } from "../../commons";
import { DeliveryCard } from "../../components";

import { GET_MENU_DELIVERY_SETTINGS, REMOVE_DELIVERY_SERVICE } from "../../api";
import {
  GetMenuDeliverySettingsQuery,
  GetMenuDeliverySettingsQueryVariables,
  RemoveDeliveryServiceMutation,
  RemoveDeliveryServiceMutationVariables,
  MenuPayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import { Wrapper } from "./PickupDelivery--styles";

import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";

const PickupDelivery = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const [open, setOpen] = React.useState(false);
  const [idDelivery, setIdDelivery] = React.useState<string>("");

  const { data, refetch } = useQuery<
    GetMenuDeliverySettingsQuery,
    GetMenuDeliverySettingsQueryVariables
  >(GET_MENU_DELIVERY_SETTINGS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const deliveryData = data?.getMenuDeliverySettings?.deliveryServices;

  const [RemoveDelivery, { loading: loadingDelete }] = useMutation<
    RemoveDeliveryServiceMutation,
    RemoveDeliveryServiceMutationVariables
  >(REMOVE_DELIVERY_SERVICE, {
    onCompleted: () => {
      refetch();
      setOpen(false);
    },
  });

  const handleRemoveDelivery = () => {
    if (localMenu?.id) {
      RemoveDelivery({
        variables: {
          input: {
            token,
            menu: localMenu?.id,
            id: idDelivery,
          },
        },
      });
    }
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "Pickup & delivery"]}
        icon={StoreSettings}
        iconAlt="translate"
      />
      <Box component={Paper} p={2} height="calc(100vh - 100px)">
        <Typography variant="h2" className="bold">
          Shipping
        </Typography>
        <Box display="flex" marginTop={2}>
          {deliveryData && deliveryData.length > 0 && (
            <Box flex={2} className="list-card">
              <Typography variant="h3" className="bold-h3">
                Current shipping and delivery methods
              </Typography>
              {deliveryData?.map((item) => (
                <DeliveryCard
                  key={item.id}
                  DeliveryPayload={item}
                  onDelete={(id) => {
                    setOpen(true);
                    setIdDelivery(id);
                  }}
                />
              ))}
            </Box>
          )}

          <Box flex={1}>
            <Typography variant="h3" className="bold-h3">
              Services delivery method
            </Typography>
            <Typography variant="subtitle1" className="description">
              Ship within your country or internationally. Enable automatic
              shipping rates from carriers or use your own custom rates if your
              product requires special shipping fees.
            </Typography>
            <Link to="/add-delivery">
              <ButtonCustomize
                variant="contained"
                color="secondary"
                className="btn-action"
                disabled={
                  findIndex(deliveryData, (o) => o.isEnabled === true) !== -1
                }
              >
                <AddIcon fontSize="small" />
                Add shipping
              </ButtonCustomize>
            </Link>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        title="Delete Delivery Service"
        message="Are you sure you want to remove this delivery service?"
        handleClose={() => setOpen(false)}
        handleContent={handleRemoveDelivery}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default PickupDelivery;
