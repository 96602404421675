import styled from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => `
  .container {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 20px;
    > div {
      nav {
        display: flex;
        height: 30px;
        color: #6a6a6d;
        font-size: 14px;
        border-bottom: 1px solid #e3e3e3;
        z-index: 9;
        margin-bottom: 15px;
        .mode {
          height: 30px;
          min-width: 30px;
          margin-right: 30px;
          cursor: pointer;
        }
        .selected {
          color: ${theme.palette.secondary.main};
          font-weight: 700;
          border-bottom: 1px solid ${theme.palette.secondary.main};
          z-index: 99;
        }
      }
    }
    .form-pricing {
      margin-top: 45px;
      .title {
        font-weight: 700;
      }
      .option-switch {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.09);
        > div {
          font-size: 14px;
        }
      }
    }
  }
  .popup-save {
    position: absolute;
    display: flex;
    width: calc(100% - 388px);
    height: 60px;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    top: 10px;
    border-radius: 10px;
    padding: 0 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11);
    z-index: 999;
    button {
      height: 35px;
    }
  }`}
`;
