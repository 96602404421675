import { UpdateCompanySiteInput } from "../../api/types";
import { Errors } from "./AddressUpdateModal";

export const AddressUpdateModalValidation = (
  values: UpdateCompanySiteInput
): Errors => {
  const errors: Errors = {};
  if (!values.identifier) errors.identifier = "form.fieldRequired";
  if (!values.address?.roadNames?.[0].value)
    errors.address = "form.fieldRequired";
  if (!values.address?.country) errors.country = "form.fieldRequired";
  if (!values.address?.city) errors.city = "form.fieldRequired";
  return errors;
};
