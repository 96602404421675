import * as React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useQuery, useMutation } from "@apollo/client";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import { ButtonCustomize, Input } from "../../commons";

import { GETMENUCATEGORYDETAILS, UPDATE_CATEGORY_DICTIONARY } from "../../api";
import {
  GetMenuCategoryDetailsQuery,
  GetMenuCategoryDetailsQueryVariables,
  UpdateCategoryDictionaryMutation,
  UpdateCategoryDictionaryMutationVariables,
  UpdateNameInput,
  UpdateDescriptionInput,
  LanguageCode,
} from "../../api/types";
import { snackBarVar } from "../../api/local-state";

type TranslateCategoryFormProps = {
  idCategory?: string;
  language?: LanguageCode;
  origineLangage: LanguageCode;
  onCleanIdCategory: (id: undefined) => void;
};

const TranslateCategoryForm: React.FC<TranslateCategoryFormProps> = ({
  language,
  idCategory,
  onCleanIdCategory,
  origineLangage,
}) => {
  const token = localStorage.getItem("token") || "";

  const [dictionary, setDictionary] = React.useState<{
    name: string;
    description: string;
  }>({
    name: "",
    description: "",
  });

  const { data } = useQuery<
    GetMenuCategoryDetailsQuery,
    GetMenuCategoryDetailsQueryVariables
  >(GETMENUCATEGORYDETAILS, {
    variables: { input: { token, id: idCategory || "" } },
    skip: !idCategory,
  });

  const category = data?.getMenuCategoryDetails?.category;

  React.useEffect(() => {
    if (category) {
      const { names, descriptions } = category;

      const findName =
        find(names, (o) => o.languageCode === language)?.value || "";
      const findDescription =
        find(descriptions, (o) => o.languageCode === language)?.value || "";

      setDictionary({
        name: findName,
        description: findDescription,
      });
    }
  }, [category, language]);

  const [updateDictionary, { loading }] = useMutation<
    UpdateCategoryDictionaryMutation,
    UpdateCategoryDictionaryMutationVariables
  >(UPDATE_CATEGORY_DICTIONARY, {
    refetchQueries: () => [
      {
        query: GETMENUCATEGORYDETAILS,
        variables: { input: { token, id: idCategory || "" } },
      },
    ],
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully updated",
      });
    },
    onError: (error) => {
      if (error.message === "menu_category_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Category not fount",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDictionary({
      ...dictionary,
      [name]: value,
    });
  };

  const handleSave = () => {
    const arrayNames: UpdateNameInput[] = [];
    const arrayDescription: UpdateDescriptionInput[] = [];

    category?.names?.forEach((name) =>
      arrayNames.push({
        id: name.id,
        languageCode: name.languageCode as LanguageCode,
        value: name.value,
      })
    );

    category?.descriptions?.forEach((description) =>
      arrayDescription.push({
        id: description.id,
        languageCode: description.languageCode as LanguageCode,
        value: description.value,
      })
    );

    const positionNames = findIndex(
      arrayNames,
      (o) => o.languageCode === language
    );
    const positionDescription = findIndex(
      arrayDescription,
      (o) => o.languageCode === language
    );

    if (positionNames === -1) {
      arrayNames.push({
        value: dictionary.name,
        languageCode: language,
      });
    } else {
      arrayNames[positionNames] = {
        ...arrayNames[positionNames],
        value: dictionary.name,
        languageCode: language,
      };
    }

    if (positionDescription === -1) {
      arrayDescription.push({
        value: dictionary.description,
        languageCode: language,
      });
    } else {
      arrayDescription[positionDescription] = {
        ...arrayDescription[positionDescription],
        value: dictionary.description,
        languageCode: language,
      };
    }
    updateDictionary({
      variables: {
        input: {
          token,
          id: idCategory || "",
          names: arrayNames,
          descriptions: arrayDescription,
        },
      },
    });
  };

  return (
    <Wrapper>
      <div className="header">
        <Typography variant="h3" style={{ fontWeight: 600 }}>
          {
            find(category?.names, (o) => o.languageCode === origineLangage)
              ?.value
          }
        </Typography>
        <div>
          <ButtonCustomize
            onClick={() => onCleanIdCategory(undefined)}
            style={{ marginRight: 10 }}
          >
            Cancel
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </ButtonCustomize>
        </div>
      </div>
      <Grid container alignItems="center" spacing={1}>
        {/* Name category */}
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            label="Category name"
            value={
              find(category?.names, (o) => o.languageCode === origineLangage)
                ?.value || ""
            }
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            name="name"
            label="Category name"
            value={dictionary?.name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        {/* Description category */}
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            label="Category desciption"
            value={
              find(
                category?.descriptions,
                (o) => o.languageCode === origineLangage
              )?.value || ""
            }
            rows={5}
            multiline
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            name="description"
            label="Category description"
            value={dictionary?.description}
            onChange={handleChange}
            rows={5}
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default TranslateCategoryForm;

const Wrapper = styled.div`
  display: flex;
  min-width: 820px;
  flex-direction: column;
  padding: 0 10px 7px 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
`;
