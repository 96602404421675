import styled from "styled-components";

export const Wrapper = styled.div`
  .header-variants {
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 16px;
      margin: auto 0;
    }
    .MuiButton-label {
      display: block;
      font-size: 14px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .info {
    color: #bcbcbc;
    font-size: 12px;
    margin: 10px 0 20px 0;
  }
  .list-variants {
    display: flex;
    flex-direction: column;
    .list-variants__title {
      display: flex;
      background: #f3f3f3;
      border-radius: 5px;
      span {
        flex: 1;
        font-size: 12px;
        color: #444444;
        line-height: 35px;
        text-align: center;
        &:nth-child(0n + 1) {
          max-width: 100px;
        }
        &:last-child {
          max-width: 80px;
        }
      }
    }
    .list-variants__collection {
      display: flex;
      flex-direction: column;
      .list-variants__item {
        display: flex;
        align-items: center;
        height: 50px;
        span {
          flex: 1;
          font-size: 12px;
          color: #444444;
          text-align: center;
          &:nth-child(0n + 1) {
            max-width: 100px;
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
            }
          }
          &:last-child {
            max-width: 80px;
            .MuiIconButton-root {
              padding: 10.5px;
            }
          }
        }
      }
    }
    .list-variants__empty {
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      p {
        font-size: 12px;
      }
    }
  }
  .variation__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
`;

export const DropDown = styled.div<{ show: boolean | null }>`
  z-index: ${(props) => (props.show ? 99 : -1)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: fit-content;
  position: fixed;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
  transition: all 0.15s ease-in-out;
  pointer-events: ${(props) => (props.show === true ? "all" : "none")};
  ul {
    margin: 0;
    padding: 0;
    div {
      cursor: ${(props) => props.show && "pointer"};
    }
  }
  margin-top: -5px;
  right: 450px;
`;

export const MenuList = styled.div<{ danger?: boolean }>`
  padding: 5px 16px;
  min-width: 100px;
  border-radius: 5px;
  color: ${(props) => (props.danger ? "#F67070" : "#444444")};
  :hover {
    background-color: ${(props) => (props.danger ? "#FCF1F1" : "#f3f4fa")};
  }
`;
