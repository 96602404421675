import gql from "graphql-tag";

// query get menu entry stats
export const GETMENUENTRYSTATS = gql`
  query getMenuEntryStats(
    $token: String!
    $menu: String!
    $year: Int!
    $month: Int!
  ) {
    getMenuEntryStats(
      input: { token: $token, menu: $menu, year: $year, month: $month }
    ) {
      list {
        dayOfWeek
        count
        details {
          day
          count
        }
      }
    }
  }
`;

// query get category entry stats
export const CATEGORYENTRYSTATS = gql`
  query getCategoryEntryStats($input: GetCategoryEntryStatsInput!) {
    getCategoryEntryStats(input: $input) {
      list {
        category {
          id
          names {
            id
            languageCode
            value
          }
          picture {
            id
            kind
            fileName
          }
        }
        currentMonthCount
        previousMonthCount
        differenceCount
      }
    }
  }
`;

// query get product entry stats

export const GETPRODUCTENTRYSTATS = gql`
  query getProductEntryStats($input: GetProductEntryStatsInput!) {
    getProductEntryStats(input: $input) {
      list {
        product {
          id
          names {
            id
            languageCode
            value
          }
          picture {
            id
            kind
            fileName
          }
        }
        currentMonthCount
        previousMonthCount
        differenceCount
      }
    }
  }
`;
