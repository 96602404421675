import React, { useState } from "react";
import { Box, Paper, Typography, MenuItem } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useQuery, useReactiveVar } from "@apollo/client";

import { BreadCrumbsTitle, Select } from "../../commons";
import { StoreStatistics, ChartLine } from "../../components";

import {
  GET_STORE_STATS_SUMMARY,
  GET_STORE_VISITS,
  GET_STORE_REVENUE,
} from "../../api";
import {
  GetStoreStatsSummaryQuery,
  GetStoreStatsSummaryQueryVariables,
  GetStoreVisitsQuery,
  GetStoreVisitsQueryVariables,
  GetStoreRevenueQuery,
  GetStoreRevenueQueryVariables,
  MenuPayload,
  CurrencyCode,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import AnalyticsIcon from "../../img/digishop/menu-icons/analytics.svg";

import { Wrapper } from "./Analytics--styles";

const Analytics: React.FC = () => {
  const token = localStorage.getItem("token") || "";

  const [selectRange, setSelectRange] = useState("Today");

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const { data: statsSummary } = useQuery<
    GetStoreStatsSummaryQuery,
    GetStoreStatsSummaryQueryVariables
  >(GET_STORE_STATS_SUMMARY, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
      },
    },
    skip: !localMenu?.id,
  });

  const { data: storeVisite } = useQuery<
    GetStoreVisitsQuery,
    GetStoreVisitsQueryVariables
  >(GET_STORE_VISITS, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        timeRange: {
          start: "2021-03-16",
          end: "2021-03-16",
        },
      },
    },
  });

  const { data: storeRevenue } = useQuery<
    GetStoreRevenueQuery,
    GetStoreRevenueQueryVariables
  >(GET_STORE_REVENUE, {
    variables: {
      input: {
        token,
        menu: localMenu?.id || "",
        timeRange: {
          start: "2021-03-16",
          end: "2021-03-16",
        },
        currency: localMenu?.currency?.code as CurrencyCode,
      },
    },
  });

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Analytics", "Details Analytics"]}
        icon={AnalyticsIcon}
        iconAlt="Analytics"
      />
      <Typography variant="h2" style={{ marginTop: 5, fontWeight: "bold" }}>
        Store Statistics
      </Typography>

      <StoreStatistics summary={statsSummary?.getStoreStatsSummary?.summary} />

      <Typography variant="h2" style={{ marginTop: 20, fontWeight: "bold" }}>
        Store Performance
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        component={Paper}
        marginTop={2}
        p={2}
      >
        <Box display="flex" width={1} justifyContent="flex-end">
          <div style={{ width: 300 }}>
            <Select
              label="Select Range"
              value={selectRange}
              onChange={(e) => setSelectRange(e.target.value as string)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="Today">Today</MenuItem>
              <MenuItem value="Yesterday">Yesterday</MenuItem>
              <MenuItem value="This Month">This Month</MenuItem>
              <MenuItem value="Last Week">Last Week</MenuItem>
              <MenuItem value="Last Month">Last Month</MenuItem>
            </Select>
          </div>
        </Box>
        <Box display="flex" width={1} flexWrap="wrap">
          <Box flex="1" maxWidth={500}>
            <Typography
              variant="h2"
              style={{ fontWeight: "bold", marginLeft: 25 }}
            >
              Visitors
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: "bold", margin: "10px 0 10px 25px" }}
            >
              5124 <ArrowUpwardIcon style={{ color: "#41BD72" }} />
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginLeft: 25 }}
            >
              3 145 This Time Yesterday
            </Typography>
            <Typography variant="subtitle1" style={{ marginLeft: "25px" }}>
              7 314 Yesterday’s total
            </Typography>
            <ChartLine />
          </Box>
          <Box flex="1" maxWidth={500}>
            <Typography
              variant="h2"
              style={{ fontWeight: "bold", marginLeft: 25 }}
            >
              Revenue
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: "bold", margin: "10px 0 10px 25px" }}
            >
              1 024 TND <ArrowUpwardIcon style={{ color: "#41BD72" }} />
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginLeft: 25 }}
            >
              614 TND This Time Yesterday
            </Typography>
            <Typography variant="subtitle1" style={{ marginLeft: "25px" }}>
              950 TND Yesterday’s total
            </Typography>
            <ChartLine />
          </Box>
          <Box flex="1" maxWidth={500}>
            <Typography
              variant="h2"
              style={{ fontWeight: "bold", marginLeft: 25 }}
            >
              Orders
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: "bold", margin: "10px 0 10px 25px" }}
            >
              24 <ArrowDownwardIcon style={{ color: "#F67070" }} />
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginLeft: 25 }}
            >
              26 This Time Yesterday
            </Typography>
            <Typography variant="subtitle1" style={{ marginLeft: "25px" }}>
              42 Yesterday’s total
            </Typography>
            <ChartLine />
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Analytics;
