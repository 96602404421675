import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    display: flex;
    font-size: 24px;
    font-weight: 300;
    color: #444444;
    img {
      width: 40px;
      margin-right: 15px;
    }
    span {
      font-weight: 600;
    }
  }
  .action {
    display: flex;
    .btn-action {
      .MuiButton-label {
        display: block;
        font-size: 16px;
        text-transform: lowercase;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .container {
    h3 {
      font-size: 20px;
      font-weight: normal;
      color: #444444;
      margin: 0;
    }
    .collections {
      background: #fcfbfb;
      padding: 20px;
      margin-top: 10px;
      border: 2px solid #f3f4fa;
      border-radius: 10px;
      .collections__header {
        display: flex;
        height: 35px;
        gap: 15px;
        justify-content: space-between;
        span {
          width: 85px;
          font-size: 14px;
          color: #444444;
          text-align: center;
        }
        span:nth-child(0n + 1) {
          width: 250px;
          margin-left: 20px;
          text-align: left;
        }
        span:nth-child(0n + 2) {
          flex: 1;
          text-align: left;
        }
        span:last-child {
          width: 60px;
        }
      }
      .collections__list {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 300px);
        gap: 10px;
        overflow-y: auto;
        .collections__item {
          display: flex;
          flex: 1;
          min-height: 65px;
          gap: 15px;
          justify-content: space-between;
          background: #ffffff;
          border: 2px solid #f3f4fa;
          border-radius: 10px;
          span {
            color: #444444;
          }
        }
        .collections__item > span {
          display: flex;
          width: 85px;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          padding: 5px 0;
          &:nth-child(0n + 1) {
            width: 250px;
            justify-content: flex-start;
            margin-left: 20px;
          }
          &:nth-child(0n + 2) {
            flex: 1;
            justify-content: flex-start;
          }
          &:last-child {
            width: 60px;
            z-index: 99;
            .MuiIconButton-root {
              padding: 10.5px;
            }
          }
        }
        .collections__item:hover {
          border: 2px solid #f29f05;
          box-shadow: 0px 0px 20px rgba(175, 177, 198, 0.28);
          border-radius: 10px;
        }
      }
    }
    .btn-description {
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const DropDown = styled.div<{ show: boolean | null }>`
  z-index: ${(props) => (props.show ? 99 : -1)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: fit-content;
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
  transition: all 0.15s ease-in-out;
  pointer-events: ${(props) => (props.show === true ? "all" : "none")};
  ul {
    margin: 0;
    padding: 0;
    div {
      cursor: ${(props) => props.show && "pointer"};
    }
  }
  margin-top: 100px;
  margin-right: 155px;
`;

export const MenuList = styled.div<{ danger?: boolean }>`
  padding: 5px 16px;
  min-width: 100px;
  border-radius: 5px;
  color: ${(props) => (props.danger ? "#F67070" : "#444444")};
  :hover {
    background-color: ${(props) => (props.danger ? "#FCF1F1" : "#f3f4fa")};
  }
`;
