import Sales from "../img/digishop/menu-icons/sales.svg";
import SalesBlack from "../img/digishop/menu-icons/salesBlack.svg";
import Product from "../img/digishop/menu-icons/products.svg";
import Marketing from "../img/digishop/menu-icons/marketing.svg";
import MarketingBlack from "../img/forus/menu-icons/MarketingBlack.svg";
import Customers from "../img/digishop/menu-icons/customers.svg";
import CustomersBlack from "../img/digishop/menu-icons/customersBlack.svg";
import Analytics from "../img/digishop/menu-icons/analytics.svg";
import Role from "../img/digishop/menu-icons/role.svg";
import RoleBlack from "../img/digishop/menu-icons/roleBlack.svg";
import StaffManagement from "../img/digishop/menu-icons/staffManagement.svg";
import StoreSettings from "../img/digishop/menu-icons/StoreSettings.svg";
import Content from "../img/digishop/menu-icons/content.svg";
import Stories from "../img/digishop/menu-icons/stories.svg";
import BundleManagement from "../img/digishop/menu-icons/bundleManagement.svg";
import StoreSettings2 from "../img/digishop/menu-icons/storeSettings2.svg";

export type ValuesPayload = {
  name: string;
  originPathname: string;
  pathnames: string[];
};

export type ListPathnameInitialPayload = {
  kind: string;
  icon: string;
  show: boolean;
  values: ValuesPayload[];
};

// ############################################################################################
// ######################## LIST PATHNAME INITIAL FOR DIGISHOP ###############################
// ##########################################################################################

export const listPathnameInitial: ListPathnameInitialPayload[] = [
  // Sales management
  {
    kind: "Sales management",
    icon: Sales,
    show: true,
    values: [
      {
        name: "Orders",
        originPathname: "/orders-list",
        pathnames: ["/orders-list", "/order"],
      },
    ],
  },
  // Product management
  {
    kind: "Product management",
    icon: Product,
    show: true,
    values: [
      {
        name: "Products",
        originPathname: "/product",
        pathnames: ["/product", "/managment-product"],
      },
      {
        name: "Categories",
        originPathname: "/categories",
        pathnames: ["/categories", "/create-category", "/edit-category"],
      },
      {
        name: "Inventory",
        originPathname: "/inventory",
        pathnames: [],
      },
      /* #### it's a comment because Analytics is not completed #### */
      {
        name: "Gift cards",
        originPathname: "/gift-cards",
        pathnames: ["/gift-cards", "/add-gift-card"],
      },
      {
        name: "Coupon",
        originPathname: "/promo-code",
        pathnames: ["/promo-code", "/add-promo-code"],
      },
    ],
  },
  /* #### it's a comment because Analytics is not completed #### */
  // Analytics
  // {
  //   kind: "Analytics",
  //   icon: Analytics,
  //   show: true,
  //   values: [
  //     { name: "Analytics", originPathname: "/analytics", pathnames: [] },
  //   ],
  // },
  // Staff management
  {
    kind: "Staff management",
    icon: Role,
    show: true,
    values: [
      {
        name: "Roles",
        originPathname: "/role-list",
        pathnames: ["/role-list", "/role-form"],
      },
      {
        name: "Staff Members",
        originPathname: "/members",
        pathnames: [],
      },
      {
        name: "Vendor",
        originPathname: "/vendor",
        pathnames: [],
      },
    ],
  },
  // Marketing
  {
    kind: "Marketing",
    icon: Marketing,
    show: true,
    values: [
      {
        name: "Newsletter",
        originPathname: "/newsletter",
        pathnames: [],
      },
      {
        name: "List Subscriptions",
        originPathname: "/subscriptions",
        pathnames: [],
      },
    ],
  },
  // customers
  {
    kind: "Customers",
    icon: Customers,
    show: true,
    values: [
      {
        name: " Customers List",
        originPathname: "/customers",
        pathnames: ["/customers", "/create-customer", "/modify-customer"],
      },
      {
        name: "Customers Group",
        originPathname: "/group",
        pathnames: ["/group", "/add-customer-group"],
      },
    ],
  },
  // Store settings
  {
    kind: "Store settings",
    icon: StoreSettings,
    show: true,
    values: [
      /* #### it's a comment because Analytics is not completed #### */
      // {
      //   name: "List of addresses",
      //   originPathname: "/setting-address",
      //   pathnames: ["/setting-address", "/setting-add-address"],
      // },
      {
        name: "Home Page",
        originPathname: "/settings-home",
        pathnames: [],
      },
      {
        name: "Payment",
        originPathname: "/payment",
        pathnames: [],
      },
      {
        name: "General Settings",
        originPathname: "/general-settings",
        pathnames: [],
      },
      {
        name: "Translate",
        originPathname: "/translate",
        pathnames: ["/translate", "/edit-translate"],
      },
      {
        name: "Store menu",
        originPathname: "/store-menu",
        pathnames: ["/store-menu", "/add-store-menu", "/edit-store-menu"],
      },
      {
        name: "Legality",
        originPathname: "/legality",
        pathnames: ["/legality", "/legality-form"],
      },
      {
        name: "Pickup & delivery",
        originPathname: "/delivery",
        pathnames: ["/delivery", "/add-delivery", "/edit-delivery"],
      },
    ],
  },
];

// #######################################################################################################
// ################################## LIST PATHNAME INITIAL FOR FORUS ##################################
// ###################################################################################################

export const listPathnameInitialForus: ListPathnameInitialPayload[] = [
  // Sales management
  {
    kind: "Sales management",
    icon: SalesBlack,
    show: true,
    values: [
      {
        name: "Orders",
        originPathname: "/orders-list",
        pathnames: ["/orders-list", "/order"],
      },
    ],
  },
  // Product management
  {
    kind: "Product management",
    icon: Content,
    show: true,
    values: [
      {
        name: "Products",
        originPathname: "/product",
        pathnames: ["/product", "/managment-product"],
      },
      {
        name: "Categories",
        originPathname: "/categories",
        pathnames: ["/categories", "/create-category", "/edit-category"],
      },
      {
        name: "Inventory",
        originPathname: "/inventory",
        pathnames: [],
      },
      {
        name: "Gift cards",
        originPathname: "/gift-cards",
        pathnames: ["/gift-cards", "/add-gift-card"],
      },
      {
        name: "Coupon",
        originPathname: "/promo-code",
        pathnames: ["/promo-code", "/add-promo-code"],
      },
    ],
  },
  // Staff management
  {
    kind: "Staff management",
    icon: RoleBlack,
    show: true,
    values: [
      {
        name: "Roles",
        originPathname: "/role-list",
        pathnames: ["/role-list", "/role-form"],
      },
      {
        name: "Staff Members",
        originPathname: "/members",
        pathnames: [],
      },
      {
        name: "Vendor",
        originPathname: "/vendor",
        pathnames: [],
      },
    ],
  },
  // Marketing
  {
    kind: "Marketing",
    icon: MarketingBlack,
    show: true,
    values: [
      {
        name: "Newsletter",
        originPathname: "/newsletter",
        pathnames: [],
      },
      {
        name: "List Subscriptions",
        originPathname: "/subscriptions",
        pathnames: [],
      },
    ],
  },
  // customers
  {
    kind: "Customers",
    icon: CustomersBlack,
    show: true,
    values: [
      {
        name: " Customers List",
        originPathname: "/customers",
        pathnames: ["/customers", "/create-customer", "/modify-customer"],
      },
      {
        name: "Customers Group",
        originPathname: "/group",
        pathnames: ["/group", "/add-customer-group"],
      },
    ],
  },
  // Store settings
  {
    kind: "Store settings",
    icon: StoreSettings2,
    show: true,
    values: [
      {
        name: "List of addresses",
        originPathname: "/setting-address",
        pathnames: ["/setting-address", "/setting-add-address"],
      },
      {
        name: "Home Page",
        originPathname: "/settings-home",
        pathnames: [],
      },
      {
        name: "Country & Currency",
        originPathname: "/country-currency",
        pathnames: ["/country-currency", "/country-currency-form"],
      },
      {
        name: "Translate",
        originPathname: "/translate",
        pathnames: ["/translate", "/edit-translate"],
      },
      {
        name: "Legality",
        originPathname: "/legality",
        pathnames: ["/legality", "/legality-form"],
      },
      {
        name: "Pickup & delivery",
        originPathname: "/delivery",
        pathnames: ["/delivery", "/add-delivery", "/edit-delivery"],
      },
      {
        name: "Plans",
        originPathname: "/plans",
        pathnames: ["/plans", "/plans-form"],
      },
    ],
  },
];

// ############################################################################################
// ########################## LIST PATHNAME INITIAL FOR RAWI #################################
// ##########################################################################################

export const listPathnameInitialRawi: ListPathnameInitialPayload[] = [
  // Content management
  {
    kind: "Content management",
    icon: Content,
    show: true,
    values: [
      {
        name: "Products",
        originPathname: "/product-type",
        pathnames: ["/product-type", "/add-book"],
      },
      {
        name: "Categories",
        originPathname: "/categories",
        pathnames: ["/categories", "/create-category", "/edit-category"],
      },
      {
        name: "Writers",
        originPathname: "/writers",
        pathnames: [],
      },
      {
        name: "Readers",
        originPathname: "/readers",
        pathnames: [""],
      },
    ],
  },
  // Stories management
  {
    kind: "Stories management",
    icon: Stories,
    show: true,
    values: [
      {
        name: "Stories",
        originPathname: "/stories",
        pathnames: [],
      },
    ],
  },
  // Staff management
  {
    kind: "Staff management",
    icon: StaffManagement,
    show: true,
    values: [
      {
        name: "Roles",
        originPathname: "/role-list",
        pathnames: ["/role-list", "/role-form"],
      },
      {
        name: "Staff Members",
        originPathname: "/members",
        pathnames: [],
      },
    ],
  },
  // Bundle management
  {
    kind: "Bundle management",
    icon: BundleManagement,
    show: true,
    values: [
      // {
      //   name: "Subscription",
      //   originPathname: "/subscription",
      //   pathnames: ["/create-subscription", "/edit-subscription"],
      // },
      {
        name: "Subscription",
        originPathname: "/plans",
        pathnames: ["/plans", "/plans-form"],
      },
    ],
  },
  // Store settings
  {
    kind: "Store settings",
    icon: StoreSettings2,
    show: true,
    values: [
      {
        name: "General Settings",
        originPathname: "/general-settings",
        pathnames: [],
      },

      {
        name: "Legality",
        originPathname: "/legality",
        pathnames: ["/legality", "/legality-form"],
      },
      {
        name: "Books Collections",
        originPathname: "/settings-home-rawi",
        pathnames: [],
      },
      // {
      //   name: "Plans",
      //   originPathname: "/plans",
      //   pathnames: ["/plans", "/plans-form"],
      // },
    ],
  },
];

// ############################################################################################
// ####################### LIST PATHNAME INITIAL CWALLET #####################################
// ##########################################################################################

export const listPathnameInitialCwallet: ListPathnameInitialPayload[] = [
  // Products management
  {
    kind: "Products management",
    icon: Content,
    show: true,
    values: [
      {
        name: "Products",
        originPathname: "/product-type",
        pathnames: ["/product-type", "/add-product-type", "/edit-product-type"],
      },
      {
        name: "Categories",
        originPathname: "/categories",
        pathnames: ["/categories", "/create-category", "/edit-category"],
      },
      {
        name: "Stores",
        originPathname: "/stores-type",
        pathnames: ["/stores-type", "/add-stores-type", "/edit-stores-type"],
      },
    ],
  },
  // Sales management
  {
    kind: "Sales management",
    icon: SalesBlack,
    show: true,
    values: [
      {
        name: "Orders",
        originPathname: "/orders-list",
        pathnames: ["/orders-list", "/order"],
      },
    ],
  },
  // Staff management
  {
    kind: "Staff management",
    icon: RoleBlack,
    show: true,
    values: [
      {
        name: "Roles",
        originPathname: "/role-list",
        pathnames: ["/role-list", "/role-form"],
      },
      {
        name: "Staff Members",
        originPathname: "/members",
        pathnames: [],
      },
      {
        name: "Vendor",
        originPathname: "/vendor",
        pathnames: [],
      },
    ],
  },
  // customers
  {
    kind: "Customers",
    icon: CustomersBlack,
    show: true,
    values: [
      {
        name: " Customers List",
        originPathname: "/customers",
        pathnames: ["/customers", "/create-customer", "/modify-customer"],
      },
      {
        name: "Customers Group",
        originPathname: "/group",
        pathnames: ["/group", "/add-customer-group"],
      },
    ],
  },
  // Store settings
  {
    kind: "Store settings",
    icon: StoreSettings2,
    show: true,
    values: [
      {
        name: "Home Page",
        originPathname: "/settings-home-cshop",
        pathnames: [],
      },
    ],
  },
];

// ############################################################################################
// ######################## LIST PATHNAME INITIAL FOR MEIZ ###############################
// ##########################################################################################

export const listPathnameInitialMeiz: ListPathnameInitialPayload[] = [
  // Sales management
  {
    kind: "Sales management",
    icon: Sales,
    show: true,
    values: [
      {
        name: "Orders",
        originPathname: "/orders-list",
        pathnames: ["/orders-list", "/order"],
      },
      {
        name: "Calendar",
        originPathname: "/calendar",
        pathnames: [],
      },
    ],
  },
  // Product management
  {
    kind: "Product management",
    icon: Product,
    show: true,
    values: [
      {
        name: "Products",
        originPathname: "/product",
        pathnames: ["/product", "/managment-product"],
      },
      {
        name: "Categories",
        originPathname: "/categories",
        pathnames: ["/categories", "/create-category", "/edit-category"],
      },
      {
        name: "Inventory",
        originPathname: "/inventory",
        pathnames: [],
      },
      {
        name: "Coupon",
        originPathname: "/promo-code",
        pathnames: ["/promo-code", "/add-promo-code"],
      },
    ],
  },
  // Staff management
  {
    kind: "Staff management",
    icon: Role,
    show: true,
    values: [
      {
        name: "Roles",
        originPathname: "/role-list",
        pathnames: ["/role-list", "/role-form"],
      },
      {
        name: "Staff Members",
        originPathname: "/members",
        pathnames: [],
      },
      {
        name: "Vendor",
        originPathname: "/vendor",
        pathnames: [],
      },
    ],
  },
  // customers
  {
    kind: "Customers",
    icon: Customers,
    show: true,
    values: [
      {
        name: " Customers List",
        originPathname: "/customers",
        pathnames: ["/customers", "/create-customer", "/modify-customer"],
      },
      {
        name: "Customers Group",
        originPathname: "/group",
        pathnames: ["/group", "/add-customer-group"],
      },
    ],
  },
  // Store settings
  {
    kind: "Store settings",
    icon: StoreSettings,
    show: true,
    values: [
      {
        name: "List of addresses",
        originPathname: "/setting-address",
        pathnames: ["/setting-address", "/setting-add-address"],
      },
      {
        name: "Home Page",
        originPathname: "/settings-home",
        pathnames: [],
      },
      {
        name: "Payment",
        originPathname: "/payment",
        pathnames: [],
      },
      {
        name: "General Settings",
        originPathname: "/general-settings",
        pathnames: [],
      },
      {
        name: "Translate",
        originPathname: "/translate",
        pathnames: ["/translate", "/edit-translate"],
      },
      {
        name: "Store menu",
        originPathname: "/store-menu",
        pathnames: ["/store-menu", "/add-store-menu", "/edit-store-menu"],
      },
      {
        name: "Legality",
        originPathname: "/legality",
        pathnames: ["/legality", "/legality-form"],
      },
      {
        name: "Pickup & delivery",
        originPathname: "/delivery",
        pathnames: ["/delivery", "/add-delivery", "/edit-delivery"],
      },
    ],
  },
];
