import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .bold {
    font-weight: 700;
    margin-bottom: 15px;
  }
  .bold-h3 {
    font-weight: 700;
    margin-bottom: 6px;
  }
  .list-card {
    height: calc(100vh - 180px);
    border-right: 1px solid #edeff2;
    padding-right: 15px;
    margin-right: 15px;
    overflow-x: auto;
  }
  .description {
    max-width: 900px;
  }
  .btn-action {
    margin-top: 10px;
  }
`;
