import gql from "graphql-tag";

export const GET_MENU_NAVIGATION = gql`
  query GetMenuNavigation($input: GetMenuNavigationInput!) {
    getMenuNavigation(input: $input) {
      list {
        id
        label
        order
        styles {
          kind
          value
        }
        navigation {
          id
          kind
          order
          label
          linkTo
          text
          isHierarchical
          styles {
            kind
            value
          }
        }
      }
    }
  }
`;

export const SET_NAVIGATION = gql`
  mutation SetNavigation($input: SetNavigationInput!) {
    setNavigation(input: $input) {
      success
    }
  }
`;

export const REMOVE_MENU_NAVIGATION = gql`
  mutation RemoveMenuNavigation($input: RemoveMenuNavigationInput!) {
    removeMenuNavigation(input: $input) {
      success
    }
  }
`;

export const SET_ORDER_NAVIGATION = gql`
  mutation setOrderNavigation($input: SetOrderNavigationInput!) {
    setOrderNavigation(input: $input) {
      success
    }
  }
`;
