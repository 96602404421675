/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, Checkbox, Fab, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import {
  GETLISTMENUCATEGORIES,
  CREATEFILTERCOLLECTION,
  GETLISTSUBCATEGORIES,
  GETMENUPRODUCTOPTIONS,
  GETLISTFILTERCOLLECTIONS,
} from "../../../api";
import {
  CreateFilterCollectionMutation,
  CreateFilterCollectionMutationVariables,
  CreateFilterCollectionInput,
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  GetListSubCategoriesQuery,
  GetListSubCategoriesQueryVariables,
  GetMenuProductOptionsQuery,
  GetMenuProductOptionsQueryVariables,
  CategoryIdInput,
  // FilterCollectionVariantInput,
  GetFilterCollectionPreviewInput,
} from "../../../api/types";
import { SelectedMenuVar } from "../../../api/local-state";
import { Wrapper, Note } from "./AddCollectionStyle";
import {
  Box,
  InputSimple,
  InputTagAutoComplete,
  DatePicker,
  Select,
} from "../../../commons";
import { ModalPreviewCollection } from "../../../components";
import {
  apparence,
  category,
  byProduct,
  variations,
  price,
  calendar,
} from "../../../img";

const AddCollection: React.FC = () => {
  const [show, setShow] = useState(true);
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  // GET SELECTED MENU FROM LOCAL STATE
  const menu = SelectedMenuVar();

  const [dataCollections, setDataCollections] =
    useState<CreateFilterCollectionInput>({
      token: localStorage.getItem("token") || "",
      menu: menu?.id || "",
      // variants: [],
    });

  const [dataCategories, setDataCategories] = useState<CategoryIdInput[]>([]);
  const [dataSubCategories, setDataSubCategories] = useState<CategoryIdInput[]>(
    []
  );
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");
  const [checkedCategory, setCheckedCategory] = useState(false);
  const [checkedSubCategory, setCheckedSubCategory] = useState(false);
  const [checkedProduct, setCheckedProduct] = useState(false);
  const [checkedVariation, setCheckedVariation] = useState<boolean[]>([false]);
  const [checkedPrice, setCheckedPrice] = useState(false);
  const [checkedCalendar, setCheckedCalendar] = useState(false);
  const [arrayVariants, setArrayVariants] = useState<
    {
      kind: string;
      values: string[];
    }[]
  >([]);
  const [openModal, setOpenModal] = useState(false);
  const [filterCollectionPreview, setFilterCollectionPreview] =
    useState<GetFilterCollectionPreviewInput>({
      token,
      menu: menu?.id || "",
      languageCode: "EN",
    });

  const intl = useIntl();

  // Query get list menu categories
  const { data: dataListCategories } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: menu?.id || "" } },
    skip: !checkedCategory, // skip if categpry is not checked
  });

  const categories = dataCategories?.map((item) => item.category);

  // Query get list menu sub categories
  const { data: dataListSubCategories } = useQuery<
    GetListSubCategoriesQuery,
    GetListSubCategoriesQueryVariables
  >(GETLISTSUBCATEGORIES, {
    variables: {
      token,
      categories: categories as string[],
    },
    skip: !checkedSubCategory,
  });

  // Query get list options
  const { data: dataListVariationOptions } = useQuery<
    GetMenuProductOptionsQuery,
    GetMenuProductOptionsQueryVariables
  >(GETMENUPRODUCTOPTIONS, {
    variables: {
      menu: menu?.id || "",
    },
    skip: !checkedVariation[0],
  });

  // Query get list product by menu and categories
  // const { data: dataProductsList } = useQuery<
  //   GetListMenuProductsQuery,
  //   GetListMenuProductsQueryVariables
  // >(GET_LIST_MENU_PRODUCTS, {
  //   variables: {
  //     input: {
  //       token: token,
  //       languageCode: "EN",
  //       menus: [menu?.id!],
  //       categories: [],
  //     },
  //   },
  //   skip: !checkedProduct,
  // });

  const addVaraints = () => {
    setCheckedVariation((prevCheckedVariation) => {
      const variantsArray = [...prevCheckedVariation];
      if (dataListVariationOptions?.getMenuProductOptions?.options) {
        if (
          dataListVariationOptions?.getMenuProductOptions?.options.length >
          variantsArray?.length
        ) {
          variantsArray.push(false);
        }
      }
      return variantsArray;
    });
  };

  // mutation create filter collection
  const [createFilterCollection, { loading }] = useMutation<
    CreateFilterCollectionMutation,
    CreateFilterCollectionMutationVariables
  >(CREATEFILTERCOLLECTION, {
    refetchQueries: () => [
      {
        query: GETLISTFILTERCOLLECTIONS,
        variables: {
          token,
          menu: menu?.id || "",
        },
      },
    ],
    onCompleted: () => {
      history.push("/appearance");
    },
  });

  {
    /* it's a comment because there is a change from api */
  }

  // const arrayListVariants: FilterCollectionVariantInput[] = [];

  // arrayVariants.map((item) => {
  //   return item.values.map((value) =>
  //     arrayListVariants.push({ kind: item.kind, value })
  //   );
  // });

  // useEffect(() => {
  //   setDataCollections({
  //     ...dataCollections,
  //     categories:
  //       dataSubCategories.length > 0 ? dataSubCategories : dataCategories,
  //     variants: arrayListVariants,
  //     priceRange: {
  //       max: parseFloat(maxPrice),
  //       min: parseFloat(minPrice),
  //     },
  //   });
  // }, [arrayVariants, dataSubCategories, dataCategories, maxPrice, minPrice]);

  const handleSubmit = () => {
    createFilterCollection({
      variables: {
        input: dataCollections,
      },
    });
  };

  {
    /* it's a comment because there is a change from api */
  }

  // const handlePreview = () => {
  //   setOpenModal(true);
  //   setFilterCollectionPreview({
  //     ...filterCollectionPreview,
  //     categories: dataCollections.categories,
  //     products: dataCollections.products,
  //     maxItems: dataCollections.maxItems,
  //     sorting: dataCollections.sorting,
  //     priceRange: dataCollections.priceRange,
  //     variants: dataCollections.variants,
  //     options: dataCollections.options,
  //   });
  // };

  return (
    <Wrapper>
      <Note>
        <div className="title">
          <img src={apparence} alt="apparence" />
          {intl.formatMessage({ id: "ProductForm.ProductManagement" })}
          &nbsp;| &nbsp;
          <span>
            {intl.formatMessage({ id: "collection.createCollection" })}
          </span>
        </div>
        <div className="action">
          <Button
            variant="contained"
            color="primary"
            className="btn-action"
            // onClick={handlePreview}
          >
            {intl.formatMessage({ id: "appearance.seeResult" })}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="btn-action"
            disabled={loading}
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: "appearance.save" })}

            {loading && (
              <CircularProgress
                size={24}
                style={{ position: "absolute" }}
                color="secondary"
              />
            )}
          </Button>
        </div>
      </Note>
      <Box
        p={20}
        width={1}
        className={`container-1 ${!show && "container-1__hidden"}`}
      >
        <div className="header">
          <span>Creation d&apos;une Collection</span>
          <div
            role="button"
            tabIndex={0}
            onKeyUp={() => setShow(!show)}
            onClick={() => setShow(!show)}
          >
            <i className={`arrow ${show && "down"}`} />
          </div>
        </div>
        {show && (
          <div className="form">
            <div className="row">
              <InputSimple
                label="Nom"
                fullWidth
                value={dataCollections?.names?.[0]?.value || ""}
                onChange={(e) => {
                  setDataCollections({
                    ...dataCollections,
                    names: [
                      {
                        languageCode: "EN",
                        value: e.target.value,
                      },
                    ],
                  });
                }}
              />
              <InputSimple
                label="Description"
                fullWidth
                value={dataCollections?.descriptions?.[0]?.value || ""}
                onChange={(e) => {
                  setDataCollections({
                    ...dataCollections,
                    descriptions: [
                      {
                        languageCode: "EN",
                        value: e.target.value,
                      },
                    ],
                  });
                }}
              />
            </div>
            {/* row for categories */}
            <div className="row__items">
              <div className="name">
                <div className="img-option">
                  <img src={category} alt="category" />
                </div>
                <span>Par catégorie </span>
              </div>
              <div className="items">
                <div className="item-1">
                  <div className="checkbox-1">
                    <Checkbox
                      checked={checkedCategory}
                      onChange={() => setCheckedCategory(!checkedCategory)}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <div
                      className={`dashed ${checkedCategory && "dashed-show"}`}
                    />
                  </div>
                  <div
                    className={`checkbox-2 ${
                      !checkedCategory && "checkbox-2-hidden"
                    }`}
                  >
                    <Checkbox
                      checked={checkedSubCategory}
                      onChange={() =>
                        setCheckedSubCategory(!checkedSubCategory)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      disabled={!checkedCategory}
                    />
                  </div>
                </div>
                <div className="item-2">
                  <div className="input-tag-1">
                    <InputTagAutoComplete
                      options={dataListCategories?.getListMenuCategories?.list}
                      inputValue={dataCategories}
                      onReturn={(list) => {
                        const arrayListId: CategoryIdInput[] = [];
                        list.map((id: string) =>
                          arrayListId.push({ category: id })
                        );
                        setDataCategories(arrayListId);
                      }}
                      disabled={checkedCategory}
                    />
                  </div>
                  <div
                    className={`input-tag-2 ${
                      !checkedCategory && "input-tag-2-hidden"
                    }`}
                  >
                    <span className="input-tag__span" style={{ minWidth: 130 }}>
                      Par Sous Categories
                    </span>
                    <InputTagAutoComplete
                      options={
                        dataListSubCategories?.getListMenuSubCategories?.list
                      }
                      inputValue={dataSubCategories}
                      onReturn={(list) => {
                        const arrayListId: CategoryIdInput[] = [];
                        list.map((id: string) =>
                          arrayListId.push({ category: id })
                        );
                        setDataSubCategories(arrayListId);
                      }}
                      disabled={checkedSubCategory}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* row for products */}
            <div className="row__items">
              <div className="name">
                <div className="img-option">
                  <img src={byProduct} alt="product" />
                </div>
                <span>Par produit </span>
              </div>
              <div className="items">
                <Checkbox
                  checked={checkedProduct}
                  onChange={() => setCheckedProduct(!checkedProduct)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <div style={{ marginLeft: 10, width: "100%" }}>
                  {/* <InputTagAutoComplete
                    options={dataProductsList?.getListMenuProducts?.list}
                    inputValue={[]}
                    onReturn={(list) => {
                      const arrayIds: ProductIdInput[] = [];
                      list?.map((id: string) => {
                        arrayIds.push({ product: id });
                      });
                      setDataCollections({
                        ...dataCollections,
                        products: arrayIds,
                      });
                    }}
                    disabled={checkedProduct}
                  /> */}
                </div>
              </div>
            </div>
            {/* row for variations */}
            <div className="row__items">
              <div className="name">
                <div className="img-option">
                  <img src={variations} alt="variation" />
                </div>
                <span>Par variation </span>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {checkedVariation.map((item, index) => (
                  <div
                    className="items"
                    key={index}
                    style={{ marginBottom: 30 }}
                  >
                    <div className="item-1">
                      <div className="checkbox-1">
                        <Checkbox
                          checked={checkedVariation[index]}
                          onChange={
                            () => {}
                            // setCheckedVariation((prevCheckedVariation) => {
                            //   const variantsArrayTemp = [
                            //     ...prevCheckedVariation,
                            //   ];
                            //   // ??????
                            //   arrayVariants[index] = !checkedVariation[index];
                            //   return variantsArrayTemp;
                            // })
                          }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <div
                          className={`dashed ${
                            checkedVariation[index] && "dashed-show"
                          }`}
                        />
                      </div>
                    </div>
                    <div className="item-2">
                      <div className="input-tag-1" style={{ minWidth: 548 }}>
                        <Select
                          value={arrayVariants[index]?.kind || ""}
                          onChange={(e) => {
                            setArrayVariants((prevArrayVariants) => {
                              const arrayData = [...prevArrayVariants];
                              arrayData[index] = {
                                kind: e.target.value as string,
                                values: [],
                              };
                              return arrayData;
                            });
                          }}
                          style={{ marginBottom: 0 }}
                          disabled={!checkedVariation[index]}
                        >
                          {dataListVariationOptions?.getMenuProductOptions?.options?.map(
                            (productOption, productOptionIndex) => (
                              <MenuItem
                                key={productOptionIndex}
                                value={productOption?.kind || ""}
                                disabled={
                                  _.findIndex(
                                    arrayVariants,
                                    (o) => o.kind === productOption?.kind
                                  ) !== -1
                                }
                              >
                                {productOption?.kind}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </div>
                      <div
                        className={`input-tag-2 ${
                          !checkedVariation[index] && "input-tag-2-hidden"
                        }`}
                        style={{ marginLeft: -20, minWidth: 548 }}
                      >
                        <span
                          className="input-tag__span"
                          style={{ minWidth: 50, fontSize: 15 }}
                        >
                          {arrayVariants[index]?.kind}
                        </span>
                        <InputTagAutoComplete
                          options={
                            dataListVariationOptions?.getMenuProductOptions
                              ?.options?.[
                              _.findIndex(
                                dataListVariationOptions?.getMenuProductOptions
                                  ?.options,
                                function (o) {
                                  return o.kind === arrayVariants[index]?.kind;
                                }
                              )
                            ]?.values || []
                          }
                          inputValue={[]}
                          onReturn={(list) => {
                            setArrayVariants((prevVariants) => {
                              const listVariants = [...prevVariants];
                              listVariants[index].values = list;
                              return listVariants;
                            });
                          }}
                          disabled={checkedVariation[index]}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="add-variantion">
              <Fab
                color="secondary"
                aria-label="add"
                onClick={addVaraints}
                disabled={!checkedVariation[0]}
              >
                <AddIcon />
              </Fab>
              Ajouter une variation
            </div>
            {/* row for price */}
            <div className="row__items">
              <div className="name">
                <div className="img-option">
                  <img src={price} alt="price" />
                </div>
                <span>Par prix </span>
              </div>
              <div className="items">
                <div className="item-1">
                  <div className="checkbox-1">
                    <Checkbox
                      checked={checkedPrice}
                      onChange={() => setCheckedPrice(!checkedPrice)}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <div
                      className={`dashed ${checkedPrice && "dashed-show"}`}
                    />
                  </div>
                </div>
                <div className="item-2">
                  <div className="input-tag-1" style={{ minWidth: 548 }}>
                    <InputSimple
                      value={minPrice}
                      onChange={(e) => {
                        setMinPrice(e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      disabled={!checkedPrice}
                      fullWidth
                      className="input-simple"
                    />
                  </div>
                  <div
                    className={`input-tag-2 ${
                      !checkedPrice && "input-tag-2-hidden"
                    }`}
                    style={{ marginLeft: -20, minWidth: 548 }}
                  >
                    <span className="input-tag__span" style={{ minWidth: 70 }}>
                      Jusqu&apos;a
                    </span>
                    <InputSimple
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                      style={{ marginBottom: 0 }}
                      disabled={!checkedPrice}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* row for calendar */}
            <div className="row__items">
              <div className="name">
                <div className="img-option">
                  <img src={calendar} alt="product" />
                </div>
                <span>Par date de publication </span>
              </div>
              <div className="items">
                <Checkbox
                  checked={checkedCalendar}
                  onChange={() => setCheckedCalendar(!checkedCalendar)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <div className="calendar">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={null}
                      fullWidth
                      placeholder="A partir"
                      disabled={!checkedCalendar}
                    />
                    <DatePicker
                      value={null}
                      fullWidth
                      placeholder="Jusqu'a"
                      disabled={!checkedCalendar}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
      <Box p={20} width={1} className="options">
        <h3> Options </h3>
        <div className="items-option">
          <div className="item-input">
            <span>Nombre maximal des résultats à affichés d'articles </span>
            <InputSimple
              value={dataCollections.maxItems || ""}
              onChange={(e) =>
                setDataCollections({
                  ...dataCollections,
                  maxItems: parseInt(e.target.value, 10),
                })
              }
            />
          </div>
          <div className="item-sort">
            <span style={{ marginBottom: 10 }}>Trier par</span>
            <div className="sort-by">
              <div
                role="button"
                tabIndex={0}
                className={`arrow ${
                  dataCollections.sorting === "NAME_ASC" && "arrow-selected"
                }`}
                onClick={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "NAME_ASC",
                  })
                }
                onKeyUp={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "NAME_ASC",
                  })
                }
              >
                <ArrowUpwardIcon fontSize="small" />
              </div>
              <div
                role="button"
                tabIndex={0}
                className={`arrow ${
                  dataCollections.sorting === "NAME_DESC" && "arrow-selected"
                }`}
                onClick={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "NAME_DESC",
                  })
                }
                onKeyUp={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "NAME_DESC",
                  })
                }
              >
                <ArrowDownwardIcon fontSize="small" />
              </div>
              <span> Par Nom </span>
            </div>
            <div className="sort-by">
              <div
                role="button"
                tabIndex={0}
                className={`arrow ${
                  dataCollections.sorting === "PRICE_ASC" && "arrow-selected"
                }`}
                onClick={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "PRICE_ASC",
                  })
                }
                onKeyUp={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "PRICE_ASC",
                  })
                }
              >
                <ArrowUpwardIcon fontSize="small" />
              </div>
              <div
                role="button"
                tabIndex={0}
                className={`arrow ${
                  dataCollections.sorting === "PRICE_DESC" && "arrow-selected"
                }`}
                onClick={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "PRICE_DESC",
                  })
                }
                onKeyUp={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "PRICE_DESC",
                  })
                }
              >
                <ArrowDownwardIcon fontSize="small" />
              </div>
              <span> Par Prix </span>
            </div>
            <div className="sort-by">
              <div
                role="button"
                tabIndex={0}
                className={`arrow ${
                  dataCollections.sorting === "DATE_ASC" && "arrow-selected"
                }`}
                onClick={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "DATE_ASC",
                  })
                }
                onKeyUp={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "DATE_ASC",
                  })
                }
              >
                <ArrowUpwardIcon fontSize="small" />
              </div>
              <div
                role="button"
                tabIndex={0}
                className={`arrow ${
                  dataCollections.sorting === "DATE_DESC" && "arrow-selected"
                }`}
                onClick={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "DATE_DESC",
                  })
                }
                onKeyUp={() =>
                  setDataCollections({
                    ...dataCollections,
                    sorting: "DATE_DESC",
                  })
                }
              >
                <ArrowDownwardIcon fontSize="small" />
              </div>
              <span> Par Date </span>
            </div>
          </div>
          <div className="item-select">
            <span>Sélectionner les options</span>
            <div>
              <Checkbox
                // checked={true}
                onChange={() => {}}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span>Most View</span>
            </div>
            <div>
              <Checkbox
                // checked={true}
                onChange={() => {}}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span>Last sales</span>
            </div>
            <div>
              <Checkbox
                // checked={true}
                onChange={() => {}}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span>Best Rated</span>
            </div>
            <div>
              <Checkbox
                // checked={true}
                onChange={() => {}}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span>Best sales</span>
            </div>
          </div>
        </div>
      </Box>
      <ModalPreviewCollection
        open={openModal}
        handleClose={() => setOpenModal(false)}
        dataInputFilterCollection={filterCollectionPreview}
      />
    </Wrapper>
  );
};

export default AddCollection;
