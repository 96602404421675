import * as React from "react";
import { useHistory } from "react-router-dom";
import { Box, Paper, Typography, LinearProgress } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useReactiveVar, useMutation } from "@apollo/client";

import { BreadCrumbsTitle, ButtonCustomize, Input } from "../../commons";
import { SnoonuLogin, DeliverySettings } from "../../components";

import {
  ADD_DELIVERY_SERVICE,
  GET_MENU_DELIVERY_SETTINGS,
  CHECK_SNOONU_CREDENTIALS,
} from "../../api";
import {
  AddDeliveryServiceMutation,
  AddDeliveryServiceMutationVariables,
  MenuPayload,
  AddDeliveryServiceApiAccessInput,
  AddDeliveryServiceMethodInput,
  AddDeliveryPackageInput,
  CheckSnoonuCredentialsMutation,
  CheckSnoonuCredentialsMutationVariables,
  DeliveryKind,
  CustomDeliveryInput,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

import { Wrapper } from "./AddDeliveryMethode-styles";

import { Snoonu } from "../../img/digishop/menu-icons";
import StoreSettings from "../../img/digishop/menu-icons/StoreSettings.svg";

function getSteps() {
  return [
    "Select your desired delivery services",
    // "Connect to snoonu delivery",
    "Set up Snoonu Delivery",
    "Set up Free Delivery",
  ];
}

const AddDeliveryMethode = (): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(1);
  const [progress, setProgress] = React.useState(0);
  const [deliveryMethods, setDeliveryMethods] = React.useState<
    AddDeliveryServiceMethodInput[]
  >([]);
  const [deliveryPackage, setDeliveryPackage] =
    React.useState<AddDeliveryPackageInput>();
  const [apiAccess, setApiAccess] =
    React.useState<AddDeliveryServiceApiAccessInput>();
  const [nameDelivery, setNameDelivery] = React.useState<string>("");
  const [nameDeliveryError, setNameDeliveryError] = React.useState<string>("");
  const [kindDelivery, setKindDelivery] =
    React.useState<DeliveryKind>("SNOONU");
  const [stepDeliverySetting, setStepDeliverySetting] =
    React.useState<boolean>(false);
  const [customDelivery, setCustomDelivery] =
    React.useState<CustomDeliveryInput>();

  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  React.useEffect(() => {
    setProgress((100 / 2) * activeStep);
  }, [activeStep]);

  const [AddDeliverly] = useMutation<
    AddDeliveryServiceMutation,
    AddDeliveryServiceMutationVariables
  >(ADD_DELIVERY_SERVICE, {
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Operation successful",
      });
      history.push("/delivery");
    },
    refetchQueries: () => [
      {
        query: GET_MENU_DELIVERY_SETTINGS,
        variables: {
          input: {
            token,
            menu: localMenu?.id || "",
          },
        },
      },
    ],
  });

  const [CheckSnoonu, { loading }] = useMutation<
    CheckSnoonuCredentialsMutation,
    CheckSnoonuCredentialsMutationVariables
  >(CHECK_SNOONU_CREDENTIALS, {
    onError: (error) => {
      if (error.message === "invalid_credentials") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Invalid credentials",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
    onCompleted: () => {
      setStepDeliverySetting(true);
    },
  });

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameDelivery(event.target.value);
    setNameDeliveryError("");
  };

  const handleSave = () => {
    AddDeliverly({
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          kind: kindDelivery,
          name: kindDelivery === "SNOONU" ? "Snoonu" : nameDelivery,
          deliveryMethods,
          deliveryPackage,
          customDelivery,
          apiAccess,
        },
      },
    });
  };

  return (
    <Wrapper>
      <BreadCrumbsTitle
        labelIntl={["Store settings", "Add new services delivery method"]}
        icon={StoreSettings}
        iconAlt="translate"
      />
      <ButtonCustomize className="back" onClick={() => history.goBack()}>
        <KeyboardBackspaceIcon /> Return
      </ButtonCustomize>
      <Box component={Paper} marginTop={2} p={2} height="calc(100vh - 160px)">
        <Box maxWidth={950}>
          <Typography variant="h2" className="bold">
            Select delivery services
          </Typography>
          {/* Stepper */}
          <Box marginTop={1} marginBottom={2}>
            <Box display="flex">
              <Typography variant="subtitle1" className="details-stepper">
                Step {activeStep} of 2.&nbsp;
              </Typography>
              <Typography variant="subtitle1">
                {kindDelivery === "SNOONU" ? steps[activeStep - 1] : steps[2]}
              </Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              value={progress}
              color="secondary"
            />
          </Box>
          {/* list card delivery method */}
          {activeStep === 1 && (
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="card"
              >
                <Box display="flex" alignItems="center">
                  <Snoonu />
                  <Box marginLeft={2}>
                    <Typography
                      variant="subtitle1"
                      className="bold"
                      style={{ marginBottom: 4 }}
                    >
                      Snoonu
                    </Typography>
                    <Typography variant="subtitle2">
                      Set up Snoonu to enable 1 delivery methods.
                    </Typography>
                  </Box>
                </Box>
                <ButtonCustomize
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setActiveStep(2);
                    setKindDelivery("SNOONU");
                  }}
                  style={{
                    width: 120,
                  }}
                >
                  Setup Snoonu
                </ButtonCustomize>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="card"
                marginTop={2}
              >
                <Box display="flex" flex={1} alignItems="center">
                  <Box flex={1} maxWidth={700}>
                    <Typography
                      variant="subtitle1"
                      className="bold"
                      style={{ marginBottom: 4 }}
                    >
                      Custom carrier
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: 10 }}
                    >
                      Enter the name of the carrier that you would like to set
                      up.
                    </Typography>
                    <Input
                      label="Carrier name"
                      value={nameDelivery}
                      onChange={handleName}
                      msgError={nameDeliveryError}
                    />
                  </Box>
                </Box>
                <ButtonCustomize
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (nameDelivery && !nameDeliveryError) {
                      setActiveStep(2);
                      setStepDeliverySetting(true);
                      setKindDelivery("CUSTOM");
                    } else {
                      setNameDeliveryError("This field is required");
                    }
                  }}
                  style={{
                    width: 120,
                  }}
                >
                  Setup
                </ButtonCustomize>
              </Box>
            </Box>
          )}
          {/* snoonu login */}
          {activeStep === 2 && (
            <>
              {!stepDeliverySetting ? (
                <SnoonuLogin
                  loading={loading}
                  onReturn={(email, password) => {
                    email &&
                      password &&
                      CheckSnoonu({
                        variables: {
                          input: {
                            token,
                            username: email,
                            password,
                          },
                        },
                      });
                    setApiAccess({
                      username: email,
                      pass: password,
                    });
                  }}
                />
              ) : (
                // Delivery settings
                <DeliverySettings
                  currency={localMenu?.currency?.code || ""}
                  kind={kindDelivery}
                  nameDelivery={nameDelivery}
                  onRetrun={(name, methods, dataPackage, custom) => {
                    name && setNameDelivery(name);
                    setDeliveryPackage(dataPackage);
                    if (kindDelivery === "SNOONU")
                      methods && setDeliveryMethods(methods);
                    else {
                      setCustomDelivery(custom);
                    }
                  }}
                  onSave={handleSave}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AddDeliveryMethode;
