export const getDtoneListCountries = [
  {
    isoCode: "AFG",
    name: "Afghanistan",
  },
  {
    isoCode: "ALB",
    name: "Albania",
  },
  {
    isoCode: "DZA",
    name: "Algeria",
  },
  {
    isoCode: "ASM",
    name: "American Samoa",
  },
  {
    isoCode: "AGO",
    name: "Angola",
  },
  {
    isoCode: "AIA",
    name: "Anguilla",
  },
  {
    isoCode: "ATG",
    name: "Antigua and Barbuda",
  },
  {
    isoCode: "ARG",
    name: "Argentina",
  },
  {
    isoCode: "ARM",
    name: "Armenia",
  },
  {
    isoCode: "ABW",
    name: "Aruba",
  },
  {
    isoCode: "BHS",
    name: "Bahamas",
  },
  {
    isoCode: "BHR",
    name: "Bahrain",
  },
  {
    isoCode: "BGD",
    name: "Bangladesh",
  },
  {
    isoCode: "BRB",
    name: "Barbados",
  },
  {
    isoCode: "BLR",
    name: "Belarus",
  },
  {
    isoCode: "BEL",
    name: "Belgium",
  },
  {
    isoCode: "BLZ",
    name: "Belize",
  },
  {
    isoCode: "BEN",
    name: "Benin",
  },
  {
    isoCode: "BMU",
    name: "Bermuda",
  },
  {
    isoCode: "BOL",
    name: "Bolivia",
  },
  {
    isoCode: "BWA",
    name: "Botswana",
  },
  {
    isoCode: "BRA",
    name: "Brazil",
  },
  {
    isoCode: "VGB",
    name: "British Virgin Islands",
  },
  {
    isoCode: "BFA",
    name: "Burkina Faso",
  },
  {
    isoCode: "BDI",
    name: "Burundi",
  },
  {
    isoCode: "KHM",
    name: "Cambodia",
  },
  {
    isoCode: "CMR",
    name: "Cameroon",
  },
  {
    isoCode: "CAN",
    name: "Canada",
  },
  {
    isoCode: "CPV",
    name: "Cape Verde",
  },
  {
    isoCode: "BES",
    name: "Caribbean Netherlands",
  },
  {
    isoCode: "CYM",
    name: "Cayman Islands",
  },
  {
    isoCode: "CAF",
    name: "Central African Republic",
  },
  {
    isoCode: "CHL",
    name: "Chile",
  },
  {
    isoCode: "CHN",
    name: "China",
  },
  {
    isoCode: "COL",
    name: "Colombia",
  },
  {
    isoCode: "COM",
    name: "Comoros",
  },
  {
    isoCode: "CRI",
    name: "Costa Rica",
  },
  {
    isoCode: "CYP",
    name: "Cyprus",
  },
  {
    isoCode: "DMA",
    name: "Dominica",
  },
  {
    isoCode: "DOM",
    name: "Dominican Republic",
  },
  {
    isoCode: "COD",
    name: "DR Congo",
  },
  {
    isoCode: "ECU",
    name: "Ecuador",
  },
  {
    isoCode: "EGY",
    name: "Egypt",
  },
  {
    isoCode: "SLV",
    name: "El Salvador",
  },
  {
    isoCode: "SWZ",
    name: "Eswatini",
  },
  {
    isoCode: "ETH",
    name: "Ethiopia",
  },
  {
    isoCode: "FJI",
    name: "Fiji",
  },
  {
    isoCode: "FRA",
    name: "France",
  },
  {
    isoCode: "GUF",
    name: "French Guiana",
  },
  {
    isoCode: "GMB",
    name: "Gambia",
  },
  {
    isoCode: "GEO",
    name: "Georgia",
  },
  {
    isoCode: "DEU",
    name: "Germany",
  },
  {
    isoCode: "GHA",
    name: "Ghana",
  },
  {
    isoCode: "GRC",
    name: "Greece",
  },
  {
    isoCode: "GRD",
    name: "Grenada",
  },
  {
    isoCode: "GTM",
    name: "Guatemala",
  },
  {
    isoCode: "GIN",
    name: "Guinea",
  },
  {
    isoCode: "GNB",
    name: "Guinea-Bissau",
  },
  {
    isoCode: "GUY",
    name: "Guyana",
  },
  {
    isoCode: "HTI",
    name: "Haiti",
  },
  {
    isoCode: "IND",
    name: "India",
  },
  {
    isoCode: "IDN",
    name: "Indonesia",
  },
  {
    isoCode: "IRQ",
    name: "Iraq",
  },
  {
    isoCode: "ITA",
    name: "Italy",
  },
  {
    isoCode: "CIV",
    name: "Ivory Coast",
  },
  {
    isoCode: "JPN",
    name: "Japan",
  },
  {
    isoCode: "JOR",
    name: "Jordan",
  },
  {
    isoCode: "KAZ",
    name: "Kazakhstan",
  },
  {
    isoCode: "KEN",
    name: "Kenya",
  },
  {
    isoCode: "UNK",
    name: "Kosovo",
  },
  {
    isoCode: "KWT",
    name: "Kuwait",
  },
  {
    isoCode: "KGZ",
    name: "Kyrgyzstan",
  },
  {
    isoCode: "LAO",
    name: "Laos",
  },
  {
    isoCode: "LBN",
    name: "Lebanon",
  },
  {
    isoCode: "LBR",
    name: "Liberia",
  },
  {
    isoCode: "LTU",
    name: "Lithuania",
  },
  {
    isoCode: "LUX",
    name: "Luxembourg",
  },
  {
    isoCode: "MDG",
    name: "Madagascar",
  },
  {
    isoCode: "MWI",
    name: "Malawi",
  },
  {
    isoCode: "MYS",
    name: "Malaysia",
  },
  {
    isoCode: "MLI",
    name: "Mali",
  },
  {
    isoCode: "MTQ",
    name: "Martinique",
  },
  {
    isoCode: "MEX",
    name: "Mexico",
  },
  {
    isoCode: "MDA",
    name: "Moldova",
  },
  {
    isoCode: "MNG",
    name: "Mongolia",
  },
  {
    isoCode: "MSR",
    name: "Montserrat",
  },
  {
    isoCode: "MAR",
    name: "Morocco",
  },
  {
    isoCode: "MOZ",
    name: "Mozambique",
  },
  {
    isoCode: "MMR",
    name: "Myanmar",
  },
  {
    isoCode: "NAM",
    name: "Namibia",
  },
  {
    isoCode: "NRU",
    name: "Nauru",
  },
  {
    isoCode: "NPL",
    name: "Nepal",
  },
  {
    isoCode: "NLD",
    name: "Netherlands",
  },
  {
    isoCode: "NIC",
    name: "Nicaragua",
  },
  {
    isoCode: "NER",
    name: "Niger",
  },
  {
    isoCode: "NGA",
    name: "Nigeria",
  },
  {
    isoCode: "OMN",
    name: "Oman",
  },
  {
    isoCode: "PAK",
    name: "Pakistan",
  },
  {
    isoCode: "PSE",
    name: "Palestine",
  },
  {
    isoCode: "PAN",
    name: "Panama",
  },
  {
    isoCode: "PNG",
    name: "Papua New Guinea",
  },
  {
    isoCode: "PRY",
    name: "Paraguay",
  },
  {
    isoCode: "PER",
    name: "Peru",
  },
  {
    isoCode: "PHL",
    name: "Philippines",
  },
  {
    isoCode: "POL",
    name: "Poland",
  },
  {
    isoCode: "PRT",
    name: "Portugal",
  },
  {
    isoCode: "PRI",
    name: "Puerto Rico",
  },
  {
    isoCode: "QAT",
    name: "Qatar",
  },
  {
    isoCode: "COG",
    name: "Republic of the Congo",
  },
  {
    isoCode: "REU",
    name: "Réunion",
  },
  {
    isoCode: "ROU",
    name: "Romania",
  },
  {
    isoCode: "RUS",
    name: "Russia",
  },
  {
    isoCode: "RWA",
    name: "Rwanda",
  },
  {
    isoCode: "KNA",
    name: "Saint Kitts and Nevis",
  },
  {
    isoCode: "LCA",
    name: "Saint Lucia",
  },
  {
    isoCode: "VCT",
    name: "Saint Vincent and the Grenadines",
  },
  {
    isoCode: "WSM",
    name: "Samoa",
  },
  {
    isoCode: "SAU",
    name: "Saudi Arabia",
  },
  {
    isoCode: "SEN",
    name: "Senegal",
  },
  {
    isoCode: "SLE",
    name: "Sierra Leone",
  },
  {
    isoCode: "SGP",
    name: "Singapore",
  },
  {
    isoCode: "SXM",
    name: "Sint Maarten",
  },
  {
    isoCode: "ZAF",
    name: "South Africa",
  },
  {
    isoCode: "KOR",
    name: "South Korea",
  },
  {
    isoCode: "ESP",
    name: "Spain",
  },
  {
    isoCode: "LKA",
    name: "Sri Lanka",
  },
  {
    isoCode: "SUR",
    name: "Suriname",
  },
  {
    isoCode: "CHE",
    name: "Switzerland",
  },
  {
    isoCode: "TJK",
    name: "Tajikistan",
  },
  {
    isoCode: "TZA",
    name: "Tanzania",
  },
  {
    isoCode: "THA",
    name: "Thailand",
  },
  {
    isoCode: "TGO",
    name: "Togo",
  },
  {
    isoCode: "TON",
    name: "Tonga",
  },
  {
    isoCode: "TTO",
    name: "Trinidad and Tobago",
  },
  {
    isoCode: "TUN",
    name: "Tunisia",
  },
  {
    isoCode: "TUR",
    name: "Turkey",
  },
  {
    isoCode: "TCA",
    name: "Turks and Caicos Islands",
  },
  {
    isoCode: "UGA",
    name: "Uganda",
  },
  {
    isoCode: "UKR",
    name: "Ukraine",
  },
  {
    isoCode: "ARE",
    name: "United Arab Emirates",
  },
  {
    isoCode: "GBR",
    name: "United Kingdom",
  },
  {
    isoCode: "USA",
    name: "United States",
  },
  {
    isoCode: "URY",
    name: "Uruguay",
  },
  {
    isoCode: "UZB",
    name: "Uzbekistan",
  },
  {
    isoCode: "VUT",
    name: "Vanuatu",
  },
  {
    isoCode: "VEN",
    name: "Venezuela",
  },
  {
    isoCode: "VNM",
    name: "Vietnam",
  },
  {
    isoCode: "YEM",
    name: "Yemen",
  },
  {
    isoCode: "ZMB",
    name: "Zambia",
  },
  {
    isoCode: "ZWE",
    name: "Zimbabwe",
  },
];
