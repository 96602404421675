import styled from "styled-components";

export const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .addressError {
    color: #f67070;
    font-weight: 500;
  }
  .MuiFab-root {
    color: #fff;
    background-color: #f29f05;
    box-shadow: none;
    width: 30px;
    height: 30px;
    min-height: 30px;
  }
  .MuiFab-root:hover {
    background-color: #ffa805;
  }
  .MuiFab-root:active {
    box-shadow: none;
  }
  .customer__form {
    max-width: 750px;
  }
  .container {
    .vector {
      position: relative;
      display: flex;
      min-width: 220px;
      height: 200px;
      justify-content: center;
      align-items: center;
      .img-vector {
        position: absolute;
        width: 150px;
        z-index: 9;
      }
      .img-apparence {
        width: 50px;
        position: absolute;
        z-index: 99;
      }
      .btn-fab-add {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 141px;
        z-index: 999;
      }
      .MuiFab-root.Mui-disabled {
        color: #fff;
        background-color: #f29f05;
      }
    }
    .customer__form-title {
      margin-bottom: 20px;
    }
    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 35px;
      .buttomRow {
        align-items: flex-start;
      }
      .row {
        display: flex;
        width: 650px;
        gap: 15px;
        padding: 0 20px 30px 20px;
        .phoneInput {
          .MuiInputBase-root {
            height: 100%;
            .MuiSelect-root {
              width: 60px;
              padding-left: 10px;
            }
          }
          #standard-select-currency {
            height: 100%;
            width: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          border: 2px solid #f1f2ff;
          border-radius: 5px;
          .MuiTextField-root {
            width: 100px !important;
            .MuiInputBase-root {
              height: 100%;
              width: 100px;
              #country-select-demo {
                margin-top: 10px;
              }
            }
          }

          &:focus-within {
            border: 2px solid #000000;
          }
          &:focus {
            border: none;
          }
          .MuiFilledInput-root {
            &:focus {
              border: none;
            }
            &:focus-within {
              border: none;
            }
          }
          .MuiInputBase-input {
            border: none;

            &:focus {
              border: none;
            }
          }
          .Mui-error .mainInput {
            border: 2px solid #f67070;
          }
          .MuiFormControl-root {
            width: auto;
          }
          .MuiInputBase-root {
            border: none;
            width: auto;
            &:focus {
              border: none;
            }
            .MuiSelect-iconFilled {
              right: -8px;
              top: 14px;
            }
          }
        }
        .MuiSelect-root {
          padding: 8px 24px 8px 5px;
        }
        .addresse-countainer {
          flex: 1;
          flex-direction: column;
          gap: 15px;
          .addresse-countainer-header {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            p {
              margin: 0;
            }
          }
          .address-list {
            .MuiFormGroup-root {
              flex: 1;
              gap: 15px;
            }
            .address-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .btn-fab-delete {
                background-color: #f29f0510;
              }
            }
            .address-list-empty {
              .address-list-empty-message {
                margin: 0;
              }
            }
          }
          .MuiRadio-colorSecondary.Mui-checked {
            color: black;
          }
        }
        .emailInput {
          flex: 1;
        }
        .phoneInput {
          flex: 1;
        }
        .MuiFormControl-marginDense {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .action {
        justify-content: flex-end;
        align-items: center;
        .MuiButton-label {
          display: block;
          font-size: 16px;
          text-transform: lowercase;
          ::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
    .addressError {
      color: #f67070;
      font-weight: 500;
    }
  }
`;
