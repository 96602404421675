export const URL = process.env.REACT_APP_API_URL;
export const URL_PICTURES = process.env.REACT_APP_API_URL_PICTURES;
export const URL_PICTURE_CATEGORY =
  process.env.REACT_APP_API_URL_PICTURE_CATEGORY;
export const URL_THEME_PICTURES = process.env.REACT_APP_API_URL_THEME;
export const URL_DP_QR = process.env.REACT_APP_QR_URL;
export const URL_DP_SHOP = process.env.REACT_APP_SHOP_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;
export const URL_ORDER = process.env.REACT_APP_API_URL_ORDER;
export const URL_LOGO_COMPANY = process.env.REACT_APP_API_URL_LOGO;
export const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

export const HOST_RAWI = process.env.REACT_APP_HOST_RAWI; // "localhost:3000"; rawi.dashboard.astrolab-agency.com
export const HOST_CSHOP = process.env.REACT_APP_HOST_CSHOP;
export const HOST_FORSA = process.env.REACT_APP_HOST_FORSA;
export const HOST_MEIZ = process.env.REACT_APP_HOST_MEIZ;
