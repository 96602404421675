import * as React from "react";
import styled from "styled-components";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Radio,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import DeleteIcon from "@material-ui/icons/Delete";

import { Input, ButtonCustomize, Select } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { SnoonuAddPackageModal } from "..";

import { URL_THEME_PICTURES } from "../../api";
import {
  AddDeliveryServiceMethodInput,
  AddDeliveryPackageInput,
  AddPackageInput,
  DeliveryKind,
  CustomDeliveryInput,
  CustomDeliveryKind,
} from "../../api/types";

import DefaultLogpackage from "../../img/digishop/defaultLogpackage.svg";

type Picture = {
  id: string;
  url: string;
};

type DeliverySettingsProps = {
  currency: string;
  kind: DeliveryKind;
  nameDelivery: string | undefined;
  onRetrun: (
    nameDelivery?: string,
    deliveryMethods?: AddDeliveryServiceMethodInput[],
    deliveryPackage?: AddDeliveryPackageInput,
    customDelivery?: CustomDeliveryInput
  ) => void;
  onSave: () => void;
};

const DeliverySettings = ({
  currency,
  kind,
  nameDelivery,
  onRetrun,
  onSave,
}: DeliverySettingsProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [deliveryMethods, setDeliveryMethods] = React.useState<
    AddDeliveryServiceMethodInput[]
  >([
    {
      kind: "STANDARD",
      isActive: true,
    },
  ]);
  const [deliveryPackage, setDeliveryPackage] =
    React.useState<AddDeliveryPackageInput>();
  const [picturesPackage, setPicturesPackage] = React.useState<Picture[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [deliveryName, setDeliveryName] = React.useState<string | undefined>(
    undefined
  );
  const [customDelivery, setCustomDelivery] =
    React.useState<CustomDeliveryInput>({
      kind: "FREE",
    });

  const columns: ColumnsProps = [
    {
      header: "Picture",
      accessor: "picture",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Size",
      accessor: "size",
    },
    {
      header: "Cost",
      accessor: "cost",
    },
    {
      header: "Action",
      accessor: "action",
    },
  ];

  const renderTableRows = () => {
    return (
      deliveryPackage?.packages
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item, index) => ({
          id: index,
          picture: item.picture ? (
            <img
              src={
                `${URL_THEME_PICTURES}${
                  find(picturesPackage, (o) => o.id === item.picture)?.url
                }` || ""
              }
              className="product__image"
              alt="img-package"
            />
          ) : (
            <img
              src={DefaultLogpackage}
              className="product__image"
              alt="img-package"
            />
          ),
          name: item.name,
          size: item.kinds?.[0]?.size,
          cost: `${item.kinds?.[0]?.cost} ${currency}`,
          action: (
            <IconButton
              size="small"
              onClick={() => handleDeleteFromPackaging(index)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          ),
        })) || []
    );
  };

  const handleDeleteFromPackaging = (index: number) => {
    const arrayPackages = [...(deliveryPackage?.packages || [])];
    arrayPackages.splice(index, 1);
    setDeliveryPackage({
      ...deliveryPackage,
      packages: arrayPackages,
    });
  };

  const handleActiveDeliveryExpress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setDeliveryMethods((prevDeliveryMethods) => {
      const arrayDeliveryMethods = [...prevDeliveryMethods];
      if (event.target.checked) {
        arrayDeliveryMethods.push({
          kind: "EXPRESS",
          isActive: true,
        });
      } else {
        const index = findIndex(
          arrayDeliveryMethods,
          (o) => o.kind === "EXPRESS"
        );
        arrayDeliveryMethods.splice(index, 1);
      }
      return arrayDeliveryMethods;
    });
  };

  const handleActiveDeliveryPackage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setDeliveryPackage({
      isActive: event.target.checked,
    });
  };

  const handleDeliveryFeels =
    (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const { name, value } = event.target;
      setDeliveryMethods((prevDeliveryMethods) => {
        const arrayDeliveryMethods = [...prevDeliveryMethods];
        const index = findIndex(arrayDeliveryMethods, (o) => o.kind === type);
        if (index !== -1) {
          arrayDeliveryMethods[index] = {
            ...arrayDeliveryMethods[index],
            deliveryFees: {
              ...arrayDeliveryMethods[index].deliveryFees,
              [name]: parseFloat(value) >= 0 && parseFloat(value),
            },
          };
        }
        return arrayDeliveryMethods;
      });
    };

  const handleDeliveryPackage = (
    packageInput: AddPackageInput,
    url?: Picture
  ) => {
    const arrayPackages = [...(deliveryPackage?.packages || [])];

    arrayPackages?.push(packageInput);
    setDeliveryPackage({
      ...deliveryPackage,
      packages: arrayPackages,
    });
    setPicturesPackage((prevPicturesPackage) => {
      const arrayUrls = [...prevPicturesPackage];
      url && arrayUrls.push(url);
      return arrayUrls;
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  React.useEffect(() => {
    setDeliveryName(nameDelivery);
  }, [nameDelivery]);

  React.useEffect(() => {
    if (kind === "SNOONU") {
      onRetrun(undefined, deliveryMethods, deliveryPackage);
    } else {
      onRetrun(deliveryName, undefined, deliveryPackage, customDelivery);
    }
  }, [deliveryMethods, deliveryPackage, customDelivery, deliveryName]);

  return (
    <Form>
      {kind === "SNOONU" && (
        <Box marginBottom={1}>
          <Typography variant="h3" className="bold">
            Snoonu delivery settings
          </Typography>
          <FormControlLabel
            control={
              <Switch
                name="acivate"
                checked={
                  findIndex(deliveryMethods, (o) => o.kind === "EXPRESS") !== -1
                }
                onChange={handleActiveDeliveryExpress}
                color="secondary"
              />
            }
            label="Activate Snoonu express"
            className="btn-switch"
          />
          <Typography variant="h3" className="bold">
            Delivery services fees
          </Typography>
          <Typography
            variant="subtitle1"
            className="bold"
            style={{ margin: "8px 0 4px 0" }}
          >
            Snoonu fees
          </Typography>
          <div className="row">
            <Typography variant="subtitle2"> Range (km) </Typography>
            <Typography variant="subtitle2"> Fees per order </Typography>
          </div>
          <div className="row">
            <Typography variant="subtitle1"> 0 km - 20 km </Typography>
            <Input
              name="min"
              label={currency}
              type="number"
              onChange={handleDeliveryFeels("STANDARD")}
              onKeyPress={onKeyPress}
            />
          </div>
          <div className="row">
            <Typography variant="subtitle1"> 20 km and more </Typography>
            <Input
              name="max"
              label={currency}
              type="number"
              onChange={handleDeliveryFeels("STANDARD")}
              onKeyPress={onKeyPress}
            />
          </div>
          {find(deliveryMethods, (o) => o.kind === "EXPRESS")?.isActive && (
            <Box>
              <Typography
                variant="subtitle1"
                className="bold"
                style={{ margin: "15px 0 5px 0" }}
              >
                Snoonu express fees
              </Typography>
              <div className="row">
                <Typography variant="subtitle2"> Range (km) </Typography>
                <Typography variant="subtitle2"> Fees per order </Typography>
              </div>
              <div className="row">
                <Typography variant="subtitle1"> 0 km - 20 km </Typography>
                <Input
                  name="min"
                  label={currency}
                  type="number"
                  onChange={handleDeliveryFeels("EXPRESS")}
                  onKeyPress={onKeyPress}
                />
              </div>
              <div className="row">
                <Typography variant="subtitle1"> 20 km and more </Typography>
                <Input
                  name="max"
                  label={currency}
                  type="number"
                  onChange={handleDeliveryFeels("EXPRESS")}
                  onKeyPress={onKeyPress}
                />
              </div>
            </Box>
          )}
        </Box>
      )}
      {kind === "CUSTOM" && (
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h3"
            style={{ fontWeight: 700, marginBottom: 10 }}
          >
            Delivery name at checkout
          </Typography>
          <Input
            label="Set the name which customers will see at checkout"
            value={deliveryName}
            onChange={(e) => setDeliveryName(e.target.value)}
            style={{ maxWidth: 500 }}
          />
          <Typography
            variant="h3"
            style={{ fontWeight: 700, margin: "15px 0 10px 0" }}
          >
            Set delivery fees
          </Typography>
          <RadioGroup
            value={customDelivery.kind}
            onChange={(e) => {
              setCustomDelivery({
                ...customDelivery,
                kind: e.target.value as CustomDeliveryKind,
                cost: undefined,
                limit: undefined,
              });
            }}
            aria-label="fees"
            name="customized-radios"
          >
            <FormControlLabel
              value="FREE"
              control={<Radio />}
              label="Free delivery"
            />
            <FormControlLabel
              value={
                customDelivery.kind === "PAYING_PERCENT"
                  ? "PAYING_PERCENT"
                  : "PAYING_AMOUNT"
              }
              control={<Radio />}
              label="Fixed fee"
            />
            {(customDelivery.kind === "PAYING_AMOUNT" ||
              customDelivery.kind === "PAYING_PERCENT") && (
              <Box display="flex" width={500} className="paying-amount">
                <Select
                  value={customDelivery.kind}
                  onChange={(e) =>
                    setCustomDelivery({
                      ...customDelivery,
                      kind: e.target.value as CustomDeliveryKind,
                    })
                  }
                  style={{ marginRight: 15 }}
                >
                  <MenuItem value="PAYING_AMOUNT">Amount</MenuItem>
                  {/* <MenuItem value="PAYING_PERCENT">Percent</MenuItem> */}
                </Select>

                <Input
                  type="number"
                  value={customDelivery.cost}
                  onBlur={(e) =>
                    setCustomDelivery({
                      ...customDelivery,
                      cost: parseFloat(e.target.value),
                      limit: 0,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      {customDelivery.kind === "PAYING_PERCENT"
                        ? "%"
                        : currency}
                    </InputAdornment>
                  }
                />
              </Box>
            )}
            <FormControlLabel
              value="FREE_FROM"
              control={<Radio />}
              label="Conditinal fee"
            />
            {customDelivery.kind === "FREE_FROM" && (
              <Box
                display="flex"
                width={500}
                alignItems="center"
                marginBottom={1}
              >
                <Input
                  label="From order cost of"
                  type="number"
                  value={customDelivery.limit}
                  onBlur={(e) =>
                    setCustomDelivery({
                      ...customDelivery,
                      limit: parseFloat(e.target.value),
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">{currency}</InputAdornment>
                  }
                />
                <Typography> Else </Typography>
                <Input
                  label="Set delivery fee"
                  type="number"
                  value={customDelivery.cost}
                  onBlur={(e) =>
                    setCustomDelivery({
                      ...customDelivery,
                      cost: parseFloat(e.target.value),
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">{currency}</InputAdornment>
                  }
                />
              </Box>
            )}
          </RadioGroup>
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <Switch
          checked={deliveryPackage?.isActive || false}
          onChange={handleActiveDeliveryPackage}
        />
        <Typography variant="h3" className="bold">
          Gift packaging
        </Typography>
      </Box>
      <Typography variant="subtitle2" className="description">
        Turn this on to set the package and gift wrap variants that you
        providefor this delivery and rates system, packages will contain their
        own cost and picture that will be displayed in the store to let
        customers choose at their liking, keeping this option disabled customers
        will not have the option to choose packages options.
      </Typography>
      {deliveryPackage?.isActive && (
        <Box marginTop={2}>
          <Typography variant="h3" className="bold">
            Packages
          </Typography>
          <Box width={800} marginTop={3}>
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="You have no packages. add one"
              tablePaginationProps={{
                labelDisplayedRows: ({ from, to }) => `${from} - ${to}`,
                count: deliveryPackage?.packages?.length || 0,
                page,
                onPageChange: (_, newPage) => setPage(newPage),
                rowsPerPage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
              }}
            />
          </Box>
          <ButtonCustomize
            variant="contained"
            className="btn-add"
            onClick={() => setOpen(true)}
          >
            Add package
          </ButtonCustomize>
          <SnoonuAddPackageModal
            open={open}
            onClose={() => setOpen(false)}
            onReturn={(data, url) => handleDeliveryPackage(data, url)}
          />
        </Box>
      )}
      <ButtonCustomize
        variant="contained"
        color="secondary"
        className="btn-save"
        onClick={onSave}
      >
        Save delivery method
      </ButtonCustomize>
    </Form>
  );
};

const Form = styled.div`
  display: flex;
  height: calc(100vh - 259px);
  flex-direction: column;
  overflow-x: auto;
  margin-top: 15px;
  .bold {
    font-weight: 700;
  }
  .MuiFormControlLabel-root {
    height: 35px;
  }
  .MuiFormControlLabel-root {
    width: 130px;
  }
  .paying-amount {
    .MuiSelect {
      padding: 14px 12px 14px;
    }
    .mainInput {
      padding: 14px 12px 14px;
    }
    margin-bottom: 8px;
  }
  .btn-switch {
    width: 250px;
    margin: 8px 0;
  }
  .row {
    display: grid;
    width: 340px;
    grid-template-columns: 200px 100px;
    align-items: center;
    grid-gap: 10px;
    border-bottom: 1px solid #edeff2;
    padding: 5px 0 5px 8px;
  }
  .mainInput {
    padding: 23px 12px 7px;
  }
  .description {
    max-width: 900px;
  }
  .btn-add {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .product__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
  .btn-save {
    width: 180px;
    margin-top: 20px;
  }
`;

export default DeliverySettings;
