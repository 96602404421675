import gql from "graphql-tag";

// Get List menu categories for create category
export const GETLISTMENUCATEGORIES = gql`
  query GetListMenuCategories($input: GetListMenuCategoriesInput!) {
    getListMenuCategories(input: $input) {
      list {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        level
        parent
        main
        picture {
          id
          fileUrl
        }
        order
        isPublished
        subCategoriesCount
        productsCount
      }
    }
  }
`;

// Get List menu categories for list categories
export const GETLISTDETAILSMENUCATEGORIES = gql`
  query getListDetailsMenuCategories($token: String!, $menu: String!) {
    getListMenuCategories(input: { token: $token, menu: $menu }) {
      list {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        # descriptions {
        #   id
        #   languageCode
        #   value
        # }
        picture {
          id
          # kind
          # fileName
        }
        level
        parent
        # pictureIsVisible
        order
        productsCount
        isPublished
        # copiedPictures {
        #   id
        #   quality
        # kind
        # fileName
        # }
      }
    }
  }
`;

// Get List menu sous categories for list categories
export const GETLISTMENUSUBCATEGORIES = gql`
  query getListMenuSubCategories($token: String!, $category: String!) {
    getListMenuSubCategories(input: { token: $token, category: $category }) {
      list {
        id
        identifier
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        level
        parent
        order
        isPublished
      }
    }
  }
`;

// Get List menu sous categories for list categories
export const GETLISTSUBCATEGORIES = gql`
  query getListSubCategories(
    $token: String!
    $category: String
    $categories: [String!]
  ) {
    getListMenuSubCategories(
      input: { token: $token, category: $category, categories: $categories }
    ) {
      list {
        id
        names {
          id
          languageCode
          value
        }
      }
    }
  }
`;

// create menu category
export const CREATEMENUCATEGORY = gql`
  mutation createMenuCategory($category: CreateMenuCategoryInput!) {
    createMenuCategory(input: $category) {
      id
    }
  }
`;

// set menu categories order
export const SET_MENU_CATEGORIES_ORDER = gql`
  mutation SetMenuCategoriesOrder($order: SetMenuCategoriesOrderInput!) {
    setMenuCategoriesOrder(input: $order) {
      success
    }
  }
`;

// update menu category
export const UPDATEMENUCATEGORIES = gql`
  mutation updateMenuCategory($menu: UpdateMenuCategoryInput!) {
    updateMenuCategory(input: $menu) {
      success
    }
  }
`;

// delete menu category
export const DELETEMENUCATEGORY = gql`
  mutation deleteMenuCategory($token: String!, $id: String!) {
    deleteMenuCategory(input: { token: $token, id: $id }) {
      success
    }
  }
`;

// get category details
export const GETMENUCATEGORYDETAILS = gql`
  query GetMenuCategoryDetails($input: GetMenuCategoryDetailsInput!) {
    getMenuCategoryDetails(input: $input) {
      category {
        identifier
        identifier
        hasMultiLanguages
        names {
          id
          languageCode
          value
        }
        descriptions {
          id
          languageCode
          value
        }
        level
        main
        parent
        picture {
          id
          fileUrl
        }
        display
        isPublished
      }
    }
  }
`;
