import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import { FilledInputProps } from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { InputBase } from "@material-ui/core";

type InputProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  dataTestId?: string;
  multiline?: boolean;
  rows?: number | string;
  required?: boolean;
  maxLength?: number;
  step?: number;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      msgError,
      dataTestId,
      multiline,
      rows,
      style,
      required,
      inputProps,
      maxLength,
      endAdornment,
      step,
      ...restProps
    },
    ref
  ) => {
    return (
      <FormControlStyled
        variant="filled"
        error={msgError !== "" && msgError !== undefined}
        style={style}
        required={required || false}
      >
        <InputLabel htmlFor="component-filled" shrink>
          {label}
        </InputLabel>

        <InputBase
          ref={ref}
          inputProps={{
            className: "mainInput",
            "data-testid": dataTestId,
            maxLength,
            step,
          }}
          {...restProps}
          multiline={multiline}
          rows={rows}
          endAdornment={endAdornment}
        />
        {msgError && <FormHelperText> {msgError} </FormHelperText>}
      </FormControlStyled>
    );
  }
);

const FormControlStyled = styled(FormControl)`
  width: 100%;
  .MuiInputBase-root {
    border: 2px solid ${(props) => (props.error ? "#f67070" : "#f1f2ff")};
    border-radius: 5px;
    &:focus-within {
      border: 2px solid ${(props) => (props.error ? "#f67070" : "#000000")};
      border-radius: 5px;
    }
  }
  .mainInput {
    overflow: hidden;
    background-color: #ffffff;
    padding: 27px 12px 10px;
    border-radius: 5px;
  }

  .MuiInputBase-multiline {
    padding: 0;
  }
  .Mui-disabled {
    background: #f1f2fb;
  }
`;

export default Input;
