// libraries imports
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  DialogActions,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";

import { Wrapper, DialogStyled } from "./GiftCardList--styles";
import { GiftCard } from "../../img";
import {
  TablePagination,
  BreadCrumbsTitle,
  ButtonCustomize,
} from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { ListDataEmpty } from "../../components";

import { GET_MENU_LOCAL, GetMenuLocalQuery } from "../../api/local-state";
import { GET_GIFT_CARDS } from "../../api/giftCard.api";
import { GetGiftCardsQuery, GetGiftCardsQueryVariables } from "../../api/types";

const GiftCardsList: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const token = localStorage.getItem("token") || "";

  const [cardId, setCardId] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);
  const menu = localMenu?.menuItem;

  // queries
  const { data: GiftCards } = useQuery<
    GetGiftCardsQuery,
    GetGiftCardsQueryVariables
  >(GET_GIFT_CARDS, { variables: { input: { menu: menu?.id || "", token } } });
  const giftCardsList = GiftCards?.getListGiftCards.list || [];

  const selectedCard = giftCardsList.filter((card) => card?.id === cardId);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: ColumnsProps = [
    { header: "Name", accessor: "name" },
    { header: "Gift Code ", accessor: "giftCode" },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return (
      giftCardsList
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((gift) => ({
          id: gift?.id,
          name: gift.names?.[0].value || "",
          giftCode: gift?.codes?.[0]?.code,
          actions: (
            <>
              <IconButton
                size="small"
                color="primary"
                style={{ color: "#444444", margin: "0 4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setCardId(gift.id || "");
                  setOpen(true);
                }}
              >
                <Visibility />
              </IconButton>
            </>
          ),
        })) || []
    );
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <BreadCrumbsTitle
          labelIntl={["Gift Card", "giftCard.list"]}
          icon={GiftCard}
          iconAlt="Customers"
        />
        {giftCardsList?.length && (
          <ButtonCustomize
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={() => history.push("/add-gift-card")}
          >
            <FontAwesomeIcon icon={faSave} color="#ffffff" />
            <span style={{ marginLeft: "15px" }}>
              {intl.formatMessage({ id: "giftCard.create" })}
            </span>
          </ButtonCustomize>
        )}
      </Box>

      {giftCardsList?.length > 0 && (
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          component={Paper}
          p={2}
        >
          <Box flex="1">
            <Table
              columns={columns}
              data={renderTableRows()}
              emptyMessage="Assign or add your first product"
              onClickRow={(row) => {
                if (row.id) {
                  setCardId(row.id);
                  setOpen(true);
                }
              }}
            />
          </Box>
          <TablePagination
            labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
            count={giftCardsList?.length || 0}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}

      {giftCardsList?.length === 0 && (
        <ListDataEmpty
          btnName={intl.formatMessage({ id: "giftCard.newCard" })}
          url="/add-gift-card"
          description={intl.formatMessage({ id: "giftCard.emptyDescription" })}
          image={GiftCard}
        />
      )}
      <DialogStyled
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {intl.formatMessage({ id: "giftCard.info" })}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4} className="dialog__labels">
              id:
            </Grid>
            <Grid item xs={8}>
              {selectedCard?.[0]?.id}
            </Grid>
            <Grid item xs={4} className="dialog__labels">
              Name:
            </Grid>
            <Grid item xs={8}>
              {selectedCard?.[0]?.names?.[0].value}
            </Grid>
            <Grid item xs={4} className="dialog__labels">
              Description:
            </Grid>
            <Grid item xs={8}>
              {selectedCard?.[0]?.descriptions?.[0].value}
            </Grid>
            <Grid item xs={4} className="dialog__labels">
              value
            </Grid>
            <Grid item xs={8}>
              {`${selectedCard?.[0]?.price?.value} ${selectedCard?.[0]?.price?.currency?.code}`}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Close
          </ButtonCustomize>
        </DialogActions>
      </DialogStyled>
    </Wrapper>
  );
};

export default GiftCardsList;
