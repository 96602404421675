import React from "react";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";

import { Box, Input } from "../../commons";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "../../api/myAccount.api";
import {
  ForgetPasswordMutation,
  ForgetPasswordMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from "../../api/types";
import { snackBarVar } from "../../api/local-state";

const ResetPassword: React.FC = () => {
  const intl = useIntl();
  const token = window.location.search.substr(7);
  const history = useHistory();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const [forgotPassword, { loading }] = useMutation<
    ForgetPasswordMutation,
    ForgetPasswordMutationVariables
  >(FORGOT_PASSWORD, {
    variables: { input: { email: username } },
    onError: (errorForgotPassword) => {
      if (errorForgotPassword.message === "user_not_found")
        snackBarVar({
          open: true,
          severity: "error",
          message: "Account not found",
        });
      else
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Check your mailbox",
      });
    },
  });

  const [resetPassword, { loading: loadingReset }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_PASSWORD, {
    variables: { input: { passwordCode: token, newPassword: password } },
    onError: (errorResetPassword) => {
      if (errorResetPassword.message === "user_not_found")
        snackBarVar({
          open: true,
          severity: "error",
          message: "Account not found",
        });
      else
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "Your password has changed",
      });
      history.push("/login");
    },
  });

  // FUNCTIONS

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const validate = () => {
    if (password !== confirmPassword) {
      setError(true);
    } else {
      resetPassword();
    }
  };

  return (
    <Wrapper>
      <Box p={24} width={400}>
        <div className="note">
          {!token ? (
            <p>
              {intl.formatMessage({ id: "resetPassword.label.resetPassword" })}
            </p>
          ) : (
            <p>
              {intl.formatMessage({ id: "resetPassword.label.newPassword" })}
            </p>
          )}
        </div>
        {!token ? (
          <TextField
            id="username"
            label={intl.formatMessage({ id: "resetPassword.username" })}
            value={username}
            type="text"
            variant="filled"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginBottom: "24px" }}
            onChange={handleChange}
          />
        ) : (
          <div>
            <Input
              id="new_password"
              label={intl.formatMessage({ id: "resetPassword.password" })}
              value={password}
              type="password"
              fullWidth
              style={{ marginBottom: "24px" }}
              onChange={handleChangePassword}
            />
            <Input
              id="confirm_password"
              label={intl.formatMessage({ id: "resetPassword.confirm" })}
              value={confirmPassword}
              type="password"
              fullWidth
              style={{ marginBottom: "24px" }}
              onChange={handleChangeConfirmPassword}
              msgError={
                error
                  ? intl.formatMessage({ id: "resetPassword.error" })
                  : undefined
              }
            />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {!token ? (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={loading}
              onClick={() => forgotPassword()}
            >
              {intl.formatMessage({ id: "resetPassword.resetPassword" })}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={loadingReset}
              onClick={validate}
            >
              {intl.formatMessage({ id: "resetPassword.resetPassword" })}
            </Button>
          )}
        </div>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .note {
    background: #fafbff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 13px;
    margin-bottom: 24px;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 15px;
      color: #afb1c6;
    }
  }
  .MuiFilledInput-underline:hover:before {
    border: 0;
  }
  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;

    :hover {
      background-color: #ffffff;
      border: 2px solid #f1f2fb;
    }
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
  }
  .connect {
    margin-left: 25px;
    border: 0;
    background: #ffffff;
    color: #4cd964;
    padding: 5px 15px;
    position: absolute;
    right: 50px;
  }
`;

export default ResetPassword;
