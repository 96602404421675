import styled from "styled-components";

export const Note = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    .search {
      display: grid;
      grid-template-columns: 1fr 100px;
      align-items: center;
      gap: 5px;
      margin-bottom: 20px;
      .mainInput {
        padding: 9px 9px 9px;
      }
      button {
        height: 38px;
        display: block;
        font-size: 14px;
        text-transform: lowercase;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .table-collection {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 15px 15px 5px 15px;
      border-radius: 10px;
      .table-option {
        display: flex;
        justify-content: flex-end;
        span {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        .mainInput {
          padding: 9px 12px 9px;
        }
        .sort {
          width: 150px;
          margin-right: 15px;
          .MuiSelect {
            padding: 9px 9px 9px;
            font-size: 13px;
          }
          .MuiTouchRipple-root {
            font-size: 13px;
          }
        }
        margin-bottom: 10px;
      }
    }
  }
  .product__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
`;

export const MenuList = styled.div<{ danger?: boolean }>`
  padding: 5px 16px;
  min-width: 100px;
  border-radius: 5px;
  color: ${(props) => (props.danger ? "#F67070" : "#444444")};
  :hover {
    background-color: ${(props) => (props.danger ? "#FCF1F1" : "#f3f4fa")};
  }
`;
