import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import findIndex from "lodash/findIndex";

import {
  ButtonCustomize,
  Input,
  Select,
  InputTags,
  AutoCompleteStyled,
} from "../../commons";

import {
  UploadPictureProduct,
  AssignProductTypeModal,
  AssignProductTypeEasyPayModal,
  AssignProductTypeBeamAndGoModal,
} from "..";

import {
  GET_LIST_PRODUCT_ATTRIBUTES,
  GETLISTMENUCATEGORIES,
  ADD_PRODUCT_PICTURE,
  REMOVE_PRODUCT_PICTURE,
  HOST_CSHOP,
  HOST_RAWI,
} from "../../api";
import {
  GetListProductAttributesQuery,
  GetListProductAttributesQueryVariables,
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  AddProductPictureMutation,
  AddProductPictureMutationVariables,
  RemoveProductPictureMutation,
  RemoveProductPictureMutationVariables,
  MenuPayload,
  UpdateMenuTypeProductInput,
  PictureUrlPayload,
  ProductGalleryInput,
  CreateApiDataBeamandgoInput,
  ProductAttributePayload,
} from "../../api/types";
import { SelectedMenuVar } from "../../api/local-state";

import {
  regroupCategories,
  MenuCategoryExtended,
} from "../../utils/category-utils";

import { Container, Seperator, RequiredIcon } from "./GeneralInforForm--styles";
import {
  ErrorsType,
  ErrorsTypeRawi,
} from "../../modules/ProductType/ProductTypeForm--validation";

type GeneralInforFormProps = {
  idTypeBooks: string;
  menuTypeProduct: UpdateMenuTypeProductInput | undefined;
  picture: PictureUrlPayload | undefined | null;
  gallery: ProductGalleryInput[] | undefined | null;
  urlImageGallery: PictureUrlPayload[] | undefined | null;
  onReturn: (data: UpdateMenuTypeProductInput) => void;
  onReturnGallery: (gallery: ProductGalleryInput[]) => void;
  onDeleteGallery: (id: string) => void;
  // eslint-disable-next-line react/require-default-props
  errorsForm?: ErrorsType;
  errorsFormRawi?: ErrorsTypeRawi;
  // eslint-disable-next-line react/require-default-props
  onReturnErrors?: (result: ErrorsType) => void;
  onReturnErrorsRawi?: (result: ErrorsTypeRawi) => void;
};

const { host } = window.location;

const GeneralInforForm = ({
  idTypeBooks,
  menuTypeProduct,
  picture,
  gallery,
  urlImageGallery,
  onReturn,
  onReturnGallery,
  onDeleteGallery,
  errorsForm,
  errorsFormRawi,
  onReturnErrors,
  onReturnErrorsRawi,
}: GeneralInforFormProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const { id: idProductType } = useParams<{
    id: string | undefined;
  }>();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const dataMenuTypeProduct: UpdateMenuTypeProductInput = {
    ...menuTypeProduct,
    token,
    id: idProductType || "",
  };

  const [open, setOpen] = React.useState(false);

  const { data: dataStores } = useQuery<
    GetListProductAttributesQuery,
    GetListProductAttributesQueryVariables
  >(GET_LIST_PRODUCT_ATTRIBUTES, {
    variables: {
      input: {
        token,
        identifier: "STORES",
        menuType: idTypeBooks,
      },
    },
  });

  // for cwallet
  const listStores = dataStores?.getListProductAttributes?.list || [];
  const store = listStores.find(
    (o) => o?.id && dataMenuTypeProduct?.attributes?.includes(o.id)
  )?.id;

  // for RAWI

  const { data: dataWriters } = useQuery<
    GetListProductAttributesQuery,
    GetListProductAttributesQueryVariables
  >(GET_LIST_PRODUCT_ATTRIBUTES, {
    variables: {
      input: {
        token,
        identifier: "WRITERS",
        menuType: idTypeBooks,
      },
    },
  });
  const listWriters = dataWriters?.getListProductAttributes?.list || [];

  const writer = listWriters.find(
    (o) => o?.id && dataMenuTypeProduct?.attributes?.includes(o.id)
  );

  const { data: dataReaders } = useQuery<
    GetListProductAttributesQuery,
    GetListProductAttributesQueryVariables
  >(GET_LIST_PRODUCT_ATTRIBUTES, {
    variables: {
      input: {
        token,
        identifier: "READERS",
        menuType: idTypeBooks,
      },
    },
  });

  const listReaders = dataReaders?.getListProductAttributes?.list || [];

  const reader = listReaders.find(
    (o) => o?.id && dataMenuTypeProduct?.attributes?.includes(o.id)
  );

  const { data: listCategory } = useQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GETLISTMENUCATEGORIES, {
    variables: { input: { token, menu: localMenu?.id || "" } },
  });
  const categoriesListData = listCategory?.getListMenuCategories?.list;

  const [AddProductPicture] = useMutation<
    AddProductPictureMutation,
    AddProductPictureMutationVariables
  >(ADD_PRODUCT_PICTURE);

  const [RemoveProductPicture] = useMutation<
    RemoveProductPictureMutation,
    RemoveProductPictureMutationVariables
  >(REMOVE_PRODUCT_PICTURE);

  const renderCategorySelectOptions = (
    List: MenuCategoryExtended[]
  ): React.ReactNode => {
    return (
      List.map((category) => [
        <MenuItem
          value={category.id || ""}
          style={{ paddingLeft: `${(category.level || 0) * 10}px` }}
          disabled={!!category?.subcategories}
        >
          {category.names?.[0].value || ""}
        </MenuItem>,

        renderCategorySelectOptions(category.subcategories || []),
      ]) || null
    );
  };

  // refactor list categories
  const categories = React.useMemo(
    () => (categoriesListData ? regroupCategories(categoriesListData) : []),
    [categoriesListData]
  );

  // for cwallet
  const handleStore = (value: string) => {
    onReturn({ ...dataMenuTypeProduct, attributes: [value] });
  };

  const handleApiProvider = (
    key: string,
    value: string,
    name?: string,
    typeId?: number,
    apiData?: CreateApiDataBeamandgoInput
  ) => {
    const position = findIndex(
      dataMenuTypeProduct?.customFields,
      (o) => o.key === key
    );
    const arrayCustomFields = [...(dataMenuTypeProduct?.customFields || [])];

    if (position !== -1) {
      arrayCustomFields[position].value = value;
    } else {
      arrayCustomFields.push({
        key,
        value,
      });
    }

    onReturn({
      ...dataMenuTypeProduct,
      customFields: arrayCustomFields,
      name: {
        ...dataMenuTypeProduct.name,
        value: name,
      },
      kind: typeId === 1 ? "DIGITAL" : "PHYSICAL",
      apiData: {
        beamandgo: apiData,
      },
    });
  };

  // for RAWI
  const handleWriter = (value: string) => {
    const position = findIndex(
      dataMenuTypeProduct?.attributes,
      (item) => item === writer?.id
    );
    const arrayAttributes = [...(dataMenuTypeProduct?.attributes || [])];
    if (position !== -1) {
      arrayAttributes[position] = value;
    } else {
      arrayAttributes.push(value);
    }
    onReturn({ ...dataMenuTypeProduct, attributes: arrayAttributes });
    onReturnErrorsRawi && onReturnErrorsRawi({ ...errorsFormRawi, writer: "" });
  };

  const handleReader = (value: string) => {
    const position = findIndex(
      dataMenuTypeProduct?.attributes,
      (item) => item === reader?.id
    );
    const arrayAttributes = [...(dataMenuTypeProduct?.attributes || [])];
    if (position !== -1) {
      arrayAttributes[position] = value;
    } else {
      arrayAttributes.push(value);
    }
    onReturn({ ...dataMenuTypeProduct, attributes: arrayAttributes });
    onReturnErrorsRawi && onReturnErrorsRawi({ ...errorsFormRawi, reader: "" });
  };

  return (
    <Container>
      <Box className="box">
        <Typography variant="h2" className="general">
          General
        </Typography>
        <Box display="flex">
          <UploadPictureProduct
            title="Base Image"
            mode="Base"
            uploadTo="Product"
            urlImage={{
              id: picture?.id,
              url: picture?.fileUrl,
            }}
            onLoaded={(image, copiedPictures) =>
              onReturn({
                ...dataMenuTypeProduct,
                picture: image,
                copiedPictures,
              })
            }
          />
          <Seperator />
          <UploadPictureProduct
            title="Gallery"
            mode="Gallery"
            uploadTo="Product"
            urlImage={{
              id: picture?.id,
              url: picture?.fileUrl,
            }}
            urlImageGallery={urlImageGallery}
            onLoaded={(image, copiedPictures) => {
              if (idProductType) {
                AddProductPicture({
                  variables: {
                    input: {
                      token,
                      product: idProductType || "",
                      picture: image,
                      copiedPictures,
                    },
                  },
                });
              } else {
                const arrayGallery = [...(gallery || [])];
                arrayGallery.push({
                  picture: image,
                  copiedPictures,
                });
                onReturnGallery(arrayGallery);
              }
            }}
            onDeleted={(pictureId) => {
              if (idProductType) {
                RemoveProductPicture({
                  variables: {
                    input: {
                      id: pictureId,
                      product: idProductType,
                      token,
                    },
                  },
                });
              } else {
                onDeleteGallery(pictureId);
              }
            }}
          />
        </Box>
      </Box>
      <Box className="box">
        <Typography variant="h3" className="general">
          Information
        </Typography>
        {host === HOST_CSHOP && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={2}
          >
            <Select
              label="API provider"
              value={
                dataMenuTypeProduct?.customFields?.find(
                  (o) => o.key === "API_PROVIDER"
                )?.value || ""
              }
              onChange={(e) => {
                handleApiProvider("API_PROVIDER", e.target.value as string);
                onReturnErrors &&
                  onReturnErrors({ ...errorsForm, apiProvider: "" });
              }}
              msgError={errorsForm?.apiProvider}
              style={{ maxWidth: 600 }}
            >
              <MenuItem value="EasyPay">EasyPay</MenuItem>
              <MenuItem value="DTOne">DTOne</MenuItem>
              <MenuItem value="BeamAndGo">Beam&Go</MenuItem>
            </Select>
            <Box
              display="flex"
              width={200}
              justifyContent="flex-end"
              marginLeft={2}
            >
              <ButtonCustomize
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}
                disabled={
                  dataMenuTypeProduct?.customFields?.find(
                    (o) => o.key === "API_PROVIDER"
                  )?.value === undefined
                }
              >
                {!!errorsForm?.apiProviderID && <RequiredIcon />}
                Assign product
              </ButtonCustomize>
            </Box>
          </Box>
        )}

        <div className="input-row">
          <Input
            label="Name"
            value={dataMenuTypeProduct?.name?.value || ""}
            onChange={(e) => {
              onReturn({
                ...dataMenuTypeProduct,
                name: {
                  ...dataMenuTypeProduct?.name,
                  value: e.target.value,
                },
              });
              onReturnErrors && onReturnErrors({ ...errorsForm, name: "" });
              onReturnErrorsRawi &&
                onReturnErrorsRawi({ ...errorsFormRawi, name: "" });
            }}
            msgError={errorsForm?.name || errorsFormRawi?.name}
            style={{ maxWidth: 600 }}
          />
        </div>
        {/* for RAWI */}
        {host === HOST_RAWI && (
          <div className="input-row">
            {writer && (
              <AutoCompleteStyled
                id="Writer"
                options={listWriters}
                value={writer}
                getOptionLabel={(option: any) => option?.name?.value || ""}
                onChange={(event, value: any) =>
                  value?.id && handleWriter(value.id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Writer"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            )}

            {!writer && (
              <AutoCompleteStyled
                id="Writer"
                options={listWriters}
                value={writer}
                getOptionLabel={(option: any) => option?.name?.value || ""}
                onChange={(event, value: any) =>
                  value?.id && handleWriter(value.id)
                }
                error={!!errorsFormRawi?.writer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Writer"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={!!errorsFormRawi?.writer}
                    helperText={errorsFormRawi?.writer}
                  />
                )}
              />
            )}

            {reader && (
              <AutoCompleteStyled
                id="Reader"
                options={listReaders}
                value={reader}
                getOptionLabel={(option: any) => option?.name?.value || ""}
                onChange={(event, value: any) =>
                  value?.id && handleReader(value.id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Reader"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            )}
            {!reader && (
              <AutoCompleteStyled
                id="Reader"
                options={listReaders}
                value={reader}
                getOptionLabel={(option: any) => option?.name?.value || ""}
                onChange={(event, value: any) =>
                  value?.id && handleReader(value.id)
                }
                error={!!errorsFormRawi?.reader}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Reader"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={!!errorsFormRawi?.reader}
                    helperText={errorsFormRawi?.reader}
                  />
                )}
              />
            )}
          </div>
        )}
        <Input
          label="Description"
          value={dataMenuTypeProduct?.description?.value || ""}
          onChange={(e) => {
            onReturn({
              ...dataMenuTypeProduct,
              description: {
                ...dataMenuTypeProduct?.description,
                value: e.target.value,
              },
            });
          }}
          multiline
          rows={5}
          style={{ maxWidth: 600, marginBottom: 16 }}
        />
        {/* for cwallet */}
        {host === HOST_CSHOP && (
          <Select
            label="Store"
            value={store || ""}
            onChange={(e) => handleStore(e.target.value as string)}
            style={{ maxWidth: 600, marginBottom: 16 }}
          >
            {listStores?.map((item) => (
              <MenuItem key={item.id} value={item.id!}>
                {item?.name?.value}
              </MenuItem>
            ))}
          </Select>
        )}
        <InputTags
          label="Tag"
          tags={menuTypeProduct?.tags || []}
          onChange={(listTags) =>
            onReturn({
              ...dataMenuTypeProduct,
              tags: listTags,
            })
          }
        />
      </Box>
      <Box className="box">
        <Typography variant="h3" className="general">
          Assign this product to a category
        </Typography>
        <Select
          label="Select category"
          value={dataMenuTypeProduct.category || ""}
          onChange={(e) => {
            onReturn({
              ...dataMenuTypeProduct,
              category: e.target.value as string,
            });
            onReturnErrorsRawi &&
              onReturnErrorsRawi({ ...errorsFormRawi, category: "" });
          }}
          style={{ maxWidth: 600 }}
          msgError={errorsFormRawi?.category}
        >
          {renderCategorySelectOptions(categories)}
        </Select>
      </Box>
      {dataMenuTypeProduct?.customFields?.find((o) => o.key === "API_PROVIDER")
        ?.value === "DTOne" && (
        <AssignProductTypeModal
          open={open}
          onClose={() => setOpen(false)}
          onReturn={(idProvider, nameProvider) => {
            handleApiProvider("API_PRODUCT_ID", idProvider, nameProvider);
            onReturnErrors &&
              onReturnErrors({ ...errorsForm, apiProviderID: "" });
          }}
        />
      )}
      {dataMenuTypeProduct?.customFields?.find((o) => o.key === "API_PROVIDER")
        ?.value === "EasyPay" && (
        <AssignProductTypeEasyPayModal
          open={open}
          onClose={() => setOpen(false)}
          onReturn={(idProvider, nameProvider) => {
            handleApiProvider("API_PRODUCT_ID", idProvider, nameProvider);
            onReturnErrors &&
              onReturnErrors({ ...errorsForm, apiProviderID: "" });
          }}
        />
      )}

      {dataMenuTypeProduct?.customFields?.find((o) => o.key === "API_PROVIDER")
        ?.value === "BeamAndGo" && (
        <AssignProductTypeBeamAndGoModal
          open={open}
          onClose={() => setOpen(false)}
          onReturn={(idProvider, nameProvider, typeId, apiData) => {
            handleApiProvider(
              "API_PRODUCT_ID",
              idProvider,
              nameProvider,
              typeId,
              apiData
            );
            onReturnErrors &&
              onReturnErrors({ ...errorsForm, apiProviderID: "" });
          }}
        />
      )}
    </Container>
  );
};

export default GeneralInforForm;
