import * as React from "react";
import styled from "styled-components";
import { Grid, Typography, Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useMutation, useQuery } from "@apollo/client";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { ButtonCustomize, Input } from "../../commons";

import { GET_PRODUCT_DETAILS, UPDATE_PRODUCT_DICTIONARY } from "../../api";
import {
  GetProductDetailsQuery,
  GetProductDetailsQueryVariables,
  UpdateProductDictionaryMutation,
  UpdateProductDictionaryMutationVariables,
  UpdateNameInput,
  UpdateDescriptionInput,
  UpdateProductOptionInput,
  UpdateProductVariantInput,
  LanguageCode,
  UpdateOptionValueInput,
} from "../../api/types";

import { snackBarVar } from "../../api/local-state";

type TranslateProductFormProps = {
  idProduct?: string;
  language?: LanguageCode;
  origineLangage: LanguageCode;
  onCleanIdProduct: (id: undefined) => void;
};

const TranslateProductForm: React.FC<TranslateProductFormProps> = ({
  idProduct,
  language,
  origineLangage,
  onCleanIdProduct,
}) => {
  const token = localStorage.getItem("token") || "";

  const [names, setNames] = React.useState<UpdateNameInput[]>([]);
  const [descriptions, setDescriptions] = React.useState<
    UpdateDescriptionInput[]
  >([]);
  const [options, setOptions] = React.useState<UpdateProductOptionInput[]>([]);
  const [variants, setVariants] = React.useState<UpdateProductVariantInput[]>(
    []
  );
  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const { data } = useQuery<
    GetProductDetailsQuery,
    GetProductDetailsQueryVariables
  >(GET_PRODUCT_DETAILS, {
    variables: {
      input: { id: idProduct || "", token },
    },
    onCompleted: (productDetails) => {
      const content =
        find(
          productDetails?.getProductDetails?.product?.descriptions,
          (o) => o.languageCode === language
        )?.value || "";
      const blocksFromHtml = htmlToDraft(content);
      const { contentBlocks, entityMap } = blocksFromHtml;

      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    },
    skip: !idProduct,
  });

  const product = data?.getProductDetails?.product;

  const content =
    find(product?.descriptions, (o) => o.languageCode === origineLangage)
      ?.value || "";
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;

  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  React.useEffect(() => {
    const arrayNames: UpdateNameInput[] = [];
    const arrayDescriptions: UpdateDescriptionInput[] = [];
    const arrayOption: UpdateProductOptionInput[] = [];
    const arrayVariants: UpdateProductVariantInput[] = [];

    product?.names?.forEach((name) =>
      arrayNames.push({
        id: name?.id,
        languageCode: name.languageCode as LanguageCode,
        value: name.value,
      })
    );

    product?.descriptions?.forEach((descriptionItem) => {
      arrayDescriptions.push({
        id: descriptionItem.id,
        languageCode: descriptionItem.languageCode as LanguageCode,
        value: descriptionItem.value,
      });
    });

    product?.options?.forEach((option) => {
      const arrayOptionName: UpdateNameInput[] = [];
      const arrayOptionValue: UpdateOptionValueInput[] = [];

      option.names?.forEach((name) =>
        arrayOptionName.push({
          id: name?.id,
          languageCode: name.languageCode as LanguageCode,
          value: name.value,
        })
      );
      option.values?.forEach((value) => {
        const arrayOptionValueName: UpdateNameInput[] = [];
        value.names?.forEach((name) =>
          arrayOptionValueName.push({
            id: name?.id,
            languageCode: name?.languageCode as LanguageCode,
            value: name.value,
          })
        );
        arrayOptionValue?.push({
          id: value.id!,
          names: arrayOptionValueName,
        });
      });
      arrayOption.push({
        id: option.id!,
        names: arrayOptionName,
        values: arrayOptionValue,
      });
    });

    product?.variants?.forEach((variant) => {
      const arrayVariantNames: UpdateNameInput[] = [];
      variant.names?.forEach((name) => {
        arrayVariantNames.push({
          id: name.id,
          languageCode: name.languageCode as LanguageCode,
          value: name.value,
        });
      });
      arrayVariants.push({
        id: variant.id!,
        names: arrayVariantNames,
      });
    });

    setNames(arrayNames);
    setDescriptions(arrayDescriptions);
    setOptions(arrayOption);
    setVariants(arrayVariants);
  }, [product, language]);

  const [updateDictionary, { loading }] = useMutation<
    UpdateProductDictionaryMutation,
    UpdateProductDictionaryMutationVariables
  >(UPDATE_PRODUCT_DICTIONARY, {
    refetchQueries: () => [
      {
        query: GET_PRODUCT_DETAILS,
        variables: {
          input: { id: idProduct || "", token },
        },
      },
    ],
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully updated",
      });
    },
    onError: (error) => {
      if (error.message === "product_not_found") {
        snackBarVar({
          open: true,
          severity: "error",
          message: "Product not fount",
        });
      } else {
        snackBarVar({
          open: true,
          severity: "error",
          message: "service unavailable",
        });
      }
    },
  });

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setNames((prevNames) => {
      const arrayNames = [...prevNames];
      const position = findIndex(
        arrayNames,
        (o) => o.languageCode === language
      );
      if (position !== -1) {
        arrayNames[position] = {
          ...arrayNames[position],
          value: event.target.value,
        };
      } else {
        arrayNames.push({
          languageCode: language,
          value: event.target.value,
        });
      }
      return arrayNames;
    });
  };

  const handleDescription = (value: EditorState) => {
    setEditorState(value);
  };

  const handleOptionName =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setOptions((prevOption) => {
        const arrayOption = [...prevOption];
        const indexOptionFind = findIndex(arrayOption, (obj) => obj.id === id);
        if (indexOptionFind !== -1) {
          const indexNameFind = findIndex(
            arrayOption?.[indexOptionFind]?.names,
            (obj) => obj.languageCode === language
          );
          const arrayName = arrayOption[indexOptionFind]?.names;
          if (indexNameFind !== -1) {
            if (arrayName) {
              arrayName[indexNameFind] = {
                ...arrayName[indexNameFind],
                value: event.target.value,
              };
              arrayOption[indexOptionFind] = {
                ...arrayOption[indexOptionFind],
                names: arrayName,
              };
            }
          } else {
            arrayOption?.[indexOptionFind]?.names?.push({
              languageCode: language,
              value: event.target.value,
            });
          }
        }
        return arrayOption;
      });
    };

  const handleOptionValue =
    (idOption: string, idValue: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setOptions((prevOptions) => {
        const arrayOptions = [...prevOptions];
        const indexOptionFind = findIndex(
          arrayOptions,
          (obj) => obj.id === idOption
        );
        if (indexOptionFind !== -1) {
          const indexValueFind = findIndex(
            arrayOptions?.[indexOptionFind]?.values,
            (obj) => obj.id === idValue
          );

          if (indexValueFind !== -1) {
            const arrayNamesValueFind =
              arrayOptions?.[indexOptionFind]?.values?.[indexValueFind].names;
            if (arrayNamesValueFind) {
              const indexName = findIndex(
                arrayNamesValueFind,
                (obj) => obj.languageCode === language
              );
              if (indexName !== -1) {
                arrayNamesValueFind[indexName] = {
                  ...arrayNamesValueFind[indexName],
                  value: event.target.value,
                };
              } else {
                arrayOptions?.[indexOptionFind]?.values?.[
                  indexValueFind
                ]?.names?.push({
                  languageCode: language,
                  value: event.target.value,
                });
              }
            }
          }
        }
        return arrayOptions;
      });
    };

  const handleVariants =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setVariants((prevVariants) => {
        const arrayVariants = [...prevVariants];
        const findVariantIndex = findIndex(arrayVariants, (o) => o.id === id);

        if (findVariantIndex !== -1) {
          const findNameIndex = findIndex(
            arrayVariants[findVariantIndex].names,
            (o) => o.languageCode === language
          );
          const arrayNames = arrayVariants?.[findVariantIndex]?.names;
          if (findNameIndex !== -1) {
            if (arrayNames) {
              arrayNames[findNameIndex] = {
                ...arrayNames[findNameIndex],
                value: event.target.value,
              };
              arrayVariants[findVariantIndex] = {
                ...arrayVariants[findVariantIndex],
                names: arrayNames,
              };
            }
          } else {
            arrayVariants?.[findVariantIndex]?.names?.push({
              languageCode: language,
              value: event.target.value,
            });
          }
        }

        return arrayVariants;
      });
    };

  const handleSave = () => {
    const arrayDescriptions: UpdateDescriptionInput[] = [];

    descriptions.map((item) =>
      arrayDescriptions.push({
        id: item.id,
        languageCode: item.languageCode,
        value: item.value,
      })
    );

    const descriptionContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const position = findIndex(
      arrayDescriptions,
      (o) => o.languageCode === language
    );

    if (position !== -1) {
      arrayDescriptions[position] = {
        ...arrayDescriptions[position],
        value: descriptionContent,
      };
    } else {
      arrayDescriptions.push({
        languageCode: language,
        value: descriptionContent,
      });
    }

    updateDictionary({
      variables: {
        input: {
          token,
          id: idProduct || "",
          names,
          descriptions: arrayDescriptions,
          options,
          variants,
        },
      },
    });
  };

  return (
    <Wrapper>
      <div className="header">
        <Typography variant="h3" style={{ fontWeight: 600 }}>
          {product?.names?.[0]?.value}
        </Typography>
        <div>
          <ButtonCustomize
            onClick={() => onCleanIdProduct(undefined)}
            style={{ marginRight: 10 }}
          >
            Cancel
          </ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            disabled={loading}
          >
            Save
          </ButtonCustomize>
        </div>
      </div>
      <Grid container spacing={1} style={{ marginBottom: 5 }}>
        {/* Name product */}
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            label="Product name"
            value={
              find(product?.names, (o) => o.languageCode === origineLangage)
                ?.value || ""
            }
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            name="name"
            label="Product name"
            value={find(names, (o) => o.languageCode === language)?.value || ""}
            onChange={handleName}
            fullWidth
          />
        </Grid>
        {/* Description product */}
        <Grid item xs="auto" style={{ width: "48%" }}>
          {/* <Box className="disabled"> */}
          <Editor
            editorState={EditorState.createWithContent(contentState)}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={[]}
          />
          {/* </Box> */}
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={handleDescription}
            placeholder="Please insert page content ... "
          />
          <div />
        </Grid>

        {/* option product */}
        {product?.options?.map((item) => (
          <>
            <Grid item xs="auto" style={{ width: "48%" }}>
              <Input
                label={`Option ${
                  find(item.names, (o) => o.languageCode === origineLangage)
                    ?.value
                } name`}
                value={
                  find(item.names, (o) => o.languageCode === origineLangage)
                    ?.value || ""
                }
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs="auto" style={{ margin: "0 auto" }}>
              <NavigateNextIcon style={{ color: "#C1C3D4" }} />
            </Grid>
            <Grid item xs="auto" style={{ width: "48%" }}>
              <Input
                name="option"
                label={`Option ${
                  find(item.names, (o) => o.languageCode === origineLangage)
                    ?.value || ""
                } name`}
                value={
                  find(
                    find(options, (o) => o.id === item.id)?.names,
                    (obj) => obj.languageCode === language
                  )?.value || ""
                }
                onChange={handleOptionName(item.id!)}
                fullWidth
              />
            </Grid>
            {/* show rows for value option */}
            {item.values?.map((value) => (
              <>
                <Grid item xs="auto" style={{ width: "48%" }}>
                  <Input
                    label={`Option ${
                      find(
                        value.names,
                        (o) => o.languageCode === origineLangage
                      )?.value
                    } name`}
                    value={
                      find(
                        value.names,
                        (o) => o.languageCode === origineLangage
                      )?.value || ""
                    }
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs="auto" style={{ margin: "0 auto" }}>
                  <NavigateNextIcon style={{ color: "#C1C3D4" }} />
                </Grid>
                <Grid item xs="auto" style={{ width: "48%" }}>
                  <Input
                    name="option"
                    label={`Option ${
                      find(
                        value.names,
                        (o) => o.languageCode === origineLangage
                      )?.value || ""
                    } name`}
                    value={
                      find(
                        find(
                          find(options, (o) => o.id === item.id)?.values,
                          (o) => o.id === value.id
                        )?.names,
                        (o) => o.languageCode === language
                      )?.value || ""
                    }
                    onChange={handleOptionValue(item.id!, value.id!)}
                    fullWidth
                  />
                </Grid>
              </>
            ))}
          </>
        ))}

        {/* Variation product */}

        {product?.variants?.map((item) => (
          <>
            <Grid item xs="auto" style={{ width: "48%" }}>
              <Input
                label={`Variation ${item.names?.[0]?.value} name`}
                value={
                  find(item.names, (o) => o.languageCode === origineLangage)
                    ?.value || ""
                }
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs="auto" style={{ margin: "0 auto" }}>
              <NavigateNextIcon style={{ color: "#C1C3D4" }} />
            </Grid>
            <Grid item xs="auto" style={{ width: "48%" }}>
              <Input
                name="variants"
                label={`Variation ${
                  find(item.names, (o) => o.languageCode === origineLangage)
                    ?.value || ""
                } name`}
                value={
                  find(
                    find(variants, (o) => o.id === item.id)?.names,
                    (o) => o.languageCode === language
                  )?.value || ""
                }
                onChange={handleVariants(item.id!)}
                fullWidth
              />
            </Grid>
          </>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default TranslateProductForm;

const Wrapper = styled.div`
  display: flex;
  min-width: 820px;
  flex-direction: column;
  padding: 0 10px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .wrapper-class {
    /* min-height: calc(100vh - 310px); */
    min-height: calc(100vh - 620px);
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #d2d9e1;
    border-radius: 8px;
  }
  .rdw-editor-main {
    /* height: calc(100% - 50px); */
    height: calc(100vh - 620px);
  }
  .toolbar-class {
    border: none;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .public-DraftStyleDefault-ltr {
    padding-right: 10px;
    padding-left: 10px;
  }
  .public-DraftEditorPlaceholder-root {
    padding-right: 10px;
    padding-left: 10px;
  }
  .rdw-option-wrapper {
    border: none;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #f29f05;
    border-radius: 5px;
  }
  .rdw-dropdown-wrapper {
    border: none;
  }
  .disabled {
    pointer-events: none;
    background: #f1f2fb;
    opacity: 0.7;
    border-radius: 10px;
  }
  .rdw-editor-toolbar {
    pointer-events: none;
    background: #f1f2fb;
    opacity: 0.7;
  }
`;
