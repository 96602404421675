import styled from "styled-components";

export const Seperator = styled.div`
  width: 100%;
  height: 20px;
`;

export const Wrapper = styled.div`
  .seperator-upload {
    width: 1px;
    background: #f1f2fb;
    margin: 0 10px;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .title {
    margin-bottom: 15px;
    font-weight: 700;
  }
  .MuiSelect-select.Mui-disabled {
    background: #e4e4e4;
  }
  .wrapper-class {
    height: 400px;
    /* height: calc(100vh - 590px); */
    min-width: 800px;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #d2d9e1;
    border-radius: 8px;
  }
  .rdw-editor-main {
    /* height: calc(100vh - 690px); */
    height: 320px;
    overflow-x: auto;
  }
  .toolbar-class {
    border: none;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .public-DraftStyleDefault-ltr {
    padding-right: 10px;
    padding-left: 10px;
  }
  .public-DraftEditorPlaceholder-root {
    padding-right: 10px;
    padding-left: 10px;
  }
  .rdw-option-wrapper {
    border: none;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #f29f05;
    border-radius: 5px;
  }
  .rdw-dropdown-wrapper {
    border: none;
  }
  .info-upload-picture {
    position: absolute;
    display: flex;
    align-items: center;
    width: 345px;
    right: 16px;
    background-color: rgba(26, 92, 255, 0.04);
    color: #64b5f6;
    border: 2px dashed rgba(26, 92, 255, 0.15);
    padding: 3px 3px 3px 7px;
    border-radius: 5px;
  }
`;
