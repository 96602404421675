import * as React from "react";
import styled from "styled-components";
import { Grid, Typography, Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { ButtonCustomize, Input } from "../../commons";

import {
  GET_MENU_LEGALITY_SETTINGS,
  UPDATE_MENU_LEGALITY_PAGE,
} from "../../api";

import {
  GetMenuLegalitySettingsQuery,
  GetMenuLegalitySettingsQueryVariables,
  UpdateMenuLegalityPageMutation,
  UpdateMenuLegalityPageMutationVariables,
  MenuPayload,
  LanguageCode,
  PagePayload,
  UpdateNameInput,
} from "../../api/types";
import { SelectedMenuVar, snackBarVar } from "../../api/local-state";

type FormTranslateLegalityProps = {
  identifier: string | undefined;
  language: LanguageCode | undefined;
  origineLangage: LanguageCode;
};

const FormTranslateLegality = ({
  identifier,
  language,
  origineLangage,
}: FormTranslateLegalityProps): JSX.Element => {
  const token = localStorage.getItem("token") || "";
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);

  const [legality, setLegality] = React.useState<PagePayload>();
  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const { data } = useQuery<
    GetMenuLegalitySettingsQuery,
    GetMenuLegalitySettingsQueryVariables
  >(GET_MENU_LEGALITY_SETTINGS, {
    variables: {
      input: {
        token,
        id: localMenu?.id || "",
      },
    },
    onCompleted: (listLegality) => {
      const selectLegality = listLegality.getMenuLegalitySettings?.pages?.find(
        (item) => item.identifier === identifier
      );

      const content =
        find(selectLegality?.content, (o) => o.languageCode === language)
          ?.value || "";

      const blocksFromHtml = htmlToDraft(content);
      const { contentBlocks, entityMap } = blocksFromHtml;

      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      setLegality(selectLegality);
      setEditorState(EditorState.createWithContent(contentState));
    },
    skip: !localMenu?.id,
  });

  const content =
    find(legality?.content, (o) => o.languageCode === origineLangage)?.value ||
    "";

  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  const [UpdateLegality, { loading: loadingUpdate }] = useMutation<
    UpdateMenuLegalityPageMutation,
    UpdateMenuLegalityPageMutationVariables
  >(UPDATE_MENU_LEGALITY_PAGE, {
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "operation successful",
      });
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable",
      });
    },
    refetchQueries: () => [
      {
        query: GET_MENU_LEGALITY_SETTINGS,
        variables: {
          input: {
            token,
            id: localMenu?.id || "",
          },
        },
      },
    ],
  });

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const arrayTitle = [...(legality?.title || [])];
    const position = findIndex(arrayTitle, (o) => o.languageCode === language);
    if (position !== -1) {
      arrayTitle[position] = {
        ...arrayTitle[position],
        value: event.target.value,
      };
    } else {
      arrayTitle.push({
        languageCode: language,
        value: event.target.value,
      });
    }
    setLegality({
      ...legality,
      title: arrayTitle,
    });
  };

  const handleChange = (value: EditorState) => {
    setEditorState(value);
  };

  const handleSubmit = () => {
    const arrayTitle: UpdateNameInput[] = [];
    const arrayContent: UpdateNameInput[] = [];

    const legalityContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    legality?.title?.map((item) =>
      arrayTitle.push({
        id: item.id,
        languageCode: item.languageCode as LanguageCode,
        value: item.value,
      })
    );

    legality?.content?.map((item) =>
      arrayContent.push({
        id: item.id,
        languageCode: item.languageCode as LanguageCode,
        value: item.value,
      })
    );

    const position = findIndex(
      arrayContent,
      (o) => o.languageCode === language
    );
    if (position !== -1) {
      arrayContent[position] = {
        ...arrayContent[position],
        value: legalityContent,
      };
    } else {
      arrayContent.push({
        languageCode: language,
        value: legalityContent,
      });
    }

    UpdateLegality({
      variables: {
        input: {
          token,
          menu: localMenu?.id || "",
          id: legality?.id || "",
          identifier: legality?.identifier,
          title: arrayTitle,
          content: arrayContent,
          styles: legality?.styles,
          isPublished: legality?.isPublished,
        },
      },
    });
  };

  return (
    <Wrapper>
      <div className="header">
        <Typography variant="h3" style={{ fontWeight: 600 }}>
          {
            find(legality?.title, (o) => o.languageCode === origineLangage)
              ?.value
          }
        </Typography>
        <div>
          <ButtonCustomize style={{ marginRight: 10 }}>Cancel</ButtonCustomize>
          <ButtonCustomize
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loadingUpdate}
          >
            Save
          </ButtonCustomize>
        </div>
      </div>
      <Grid
        container
        // alignItems="center"
        spacing={1}
        style={{ marginBottom: 5 }}
      >
        {/* name legality */}
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            label="Title"
            value={
              find(legality?.title, (o) => o.languageCode === origineLangage)
                ?.value || ""
            }
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Input
            name="name"
            label="Title"
            value={
              find(legality?.title, (o) => o.languageCode === language)
                ?.value || ""
            }
            onChange={handleTitle}
            fullWidth
          />
        </Grid>
        {/* content legality */}
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Box className="disabled">
            <Editor
              editorState={EditorState.createWithContent(contentState)}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </Box>
        </Grid>
        <Grid item xs="auto" style={{ margin: "0 auto" }}>
          <NavigateNextIcon style={{ color: "#C1C3D4" }} />
        </Grid>
        <Grid item xs="auto" style={{ width: "48%" }}>
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={handleChange}
            placeholder="Please insert page content ... "
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FormTranslateLegality;

const Wrapper = styled.div`
  display: flex;
  min-width: 820px;
  flex-direction: column;
  padding: 0 10px;
  height: calc(100vh - 180px);
  overflow-y: auto;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .wrapper-class {
    min-height: calc(100vh - 310px);
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #d2d9e1;
    border-radius: 8px;
  }
  .rdw-editor-main {
    height: calc(100% - 50px);
  }
  .toolbar-class {
    border: none;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .public-DraftStyleDefault-ltr {
    padding-right: 10px;
    padding-left: 10px;
  }
  .public-DraftEditorPlaceholder-root {
    padding-right: 10px;
    padding-left: 10px;
  }
  .rdw-option-wrapper {
    border: none;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #f29f05;
    border-radius: 5px;
  }
  .rdw-dropdown-wrapper {
    border: none;
  }
  .disabled {
    pointer-events: none;
    background: #f1f2fb;
    opacity: 0.7;
    border-radius: 10px;
  }
`;
