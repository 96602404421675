import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import findIndex from "lodash/findIndex";

import LinkNav from "./LinkNav";

import {
  listPathnameInitial,
  listPathnameInitialRawi,
  listPathnameInitialCwallet,
  ListPathnameInitialPayload,
  listPathnameInitialForus,
  listPathnameInitialMeiz,
  ValuesPayload,
} from "../utils";

import {
  GET_STAFF_INFORMATION,
  HOST_RAWI,
  HOST_CSHOP,
  HOST_FORSA,
  HOST_MEIZ,
} from "../api";
import {
  GetStaffInformationQuery,
  GetStaffInformationQueryVariables,
} from "../api/types";

const { host } = window.location;

const ListMenuNav: React.FC = () => {
  const location = useLocation();
  const token = localStorage.getItem("token") || "";
  const kind = localStorage.getItem("kind");
  const hasMultiVendor = localStorage.getItem("hasMultiVendor");
  const { pathname } = location;
  const history = useHistory();

  const [listPathname, setListPathname] = useState(listPathnameInitial);

  const { data: staffInformation } = useQuery<
    GetStaffInformationQuery,
    GetStaffInformationQueryVariables
  >(GET_STAFF_INFORMATION, {
    variables: {
      input: {
        token,
        languageCode: "EN",
      },
    },
    skip: kind === "Manager",
  });

  useEffect(() => {
    if (host === HOST_RAWI) {
      setListPathname(listPathnameInitialRawi);
    }
    if (host === HOST_CSHOP) {
      setListPathname(listPathnameInitialCwallet);
    }
    if (host === HOST_FORSA) {
      setListPathname(listPathnameInitialForus);
    }
    if (host === HOST_MEIZ) {
      setListPathname(listPathnameInitialMeiz);
    }
  }, [host]);

  useEffect(() => {
    if (staffInformation && kind !== "Manager") {
      const arrayPaths: ListPathnameInitialPayload[] = [];

      staffInformation?.getStaffInformation?.information?.permissions?.forEach(
        (item) => {
          listPathnameInitial?.forEach((path) => {
            if (item.kind === path.kind) {
              const arrayValues: ValuesPayload[] = [];
              item.values?.forEach((subItem) => {
                path.values?.forEach((subPath) => {
                  if (subPath.name === subItem.names?.[0]?.value)
                    arrayValues.push(subPath);
                });
              });
              arrayPaths.push({
                kind: path.kind,
                icon: path.icon,
                show: path.show,
                values: arrayValues,
              });
            }
          });
        }
      );
      setListPathname(arrayPaths);
    }
  }, [staffInformation]);

  const memoizedPathnames = useMemo(() => {
    const arrayPathname: string[] = [];
    listPathname.forEach((item) =>
      item.values?.forEach((value) => {
        if (value.pathnames.length > 0) {
          value.pathnames.forEach((subPathname) => {
            arrayPathname.push(subPathname);
          });
        }
        arrayPathname.push(value.originPathname);
      })
    );
    return arrayPathname;
  }, [listPathname]);

  useEffect(() => {
    if (kind === "Staff") {
      if (pathname !== "/account" && pathname !== "/login") {
        if (!memoizedPathnames.find((path) => pathname.includes(path))) {
          history.push(memoizedPathnames[0]);
        }
      }
    }
    if (hasMultiVendor !== "true" && pathname === "/vendor") {
      history.goBack();
    }
  }, [pathname, memoizedPathnames]);

  useEffect(() => {
    if (hasMultiVendor !== "true") {
      setListPathname((prevListPathname) => {
        const arrayListPathname = [...prevListPathname];
        arrayListPathname.forEach((item, index) => {
          const position = findIndex(item.values, (o) => o.name === "Vendor");
          if (position !== -1) {
            arrayListPathname?.[index]?.values.splice(position, 1);
          }
        });
        return arrayListPathname;
      });
    }
  }, [hasMultiVendor]);

  const handleShow = (index: number) => {
    setListPathname((prevListPathname) => {
      const arrayListPathname = [...prevListPathname];
      arrayListPathname[index] = {
        ...arrayListPathname[index],
        show: !arrayListPathname[index].show,
      };
      return arrayListPathname;
    });
  };

  return (
    <Wrapper>
      {listPathname.map((item, index) => (
        <li>
          <div
            role="button"
            tabIndex={0}
            onKeyUp={() => {
              handleShow(index);
            }}
            onClick={() => {
              handleShow(index);
            }}
          >
            <span>
              <img src={item.icon} alt={item.kind} />
              {item.kind}
            </span>
            <i className={`arrow ${item.show && "down"}`} />
          </div>
          <ul className={`sub-menu ${!item.show && "hidden"}`}>
            {item?.values?.map((subItem) => (
              <LinkNav
                link={subItem?.originPathname}
                nameLink={subItem?.name}
                pathname={pathname}
                arrayPathnames={subItem.pathnames}
              />
            ))}
          </ul>
        </li>
      ))}
    </Wrapper>
  );
};

export default ListMenuNav;

const Wrapper = styled.ul`
  line-height: 3;
  height: calc(100vh - 70px);
  overflow-y: auto;
  > li {
    cursor: pointer;
    div {
      display: flex;
      align-items: center;
      span {
        display: flex;
        flex: 1;
        align-items: center;
        font-weight: bold;
        font-size: 13px;
      }
      .arrow {
        height: 2px;
        width: 2px;
        border: solid #444444;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
        cursor: pointer;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transition: transform 0.3s linear;
        margin-right: 15px;
      }
      .down {
        transform: rotate(135deg);
        -webkit-transform: rotate(45deg);
      }
    }
    img {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      color: #444444;
    }
    .sub-menu {
      list-style: none;
      font-size: 12px;
      visibility: visible;
      height: auto;
    }
    .hidden {
      visibility: hidden;
      height: 0;
    }
  }
`;
