import gql from "graphql-tag";

export const UPDATE_HOME_PAGE = gql`
  mutation updateHomePage($input: SetHomePageInput!) {
    setHomePage(input: $input) {
      success
    }
  }
`;
export const GET_LIST_TAGS_BY_MENU = gql`
  query getListTagsByMenu($input: GetListTagsByMenuInput!) {
    getListTagsByMenu(input: $input) {
      tags
    }
  }
`;

export const GET_HOME_PAGE = gql`
  query getHomePage($input: GetHomePageInput!) {
    getHomePage(input: $input) {
      slides {
        id
        fileUrl
      }
      partnerSlides {
        id
        fileUrl
      }
      collections {
        position
        titles {
          id
          value
          languageCode
        }
        tags
        picture {
          id
          fileUrl
        }
      }
    }
  }
`;

export const UPDATE_HOME_PAGE_CSHOP = gql`
  mutation updateHomePageCshop($input: SetHomePageV2Input!) {
    setHomePageV2(input: $input) {
      success
    }
  }
`;

export const GET_HOME_PAGE_CSHOP = gql`
  query getHomePageV2($input: GetHomePageV2Input!) {
    getHomePageV2(input: $input) {
      slides {
        picture {
          id
          fileUrl
        }
        kind
        object
      }
      collections {
        position
        titles {
          id
          languageCode
          value
        }
        tags
        picture {
          id
          fileUrl
        }
        kind
        object
      }
      products {
        id
        identifier
        names {
          id
          languageCode
          value
        }
      }
    }
  }
`;
