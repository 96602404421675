import React, { FC } from "react";
import styled from "styled-components";
import { Typography, IconButton } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

type RowsProps = {
  nameFields: string[];
  data: { [key: string]: string }[];
  onDelete: (id: string) => void;
};

const Rows: FC<RowsProps> = ({ nameFields, data, onDelete }) => {
  const getcolumns = (fields: number) => {
    const template: string[] = [];

    for (let i = 1; i < fields; i++) {
      template.push("1fr");
    }
    return template.join(" ");
  };

  return (
    <Wrapper rows={getcolumns(nameFields.length)}>
      {nameFields.map((name) => (
        <div className="case">
          <Typography
            variant="body2"
            style={{ fontWeight: 700, padding: "20px 0" }}
          >
            {name}
          </Typography>
        </div>
      ))}

      {data.map((item: { [key: string]: string }) =>
        Object.entries(item).map(([key, value]) => (
          <>
            {key !== "id" && key !== "action" && (
              <div className="case">
                <Typography variant="body2">{value}</Typography>
              </div>
            )}

            {value === "delete" && (
              <div className="case">
                <IconButton
                  component="span"
                  size="medium"
                  onClick={() => {
                    onDelete(item.id);
                  }}
                >
                  <DeleteIcon fontSize="inherit" color="error" />
                </IconButton>
              </div>
            )}
          </>
        ))
      )}
    </Wrapper>
  );
};

type RowType = {
  rows: string;
};

const Wrapper = styled.div<RowType>`
  display: grid;
  max-height: 600px;
  grid-template-columns: ${(props) => props.rows} 50px;
  overflow-x: auto;
  margin: 5px 0;
  .case {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edeff2;
    padding: 3px 0;
  }
`;

export default Rows;
