import React, { FC } from "react";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import {
  Button,
  ClickAwayListener,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ReactSortable } from "react-sortablejs";
import debounce from "lodash/debounce";
import find from "lodash/find";

import ModalProductList from "./ModalProductList";
import {
  Tree,
  DropDown,
  DropDownItem,
  TablePagination,
  Modal,
  BreadCrumbsTitle,
  LoadingListCategores,
} from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import {
  GETLISTMENUCATEGORIES,
  GET_LIST_CATEGORY_PRODUCTS,
  DELETE_CATEGORY_PRODUCTS,
  SET_MENU_CATEGORIES_ORDER,
  HOST_CSHOP,
  HOST_RAWI,
} from "../../../api";
import {
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables,
  GetListCategoryProductsQuery,
  GetListCategoryProductsQueryVariables,
  DeleteCategoryProductMutation,
  DeleteCategoryProductMutationVariables,
  SetMenuCategoriesOrderMutation,
  SetMenuCategoriesOrderMutationVariables,
  MenuCategoryPayload,
  MenuCategoryOrderInput,
  MenuPayload,
} from "../../../api/types";
import { snackBarVar, SelectedMenuVar } from "../../../api/local-state";

import { ReactComponent as More } from "../../../img/more.svg";
import { ReactComponent as NewItem } from "../../../img/newItem.svg";
import { ReactComponent as Add } from "../../../img/add.svg";
import { ReactComponent as SearchIcon } from "../../../img/search.svg";
import { Wrapper, EmptyState, Menu, Search } from "./styles";

interface ItemType {
  id: number;
  category: MenuCategoryPayload;
}

const { host } = window.location;

const ListCategories: FC = () => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const localMenu = useReactiveVar<MenuPayload | undefined>(SelectedMenuVar);
  const languageIsMain = find(
    localMenu?.languages,
    (o) => o.isMain === true
  )?.language;

  // States
  const [selectedCategory, setSelectedCategory] = React.useState<
    string | undefined | null
  >();
  const [rank1Categories, setRank1Categories] = React.useState<ItemType[]>([]);
  const [search, setSearch] = React.useState("");
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [productToDelete, setProductToDelete] = React.useState("");
  const [categoriesSortable, setCategoriesSortable] = React.useState<
    MenuCategoryOrderInput[]
  >([]);

  // Queries & Mutaions
  const {
    data: categoriesListData,
    loading: loadingCategory,
    refetch,
  } = useQuery<GetListMenuCategoriesQuery, GetListMenuCategoriesQueryVariables>(
    GETLISTMENUCATEGORIES,
    {
      variables: { input: { token, menu: localMenu?.id || "" } },
    }
  );

  const categories = categoriesListData?.getListMenuCategories.list;

  React.useEffect(() => {
    const arrayState: ItemType[] = [];
    if (categoriesListData) {
      categoriesListData?.getListMenuCategories.list.forEach((item) => {
        if (item.order && item.level === 1)
          arrayState.push({
            id: item.order,
            category: item,
          });
      });
    }
    setRank1Categories(arrayState);
  }, [categoriesListData]);

  const rank2Categories = categories?.filter(
    (category) => category.level === 2
  );
  const rank3Categories = categories?.filter(
    (category) => category.level === 3
  );

  const { data: categoriesProductsListData, loading } = useQuery<
    GetListCategoryProductsQuery,
    GetListCategoryProductsQueryVariables
  >(GET_LIST_CATEGORY_PRODUCTS, {
    variables: { input: { token, category: selectedCategory || "" } },
    fetchPolicy: "network-only",
    skip: !selectedCategory,
  });

  const products = categoriesProductsListData?.getListCategoryProducts.list;

  const [deleteCategoryProduct] = useMutation<
    DeleteCategoryProductMutation,
    DeleteCategoryProductMutationVariables
  >(DELETE_CATEGORY_PRODUCTS, {
    refetchQueries: () => [
      {
        query: GET_LIST_CATEGORY_PRODUCTS,
        variables: { input: { token, category: selectedCategory || "" } },
      },
    ],
    onCompleted: () => {
      setProductToDelete("");
      snackBarVar({
        open: true,
        severity: "success",
        message: "Product has been deleted",
      });
    },
  });

  const [SetCategoriesOrder] = useMutation<
    SetMenuCategoriesOrderMutation,
    SetMenuCategoriesOrderMutationVariables
  >(SET_MENU_CATEGORIES_ORDER, {
    variables: {
      order: {
        token,
        categories: categoriesSortable,
      },
    },
    onCompleted: () => {
      refetch();
    },
  });

  // Functions
  const handleClose = () => {
    setOpen(false);
  };

  const handleCategory = (id?: string | null) => {
    const currentCategory = categories?.filter(
      (category) => category.id === (id || selectedCategory)
    )?.[0];

    history.push({
      pathname: "/create-category",
      state: {
        level: (currentCategory?.level || 0) + 1,
        parent: currentCategory?.id || null,
        main: currentCategory?.main || null,
      },
    });
  };

  const handleOrder = () => {
    const arrayCategoriesSortable: MenuCategoryOrderInput[] = [];
    rank1Categories.forEach((item, index) => {
      if (item?.category?.id)
        arrayCategoriesSortable.push({
          id: item?.category?.id,
          order: index + 1,
        });
    });
    setCategoriesSortable(arrayCategoriesSortable);
  };

  const sendOrder = React.useCallback(
    debounce(() => SetCategoriesOrder(), 2000),
    []
  );

  const handleOnEdit = (id: string) => {
    if (host !== HOST_RAWI && host !== HOST_CSHOP) {
      history.push({
        pathname: `/managment-product/${id}`,
        state: {
          attributes: false,
          generale: true,
          options: false,
          stock: false,
          variation: false,
        },
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTableRows = () => {
    return (
      products
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((product) => ({
          id: product?.id,
          name:
            find(product.names, (o) => o.languageCode === languageIsMain?.code)
              ?.value || "",
          value: (
            <>
              {product?.price?.value} &nbsp; {product.price?.currency?.code}
            </>
          ),
          actions: (
            <>
              {host !== HOST_CSHOP && host !== HOST_RAWI && (
                <IconButton
                  size="small"
                  color="primary"
                  style={{ color: "#444444", margin: "0 4px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnEdit(product.id || "");
                  }}
                >
                  <CreateIcon />
                </IconButton>
              )}
              <IconButton
                size="small"
                color="primary"
                style={{ color: "#ff4949", margin: "0 4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setProductToDelete(product.id || "");
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ),
        })) || []
    );
  };

  const columns: ColumnsProps = [
    { header: "Name", accessor: "name" },
    { header: "Value", accessor: "value" },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  return (
    <>
      <BreadCrumbsTitle
        labelIntl={["Product Management", "Categories"]}
        iconAlt="Categories"
      />
      <Wrapper>
        <Box
          width={1}
          bgcolor="#ffffff"
          border="2px solid #f3f4fa"
          borderRadius="10px"
        >
          <div className="header">
            <Typography variant="h2">Categories</Typography>
            <Button
              variant="contained"
              disableElevation
              size="medium"
              color="secondary"
              onClick={() => handleCategory()}
            >
              Add Category
            </Button>
          </div>

          <div className="content">
            <ReactSortable
              list={rank1Categories}
              setList={setRank1Categories}
              animation={200}
              onSort={async () => {
                await handleOrder();
                sendOrder();
              }}
            >
              {categories &&
                categories?.length > 0 &&
                // rank1Categories?.map((category1) => {
                rank1Categories?.map((category1) => {
                  return (
                    <Tree
                      id={category1.category.id || ""}
                      key={category1.category.id}
                      level={1}
                      title={
                        find(
                          category1.category.names,
                          (o) => o.languageCode === languageIsMain?.code
                        )?.value
                      }
                      isPublished={category1.category.isPublished || false}
                      onSelect={() =>
                        setSelectedCategory(category1.category.id)
                      }
                      onAddSubcategory={() =>
                        handleCategory(category1.category.id)
                      }
                    >
                      {rank2Categories
                        ?.filter(
                          (category2) =>
                            category2.parent === category1.category.id
                        )
                        ?.map((category2) => {
                          return (
                            <Tree
                              id={category2.id || ""}
                              key={category2.id}
                              level={2}
                              title={
                                find(
                                  category2.names,
                                  (o) => o.languageCode === languageIsMain?.code
                                )?.value
                              }
                              isPublished={category2.isPublished || false}
                              onSelect={() => setSelectedCategory(category2.id)}
                              onAddSubcategory={() =>
                                handleCategory(category2.id)
                              }
                            >
                              {rank3Categories
                                ?.filter(
                                  (category3) =>
                                    category3.parent === category2.id
                                )
                                ?.map((category3) => {
                                  return (
                                    <Tree
                                      id={category3.id || ""}
                                      key={category3.id}
                                      level={3}
                                      title={
                                        find(
                                          category3.names,
                                          (o) =>
                                            o.languageCode ===
                                            languageIsMain?.code
                                        )?.value
                                      }
                                      isPublished={
                                        category3.isPublished || false
                                      }
                                      onAddSubcategory={() =>
                                        handleCategory(category3.id)
                                      }
                                      onSelect={() =>
                                        setSelectedCategory(category3.id)
                                      }
                                    />
                                  );
                                })}
                            </Tree>
                          );
                        })}
                    </Tree>
                  );
                })}
            </ReactSortable>
            {categories && categories?.length === 0 && (
              <EmptyState>You don&apos;t have any categories yet!</EmptyState>
            )}
            {loadingCategory && <LoadingListCategores />}
          </div>
        </Box>

        <Box
          width={1}
          bgcolor="#ffffff"
          border="2px solid #f3f4fa"
          borderRadius="10px"
        >
          <div className="header">
            <Typography variant="h2">
              Products
              {selectedCategory &&
                ` - ${
                  categories
                    ?.filter(
                      (category) => category.id === selectedCategory
                    )?.[0]
                    ?.names?.find(
                      (o) => o?.languageCode === languageIsMain?.code
                    )?.value
                }`}
            </Typography>
            {selectedCategory && (
              <div className="top-bar-actions">
                <Button
                  onClick={() => setOpen(true)}
                  variant="contained"
                  color="secondary"
                  disableElevation
                >
                  Assign products
                </Button>
                <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                  <div className="menu">
                    <Menu onClick={() => setOpenMenu(!openMenu)}>
                      <More width="20" height="20" />
                    </Menu>
                    <DropDown open={openMenu}>
                      <Link
                        to={{
                          pathname:
                            HOST_RAWI === host || host === HOST_CSHOP
                              ? "add-product-type"
                              : "/managment-product",
                          state: { categoryParent: selectedCategory },
                        }}
                      >
                        <DropDownItem onClick={() => {}}>
                          <NewItem width="20" height="20" />
                          <span>Create product</span>
                        </DropDownItem>
                      </Link>
                      <hr />
                      <DropDownItem onClick={handleCategory}>
                        <Add width="20" height="20" />
                        <span>Add a Subcategory</span>
                      </DropDownItem>
                    </DropDown>
                  </div>
                </ClickAwayListener>
              </div>
            )}
          </div>
          <Box
            display="flex"
            flexDirection="column"
            className="content products"
          >
            {!loading ? (
              products && products.length > 0 ? (
                <>
                  <Box flex="1">
                    <Table
                      columns={columns}
                      data={renderTableRows()}
                      emptyMessage="Assign or add your first product"
                      onClickRow={(row) => row.id && handleOnEdit(row.id)}
                    />
                  </Box>

                  <TablePagination
                    labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                    count={products?.length || 0}
                    page={page}
                    onPageChange={(_, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <EmptyState>No Products Assigned Yet!</EmptyState>
              )
            ) : (
              <EmptyState>
                <CircularProgress size={24} style={{ position: "absolute" }} />
              </EmptyState>
            )}
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <Search>
            <DialogTitle id="alert-dialog-title">
              {selectedCategory &&
                ` Assign products to ${
                  categories
                    ?.filter(
                      (category) => category.id === selectedCategory
                    )?.[0]
                    ?.names?.find(
                      (o) => o?.languageCode === languageIsMain?.code
                    )?.value
                }`}
            </DialogTitle>
            <div className="search">
              <input
                type="text"
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button type="button" className="search-btn">
                <SearchIcon width="22" height="22" />
              </button>
            </div>
          </Search>
          <ModalProductList
            handleClose={handleClose}
            category={selectedCategory || ""}
            search={search}
          />
        </Dialog>
        <Modal
          open={!!productToDelete}
          title="Delete product"
          message="Do you want to delete this product"
          handleClose={() => setProductToDelete("")}
          handleContent={() =>
            deleteCategoryProduct({
              variables: { token, id: productToDelete },
            })
          }
          action="delete"
          loading={false}
        />
      </Wrapper>
    </>
  );
};

export default ListCategories;
