import styled from "styled-components";

export const Container = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 16px;
  .title-content {
    font-weight: 700;
  }
  .row {
    display: grid;
    grid-template-columns: 300px 1fr 50px;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #edeff2;
  }
  .input-upload {
    display: none;
  }
  .row-table {
    display: grid;
    grid-template-columns: 25px 260px 1fr 50px;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #edeff2;
    .mainInput {
      padding: 10px;
    }
    .MuiIconButton-root {
      color: ${theme.palette.secondary.main}
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: transparent;
    }
  }
  .drag {
    cursor: grab;
  }
  .chapters {
    grid-template-columns: 25px 260px 150px 1fr 45px 45px;
  }
  .row-add-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #edeff2;
    .MuiButton-root {
      color: #1f90cf;
    }
    .MuiButton-root.Mui-disabled {
    color: rgb(135 110 110 / 26%);
    }
  }
  .list-empty {
    margin: 12px auto;
  }
  .MuiInputBase-input::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px; 
  }
  .file-url-upload {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  `}
`;
