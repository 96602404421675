import styled from "styled-components";
import { lighten } from "polished";

export const Wrapper = styled.div`
  .circle-1 {
    display: flex;
    height: 150px;
    width: 150px;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => lighten(0.4, theme.palette.secondary.main)};
    border-radius: 50%;
    margin: 30px auto 20px auto;
  }

  .circle-2 {
    display: flex;
    height: 140px;
    width: 140px;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => lighten(0.3, theme.palette.secondary.main)};
    border-radius: 50%;
  }

  .circle-3 {
    display: flex;
    height: 130px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => lighten(0.1, theme.palette.secondary.main)};
    border-radius: 50%;
  }

  .circle-4 {
    display: flex;
    height: 120px;
    width: 120px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 50%;
    .MuiSvgIcon-root {
      font-size: 50px;
    }
  }
`;
