import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Switch,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { TreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import isEqual from "lodash/isEqual";

import {
  InputSimple,
  TreeItem,
  TablePagination,
  Select,
  Modal,
  BreadCrumbsTitle,
  LoadingListProductInventory,
  Search,
} from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import {
  ListDataEmpty,
  InventoryStockEntryFormModal,
  InventoryLostQuantityModal,
} from "../../components";

import {
  GET_LIST_PRODUCT_INVENTORIES,
  GET_PRODUCT_INVENTORY_DETAILS,
  GETLISTCOMPANYSITES,
  REMOVE_PRODUCT_INVENTORY,
} from "../../api";
import {
  GetListProductInventoriesQuery,
  GetListProductInventoriesQueryVariables,
  InventoryAvailability,
  GetProductInventoryDetailsQuery,
  GetProductInventoryDetailsQueryVariables,
  ProductInventoryDetailsPayload,
  GetListCompanySitesQuery,
  GetListCompanySitesQueryVariables,
  RemoveProductInventoryMutation,
  RemoveProductInventoryMutationVariables,
} from "../../api/types";
import {
  GET_MENU_LOCAL,
  GetMenuLocalQuery,
  snackBarVar,
} from "../../api/local-state";

import productDetailsEmpty from "../../img/digishop/MenuLayout/UsedProduct.svg";
import products from "../../img/digishop/menu-icons/productManagment.svg";

import { Wrapper, Note, SpanStatus } from "./InventoryStyle";

type DetailsInventory = {
  id?: string;
  date?: string;
  availableQuantity?: number;
  totalQuantity?: number;
  cost?: {
    currency?: string;
    value?: number;
  };
};

const detailsInventoryInitial: DetailsInventory = {
  id: undefined,
  date: undefined,
  availableQuantity: undefined,
  totalQuantity: undefined,
  cost: {
    currency: undefined,
    value: undefined,
  },
};

const availabilitiesInitial = {
  IN: false,
  LOW: false,
  OUT: false,
};

const Inventory: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();

  const [checked, setChecked] = useState<boolean>(false);

  const [filter, setFilter] = useState<boolean>(false);

  const [openStockEntryModal, setOpenStockEntryModal] =
    useState<boolean>(false);
  const [openLostQuantityModal, setOpenLostQuantityModal] =
    useState<boolean>(false);

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [productName, setProductName] = useState<string>("");
  const [searchBarCode, setSearchBarCode] = useState<string | undefined>(
    undefined
  );

  const [productNameDetails, setProductNameDetails] = useState<string>("");

  const [availabilities, setAvailabilities] = useState(availabilitiesInitial);

  const [idProductSelected, setIdProductSelected] = useState<string>("");

  const [idVariantSelected, setIdVariantSelected] = useState<
    string | undefined
  >(undefined);

  const [idSite, setIdSite] = useState<string | undefined>(undefined);

  const [collections, setCollections] = useState<
    ProductInventoryDetailsPayload[]
  >([]);
  const [page1, setPage1] = useState<number>(1);

  const [rowsPerPage1, setRowsPerPage1] = useState<number>(10);

  const [page2, setPage2] = useState<number>(1);
  const [rowsPerPage2, setRowsPerPage2] = useState<number>(10);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [idInventory, setIdInventory] = useState("");

  const [lostQuantityData, setLostQuantityData] = useState<DetailsInventory>(
    detailsInventoryInitial
  );

  const { data: localMenu } = useQuery<GetMenuLocalQuery>(GET_MENU_LOCAL);

  const { data: listCompanySite } = useQuery<
    GetListCompanySitesQuery,
    GetListCompanySitesQueryVariables
  >(GETLISTCOMPANYSITES, {
    variables: {
      token,
      menu: localMenu?.menuItem?.id,
    },
  });

  const arrayAvailability = React.useMemo(() => {
    const arry: InventoryAvailability[] = [];
    Object.entries(availabilities).forEach(([key, value]) => {
      if (value) {
        arry.push(key as InventoryAvailability);
      }
    });
    return arry;
  }, [availabilities]);

  const { data: listProducts, loading } = useQuery<
    GetListProductInventoriesQuery,
    GetListProductInventoriesQueryVariables
  >(GET_LIST_PRODUCT_INVENTORIES, {
    variables: {
      input: {
        token,
        menu: localMenu?.menuItem?.id || "",
        productName,
        barcode: searchBarCode,
        availabilities: arrayAvailability,
        pageCount: rowsPerPage1,
        pageNumber: page1,
      },
    },
    skip: !localMenu?.menuItem?.id,
  });

  const lengthListProducts =
    listProducts?.getListProductInventories?.pageInfo?.total || 0;

  const {
    data: listInventoryDetails,
    loading: loadingDetails,
    refetch,
  } = useQuery<
    GetProductInventoryDetailsQuery,
    GetProductInventoryDetailsQueryVariables
  >(GET_PRODUCT_INVENTORY_DETAILS, {
    variables: {
      input: {
        token,
        product: idProductSelected,
        variant: idVariantSelected,
        site: idSite,
        pageCount: rowsPerPage2,
        pageNumber: page2,
      },
    },
    skip: !idProductSelected,
    fetchPolicy: "cache-and-network",
  });

  const [RemoveInventory, { loading: loadingDelete }] = useMutation<
    RemoveProductInventoryMutation,
    RemoveProductInventoryMutationVariables
  >(REMOVE_PRODUCT_INVENTORY, {
    variables: {
      input: {
        id: idInventory,
        token,
      },
    },
    onCompleted: () => {
      snackBarVar({
        open: true,
        severity: "success",
        message: "successfully removed!",
      });
      refetch();
      setOpenModalDelete(false);
    },
    onError: () => {
      snackBarVar({
        open: true,
        severity: "error",
        message: "service unavailable!",
      });
    },
  });

  const lengthListProductsDetails =
    listInventoryDetails?.getProductInventoryDetails?.itemsCount || 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailabilities({
      ...availabilities,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(1);
  };

  const handleChangeRowsPerPage2 = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(1);
  };

  useEffect(() => {
    setCollections(
      listInventoryDetails?.getProductInventoryDetails?.list || []
    );
  }, [listInventoryDetails]);

  const columns: ColumnsProps = [
    {
      header: "Acquisition date",
      accessor: "date",
    },
    {
      header: "Cost per item",
      accessor: "cost",
    },
    {
      header: "Quantity",
      accessor: "quantity",
    },
    {
      header: "Entry status",
      accessor: "status",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "100px" },
    },
  ];

  const handleStatus = (status: string) => {
    switch (status) {
      case "IN":
        return <SpanStatus status="IN">In stock</SpanStatus>;
      case "NEW":
        return <SpanStatus status="NEW">In transit</SpanStatus>;
      case "OUT":
        return <SpanStatus status="OUT">Out of stck</SpanStatus>;
      default:
        return "";
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderTableRows = () => {
    return (
      collections?.map((collection) => ({
        date: (
          <Typography variant="subtitle1">
            {moment(collection?.date).format("L")}
          </Typography>
        ),
        cost: (
          <Typography variant="subtitle1">
            {collection?.cost?.value}&nbsp;{collection?.cost?.currency?.code}
          </Typography>
        ),
        quantity: (
          <Typography variant="subtitle1">
            {collection?.availableQuantity} / {collection?.totalQuantity}
          </Typography>
        ),
        status: handleStatus(collection?.stockStatus || ""),

        actions: (
          <>
            <IconButton
              id={collection.id || ""}
              key={collection.id || ""}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e);
                setIdInventory(collection.id || "");
                setLostQuantityData({
                  id: collection?.id || "",
                  date: collection?.date || "",
                  availableQuantity: collection?.availableQuantity || 0,
                  totalQuantity: collection?.totalQuantity || 0,
                  cost: {
                    currency: collection?.cost?.currency?.code || "",
                    value: collection?.cost?.value || 0,
                  },
                });
              }}
              autoFocus
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      })) || []
    );
  };

  return (
    <Wrapper checked={checked}>
      <Note>
        <BreadCrumbsTitle
          labelIntl={[
            intl.formatMessage({ id: "ProductForm.ProductManagement" }),
            intl.formatMessage({ id: "inventory.title" }),
          ]}
          icon={products}
          iconAlt="products"
        />
        {/* #### it's a comment because multi address is not completed #### */}
        {/* {lengthListProducts > 0 && (
          <div className="option">
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked || false);
                    setIdSite(undefined);
                  }}
                  name="checkedA"
                />
              }
              label="Manage in multiaddress"
            />
          </div>
        )} */}
      </Note>
      {loading && <LoadingListProductInventory />}

      {/* {(lengthListProducts > 0 ||
        !isEqual(availabilitiesInitial, availabilities) ||
        productName !== "") && ( */}

      {!loading && (
        <div className="container">
          <Box component={Paper} className="box-1">
            <div className="header-filter">
              <div>
                <Typography
                  variant="h2"
                  style={{ marginLeft: 0, fontWeight: "bold" }}
                >
                  Product List
                </Typography>
                <Button onClick={() => setFilter(!filter)}>
                  <FontAwesomeIcon icon={faFilter} color="#1F90CF" />
                  <span>Filter</span>
                </Button>
              </div>
              {filter && (
                <div className="show-filter">
                  <FormControlLabel
                    name="IN"
                    checked={availabilities.IN}
                    control={<Checkbox onChange={handleChange} />}
                    label="In stock products"
                  />
                  <FormControlLabel
                    name="OUT"
                    checked={availabilities.OUT}
                    control={<Checkbox onChange={handleChange} />}
                    label="Out of stock products"
                  />
                  <FormControlLabel
                    name="LOW"
                    checked={availabilities.LOW}
                    control={<Checkbox onChange={handleChange} />}
                    label="Low Stock Products"
                  />
                </div>
              )}
            </div>
            <Search
              value={productName}
              onReturnSearch={(value) => setProductName(value)}
              placeholder="Search by name"
              style={{ marginBottom: 10 }}
            />
            <Search
              value={searchBarCode}
              onReturnSearch={(value) => setSearchBarCode(value || undefined)}
              placeholder="Search by barcode"
            />

            <div className={filter ? "list-products-filter" : "list-products"}>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {listProducts?.getListProductInventories?.list?.map(
                  (product) => (
                    <TreeItem
                      nodeId={product?.product?.id || ""}
                      key={product?.product?.id || ""}
                      labelText={product?.product?.names?.[0]?.value || ""}
                      onClick={() => {
                        setIdVariantSelected(undefined);
                        product?.product?.id &&
                          setIdProductSelected(product.product.id);
                        setProductNameDetails(
                          product?.product?.names?.[0]?.value || ""
                        );
                      }}
                    >
                      {product?.variants &&
                        product.variants?.length > 0 &&
                        product.variants.map((variant) => (
                          <TreeItem
                            nodeId={variant?.variant?.id || ""}
                            key={variant?.variant?.id || ""}
                            labelText={
                              variant?.variant?.names?.[0]?.value || ""
                            }
                            low={variant?.availability === "LOW"}
                            out={variant?.availability === "OUT"}
                            onClick={() => {
                              product?.product?.id &&
                                setIdProductSelected(product.product.id);
                              variant?.variant?.id &&
                                setIdVariantSelected(variant.variant.id);
                            }}
                          />
                        ))}
                    </TreeItem>
                  )
                )}
              </TreeView>
            </div>
            <TablePagination
              labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
              count={lengthListProducts}
              page={page1 - 1}
              onPageChange={(_, newPage) => setPage1(newPage + 1)}
              rowsPerPage={rowsPerPage1}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
          <Box component={Paper} className="box-2">
            {(lengthListProductsDetails > 0 || idSite !== undefined) && (
              <>
                <div>
                  <div className="header">
                    <Typography
                      variant="h2"
                      style={{ marginLeft: 0, fontWeight: "bold" }}
                    >
                      {productNameDetails}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenStockEntryModal(true)}
                    >
                      Add Entry
                    </Button>
                  </div>
                  {checked && (
                    <div style={{ width: 300, marginTop: 20 }}>
                      <Select
                        label="Select Address"
                        value={idSite || ""}
                        onChange={(e) => setIdSite(e.target.value as string)}
                      >
                        {listCompanySite?.getListCompanySites?.list?.map(
                          (site) => (
                            <MenuItem
                              key={site?.id || ""}
                              value={site?.id || ""}
                            >
                              {site?.identifier}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </div>
                  )}
                  <div className="details">
                    <Typography
                      variant="subtitle1"
                      style={{ marginLeft: 0, fontWeight: "bold" }}
                    >
                      Total Available : &nbsp;
                      {
                        listInventoryDetails?.getProductInventoryDetails
                          ?.availableQuantity
                      }
                    </Typography>
                    <Typography variant="subtitle2">
                      SKU : &nbsp;
                      {listInventoryDetails?.getProductInventoryDetails?.sku}
                    </Typography>
                    <Typography variant="subtitle2">
                      Barcode : &nbsp;
                      {
                        listInventoryDetails?.getProductInventoryDetails
                          ?.barcode
                      }
                    </Typography>
                    <Typography variant="subtitle2">
                      Selling Price : &nbsp;
                      {
                        listInventoryDetails?.getProductInventoryDetails?.price
                          ?.value
                      }
                      &nbsp;
                      {
                        listInventoryDetails?.getProductInventoryDetails?.price
                          ?.currency?.code
                      }
                    </Typography>
                  </div>
                </div>
                <Table
                  columns={columns}
                  data={renderTableRows()}
                  emptyMessage="This product has no entries"
                />
                <div className="footer-pagination">
                  <TablePagination
                    labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                    count={lengthListProductsDetails}
                    page={page2 - 1}
                    onPageChange={(_, newPage) => setPage2(newPage + 1)}
                    rowsPerPage={rowsPerPage2}
                    onChangeRowsPerPage={handleChangeRowsPerPage2}
                  />
                </div>
              </>
            )}
            {!loadingDetails &&
              lengthListProductsDetails === 0 &&
              idSite === undefined && (
                <div className="product-list-details-empty">
                  <img src={productDetailsEmpty} alt="product-details-empty" />
                  <p>To manage entries please select a product.</p>
                </div>
              )}
          </Box>
        </div>
      )}

      {!loading &&
        lengthListProducts === 0 &&
        isEqual(availabilitiesInitial, availabilities) &&
        productName === "" && (
          <ListDataEmpty
            image={products}
            description="You have no created products to manage their stock!"
            btnName="Go To Products"
            url="/managment-product"
          />
        )}
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenLostQuantityModal(true);
          }}
        >
          Set Lost Quantity
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenModalDelete(true);
          }}
          style={{ color: "#F67070" }}
        >
          Delete
        </MenuItem>
      </Menu>
      <InventoryStockEntryFormModal
        title="Add Stock entry"
        open={openStockEntryModal}
        handleClose={() => setOpenStockEntryModal(false)}
        product={idProductSelected}
        variant={idVariantSelected}
        site={idSite}
        refetch={refetch}
      />
      <InventoryLostQuantityModal
        title="Lost Quantity Modifier"
        open={openLostQuantityModal}
        handleClose={() => setOpenLostQuantityModal(false)}
        detailsInventory={lostQuantityData}
        refetch={refetch}
      />
      <Modal
        open={openModalDelete}
        title="Delete inventory?"
        message="Are you sure you want to remove this inventory?"
        handleClose={() => setOpenModalDelete(false)}
        handleContent={RemoveInventory}
        action="delete"
        loading={loadingDelete}
      />
    </Wrapper>
  );
};

export default Inventory;
