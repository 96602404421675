import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  .back {
    color: #0052b4;
    width: 100px;
    svg {
      margin-right: 5px;
    }
  }
  .bold {
    font-weight: 700;
  }
  .MuiFormControlLabel-root {
    height: 35px;
  }
  .MuiFormControlLabel-root {
    width: 130px;
  }
  .paying-amount {
    .MuiSelect {
      padding: 14px 12px 14px;
    }
    .mainInput {
      padding: 14px 12px 14px;
    }
    margin-bottom: 8px;
  }
  .btn-switch {
    width: 250px;
    margin: 8px 0;
  }
  .row {
    display: grid;
    width: 340px;
    grid-template-columns: 200px 100px;
    align-items: center;
    grid-gap: 10px;
    border-bottom: 1px solid #edeff2;
    padding: 5px 0 5px 8px;
  }
  .mainInput {
    padding: 23px 12px 7px;
  }
  .description {
    max-width: 900px;
  }
  .product__image {
    width: 80px;
    height: 45px;
    max-width: 90px;
    min-height: 40px;
    max-height: 50px;
    object-fit: contain;
    border: 1px solid #e9e9e9;
  }
  .btn-save {
    width: 180px;
    margin-top: 20px;
  }
`;
