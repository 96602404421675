import React from "react";
import styled from "styled-components";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import {
  Table as TableMUI,
  TableBody,
  TableContainer,
  TableFooter,
  TableRow,
} from "@material-ui/core";
import { TablePaginationProps } from "@material-ui/core/TablePagination";

import { TablePagination } from ".";
import { generateKey } from "../utils/generate-key";

export type ColumnsProps = {
  header: React.ReactNode;
  accessor: string;
  headerCellProps?: TableCellProps;
  cellProps?: TableCellProps;
}[];

type TableProps<T> = {
  data: T[];
  columns: ColumnsProps;
  tablePaginationProps?: TablePaginationProps;
  emptyMessage?: string;
  onClickRow?: (row: T) => void;
  sortable: any;
};

function TableSortable<T extends { [key: string]: React.ReactNode }>(
  tableProps: TableProps<T>
): JSX.Element {
  const {
    data,
    columns,
    tablePaginationProps,
    emptyMessage,
    onClickRow,
    sortable,
  } = tableProps;

  const rows = data.map((item) => ({ render: item, key: generateKey() }));

  const Sortable = sortable;

  return (
    <TableContainer>
      <TableMUI aria-label="customized table" size="small">
        <TableBody>
          {Sortable && rows.length >= 1 && (
            <Sortable>
              {rows.length >= 1 ? (
                <>
                  {rows?.map((row, indexRow) => (
                    <TableRowStyled
                      key={row.key}
                      hover={!!onClickRow}
                      onClick={() => {
                        onClickRow && onClickRow(row.render);
                      }}
                    >
                      {columns?.map((column, index) => {
                        // if (indexRow === 0) {
                        //   return (
                        //     <TableCellStyled
                        //       // eslint-disable-next-line
                        //       key={index}
                        //       {...column.headerCellProps}
                        //     >
                        //       {column.header}
                        //     </TableCellStyled>
                        //   );
                        // }
                        return (
                          <TableCell
                            // eslint-disable-next-line
                            key={index}
                            {...column.cellProps}
                          >
                            {row.render[column?.accessor]}
                          </TableCell>
                        );
                      })}
                    </TableRowStyled>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    size="medium"
                    colSpan={columns.length}
                    align="center"
                  >
                    {emptyMessage}
                  </TableCell>
                </TableRow>
              )}
            </Sortable>
          )}
        </TableBody>

        <TableFooter>
          {tablePaginationProps && (
            <TablePagination {...tablePaginationProps} />
          )}
        </TableFooter>
      </TableMUI>
    </TableContainer>
  );
}

const TableCellStyled = styled(TableCell)`
  background: ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.common.black};
  border: none;
  padding: 10px 24px 10px 16px;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TableRowStyled = styled(TableRow)`
  ${({ hover }) => hover && { cursor: "pointer" }}
`;
export default TableSortable;
