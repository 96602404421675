// Libraries Imports
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Chip, Avatar, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

// Local Imports
import { Wrapper, Note, Separator } from "./styles";
import {
  ValidationCategoryForm,
  getCheckSpecialCaratcters,
  getCheckAlphanumeric,
} from "./utils";

// Common Imports
import {
  Box,
  Input,
  BreadCrumbsTitle,
  ButtonCustomize,
} from "../../../commons";

// Components Imports

// API Imports
import { GETLISTLANGUAGES, CREATEMENU, GETLISTMENUS } from "../../../api";
import {
  CreateNameInput,
  CreateDescriptionInput,
  GetListLanguagesQuery,
  CreateMenuMutation,
  CreateMenuMutationVariables,
  GetListMenuQuery,
  GetListMenuQueryVariables,
} from "../../../api/types";
import { SelectedMenuVar } from "../../../api/local-state";

// Other Imports
import Shop from "../../../img/digishop/menu-icons/shop.svg";
import { getLanguage, getFlag } from "../../../utils";

// Types
type Check = {
  alphanumeric: undefined | boolean;
  specialCaratcters: undefined | boolean;
};

type LanguageType = {
  language: string;
  isMain: boolean;
};

const StoreCreate: FC = () => {
  // Local variables
  const intl = useIntl();
  const history = useHistory();
  const token = localStorage.getItem("token") || "";

  // States
  const [identifier, setIdentifier] = useState<string>("");
  const [names, setName] = useState<CreateNameInput[]>([
    { languageCode: "EN", value: "" },
  ]);
  const [descriptions, setDescriptions] = useState<CreateDescriptionInput[]>([
    { languageCode: "EN", value: "" },
  ]);
  const [nameError, setNameError] = useState<Check>({
    alphanumeric: undefined,
    specialCaratcters: undefined,
  });
  const [languageInput, setLanguageInput] = useState<LanguageType[]>([]);
  const [mainLang, setMainLang] = useState<string>();
  const [errors, setErrors] = useState({
    identifier: "",
  });

  // Queries & Mutaions
  const { data: languageList } = useQuery<GetListLanguagesQuery>(
    GETLISTLANGUAGES,
    {
      onCompleted: () => {
        setMainLang(languageList?.getListLanguages.list?.[0].id || "");
        setLanguageInput([
          {
            language: languageList?.getListLanguages.list?.[0].id || "",
            isMain: true,
          },
        ]);
      },
    }
  );

  /* Global const */
  const languages = languageList?.getListLanguages.list;

  const [getListMenu] = useLazyQuery<
    GetListMenuQuery,
    GetListMenuQueryVariables
  >(GETLISTMENUS, {
    variables: {
      token,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!SelectedMenuVar()) {
        SelectedMenuVar(data.getListMenus.list[0]);
        history.push("/");
      }
    },
  });

  const [createMenu, { loading }] = useMutation<
    CreateMenuMutation,
    CreateMenuMutationVariables
  >(CREATEMENU, {
    variables: {
      menu: {
        token,
        identifier,
        names,
        descriptions,
        languages: languageInput,
      },
    },
    onCompleted: () => {
      getListMenu();
    },
  });

  // Functions
  const handleOnChangeIdentifier = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const format = /[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
    if (event.target.value) {
      setNameError({ ...nameError, alphanumeric: true });
      if (event.target.value && !format.test(event.target.value)) {
        setNameError({
          ...nameError,
          alphanumeric: true,
          specialCaratcters: true,
        });
      } else {
        setNameError({
          ...nameError,
          alphanumeric: true,
          specialCaratcters: false,
        });
      }
    } else {
      setNameError({ alphanumeric: undefined, specialCaratcters: undefined });
    }
    setIdentifier(
      event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    );
    setErrors((prevErrors) => ({ ...prevErrors, identifier: "" }));
  };

  const handleChangeLanguage = (id: string, isMain: boolean) => {
    const languagesArr: LanguageType[] = Object.assign([], languageInput);

    if (_.findIndex(languagesArr, { language: id }) === -1) {
      languagesArr.push({ language: id, isMain });
      setLanguageInput(languagesArr);
    } else if (
      languagesArr[_.findIndex(languagesArr, { language: id })].isMain !==
        true &&
      mainLang !== id
    ) {
      languagesArr.splice(_.findIndex(languagesArr, { language: id }), 1);
      setLanguageInput(languagesArr);
    }
  };

  const setMainLanguage = (id: string) => {
    const languagesArr: LanguageType[] = Object.assign([], languageInput);

    languagesArr[_.findIndex(languagesArr, { isMain: true })].isMain = false;

    languagesArr[_.findIndex(languagesArr, { language: id })].isMain = true;
    setLanguageInput(languagesArr);

    setMainLang(id);
  };

  const handleOnSubmit = () => {
    const validation = ValidationCategoryForm(identifier);
    // check the table "found" if there is 0 and validation if retrun empty
    if (Object.entries(validation).length === 0) {
      createMenu();
    }
  };

  return (
    <>
      <Note>
        <BreadCrumbsTitle
          labelIntl={["Stores", "Create new store"]}
          icon={Shop}
          iconAlt="stores"
        />
        <ButtonCustomize
          data-testid="submit-button"
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleOnSubmit}
          disabled={loading}
        >
          Create
        </ButtonCustomize>
      </Note>
      <Wrapper>
        <Box width={1} p={24}>
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            Store informations
          </Typography>
          <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
            Store name in URL
          </Typography>

          <div className="link-container">
            <div className="link">
              <label htmlFor="store__name" className="link-prefix">
                company.digishop.online/
              </label>
              <input
                id="store__name"
                type="text"
                className="store-name"
                value={identifier || ""}
                onChange={handleOnChangeIdentifier}
              />
            </div>
            <p>
              {errors?.identifier &&
                intl.formatMessage({ id: errors.identifier })}
            </p>
            <div className="checklist">
              <div className="tip" />
              <ul>
                <li className={getCheckAlphanumeric(nameError.alphanumeric)}>
                  <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                  {intl.formatMessage({ id: "format.alphaneumeric" })}
                </li>
                <li
                  className={getCheckSpecialCaratcters(
                    nameError.specialCaratcters
                  )}
                >
                  <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                  {intl.formatMessage({
                    id: "format.specialCaracters",
                  })}
                </li>
              </ul>
            </div>
          </div>
          <Typography variant="subtitle1" style={{ margin: "16px 0 8px 0" }}>
            Store name
          </Typography>

          <div className="input-container">
            <Input
              id="categoryName"
              name="categoryName"
              label="Name"
              value={names?.[0].value || ""}
              onChange={(e) => {
                setName([{ languageCode: "EN", value: e.target.value }]);
              }}
              dataTestId="categoryName"
            />
          </div>

          <Typography variant="subtitle1" style={{ margin: "16px 0 8px 0" }}>
            Description
          </Typography>

          <div className="textarea-container">
            <Input
              id="description"
              name="description"
              label="Name"
              value={descriptions?.[0].value || ""}
              onChange={(e) => {
                setDescriptions([
                  { languageCode: "EN", value: e.target.value },
                ]);
              }}
              multiline
              rows={5}
            />
          </div>
        </Box>
        <Separator />
        <Box width={1} p={24}>
          <div className="container">
            <div className="topbar">
              <Typography variant="h2" style={{ fontWeight: 700 }}>
                {intl.formatMessage({
                  id: "MenuUpdate.languageSettings",
                })}
              </Typography>
            </div>
            <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
              {intl.formatMessage({ id: "Store.menuMainLanguage" })}
            </Typography>
            <div
              className="box"
              style={{ marginTop: "15px" }}
              data-testid="menu-main-lanugage"
            >
              {languages !== undefined &&
                languages.map((language) => {
                  return (
                    <Chip
                      key={language.id}
                      avatar={
                        <Avatar
                          alt={getLanguage(language.code!)}
                          src={getFlag(language.code!)}
                        />
                      }
                      label={getLanguage(language.code!)}
                      onClick={() => setMainLanguage(language?.id || "")}
                      color={mainLang === language.id! ? "primary" : "default"}
                      style={{
                        marginRight: "15px",
                        marginBottom: "10px",
                      }}
                      disabled={
                        _.findIndex(languageInput, {
                          language: language?.id || "",
                        }) === -1
                      }
                    />
                  );
                })}
            </div>
            <Typography variant="subtitle1" style={{ margin: "5px 0" }}>
              {intl.formatMessage({ id: "Store.menuLanguages" })}
            </Typography>
            <div
              className="box"
              style={{ marginTop: "15px" }}
              data-testid="menu-languages"
            >
              {languages !== undefined &&
                languages.map((language) => {
                  return (
                    <Chip
                      key={language.id}
                      avatar={
                        <Avatar
                          alt={getLanguage(language.code!)}
                          src={getFlag(language.code!)}
                        />
                      }
                      label={getLanguage(language.code!)}
                      onClick={() => handleChangeLanguage(language.id!, false)}
                      color={
                        _.findIndex(languageInput, {
                          language: language.id!,
                        }) !== -1
                          ? "primary"
                          : "default"
                      }
                      style={{
                        marginRight: "15px",
                        marginBottom: "10px",
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </Box>
      </Wrapper>
    </>
  );
};

export default StoreCreate;
